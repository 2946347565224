import React, {ReactElement} from "react";
import classNames from "classnames/bind";
import {useNavigate} from "react-router";

import {AiOrchestration} from "../../../models/ai-orchestration";
import {useThemeMode} from "../../../context/theme-mode-context";
import {Avatar, Body, Caption, Card, Dropdown, Subheader} from "../../../shared/v2";
import {BinDeleteIcon, DotsIcon, PenEditIcon} from "../../../icons";

import styles from "./workflow-card.module.scss";

const bStyles = classNames.bind(styles);

export interface WorkflowCardProps {
	workflow: AiOrchestration;
	onClick: (id: string) => void;
	handleDelete: (id: string) => void;
}


const WorkflowCard = ({workflow, handleDelete, onClick}: WorkflowCardProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const navigate = useNavigate();

	const openWorkflowPage = (): void => {
		navigate(`/workflow/flows/${workflow.id}`);
	};

	return (
		<Card
			className={bStyles("card", {isDarkMode})}
			onClick={() => onClick(workflow.id)}
		>
			<Dropdown
				className={styles.dropdown}
				trigger={<DotsIcon className={styles.trigger} />}
				items={[
					{
						label: "Edit",
						icon: <PenEditIcon />,
						onClick: () => openWorkflowPage(),
					},
					{
						label: "Delete",
						icon: <BinDeleteIcon />,
						color: "danger",
						onClick: () => handleDelete(workflow.id)
					}
				]}
				position="bottom-start"
			/>
			<Subheader type="medium">{workflow.name}</Subheader>
			<Body className={styles.description} size="xs" color="text-secondary">
				{workflow.description}
			</Body>

			<div className={styles.teamsSection}>
				<Caption className={styles.team}>
					Team:
				</Caption>
				{Boolean(workflow.aiPersonaTasks.length) && <div className={styles.teams}>
					{workflow.aiPersonaTasks.slice(0,6).map((task, index) => {
						return <Avatar
							key={index}
							firstName={task.persona?.name}
							url={task.persona?.picture?.url}
							className={styles.avatar}
						/>;
					})}

					{workflow.aiPersonaTasks.length > 6 &&
						<Avatar
							className={styles.avatar}
							firstName="+"
							lastInitial={`${workflow.aiPersonaTasks.length - 6}`}
						/>
					}
				</div>
				}
			</div>
		</Card>
	);
};

export {WorkflowCard};
