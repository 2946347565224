import React, {ReactElement, useEffect, useRef} from "react";
import styles from "./review-modal.module.scss";
import {GET_ANSWER_BY_ID} from "../../../../../graphql/queries/survey-queries";
import {useLoadingQuery} from "../../../../../hooks";
import {AnswerTranscriptData} from "../../../../../models/answer";
import {useMutation} from "@apollo/client";
import {REVIEW_ANSWER, UNREVIEW_ANSWER} from "../../../../../graphql/mutations/survey-mutations";
import {Button, Modal} from "../../../../../shared/v2";
import {orderSubtitles} from "../../../../../shared/utility/utility";

export interface ReviewModalProps {
	answerId: string;
	onClose: () => void;
	openReelModal: (answerId: string, description: string) => void;
}

const ReviewModal = (props: ReviewModalProps): ReactElement => {
	const {answerId, onClose, openReelModal} = props;
	const vidRef = useRef<HTMLVideoElement>(null);

	const {data, fragment} = useLoadingQuery<AnswerTranscriptData>(GET_ANSWER_BY_ID, {
		skip: !answerId,
		variables: {id: answerId},
		what: "Answer details",
	});

	const [reviewed] = useMutation(REVIEW_ANSWER, {
		variables: {id: answerId},
	});
	const [unreviewed] = useMutation(UNREVIEW_ANSWER, {
		variables: {id: answerId},
	});

	const handleSetAsReviewed = (): void => {
		reviewed();
	}

	const handleSetAsUnreviewed = (): void => {
		unreviewed();
	}

	const handleOpen = (): void => {
		openReelModal(answerId, data?.answer.text || "");
	};

	useEffect(() => {
		if (!vidRef.current) return;
		const {current} = vidRef;
		const length = current.textTracks.length;
		if (length === 0) return;
		current.textTracks[0].mode = "showing";
		if (length > 1) current.textTracks[1].mode = "hidden";
	}, [vidRef.current, data]);

	return (
		<Modal isOpen={Boolean(answerId)} onClose={onClose} size="medium">
			{fragment && <div className={styles.loading}>{fragment}</div>}
			{data && <div className={styles.container}>
				<div className={styles.topContainer}>
					<div className={styles.videoContainer}>
						<video className={styles.video} controls>
							<source src={data.answer.video.video} />
							<source src={data.answer.video.mp4}/>
							{data.answer.video?.subtitles && data.answer.video?.subtitles.length > 0 &&
								orderSubtitles(data.answer.video?.subtitles).map(sub =>
									<track key={sub.id} lang={sub.langCode} src={sub.fileUrl} label={sub.langCode}/>)}
						</video>
					</div>
					<div className={styles.transcript}>
						{data.answer.video.transcript?.words.map(word => `${word.word} `) ??
							"Transcript is not available"}
					</div>
				</div>
				<div className={styles.actions}>
					{data.answer.reviewed ? <div className={styles.review}>
						<Button variant="outlined" size="small" onClick={handleSetAsUnreviewed}>Mark as Unreviewed</Button>
						<span className={styles.reviewer}>Reviewed by {data.answer.reviewer.name}</span>
					</ div> :
						<Button size="small" onClick={handleSetAsReviewed}>Mark as Reviewed</Button>
					}
					<Button
						onClick={handleOpen}
					>
						{data.answer.reviewed ? "Create Reel" : "Review and Create Reel"}
					</Button>
				</div>
			</div>
			}
		</Modal>
	);
};

export {ReviewModal};
