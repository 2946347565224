import React, {ReactElement} from "react";

import {useUnsafeChatPersonaContext} from "../../../context/chat-contexts";
import {useThemeMode} from "../../../context/theme-mode-context";
import {useUserContext} from "../../../context/user-context";
import {Persona} from "../../../models";
import {Avatar, Body} from "../../../shared/v2";
import {TextToHTMLPatternReplacer} from "../../../shared/v2/text-to-html-pattern-replacer";
import {TextToHTMLPatternReplacerOption} from "../../../shared/v2/text-to-html-pattern-replacer/patterns";
import {Mention} from "../../chat-bubble/command-text-area/mention";

import styles from "./input-bubble.module.scss";

export interface InputBubbleProps {
	content: string;
}

export const InputBubble = ({content}: InputBubbleProps): ReactElement => {
	const {personas} = useUnsafeChatPersonaContext() ?? {};
	const {isDarkMode} = useThemeMode();
	const {user} = useUserContext();
	const imageUrl = user.picture?.thumbnail;

	const renderMention = (personaName: string) => {
		const persona = personas?.find((persona) => persona.name === personaName.slice(1)) as Persona;

		if (!persona) {
			return <>{personaName}</>;
		}

		return <Mention persona={persona} />;
	}

	return (
		<>
			<div className={styles.inputBubble} aria-label="input-bubble">
				<div className={styles.mainWrapper}>
					<Avatar type="square" size="md" firstName={user.firstName} url={imageUrl} />
					<Body className={styles.content} color={isDarkMode ? "text-body" : "gray-modern-900"}>
						<TextToHTMLPatternReplacer
							text={content}
							patterns={personas ? {
								[TextToHTMLPatternReplacerOption.Mention]: {
									render: renderMention,
									mentions: personas?.map((persona) => persona.name) || [],
								}
							} : {}}
						/>
					</Body>
				</div>
			</div>
		</>
	);
};
