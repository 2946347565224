/* eslint-disable */
import {ResponsiveContainer, BarChart, XAxis, YAxis, Bar} from "recharts";
import React, {ReactElement} from "react";

import {Checkbox} from "../../../shared/v2";
import {NumericCounts, SliderQuestion} from "../../../models/questions";

import styles from "./slider-chart.module.scss";

interface SliderChartProps {
	question: SliderQuestion;
	copyButton?: JSX.Element;
	changeSelected: (e: React.ChangeEvent<HTMLInputElement>) => void;
	selected?: (number | null)[] | null;
}

const makeData = (data: NumericCounts[], min: number, max: number): NumericCounts[] =>
	Array(min === 0 ? max + 1 : max).fill(1).map((_x, i) => data.find(d => d?.answer === i + min) ?? {answer: i + min, count: 0});

const SliderChart = React.forwardRef<HTMLDivElement, SliderChartProps>((props, ref): ReactElement => {
	const {
		question: {numericCounts, numericAverage, answerCount, max, min, leftLabel, rightLabel, videoResponse},
		copyButton,
		selected,
		changeSelected,
	} = props;
	const barData = makeData(numericCounts, min, max);
	return (
		<div className={styles.container} ref={ref}>
			<header className={styles.header}>
				<h3>{(numericAverage?.toFixed(2)) || 0} average rating</h3>
				{copyButton}
			</header>
			<div className={styles.labels}>
				<span>{leftLabel} ({min})</span>
				<span>{rightLabel} ({max})</span>
			</div>
			<ResponsiveContainer width="100%" height={300}>
				<BarChart layout="horizontal" data={barData}>
					<XAxis hide/>
					<YAxis width={0}
						axisLine={false} tickLine={false}
						tick={false}
						domain={[0, answerCount]}
					/>
					<Bar
						key="amount"
						background={{fill: "var(--batterii-grey-extralight)"}}
						fill="var(--batterii-purple)"
						dataKey="count"
					/>
				</BarChart>
			</ResponsiveContainer>
			{ videoResponse &&
				<div className={styles.checkboxContainer}>
					{barData.map(item => <Checkbox
						key={`${item.answer}-checkbox`}
						id={`${item.answer}-checkbox`}
						value={item.answer || ""}
						onChange={changeSelected}
						size="s"
						checked={selected?.some(value => value === item.answer) || false}
					/>)
					}
				</div>
			}
		</div>
	);
});

SliderChart.displayName = "SliderChart";

export {SliderChart};
