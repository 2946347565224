import React, {ReactElement} from "react";
import classNames from "classnames/bind";
import {Argument} from "classnames";

import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./tip-banner.module.scss";

const bStyles = classNames.bind(styles);

export interface TipBannerProps {
	children: React.ReactNode;
	className?: Argument;
}

const TipBanner = ({children, className}: TipBannerProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	return <span className={bStyles("tip", className, {isDarkMode})}>{children}</span>;
};


export {TipBanner};
