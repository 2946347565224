import React, {ReactElement, useEffect, useRef, useState} from "react";
import {animated, useSpring} from "react-spring";
import {Icon} from "../icon";
import styles from "./multi-select.module.scss";
import classNames from "classnames/bind";
import {useThemeMode} from "../../../context/theme-mode-context";

const bStyles = classNames.bind(styles);

export interface SelectValue<T> {
	id: T;
	name: string;
}

export interface MultiSelectProps<T> {
	/**
	 * Data to take in for the select
	 */
	options: SelectValue<T>[];
	/**
	 * Label for the select box
	 */
	label: string;
	/**
	 * All currently selected values
	 */
	value: T[];
	/**
	 * Optional key to give the Multiselect in case component is in an array
	 */
	id: string;
	/**
	 * Handle selection of dropdown
	 */
	onChange: (newValue: T[], id: string) => void;
	/**
	 * Denotes if you can search through the dropdown to find option you want.
	 * If this is false, user cannot type in the input.
	 */
	isSearchable?: boolean;
	maxLength?: number;
}

function MultiSelect<T = string>(props: MultiSelectProps<T>): ReactElement {
	const {options, label, value = [], isSearchable = false, maxLength} = props;
	const {isDarkMode} = useThemeMode();
	const [showDropdown, setShowDropdown] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState<string>("");
	const node = useRef() as React.MutableRefObject<HTMLDivElement>;
	const animatedProps = useSpring({
		maxHeight: showDropdown ? 250 : 0,
		config: {duration: 3.5},
	});
	let filtered: SelectValue<T>[] = options;

	if (isSearchable && searchValue) {
		filtered = options.filter((item: SelectValue<T>): boolean => {
			const lowerCase = item.name.toLowerCase();
			return lowerCase.includes(searchValue.toLowerCase());
		});
	}
	// Note to self, maybe make some constant string values for stuff like colors
	/**
	 *
	 * @param event Click event. If we click outside of the given div, we will hide
	 * the dropdown
	 *
	 * @returns Nothing or sets show dropdown to false.
	 */
	const handleClick = (event): void => {
		if (node.current.contains(event.target)) {
			// node.current.style.borderColor = "var(--batterii-blue)";
			node.current.style.boxShadow = "0 0 0 3px rgba(95, 77, 255, .5)";
			return;
		}
		node.current.style.boxShadow = "none";
		setShowDropdown(false);
	};

	/**
	 * Use effect adds listeners to see if user clicks on the select box
	 */
	useEffect(() => {
		document.addEventListener("mousedown", handleClick);
		// document.addEventListener("keydown", handleKeyboard);

		return () => {
			document.removeEventListener("mousedown", handleClick);
			// document.removeEventListener("keydown", handleKeyboard);
		};
	}, []);

	/**
	 * This useEffect will make sure a dropdown will always be showing when a user
	 * has something typed in the input
	 */
	useEffect(() => {
		if (searchValue !== "") {
			setShowDropdown(true);
		}
	}, [searchValue]);

	const handleValue = (option: SelectValue<T>): void => {
		let exists = false;
		const newValue = value.filter(v => {
			if (v === option.id) {
				exists = true;
				return false;
			}
			return true;
		});
		if (!exists) newValue.push(option.id);
		node.current.style.boxShadow = "none";
		props.onChange(newValue, props.id);
		setSearchValue("");
	};

	const handleShow = (): void => setShowDropdown(!showDropdown);

	/**
	 * This is the onKeyDown check of the input. Right now only checking if
	 * the enter key was pressed.
	 * @param event React Keyboard event on the input. Contains what key is pressed
	 * in plain english (If enter is pressed, event.key will be "Enter")
	 *
	 * @returns Void. Will update the selected state and searchValue state if
	 * the search value is equal to one of the filtered options.
	 */
	const checkKeyPressed = (event: React.KeyboardEvent<HTMLInputElement>): void => {
		switch (event.key.toLowerCase()) {
		case "enter":
			/*
				* If there is only one option in filtered and the user hits enter,
				* we will assume they want that one option and select it.
				*/
			if (filtered?.length === 1) {
				handleValue(filtered[0]);
				// setSearchValue("");
				break;
			}
			if (filtered) {
				for (const option of filtered) {
					if (searchValue.toLowerCase() === option.name.toLowerCase()) {
						handleValue(option);
						// setSearchValue("");
						break;
					}
				}
			}
			break;
		default:
		}
	};

	return (
		<div className={styles.container}>
			<label className={styles.label}>{label}</label>
			<div
				className={bStyles("input-container", {isDarkMode})}
				ref={node}
			>
				{value.length >= 1 ? (
					<div onClick={handleShow} className={bStyles("count-selected", {isDarkMode})}>
						{value.length} selected
					</div>
				)	: null}
				<input
					className={styles["search-field"]}
					onChange={e => setSearchValue(e.target.value)}
					autoComplete={"off"}
					type="text"
					onKeyDown={checkKeyPressed}
					value={searchValue}
					readOnly={!isSearchable}
					onClick={handleShow}
				/>
				<Icon
					clicked={handleShow}
					name="chevron"
					size="medium"
					fill="var(--color-text-body)"
				/>
				<animated.ul style={animatedProps} className={bStyles("dropdown", {isDarkMode})}
					id={`select-${label}`}>
					{filtered.map((option, index) => <li
						key={`${index}-${option.id}`}
						tabIndex={0}
						title={option.name}
						onClick={() => handleValue(option)}
						className={bStyles(
							"list-item",
							{
								selected: value.some(v => v === option.id),
								isDarkMode,
							},
						)}
					>
						{ maxLength ?
							 
							option.name.length > maxLength ? `${option.name.substring(0, maxLength)}...` : option.name
							: option.name
						}
					</li>)}
				</animated.ul>
			</div>
		</div>
	);
}

export {MultiSelect};
