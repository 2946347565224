import React, {ReactElement, useRef} from "react";
import classNames from "classnames/bind";

import {InputProps} from "../input";
import {InputElements} from "../input-elements";
import {currencySymbol} from "../../../../shared/utility/utility";
import {TremendousCurrencyType} from "../../../../models/rewards";
import {Body} from "../../../../shared/v2";

import styles from "../input/input.module.scss";

const cx = classNames.bind(styles);

export type CurrencyInputProps = InputProps<string> & {
	currencyType?: TremendousCurrencyType;
}

const regEx = /^([0-9]{1}\d*){1}(.\d{0,2})?$/;

export const CurrencyInput = ({
	value,
	onChange,
	className,
	size = "small",
	variant = "default",
	label,
	hintText,
	leftIcon,
	rightIcon,
	onBlur,
	currencyType,
	...props
}: CurrencyInputProps): ReactElement => {
	const ref = useRef<HTMLInputElement>(null);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		if (e.target.value === "") {
			onChange(e.target.value);
			return;
		}
		if (!regEx.exec(e.target.value)) return;
		onChange(e.target.value);
	};

	const handleBlur = (): void => {
		onChange(parseFloat(value || "0").toFixed(2));
		onBlur?.(parseFloat(value || "0").toFixed(2));
	};

	const handleFocus = (): void => {
		if (!ref.current) return;
		ref.current.focus();
		ref.current.select();
	};

	const currency = currencySymbol(currencyType || TremendousCurrencyType.USD);

	return <InputElements
		variant={variant}
		className={className}
		label={label}
		hintText={hintText}
		leftIcon={leftIcon}
		rightIcon={rightIcon}
	>
		<Body className={styles.currencySymbol}>{currency}</Body>
		<input
			ref={ref}
			className={cx("input", size, variant, leftIcon && "withLeftIcon", rightIcon && "withRightIcon", "currencyInput", `currency-${currency.length}`)}
			value={value}
			onChange={handleChange}
			onBlur={handleBlur}
			placeholder="0.00"
			onClick={handleFocus}
			{...props}
		/>
	</InputElements>
}
