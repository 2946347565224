/**
 * This file contains queries related to rewards as well as Tremendous integration
 */

import {gql} from "@apollo/client";

// Funding Sources exist on the Workspace.
export const GET_FUNDING_SOURCES = gql`
	query GetFundingSources($id: GUID!) {
		workspace(id: $id) {
			id # Caching!
			tremendousFundingSources {
				id
				method
				meta # ??
			}
		}
	}
`;

// Campaigns exist on the Workspace.
export const GET_TREMENDOUS_CAMPAIGNS = gql`
	query GetTremndousCampaigns($id: GUID!) {
		workspace(id: $id) {
			id # Caching!
			tremendousCampaigns {
				id
				name
				description
			}
		}
	}
`;

export const GET_FUNDING_AND_CAMPAIGNS = gql`
	query GetTremendousFundingAndCampaigns($id: GUID!) {
		workspace(id: $id) {
			id
			tremendousCampaigns {
				id
				name
				description
			}
			tremendousFundingSources {
				id
				method
				meta # ??
			}
		}
	}
`;

export const GET_REWARD_SETTINGS = gql`
	query GetAllRewardSettings($workspaceId: GUID!) {
		workspaceRewardSettings(workspaceId: $workspaceId) {
			rewardType {
				id
				name
				description
			}
			workspaceId
			configuration
			enabledStatus
		}
	}
`;

export const GET_REWARD_SETTINGS_NOCONFIG = gql`
query GetAllRewardSettingsNoConfig($workspaceId: GUID!) {
	workspaceRewardSettings(workspaceId: $workspaceId) {
		rewardType {
			id
			name
			description
		}
		workspaceId
		enabledStatus
	}
}
`;

export const GET_REWARDS_DATA = gql`
	query GetRewardsData(
		$surveyId: GUID
		$workspaceId: GUID
		$limit: PositiveInt 
		$cursor: String, 
		$filter: ResponsesFilter
		$sort: ResponsesSort
	) {
		responses(
			surveyId: $surveyId
			workspaceId: $workspaceId
			limit: $limit 
			cursor: $cursor
			filter: $filter
			sort: $sort,
		) {
			cursor
			remaining
			items {
				id
				completedAt
				surveyId
				user {
					id
					firstName
					lastInitial
					creatorTag
					picture {
						badge:url(version: BADGE)
					}
				}
				rewards {
					items {
						id
						createdAt
						configuration
						status
					}
				}
			}
		}
	}
`;

