import React, {ReactElement} from "react";
import classnames from "classnames/bind";

import {Icon, IconTypes} from "../../";

import styles from "./slider.module.scss";
import classNames, {Argument} from "classnames";

const bStyles = classnames.bind(styles);

interface SliderOption<T> {
	value: T;
	name: string;
	icon?: IconTypes;
	beta?: boolean;
}

interface SliderProps<T> {
	value: T;
	options: SliderOption<T>[];
	className?: Argument;
	onChange: (value: T) => void;
	allowReselect?: boolean;
}


function Slider<T = string>({value, options, className, onChange, allowReselect}: SliderProps<T>): ReactElement {
	return (
		<div className={classNames(styles.container, className)}>
			{options.map(o => (
				<div
					className={bStyles("option", {selected: value === o.value, allowReselect})}
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					key={o.value as any}
					onClick={() => (allowReselect || value !== o.value) && onChange(o.value)}
				>
					{o.icon && <Icon name={o.icon} fill={value === o.value ? "white" : "black"} />}
					{o.name}
					{o.beta && <div className={styles.beta}>Beta</div>}
				</div>
			))}
		</div>
	);
}

export {Slider};
