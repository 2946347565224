import {ApolloError} from "@apollo/client";

export const graphQLErrorFormatter = (
	{message, graphQLErrors}: ApolloError,
	fieldNamesMap: Record<string, string> = {}
): string[] => graphQLErrors.map(
	(error) => {
		const extensionsMessage = error.extensions?.message;

		if (extensionsMessage?.includes("not a valid UUID")) {
			const fieldNameKey = Object.keys(fieldNamesMap).find((key) => error.message.includes(key));
			const fieldName = fieldNameKey && fieldNamesMap[fieldNameKey];

			return fieldName ? `${fieldName} got invalid ID` : "Invalid ID";
		}

		if (extensionsMessage?.includes("was not provided")) {
			const fieldName = extensionsMessage.split('"')[1];

			return `${fieldNamesMap[fieldName] ?? fieldName} can't be empty`;
		}

		return message;
	}
);
