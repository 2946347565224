import React, {ReactElement, useContext, useState} from "react";
import {gql, useMutation} from "@apollo/client";
import {CREATE_ATTRIBUTE_CATEGORY} from "../../graphql/mutations/attribute-mutations";
import {FetchResult} from "apollo-link";
import {ToastContext} from "../../context/toast-context";
import styles from "./attribute-category.module.scss";
import {updateCacheAddItemByFieldId} from "../../shared/utility/update-cache";
import {useWorkspaceContext} from "../../context/workspace-context";
import {BaseModalProps, Button, Input, Modal} from "../../shared/v2";

const AttributeCategoryModal = ({isOpen, onClose}: BaseModalProps): ReactElement => {
	const {updateToast} = useContext(ToastContext);
	const [name, setName] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const {workspace: {id: workspaceId}} = useWorkspaceContext();

	const [createCategory] = useMutation(CREATE_ATTRIBUTE_CATEGORY);

	const closeModal = (): void => {
		setName("");
		setErrorMessage("");
		onClose();
	};
	const onError = (error): void => {
		if (error.message.toString().includes("duplicate key value violates unique constraint")) {
			setErrorMessage("Name already used. Choose a different name.");
		}
	};
	const handleNameChange = (value): void => {
		setName(value);
		setErrorMessage("");
	};

	const createCategoryFunc = async(): Promise<FetchResult> => {
		return createCategory({
			variables: {
				workspaceId,
				name,
			},
			onCompleted: () => updateToast({description: "Created new category", type: "informational"}),
			onError,
			update(cache, {data: createData}) {
				if (!createData) return;
				const ref = cache.writeFragment({
					data: createData.createAttributeCategory,
					fragment: gql`
						fragment NewAttributeCategory on AttributeCategory {
							id
							workspaceId
							name
						}
					`,
				});
				updateCacheAddItemByFieldId(
					cache,
					"attributeCategories",
					ref,
					workspaceId,
					createData.createAttributeCategory.id,
				);
			},
		});
	};

	const handleSave = async(): Promise<void> => {
		if (!name.length) {
			return;
		}

		const result = await createCategoryFunc();

		if (!result.errors) {
			closeModal();
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={closeModal}
			title="New Category"
		>
			<Input
				label="Category Name"
				value={name ?? ""}
				onChange={handleNameChange}
			/>
			<span className={styles.errorMessage}>
				{errorMessage}
			</span>
			<Button
				className={styles.submitButton}
				onClick={handleSave}
				disabled={!name.length || !!errorMessage.length}
			>
				Save
			</Button>
		</Modal>
	);
};

export {AttributeCategoryModal};
