import React, {
	createContext,
	PropsWithChildren,
	ReactElement,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import {useLocation} from "react-router";

import {DARK_MODE_ONLY_ROUTES, NEW_ROUTES_REGEX_STRING} from "../shared/constants/constants";

export interface ThemeContextProps {
	isDarkMode: boolean;
	toggleTheme: () => void;
}

export const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ThemeModeProvider = ({children}: PropsWithChildren<any>): ReactElement => {
	const location = useLocation();
	const themeFromLocalStorage = localStorage.getItem("theme");
	const [innerIsDarkMode, setInnerIsDarkMode] = useState(themeFromLocalStorage === "dark");

	const toggleTheme = ():void => {
		setInnerIsDarkMode(!innerIsDarkMode);
		localStorage.setItem("theme", innerIsDarkMode ? "light" : "dark");
	};

	const isNewRoute = useMemo(
		() => new RegExp(NEW_ROUTES_REGEX_STRING, "g").test(location.pathname),
		[location.pathname],
	);

	const isDarkModeOnlyRoute = useMemo(
		() => new RegExp(DARK_MODE_ONLY_ROUTES, "g").test(location.pathname),
		[location.pathname],
	)

	const isDarkMode = useMemo(
		() => (isNewRoute && innerIsDarkMode) || isDarkModeOnlyRoute,
		[innerIsDarkMode, isNewRoute, isDarkModeOnlyRoute],
	);

	useEffect(() => {
		if (isDarkMode) {
			return document.documentElement.classList.add("dark-theme");
		}
		document.documentElement.classList.remove("dark-theme");
	}, [isDarkMode]);

	return (
		<ThemeContext.Provider value={{isDarkMode, toggleTheme}}>
			{children}
		</ThemeContext.Provider>
	);
};

export const useThemeMode = ():ThemeContextProps => {
	const context = useContext(ThemeContext);

	if (!context) {
		throw new Error("useThemeMode must be used within a ThemeModeProvider");
	}

	return context;
}
