import React, {ReactElement} from "react";

import {Body, Spinner} from "../../../../shared/v2";
import {useThemeMode} from "../../../../context/theme-mode-context";

import styles from "./agent-preview-loader.module.scss";


export const AgentPreviewLoader = (): ReactElement => {
	const {isDarkMode} = useThemeMode();

	return (
		<div className={styles.loaderWrapper}>
			<div className={styles.spinnerWrapper}>
				<Spinner className={styles.spinner} />
				<Body
					className={styles.loadingInfo}
					type="medium"
					size="l"
					color={
						isDarkMode ?
							"gray-modern-500" :
							"charcoal-elevation-100"
					}
				>
          Complete all fields to enable <br /> agent testing.
				</Body>
			</div>
		</div>
	);
}
