import React, {ReactElement} from "react";

import {InitialStep} from "./steps/initial-step";
import {SignInLoginStep} from "./steps/sign-in-login-step";
import {SignInPasswordStep} from "./steps/sign-in-password-step";
import {useSignUpModal} from "../context/sign-up-modal.context";
import {SignUpModalStep} from "../reducer/sign-up-modal-reducer";
import {RecoverPasswordStep} from "./steps/recover-password-step";
import {CheckYourEmailStep} from "./steps/check-your-email-step";
import {SignUpStep} from "./steps/sign-up-step";

import styles from "./login.module.scss";

export const Login = ():ReactElement =>  {
	const {state: {currentStep}} = useSignUpModal();

	const renderStep = () => {
		if (currentStep === SignUpModalStep.Initial) {
			return <InitialStep />
		}

		if (currentStep === SignUpModalStep.Email) {
			return <SignInLoginStep />
		}

		if (currentStep === SignUpModalStep.Password) {
			return <SignInPasswordStep />
		}

		if (currentStep === SignUpModalStep.RecoverPassword) {
			return <RecoverPasswordStep />;
		}

		if (currentStep === SignUpModalStep.CheckYourEmail) {
			return <CheckYourEmailStep />;
		}

		return <SignUpStep />
	}

	return (
		<div className={styles.wrapper}>
			{renderStep()}
		</div>
	);
}
