import {
	ATTRIBUTE_FRAGMENT,
	ATTR_CATEGORY_FRAGMENT,
	ATTR_RULE_FRAGMENT,
} from "../fragments/fragments";
import {gql} from "@apollo/client";

export const CREATE_ATTRIBUTE = gql`
	mutation CreateAttribute(
		$workspaceId: GUID!
		$name: String!
		$description: String
		$type: AttributeType!
		$categoryId: String
	) {
		createAttribute(
			workspaceId: $workspaceId
			name: $name,
			description: $description
			type: $type
			categoryId: $categoryId
		) {
			id
			workspaceId
			name
			description
			type
			category {
				id
				name
			}
			rules {
				...AttrRuleFields
			}
		}
	}
	${ATTR_RULE_FRAGMENT}
`;

export const UPDATE_ATTRIBUTE = gql`
	mutation UpdateAttribute($id: GUID! $changes: UpdateAttributeChanges!) {
		updateAttribute(id: $id changes: $changes) {
			...AttributeFields
		}
	}
	${ATTRIBUTE_FRAGMENT}
`;

export const DELETE_ATTRIBUTE = gql`
	mutation DeleteAttribute($id: GUID!) {
		deleteAttribute(id: $id) {
			id
		}
	}
`;

export const DELETE_ATTRIBUTE_VALUE = gql`
	mutation DeleteAttributeValue($id: GUID!) {
		deleteAttributeValue(id: $id) {
			id
		}
	}
`;

export const CREATE_ATTRIBUTE_RULE = gql`
	mutation CreateRule (
		$attributeId: GUID!
		$questionId: GUID!
		$choiceId: GUID
		$picked: Boolean!
		$action: RuleAction!
		$value: String
	) {
		createAttributeRule (
			attributeId: $attributeId
			questionId: $questionId
			choiceId: $choiceId
			picked: $picked
			action: $action
			value: $value
		) {
			...AttrRuleFields
		}
	}
	${ATTR_RULE_FRAGMENT}
`;

export const UPDATE_ATTRIBUTE_RULE = gql`
	mutation UpdateAttributeRule ($id: GUID! $changes: UpdateAttributeRuleChanges!) {
		updateAttributeRule(id: $id changes: $changes) {
			...AttrRuleFields
		}
	}
	${ATTR_RULE_FRAGMENT}
`;

export const DELETE_ATTRIBUTE_RULE = gql`
	mutation DeleteAttributeRule($id: GUID!) {
		deleteAttributeRule(id: $id) {
			id
		}
	}
`;

export const CREATE_ATTRIBUTE_CATEGORY = gql`
	mutation createAttributeCategory($name: String!, $workspaceId: GUID!) {
		createAttributeCategory(name: $name, workspaceId: $workspaceId) {
			...AttrCategoryFields
		}
	}
	${ATTR_CATEGORY_FRAGMENT}
`;

export const UPDATE_ATTRIBUTE_CATEGORY = gql`
	mutation updateAttributeCategory($id: GUID!, $changes: UpdateAttributeCategoryChanges!) {
		updateAttributeCategory(id: $id, changes: $changes) {
			...AttrCategoryFields
		}
	}
	${ATTR_CATEGORY_FRAGMENT}
`;

export const DELETE_ATTRIBUTE_CATEGORY = gql`
	mutation deleteAttributeCategory($id: GUID!) {
		deleteAttributeCategory(id: $id) {
			...AttrCategoryFields
		}
	}
	${ATTR_CATEGORY_FRAGMENT}
`;
