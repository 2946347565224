import {motion} from "framer-motion";
import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {ButtonIcon, Button} from "../../../shared/v2";
import {SendArrowChatIcon} from "../../../icons";
import {useThemeMode} from "../../../context/theme-mode-context";
import {variants} from "../../chat-view/animation-helpers";

import styles from "./submit-button.module.scss";

const cx = classNames.bind(styles);

export interface SubmitButtonProps {
  context: "home" | "chat";
  onSubmit: () => void;
}

export const SubmitButton = ({context, onSubmit}: SubmitButtonProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	const wrapperVariants = variants({
		home: {
			width: "115px",
		},
		chat: {
			width: "32px",
		},
	}, 2)

	const chatSubmitVariants = variants({
		home: {
			opacity: 0,
		},
		chat: {
			opacity: 1,
		},
	}, 2)

	const homeSubmitVariants = variants({
		home: {
			opacity: 1,
		},
		chat: {
			opacity: 0,
			width: 0,
		},
	}, 2)

	return (
		<motion.div
			className={cx("submitButton", context, {isDarkMode})}
			variants={wrapperVariants}
		>
			{context === "chat" && (
				<motion.div
					className={styles.chatSubmit}
					variants={chatSubmitVariants}
				>
					<ButtonIcon
						className={styles.icon}
						icon={<SendArrowChatIcon />}
						onClick={onSubmit}
					/>
				</motion.div>
			)}
			<motion.div variants={homeSubmitVariants}>
				<Button
					leftIcon={<SendArrowChatIcon />}
					onClick={onSubmit}
					size="small"
				>
          Generate
				</Button>
			</motion.div>
		</motion.div>
	);
}
