import React, {ReactElement} from "react";

import styles from "./Buttons.module.scss";
import {MailIcon} from "../../icons";
import {useSignUpModal} from "../../context/sign-up-modal.context";
import {SignUpModalActionTypes, SignUpModalStep} from "../../reducer/sign-up-modal-reducer";

export interface EmailSignInButtonProps {
	text?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	customData?: any;
	id?: string;
}

export const EmailSignInButton = (
	{text = "Sign in with email", id}: EmailSignInButtonProps,
): ReactElement => {
	const {dispatch} = useSignUpModal();

	return (
		<button
			id={id}
			onClick={() => {
				return dispatch({
					type: SignUpModalActionTypes.SET_CURRENT_STEP,
					payload: {
						step: SignUpModalStep.Email,
					},
				});
			}}
			className={styles.authButton}
		>
			<MailIcon className={styles.authButtonIcon} />
			<span className={styles.authButtonText}>{text}</span>
		</button>
	);
};
