import React, {ReactElement, useContext, useState} from "react";
import {GET_FEEDBACK_SURVEY, GET_FEEDBACK_QUESTIONS} from "../../../graphql/queries/survey-queries";
import {FeedbackQuestionsPageReturn} from "../../../models/questions";
import {LayoutContainer, updateObject} from "../../../shared";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {useLoadingQuery} from "../../../hooks";
import styles from "./brand-questions.module.scss";
import {useMutation} from "@apollo/client";
import {CREATE_FEEDBACK_QUESTION} from "../../../graphql/mutations/brand-mutations";
import {FEEDBACK_QUEST_FRAGMENT} from "../../../graphql/fragments/fragments";
import {
	updateCacheAddPageItem,
	updateCacheDeletePageItem,
} from "../../../shared/utility/update-cache";
import {
	DELETE_QUESTION,
	MOVE_QUESTION,
	UPDATE_QUESTION,
} from "../../../graphql/mutations/survey-mutations";
import {ToastContext} from "../../../context/toast-context";
import {FeedbackCard} from "../../../branding/components/feedback-card";
import {FeedbackSurveyReturn} from "../../../models/survey";
import {ConfirmModal} from "../../../modals/confirm-modal";
import {Button} from "../../../shared/v2";

const BrandQuestions = (): ReactElement => {
	const [questionId, setQuestionId] = useState("");
	const {workspace: {brand}} = useWorkspaceContext();
	const {updateToast} = useContext(ToastContext);

	const {data, fragment} = useLoadingQuery<FeedbackSurveyReturn>(GET_FEEDBACK_SURVEY, {
		skip: !brand?.id,
		variables: {brandId: brand?.id},
		what: "questions",
	});

	const [updateQuestion] = useMutation(UPDATE_QUESTION);
	const [moveQuestion] = useMutation(MOVE_QUESTION);
	const [deleteQuestion] = useMutation(DELETE_QUESTION);
	const [createQuestion] = useMutation(CREATE_FEEDBACK_QUESTION, {
		variables: {surveyId: data?.feedbackSurvey.id},
		update(cache, {data: createData}) {
			if (!createData) return;
			const newRef = cache.writeFragment({
				data: createData.createQuestion,
				fragment: FEEDBACK_QUEST_FRAGMENT,
			});
			updateCacheAddPageItem(cache, newRef, "questions", createData.createQuestion.id);
		},
	});

	const handleCreateQuestion = (): void => {
		createQuestion();
	}

	const {data: questionData, fragment: questFrag} =
	useLoadingQuery<FeedbackQuestionsPageReturn>(GET_FEEDBACK_QUESTIONS, {
		skip: !data || !brand?.id || !data.feedbackSurvey,
		variables: {id: data?.feedbackSurvey?.id},
		what: "questions",
	});

	const handleUpdate = (value: string | number, id: string): void => {
		const changes = typeof value === "string" ? {text: value} : {points: value};
		updateQuestion({
			variables: {id, changes},
			onCompleted: () => updateToast({description: "Question updated", type: "informational"}),
		});
	};

	const handleDeleteQuestion = (): void => {
		deleteQuestion({
			variables: {id: questionId},
			onCompleted: () => {
				setQuestionId("");
				updateToast({description: "Question deleted", type: "informational"});
			},
			update(cache, {data: deleteData}) {
				if (!deleteData) return;
				updateCacheDeletePageItem(cache, "questions", "question", deleteData.deleteQuestion.id);
			},
		});
	};

	const handlePosition = (newPosition: number, currentPosition: number, id: string): void => {
		moveQuestion({
			variables: {id, index: newPosition},
			onCompleted: () => updateToast({description: "Question moved", type: "informational"}),
			update(cache) {
				cache.modify({
					fields: {
						questions(currentQuestions = []) {
							const items = [...currentQuestions.items];
							items.splice(newPosition, 0, items.splice(currentPosition, 1)[0]);
							const updatedQuestions = updateObject(currentQuestions, {
								items,
							});
							return updatedQuestions;
						},
					},
				});
			},
		});
	};


	return (
		<LayoutContainer theme="dark">
			<p className={styles.tip}>* You cannot delete questions that have answers to them</p>
			<Button onClick={handleCreateQuestion}>+ New Question</Button>
			<div className={styles.grid}>
				{fragment || questFrag}
				{questionData?.questions.items.map((question, index) => (
					<FeedbackCard
						key={question.id}
						question={question}
						handleUpdate={handleUpdate}
						otherActions={index !== 0}
						questionCount={questionData.questions.items.length}
						position={index}
						handlePosition={handlePosition}
						handleDelete={setQuestionId}
					/>
				))}
			</div>
			<ConfirmModal
				isOpen={Boolean(questionId)}
				confirm={handleDeleteQuestion}
				onClose={() => setQuestionId("")}
				text="Are you sure you want to delete this question?"
			/>
		</LayoutContainer>
	);
};

export {BrandQuestions};
