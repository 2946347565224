/**
 * Also contains mutations relating to Tremendous integration in general
 */

import {gql} from "@apollo/client";

export const SEND_REWARDS = gql`
	mutation SendRewards(
		$workspaceId: GUID!
		$rewardTypeName: RewardTypeName!
		$rewards: [RewardGroup!]
		$useFilter: Boolean
		$filter: ResponsesFilter
		$settingsForFilteredRewards: FilteredRewardSettings
	) {
		sendRewards(
			workspaceId: $workspaceId
			rewardTypeName: $rewardTypeName
			rewards: $rewards
			useFilter: $useFilter
			filter: $filter
			settingsForFilteredRewards: $settingsForFilteredRewards
		) {
			id
			createdAt
			configuration
			response { # Potentially used for caching
				id
			}
			rewardTransaction {
				id
			}
		}
	}
`;

export const SET_TREMENDOUS_API = gql`
	mutation SetTremendousApiKey($workspaceId: GUID! $apiKey: String!) {
		setTremendousApiKey(workspaceId: $workspaceId apiKey: $apiKey) {
			rewardType {
				id
				name
				description
			}
			configuration
			workspaceId
			enabledStatus
		}
	}
`;

export const DELETE_TREMENDOUS_KEY = gql`
	mutation DeleteTremendousKey($workspaceId: GUID!) {
		deleteTremendousApiKey(workspaceId: $workspaceId) {
			rewardType {
				id
				name
				description
			}
			configuration
			workspaceId
			enabledStatus
		}
	}
`;

export const UPDATE_TREMENDOUS_SETTINGS = gql`
	mutation UpdateTremendousSettings (
		$workspaceId: GUID!
		$defaultFundingId: String
		$defaultCampaignId: String
		$enable: Boolean
		$disable: Boolean
	) {
		updateTremendousRewardSettings(
			workspaceId: $workspaceId
			defaultFundingId: $defaultFundingId
			defaultCampaignId: $defaultCampaignId
			enable: $enable
			disable: $disable
		) {
			rewardType {
				id
				name
				description
			}
			configuration
			workspaceId
			enabledStatus
		}
	}
`;
