/* eslint-disable react/prop-types */

import classNames, {Argument} from "classnames";
import React from "react";
import {Input} from "../input";
import styles from "./input-with-button.module.scss";
import {Button} from "../../../shared/v2";

interface InputWithButtonProps {
	/**
	 * Default value for input, if any
	 */
	defaultValue?: string;
	/**
	 * What the button should do
	 */
	onClick: () => void;
	/**
	 * Button text
	 */
	buttonText: string;
	/**
	 * Color the button should be
	 */
	style?: "brand" | "danger" | "ai";
	/**
	 * Id for the input
	 */
	id: string;
	/**
	 * If the button should be active or not.
	 * If not defined, defaults to true
	 */
	disabled?: boolean;
	buttonDisabled?: boolean;
	/**
	 * Label, if we want one
	 */
	label?: string;
	/**
	 * If input is read only.
	 * If the input is read only, the "onClick" function will also trigger on click
	 * of the input by default.
	 */
	readOnly?: boolean;
	/**
	 * On change trigger (optional)
	 */
	onChange?: () => void;
	/**
	 * If the input and button should be separated.
	 */
	className?: Argument;
}

const InputWithButton = React.forwardRef<HTMLInputElement, InputWithButtonProps>(
	(props,	ref) => {
		const {
			defaultValue,
			onClick,
			style = "brand",
			buttonText,
			id,
			disabled = false,
			buttonDisabled = false,
			label,
			readOnly,
			onChange,
			className,
		} = props;
		return (
			<Input id={id} className={classNames(styles.box, className)}>
				{
					label &&
					<label className={styles.label} htmlFor={id}>
						{label}
					</label>
				}
				<div
					className={[
						styles.container,
						styles.flex,
					].join(" ")}
				>

					<input
						id={id}
						ref={ref}
						style={{cursor: readOnly && !disabled ? "pointer" : "inherit"}}
						className={[
							styles.input,
							styles.inline,
						].join(" ")}
						readOnly={readOnly}
						defaultValue={defaultValue}
						onClick={readOnly ? onClick : undefined}
						onChange={onChange}
						disabled={disabled}
					/>
					{
						<Button
							disabled={buttonDisabled}
							onClick={onClick}
							style={style}
							className={styles.button}
						>
							{buttonText}
						</Button>

					}
				</div>
			</Input>
		);
	},
);

InputWithButton.displayName = "InputWithButton";

export {InputWithButton};
