import {Dropdown, TagInput} from "../../../shared";
import React, {ReactElement, useEffect, useState} from "react";
import {TipBanner} from "../../../shared/components/tip-banner";
import {WorkspaceRole} from "../../../models/filter";
import {useTagInput} from "../../../hooks/useTagInput";
import styles from "./invite-member.module.scss";
import {useDropdown} from "../../../hooks";
import {useSegmentOptions} from "../../../hooks/useSegmentOptions";
import {Button, Body, RadioGroup, Modal, BaseModalProps} from "../../../shared/v2";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

export interface InviteModalProps extends BaseModalProps {
	/**
	 * If we are inviting creators / admins
	 */
	isMemberInvite?: boolean;
	/**
	 * Role of logged in user
	 */
	role?: string;
	handleAddToList?: (emails: string[], segId: string) => void;
	handleInvite: (emails: string[], role?: WorkspaceRole, segId?: string) => void;
}


const InviteModal = React.memo((props: InviteModalProps): ReactElement => {
	const {isOpen, isMemberInvite = false, role, handleInvite, handleAddToList} = props;
	const [emails, setEmails] = useState<string []>([]);
	const [selected, setSelected] = useState<WorkspaceRole>(WorkspaceRole.MANAGER);
	const {anchorEl, handleClick, handleClose} = useDropdown();

	const {
		handleInput,
		handleRemove,
		handlePaste,
		inputRef,
	} = useTagInput(emails, setEmails);

	/**
	 * Sets the input to be empty when it is put on screen.
	 */
	useEffect(() => {
		if (isOpen && emails.length > 0) {
			setEmails([]);
			inputRef.current.innerText = "";
		}
	}, [isOpen]);

	const handleAdd = (id: string): void => {
		props.onClose();
		handleAddToList?.(emails, id);
	};

	const handleInviting = (segmentId?: string): void => {
		props.onClose();
		if (emails.length > 0) {
			handleInvite(emails, isMemberInvite ? selected : undefined, segmentId);
		}
	};

	const actions = useSegmentOptions(handleAdd);

	return (
		<Modal
			title={isMemberInvite ? "Invite Members" : "Add Creators"}
			isOpen={props.isOpen}
			onClose={props.onClose}
		>
			<Body size="xs">
				{isMemberInvite ?
					 
					"Copy/paste email addresses below. This will add new users to your team to help create campaigns, manage creators, and analyze feedback."
				 
					: "Copy/paste email addresses below. If a creator is already on Vurvey, their profile will show as a confirmed contact when saved."
				}
			</Body>

			<TagInput
				tags={emails}
				handleEnter={handleInput}
				ref={inputRef}
				removeTag={handleRemove}
				type="email"
				label="Email Addresses"
				onPaste={handlePaste}
			/>
			{isMemberInvite &&
				<RadioGroup
					size="s"
					onChange={setSelected}
					value={selected}
					label="Add users as:"
					className={styles.radioGroup}
				>
					<RadioGroup.Option
						value={WorkspaceRole.OWNER}
						label="Owner"
						disabled={role !== "OWNER"}
						className={styles.radioOption}
					/>
					<Body
						size="s"
						color="text-tertiary"
						className={cx("radioDescription", {disabled: role != "OWNER"})}
					>
						Manage billing, users, campaigns, and creators
					</Body>

					<RadioGroup.Option
						value={WorkspaceRole.ADMINISTRATOR}
						label="Admin"
						className={styles.radioOption}
					/>
					<Body
						size="s"
						color="text-tertiary"
						className={styles.radioDescription}
					>
						Manage users, campaigns, and creators
					</Body>

					<RadioGroup.Option
						value={WorkspaceRole.MANAGER}
						label="Manager"
						className={styles.radioOption}
					/>
					<Body
						size="s"
						color="text-tertiary"
						className={styles.radioDescription}
					>
						Manage campaigns and see full creator information
					</Body>
				</RadioGroup>
			}
			<div className={styles.bottom}>
				<TipBanner className={styles.banner}>
					🥸 Use commas or press ENTER to separate addresses.
				</TipBanner>
				<div className={styles.actions}>
					{ actions && <>
						<Button
							id="add-to-list"
							disabled={emails.length <= 0}
							onClick={handleClick}
						>
							Add to list
						</Button>
						<Dropdown
							anchorEl={anchorEl} closeMenu={handleClose}
						>
							{actions?.map((action, i) => (
								<Dropdown.Item
									key={`${action.children}+${i}`}
									options={action.options}
									className={action.className}
								>
									{action.children}
								</Dropdown.Item>
							))}
						</Dropdown>
					</>
					}
					<Button
						onClick={handleInviting}
						disabled={emails.length <= 0}
					>
						{isMemberInvite ? "Add Member" : "Add Creators"}
					</Button>
				</div>
			</div>
		</Modal>
	);
});

InviteModal.displayName = "InviteModal";

export {InviteModal};
