import React, {PropsWithChildren, ReactNode} from "react"

import {Tooltip} from "../../../../shared/v2/tooltip"
import {Body} from "../../../../shared/v2/typography"

import styles from "./navigation-tooltip.module.scss"

export type NavigationTooltipProps = PropsWithChildren<{
	text: string;
	children: ReactNode;
}>;

export const NavigationTooltip = ({children, text}: NavigationTooltipProps) => (
	<Tooltip startDelay={2000} placement="bottom" className={styles.tooltip} content={(
		<Body size="xs">
			{text}
		</Body>
	)}>
		<>
			{children}
		</>
	</Tooltip>
);
