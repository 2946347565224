 
import {Dropdown, Icon} from "../../../shared";
import React, {ReactElement, useCallback, useState} from "react";
import {WorkspacePlan} from "../../../models/workspace";
import {WorkspaceTimers} from "../../../shared/constants/constants";
import styles from "./time-selector.module.scss";

export interface TimeSelectorProps {
	/**
	 * WorkspaceID. If this has a value then we are looking at a workspace, otherwise
	 * it is an individual account
	 */
	workspaceId: string | null;
	/**
	 * Plan to compare to in order to see if item should be clickable
	 */
	plan?: WorkspacePlan;
	/**
	 * Current timer (in seconds)
	 */
	currentTime: number | undefined;
	/**
	 * Handles the timer change
	 */
	handleChange: (newTime: number) => void;
}

/**
 * TimeSelector is a component that allows the user to select
 * how much time someone can spend on a video response in the responder app
 */
const TimeSelector = (props: TimeSelectorProps): ReactElement => {
	const {workspaceId, plan, currentTime, handleChange} = props;
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	/**
	 * Handles when user clicks on the opener
	 * @param e React Mouse Event (contains currentTarget)
	 */
	const handleClick = (e: React.MouseEvent<HTMLDivElement>): void => {
		setAnchorEl(e.currentTarget);
	};

	/**
	 * Handles closing the dropdown.
	 */
	const handleClose = (): void => {
		setAnchorEl(null);
	};

	/**
	 * Helps determine if a user can click on an option.
	 * @param badge value of the current option
	 */
	const canClick = (badge: string | undefined): boolean => {
		if (badge === undefined) return true;
		if (plan === "ENTERPRISE") return true;
		if (badge === "PRO" && (plan === "PRO" || plan === "TRIAL")) return true;
		return false;
	};

	/**
	 * Determines if we should show the badge or not on the option
	 * @param badgeValue The badge value
	 */
	const showBadge =
	(badgeValue: "ENTERPRISE" | "PRO" | undefined): "ENTERPRISE" | "PRO" | undefined => {
		if (plan === WorkspacePlan.BASIC) return badgeValue;
		if (plan === WorkspacePlan.PRO && badgeValue === "PRO") return undefined;
		if (
			(plan === WorkspacePlan.PRO || plan === WorkspacePlan.TRIAL) &&
			badgeValue === "ENTERPRISE"
		) return badgeValue;
		return undefined;
	};

	const getOptions = useCallback((): JSX.Element[] | JSX.Element => {
		return WorkspaceTimers.map(value => {
			const clickable = canClick(value.badge);
			return <Dropdown.Item
				key={value.text}
				options={{
					badge: showBadge(value.badge),
					isClickable: clickable,
					onClick: () => handleChange(value.seconds),
				}}
			>
				{value.text}
			</Dropdown.Item>;
		});
	}, [workspaceId, handleChange]);

	const formatSeconds = (): string => {
		if (currentTime === 30) return "30 sec";
		return `${Math.floor(currentTime as number / 60)} min`;
	};
	return (
		<>
			<div className={styles.selector} onClick={handleClick}>
				{formatSeconds()}
				<Icon name="left-arrow" size="tiny" className={styles.icon}/>
			</div>
			<Dropdown
				anchorEl={anchorEl}
				closeMenu={handleClose}
				position="right"
			>
				{getOptions()}
				{
					plan !== WorkspacePlan.ENTERPRISE &&
					<Dropdown.Item
						options={{
							isLink: true,
							linkTo: {
								pathname: "/workspace/manage-plan",
								search: `workspaceId=${workspaceId}`,
							},
						}}
					>
						Upgrade plan
					</Dropdown.Item>
				}
			</Dropdown>
		</>
	);
};

export {TimeSelector};
