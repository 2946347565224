import {gql} from "@apollo/client";

export const GET_SURVEY_TEMPLATES = gql`
	query GetSurveyTemplates {
		surveyTemplates {
			items {
				id
				title
				templateDescription
				templateHeadline
				questionCount
			}
		}
	}
`;

export const GET_SURVEY_TEMPLATES_WITH_CATEGORIES = gql`
	query GetSurveyTemplates {
		surveyTemplates {
			items {
				id
				title
				templateDescription
				templateHeadline
				questionCount
				categories {
					id
					name
				}
			}
		}
	}
`;

export const GET_SURVEY_TEMPLATE_CATEGORY_GROUPS_WITH_TEMPLATES = gql`
	query GetSurveyTemplateCategoryGroups {
		surveyTemplateCategoryGroups {
			items {
				id
				name
				categories {
					name
					id
					templates {
						id
						title
						templateDescription
						templateHeadline
						questionCount
						incentiveAmount
						incentiveCurrency
						estimatedCompletionTime
					}
				}
			}
		}
	}
`;

export const GET_SURVEY_TEMPLATE_CATEGORIES = gql`
	query SurveyTemplateCategories {
		surveyTemplateCategories {
			items {
				id
				name
			}
		}
	}
`;
