import {CHAT_MESSAGE_LOADING_MARKER} from "../../../shared/constants/constants";
import {ChatConversationMessage} from "../../../models/ai-model";
import {Persona} from "../../../models";

export const generateChatMessageLoading = (data: {conversationId?: string, persona?: Persona}) => {
	return {
		id: `${CHAT_MESSAGE_LOADING_MARKER}${Date.now()}`,
		...data,
	} as ChatConversationMessage;
}
