import React, {ReactElement, useMemo, useState} from "react";
import {useQuery} from "@apollo/client";

import {AddTrainingSetModal} from "../../../datasets/modals";
import {Button, Caption, LoadingContainer, SearchInput} from "../../../shared/v2";
import {CreateCard} from "../../../shared/v2/cards";
import {DatasetCard} from "../../../datasets/components";
import {GET_TRAINING_SETS} from "../../../graphql/queries/ai-models-queries";
import {Link} from "../../../hoc/layout/navigation/link";
import {PlusSmallIcon} from "../../../icons";
import {TrainingSet} from "../../../models/ai-model";
import {useMount} from "../../../hooks/useMount";
import {useWorkspaceContext} from "../../../context/workspace-context";

import styles from "./all-datasets-page.module.scss";

export const AllDatasetsPage = (): ReactElement => {
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	const [searchValue, setSearchValue] = useState<string>("");

	const {
		data: traningSetData,
		loading,
	} = useQuery(GET_TRAINING_SETS, {
		variables: {
			workspaceId,
		},
	});

	const trainingSets = traningSetData?.trainingSets || [];

	const sortedTrainingSets = useMemo(() => {
		return [...trainingSets]?.sort((a, b) => a.alias.localeCompare(b.alias));;
	}, [trainingSets]);

	const filteredTrainingSets = useMemo(() => {
		if (!searchValue) {
			return sortedTrainingSets;
		}

		return sortedTrainingSets.filter((trainingSet: TrainingSet) => {
			return trainingSet.alias.toLowerCase().includes(searchValue.toLowerCase());
		});
	}, [searchValue, sortedTrainingSets]);

	useMount(() => {document.title = "Vurvey - Datasets"})

	const renderContent = (): ReactElement => {
		if (loading) return <LoadingContainer />;

		return <>
			<div className={styles.trainingSets}>
				<CreateCard wrapperClassname={styles.cardWrapper}>
					<Button
						style="brand"
						leftIcon={<PlusSmallIcon />}
						onClick={() => setIsAddModalOpen(true)}
					>
						Create Dataset
					</Button>
					<Caption color="text-secondary" type="regular">
						We keep your <Link
							to="https://help.vurvey.com/en/collections/9210870-ai-data-security-compliance"
							type="underline"
							text="data safe"
						/>
					</Caption>
				</CreateCard>

				{filteredTrainingSets.map((trainingSet: TrainingSet) => (
					<DatasetCard key={trainingSet.id} trainingSet={trainingSet} />
				))}
			</div>

			<AddTrainingSetModal
				isOpen={isAddModalOpen}
				onClose={() => setIsAddModalOpen(false)}
			/>
		</>;
	};

	const handleSearchChange = (value: string):void => {
		setSearchValue(value);
	};

	return <div className={styles.fullWidth}>
		<SearchInput
			className={styles.searchInput}
			value={searchValue}
			onChange={handleSearchChange}
		/>
		{renderContent()}
	</div>
};
