import {useEffect, useState} from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useIsObjectChanged = (object: object, resetOnChange: any[]): boolean => {
	const [hasChanged, setHasChanged] = useState<boolean>(false);
	const [original, setOriginal] = useState<object>(object);

	const reset = (): void => {
		setHasChanged(false);
		setOriginal(object);
	};

	useEffect(() => {
		const isChanged =
      Object.keys(object).some(key => object[key] !== original[key]) &&
      Object.keys(original).length === Object.keys(object).length;

		setHasChanged(isChanged);
	}, [object, original]);

	useEffect(() => {
		reset();
	}, resetOnChange);

	return hasChanged;
};
