import React, {ReactElement, useMemo} from "react";
import classNames from "classnames/bind";

import {Body, Subheader} from "../../../shared/v2/typography";
import {Separator} from "../../../shared/v2/separator";
import {Status} from "./status";
import {Badge} from "./badge";
import {useThemeMode} from "../../../context/theme-mode-context";
import {AIModel} from "models/ai-model";
import {
	BrandAnthropicIcon,
	BrandGoogleIcon,
	BrandVurveyBlueIcon,
	BrandVurveyIcon,
} from "../../../icons";

import styles from "./model-card.module.scss";

const bStyles = classNames.bind(styles);

export interface ModelCardProps {
	model: AIModel;
}

export const ModelCard = ({model}: ModelCardProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	const icon = useMemo(() => {
		if (model.provider === "Google") {
			return <BrandGoogleIcon />;
		}
		if (model.provider === "Anthropic") {
			return <BrandAnthropicIcon className={bStyles("anthropicIcon", {isDarkMode})} />;
		}
		return isDarkMode ? <BrandVurveyIcon /> : <BrandVurveyBlueIcon />;
	}, [model.provider, isDarkMode]);

	return <div className={styles.modelCard}>
		<div className={styles.infoContainer}>
			<div className={styles.badges}>
				<Badge text={model.task} />
				<Badge text={model.modality} />
			</div>

			<div className={styles.contentWrapper}>
				<Subheader
					size="l"
					type="semibold"
					className={styles.header}
				>
					{model.name}
				</Subheader>
				<Separator color="border-soft" />
				<Body size="s" className={styles.description} color="text-secondary">
					{model.description}
				</Body>
			</div>
		</div>
		<div>
			<Separator />
			<div className={styles.statusProvider}>

				<Status status={model.status} />

				{icon}
			</div>
		</div>

	</div>;
};
