import {Persona} from "../models";
import {PersonaDefaultVoice, PersonaModel, PersonaPicture} from "../models/persona";

export enum AgentCreationReducerActionTypes {
  UPDATE = "UPDATE",
  RESET = "RESET",
}

export interface AgentCreationState extends Pick<Persona, "name" | "description"> {
  background: string;
  categoryId: string;
  continuouslyLearning: boolean;
  instructions: string;
  picture?: PersonaPicture | null;
  temperature: number;
  model: string;
  personaTypeId: string;
  voiceId?: string;
  voiceName?: string;
  skillIds: string[];
}

export const AGENT_INITIAL_STATE: AgentCreationState = {
	background: "",
	categoryId: "",
	continuouslyLearning: false,
	description: "",
	instructions: "",
	name: "",
	model: PersonaModel.GEMINI,
	personaTypeId: "",
	temperature: 0.5,
	voiceId: PersonaDefaultVoice.ID,
	voiceName: PersonaDefaultVoice.NAME,
	skillIds: [],
};

export type AgentCreationUpdateInput = Partial<AgentCreationState>;

export type AgentCreationAction =
  {
    type: AgentCreationReducerActionTypes.UPDATE,
    payload: AgentCreationUpdateInput
  } |
  {
    type: AgentCreationReducerActionTypes.RESET,
    payload?: Persona
  };

export type AgentCreationReducerType = (state: AgentCreationState, action: AgentCreationAction) => AgentCreationState;

export const agentCreationReducer: AgentCreationReducerType = (state, action) => {
	switch (action.type) {
	case AgentCreationReducerActionTypes.UPDATE: {
		return {
			...state,
			...action.payload,
		};
	}
	case AgentCreationReducerActionTypes.RESET: {
		const persona = action.payload;

		if (!persona) {
			return {...AGENT_INITIAL_STATE};
		};

		return {
			background: persona?.parsedInstructions?.system_prompt ?? "",
			categoryId: persona?.personaCategory?.id ?? "",
			continuouslyLearning: false,
			description: persona?.description ?? "",
			instructions: persona?.parsedInstructions?.chat_prompt ?? "",
			model: persona?.parsedInstructions?.model ?? PersonaModel.GEMINI,
			name: persona?.name ?? "",
			personaTypeId: persona?.personaType?.id ?? "",
			picture: persona?.picture ?? undefined,
			temperature: persona?.parsedInstructions?.temperature ?? 0.5,
			voiceId: persona?.voiceId ?? PersonaDefaultVoice.ID,
			voiceName: persona?.voiceName ?? PersonaDefaultVoice.NAME,
			skillIds: persona?.skills?.map((skill) => skill.id) ?? [],
		};
	}
	default:
		return state;
	}
};
