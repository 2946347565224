import React, {ReactElement, useState} from "react";
import {Icon} from "../icon";
import {BetterTooltip as Tooltip} from "../tooltip";
import styles from "./help-tooltip.module.scss";

export interface HelpTooltipProps {
	id: string;
	children: React.ReactNode;
}

const HelpTooltip = ({id, children}: HelpTooltipProps): ReactElement => {
	const [el, setEl] = useState<HTMLElement | null>(null);
	return (
		<>
			<span
				className={styles.anchor}
				onMouseOver={e => setEl(e.currentTarget)}
				onMouseOut={() => setEl(null)}
			>
				<Icon	name="info" size="extrasmall" fill="var(--batterii-purple)" />
			</span>
			<Tooltip id={id} anchorEl={el} position="right" showArrow>
				{children}
			</Tooltip>
		</>
	);
};

export {HelpTooltip};
