import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import {
	DocumentsFolderFileIcon,
	HornMegaphoneCampaignIcon,
	PersonaIcon,
	PhotoImageIcon,
} from "../../../icons";
import {Dropdown} from "../../../shared/v2";
import {SelectCampaignModal} from "../../select-campaign-modal";
import {SelectPersonaModal} from "../../select-persona-modal";
import {SelectTrainingSetModal} from "../../select-training-set-modal";
import {TrainingSet} from "../../../models";
import {useChatImageContext} from "../../../context/chat-contexts/chat-image-context";
import {useChatCampaignContext, useChatPersonaContext, useChatTrainingSetContext} from "../../../context/chat-contexts";
import {useFileUploader} from "../../useFileUploader";
import {useModalActions} from "../../../hooks";

import styles from "./actions-dropdown.module.scss";

const bStyles = classNames.bind(styles);

export interface ActionsDropdownProps {
  children: ReactElement;
  className?: string;
  
}

export const ActionsDropdown = ({children, className}: ActionsDropdownProps): ReactElement => {
	const {
		activeTrainingSets,
		saveTrainingSetIds,
		isUpdatingDisabled: isTrainingSetUpdatingDisabled,
	} = useChatTrainingSetContext();
	const {
		isUpdatingDisabled: isCampaignUpdatingDisabled,
	} = useChatCampaignContext();
	const {
		isUpdatingDisabled: isPersonaUpdatingDisabled,
	} = useChatPersonaContext();
	const {uploadImage} = useChatImageContext();

	const {
		value: isSelectPersonaModalOpen,
		open: openSelectPersonaModal,
		close: closeSelectPersonaModal,
	} = useModalActions();

	const {
		value: isSelectCampaignModalOpen,
		open: openSelectCampaignModal,
		close: closeSelectCampaignModal,
	} = useModalActions();

	const {
		value: isSelectTrainingSetModalOpen,
		open: openSelectTrainingSetModal,
		close: closeSelectTrainingSetModal,
	} = useModalActions();

	const {modals: fileUploadModals/*, openUploadModal*/} = useFileUploader({
		setTrainingSet: (trainingSet: TrainingSet) => {
			saveTrainingSetIds([...(activeTrainingSets?.map((trainingSet) => trainingSet.id) || []), trainingSet.id]);
		},
	});

	return (
		<>
			<Dropdown
				className={bStyles("actionsDropdown", className)}
				position="bottom-start"
				space={12}
				trigger={children}
				ariaLabel="plus-button"
				items={[
					{
						label: "Choose Agent",
						icon: <PersonaIcon />,
						disabled: isPersonaUpdatingDisabled,
						onClick: openSelectPersonaModal,
					},
					{
						label: "Sources",
						items: [
							{
								label: "Include Campaign",
								disabled: isCampaignUpdatingDisabled,
								icon: <HornMegaphoneCampaignIcon />,
								onClick: openSelectCampaignModal,
							},
							{
								label: "Include Dataset",
								disabled: isTrainingSetUpdatingDisabled,
								icon: <DocumentsFolderFileIcon />,
								onClick: openSelectTrainingSetModal,
							},
							{
								label: "Upload Image",
								icon: <PhotoImageIcon />,
								onClick: uploadImage,
							},
						]
					}
				]}
			/>

			<SelectPersonaModal
				isOpen={isSelectPersonaModalOpen}
				onClose={closeSelectPersonaModal}
			/>

			<SelectCampaignModal
				isOpen={isSelectCampaignModalOpen}
				onClose={closeSelectCampaignModal}
			/>

			<SelectTrainingSetModal
				isOpen={isSelectTrainingSetModalOpen}
				onClose={closeSelectTrainingSetModal}
			/>

			{fileUploadModals}
		</>
	);
}
