import {ApolloCache} from "@apollo/client";
import {updateObject} from "./utility";

/**
 * @param cache ApolloCache object we are modifying
 * @param id ID of the answer
 * @param answer AnswerRef to remove from our cache
 * @returns a boolean on success or fail.
 */
export const updateFromDeletion = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	cache: ApolloCache<any>,
	id: string,
): boolean => {
	cache.modify({
		fields: {
			answers(existingAnswers, {readField}) {
				const updatedAnswers = updateObject(existingAnswers, {
					items: existingAnswers.items.filter(
						answerRef => id !== readField("id", answerRef),
					),
				});
				return updatedAnswers;
			},
		},
	});
	return cache.evict({id});
};
