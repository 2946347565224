import React, {ReactElement, useState} from "react";
import {useMutation} from "@apollo/client";

import {BaseModalProps, Button, Input, Modal} from "../../../shared/v2";
import {ChatConversation} from "../../../models";
import {ChatUpdateConversationResponse} from "../../../context/chat-contexts";
import {CHAT_UPDATE_CONVERSATION} from "../../../graphql/mutations/ai-mutations";
import {useToastContext} from "../../../context/toast-context";
import {useValidation} from "../../../hooks/useValidation";

export interface EditChatModal extends BaseModalProps {
	conversation: ChatConversation;
}

export const EditChatModal = ({
	isOpen,
	onClose,
	conversation,
}: EditChatModal): ReactElement => {
	const {updateToast} = useToastContext();

	const [changes, setChanges] = useState({
		name: conversation?.name || "",
	});

	const {results: validation, validate} = useValidation(changes, {
		name: {
			ruleset: {
				required: true,
				max: 150,
				custom: (value: string) => {
					return value.replace(/\s/g,'').length > 0;
				}
			},
			customErrorMessage: {
				custom: () => "Name cannot be empty."
			}
		},
	});

	const [chatUpdateConversation, {loading: isUpdating}] =
		useMutation<ChatUpdateConversationResponse>(CHAT_UPDATE_CONVERSATION);

	const handleSave = ():void => {
		if (!validate()) {
			return;
		}

		chatUpdateConversation({
			variables: {
				conversationId: conversation?.id,
				changes: changes,
			},
			onError: error => {
				updateToast({
				  type: "failure",
				  description: error.message,
				});
			},
			onCompleted: () => {
				onClose();
				updateToast({
					type: "success",
					description: "Conversation updated successfully.",
				});
			}
		});
	};

	return <Modal
		title="Rename conversation"
		isOpen={isOpen}
		onClose={onClose}
		size="x-small"
	>

		<Input
			label="Name"
			value={changes.name}
			onChange={value => setChanges({name: value})}
			placeholder="Enter a name for this conversation"
			validation={validation.name}
		/>
		<Button
			disabled={isUpdating}
			onClick={handleSave}
		>
			Save
		</Button>
	</Modal>
};
