/* eslint-disable react/prop-types */
import {BugsnagErrorBoundary} from "@bugsnag/plugin-react";
import React, {ReactElement, useEffect} from "react";

import {useErrorBoundaryContext} from "../../context/error-boundary-context";

interface ClearErrorProps {
  clearError: () => void;
  children: ReactElement;
}

export const ClearError = ({clearError, children}: ClearErrorProps): ReactElement => {
	useEffect(() => clearError, []);

	return children;
}

export const ErrorBoundary: BugsnagErrorBoundary = ({FallbackComponent, ...props}): ReactElement => {
	const {ErrorBoundary: Component} = useErrorBoundaryContext();

	return (
		<Component
			FallbackComponent={
				FallbackComponent ?
					({clearError, ...renderProps}) => (
						<ClearError clearError={clearError}>
							<FallbackComponent clearError={clearError} {...renderProps} />
						</ClearError>
					) : undefined}
			{...props}
		/>
	);
}
