import {first} from "lodash-es";
import React, {ReactElement, cloneElement, useState} from "react";

import classNames from "classnames/bind";
import {Icon} from "../../../shared/components/icon";
import {Body} from "../typography";

import styles from "./avatar.module.scss";

const bStyles = classNames.bind(styles);

export interface AvatarProps {
	size?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | "xxxl";
	type?: "circle" | "square";
	url?: string;
	firstName?: string;
	lastInitial?: string;
	additionalIcon?: ReactElement;
  additionalContent?: ReactElement;
	className?: string;
}

export const Avatar = ({
	additionalContent,
	additionalIcon,
	firstName,
	lastInitial,
	size = "md",
	type = "circle",
	url,
	className,
}: AvatarProps): ReactElement => {
	const [imgSrc, setImgSrc] = useState(url);
	const [retryCount, setRetryCount] = useState(0);

	const handleError = () => {
		if (retryCount < 3) {
			setTimeout(() => {
				const updatedUrl = `${imgSrc}?retry=${Date.now()}`;
				setImgSrc(updatedUrl);
				setRetryCount(retryCount + 1);
			}, 1000);
		}
	};

	const renderContent = () => {
		if (url === "/images/vurvey-light.svg") {
			return <img src={url} alt="avatar" className={bStyles("image", type, size)} />;
		}

		if (url) {
			return <img src={imgSrc} alt="avatar" className={bStyles("image", type, size)} onError={handleError} />;
		}

		if (firstName && lastInitial) {
			return (
				<Body className={bStyles("initials", size)}>
					{first(firstName)} {lastInitial}
				</Body>
			);
		}

		if (firstName) {
			return <Body className={bStyles("initials", size)}>{first(firstName)?.toUpperCase()}</Body>;
		}

		return <Icon name="person" />;
	};

	return (
		<div className={bStyles("avatar", size, type, className, {noImage: !url})}>
			{renderContent()}
			{additionalIcon && cloneElement(additionalIcon, {className: bStyles("icon", size)})}
			{additionalContent &&
        <div className={styles.additionalContent}>
        	{additionalContent}
        </div>
			}
		</div>
	);
};
