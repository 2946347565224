import React, {ReactElement, useEffect, useState} from "react";
import {useMutation} from "@apollo/client";

import {Body, Button, Modal, NumberInput, Switch} from "../../../../../shared/v2";
import {UPDATE_WORKSPACE} from "../../../../../graphql/mutations/workspace-settings-mutations";
import {useWorkspaceContext} from "../../../../../context/workspace-context";

import styles from "./force-logout-modal.module.scss";

interface ForceUserLogoutModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ForceUserLogoutModal = (props: ForceUserLogoutModalProps): ReactElement => {
	const {workspace} = useWorkspaceContext();
	const {isOpen, onClose} = props;

	const [enabled, setEnabled] = useState(workspace.forceLogout || false);
	const [time, setTime] = useState(workspace.forceLogoutPeriodMin || 15);

	const handleToggle = (checked: boolean) => {
		setEnabled(checked);
	};

	const handleTimeChange = (value: number) => {
		setTime(value);
	};

	const [updateWorkspace] = useMutation(UPDATE_WORKSPACE);

	useEffect(() => {
		setEnabled(workspace?.forceLogout || false);
	}, [isOpen, workspace]);

	const handleSave = async () => {
		await updateWorkspace({
			variables: {
				id: workspace.id,
				changes: {forceLogout: enabled, forceLogoutPeriodMin: time},
			},
		});

		onClose();
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title="Session Timeout"
			description="Set the time after which a user will be logged out automatically if no activity is detected."
			size="x-small"
		>
			<div className={styles.forceLogoutControl}>
				<Switch checked={enabled} onChange={handleToggle} />
				<div className={styles.timeWrapper}>
					<NumberInput
						value={time}
						onChange={handleTimeChange}
						disabled={!enabled}
						className={styles.timePeriod}
					/>
					<Body size="s">minutes</Body>
				</div>
			</div>

			<div className={styles.buttons}>
				<Button className={styles.button} variant="outlined" onClick={onClose}>
          Cancel
				</Button>
				<Button className={styles.button} onClick={handleSave}>
          Save
				</Button>
			</div>
		</Modal>
	);
};
