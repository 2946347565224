/* eslint-disable react/prop-types */

import React, {
	ChangeEvent,
	useCallback,
	useEffect,
	useState,
} from "react";
import classNames from "classnames/bind";
import {getCurrent} from "../../../ref";
import styles from "./multirange-slider.module.scss";

const slideStyles = classNames.bind(styles);
interface MultiRangeSliderProps {
	min: number;
	max: number;
	minStartVal: number;
	maxStartVal: number;
	onChange: (min: number, max: number) => void;
	handleUpdate?: (start: number, end: number) => void;
	isDisabled?: boolean;
}

const MultiRangeSlider = React.forwardRef<HTMLDivElement, MultiRangeSliderProps>((props, ref) => {

	const {min, max, maxStartVal, minStartVal, isDisabled = false, onChange, handleUpdate} = props;
	const [minVal, setMinVal] = useState<number>(minStartVal);
	const [maxVal, setMaxVal] = useState<number>(maxStartVal);

	/**
	 * Convert to a percentage
	 */
	const getPercent = useCallback(
		(value: number) => Math.round(((value - min) / (max - min)) * 100),
		[min, max, minVal, maxVal],
	);

	// Set width of the range
	useEffect(() => {
		const minPercent = getPercent(minVal);
		const maxPercent = getPercent(maxVal);
		const current = getCurrent(ref);

		if (current) {
			current.style.left = `${minPercent}%`;
			current.style.width = `${maxPercent - minPercent}%`;
		}
	}, [minVal, maxVal, getPercent]);

	/**
	 * Use effect to update based on change to the slider values
	 */
	useEffect(() => {
		onChange(minVal, maxVal);
	}, [minVal, maxVal]);

	useEffect(() => {
		if (minVal !== minStartVal) setMinVal(minStartVal);
		if (maxVal !== maxStartVal) setMaxVal(maxStartVal);
	}, [minStartVal, maxStartVal]);


	const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>): void => {
		if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
			handleUpdate?.(minVal, maxVal);
		}
	};

	/**
	 * Handles when user is done dragging one of the handles.
	 */
	const handleMouseUp = (): void => {
		handleUpdate?.(minVal, maxVal);
	};

	return (
		<>
			<input
				type="range"
				min={min}
				max={max}
				value={minVal}
				disabled={isDisabled}
				onMouseUp={handleMouseUp}
				onKeyUp={handleKeyUp}
				className={slideStyles("thumb", "thumb-left", {disabled: isDisabled})}
				style={{zIndex: minVal > max - 100 ? 5 : 3}}
				onChange={(event: ChangeEvent<HTMLInputElement>) => {
					const value = Math.min(Number(event.target.value), maxVal - 1);
					setMinVal(value);
				}}
			/>
			<input
				type="range"
				min={min}
				max={max}
				value={maxVal}
				disabled={isDisabled}
				onMouseUp={handleMouseUp}
				onKeyUp={handleKeyUp}
				className={slideStyles("thumb", "thumb-right", {disabled: isDisabled})}
				onChange={(event: ChangeEvent<HTMLInputElement>) => {
					const value = Math.max(Number(event.target.value), minVal + 1);
					setMaxVal(value);
				}}
			/>
			<div className={styles.slider}>
				<div className={styles["slider-track"]}/>
				<div ref={ref} className={slideStyles("slider-range", {disabled: isDisabled})}/>
			</div>
		</>
	);
});

MultiRangeSlider.displayName = "MultiRangeSlider";

export {MultiRangeSlider};
