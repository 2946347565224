import React, {ReactElement, useContext, useState} from "react";
import {gql, useApolloClient, useQuery} from "@apollo/client";

import {Button, Spinner, SearchInput, Body, Checkbox, Modal, BaseModalProps} from "../../shared/v2";
import {determineQuestionType} from "../../models/questions";
import {GET_QUESTION_TEMPLATES} from "../../graphql/queries/question-template-queries";
import {GET_SURVEY} from "../../graphql/queries/survey-queries";
import {Icon, Option, Select, TableGrid} from "../../shared";
import {QUESTION_FRAGMENT} from "../../graphql/fragments/fragments";
import {QUESTION_TYPES} from "../../shared/constants/constants";
import {QuestionTemplatePageData} from "../../models/question-template";
import {SearchableFilter} from "../../shared/components/searchable-filter";
import {SurveyContext} from "../../context/survey-context";
import {useNavigate} from "../../route";
import {useWorkspaceContext} from "../../context/workspace-context";

import styles from "./question-templates.module.scss";

const QUESTION_TEMPLATES_SORT: Option[] = [
	{text: "Sort by Most Used", value: "DEFAULT"},
	{text: "Sort by Oldest", value: "OLDEST_FIRST"},
	{text: "Sort by Newest ", value: "NEWEST_FIRST"},
];

const QUESTION_SOURCE: Option[] = [
	{text: "All Authors", value: "ALL"},
	{text: "This Workspace", value: "WORKSPACE"},
	{text: "Vurvey", value: "GLOBAL"},
];

export const QuestionTemplatesModal = (props: BaseModalProps): ReactElement => {
	const apolloClient = useApolloClient();
	const {isOpen, onClose} = props;

	const {survey: {id: surveyId}} = useContext(SurveyContext);
	const {workspace: {name, id: workspaceId}} = useWorkspaceContext();
	const navigate = useNavigate();

	const questionTypeKeys = Object.keys(QUESTION_TYPES);
	const QUESTION_TYPES_OPTIONS = questionTypeKeys.map(t => {
		return {name: QUESTION_TYPES[t].text, id: t, icon: QUESTION_TYPES[t].icon};
	});

	/**
	 * Currently there is no type for a video upload question and pdf question. We'll need to put
	 * that type in the backend. For now, we're just removing it so the library works.
	 */
	const types = Object.keys(QUESTION_TYPES);
	types.pop(); // Gets rid of VIDUPLOAD
	types.pop(); // Gets rid of PDF
	const [search, setSearch] = useState("");
	const [searchValue, setSearchValue] = useState("");
	const [sort, setSort] = useState("DEFAULT");
	const [questionTypes, setQuestionTypes] = useState(types);
	const [templateSource, setTemplateSource] = useState("ALL");

	const [selectedTemplateIds, setSelectedTemplateIds] = useState<string[]>([]);

	const {data: questionTemplatesData, loading} =
	useQuery<QuestionTemplatePageData>(GET_QUESTION_TEMPLATES, {
		skip: !isOpen,
		variables: {
			workspaceId,
			excludeWorkspace: (templateSource === "GLOBAL") ? true : undefined,
			excludeGlobals: (templateSource === "WORKSPACE") ? true : undefined,
			sort,
			filter: {
				type: questionTypes,
				search,
			},
		},
	});

	const [isAddingQuestions, setIsAddingQuestions] = useState<boolean>(false);

	const handleSelect = (questionId: string) :void=> {
		if (selectedTemplateIds.includes(questionId)) {
			setSelectedTemplateIds(selectedTemplateIds.filter(id => id !== questionId));
		} else {
			setSelectedTemplateIds([...selectedTemplateIds, questionId]);
		}
	};

	const closeModal = () :void=> {
		setSelectedTemplateIds([]);
		setSearchValue("");
		setSearch("");
		setQuestionTypes(questionTypeKeys);
		setSort("DEFAULT");
		onClose();
	};

	// We have to generate the mutation dynamically to send just one request
	// for all selected templates.
	// createQuestionFromTemplate accepts just an id, so there is no other way of doing this,
	//  except calling the mutation for each template and this solution
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleCreateQuestionFromTemplate = async(): Promise<any>=> {
		const params = selectedTemplateIds.map((_id, index) => `$templateId${index}: GUID!`)
			.join(", ");
		const mutationBody = selectedTemplateIds.map((_id, index) => {

			return `templateId${index}: createQuestionFromTemplate(templateId: $templateId${index}, surveyId: $surveyId){
				...QuestionFields
			}`;
		}).join("\n");


		const CREATE_QUESTION_FROM_TEMPLATE = gql`
			mutation CreateQuestionsFromTemplate($surveyId: GUID!, ${params}) {
				${mutationBody}
			}
			${QUESTION_FRAGMENT}
		`;

		const mappedTemplates = selectedTemplateIds.map((id, index) => {
			return {
				key: `templateId${index}`,
				value: id,
			};
		});

		const variables = mappedTemplates.reduce((obj, item) =>
			Object.assign(obj, {[item.key]: item.value}), {});

		setIsAddingQuestions(true);
		const result = await apolloClient.mutate({
			mutation: CREATE_QUESTION_FROM_TEMPLATE,
			variables: {...variables, surveyId},
			refetchQueries: [
				{
					query: GET_SURVEY,
					variables: {id: surveyId, workspaceId},
				},
			],
		});

		const lastItem = result.data[`templateId${selectedTemplateIds.length - 1}`];

		setIsAddingQuestions(false);
		closeModal();
		navigate(`/survey/${surveyId}/questions/${lastItem.id}`, {workspace: true});
	};

	const handleTypesFilter = (selected: {id: string, name: string}): void => {
		if (questionTypes.includes(selected.id)) {
			setQuestionTypes(questionTypes.filter((id: string) => id !== selected.id));
		} else {
			setQuestionTypes([...questionTypes, selected.id]);
		}
	};

	const handleSearch = () :void => {
		setSearch(searchValue);
	};

	const selectedLabel = questionTypes.length === questionTypeKeys.length ?
		"All Types" : questionTypes.length === 1 ? "1 type" : `${questionTypes.length} types`;

	return (
		<Modal
			isOpen={isOpen}
			onClose={closeModal}
			size="large"
			title="Saved Questions"
		>
			<div className={styles.modalContainer}>

				<div className={styles.questionTemplatesContainer}>
					<div className={styles.searchRow}>
						<SearchInput
							className={styles.search}
							id="search-input"
							value={searchValue}
							onChange={setSearchValue}
							onEnter={handleSearch}
						/>

						<SearchableFilter
							className={styles.select}
							options={QUESTION_TYPES_OPTIONS}
							value={questionTypes}
							selectedLabel={selectedLabel}
							onChange={handleTypesFilter}
							placeholder="Select Types"
						/>

						<Select
							className={styles.sort}
							id="sort-select"
							options={QUESTION_TEMPLATES_SORT}
							onChange={setSort}
							selectedValue={sort}
						/>
						<Select
							id="author-select"
							className={styles.sort}
							options={QUESTION_SOURCE}
							selectedValue={templateSource}
							onChange={setTemplateSource}
						/>
					</div>
					{
						questionTemplatesData?.questionTemplates?.items?.length ? <>
							<TableGrid size="medium" className={styles.table}>
								<TableGrid.Header>
									<TableGrid.Cell cols="five">
											Questions ({questionTemplatesData?.questionTemplates?.items?.length})
									</TableGrid.Cell>
									<TableGrid.Cell>Author</TableGrid.Cell>
									<TableGrid.Cell>Type</TableGrid.Cell>
									<TableGrid.Cell>Used</TableGrid.Cell>
								</TableGrid.Header>

								{loading && (
									<Spinner />
								)}

								{questionTemplatesData?.questionTemplates?.items?.map(questionTemplate =>
									<TableGrid.Row className={styles.questionRow} key={questionTemplate.id}>
										<TableGrid.Cell className={styles.questionCell} cols="five">
											<Checkbox
												className={styles.questionRow}
												size="s"
												id={questionTemplate.id}
												checked={selectedTemplateIds.includes(questionTemplate.id)}
												onChange={() => handleSelect(questionTemplate.id)}
												text={(
													<Body size="s" color="text-tertiary">
														{questionTemplate.text}
													</Body>
												)}
											/>
										</TableGrid.Cell>
										<TableGrid.Cell>
											{questionTemplate.workspaceId ? name : "Vurvey"}
										</TableGrid.Cell>
										<TableGrid.Cell>
											<Icon
												name={QUESTION_TYPES[determineQuestionType(questionTemplate)]?.icon ??
														"question-video"}
												size="extrasmall"
											/>
										</TableGrid.Cell>

										<TableGrid.Cell>
											{questionTemplate.useCount}
										</TableGrid.Cell>
									</TableGrid.Row>)
								}
							</TableGrid>

							<div className={styles.bottomSection}>
								{selectedTemplateIds.length > 0 && <span className={styles.selectedText}>
									{selectedTemplateIds.length} selected
								</span>}

								<Button
									disabled={selectedTemplateIds.length === 0 || isAddingQuestions}
									onClick={handleCreateQuestionFromTemplate}
								>
									Add to campaign
								</Button>
							</div>
						</> : <div className={styles.placeholderWrapper}>
							<Icon
								name="folder"
								className={styles.placeholderIcon}
							/>
							<h3>{templateSource === "GLOBAL" ?
								"No global templates match your search results." :
								"Save commonly used questions to your library for faster campaign building."}</h3>
						</div>
					}
				</div>
			</div>
		</Modal>
	);
};
