import React, {ReactElement, useContext, useRef, useState} from "react";

import {Body, Button, Checkbox, Input} from "../../../shared/v2";
import {InputWithButton} from "../../../shared";
import {Panel} from "../../../shared/components/panel";
import {SurveyContext} from "../../../context/survey-context";
import {ToastContext} from "../../../context/toast-context";
import {UPDATE_SURVEY} from "../../../graphql/mutations/survey-mutations";
import {UpdateSurveyChanges} from "../../../models/survey";
import {useMutation} from "@apollo/client";

import styles from "./share-panel.module.scss";

const SharePanel = (): ReactElement | null => {
	const {
		survey: {id, requiresPassword, password},
		showShare,
		setShowShare,
	} = useContext(SurveyContext);
	const {updateToast} = useContext(ToastContext);
	const [pass, setPass] = useState(password || "");
	const inputRef = useRef<HTMLInputElement>(null);

	const [updateSurvey] = useMutation(UPDATE_SURVEY);

	const handleCopy = (): void => {
		if (inputRef.current) {
			inputRef.current.select();
			document.execCommand("copy");
			updateToast({description: "Copied to clipboard", type: "informational"});
		}
	};

	const handleUpdate = (changes: UpdateSurveyChanges): void => {
		updateSurvey({
			variables: {id, changes},
			onCompleted: ()=> updateToast({description: "Share settings updated", type: "informational"}),
		});
	};

	const handleClose = (): void => setShowShare(false);

	if (!showShare) return null;
	return <Panel title="Share" theme="blue" handleClose={handleClose}>
		<div className={styles.content}>
			<h3>Share Link</h3>
			<InputWithButton
				id="copy-link"
				buttonText="Copy"
				ref={inputRef}
				defaultValue={`${window.location.origin}/summary/${id}`}
				readOnly
				onClick={handleCopy}
			/>
			<section className={styles.password}>
				<Checkbox
					id="password-checkbox"
					checked={requiresPassword}
					size="s"
					className={styles.checkbox}
					text={(
						<Body size="xs">
							Require a password
						</Body>
					)}
					onChange={e => handleUpdate({requiresPassword: e.target.checked})}
				/>
				<div className={styles.passwordInput}>
					<Input
						id="reel-password"
						value={pass}
						onChange={setPass}
						disabled={!requiresPassword}
					/>
					<Button
						disabled={!requiresPassword || pass === password || !pass}
						onClick={() => handleUpdate({password: pass, requiresPassword})}
					>
						Save
					</Button>
				</div>
			</section>
		</div>
	</Panel>;
};

export {SharePanel};
