import React, {ReactElement, useMemo, useState} from "react";
import {useQuery} from "@apollo/client";

import {BaseModalProps, Button, Modal, SearchInput, Spinner} from "../../../shared/v2";
import {AI_PERSONA_TYPES} from "../../../graphql/queries/ai-models-queries";
import {FilterChip} from "../../../shared/components/filter-chip";
import {Persona} from "../../../models";

import styles from "./select-agent-modal.module.scss";
import {AgentCard} from "../../../agents/components/agent-card";

export interface SelectAgentModalProps extends BaseModalProps {
  onSelect: (persona: Persona) => void;
  agents: Persona[];
  isLoadingAgents: boolean;
}

export const SelectAgentModal = ({isOpen, onClose, onSelect, agents, isLoadingAgents}: SelectAgentModalProps): ReactElement => {
	const {data: agentTypesData} = useQuery(AI_PERSONA_TYPES, {
		fetchPolicy: "cache-first",
	});

	const [filter, setFilter] = useState<string[]>([]);
	const [search, setSearch] = useState("");
	const [selectedPersona, setSelectedPersona] = useState<Persona | undefined>(undefined);

	const agentTypes = useMemo(() => {
		if (!agentTypesData) {
			return [];
		}

		return agentTypesData.aiPersonaTypes.map(({id, name}) => ({
			id,
			name,
		}));

	}, [agentTypesData])

	const filteredPersonas = useMemo(
		() => (
			agents
				.filter(
					persona => (
						persona.name
							.toLowerCase()
							.includes(search.toLowerCase())
					),
				)
				.filter(
					persona => (
						filter.length === 0
						|| filter.some(f => f === persona.personaType.id)
					),
				)
		),
		[search, agents, filter],
	);


	const handleCloseModal = (): void => {
		setSelectedPersona(undefined);
		setFilter([]);
		setSearch("");
		onClose();
	};

	const handleSubmitPersona = (): void => {
		if (!selectedPersona) return;

		onSelect(selectedPersona);

		handleCloseModal();
	};

	const renderPersonas = () => {
		if (isLoadingAgents) {
			return <Spinner className={styles.spinner}/>;
		}

		return <div className={styles.personasCollection}>
			{filteredPersonas.map(persona => (
				<AgentCard
					key={persona.id}
					persona={persona}
					onSelected={setSelectedPersona}
					isSelected={selectedPersona?.id === persona.id}
				/>
			))
			}
		</div>
	}

	return <Modal
		title="Select Agent"
		isOpen={isOpen}
		onClose={onClose}
		className={styles.modal}
		aria-label="select-agent"
	>
		<div className={styles.searchWrapper}>
			<div className={styles.filterSection}>
				{agentTypes.map(agentType => (
					<FilterChip
						key={agentType.id}
						label={agentType.name}
						isSelected={filter?.includes(agentType.id)}
						onClick={() => {
							if (filter?.includes(agentType.id)) {
								setFilter(filter.filter(f => f !== agentType.id));
							} else {
								setFilter([...filter, agentType.id]);
							}
						}}
					/>
				))}
			</div>
			<SearchInput
				className={styles.searchInput}
				value={search}
				onChange={setSearch}
			/>
		</div>
		{renderPersonas()}
		<div className={styles.commitButtonsWrapper}>
			<Button
				className={styles.commitButton}
				variant="outlined"
				onClick={handleCloseModal}
			>
				Cancel
			</Button>

			<Button
				className={styles.commitButton}
				disabled={!selectedPersona}
				onClick={handleSubmitPersona}
			>
				Add Agent
			</Button>
		</div>
	</Modal>
};
