import {QuestionImage, SurveyVideo} from "../../../models/questions";
import React, {ReactElement, useState} from "react";
import {Icon} from "../../../shared";
import styles from "./intro-media.module.scss";
import {toggleIntroImageUpload} from "../../../cache";

export interface IntroMediaProps {
	/**
	 * The current image on the question
	 */
	image?: SurveyVideo | QuestionImage;
	/**
	 * Video, if there is one
	 */
	isVideo: boolean;
	/**
	 * What to do when delete is clicked
	 */
	deleteImage: () => void;

	id?: string;
}

/**
 * The media associated with the introduction
 * @param image Image URL
 * @param video If the image given should also have a video associated with it
 * @param deleteIamge Delete handler for image
 */
const IntroMedia = (props: IntroMediaProps): ReactElement => {
	const {isVideo, image, deleteImage, id} = props;
	const [showVideo, setShowVideo] = useState<boolean>(false);
	const handleShowModal = (): boolean => toggleIntroImageUpload(true);

	/**
	 * Shows video instead of still image
	 */
	const changeToVideo = (): void => {
		setShowVideo(true);
	};
		/**
		 * Resets the "showVideo" state and deletes asset
		 */
	const handleDelete = (): void => {
		setShowVideo(false);
		deleteImage();
	};

	/**
	 * Displays the image / video if it is available.
	 */
	const getDisplay = (): JSX.Element | undefined => {
		if (image) {
			if (isVideo && showVideo) {
				return <video
					className={styles.video}
					controls
					autoPlay
				>
					<source src={(image as SurveyVideo).mp4} type="video/mp4"/>
					<source src={(image as SurveyVideo).video} type="video/webm"/>
				</video>;
			}
			return <>
				<img
					className={styles.image}
					src={image.small}
				/>
				{
					isVideo && <div onClick={changeToVideo} className={styles.play}>
						<Icon name="play-solid" size="small" fill="white"/>
					</div>
				}
			</>;
		}
	};
	return (
		<div className={styles.container}>
			{getDisplay()}
			<div className={styles.actions}>
				<Icon
					name="image"
					size="extrasmall"
					isClickable
					clicked={image ? handleDelete : handleShowModal}
					fill="var(--batterii-grey-medium)"
				/>
				<button
					className={styles.button}
					onClick={image ? handleDelete : handleShowModal}
					id={id}
				>
					{image ? "Remove" : "Add image or video"}
				</button>
			</div>
		</div>
	);
};

export {IntroMedia};
