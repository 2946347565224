import React, {ReactElement} from "react";
import styles from "./remove-member.module.scss";
import {BaseModalProps, Body, Button, Modal} from "../../shared/v2";

export interface RemoveMemberModalProps extends BaseModalProps {
	confirm: () => void;
}

const RemoveMemberModal = (props: RemoveMemberModalProps): ReactElement => {
	const {isOpen, onClose, confirm} = props;

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size="x-small"
			title="Confirm action"
		>
			<div className={styles.container}>
				<Body type="semibold" className={styles.warning}>
					Are you sure you would like to remove this user from the workspace?
				</Body>
				<div className={styles.actions}>
					<Button onClick={confirm}>Yes</Button>
					<Button style="danger" onClick={onClose}>No</Button>
				</div>
			</div>
		</Modal>
	);
};

export {RemoveMemberModal};
