import React, {FormEvent, HTMLProps, ReactElement} from "react";
import classNames from "classnames/bind";

import {InputElements} from "../input-elements";
import {UseValidationResult} from "../../../../hooks/useValidation";

import styles from "./input.module.scss";

const cx = classNames.bind(styles);

export type InputProps<T> = Omit<HTMLProps<HTMLInputElement>, "size" | "value" | "onChange" | "onBlur"> & {
	variant?: "default" | "error",
	size?: "small" | "medium"
	leftIcon?: ReactElement;
	rightIcon?: ReactElement;
	label?: string;
	hintText?: string;
	onChange: (value: T) => void;
	onBlur?: (value: T) => void;
	onEnter?: (value: T) => void;
	validation?: UseValidationResult;
	value: T;
}

export const Input = ({
	value,
	onChange,
	className,
	size = "small",
	variant = "default",
	label,
	hintText,
	leftIcon,
	rightIcon,
	validation,
	onBlur,
	onEnter,
	...props
}: InputProps<string>): ReactElement => {
	const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
		if (e.key === "Enter") {
			e.preventDefault();
			onEnter?.(value);
		}
	}

	return <InputElements
		variant={variant}
		className={className}
		label={label}
		hintText={hintText}
		leftIcon={leftIcon}
		rightIcon={rightIcon}
		validation={validation}
	>
		<input
			className={cx(
				"input",
				size,
				validation?.isError ? "error" : variant,
				leftIcon && "withLeftIcon",
				rightIcon && "withRightIcon",
			)}
			value={value}
			onChange={(e: FormEvent<HTMLInputElement>) => onChange(e.currentTarget.value)}
			onBlur={() => onBlur?.(value)}
			onKeyDown={onKeyDown}
			{...props}
		/>
	</InputElements>
}
