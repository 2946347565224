import React, {ReactElement, useContext, useEffect, useRef} from "react";
import {Transcript as FullTranscript} from "../../models/transcript";
import {HighlightContext} from "../../context/highlight-context";
import {KeywordHighlighter} from "../../shared/components/keyword-highlighter";
import styles from "./transcript.module.scss";
import {convertToClockTime} from "../../shared/utility/utility";

export interface TranscriptProps {
	/**
	 * Answer ID, in order to update values.
	 */
	answerId: string;
	search?: string;
	transcript?: FullTranscript;
	showTranslation?: boolean;
}

const Transcript = (props: TranscriptProps): ReactElement => {
	const {answerId, search = "", transcript, showTranslation = false} = props;
	const {setDropdownState} = useContext(HighlightContext);
	const ref = useRef<HTMLDivElement>(null);

	const handleSelection = ({target}): void => {
		if (!(ref.current && transcript)) return;
		if (ref.current.contains(target) && window.getSelection()?.toString()) {
			// setSelection(window.getSelection()?.getRangeAt(0));
			setDropdownState({
				range: window.getSelection()?.getRangeAt(0),
				id: transcript.id,
				answerId,
			});
		}
	};

	/**
	 * The way that extractContents works keeps the original elements in the dom
	 * at the start and end of the range, but removes the text nodes.
	 * We want to use this to remove dom elements that are empty.
	 */
	const cleanUpEmptyElem = (collection, parent): void =>{
		collection.forEach(elem => {
			if (elem.textContent.length < 1) {
				try {
					parent.removeChild(elem);
				} catch {

					console.log("Not able to remove");
				}
			}
		});
	};

	useEffect(() => {
		if (!(transcript?.highlights && ref.current)) return;
		const {current} = ref;
		const {highlights} = transcript;
		const range = new Range();
		highlights.forEach(value => {
			const start = current.querySelector(`[data-id="${value.firstWord}"]`);
			let end;
			if (value.lastWord) end = current.querySelector(`[data-id="${value.lastWord}"]`);
			range.setStart(start as Element, 0);
			try {
				if (end) {
					/**
					 * From mostly playing around, figured out that each keyword highlight within
					 * the end of a highlight range adds an extra 2 points we need to account for.
					 * IE: keyword is "it", end is "British". <span>Br<span>it</span>ish</span>. The
					 * extra 2 are the beginnings and end of the span that encases "it"
					 * to give it the keyword highlight.
					 */
					if ((end as Element).children.length > 0) {
						range.setEnd(end as Element, (2 + (2 * (end as Element).children.length)));
					} else {
						range.setEnd(end as Element, 2);
					}
				}
			} catch {

				console.log("Range already set");
			}

			if (range.commonAncestorContainer.nodeName !== "MARK") {
				const mark = document.createElement("mark");
				if (!end || start === end) {
					if (start?.parentNode?.nodeName === "MARK") return;
					mark.id = value.id;
					mark.appendChild(start as Element);
					range.insertNode(mark);
					return;
				}
				const contents = range.extractContents();
				mark.appendChild(contents);
				mark.id = value.id;
				range.insertNode(mark);
				try {

					cleanUpEmptyElem([].slice.call(range.commonAncestorContainer.childNodes), range.commonAncestorContainer);
				} catch {

					console.log("Could not perform cleanup on transcript");
				}
			}
		});
	}, [transcript?.highlights]);

	if (!transcript?.words.length && !transcript?.text) return <p>Transcript not available.</p>;
	console.log("Transcript.words.length: ", transcript.words.length);
	return (
		<>
			{(transcript.words.length && !showTranslation) || // Below is a failsafe. Some transcripts don't have text or translation available
				(transcript && showTranslation && !transcript.englishTranslation && !transcript.text) ?
				<KeywordHighlighter
					className={styles.transcript}
					ref={ref}
					onMouseUp={handleSelection}
					id={transcript.id}
					searchValue={search}
					highlightClassName={styles.keyword}
				>
					{
						transcript.words.map((word, i) => <span
							key={`${word.word}-${i}`}
							className={styles.word}
							data-id={i}
							title={convertToClockTime(word.startAt, "milliseconds", "clock")}
						> {word.word}
						</span>)
					}
				</KeywordHighlighter>
				: <div className={styles.transcript}>
					{transcript.englishTranslation || transcript.text}
				</div>}
		</>
	);
};

export {Transcript};
