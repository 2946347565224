import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {Body} from "../../../typography";
import {DropdownActionItem, DropdownItem, DropdownLinkItem, DropdownNestedItem} from "..";
import {DropdownItemSpacing} from "../_helpers";
import {useThemeMode} from "../../../../../context/theme-mode-context";

import styles from "./section.module.scss";

const cx = classNames.bind(styles);

export interface DropdownSection {
  label: string;
  items: (DropdownItem | undefined)[];
}

export interface DropdownSectionItemProps {
  section: DropdownSection;
  isLast: boolean;
  isFirst: boolean;
}

export const DropdownSectionItem = ({section, isLast, isFirst}: DropdownSectionItemProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	const renderItem = (item: typeof section.items[0], index: number) => {
		if (!item) {
			return null;
		}

		if ("dropdown" in item) {
			return <DropdownNestedItem key={index} item={item} />;
		}

		if ("link" in item) {
			return <DropdownLinkItem key={index} item={item} />;
		}

		return <DropdownActionItem key={index} item={item} />;
	}

	return (
		<div
			className={
				cx(
					"section",
					isFirst && "first",
					isLast && "last",
					{isDarkMode},
				)}>
			<DropdownItemSpacing active={false}>
				<Body size="s" type="medium" className={styles.label}>
					{section.label}
				</Body>
			</DropdownItemSpacing>
			{section.items.map(renderItem)}
		</div>
	);
}
