import classNames from "classnames/bind";
import React, {ReactElement, ReactNode} from "react";

import {Body, Subheader} from "..";

import styles from "./option-row.module.scss";

const cx = classNames.bind(styles);

export interface OptionRowProps {
  title: string;
  description: string;
  children: ReactNode;
  className?: string;
}

export const OptionRow = (props: OptionRowProps): ReactElement => {
	const {
		children,
		title,
		description,
		className,
	} = props;

	return (
		<div className={cx("optionRow", className)}>
			<div className={styles.leftSideWrapper}>
				<Subheader>
					{title}
				</Subheader>

				<Body size="s" color="text-secondary">
					{description}
				</Body>
			</div>

			<div className={styles.rightSideWrapper}>
				{children}
			</div>
		</div>
	);
}
