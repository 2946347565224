import React, {ReactElement, ReactNode, createContext, useState} from "react";

import {ChatConversationMessage} from "../../models/ai-model";

export interface ChatMessagesContextValue {
	messages: ChatConversationMessage[];
	setMessages: (messages: ChatConversationMessage[]) => void;
	addMessage: (message: ChatConversationMessage | ChatConversationMessage[]) => void;
	replaceMessage: (id: string, replaceMessage: ChatConversationMessage) => void;
}

export const ChatMessagesContext = createContext<ChatMessagesContextValue | undefined>(undefined);

export const ChatMessagesContextProvider = ({children}: {children: ReactNode}): ReactElement => {
	const [messages, setMessages] = useState<ChatConversationMessage[]>([]);

	const addMessage = (message: ChatConversationMessage | ChatConversationMessage[]): void => {
		setMessages(state => {
			const currentConversationId = state.filter(c => c.conversationId)[0]?.conversationId;
			const isArray = Array.isArray(message);
			const firstMessage = isArray ? message[0] : message;

			if (currentConversationId && currentConversationId !== firstMessage.conversationId) {
				return [...state];
			}

			return [...state, message].flat();
		});
	};

	const replaceMessage = (id: string, replaceMessage: ChatConversationMessage): void => {
		setMessages(state => {
			const index = state.findIndex(m => m.id === id);

			if (index === -1) {
				return state;
			}

			const newMessages = [...state];
			newMessages[index] = replaceMessage;
			return newMessages;
		});
	};

	return (
		<ChatMessagesContext.Provider
			value={{
				messages,
				setMessages,
				addMessage,
				replaceMessage,
			}}
		>
			{children}
		</ChatMessagesContext.Provider>
	);
};

export const useChatMessagesContext = (): ChatMessagesContextValue => {
	const context = React.useContext(ChatMessagesContext);

	if (context === undefined) {
		throw new Error("useChatMessagesContext must be used within a ChatMessagesContextProvider");
	}

	return context;
};
