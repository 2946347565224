import {useEffect, useState} from "react";

export const useCreateElement = <T extends HTMLElement>(type: string, callback: (element: T) => void): T | null => {
	const [element, setElement] = useState<T | null>(null);

	useEffect(() => {
		const element = document.createElement(type) as T;
		element.style.display = "none";
		callback(element);
		setElement(element);
	}, []);

	return element;
}
