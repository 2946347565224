import classNames from "classnames/bind";
import React, {ReactElement, useEffect, useMemo, useState} from "react";

import {Body, Caption, Input, MultiSelectInput, SelectInput, Slider, Subheader, TextArea, Tooltip} from "../../../shared/v2";
import {CREATE_PERSONA_PICTURE} from "../../../graphql/mutations/persona-mutations";
import {EditPictureModal} from "../../../shared/v2/modals/edit-picture-modal";
import {client} from "../../../shared/utility/client";
import {ImageDropAreaDummy} from "../../../shared/v2/image-drop-area/image-drop-area-dummy";
import {InformationCicrcleIcon, LockIcon} from "../../../icons";
import {PersonaModel, PersonaPicture} from "../../../models/persona";
import {useAgentCreationContext} from "../../../context/agent-creation-context";
import {useThemeMode} from "../../../context/theme-mode-context";
import Config from "../../../config";

import styles from "./agent-form.module.scss";
import {useUserContext} from "../../../context/user-context";

const bStyles = classNames.bind(styles);
interface Voice {
  name: string;
  id: string;
}

export const AgentForm = (): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {
		state: currentFormData,
		agent,
		update: updateAgent,
		agentTypes,
		validation,
		isVisualMode,
		visualAgentType,
		skills,
	} = useAgentCreationContext();
	const {isEnterpriseManagerOrSupport} = useUserContext();
	const [isEditPictureModalOpen, setIsEditPictureModalOpen] = useState(false);
	const [agentVoices, setAgentVoices] = useState<Voice[]>([]);

	useEffect(() => {
		const fetchVoices = async () => {
			try {
				const data = await client
					.get(`${Config.apiHost}/rest/voices`)
					.json<{name: string, voice_id: string}[]>();
				const voicesList = data.map(x => ({name: x.name, id: x.voice_id}));
				setAgentVoices(voicesList)
			} catch (error) {
				console.error("Error fetching voices:", error);
			}
		};

		fetchVoices();
	}, []);

	const handleUploadImage = async (picture: PersonaPicture) => {
		updateAgent({picture: picture});
	};

	const isLocked = agent?.isVurvey && !isEnterpriseManagerOrSupport;

	const handleUpdateTemperature = (value) => {
		updateAgent({temperature: value[0]});
	};

	const agentModels = useMemo(() => {
		if (isVisualMode) {
			return [
				{
					id: PersonaModel.STABLE_DIFFUSION,
					name: "Stable Diffusion",
				},
				{
					id: PersonaModel.IMAGE_GEN,
					name: "Imagen",
				},
				{
					id: PersonaModel.DALL_E,
					name: "DALL-E",
				},
			];
		}

		return [
			{
				id: PersonaModel.GPT,
				name: "GPT",
			},
			{
				id: PersonaModel.GEMINI,
				name: "Gemini",
			},
			{
				id: PersonaModel.CLAUDE,
				name: "Claude",
			},
		];
	}, [isVisualMode]);

	const handleUpdateVoice = (voiceId) => {
		const voice = agentVoices.find((voice) => voice.id === voiceId);
		updateAgent({voiceId: voice?.id, voiceName: voice?.name});
	};

	const handleUpdateType = (personaTypeId) => {
		const changes = {personaTypeId};

		if (visualAgentType.id === personaTypeId) {
			changes["model"] = PersonaModel.STABLE_DIFFUSION;
		} else if (currentFormData.model === PersonaModel.IMAGE_GEN || currentFormData.model === PersonaModel.STABLE_DIFFUSION || currentFormData.model === PersonaModel.DALL_E) {
			changes["model"] = PersonaModel.GPT;
		}
		updateAgent(changes);
	};

	const handleSelectSkills = (skillId: string) => {
		if (currentFormData.skillIds?.includes(skillId)) {
			const skillIds = currentFormData.skillIds.filter((id) => id !== skillId);
			updateAgent({skillIds});
			return;
		}
		updateAgent({skillIds: [...currentFormData.skillIds, skillId]});
	};

	return (
		<>
			<div className={styles.formScroll}>
				{!isEnterpriseManagerOrSupport && isLocked && (
					<div className={styles.lockedAgentWrapper}>
						<LockIcon className={styles.lockIcon} />
						<Subheader size="xl" type="semibold">
              The agent properties are locked
						</Subheader>
					</div>
				)}
				<div className={bStyles("formWrapper", {disabled: isLocked})}>
					<ImageDropAreaDummy
						url={currentFormData?.picture?.url ?? ""}
						className={styles.avatar}
						onClick={() => setIsEditPictureModalOpen(true)}
					/>

					<div className={styles.profileWrapper}>
						<div className={bStyles("headerSection", "row")}>
							<Subheader size="l" type="medium">
                Profile
							</Subheader>
							<Tooltip content={<Body size="xs">Give your agent a personality and backstory</Body>}>
								<InformationCicrcleIcon className={styles.profileIcon} />
							</Tooltip>
						</div>

						<div className={styles.formSection}>
							<Input
								aria-label="agent-name"
								label="Name"
								placeholder="Please enter the name of the agent"
								value={currentFormData.name}
								onChange={(name) => updateAgent({name})}
								validation={validation.name}
								disabled={isLocked}
							/>

							<SelectInput
								aria-label="agent-type"
								label="Type"
								options={agentTypes}
								validation={validation.personaTypeId}
								value={currentFormData.personaTypeId}
								onChange={handleUpdateType}
							/>

							<SelectInput
								aria-label="agent-model"
								label="Model"
								options={agentModels}
								value={currentFormData.model}
								onChange={(model) => updateAgent({model})}
							/>

							<MultiSelectInput
								validation={validation.skillIds}
								label="Tags"
								placeholder="Tags"
								options={skills}
								values={currentFormData.skillIds}
								onChange={(value) => handleSelectSkills(value)}
							/>

							{isEnterpriseManagerOrSupport && <SelectInput
								aria-label="agent-voice"
								label="Voice"
								options={agentVoices}
								value={currentFormData.voiceId}
								onChange={handleUpdateVoice}
							/>}

							<TextArea
								aria-label="agent-description"
								label="Description"
								validation={validation.description}
								placeholder="The assistant's objective which will guide the assistant during the decision-making process."
								value={currentFormData.description}
								onChange={(description) => updateAgent({description})}
								disabled={isLocked}
							/>

							<TextArea
								aria-label="agent-background"
								label="Background"
								validation={validation.background}
								placeholder="The assistant's background information which will help the assistant make better decisions."
								value={currentFormData.background}
								onChange={(background) => updateAgent({background})}
								disabled={isLocked}
							/>
						</div>
					</div>

					<div className={styles.formSection}>
						<div className={styles.headerSection}>
							<Subheader size="l" type="medium">
                Instructions
							</Subheader>

							<Body color="text-secondary">Type instructions below.</Body>
						</div>

						<TextArea
							aria-label="agent-instructions"
							placeholder="Clear and concise instructions for performing tasks"
							validation={validation.instructions}
							value={currentFormData.instructions}
							onChange={(instructions) => updateAgent({instructions})}
							disabled={isLocked}
						/>
					</div>

					<div className={styles.formSection}>
						<div className={styles.headerSection}>
							<Subheader size="l" type="medium">
                Creativity Level
							</Subheader>
							<Body color="text-secondary">Slide level to desired creativity while performing tasks.</Body>
						</div>

						<div className={styles.sliderWrapper}>
							<Caption size="l" className={bStyles("sliderText", {isDarkMode})}>
                More Concise
							</Caption>

							<Slider
								value={[currentFormData.temperature]}
								onValueChange={handleUpdateTemperature}
								max={1}
								step={0.01}
								min={0}
								tooltipTransform={(value) => `${Math.round(value * 100)}%`}
							/>

							<Caption size="l" className={bStyles("sliderText", {isDarkMode})}>
                More Creative
							</Caption>
						</div>
					</div>

					{/* TODO: Hiden for v1 relase. Bring it back when ready */}
					{/* <div className={styles.continuoslyLearningForm}>
          <Subheader size="l" type="medium">
            Continuously Learning
          </Subheader>

            <div className={styles.switchWrapper}>
              <Body color="text-secondary">
                Switch ON to allow this persona to learn from new responses.
              </Body>
              <Switch
                checked={currentFormData.continuouslyLearning}
                onChange={(continuouslyLearning) => updateAgent({continuouslyLearning})}
                disabled={isLocked}
              />
            </div>
          </div> */}
				</div>
			</div>
			<EditPictureModal<PersonaPicture>
				isOpen={isEditPictureModalOpen}
				title="Agent avatar"
				description="Upload an avatar for your agent. This image will serve as a visual representation of your agent."
				imageUrl={currentFormData?.picture?.url ?? ""}
				createPictureMutation={CREATE_PERSONA_PICTURE}
				onUpdate={handleUploadImage}
				onClose={() => setIsEditPictureModalOpen(false)}
			/>
		</>
	);
};
