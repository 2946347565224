import React, {ReactElement, useEffect, useMemo, useRef, useState} from "react";
import {convertTimeFromMiliseconds, convertToClockTime} from "../../../shared/utility/utility";
import {CssSpinner} from "../../../shared";
import {PlayStop} from "../play-stop";
import {getCurrent} from "../../../ref";
import styles from "./video-clip.module.scss";
import {Setter} from "../../../types";

interface ClipState {
	isPlaying: boolean;
	isBuffering: boolean;
	setIsBuffering: Setter<boolean>;
	togglePlaying: () => void;
}

export interface VideoClipProps {
	startTime: number;
	endTime: number;
	video: string;
	videoState: ClipState;
}

const VideoClip = (props: VideoClipProps): ReactElement => {
	const {
		startTime, endTime, video,
		videoState: {isPlaying, isBuffering, setIsBuffering, togglePlaying},
	} = props;

	const ref = useRef<HTMLVideoElement>(null);
	const [timer, setTimer] = useState("0:00");
	// Might be worth doing it this way since they will need to recalculate frequently
	const start = useMemo(() => convertTimeFromMiliseconds(startTime, "seconds"), [startTime]);
	const end = useMemo(() => convertTimeFromMiliseconds(endTime, "seconds"), [endTime]);
	/**
	 * Starts the video at the time of the clip
	 */
	const setStartTime = (): void => {
		const current = getCurrent(ref);
		if (current) current.currentTime = start;
	};

	/**
	 * Checks the current time vs the clip, pauses the video if it gets
	 * to the end of the clip (for now).
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handlePlaying = (): any => {
		const current = getCurrent(ref);
		if (!current) return;
		let time = "0:00";
		time = new Date((current.currentTime * 1000) - startTime).toISOString().substr(15, 4);
		setTimer(time);
		if (current.currentTime >= end) togglePlaying();
	};
	const handlePaused = (): void => {
		if (isBuffering) setIsBuffering(false);
	};
	const handlePlay = (): void => {
		setIsBuffering(false);
	};
	const handleWait = (): void => {
		setIsBuffering(true);
	};
	/**
	 * Adds event listeners to the video ref.
	 */
	useEffect(() => {
		const current = getCurrent(ref);
		if (current) {
			current.onloadedmetadata = setStartTime;
			current.onpause = handlePaused;
			current.onplaying = handlePlay;
			current.onwaiting = handleWait;
			current.ontimeupdate = handlePlaying;
		}
		return () => {
			if (current) {
				current.removeEventListener("loadedmetadata", setStartTime);
				current.removeEventListener("timeupdate", handlePlaying);
				current.removeEventListener("playing", handlePlay);
				current.removeEventListener("pause", handlePaused);
				current.removeEventListener("waiting", handleWait);
			}
		};
	}, [ref, startTime, endTime, video, isPlaying]);

	/**
	 * Sets the play / pause state of the video.
	 */
	useEffect(() => {
		const current = getCurrent(ref);
		if (current) {
			if (isPlaying) {
				current.play();
			} else {
				current.pause();
				setStartTime();
			}
		}
	}, [isPlaying, ref]);

	/**
	 * Should set the start time of the current video every time the startTime
	 * changes.
	 */
	useEffect(() => {
		const current = getCurrent(ref);
		if (current) {
			setStartTime();
		}
	}, [startTime, endTime]);

	return (
		<>
			<video
				className={styles.video}
				ref={ref}
				src={video}
				onContextMenu={event => event.preventDefault()}
			/>
			<PlayStop isPlaying={isPlaying} onClick={togglePlaying} fill="white" className={styles.play}/>
			<span className={styles.timer}>
				{timer} / {convertToClockTime(endTime - startTime, "milliseconds")}
			</span>
			{
				isBuffering && <div className={styles.buffering}>
					<CssSpinner/>
				</div>
			}
		</>
	);
};

VideoClip.displayName = "VideoClip";

export {VideoClip};

