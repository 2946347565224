import React, {ReactElement} from "react";
import classNames, {Argument} from "classnames";
import classnames from "classnames/bind";

import {User} from "../../../models/user";

import styles from "./avatar.module.scss";
import {Icon} from "../icon";

const bStyles = classnames.bind(styles);
export interface AvatarProps {
	firstName?: string;
	lastInitial?: string;
	/**
	 * Any additional styles to apply to the avatar
	 */
	additionalClasses?: string;
	/**
	 * Optional action for when the avatar is clicked
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	clicked?: any;
	/**
	 * Avatar picture (link)
	 */
	imageUrl?: string;
	/**
	 * Size of avatar, three sizes so far (defaults to "medium")
	 */
	size?: "px-24" | "small" | "medium" | "large" | "halfFull" | "extralarge";
	/**
	 * If we should show the darken overlay when user hovers over avatar
	 */
	hasOverlay?: boolean;
}

/**
 * I generally like to keep these for TSDocs, although maybe not strictly needed.
 * Returns an avatar
 * @param name - User's name (first and last)
 * @param imageUrl - String, optional URL for image
 * @param clicked Function to trigger on click (if defined)
 * @param size - String, size of avatar (small, medium, large, extralarge)
 * @param hasOverlay If there should be a black overlay on image when hovering
 *
 * @returns User or workspace avatar.
 */
const Avatar = (props: AvatarProps): ReactElement => {
	const {
		firstName,
		lastInitial,
		additionalClasses,
		imageUrl,
		size = "medium",
		clicked,
		hasOverlay,
	} = props;

	return (
		<div
			onClick={clicked}
			className={[
				additionalClasses,
				bStyles("container", [`${size}`], {
					clickable: clicked,
					"has-overlay": hasOverlay,
					"has-image": imageUrl,
				}),
			].join(" ")}
		>
			{imageUrl ? (
				<img src={imageUrl} className={styles.image} />
			) : firstName ? (
				lastInitial ? (
					firstName?.charAt(0) + lastInitial
				) : (
					firstName?.charAt(0)?.toUpperCase()
				)
			) : (
				<Icon name="person" />
			)}
		</div>
	);
};

export {Avatar};

export interface NewAvatarProps {
	user: User;
	className?: Argument;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onClick?: any;
	size?: "small" | "medium" | "large" | "halfFull" | "extralarge";
	hasOverlay?: boolean;
	showName?: boolean;
	showTag?: boolean;
}

const NewAvatar = (props: NewAvatarProps): ReactElement => {
	const {
		user,
		size = "medium",
		onClick,
		hasOverlay,
		showName,
		showTag,
		className,
	} = props;

	// If a user has no firstName then we want this to also be true
	const isAnon = user.firstName?.toLowerCase()?.includes("anonymous") ?? true;
	const clickHandler = isAnon ? undefined : onClick;

	return (
		<div className={bStyles("newContainer", className)}>
			<Avatar
				firstName={user.firstName}
				lastInitial={user.lastName?.charAt(0) || user.lastInitial}
				imageUrl={user.picture?.thumbnail || user.picture?.badge}
				size={size}
				clicked={clickHandler}
				hasOverlay={hasOverlay}
			/>
			{(showName || showTag) && (
				<div className={styles.text}>
					{showTag && (
						<span onClick={clickHandler} className={classNames(styles.tag, !isAnon && onClick && styles.link)}>
							{user.creatorTag}
						</span>
					)}
					{showName && <span className={styles.name}>{user.firstName} {user.lastInitial}</span>}
				</div>
			)}
		</div>
	);
};

export {NewAvatar};
