import classNames from "classnames/bind";
import React, {ReactElement, ReactNode} from "react";

import styles from "./navigation-page-layout.module.scss";

const cx = classNames.bind(styles);

export interface NavigationPageLayoutProps {
  children: ReactNode;
  className?: string;
}

export const NavigationPageLayout = ({children, className}: NavigationPageLayoutProps): ReactElement => {
	return (
		<div className={styles.layoutWrapper}>
			<div className={cx("main", className)}>
				{children}
			</div>
		</div>
	);
};
