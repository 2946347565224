import React, {ReactElement, useContext} from "react";
import {ClipPage} from "../../../models/clip";
import {ReelContext} from "../../../context/reel-context";
import {ReelTimer} from "../reel-timer";
import {VideoClip} from "../video-clip";
import styles from "./clip-preview.module.scss";

export interface ClipPreviewProps {
	/**
	 * Clip data
	 */
	clipsData: ClipPage;
}

/**
 * This is the clip previewer. Plan is to have it be an actual
 * video player when we click on edit so that it can play, it will be messy.
 */
const ClipPreview = (props: ClipPreviewProps): ReactElement => {
	const {clipsData} = props;
	const {
		index,
		isPlaying,
		isBuffering,
		setIsBuffering,
		togglePlaying,
	} = useContext(ReelContext);


	if (clipsData.items.length === 0) {
		return <div className={styles.empty}><ReelTimer endTime={0}/></div>;
	}
	return (
		<div className={styles.container}>
			{
				clipsData && clipsData.items[index] && (
					<VideoClip
						 
						video={clipsData.items[index].answer?.video.mp4 || clipsData.items[index].sourceVideo?.mp4 || ""}
						startTime={clipsData.items[index].startTime}
						endTime={clipsData.items[index].endTime}
						videoState={{isPlaying, isBuffering, setIsBuffering, togglePlaying}}
					/>
				)
			}
		</div>
	);
};

export {ClipPreview};
