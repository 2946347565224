import React, {ReactElement, ReactNode} from "react";
import {
	ChatCampaignContextProvider,
	ChatConversationContextProvider,
	ChatEventsContextProvider,
	ChatFollowUpsContextProvider,
	ChatImageContextProvider,
	ChatMentionsContextProvider,
	ChatMessagesContextProvider,
	ChatPersonaContextProvider,
	ChatScrollContextProvider,
	ChatSendQuestionContextProvider,
	ChatTrainingSetContextProvider,
} from "../../context/chat-contexts";
import {ChatHandlers} from "./handlers";

export interface ChatContextsAndHandlersProps {
  children?: ReactNode;
}

export const ChatContextsAndHandlers = ({children}: ChatContextsAndHandlersProps): ReactElement => {
	return (
		<ChatScrollContextProvider>
			<ChatEventsContextProvider>
				<ChatMentionsContextProvider>
					<ChatFollowUpsContextProvider>
						<ChatImageContextProvider>
							<ChatConversationContextProvider>
								<ChatPersonaContextProvider>
									<ChatMessagesContextProvider>
										<ChatTrainingSetContextProvider>
											<ChatCampaignContextProvider>
												<ChatSendQuestionContextProvider>
													<ChatHandlers />
													{children}
												</ChatSendQuestionContextProvider>
											</ChatCampaignContextProvider>
										</ChatTrainingSetContextProvider>
									</ChatMessagesContextProvider>
								</ChatPersonaContextProvider>
							</ChatConversationContextProvider>
						</ChatImageContextProvider>
					</ChatFollowUpsContextProvider>
				</ChatMentionsContextProvider>
			</ChatEventsContextProvider>
		</ChatScrollContextProvider>
	);
}
