import classNames, {Argument} from "classnames";
import React, {ReactElement} from "react";
import styles from "./card.module.scss";

export interface CardProps {
	children: React.ReactNode;
	id?: string;
	className?: Argument;
}

const Card = ({children, className, id}: CardProps): ReactElement => (
	<section className={classNames(styles.card, className)} id={id}>
		{children}
	</section>
);

export {Card};
