import {Outlet} from "react-router";
import React, {ReactElement} from "react";
import {NavigationPageLayout} from "../../../hoc/layout/navigation-page-layout";
import {PageSubNavigation} from "../../../hoc/layout/layout/page-sub-navigation";
import {
	ModelsIcon,
	SettingsGearIcon,
} from "../../../icons";
import {ButtonGroup, ButtonGroupElement} from "../../../shared/components/navigation";
import {useMount} from "../../../hooks/useMount";

export const WorkspaceSettings = (): ReactElement => {
	useMount(() => { document.title = "Vurvey - Workspace Settings" });

	return (
		<NavigationPageLayout>
			<PageSubNavigation header="Settings">
				<ButtonGroup>
					<ButtonGroupElement
						icon={<SettingsGearIcon />}
						text="General settings"
						to=""
						end
					/>
					<ButtonGroupElement
						icon={<ModelsIcon />}
						text="Ai Models"
						to="ai-models"
					/>
				</ButtonGroup>
			</PageSubNavigation>
			<Outlet />
		</NavigationPageLayout>
	);
};
