/* eslint-disable */

import {BarChart, ResponsiveContainer, Bar, XAxis, YAxis} from "recharts";
import React, {ReactElement} from "react";

import {Checkbox} from "../../../shared/v2";
import {NumericCounts, SliderQuestion} from "../../../models/questions";

import styles from "./star-chart.module.scss";

interface StarChartProps {
	question: SliderQuestion;
	copyButton?: JSX.Element;
	selected?: (number | null)[] | null;
	changeSelected: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * numericCounts only populates answers if they are selected.
 * IE if there is one answer of "5" selected on a star, there will only be
 * [{answer: 5, count: 1}]. Still have to display 5 bars, so need to map it in some way
 */
const makeData = (data: NumericCounts[], starCount: number, na: boolean): NumericCounts[]=> {
	const starData = Array(starCount).fill(1).map((_x, i) => data?.find(d => d?.answer === starCount - i) ?? {answer: starCount - i, count: 0});
	// This will also push any values of skipped to be N/A (if na is true and user can also skip question)
	if (na) starData.push(data.find(d => d.answer === null) ?? {answer: null, count: 0});
	return starData;
};

const CustomizedTick = (tickInfo: any): ReactElement => <>
	<text x={tickInfo.x + 15} y={tickInfo.y - 15} fontSize={13}>
		{"\u2605".repeat(tickInfo.data[tickInfo.index].answer) || "N/A"}
	</text>
	<text x="99%" textAnchor="end" y={tickInfo.y - 15} fontSize={13}>
		{tickInfo.data[tickInfo.index].count}
	</text>
</>;

const StarChart = React.forwardRef<HTMLDivElement, StarChartProps>((props, ref): ReactElement => {
	const {
		question: {numericCounts, numericAverage, max, answerCount, videoResponse, na},
		copyButton,
		selected,
		changeSelected,
	} = props;
	const starData = makeData(numericCounts, max, na);
	return (
		<div className={styles.container} ref={ref}>
			<header className={styles.header}>
				<h3>{(numericAverage?.toFixed(2)) || 0} average rating</h3>
				{copyButton}
			</header>
			<div className={styles.flex}>
				{ videoResponse && <div className={styles.checkboxContainer}>
					{ starData.map(value => <Checkbox
						key={`${value.answer}-checkbox`}
						id={`${value.answer}-checkbox`}
						value={value.answer || "null"}
						size="s"
						className={styles.checkbox}
						checked={selected?.some(choice => choice === value.answer) || false}
						onChange={changeSelected}
					/>)}
				</div>
				}
				<ResponsiveContainer width="100%" height={66 * starData.length}>
					<BarChart layout="vertical" data={starData} barSize={20}>
						<XAxis type="number" hide domain={[0, answerCount]}/>
						<YAxis width={1}
							dataKey="value" type="category"
							axisLine={false} tickLine={false}
							tick={<CustomizedTick data={starData}/>}
						/>
						<Bar
							key="amount"
							background={{fill: "var(--batterii-grey-extralight)"}}
							fill="var(--batterii-purple)"
							dataKey="count"
						/>
					</BarChart>
				</ResponsiveContainer>
			</div>
		</div>
	);
});

StarChart.displayName = "StarChart";

export {StarChart};
