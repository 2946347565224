import {cache} from "../../cache";

/**
 * This funcion will clean up the values in cache
 * we no longer want. Generally call it after navigating
 * away from a page or section that might store a lot of information
 * in apollo's cache.
 * @param value String or array of strings to remove from cache
 */
export const cleanupCache = (value: string | string[]): void => {
	if (Array.isArray(value)) {
		for (const ref of value) {
			cache.evict({broadcast: false, fieldName: ref});
		}
		cache.gc();
		return;
	}
	cache.evict({broadcast: false, fieldName: value});
	cache.gc();
};
