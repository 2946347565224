import {markResult, PatternProps, ReplacerFunc, splitTaggedResultIntoChunks} from ".";

export interface MentionPatternProps extends PatternProps {
  mentions: string[];
}

const escapeSpecialCharacters = (str: string): string => {
	// eslint-disable-next-line no-useless-escape
	return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
}

export const mentionPatternReplacer: ReplacerFunc<MentionPatternProps> = (props) => {
	const {text, render, mentions} = props;

	const result = mentions.reduce((acc, mention) => {
		const regex = new RegExp(`(?<=\\s|^)@${escapeSpecialCharacters(mention)}(?=\\s|$)`);
		const result = acc.replace(regex, markResult(`@${mention}`));

		return result;
	}, text);

	return splitTaggedResultIntoChunks(result, render);
}
