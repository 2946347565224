import React, {ReactElement} from "react";

import {Body} from "../../../../typography";
import {DropdownItem} from "../..";
import {InformationCicrcleIcon} from "../../../../../../icons";
import {Tooltip} from "../../../../tooltip";

import styles from "../../items.module.scss";

export interface DropdownItemTooltipProps {
  item: DropdownItem;
  theme?: "light" | "dark";
  children?: ReactElement;
}

export const DropdownItemTooltip = ({item: {tooltip}, children}: DropdownItemTooltipProps): ReactElement => {
	return typeof tooltip !== "undefined" ? (
		<Tooltip content={<Body size="xs">{tooltip}</Body>}>
			<div className={styles.tooltipWrapper}>
				{children}
				<InformationCicrcleIcon className={styles.informationIcon} />
			</div>
		</Tooltip>
	) : <>{children}</>
}
