import {GET_REEL_PUBLISH_STATUS, GET_REEL_VIDEO_STATUS} from "../graphql/queries/reel-queries";
import {ReelStatusData, ReelTranscodingData, ReelVideoStatus} from "../models/reels";
import {TranscodingStatus} from "../models/answer";
import {useLazyQuery, useQuery} from "@apollo/client";
import {useEffect, useState} from "react";

export interface ReelPublishHookReturn {
	loading: boolean;
	error: boolean;
}

export const useWaitForReelPublish = (
	reelId: string,
	vidStatus: ReelVideoStatus | undefined,
): ReelPublishHookReturn => {
	/**
	 * The "loading" value from apollo's useQuery does not stay
	 * true between individual polls, and networkStatus resets to "7" (ready)
	 * inbetween polls, so we're using our own states.
	 *
	 * This might be easier to implement if we end up changing to webhooks or
	 * subscriptions in the future.
	 */
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(vidStatus === ReelVideoStatus.FAILED);
	const [videoStatusQuery, {stopPolling: stopVideo}] =
	useLazyQuery<ReelTranscodingData>(GET_REEL_VIDEO_STATUS, {
		variables: {reelId},
		fetchPolicy: "cache-and-network",
		pollInterval: 3000,
		notifyOnNetworkStatusChange: true,
		onCompleted(data) {
			setError(data.reel.video.transcodingStatus === TranscodingStatus.FAILED);
			if (data.reel.video.transcodingStatus !== TranscodingStatus.PENDING) {
				setLoading(false);
				stopVideo?.();
			}
		},
	});

	const {loading: reelLoad, stopPolling} = useQuery<ReelStatusData>(GET_REEL_PUBLISH_STATUS, {
		variables: {reelId},
		fetchPolicy: "cache-and-network",
		pollInterval: 3000,
		notifyOnNetworkStatusChange: true,
		skip: vidStatus !== ReelVideoStatus.PENDING,
		onCompleted(data) {
			if (data.reel.videoStatus === ReelVideoStatus.CREATED) {
				videoStatusQuery();
				stopPolling();
			}
			if (data.reel.videoStatus === ReelVideoStatus.FAILED) {
				stopPolling();
				setLoading(false);
				setError(true);
			}
		},
	});


	useEffect(() => {
		if (vidStatus === ReelVideoStatus.PENDING) {
			setLoading(true);
		}
	}, [vidStatus]);

	return {loading: loading || reelLoad, error};
};

