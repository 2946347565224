import {v4 as uuid} from "uuid";
import ky from "ky";

const _getToken = () => {
	return localStorage.getItem("token");
}

export const client = ky.extend({
	timeout: 1000 * 60 * 5,
	hooks: {
		beforeRequest: [
			(request) => {
				request.headers.set("Authorization", `Bearer ${_getToken()}`);
				request.headers.set("Content-Type", "application/json");
				request.headers.set("X-Request-ID", uuid());
			}
		]
	}
});
