import {Choice} from "./choice";
import {TranscodingStatus} from "./answer";
import {SensemakeReel} from "./reels";
import {GenericTitleDesc} from "../survey/components/auto-reel-info";
import {HeadlineParsed, IdeaParsed, InsightsParsed, ObjectivesParsed} from "./survey";
import {GenericPage} from "./generic";

export interface NumericCounts {
	answer: number | null;
	count: number;
}
export interface WordCount {
	word: string;
	count: number;
}
export enum ZipFileStatus {
	UPLOADING = "UPLOADING",
	UPDATING = "UPDATING",
	OUTDATED = "OUTDATED",
	SUCCEEDED = "SUCCEEDED",
	FAILED = "FAILED",
}
export interface ZipFile {
	id: string;
	originalFilename: string;
	url: string;
	uploadStatus: ZipFileStatus;
}
export interface Question {
	id: string;
	surveyId: string;
	type: QuestionType;
	index: number;
	text: string;
	na: boolean;
	required: boolean;
	points: number | null;
	videoResponse: boolean;
	imageId?: string;
	image?: QuestionImage;
	video?: SurveyVideo;
	arModel?: QuestionArModel;
	choices?: Choice[];
	followUp?: string;
	shuffle?: boolean;
	timeLimit?: number;
	highlightCount?: number;
	answerCount: number;
	max?: number;
	min?: number;
	rank?: boolean;
	step?: number;
	subtype?: string | null;
	numericCounts: NumericCounts[];
	textWordCounts: WordCount[]
	numericAverage?: number;
	leftLabel?: string;
	rightLabel?: string;
	zipFile?: ZipFile;
	sensemakeReel?: SensemakeReel;
	questionInsights?: QuestionInsights;
}

 
export type FeedbackQuestion = Pick<Question, "id" | "videoResponse" | "type" | "text" | "answerCount" | "points" | "index">;

export interface QuestionSenseMake {
	id: string;
	index: number; // For downloading chart
	sensemakeReel: SensemakeReel;
	questionInsights: QuestionInsights;
}

export interface QuestionInsights {
	id: string;
	insights: string; // Straight string
	isSummary: boolean;
	sentiment: string;
	meta: string;
	topics: string; // Needs to be parsed to make any sense.
	summary: GenericTitleDesc[]; // We parse this in the cache before returning.
}

export interface Category {
	category: string;
	confidence: number; // Between -2 and 2 (maybe -1 and 1) Higher is more confident.
}

export interface Topics {
	[key: string]: SubTopic;
}

export interface SubTopic {
	[key: string]: TopicStats;
}
export interface TopicStats {
	count: number;
	magnitude: number;
	sentiment: number;
	salience: number; // Higher is more relevant.
}

export interface ParsedTopics {
	categories: Category[];
	topics: Topics;
}

export interface UpdateQuestionChanges {
	text?: string;
	timeLimit?: number;
	imageId?: string;
	videoId?: string;
	arModelId?: string;
	questionUrl?: string;
	videoResponse?: boolean;
	followUp?: string;
	shuffle?: boolean;
	max?: number;
	min?: number;
	rank?: boolean;
	step?: number;
	leftLabel?: string;
	rightLabel?: string;
	subtype?: string;
	na?: boolean;
	required?: boolean;
}

export interface UpdateQuestionDeletions {
	imageId?: boolean;
	videoId?: boolean;
	arModelId?: boolean;
	questionUrl?: boolean;
	leftLabel?: boolean;
	rightLabel?: boolean;
}

export interface UpdateQuestionData {
	updateQuestion: Question;
}

export interface UpdateQuestionVars {
	id: string;
	changes?: UpdateQuestionChanges;
	deletions?: UpdateQuestionDeletions;
}

export interface ChoiceQuestion extends Question {
	choices: Choice[];
	max: number;
	min: number;
	shuffle: boolean;
}

export interface ImageUploadQuestion extends Question {
	subtype: "picture";
}

export interface SliderQuestion extends Question {
	min: number;
	max: number;
	subtype: string | null;
}

export interface TextQuestion extends Question {
	subtype: string; // Always defined right now.
}

export interface SurveyVideo {
	id: string;
	filename?: string;
	transcodingStatus?: TranscodingStatus;
	video?: string;
	small?: string;
	mp4?: string;
}
export enum QuestionType {
	NONE = "NONE",
	CHOICE = "CHOICE",
	PICTURE = "FILE",
	SLIDER = "SLIDER",
	TEXT = "TEXT",
	BARCODE = "BARCODE",
}

export interface QuestionImage {
	id: string;
	filename: string;
	mimetype: string;
	transcodingStatus?: TranscodingStatus;
	medium?: string;
	small?: string;
	full?: string;
}

export interface QuestionArModel {
	id: string;
	glbUrl: string;
	usdzUrl: string;
}

/**
 * For the GET_QUESTION query return value
 */
export interface QuestionData {
	question: Question;
}

/**
 * For createQuestion mutation
 */
export interface CreateQuestionData {
	createQuestion: Question;
}

export interface CreateMediaVars {
	/**
	 * Upload Item ID
	 */
	uploadItemId: string;
}
export interface CreateARModelVars {
	glbUploadItemId: string;
	usdzUploadItemId: string;
}

export interface CreateImageData {
	/**
	 * Returns the QuestionImage type
	 */
	createImage: QuestionImage;
}

export interface CreateSurveyVideoData {
	/**
	 * Returns Question
	 */
	createSurveyVideo: SurveyVideo;
}
export interface CreateQuestionArData {
	/**
	 * Returns a QuestionArModel
	 */
	createQuestionArModel: QuestionArModel;
}
export interface UploadFileVars {
	/**
	 * The file(!)
	 */
	file: File;
}

export interface CreateZipReturn {
	createQuestionZip: ZipFile;
}

export interface GetZipQueryReturn {
	question: {
		id: string;
		zipFile: ZipFile;
	}
}

export interface GetSensemakeQueryReturn {
	question: QuestionSenseMake;
}

export type QuestionPage = GenericPage<Question>;

export interface QuestionPageReturn {
	questions: QuestionPage;
}

export interface FeedbackQuestionsPageReturn {
	questions: GenericPage<FeedbackQuestion>;
}

export interface SummaryReturn {
	survey: {
		id: string,
		title: string,
		insightsProcessing: boolean;
		surveyInsights: {
			id: string;
			summary?: GenericTitleDesc[],
			objective?: ObjectivesParsed[],
			insights?: InsightsParsed[],
			pros?: GenericTitleDesc[];
			cons?: GenericTitleDesc[];
			headlines?: HeadlineParsed[]
			ideas?: IdeaParsed[];
			edited?: boolean;
		}[]
	}
}

/**
 * This might make more sense to be in a different file.
 */
export interface UploadItem {
	/**
	 * Id of the item
	 */
	id: string;
	/**
	 * Name of the file
	 */
	filename: string;
	/**
	 * Mimetype
	 */
	mimetype: string;
	/**
	 * URL
	 */
	url: string;
}

export interface UploadItemData {
	/**
	 * Returns an upload item
	 */
	upload: UploadItem;
}

 
export type QuestionSubType = "MULTISELECT" | "RANKED" | "CHOICE" | "VIDEO" | "STAR" | "SLIDER" |"SHORT" | "LONG" | "NUMBER" | "PICTURE" | "VIDUPLOAD" | "PDF" | "BARCODE";

export const determineQuestionType = (question: Question): QuestionSubType => {
	if (question.type === QuestionType.BARCODE) return "BARCODE";
	if (question.type === QuestionType.PICTURE) {
		return question.subtype === "picture"
			? "PICTURE" : question.subtype === "pdf" ?
				"PDF" : question.subtype === "video" ? "VIDUPLOAD" : "VIDEO";
	}
	if (question.type === QuestionType.CHOICE) {
		return question.max as number > 1 && !question.rank ? "MULTISELECT"
			: question.rank ? "RANKED"
				: "CHOICE";
	}
	if (question.type === QuestionType.TEXT) {
		if (question.subtype === "short") return "SHORT";
		if (question.subtype === "long") return "LONG";
		return "NUMBER";
	}
	// Right now only have star
	if (question.type === QuestionType.SLIDER) {
		if (question.subtype === "star") return "STAR";
		return "SLIDER";
	}
	return "VIDEO";
};
