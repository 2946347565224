import {Option, Select} from "../../../shared";
import React, {ReactElement, useCallback, useEffect, useMemo, useState} from "react";
import {
	TremendousCurrencyType,
	WorkspaceFundingAndCampaignReturn,
} from "../../../models/rewards";
import {CURRENCY_SELECT_VALUES} from "../../../shared/constants/constants";
import {GET_FUNDING_AND_CAMPAIGNS} from "../../../graphql/queries/reward-queries";
import {GET_SURVEY_INCENTIVE} from "../../../graphql/queries/survey-queries";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {convertToDollars, currencySymbol} from "../../../shared/utility/utility";
import styles from "./tremendous-reward.module.scss";
import {useSearchParams} from "../../../route";
import {useQuery} from "@apollo/client";
import {SurveyIncentiveData} from "../../../models/survey";
import {Body, Button, CurrencyInput, Spinner, Modal, BaseModalProps} from "../../../shared/v2";

export interface TremendousRewardModalProps extends BaseModalProps {
	creatorCount: number;
	handlePay:
	(value: string, currency: TremendousCurrencyType, funds?: string, camp?: string) => void;
}

const TremendousRewardModal = (props: TremendousRewardModalProps): ReactElement => {
	const {isOpen, creatorCount, onClose, handlePay} = props;
	const {workspace: {id}} = useWorkspaceContext();
	const parsedSearch = useSearchParams();
	const {surveyIds = []} = parsedSearch;

	const [disableButton, setDisableButton] = useState(false);
	const [value, setValue] = useState("");
	const [currency, setCurrency] = useState(TremendousCurrencyType.USD); // we won't reset on closing
	const [funds, setFunds] = useState("");
	const [camp, setCamp] = useState("");
	const [toggle, setToggle] = useState(false);

	const {data, loading} = useQuery<WorkspaceFundingAndCampaignReturn>(GET_FUNDING_AND_CAMPAIGNS, {
		variables: {id},
	});

	const {data: survey, loading: surveyLoad} = useQuery<SurveyIncentiveData>(GET_SURVEY_INCENTIVE, {
		skip: surveyIds.length !== 1 || !isOpen,
		variables: {id: surveyIds[0], workspaceId: id},
	});

	const handleConfirm = (): void => {
		setDisableButton(true);
		handlePay(value, currency, funds, camp);
	};

	// Reset values whenever closing modal
	useEffect(() => {
		if (!isOpen) {
			setValue("");
			setToggle(false);
			setDisableButton(false);
		}
	}, [isOpen]);

	useEffect(() => {
		if (!survey) return;
		setValue(survey.survey.incentiveAmount || "0");
		setCurrency(survey.survey.incentiveCurrency);
	}, [survey]);
	// Reset to default values if toggle gets reset.
	useEffect(() => {
		// Since toggle gets reset when closing, decided to just keep reset funds / camp here.
		if (!toggle) {
			setFunds("");
			setCamp("");
		}
	}, [toggle]);

	// Maybe overoptimizing but meh.
	const mappedCamps: Option[] | undefined
		= useMemo(() =>
			data?.workspace.tremendousCampaigns.map(v => ({text: v.name, value: v.id})), [data]);
	const mappedFunds: Option[] | undefined
		= useMemo(() => data?.workspace.tremendousFundingSources.map(v =>
			({
				text: `${v.method}: $${convertToDollars(JSON.parse(v.meta as string).available_cents)}`,
				value: v.id,
			})), [data]);

	// Same, although render logic was getting a bit complicated.
	const renderPayment = useCallback((): JSX.Element => {
		if (loading || surveyLoad) return <span>Loading your payment options...</span>;
		if (toggle && mappedCamps && mappedFunds) {
			return <div className={styles.selects}>
				<span>Campaign:</span> <Select
					id="select-campaign"
					selectedValue={camp}
					onChange={setCamp}
					options={mappedCamps}
				/>
				<span>Funding:</span> <Select
					id="select-funds"
					options={mappedFunds}
					selectedValue={funds}
					onChange={setFunds}
				/>
			</div>;
		}
		// Only reach here if toggle is false AND funds/camp ARE set.
		return <Body className={styles.info}>You are paying using your set up defaults.</Body>;
	}, [loading, data, camp, funds, toggle]);

	const handleToggle = (): void => setToggle(prev => !prev);

	const canContinue = useCallback(() => {
		if (!toggle) {
			return disableButton || !value || value === "0.00";
		}
		return disableButton || !value || value === "0.0.0" || !funds || !camp;
	}, [disableButton, value, funds, camp, toggle]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title="Send reward"
		>
			{disableButton ? <div className={styles.spinner}><Spinner />
				<span>Sending rewards...</span></div> : <div>
				<div className={styles.container}>
					<img src="images/tremendous-icon.png" className={styles.icon}/>
					<span>Reward with Tremendous</span>
					<div className={styles.curContainer}>
						<CurrencyInput
							value={value}
							onChange={setValue}
							currencyType={currency}
							className={styles.input}
						/>
						<Select
							id="select-currency"
							onChange={setCurrency}
							selectedValue={currency}
							options={CURRENCY_SELECT_VALUES}
						/>
					</div>
				</div>
				<span className={styles.amount}>*Per Creator ({creatorCount})</span>
				<div className={styles.paymentOption}>
					{renderPayment()}
					{ mappedCamps && mappedFunds && // Only give the option to toggle if values exist
						<span className={styles.toggle} onClick={handleToggle}>
							{toggle ? "Use defaults" : "Choose payment source"}
						</span>
					}
				</div>
			</div>
			}
			<div className={styles.actions}>
				<Body size="xs" color="text-secondary" className={styles.notice}>*Tremendous may charge 2.5% per reward sent out.</Body>
				<Button variant="outlined" onClick={onClose} disabled={disableButton}>Cancel</Button>
				<Button
					disabled={canContinue()}
					onClick={handleConfirm}
				>
					{`Pay (${currencySymbol(currency)}${(Number(value) * creatorCount).toFixed(2)})`}
				</Button>
			</div>
		</Modal>
	);
};

export {TremendousRewardModal};
