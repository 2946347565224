import classNames, {Argument} from "classnames";
import React, {ReactElement, useEffect, useRef} from "react";
import Mark from "mark.js";

export interface HighlighterProps {
	text: string;
	searchValue?: string;
	className?: Argument;
	highlightClassName?: Argument;
	dataId?: string | number;
	title?: string
}

 
const Highlighter = ({text, searchValue, highlightClassName, className, dataId, title}: HighlighterProps): ReactElement => {
	const ref = useRef<HTMLSpanElement>(null);
	useEffect(() => {
		if (ref.current) {
			const marker = new Mark(ref.current);
			marker.unmark();
			if (searchValue) {
				marker.mark(searchValue, {className: classNames(highlightClassName),
					element: "span",
					each: node => node.setAttribute("data-id", dataId as string)});
			}
		}
	}, [searchValue, ref.current]);

	 
	return <span className={classNames(className)} ref={ref} title={title} data-id={dataId}> {text}</span>;
};

export {Highlighter};
