import React, {ReactElement, useState} from "react";
import {Dropdown, Icon} from "../../../shared";
import {Attribute, AttributeCategory} from "../../../models/attribute";
import {Setter} from "../../../types";
import styles from "./attribute-selector.module.scss";
import {useDropdown} from "../../../hooks";
import {groupBy, uniqBy, map} from "lodash-es";
import {SearchInput} from "../../../shared/v2/inputs";

export interface AttributeSelectorProps {
	value?: Attribute;
	attributes: Attribute[];
	showModal: Setter<boolean>;
	handleSelection: (newAttr: Attribute | undefined) => void;
}

const AttributeSelector = (props: AttributeSelectorProps): ReactElement => {
	const {value, attributes, showModal, handleSelection} = props;
	const [filter, setFilter] = useState("");
	const {anchorEl, handleClick, handleClose} = useDropdown();

	const attributeCategories = groupBy(attributes, "category.id");

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const allCategories: any = map(
		uniqBy(attributes.map(({category}) => category), "id"),
		(category: AttributeCategory): AttributeCategory => {
			const categoryCopy: AttributeCategory = {...category};
			categoryCopy.attributes = attributeCategories[category.id];
			return categoryCopy;
		},
	);

	const handleCreate = (): void => {
		showModal(true);
		handleClose();
	};

	const handleClickAttr = (attr: Attribute | undefined): void => {
		handleSelection(attr);
		handleClose();
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const mapAttributesIntoItems = (attributesList): any => <>
		{

			attributesList.filter(attribute => attribute.name.toLowerCase().includes(filter.toLowerCase())).map(attribute =>

				<Dropdown.Item key={attribute.id} options={{onClick: () => handleClickAttr(attribute)}}>
					{attribute.name}
				</Dropdown.Item>)
		}
	</>;

	return (
		<>
			<div className={styles.inputContainer} onClick={handleClick}>
				<input
					id="attribute-selector"
					className={styles.input}
					placeholder="-- Choose Data Property --"
					readOnly
					value={value?.name || ""}
				/>
				<Icon
					className={styles.icon}
					name="chevron"
					size="small"
					fill="black"
				/>
			</div>
			<Dropdown
				anchorEl={anchorEl} closeMenu={handleClose}
				width={240}
				keepOpen
			>
				<SearchInput
					id="filter-segments"
					value={filter}
					onChange={setFilter}
					className={styles.filter}
				/>
				<div className={styles.attributes}>
					<Dropdown.Item className={styles.link} options={{onClick: handleCreate}}>
						+ New Property
					</Dropdown.Item>
					<Dropdown.Item options={{onClick: () => handleClickAttr(undefined)}}>
						** Unassigned
					</Dropdown.Item>
					{
						allCategories.map(({name, id, attributes: attrs}) => (
							<Dropdown.Section skip={!attrs?.length} title={name} key={id}>
								{mapAttributesIntoItems(attrs)}
							</Dropdown.Section>
						))
					}
				</div>
			</Dropdown>
			<a
				className={styles.infoLink}
				target="_blank"
				href="https://help.vurvey.com/en/articles/7179808-custom-properties"
				rel="noreferrer">
				What&apos;s this?
			</a>
		</>
	);
};

export {AttributeSelector};
