

import React, {useRef, useState, useEffect} from "react";
import config from "../../config";
import styles from "./mask.module.scss";
import ClipLoader from "react-spinners/ClipLoader";
import {Button} from "../../shared/v2";
import {useTask} from "../../hooks/useTask";
import {client} from "../../shared/utility/client";
import ky from "ky";


interface MaskComponent {
  src: string;
}

const MaskComponent: React.FC<MaskComponent> = ({src}) => {
	const imageCanvasRef = useRef<HTMLCanvasElement | null>(null);
	const maskCanvasRef = useRef<HTMLCanvasElement | null>(null);
	const [brushRadius, setBrushRadius] = useState(30);
	const [isPainting, setIsPainting] = useState(false);
	const [prompt, setPrompt] = useState("");
	const [editedImages, setEditedImages] = useState<string[]>([]);
	const [currentSrc, setCurrentSrc] = useState(src);
	const [hasEdit, setHasEdit] = useState(false);

	const {run: handleUpscale, loading: isLoadingUpscale} = useTask(async () => {
		try {
			const {urls} = await client.post(`${config.apiHost}/rest/upscale`, {
				body: JSON.stringify({
					url: currentSrc,
					prompt: prompt,
				}),
			}).json<{urls: string[]}>();
			setEditedImages(urls);
		} catch (error) {
			console.error("Failed to generate edited image");
			handleReset();
			throw error;
		}
	})

	const {run: handleRemove, loading: isLoadingRemove} = useTask(async () => {
		const maskCanvas = maskCanvasRef.current;
		const maskDataURL = maskCanvas?.toDataURL("image/webp");
		const data = {
			maskData: maskDataURL,
			prompt: "",
			baseImage: currentSrc,
		};

		try {
			const {editedImageUrls} = await client.post(`${config.apiHost}/rest/imgeditor`, {
				body: JSON.stringify(data),
			}).json<{editedImageUrls: string[]}>();
			setEditedImages(editedImageUrls);
		} catch (error) {
			console.error("Error generating edited image:", error);
			throw error;
		} finally {
			handleReset();
		}
	});

	const {run: handleGenerate, loading: isLoading} = useTask(async () => {
		const maskCanvas = maskCanvasRef.current;
		const maskDataURL = maskCanvas?.toDataURL("image/webp");
		const data = {
			maskData: maskDataURL,
			prompt: prompt,
			baseImage: currentSrc,
		};

		try {
			const {editedImageUrls} = await client.post(`${config.apiHost}/rest/imgeditor`, {
				body: JSON.stringify(data),
			}).json<{editedImageUrls: string[]}>();
			setEditedImages(editedImageUrls);
		} catch (error) {
			console.error("Error generating edited image:", error);
			throw error;
		} finally {
			handleReset();
		}
	});

	const downloadImage = async (url: string) => {
		try {
			const blob = await ky.get(url).blob();
			const objectUrl = URL.createObjectURL(blob);

			const link = document.createElement("a");
			link.href = objectUrl;
			link.download = "edited_image.webp";
			document.body.appendChild(link);
			link.click();

			// Clean up the object URL after the download
			URL.revokeObjectURL(objectUrl);
			document.body.removeChild(link);
		} catch (error) {
			console.error("Error downloading the image:", error);
		}
	};

	const shouldDisableInpaint = isLoading || isLoadingRemove || isLoadingUpscale || !hasEdit || !prompt?.trim();
	const shouldDisableRemove = isLoadingRemove || isLoading || !hasEdit || !shouldDisableInpaint;
	const shouldDisableUpscale = isLoading || isLoadingUpscale || isLoadingRemove || !shouldDisableInpaint || !shouldDisableRemove

	useEffect(() => {
		const canvas = imageCanvasRef.current;
		const ctx = canvas?.getContext("2d");

		const maskCanvas = maskCanvasRef.current;
		const tempCtx = maskCanvas?.getContext("2d");

		if (!ctx || !tempCtx) return;

		const image = new Image();
		image.crossOrigin = "anonymous";
		image.onload = () => {
			const {width: originalWidth, height: originalHeight} = image;
			let newWidth = originalWidth;
			let newHeight = originalHeight;

			if (originalWidth > 512) {
				newWidth = 512;
				newHeight = (originalHeight * newWidth) / originalWidth;
			}

			if (canvas) {
				canvas.width = newWidth;
				canvas.height = newHeight;
			}
			if (maskCanvas) {
				maskCanvas.width = newWidth;
				maskCanvas.height = newHeight;
			}

			ctx.clearRect(0, 0, newWidth, newHeight);
			ctx.drawImage(image, 0, 0, newWidth, newHeight);

			tempCtx.fillStyle = "black";
			tempCtx.fillRect(0, 0, newWidth, newHeight);
		};
		image.src = currentSrc;
	}, [currentSrc]);

	const handleReset = () => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const canvas = imageCanvasRef.current as any;
		const ctx = canvas?.getContext("2d");
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const maskCanvas = maskCanvasRef.current as any;
		const tempCtx = maskCanvas?.getContext("2d");

		if (ctx && tempCtx) {
			// Clear the mask canvas
			tempCtx.clearRect(0, 0, maskCanvas.width, maskCanvas.height);
			tempCtx.fillStyle = "black";
			tempCtx.fillRect(0, 0, maskCanvas.width, maskCanvas.height);

			// Clear the image canvas and redraw the initial image
			const image = new Image();
			image.crossOrigin = "anonymous";
			image.onload = () => {
				ctx.clearRect(0, 0, canvas.width, canvas.height);
				ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
			};
			image.src = currentSrc;

			// Reset other state variables
			setHasEdit(false);
			setPrompt("");
		}
	};

	const startPainting = () => {
		setIsPainting(true);
	};

	const stopPainting = () => {
		setIsPainting(false);
		const ctx = imageCanvasRef.current?.getContext("2d");
		const tempCtx = maskCanvasRef.current?.getContext("2d");
		if (ctx) ctx.beginPath();
		if (tempCtx) tempCtx.beginPath();
	};

	const paint = (event: React.MouseEvent<HTMLCanvasElement>) => {
		if (!isPainting) return;
	  setHasEdit(true);
		const imageCanvas = imageCanvasRef.current;
		const ctx = imageCanvas?.getContext("2d");

		const maskCanvas = maskCanvasRef.current;
		const tempCtx = maskCanvas?.getContext("2d");

		const rect = imageCanvas?.getBoundingClientRect();
		const x = event.clientX - (rect?.left || 0);
		const y = event.clientY - (rect?.top || 0);

		if (ctx) {
			ctx.lineWidth = brushRadius * 2;
			ctx.lineCap = "round";
			ctx.strokeStyle = "white";
			ctx.lineTo(x, y);
			ctx.stroke();
			ctx.beginPath();
			ctx.moveTo(x, y);
		}

		if (tempCtx) {
			tempCtx.lineWidth = brushRadius * 2;
			tempCtx.lineCap = "round";
			tempCtx.strokeStyle = "white";
			tempCtx.lineTo(x, y);
			tempCtx.stroke();
			tempCtx.beginPath();
			tempCtx.moveTo(x, y);
		}
	};

	const swapImage = async (url: string) => {
		try {
			const canvas = imageCanvasRef.current;
			const ctx = canvas?.getContext("2d");
			const newImage = new Image();
			newImage.crossOrigin = "anonymous";

			newImage.onload = () => {
				// Save the current canvas content
				const currentImageData = canvas?.toDataURL();

				// Get the dimensions of the new image
				const {width, height} = newImage;

				// Set canvas dimensions to match the new image
				if (canvas) {
					canvas.width = width;
					canvas.height = height;
				}

				// Clear the canvas and draw the new image
				ctx?.clearRect(0, 0, width, height);
				ctx?.drawImage(newImage, 0, 0, width, height);

				// Move the previous canvas content to edited images
				if (currentImageData) {
					setEditedImages((prevImages) => [currentImageData, ...prevImages.filter((img) => img !== url)]);
				}

				// Update the currentSrc to the new image URL
				setCurrentSrc(url);
			};

			newImage.src = url;
		} catch (error) {
			console.error("Error swapping the image:", error);
		}
	};

	return (
		<div className={styles.container}>
			<div className={styles.leftBox}>
				<div className={styles.promptContainer}>
					{/*<label className={styles.promptLabel}>
		  ✨ Enhance: to upscale and auto fix image.
          </label>
          <label className={styles.promptLabel}>
		  🎨 Inpaint: use with brush and prompt to fill with the prompt text.
          </label>
          <label className={styles.promptLabel}>
		  🫥 Remove: use with brush (no prompt) to remove the brushed area.
          </label>*/}
					<input
						type="text"
						value={prompt}
						onChange={(e) => setPrompt(e.target.value)}
						className={styles.promptInput}
						placeholder="Enter prompt here for inpainting. Otherwise leave blank."
					/>
				</div>
				<div className={styles.controls}>
					<div className={styles.control}>
						<label className={styles.label}>Brush Size: </label>
						<input
							type="range"
							min={1}
							max={50}
							value={brushRadius}
							onChange={(e) => setBrushRadius(parseInt(e.target.value))}
							className={styles.input}
						/>
					</div>
				</div>
				<div className={styles.buttonContainer}>
					<Button style="ai" onClick={handleReset} disabled={!hasEdit || isLoading || isLoadingRemove || isLoadingUpscale}>
            Reset
					</Button>
					<Button style="ai" onClick={handleRemove} disabled={shouldDisableRemove}>
						{isLoadingRemove ? (
							<div className="spinner-container">
								<ClipLoader color="#36d7b7" loading={isLoadingRemove} size={25} />
							</div>
						) : (
							"Remove"
						)}
					</Button>
					<Button style="ai" onClick={handleGenerate} disabled={shouldDisableInpaint}>
						{isLoading ? (
							<div className="spinner-container">
								<ClipLoader color="#36d7b7" loading={isLoading} size={25} />
							</div>
						) : (
							"Inpaint"
						)}
					</Button>
					<Button style="ai" onClick={handleUpscale} disabled={shouldDisableUpscale}>
						{isLoadingUpscale ? (
							<div className="spinner-container">
								<ClipLoader color="#36d7b7" loading={isLoadingUpscale} size={25} />
							</div>
						) : (
							"Enhance"
						)}
					</Button>
				</div>
				<div className={styles.canvasContainer}>
					<canvas
						ref={imageCanvasRef}
						className={styles.canvas}
						onMouseDown={startPainting}
						onMouseUp={stopPainting}
						onMouseMove={paint}
						onMouseLeave={stopPainting}
					/>
					<canvas
						ref={maskCanvasRef}
						className={styles.hiddenCanvas}
						onMouseDown={startPainting}
						onMouseUp={stopPainting}
						onMouseMove={paint}
						onMouseLeave={stopPainting}
					/>
				</div>
			</div>
			{editedImages.length > 0 && (
				<div className={styles.rightBox}>
					{/*<h2 className={styles.editedImageHeading}>Edited Images</h2>*/}
					<div className={styles.editedImagesGrid}>
						{editedImages.map((image) => (
							<div key={image} className={styles.editedImageContainer}>
								<img
									src={image}
									alt="Edited"
									className={styles.editedImage}
									onClick={() => image && window.open(image, "_blank")?.focus()}
								/>
								<button className={styles.downloadButton} onClick={() => image && downloadImage(image)}>
                  Save
								</button>
								<button className={styles.swapButton} onClick={() => swapImage(image)}>
                  Swap
								</button>
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default MaskComponent;
