import React, {ReactElement} from "react";

import {useMakePortal} from "../../hooks";

import styles from "./viewport-portal.module.scss";

export const VIEWPORT_PORTAL_NAME = "viewport-portal";

export const ViewportPortal = (): ReactElement => {
	const portalRef = useMakePortal<HTMLDivElement>(VIEWPORT_PORTAL_NAME);

	return (
		<div
			className={styles.viewportPortal}
			id={VIEWPORT_PORTAL_NAME}
			ref={portalRef}
		/>
	);
}
