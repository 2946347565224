import React, {ReactElement, useCallback, useEffect, useState} from "react";
import {Question} from "../../../models/questions";
import {QuestionCard} from "../question-card";

export interface QuestionListProps {
	/**
	 * The questions
	 */
	questions: Question[];
	/**
	 * How to handle a delete call
	 */
	onDelete: (id: string) => void;
	/**
	 * Function to handle copying a question
	 */
	onCopy: (id: string) => void;
	/**
	 * Move a question around
	 */
	moveQuestion: (question: Question, dragIndex: number, dropIndex: number) => void;
	move: (question: Question, endIndex: number, startIndex: number) => void;
}

/**
 * Lists out the questions in a sortable, drag and drop list.
 * @param questions The questions we receive from the parent
 * @param onDelete Function to handle when we delete a question
 * @param status Status of the survey
 * @param moveQuestion What to do when we drop a question
 */
const QuestionList = (props: QuestionListProps): ReactElement => {
	const {questions, onCopy, onDelete, moveQuestion, move} = props;
	const [questionList, setQuestionList] = useState<Question[]>(() => questions);

	const handleDragging = useCallback((dragIndex: number, hoverIndex: number): void => {
		// if (questionList) {
		// const question = questionList[dragIndex];
		const copiedList = questions.slice();
		copiedList.splice(hoverIndex, 0, copiedList.splice(dragIndex, 1)[0]);
		setQuestionList(copiedList);
		// }
	}, [questions]);
	/**
	 * Handles the drop action. Will only trigger if drag / dropIndex are not the same.
	 * @param dragIndex The index of the object we are dragging
	 * @param dropIndex The index
	 * @returns Calls the moveQuestion mutation on parent.
	 */
	const handleDrop = useCallback((dragIndex: number, dropIndex: number): void => {
		const question = questions[dragIndex]; // get question
		if (moveQuestion) {
			moveQuestion(question, dragIndex, dropIndex);
		} else {
			throw new Error("Move question was not defined");
		}
	}, [questions]);

	useEffect(() => {
		setQuestionList(questions);
	}, [questions]);
	return (
		<>
			{
				questionList.map((question, index) => <QuestionCard
					key={question.id}
					question={question}
					onDelete={onDelete}
					onCopy={onCopy}
					index={index}
					onDrag={handleDragging}
					onDrop={handleDrop}
					onMove={move}
					mode="questions"
				/>)
			}
		</>
	);
};

export {QuestionList};
