import React, {ReactElement} from "react";
import {QuestionNav} from "../question-nav";
import styles from "./question-panel.module.scss";

const QuestionPanel = (): ReactElement => (
	<div className={styles.container}>
		<QuestionNav />
	</div>
);

export {QuestionPanel};
