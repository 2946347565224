import {useMutation, useQuery} from "@apollo/client";
import React, {ReactElement, useEffect, useMemo, useState} from "react";

import {Country} from "../../../../models/user";
import {DateInput, Input, SelectInput, RadioGroup, Button} from "../../../../shared/v2";
import {Gender, USER_FIELD_MAX_LENGTH} from "../../../../shared/constants/constants";
import {GET_COUNTRIES, GET_USER_INFO} from "../../../../graphql/queries/queries";
import {UPDATE_ME} from "../../../../graphql/mutations/account-mutations";
import {useToastContext} from "../../../../context/toast-context";
import {useUserContext} from "../../../../context/user-context";

import styles from "./personal-information-form.module.scss";

export const PersonalInformationForm = (): ReactElement => {
	const {user} = useUserContext();
	const {updateToast} = useToastContext();

	const {
		data: {countries = []} = {},
		loading: isLoadingCountries
	} = useQuery(GET_COUNTRIES);
	const [saveChanges, {loading: isSavingChanges}] = useMutation(UPDATE_ME, {
		onCompleted: () => {
			updateToast({description: "Profile updated", type: "informational"});
		},
	});

	const [firstName, setFirstName] = useState<string>(user.firstName);
	const [lastName, setLastName] = useState<string>(user.lastName || "");
	const [birthdate, setBirthdate] = useState<string | undefined | null>(user.birthdate);
	const [city, setCity] = useState<string>(user.city || "");
	const [country, setCountry] = useState<Country | undefined>(user.country);
	const [gender, setGender] = useState<Gender | undefined>(user?.gender as Gender);

	const handleSetCountry = (id: string) => {
		const country = countries.find((country) => country.id === id);

		if (country) {
			setCountry(country);
		}
	}

	const handleSetBirthdate = (value: string) => {
		if (value === "") {
			setBirthdate(null);
			return;
		}

		setBirthdate(value);
	}

	const handleSave = () => {
		if (!isValid) {
			return;
		}

		saveChanges({
			variables: {
				changes: {
					firstName,
					lastName,
					birthdate,
					gender,
					city,
					countryId: country?.id,
				},
			},
			refetchQueries: [GET_USER_INFO],
		});
	};


	const handleCancel = () => {
		if (!user) {
			return;
		}
		setFirstName(user.firstName);
		setLastName(user.lastName || "");
		setGender(user.gender as Gender);
		setCity(user.city || "");
		setCountry(user.country);
		setBirthdate(user.birthdate);
	}

	const isDisabled = useMemo(() => {
		if (!user || isSavingChanges) {
			return true;
		}

		const hasChanges = (
			firstName !== user.firstName ||
      lastName !== user.lastName ||
      gender !== user.gender ||
      birthdate !== user.birthdate ||
      city !== user.city ||
      country?.id !== user.country?.id
		);

		return !hasChanges;
	}, [
		user,
		isSavingChanges,
		firstName,
		lastName,
		gender,
		birthdate,
		city,
		country,
	]);

	const isValid = useMemo(() => {
		const isTooLong = [firstName, lastName, city].some((value) => value?.length > 200);
		const isFromFuture = birthdate && new Date(birthdate) > new Date();

		return !isTooLong && !isFromFuture;
	}, [firstName, lastName, city, birthdate]);


	useEffect(handleCancel, [user]);

	return (
		<div className={styles.personalInformationForm}>
			<Input
				label="First Name"
				maxLength={USER_FIELD_MAX_LENGTH}
				value={firstName}
				onChange={setFirstName}
				size="medium"
			/>

			<Input
				label="Last Name"
				maxLength={USER_FIELD_MAX_LENGTH}
				value={lastName}
				onChange={setLastName}
				size="medium"
			/>

			<RadioGroup
				label="Gender"
				value={gender}
				onChange={setGender}
			>
				<RadioGroup.Option value={Gender.MALE} label="Male" />
				<RadioGroup.Option value={Gender.FEMALE} label="Female" />
				<RadioGroup.Option value={Gender.NONBINARY} label="Nonbinary" />
			</RadioGroup>

			<DateInput
				label="Birthdate"
				value={birthdate || ""}
				min="1900-01-01"
				max={new Date().toISOString().split("T")[0]}
				onChange={handleSetBirthdate}
				size="medium"
			/>

			<Input
				label="City"
				maxLength={USER_FIELD_MAX_LENGTH}
				value={city}
				onChange={setCity}
				size="medium"
			/>

			<SelectInput
				label="Country"
				value={country?.id}
				options={countries}
				onChange={handleSetCountry}
				disabled={isLoadingCountries}
				size="medium"
			/>

			<div className={styles.buttons}>
				<Button
					disabled={isDisabled}
					onClick={handleSave}
				>
          Save
				</Button>

				<Button
					disabled={isDisabled}
					variant="text"
					onClick={handleCancel}
				>
          Cancel
				</Button>
			</div>
		</div>
	);
}
