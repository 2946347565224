import React, {ReactElement} from "react";

import {ErrorBoundary} from "../../components";
import {Header, Body} from "../../../shared/v2";

import styles from "./navigaiton-content-error-boundary.module.scss";

export interface NavigationContentErrorBoundaryProps {
  children: ReactElement;
}

export const NavigationContentErrorBoundary = ({children}: NavigationContentErrorBoundaryProps): ReactElement => {
	return (
		<ErrorBoundary FallbackComponent={() => (
			<div className={styles.navigationContentErrorBoundary}>
				<Header>
          Something went wrong
				</Header>

				<Body>
          Try refreshing the page or contact support
				</Body>
			</div>
		)}>
			{children}
		</ErrorBoundary>
	);
}
