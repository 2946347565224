import React, {ReactElement, useContext, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router";
import {useMutation, useQuery} from "@apollo/client";
import {Column, Row} from "react-table";

import {GET_TRAINING_SET} from "../../../graphql/queries/ai-models-queries";
import {DropdownOptions, Icon} from "../../../shared";
import {
	DELETE_TRAINING_SET_FILE,
	DELETE_TRAINING_SET_VIDEO,
	REMOVE_FILE_FROM_TRAINING_SET,
	REMOVE_VIDEO_FROM_TRAINING_SET,
} from "../../../graphql/mutations/ai-mutations";
import {NavLink} from "../../../route/components";
import {TrainingSetMedia, TrainingSetVideo} from "../../../models/ai-model";
import {BinDeleteIcon, ChevronLeftIcon, DotsIcon, SparkAiStarsIcon} from "../../../icons";
import {Table} from "../../../shared/components/table";
import {AlignText} from "../../../shared/typography/align-text";
import {ActionsButton} from "../../../contacts/components/actions-button";
import {FileUploader} from "../../components";
import {ToastContext} from "../../../context/toast-context";
import {
	Button,
	SearchInput,
	Body,
	Subheader,
	LoadingContainer,
	Loader,
	Tooltip,
	Dropdown,
	DropdownItem,
} from "../../../shared/v2";
import {useNavigate} from "../../../route";
import {ConfirmActionModal} from "../../../shared/v2/modals/confirm-action-modal";

import styles from "./dataset-page.module.scss";
import {useWorkspaceContext} from "../../../context/workspace-context";

export const POLL_INTERVAL = 5000;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isTrainingVideo = (m: any): m is TrainingSetVideo => m.transcodingStatus !== undefined;

const isCSVorXLSX = (file: TrainingSetMedia): boolean => {
	const extension = file.originalFilename.split(".").pop()?.toLowerCase();
	return extension === "csv" || extension === "xlsx";
};

export const DatasetPage = (): ReactElement => {
	const {trainingSetId} = useParams();
	const navigate = useNavigate();
	const {updateToast} = useContext(ToastContext);
	const [itemSelected, setItemSelected] = useState<TrainingSetMedia | undefined>();
	const [showBulkDelete, setShowBulkDelete] = useState(false);
	const {workspace} = useWorkspaceContext();
	const [shouldPoll, setShouldPoll] = useState(false);

	const {
		data: {trainingSet} = {},
		loading,
		startPolling,
		stopPolling,
	} = useQuery(GET_TRAINING_SET, {
		variables: {trainingSetId},
		fetchPolicy: "network-only",
	});

	useEffect(() => {
		if (trainingSet) {
			if (allHasEmbeddings) {
				setShouldPoll(false);
				stopPolling();

				if (shouldPoll) {
					updateToast({
						description: "All files are ready. You can start a conversation now.",
						type: "success",
					});
				}
			}
		}
	}, [trainingSet, stopPolling]);

	const [deleteFile] = useMutation(DELETE_TRAINING_SET_FILE);
	const [deleteVideo] = useMutation(DELETE_TRAINING_SET_VIDEO);
	const [removeFileFromTrainingSet] = useMutation(REMOVE_FILE_FROM_TRAINING_SET, {
		refetchQueries: [GET_TRAINING_SET],
	});
	const [removeVideoFromTrainingSet] = useMutation(REMOVE_VIDEO_FROM_TRAINING_SET, {
		refetchQueries: [GET_TRAINING_SET],
	});

	const [searchValue, setSearchValue] = useState("");
	const [selectedFiles, setSelectedFiles] = useState<Row<TrainingSetMedia>[]>([]);

	const trainingSetFiles = useMemo(() => {
		return trainingSet?.files?.concat(trainingSet?.videos || []);
	}, [trainingSet?.files, trainingSet?.videos]);

	const allHasEmbeddings = useMemo(() => {
		return trainingSetFiles?.every(file => file.hasEmbeddings);
	}, [trainingSetFiles]);

	const filteredFiles = useMemo(() => {
		if (!trainingSetFiles) return [];

		return trainingSetFiles.filter(file => {
			return file.originalFilename.toLowerCase().includes(searchValue.toLowerCase());
		});
	}, [trainingSetFiles, searchValue]);

	const handleDeleteFile = (file?: TrainingSetMedia): void => {
		if (!file) return;
		if (isTrainingVideo(file)) {
			deleteVideo({
				variables: {videoId: file.id},
				onError: () =>
					updateToast({
						type: "failure",
						description: "Failed to delete, please try again later",
					}),
				onCompleted: data => {
					if (!data.deleteTrainingSetVideo) {
						return updateToast({
							type: "failure",
							description: "Failed to delete, please try again later",
						});
					}
					removeVideoFromTrainingSet({
						// Only returns a boolean, probably should update API
						variables: {videoId: file.id, trainingSetId},
						onCompleted: () =>
							updateToast({
								description: "File deleted",
								type: "informational",
							}),
						onError: () =>
							updateToast({
								type: "failure",
								description: "File deleted but it will take time to reflect here",
							}),
					});
				},
			});
			return setItemSelected(undefined);
		}
		deleteFile({
			variables: {fileId: file.id},
			onError: () =>
				updateToast({
					type: "failure",
					description: "Failed to delete, please try again later",
				}),
			onCompleted: data => {
				if (!data.deleteTrainingSetFile) {
					return updateToast({
						type: "failure",
						description: "Failed to delete, please try again later",
					});
				}
				removeFileFromTrainingSet({
					// Only returns a boolean, probably should update API
					variables: {fileId: file.id, trainingSetId},
					onCompleted: () => updateToast({description: "File deleted", type: "informational"}),
					onError: () =>
						updateToast({
							type: "failure",
							description: "File deleted but it will take time to reflect here",
						}),
				});
			},
		});
		setItemSelected(undefined);
	};

	const handleDeleteSelectedFiles = (): void => {
		selectedFiles.forEach(file => {
			handleDeleteFile(file.original);
		});
		setShowBulkDelete(false);
	};

	const columns = useMemo(
		(): Column<TrainingSetMedia>[] => [
			{
				Header: "Name",
				accessor: "originalFilename",
				Cell: data => {
					const file = data.row.original;
					return (
						<a href={file.url} target="_blank" className={styles.fileLink} rel="noreferrer">
							<Body size="s" color="text-secondary" className={styles.overflow}>
								{file.originalFilename}
							</Body>
						</a>
					);
				},
			},
			{
				Header: "Type",
				accessor: "originalMimetype",
				Cell: data =>
					data.value && (
						<Body size="s" className={styles.overflow} color="text-secondary">
							{data.value}
						</Body>
					),
			},
			{
				Header: "Status",
				Cell: data => {
					const row = data.row.original;

					if (row.hasEmbeddings) {
						return <Icon name="green-checkmark" />;
					}
					const fileOrVideo = isTrainingVideo(row) ? "Video" : "File";

					return (
						<Tooltip
							content={
								<Body size="xs">
									{fileOrVideo} is being processed. We&apos;ll let you know when is ready.
								</Body>
							}
						>
							<Loader size="medium" />
						</Tooltip>
					);
				},
			},
			{
				id: "options",
				Header: "",
				maxWidth: 50,
				disableSortBy: true,
				Cell: data => {
					const file = data.row.original;
					return (
						<AlignText align="right">
							<Dropdown
								trigger={<DotsIcon className={styles.trigger} />}
								items={
									[
										isCSVorXLSX(file) &&
											trainingSet?.name && {
												label: "Open In Data Studio",
												onClick: () => {
													const url = `https://datastudio.vurvey.dev/?workspace_id=${workspace?.id}&file_id=${file?.id}&dataset_id=${trainingSet?.name}&file_name=${encodeURIComponent(file?.originalFilename)}`;
													window.open(url, "_blank");
												},
												color: "primary",
											},
										{
											label: "Delete",
											onClick: () => setItemSelected(file),
											color: "danger",
											icon: <BinDeleteIcon />,
										},
									].filter(Boolean) as DropdownItem[]
								}
								position="bottom-start"
							/>
						</AlignText>
					);
				},
			},
		],
		[workspace.id, trainingSetId, trainingSet],
	);

	const actions: DropdownOptions[] = [
		{
			name: "Delete",
			actionOptions: {
				onClick: () => setShowBulkDelete(true),
			},
		},
	];

	const openInCopilot = (): void => {
		navigate(
			{
				pathname: "/workflow/conversation",
				search: {trainingSetIds: [trainingSet.id]},
			},
			{workspace: true},
		);
	};

	const handleStartPolling = (): void => {
		setShouldPoll(true);
		startPolling(POLL_INTERVAL);
	};

	if (loading) {
		return <LoadingContainer />;
	}

	return (
		<>
			<div className={styles.container}>
				<NavLink to="/datasets" className={styles.backArrowWrapper} workspace omitSearch>
					<ChevronLeftIcon className={styles.backIcon} />
				</NavLink>
				<div className={styles.introSection}>
					<Subheader size="xl" type="medium">
						{trainingSet?.alias}
					</Subheader>
					<Body className={styles.description} type="regular" color="text-secondary">
						{trainingSet?.description}
					</Body>
				</div>

				<div className={styles.tableActions}>
					<SearchInput className={styles.searchInput} value={searchValue} onChange={setSearchValue} />
					<div className={styles.actionsWrapper}>
						{selectedFiles.length > 0 && (
							<ActionsButton text={`Bulk actions (${selectedFiles.length})`} options={actions} />
						)}

						<FileUploader
							trainingSet={trainingSet}
							refetchQuery={GET_TRAINING_SET}
							startPolling={handleStartPolling}
						/>
						<Tooltip
							content={
								<Body size="xs">
									All files and videos must have been processed before starting conversation
								</Body>
							}
						>
							<Button
								disabled={!allHasEmbeddings}
								style="ai"
								onClick={openInCopilot}
								leftIcon={<SparkAiStarsIcon />}
								size="small"
							>
								Start a Conversation
							</Button>
						</Tooltip>
					</div>
				</div>

				<Table
					columns={columns}
					data={filteredFiles}
					selectedValues={selectedFiles}
					onSelectChange={setSelectedFiles}
				/>
				<ConfirmActionModal
					title="Delete File"
					isOpen={Boolean(itemSelected)}
					onClose={() => setItemSelected(undefined)}
					onConfirm={() => handleDeleteFile(itemSelected)}
					description="Are you sure you want to delete this file?"
				/>
				<ConfirmActionModal
					title="Delete Files"
					isOpen={showBulkDelete}
					onClose={() => setShowBulkDelete(false)}
					onConfirm={() => handleDeleteSelectedFiles()}
					description="Are you sure you want to delete these files? (May take some time to fully delete)"
				/>
			</div>
		</>
	);
};
