import React, {ReactElement, useContext, useState} from "react";
import {SEND_SURVEY_TO_MEMBERS} from "../../../graphql/mutations/survey-mutations";
import {Emailer} from "../../../shared/components/emailer";
import {StatusBanner} from "../../components/status-banner";
import {SendSurveyResult} from "../../../models/survey";
import {ToastContext} from "../../../context/toast-context";
import {SurveyContext} from "../../../context/survey-context";
import {useEmailer} from "../../../hooks/useEmailer";
import {useMutation} from "@apollo/client";
import styles from "./emailer.module.scss";
import {UserContext} from "../../../context/user-context";
import {Setter} from "../../../types";
import {SurveyMemberStatus} from "../../../models/user";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {BaseModalProps, Button, Modal} from "../../../shared/v2";

export interface EmailerModalProps extends BaseModalProps {
	selectedUsers: string[];
	selectAllState: [boolean, Setter<boolean>];
	status: SurveyMemberStatus | undefined;
}

const EmailModal = (props: EmailerModalProps): ReactElement => {
	const {isOpen, onClose, selectedUsers, selectAllState, status} = props;
	const [selectAll, setSelectAll] = selectAllState;
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const [sending, setSending] = useState(false);
	const {survey: {id}} = useContext(SurveyContext);
	const {user: {id: userId}} = useContext(UserContext);
	const {updateToast} = useContext(ToastContext);

	const emailerProps = useEmailer(workspaceId, userId);
	const {resetValues, ...emailer} = emailerProps;

	const [sendSurveyToMembers] = useMutation<SendSurveyResult>(SEND_SURVEY_TO_MEMBERS);

	const handleSubmit = (): void => {
		setSending(true);
		sendSurveyToMembers({
			variables: {
				surveyId: id,
				customMessage: emailer.message,
				senderId: emailer.from || undefined,
				jobTitle: emailer.jobTitle,
				useUserIds: true,
				userIds: selectedUsers,
				allowResend: true,
				useFilter: selectAll,
				filter: {status},
				skipPartials: false,
			},
			onError: () => {
				setSending(false);
				updateToast({description: "An error occurred, please try again later", type: "failure"});
			},
			onCompleted: amt => {
				updateToast({description: `${amt.sendSurveyToMembers} email(s) sent`,
					type: "informational"});
				resetValues();
				onClose();
				setSending(false);
				setSelectAll(false);
			},
		});
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title="Email Creators"
			size="medium"
		>
			<StatusBanner />
			<Emailer {...emailer} />
			<div className={styles.cta}>
				<Button
					onClick={handleSubmit}
					disabled={sending}
				>
					Send emails
				</Button>
			</div>
		</Modal>
	);
};

export {EmailModal};
