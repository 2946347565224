import React, {ReactElement} from "react";
import styles from "./interaction-counter.module.scss";
import classNames, {Argument} from "classnames";

export interface InteractionCounterProps {
	/**
	 * The amount of interactions to display
	 */
	count: number;
	/**
	 * The icon to display
	 */
	icon: JSX.Element;
	/**
	 * If the icon should come first or counter should come first.
	 * Default (false), counter comes first. If true the icon comes first
	 */
	reverse?: boolean;
	className?: Argument
}

 
const InteractionCounter = ({count, icon, reverse, className}: InteractionCounterProps): ReactElement => (
	<div
		className={classNames(styles.container, className)}
		style={{flexDirection: reverse ? "column-reverse" : "column"}}
	>
		<span
			style={{margin: reverse ? "10px 0 0" : "0 0 10px"}}
			className={styles.count}
		>
			{count}
		</span>
		{icon}
	</div>
);

export {InteractionCounter};
