import {
	Clip,
	ClipUpdateData,
	ClipUpdateVars,
} from "../../models/clip";
import {MultiRangeSlider} from "../../shared";
import React, {ReactElement, useContext, useEffect, useRef, useState} from "react";
import {useApolloClient, useMutation} from "@apollo/client";
import {CLIP_FRAGMENT} from "../../graphql/fragments/fragments";
import {UPDATE_CLIP} from "../../graphql/mutations/clip-mutations";
import {ClipTranscript} from "../../modal-components/clip-transcript";
import {VideoClip} from "../../reels/components/video-clip";
import {ToastContext} from "../../context/toast-context";
import styles from "./clip-editor.module.scss";
import {convertTimeFromMiliseconds} from "../../shared/utility/utility";
import {TimeIndicator} from "../../reels/components/time-indicator";
import {ClipBar} from "../../modal-components/clip-bar";
import {ReelContext} from "../../context/reel-context";
import {Button, RadioGroup, Modal, BaseModalProps} from "../../shared/v2";

export interface ClipEditorModalProps extends BaseModalProps {
	clipId?: string;
}

const ClipEditorModal = (props: ClipEditorModalProps): ReactElement | null => {
	const {clipId, isOpen, onClose} = props;
	const client = useApolloClient();
	const rangeRef = useRef<HTMLDivElement>(null);
	const barRef = useRef<HTMLDivElement>(null);
	const {updateToast} = useContext(ToastContext);
	const {reelId} = useContext(ReelContext);
	const [updateClip] = useMutation<ClipUpdateData, ClipUpdateVars>(UPDATE_CLIP);

	/**
	 * May change how this is done, but didn't want to add another query if not needed.
	 * Essentially, all the data we need should be in the cache already. This just gets it for us
	 */
	const clip = client.readFragment<Clip>({id: `Clip:${clipId}`, fragment: CLIP_FRAGMENT});

	const [startTime, setStartTime] = useState(clip?.startTime || 0);
	const [endTime, setEndTime] = useState(clip?.endTime || 0);
	const [isPlaying, setIsPlaying] = useState(false);
	const [isBuffering, setIsBuffering] = useState(false);
	const [showInput, setShowInput] = useState<"transcript" | "range">("transcript");

	useEffect(() => {
		if (!isOpen) {
			setStartTime(0);
			setEndTime(0);
			return;
		}
		if (clip) {
			setStartTime(clip.startTime);
			setEndTime(clip.endTime);
		}
	}, [clip, isOpen]);


	const handleChange = (min: number, max: number): void => {
		if (min !== startTime) setStartTime(min);
		if (max !== endTime) setEndTime(max);
	};

	const togglePlaying = (): void => setIsPlaying(prev => !prev);

	const handleCloseAndReset = (): void => {
		setIsPlaying(false);
		setIsBuffering(false);
		setStartTime(0);
		setEndTime(0);
		setShowInput("transcript");
		onClose();
	};

	const handleUpdate = (): void => {
		if (!clip) return;
		const prevStart = clip.startTime;
		const prevEnd = clip.endTime;
		updateClip({
			variables: {id: clip.id, changes: {startTime, endTime}},
			onCompleted: () => {
				updateToast({description: "Clip updated", type: "informational"});
				onClose();
			},
			update(cache, {data}) {
				if (!data) return;
				cache.modify({
					id: `Clip:${data.updateClip.id}`,
					fields: {
						startTime() {
							return data.updateClip.startTime;
						},
						endTime() {
							return data.updateClip.endTime;
						},
					},
				});
				// Update reel duration.
				cache.modify({
					id: `Reel:${reelId}`,
					fields: {
						duration(current = 0) {
							const newTotal = endTime - startTime;
							const oldTotal = prevEnd - prevStart;
							const difference = newTotal - oldTotal;
							if (difference < 0) return current - Math.abs(difference);
							// we know current is only a number but sometimes typescript dumb
							return current as number + difference;
						},
					},
				});
			},
		});
	};

	const handleUpdateClip = (start: number, end: number): void => {
		setStartTime(start);
		setEndTime(end);
	};

	if (!clip) return null;
	return (
		<Modal
			isOpen={isOpen}
			onClose={handleCloseAndReset}
			size="large"
			title={clip.answer ? `${clip.answer?.user?.creatorTag}`
				: "Uploaded Video"}
			className={styles.modal}
		>
			<div className={styles.container}>
				{clip.answer && <p className={styles.from}>from {clip.answer.question?.survey.name}</p>}

				<div className={styles.video}>
					<VideoClip
						video={clip.answer?.video.mp4 || clip.sourceVideo?.mp4 || ""}
						startTime={startTime}
						endTime={endTime}
						videoState={{isPlaying, isBuffering, setIsBuffering, togglePlaying}}
					/>
				</div>
				<div className={styles.progress}>
					<ClipBar
						min={0}
						max={clip.answer?.video.duration || clip.sourceVideo?.duration || 0}
						start={startTime}
						end={endTime}
						ref={barRef}
					/>
					{barRef.current && <TimeIndicator
						bar={barRef.current}
             
						time={convertTimeFromMiliseconds(endTime, "seconds") -
            convertTimeFromMiliseconds(startTime, "seconds")}
						isPlaying={isPlaying}
						isBuffering={isBuffering}
					/>}
				</div>
				{ (clip.answer || clip.sourceVideo.transcript) &&
          <RadioGroup
          	size="s"
          	value={showInput}
          	onChange={setShowInput}
          	className={styles.selector}
          >
          	<RadioGroup.Option label="Clip with transcript" value="transcript" />
          	<RadioGroup.Option label="Clip using slider" value="range" />
          </RadioGroup>
				}
				{
					showInput === "transcript" && (clip.answer || clip.sourceVideo?.transcript) ? (
						<div className={styles.transcript}>
							<h3>Transcript</h3>
							<ClipTranscript
								transcript={clip.answer?.video.transcript || clip.sourceVideo?.transcript}
								startTime={startTime}
								endTime={endTime}
								updateClip={handleUpdateClip}
							/>
						</div>
					) : (
						<div className={styles.slider}>
							<MultiRangeSlider
								min={0}
								// Tried and failed do a type where if answer isn't defined, we know sourceVideo is
								max={clip.answer?.video.duration || clip.sourceVideo?.duration || 10}
								minStartVal={startTime}
								maxStartVal={endTime}
								onChange={handleChange}
								ref={rangeRef}
							/>
						</div>
					)
				}
				<div className={styles.actions}>
					<Button onClick={handleUpdate}>Save</Button>
					<Button variant="outlined" onClick={onClose}>Cancel</Button>
				</div>
			</div>
		</Modal>
	);
};

export {ClipEditorModal};
