import React, {ReactElement, useContext, useMemo} from "react";
import {SurveyContext} from "../../../context/survey-context";
import {Survey, SurveyStatus} from "../../../models/survey";
import {Icon} from "../../../shared";
import styles from "./status-banner.module.scss";

const StatusBanner = (props: { overrideSurvey?: Survey | undefined }): ReactElement | null => {
	const {
		survey: {status: statusFromSurveyContext},
	} = useContext(SurveyContext);

	const status = useMemo(() => {
		return props.overrideSurvey?.status || statusFromSurveyContext;
	}, [props]);

	if (status !== SurveyStatus.CLOSED && status !== SurveyStatus.DRAFT) return null;
	return (
		<div className={styles.banner}>
			<Icon name="info"/>
			<span>Your campaign is {status === SurveyStatus.DRAFT ?
				<>in <strong>draft mode.</strong> Publish your campaign before sharing.</>
				: <><strong>closed.</strong> Set your campaign to live before sharing.</>
			}
			</span>
		</div>
	);
};

export {StatusBanner};
