import {MouseEvent, useState} from "react";

export interface UseDropdownReturn {
	/**
	 * The anchor element
	 */
	anchorEl: HTMLElement | null;
	/**
	 * Handle click callback
	 */
	handleClick: (e: MouseEvent<HTMLElement>) => void;
	/**
	 * Handle close callback
	 */
	handleClose: () => void;
}

/**
 * This hook could probably be better named overall.
 * It sets the anchor element for the dropdown for now.
 */
export const useDropdown = (): UseDropdownReturn => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	/**
	 * Sets the anchor element to the current target
	 */
	const handleClick = (e: MouseEvent<HTMLElement>): void => {
		setAnchorEl(e.currentTarget);
	};
	/**
	 * Resets the anchor element to null.
	 */
	const handleClose = (): void => {
		setAnchorEl(null);
	};
	return {
		anchorEl,
		handleClick,
		handleClose,
	};
};
