import React, {ReactElement} from "react";
import {Icon} from "../../shared";
import styles from "./warning-modal.module.scss";
import {BaseModalProps, Body, Button, Modal} from "../../shared/v2";

export interface WarningModalProps extends BaseModalProps {
	text: string;
	title?: string;
}

const WarningModal = ({isOpen, onClose, text, title}: WarningModalProps): ReactElement =>
	<Modal
		isOpen={isOpen}
		size="small"
		onClose={onClose}
		title={title}
	>
		<div className={styles.container}>
			<Icon name="warning" />
			<Body>{text}</Body>
			<Button onClick={onClose}>Close</Button>
		</div>
	</Modal>;

export {WarningModal};
