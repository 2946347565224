import React, {ReactElement} from "react";
import {Outlet} from "react-router";

import {ButtonGroup, ButtonGroupElement} from "../../../shared/components/navigation";
import {CopyDocumentsIcon, SparkAiStarsIcon} from "../../../icons";
import {PageSubNavigation} from "../../../hoc/layout/layout/page-sub-navigation";
import {NavigationPageLayout} from "../../../hoc/layout/navigation-page-layout";
import {NavigationTooltip} from "../../../hoc/layout/navigation/navigation-tooltip";

export const DatasetsPage = (): ReactElement => {


	return <NavigationPageLayout>
		<PageSubNavigation header="Datasets">
			<ButtonGroup>
				<NavigationTooltip text="Examine the datasets you have uploaded. Check for completeness, accuracy, and relevancy. Here, you can view a summary of your data, including the number of records, fields, and any detected patterns or anomalies.">
					<ButtonGroupElement
						icon={<CopyDocumentsIcon />}
						text="All Datasets"
						to=""
						end
					/>
				</NavigationTooltip>
				<NavigationTooltip text="View emerging topics, sentiment, and themes to discover opportunities">
					<ButtonGroupElement
						icon={<SparkAiStarsIcon />}
						text="Magic Summaries"
						to="magic-summaries"
					/>
				</NavigationTooltip>
			</ButtonGroup>
		</PageSubNavigation>

		<Outlet />
	</NavigationPageLayout>;
}
