import {useMutation} from "@apollo/client";
import React, {ReactElement, useCallback, useContext, useEffect, useState} from "react";

import {
	CREATE_CHOICE,
	DELETE_CHOICE,
	MOVE_CHOICE,
	UPDATE_CHOICE,
	UPDATE_QUESTION,
} from "../../../graphql/mutations/survey-mutations";
import {Body, Button, Checkbox} from "../../../shared/v2";
import {CHOICE_FRAGMENT} from "../../../graphql/fragments/fragments";
import {Choice} from "../../../models/choice";
import {ChoiceQuestion, QuestionSubType} from "../../../models/questions";
import {OptionCard} from "../option-card";
import {PlusIcon} from "../../../icons";
import {ToastContext} from "../../../context/toast-context";

import styles from "./option-list.module.scss";

export interface OptionListProps {
	question: ChoiceQuestion;
	questionType: QuestionSubType;
}

/**
 * The option list that can drag and drop items
 * Converted to not take in as many arguments, as most of the mutations
 * are only used with the options list.
 */
const OptionList = (props: OptionListProps): ReactElement => {
	const {question, questionType} = props;
	const {updateToast} = useContext(ToastContext);
	const [optionsList, setOptionsList] = useState<Choice[]>(() => question.choices);

	const [createChoice] = useMutation(CREATE_CHOICE);
	const [updateChoice] = useMutation(UPDATE_CHOICE);
	const [updateQuestion] = useMutation(UPDATE_QUESTION);
	const [moveChoice] = useMutation(MOVE_CHOICE);
	const [deleteChoice] = useMutation(DELETE_CHOICE);

	/** *****************
	 * Mutations
	 ***************** */
	const handleAddOption = (): void => {
		createChoice({
			variables: {
				input: {
					questionId: question.id,
					index: question.choices?.length,
					text: "New Option",
				},
			},
			onError: error => {
				if (error.networkError) {
					 
					updateToast({description: "You are having connectivity issues", type: "failure"});
					return;
				}
				if (error.graphQLErrors[0]?.extensions?.name === "InvalidOperationError") {
					updateToast({description: "Max amount of choices reached", type: "failure"});
				}
			},
			update(cache, {data}) {
				const newOptionRef = cache.writeFragment({
					data: data.createChoice,
					fragment: CHOICE_FRAGMENT,
				});
				cache.modify({
					id: `Question:${question.id}`,
					fields: {
						choices(existingChoices = []) {
							return [...existingChoices, newOptionRef];
						},
					},
				});
			},
		});
	};
	// Delete
	const handleDeleteOption = (id: string): void => {
		deleteChoice({
			variables: {id},
			update(cache, {data}) {
				cache.modify({
					id: `Question:${question.id}`,
					fields: {
						choices(existingChoices = [], {readField}) {
							const items = [...existingChoices];
							const updatedChoices = items.filter(
								ref => {
									return data.deleteChoice.id !== readField("id", ref);
								},
							);
							return updatedChoices;
						},
					},
				});
				cache.evict({id: `Choice:${data.deleteChoice.id}`, broadcast: false});
				cache.gc();
			},
		});
	};

	// Reordering
	const handleChangeOrder = (choice: Choice, dragIndex: number, dropIndex: number): void => {
		moveChoice({
			variables: {id: choice.id, index: dropIndex},
			optimisticResponse: {
				moveChoice: {
					id: choice.id,
					index: dropIndex,
				},
			},
			update(cache) {
				cache.modify({
					id: `Question:${question.id}`,
					fields: {
						choices(existingChoices = []) {
							const choices = [...existingChoices];
							choices.splice(dropIndex, 0, choices.splice(dragIndex, 1)[0]);
							return choices;
						},
					},
				});
			},
		});
	};

	// Rename option
	const handleRenameOption = (newValue: string, id: string): void => {
		updateChoice({
			variables: {
				id,
				changes: {text: newValue},
			},
			optimisticResponse: {
				updateChoice: {
					id,
					text: newValue,
				},
			},
		});
	};

	// The Shuffle
	const handleShuffle = (e: React.ChangeEvent<HTMLInputElement>): void => {
		updateQuestion({
			variables: {id: question.id, changes: {shuffle: e.target.checked}},
		});
	};

	/**
	 * @param dragIndex Index of currently grabbed card
	 * @param hoverIndex Index of hovered over card.
	 */
	const dragCard = useCallback((dragIndex: number, hoverIndex: number): void => {
		const copiedList = question.choices.slice();
		copiedList.splice(hoverIndex, 0, copiedList.splice(dragIndex, 1)[0]);
		setOptionsList(copiedList);
	}, [question.choices]);
	/**
	 * Callback function to handle when user drops a card into a valid spot on the
	 * card list.
	 * @param dragIndex Index of the dragged item
	 * @param hoverIndex Index where we dropped the item
	 */
	const moveCard = useCallback((dragIndex: number, hoverIndex: number): void => {
		const option = question.choices[dragIndex];
		handleChangeOrder(option, dragIndex, hoverIndex);
	}, [question.choices]);

	useEffect(() => {
		setOptionsList(question.choices);
	}, [question.choices, setOptionsList]);
	return (
		<>
			{
				optionsList.map((choice, i) => <OptionCard
					onRename={handleRenameOption}
					key={choice.id}
					index={i}
					choice={choice}
					onDelete={handleDeleteOption}
					dragCard={dragCard}
					moveCard={moveCard}
					canDelete={question.choices.length > 2}
					choiceType={questionType}
				/>)
			}
			<Button
				className={styles.addButton}
				leftIcon={<PlusIcon />}
				onClick={handleAddOption}
				variant="outlined"
				id="add-choice"
			>
				Add Choice
			</Button>
			<Checkbox
				className={styles.checkbox}
				id="shuffle-checkbox"
				size="s"
				checked={question.shuffle}
				onChange={handleShuffle}
				text={(
					<Body size="xs">
						Randomize options when responding
					</Body>
				)}
			/>
		</>
	);
};

export {OptionList};
