import React, {ImgHTMLAttributes, ReactElement} from "react";

export interface ImageWithAltProps extends Omit<ImgHTMLAttributes<HTMLImageElement>, "onError"> {
  src: string;
  children: ReactElement;
}

export const ImageWithAlt = ({children, ...props}: ImageWithAltProps): ReactElement => {
	const [isError, setIsError] = React.useState(false);

	return isError ? children : (
		<img {...props} onError={() => {
			setIsError(true);
		}} />
	)
}
