import React, {ReactElement, ReactNode, createContext} from "react";

export interface ChatFollowUpsContextValue {
  followUps: string[];
  setFollowUps: (followUps: string[]) => void;
}

export const ChatFollowUpsContext =
  createContext<ChatFollowUpsContextValue | undefined>(undefined);

export const ChatFollowUpsContextProvider = (
	{children}: {children: ReactNode},
): ReactElement => {
	const [followUps, setFollowUps] = React.useState<string[]>([]);

	return (
		<ChatFollowUpsContext.Provider value={{followUps, setFollowUps}}>
			{children}
		</ChatFollowUpsContext.Provider>
	);
};

export const useChatFollowUpsContext = (): ChatFollowUpsContextValue => {
	const context = React.useContext(ChatFollowUpsContext);

	if (context === undefined) {
		throw new Error(
			"useChatFollowUpsContext must be used within a ChatFollowUpsContextProvider",
		);
	}

	return context;
};
