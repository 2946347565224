import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {SurveyTemplateCategory, SurveyTemplateCategoryGroup} from "../../../models/survey";
import {useThemeMode} from "../../../context/theme-mode-context";
import {Body, Subheader} from "../../../shared/v2/typography";

import styles from "./survey-template-group.module.scss";

const bStyles = classNames.bind(styles);

export interface SurveyTemplateGroupProps {
	group: SurveyTemplateCategoryGroup;
	activeCategory?: SurveyTemplateCategory;
	changeCategory: (category: SurveyTemplateCategory) => void;
}

export const SurveyTemplateGroup = (props: SurveyTemplateGroupProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {group, activeCategory, changeCategory} = props;

	return (
		<div className={styles.group}>
			<Subheader className={styles.groupName} type="semibold">{group.name}</Subheader>
			<div className={styles.categories}>
				{group.categories.map(category => {
					return <div
						className={bStyles("category", {
							active: activeCategory === category,
							isDarkMode,
						})}
						key={category.id}
						onClick={() => changeCategory(category)}
					>
						<Body size="s" color="text-secondary">{category.name}</Body>
						<Body size="s" color="text-secondary">{category?.templates.length}</Body>
					</div>;
				})}
			</div>
		</div>
	);
};
