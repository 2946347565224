 
import classnames, {Argument} from "classnames";
import React, {ReactElement} from "react";

export interface PlayStopProps {
	/**
	 * If we are playing
	 */
	isPlaying: boolean;
	/**
	 * Action to take on pressing button
	 */
	onClick: () => void;
	/**
	 * Fill color if needed
	 */
	fill?: string;
	className?: Argument;
}

const PlayStop = ({isPlaying, fill, onClick, className}: PlayStopProps): ReactElement =>
	<svg
		onClick={onClick}
		width={24}
		height={24}
		className={classnames(className)}
		viewBox={"0 0 28 28"}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		stroke="black"
		strokeWidth="2px"
	>
		{
			isPlaying ? (
				<rect x="4" y="4" width="24" height="24" rx="3" fill={fill || "black"}/>
			) : (
				<>
					<path d="M23.2509 12.2669L8.0506 2.88324C6.71814 2.06067 5 3.01917 5 4.58507V23.4045C5 24.9726 6.72251 25.9307 8.05481 25.1037L23.2551 15.6679C24.5172 14.8845 24.5149 13.0472 23.2509 12.2669Z" fill={fill || "black"}/>
					<path d="M23.2509 12.2669L8.0506 2.88324C6.71814 2.06067 5 3.01917 5 4.58507V23.4045C5 24.9726 6.72251 25.9307 8.05481 25.1037L23.2551 15.6679C24.5172 14.8845 24.5149 13.0472 23.2509 12.2669Z" fill={fill || "white"}/>
				</>
			)
		}
	</svg>;

export {PlayStop};
