import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import {FileUploadIcon} from "../../../../../icons";
import {useThemeMode} from "../../../../../context/theme-mode-context";

import styles from "./upload-circles.module.scss";

const bStyles = classNames.bind(styles);

export const UploadCircles = (): ReactElement => {
	const {isDarkMode} = useThemeMode();
	return <div className={bStyles("circle1", {isDarkMode})}>
		<div className={bStyles("circle2", {isDarkMode}) }>
			<FileUploadIcon className={bStyles("fileUploadIcon", {isDarkMode})}/>
		</div>
	</div>;
};
