/* eslint-disable @typescript-eslint/no-explicit-any*/

import {UploadFileVars, UploadItemData} from "../models/questions";
import {
	FetchResult,
	MutationFunctionOptions,
	OperationVariables,
	useMutation,
} from "@apollo/client";
import {FileType, checkFileType} from "../shared/utility";
import React, {useState} from "react";
import {UPLOAD_FILE} from "../graphql/mutations/mutations";
import {Setter} from "../types";

export interface MediaState {
	preview: string;
	raw: File | undefined;
	type: FileType;
}

export interface UseMediaUploadReturn {
	/**
	 * Current media. State value
	 */
	media: MediaState;
	setMedia: Setter<MediaState>
	/**
	 * If there is an error
	 */
	hasError: boolean;
	setHasError: Setter<boolean>;
	/**
	 * Processing state
	 */
	isProcessing: boolean;
	/**
	 * Clears any media values we have set
	 */
	clearMedia: () => void;
	/**
	 * Setter function for the processing state
	 */
	setIsProcessing: React.Dispatch<React.SetStateAction<boolean>>;
	/**
	 * Handle change event callbacks
	 */
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	/**
	 * Handles when clicking on "save" to submit
	 */
	handleSave: (createM: (options?: MutationFunctionOptions<any, OperationVariables> | undefined) =>
	Promise<FetchResult<any, Record<string, any>, Record<string, any>>>) => Promise<any>;
	/**
	 * The ID of the media created
	 */
	mediaId?: string;
}
/**
 * @param createImage Mutation to use for image creation.
 * @returns UseMediaUploadReturn
 */
export const useMediaUpload = (fileSupport?: "video" | "image"): UseMediaUploadReturn => {
	const [media, setMedia] = useState<MediaState>({
		preview: "",
		raw: undefined,
		type: undefined,
	});
	const [mediaId, setMediaId] = useState<string | undefined>(undefined);
	const [hasError, setHasError] = useState<boolean>(false);
	const [isProcessing, setIsProcessing] = useState<boolean>(false);

	const [uploadFile] = useMutation<UploadItemData, UploadFileVars>(UPLOAD_FILE);

	/**
	 * This function will allow you to clear the image if need be
	 */
	const clearMedia = (): void => {
		setMediaId(undefined);
		if (media.raw || media.preview) {
			setMedia({
				preview: "",
				raw: undefined,
				type: undefined,
			});
		}
	};

	/**
	 * Handles when user changes what is being uploaded in the input
	 * @param event contains the files we want
	 */
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		if (!e?.target.files?.length) return;
		setHasError(false);
		const [file] = e.target.files;
		const {isSupported, fileType} = checkFileType(file.type);
		if (!isSupported) return setHasError(true);
		if (!fileSupport) {
			return setMedia(() => ({
				preview: URL.createObjectURL(file),
				raw: file,
				type: fileType,
			}));
		}
		if (fileSupport === fileType) {
			return setMedia(() => ({
				preview: URL.createObjectURL(file),
				raw: file,
				type: fileType,
			}));
		}
		return setHasError(true);
	};

	/**
	 * Handles the upload (when clicking on save)
	 */

	const handleSave = async(createM: (options?: MutationFunctionOptions<any, OperationVariables> | undefined) =>
	Promise<FetchResult<any, Record<string, any>, Record<string, any>>>): Promise<any> => {
		if (!media.raw) return;
		setIsProcessing(true);
		const {data: file} = await uploadFile({
			variables: {file: media.raw},
		});
		const id = file?.upload?.id;
		if (!id) return;
		const {data} = await createM({
			variables: {uploadItemId: id},
		});
		if (data) {
			const [value] = Object.keys(data);
			setMediaId(data[value].id);
			setIsProcessing(false);
			return data[value].id;
		}
	};

	return {
		media,
		setMedia,
		hasError,
		setHasError,
		isProcessing,
		clearMedia,
		setIsProcessing,
		handleChange,
		handleSave,
		mediaId,
	};
};
