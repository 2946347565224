import {Resolvers, gql} from "@apollo/client";

export const typeDefs = gql`
	extend type Query {
		# isLoggedIn: Boolean!
		# me: User
		selectedUser: GUID!
		selectedSurvey: GUID!
		toggleShowUserModal: Boolean!
		toggleShowInviteModal: Boolean!
		toggleShowFilter: Boolean!
		toggleShowUploadModal: Boolean!
		toggleShowRemoveMemberModal: Boolean!
	}

	extend type Mutation {
		Me: User
		SelectedUser: GUID!
		SelectedSurvey: GUID!
		ToggleDeleteSurveyModal: Boolean!
		ToggleShowUserModal: Boolean!
		ToggleShowInviteModal: Boolean!
		ToggleShowUploadModal: Boolean!
		ToggleShowFilter: Boolean!
		ToggleShowRemoveMemberModal: Boolean!
	}
	extend type SurveyPage {
		totalHours: Int
		totalResponders: Int
	}
`;

export const resolvers: Resolvers | Resolvers[] = {
	SurveyPage: {
		totalHours: () => 350,
		totalResponders: () => 100,
	},
};
