import {checkValidTag} from "./utility";

/**
 * @param list The list we want to sift through
 *
 * @returns object with valid array and invalid array;
 */
export const siftEmails = (list: string[]): {validEmails: string[]; invalidEmails: string[]} => {
	const validEmails: string[] = [];
	const invalidEmails: string[] = [];
	let skip = false;
	/**
	 * Below forEach will skip a value if we find that a previous value doesn't
	 * equal a valid email. It also combine the value that wasn't an email with the
	 * next value by a space.
	 * IE: mkiemle,@gmail.com would become mkiemle @gmail.com in the textbox, not tagged.
	 */
	list.forEach((value, i) => {
		if (skip) {
			skip = false;
			return;
		}
		const isValid = checkValidTag(value, "email");
		if (isValid) {
			validEmails.push(value);
		} else if (list[i + 1]) {
			const together = `${value} ${list[i + 1]}`;
			invalidEmails.push(together);
			skip = true;
		} else {
			invalidEmails.push(value);
		}
	});
	return {validEmails, invalidEmails};
};
