import {gql} from "@apollo/client";

/**
 * Adds a brand new reel.
 */
export const ADD_REEL = gql`
	mutation CreateReel($input: NewReel!) {
		createReel(input: $input) {
			id
			name
			description
			creator {
				id
				name
			}
			createdAt
			duration
		}
	}
`;
/**
 * Duplicates a reel (!)
 */
export const DUPLICATE_REEL = gql`
	mutation DuplicateReel($id: GUID!) {
		duplicateReel(id: $id) {
			id
			name
			description
			creator {
				id
				name
			}
			createdAt
			duration
		}
	}
`;
/**
 * Deletes a reel.
 */
export const DELETE_REEL = gql`
	mutation DeleteReel ($id: GUID!) {
		deleteReel(id: $id) {
			id
			name
			description
			creator {
				id
				name
			}
		}
	}
`;

/**
 * Starts editing a reel
 * Returns the ID of the reel and the new list of editors
 */
export const START_EDITING_REEL = gql`
	mutation StartEditingReel ($id: GUID!) {
		startEditingReel (id: $id) {
			id
			editors {
				id
			}
		}
	}
`;

/**
 * Stops editing a reel
 * Returns the ID of the reel and the new list of editors.
 */
export const STOP_EDITING_REEL = gql`
	mutation StopEditingReel ($id: GUID!) {
		stopEditingReel (id: $id) {
			id
			editors {
				id
			}
		}
	}
`;

export const UPDATE_REEL = gql`
	mutation UpdateReel ($id: GUID! $changes: UpdateReelChanges $deletions: UpdateReelDeletions) {
		updateReel(id: $id changes: $changes deletions: $deletions) {
			id
			name
			description
			displayMode
			requiresPassword
			password
		}
	}
`;

/**
 * Publishes a reel. Returns the current videoStatus (published status)
 */
export const PUBLISH_REEL = gql`
	mutation PublishReel ($id: GUID!) {
		publishReel(id: $id) {
			id
			videoStatus
		}
	}
`;

/**
 * Logs feedback for a reel. Returns the log
 */
export const REEL_FEEDBACK = gql`
	mutation LogReelTracker ($reelId: GUID! $indicator: ReelTrackersIndicator!) {
		logReelTracker(reelId: $reelId, indicator: $indicator) {
			id
		}
	}
`;

export const SHARE_REEL_WITH_BRAND = gql`
	mutation ShareReelToBrand ($reelId: GUID! $share: Boolean!) {
		shareReelToBrand(reelId: $reelId share: $share) {
			id
			sharedInBrand
		}
	}
`;
