import React from 'react';
import {useMutation} from '@apollo/client';
import {useNavigate} from 'react-router';

import {CREATE_SURVEY} from '../../../../graphql/mutations/survey-mutations';
import {Button, Caption} from '../../../../shared/v2';
import {CreateSurveyReturn, CreateSurveyVars} from '../../../../models/survey';
import {Link} from '../../../../hoc/layout/navigation/link';
import {PlusSmallIcon} from '../../../../icons';
import {useToastContext} from '../../../../context/toast-context';
import {useWorkspaceContext} from '../../../../context/workspace-context';
import {CreateCard} from '../../../../shared/v2/cards';
import {GET_ALL_SURVEYS} from '../../../../graphql/queries/survey-queries';

export const CreateCampaignCard = () => {
	const {workspace: {id: workspaceId} = {id: ""}} = useWorkspaceContext();

	const navigate = useNavigate();
	const {onError} = useToastContext();

	const [createSurvey, {loading: createSurveyLoading}] = useMutation<CreateSurveyReturn, CreateSurveyVars>(CREATE_SURVEY, {
		onError,
		// TODO: instead of refetching all surveys, we should just add the new survey to the list
		refetchQueries: [GET_ALL_SURVEYS],
	});

	const handleCreateSurvey = (): void => {
		createSurvey({
			variables: {
				input: {
					workspaceId,
					name: "UNTITLED CAMPAIGN",
					incentiveAmount: "0", // default
				},
			},
			onCompleted: data =>
				navigate(`/survey/${data.createSurvey.id}/questions`),
		});
	};

	return (
		<CreateCard>
			<Button
				style='brand'
				leftIcon={<PlusSmallIcon />}
				onClick={handleCreateSurvey}
				disabled={createSurveyLoading}
			>
				{createSurveyLoading ? "Creating..." : "Create Campaign"}
			</Button>
			<Caption color="text-secondary" type="regular">
				or start with a {" "}
				<Link
					weight="medium"
					type="underline"
					to="/campaigns/templates"
					text="Template"
				/>
			</Caption>
		</CreateCard>
	);
}
