import {Link} from "react-router-dom";
import React, {ReactElement, useContext, useEffect} from "react";

import {Icon} from "../shared";
import {cache} from "../cache";
import {CreateWorkspaceStep} from "./components/create-workspace-step";
import {useAuthentication} from "../hooks/user/useAuthentication";
import {useNavigate} from "../route";
import {UserContext} from "../context/user-context";
import {UserStatus} from "../models/user";
import config from "../config";
import {Button} from "../shared/v2";

import styles from "./welcome-page.module.scss";

/**
 * The welcome page. Users should see this page if they have just created an account
 * and have no workspaces associated with them.
 *
 * May also see it if they have a workspace they
 * are associated with but do not have an email confirmed.
 * (might have to look into that)
 */

export interface WelcomePageProps {
	step?: string;
}

const WelcomePage = (props: WelcomePageProps): ReactElement => {
	const {
		user: {firstName, lastName, status, email},
		workspaces,
	} = useContext(UserContext);
	const navigate = useNavigate();
	const {user, logout, sendVerificationEmail} = useAuthentication();

	/**
	 * Handles logout.
	 * Simply calls the firebase logout hook
	 */
	const handleLogout = (): void => {
		cache.reset();
		logout();
		window.location.href = window.location.origin;
	};

	const handleResendEmail = (): void => {
		sendVerificationEmail();
	};

	useEffect(() => {
		if (!user?.emailVerified) {
			sendVerificationEmail();
		}
	}, [user?.emailVerified]);

	if (!user?.emailVerified) {
		return (
			<div className={styles.signUpContainer}>
				<h2 className={styles["confirm-header"]}>
					Verify your email address to complete sign-up
				</h2>
				<p className={styles.confirm}>
					Click the link in the confirmation email we just sent you. ({email}) After you
					click on the link in the email, refresh this page.
				</p>
				<span className={styles.banner}>
					<Icon name="warning" size="small" /> The email could have been sent to your
					spam, make sure to check it
				</span>
				<div className={styles.actions}>
					<Button onClick={handleResendEmail}>Resend Email</Button>
					<Link to="/welcome" className={styles.link} onClick={handleLogout}>
						Logout
					</Link>
				</div>
			</div>
		);
	}

	// If a newly signed up user reaches this page, we want to redirect them to getting started
	// Right now best way to check is if user has a workspace and that workspace has a default name.
	if (
		status === UserStatus.CONFIRMED &&
		workspaces.length > 0 &&
		workspaces[0].name !== `${firstName} ${lastName} - Default`
	) {
		navigate({
			pathname: "/getting-started",
			search: {workspaceId: workspaces[0].id},
		});
	}

	return (
		<div className={styles.container}>
			{props.step === "CreateWorkspace" ? (
				<CreateWorkspaceStep workspaces={workspaces} />
			) : (
				<>
					<div className={styles.introText}>
						<h2 className={styles.header}>Welcome to Vurvey for Brands</h2>
						<div className={styles.description}>
							Not a Brand? Looking to take a Vurvey? That&apos;s always Free!{" "}
							<a
								className={styles.enterprise}
								href={config.creatorHost}
								target="_blank"
								rel="noopener noreferrer"
							>
								Click Here to get started.
							</a>
						</div>
					</div>

					<div className={styles.plans}>
						<div className={styles.creatorInfo}>
							If you were invited to a Brand workspace, you need to contact your Brand
							administrator and have them add your email to the workspace or sign up with
							the email that was used to invite.

							<a className={styles.link} onClick={handleLogout}>
								&nbsp;Click Here to Logout
							</a>
						</div>
						<div className={styles.creatorInfo}>
							Looking for Enterprise?{" "}
							<a
								className={styles.enterprise}
								href="https://vurvey.ai/brand-sign-up"
								target="_blank"
								rel="noopener noreferrer"
							>
								Let&apos;s talk.
							</a>
						</div>
						<div className={styles.creatorInfo}>
							Looking for your creator profile?{" "}
							<a href={config.creatorHost}>Click here</a>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export {WelcomePage};
