import {DYNAMIC_SEGMENT_FRAGMENT, SEGMENT_FRAGMENT, USER_FRAGMENT} from "../fragments/fragments";
import {gql} from "@apollo/client";

export const TOGGLE_UPLOAD_MODAL = gql`
	mutation ToggleShowUploadModal {
		toggleShowUploadModal @client
	}
`;
export const TOGGLE_RECRUIT_MODAL = gql`
	mutation ToggleRecruitModal {
		toggleRecruitModal @client
	}
`;
export const TOGGLE_USER_MODAL = gql`
	mutation ToggleShowUserModal {
		toggleShowUserModal @client
	}
`;

export const SELECTED_USER = gql`
	mutation SelectedUser($id: GUID!) {
		selectedUser(id: $id) @client
	}
`;

export const SELECTED_RESPONSE = gql`
	mutation SelectedResponse($id: GUID!) {
		selectedResponse(id: $id) @client
	}
`;

export const SELECTED_SURVEY = gql`
	mutation SelectedSurvey($id: GUID!, $name: String!) {
		selectedSurvey(id: $id, name: $name) @client
	}
`;
export const TOGGLE_INVITE_MODAL = gql`
	mutation ToggleInviteModal {
		toggleShowInviteModal @client
	}
`;
export const TOGGLE_FILTER_PANEL = gql`
	mutation ToggleFilterPanel {
		toggleShowFilter @client
	}
`;

// May want to make a more general one
export const TOGGLE_REMOVE_MEMBER_MODAL = gql`
	mutation ToggleRemoveMemberModal {
		toggleShowRemoveMemberModal @client
	}
`;

export const CREATE_SEGMENT = gql`
	mutation CreateSegment($input: NewSegment!) {
		createSegment(input: $input) {
			...SegmentFields
		}
	}
	${SEGMENT_FRAGMENT}
`;

export const COMBINE_SEGMENTS = gql`
	mutation CombineSegments($ids: [GUID!]!) {
		combineSegments(ids: $ids) {
			...SegmentFields
		}
	}
	${SEGMENT_FRAGMENT}
`;

export const UPDATE_SEGMENT = gql`
	mutation UpdateSegment($id: GUID!, $changes: SegmentChanges!) {
		updateSegment(id: $id, changes: $changes) {
			id
			name
		}
	}
`;

export const DUPLICATE_SEGMENT = gql`
	mutation DuplicateSegment($id: GUID!) {
		duplicateSegment(id: $id) {
			...SegmentFields
		}
	}
	${SEGMENT_FRAGMENT}
`;

export const DELETE_SEGMENTS = gql`
	mutation DeleteSegments($ids: [GUID!]!) {
		deleteSegments(ids: $ids) {
			id
		}
	}
`;

export const DELETE_DYNAMIC_SEGMENTS = gql`
	mutation DeleteDynamicSegments($ids: [GUID!]!) {
		deleteDynamicSegments(ids: $ids) {
			id
		}
	}
`;

export const CREATE_DYNAMIC_SEGMENT = gql`
	mutation CreateDynamicSegment($input: NewDynamicSegment!) {
		createDynamicSegment(input: $input) {
			...DynamicSegmentFields
		}
	}
	${DYNAMIC_SEGMENT_FRAGMENT}
`;

export const DUPLICATE_DYNAMIC_SEGMENT = gql`
	mutation DuplicateDynamicSegment($id: GUID!) {
		duplicateDynamicSegment(id: $id) {
			...DynamicSegmentFields
		}
	}
	${DYNAMIC_SEGMENT_FRAGMENT}
`;

export const UPDATE_DYNAMIC_SEGMENT = gql`
	mutation UpdateDynamicSegment($id: GUID!, $changes: DynamicSegmentChanges!) {
		updateDynamicSegment(id: $id, changes: $changes) {
			...DynamicSegmentFields
		}
	}
	${DYNAMIC_SEGMENT_FRAGMENT}
`;

// Note: may want to make a new file along the lines of workspace-mutations.ts
export const CHANGE_REQUEST_STATUS = gql`
	mutation ChangeRequestStatus(
		$workspaceId: GUID!
		$userIds: [GUID!]
		$status: requestStatus!
		$useFilter: Boolean
		$filter: ContactsFilter
	) {
		changeRequestStatuses(
			workspaceId: $workspaceId
			userIds: $userIds
			status: $status
			filter: $filter
			useFilter: $useFilter
		) {
			userId
			status
		}
	}
`;

export const ADD_WORKSPACE_SEGMENT_MEMBERS = gql`
	mutation AddWorkspaceSegmentMembers(
		$segmentId: GUID!
		$userIds: [GUID!]
		$useContactsFilter: Boolean
		$contactsFilter: ContactsFilter
		$useAnswersFilter: Boolean
		$answersFilter: AnswersFilter
		$workspaceId: GUID!
	) {
		addSegmentMembers(
			segmentId: $segmentId
			userIds: $userIds
			useContactsFilter: $useContactsFilter
			contactsFilter: $contactsFilter
			useAnswersFilter: $useAnswersFilter
			answersFilter: $answersFilter
			workspaceId: $workspaceId
		) {
			segment {
				id
				workspaceId
				name
				memberCount
			}
			addedUsers {
				id
			}
			existingUsers {
				id
			}
		}
	}
`;
export const REMOVE_FROM_SEGMENT = gql`
	mutation RemoveFromSegment(
		$segmentId: GUID!
		$userIds: [GUID!]
		$useFilter: Boolean
		$filter: ContactsFilter
		$workspaceId: GUID!
	) {
		removeSegmentMembers(
			segmentId: $segmentId
			userIds: $userIds
			useFilter: $useFilter
			filter: $filter
			workspaceId: $workspaceId
		) {
			segment {
				id
				workspaceId
				name
				memberCount
			}
			users {
				id
			}
		}
	}
`;
export const SET_WORKSPACE_ROLE = gql`
	mutation SetWorkspaceRole($workspaceId: GUID!, $userId: GUID!, $role: WorkspaceRole!) {
		setWorkspaceMemberRole(workspaceId: $workspaceId, userId: $userId, role: $role) {
			workspace {
				id
				name
				myRole
			}
			user {
				id
				email
				workspaceRole
			}
		}
	}
`;
export const REMOVE_CONTACT = gql`
	mutation RemoveContact($workspaceId: GUID!, $userIds: [GUID!], $useFilter: Boolean, $filter: ContactsFilter) {
		removeContacts(workspaceId: $workspaceId, userIds: $userIds, useFilter: $useFilter, filter: $filter) {
			workspace {
				id
			}
			users {
				...UserFields
			}
		}
	}
	${USER_FRAGMENT}
`;

/* We probably won't need much more than the id returned from this, if anything. */
export const ADD_ATTRIBUTE_TO_USERS = gql`
	mutation AddAttributeToUsers($attributeId: GUID!, $value: String, $userIds: [GUID!]!, $ruleAction: RuleAction) {
		addAttributeValueToUsersByIds(
			attributeId: $attributeId
			value: $value
			userIds: $userIds
			ruleAction: $ruleAction
		) {
			id
		}
	}
`;

export const ADD_ATTRIBUTE_BY_CSV = gql`
	mutation AddAttributesByCSV($file: Upload!, $ruleAction: RuleAction, $workspaceId: GUID!) {
		addAttributeValuesFromCSV(file: $file, ruleAction: $ruleAction, workspaceId: $workspaceId)
	}
`;

export const INVITE_USERS = gql`
	mutation InviteUser($workspaceId: GUID!, $emails: [EmailAddress!]!, $role: WorkspaceRole!) {
		inviteEmailsToWorkspace(workspaceId: $workspaceId, emails: $emails, role: $role)
	}
`;

/**
 * Invite contacts by email to the workspace
 */
export const INVITE_CONTACTS = gql`
	mutation InviteContacts($workspaceId: GUID!, $emails: [EmailAddress!]!, $segmentId: GUID) {
		addContactsByEmail(workspaceId: $workspaceId, emails: $emails, segmentId: $segmentId)
	}
`;

export const LOGOUT = gql`
	mutation Logout {
		logout
	}
`;

/* Uploady boi */
export const UPLOAD_FILE = gql`
	mutation Upload($file: Upload!) {
		upload(file: $file) {
			id
			filename
			mimetype
			url
		}
	}
`;

/* Create an avatar */
export const CREATE_USER_PICTURE = gql`
	mutation CreateUserPicture($uploadItemId: String!) {
		createUserPicture(uploadItemId: $uploadItemId) {
			id
			transcodingStatus
		}
	}
`;

// Probably want to separate these better in the future (soon future)
export const CREATE_WORKSPACE_LOGO = gql`
	mutation CreateWorkspaceLogo($uploadItemId: String!) {
		createWorkspaceLogo(uploadItemId: $uploadItemId) {
			id
			transcodingStatus
		}
	}
`;

/**
 * Mutation for creating a brand new workspace.
 */
export const CREATE_WORKSPACE = gql`
	mutation CreateWorkspace($input: NewWorkspace!) {
		createWorkspace(input: $input) {
			id
			name
			logoId
			plan
			logo {
				id
				thumbnail: url(version: THUMBNAIL)
			}
			myRole
			permissions
		}
	}
`;

/**
 * Change features for workspaces
 */
export const MANAGE_WORKSPACES = gql`
	mutation ManageWorkspaces(
		$changes: WorkspaceChanges!
		$useFilter: Boolean!
		$filter: WorkspaceFilter
		$workspaceIds: [GUID!]
	) {
		manageWorkspaces(changes: $changes, useFilter: $useFilter, filter: $filter, workspaceIds: $workspaceIds)
	}
`;

/**
 * Change features for workspaces
 */
export const MANAGE_BRANDS = gql`
	mutation ManageWorkspaces($changes: BrandChanges!, $useFilter: Boolean!, $filter: BrandFilter, $brandIds: [GUID!]) {
		manageBrands(changes: $changes, useFilter: $useFilter, filter: $filter, brandIds: $brandIds)
	}
`;
