import {PUBLISH_SURVEY, PUBLISH_AND_EMAIL} from "../../graphql/mutations/survey-mutations";
import {formatLong, Icon} from "../../shared";
import React, {ReactElement, useCallback, useContext, useMemo} from "react";
import {SurveyContext} from "../../context/survey-context";
import {ToastContext} from "../../context/toast-context";
import styles from "./publish-confirm.module.scss";
import {useMutation, useQuery} from "@apollo/client";
import {useNavigate} from "../../route";
import {GET_PUBLISH_INFO} from "../../graphql/queries/survey-queries";
import {UserContext} from "../../context/user-context";
import {Body, Button, Subheader, Modal} from "../../shared/v2";

/**
 * Modal that will pop up when a user clicks on the "Publish" button on
 * the survey participant page
 * @param isShowing If the modal is showing or not
 * @param modalClosed What happens when user normally closes the modal
 * @param publishOnly What to do when user clicks on "Publish Only"
 * @param publishAnyNotify What to do when user clicks on "Publish and notify"
 *
 * @returns A modal that will require further user input in order to publish the
 * current survey.
 */
const PublishSurveyModal = (): ReactElement => {
	const {updateToast} = useContext(ToastContext);
	const {
		isShowingPublish,
		setShowingPublish,
		survey: {
			id,
			incentiveAmount,
			incentiveCurrency,
			multiResponse,
			language,
			accessLevel,
			closeAfterResponsesCount,
			closeAt,
			workspaceId,
		},
	} = useContext(SurveyContext);
	const {user} = useContext(UserContext);
	const {data} = useQuery(GET_PUBLISH_INFO, {
		variables: {surveyId: id},
		skip: !isShowingPublish,
		fetchPolicy: "cache-and-network",
	});
	const navigate = useNavigate();

	const [publishSurvey] = useMutation(PUBLISH_SURVEY, {
		onCompleted: () => {
			// Probably should do this in index.html?
			const host = window.location.hostname;
			if (host !== "localhost" && !host.includes("staging")) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(window as any).Vitally.track({
					event: "published-new-campaign",
					userId: user.id,
					properties: {
						accountId: workspaceId,
					},
				});
			}
			updateToast({description: "Vurvey opened", type: "informational"});
			setShowingPublish(false);
			navigate(`/survey/${id}/participants`, {workspace: true});
		},
	});
	const [publishAndEmailSurvey] = useMutation(PUBLISH_AND_EMAIL, {
		onCompleted: () => {
			updateToast({description: "Invites sent", type: "informational"});
			setShowingPublish(false);
			navigate(`/survey/${id}/participants`, {workspace: true});
		},
	});

	const handlePublish = (): void => {
		publishSurvey({variables: {id}});
	};

	const handlePublishAndEmail = (): void => {
		publishAndEmailSurvey({variables: {id}});
	};

	const currentLanguage = useMemo(() => {
		return data?.surveyLanguages?.find(lang => lang.id === language.id);
	}, [language.id, data]);


	const renderCloseTime = useCallback(() => {

		if (closeAt) return <Body><Icon name="checkmark" size="extrasmall"/> Closes on <b>{formatLong(closeAt)}</b></Body>;

		if (closeAfterResponsesCount) return <Body><Icon name="checkmark" size="extrasmall"/> Closes after <b>{closeAfterResponsesCount} responses</b></Body>;
		return <Body><Icon name="checkmark" size="extrasmall"/> Closes <b>manually</b></Body>;
	}, [closeAt, closeAfterResponsesCount]);

	return (
		<Modal
			isOpen={isShowingPublish}
			onClose={() => setShowingPublish(false)}
			size="small"
		>
			<div className={styles.container}>
				<Icon name="warning" />
				<div>
					<Subheader type="semibold" size="l">Confirm your campaign settings</Subheader>
					<div className={styles.list}>
						<Body><Icon name="checkmark" size="extrasmall"/>
							{" "}Incentive amount is <b>{incentiveAmount} {incentiveCurrency}</b>
						</Body>
						{renderCloseTime()}
						<Body><Icon name="checkmark" size="extrasmall"/>
							{" "}Responders can respond <b>{multiResponse ? "multiple times" : "once"}</b></Body>
						<Body><Icon name="checkmark" size="extrasmall"/>
							{" "} Language is set to <b>{currentLanguage?.name}</b></Body>
						<Body><Icon name="checkmark" size="extrasmall"/> Privacy is set to <b>{accessLevel}</b></Body>
					</div>
				</div>
				<div className={styles.actions}>
					<Button
						onClick={() => setShowingPublish(false)}
						variant="outlined"
					>
						Cancel
					</Button>
					<Button
						onClick={handlePublish}
					>
						{data?.surveyMembers?.items?.length > 0 ? "Publish Only" : "Confirm & Publish"}
					</Button>
					{data?.surveyMembers?.items?.length > 0 &&
						<Button
							onClick={handlePublishAndEmail}
						>
							Publish & Notfiy Participants
						</Button>}
				</div>
			</div>
		</Modal>
	);
};

export {PublishSurveyModal};
