/* eslint-disable @typescript-eslint/no-explicit-any*/

import {capitalize} from "lodash-es";

const validationFunctions: {
  [key in keyof ValidationRuleset]: (value: any, rule: Required<ValidationRuleset>[key]) => boolean;
} = {
	required: (value: any) => typeof value === 'string' ? value.trim().length > 0 : !!value,
	min: (value: any, min: number) => value.length >= min,
	max: (value: any, max: number) => value.length <= max,
	custom: (value: any, custom: (value: any) => boolean) => custom(value),
};

const validationErrorMessages: {
  [key in keyof ValidationRuleset]: (field: key, rule: Required<ValidationRuleset>[key], value: any) => string;
} = {
	required: (field: string) => `${capitalize(field)} field is required`,
	min: (field: string, min: number) => `${capitalize(field)} field must be at least ${min} characters`,
	max: (field: string, max: number) => `${capitalize(field)} field must be at most ${max} characters`,
	custom: (field: string) => `${capitalize(field)} field is invalid`,
};

export interface ValidationOption {
  ruleset: ValidationRuleset;
  customErrorMessage?: Partial<typeof validationErrorMessages>;
}

export type Validations<T> = {
  [key in keyof T]?: ValidationOption;
}

export interface ValidationRuleset {
  required?: boolean;
  min?: number;
  max?: number;
  custom?: (value: any) => boolean;
}

export interface ValidationError {
  value: any;
  field: string;
  rule: keyof ValidationRuleset;
  message: string;
}

export interface ValidatorResult {
  isValid: boolean;
  errors: ValidationError[];
}

export const validate = <T extends object>(
	object: T, validations: Validations<T>
): ValidatorResult => {
	const validationEntries = Object.entries(validations) as [keyof T, ValidationOption][];

	const newErrors = validationEntries.reduce((acc, [field, {ruleset}]) => {
		const messageConstructors = {
			...validationErrorMessages,
			...validations[field]?.customErrorMessage,
		};
		const rules = Object.entries(ruleset) as [keyof ValidationRuleset, Required<ValidationRuleset>[keyof ValidationRuleset]][];
		const value = object[field];
		const fieldErrors = rules.reduce((acc, [rule, ruleValue]) => {
			const isValid = validationFunctions[rule]?.(value, ruleValue as never);

			if (!isValid) {
				const message = messageConstructors[rule]?.(field as never, ruleValue as never, value);
				return [...acc, {value, field: field as string, rule, message}];
			}

			return acc;
		}, [] as any[]);

		return [...acc, ...fieldErrors];
	}, [] as ValidationError[]);

	return {
		isValid: newErrors.length === 0,
		errors: newErrors,
	};
}
