import {useEffect, useRef} from "react";

/**
 * Creates a DOM element to be used as a root
 * @param id ID to give div element
 * @returns {HTMLElement}
 */
const createRootElement = (id: string): HTMLDivElement => {
	const rootContainer = document.createElement("div");
	rootContainer.setAttribute("id", id);
	return rootContainer;
};

/**
 * Appends element as last child of body
 * @param {HTMLElement} rootElem
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addRootElement = (rootElem: any): void => {
	document.body.insertBefore(
		rootElem,
		document.body.lastElementChild?.nextElementSibling as Element,
	);
};

export const usePortal = (id: string): HTMLDivElement => {
	const rootElemRef = useRef<HTMLDivElement | null>();

	useEffect(() => {
		const existingParent = document.querySelector(`#${id}`);
		const parentElem = existingParent || createRootElement(id);

		if (!existingParent) {
			addRootElement(parentElem);
		}
		parentElem.appendChild(rootElemRef.current as HTMLDivElement);
		// parentElem?.appendChild(rootElemRef.current as HTMLDivElement);
		return () => {
			if (rootElemRef.current) {
				rootElemRef.current.remove();
			}
			if (parentElem.childElementCount === 0) {
				parentElem.remove();
			}
		};
	}, [id]);

	const getRootElem = (): HTMLDivElement => {
		if (!rootElemRef.current) {
			rootElemRef.current = document.createElement("div");
		}
		return rootElemRef.current;
	};

	return getRootElem();
};
