import React, {ReactElement, ReactNode, createContext, useContext, useEffect, useState} from "react";

export interface ConnectionHealthContextValue {
  isConnected: boolean;
}

export const ConnectionHealthContext =
  createContext<ConnectionHealthContextValue | undefined>(undefined);

export const ConnectionHealthContextProvider = (
	{children}: {children: ReactNode},
): ReactElement => {
	const [isConnected, setIsConnected] = useState(true);

	useEffect(() => {
		const handleOnline = () => setIsConnected(true);
		const handleOffline = () => setIsConnected(false);

		window.addEventListener("online", handleOnline);
		window.addEventListener("offline", handleOffline);

		return () => {
			window.removeEventListener("online", handleOnline);
			window.removeEventListener("offline", handleOffline);
		};
	}, []);

	return (
		<ConnectionHealthContext.Provider value={{isConnected}}>
			{children}
		</ConnectionHealthContext.Provider>
	);
};

export const useConnectionHealthContext = (): ConnectionHealthContextValue => {
	const context = useContext(ConnectionHealthContext);

	if (context === undefined) {
		throw new Error(
			"useConnectionHealthContext must be used within a ConnectionHealthContextProvider",
		);
	}

	return context;
};
