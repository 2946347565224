/* eslint-disable @typescript-eslint/no-explicit-any*/
import {Icon, IconTypes} from "../icon";
import React, {ReactElement, ReactNode, useState, useMemo} from "react";
import classnames, {Argument} from "classnames";
import {Dropdown} from "../dropdown";
import {useDropdown} from "../../../hooks";
import styles from "./select.module.scss";
import {useThemeMode} from "../../../context/theme-mode-context";
import {DebounceSearch} from "../../../shared/v2/inputs";

export interface Option<T = any> {
	text: string;
	value: T;
	visible?: boolean;
	icon?: IconTypes;
	iconFill?: string;
	className?: Argument;
}

export interface SelectProps {
	id: string;
	label?: string;
	placeholder?: string;
	options: Option[];
	onChange: (newValue: any) => void;
	selectedValue?: any;
	className?: Argument;
	disabled?: boolean;
	size?: "small" | "medium" | "auto";
	children?: ReactNode;
	inputClass?: string;
	isSearchable?: boolean;
  dropdownClassName?: string;
}

const Select = (props: SelectProps): ReactElement => {

	const {id, options, label, selectedValue, onChange, className, size = "auto", disabled, inputClass, isSearchable = false, dropdownClassName} = props;
	const currentOption = options.find(o => o.value === selectedValue);
	const [search, setSearch] = useState("");

	const {anchorEl, handleClick, handleClose} = useDropdown();
	const {isDarkMode} = useThemeMode();

	// Initial filter before checking visible. Only updates if isSearchable is true
	const filteredOptions = useMemo(() => {
		if (!isSearchable) return options;
		return options.filter(o => o.text.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
	}, [isSearchable, search, options]);


	const handleSelect = (value: string): void => {
		onChange(value);
		if (isSearchable) {
			handleClose()
			setSearch("")
		};
	};

	return (
		<>
			<div
				className={classnames(
					className,
					styles.container,
					styles[size],
					disabled && styles.disabled,
				)}
				onClick={disabled ? undefined : handleClick}
			>
				{label && <label htmlFor={id} className={styles.label}>{label}</label>}
				<div className={classnames(
					styles.inputContainer,
					inputClass,
					{
						[styles.disabled]: disabled,
						[styles.isDarkMode]: isDarkMode,
					},
					anchorEl && styles.active,
				)}>
					{currentOption?.icon && (
						<Icon
							name={currentOption.icon}
							size="extrasmall"
							fill={currentOption.iconFill}
						/>
					)}
					{currentOption ? currentOption.text : props.placeholder ?? "Select..."}
					<Icon
						name="chevron"
						size="medium"
						fill="var(--color-text-body)"
					/>
				</div>
			</div>
			<Dropdown anchorEl={anchorEl} closeMenu={handleClose}
				width={anchorEl?.getBoundingClientRect().width}
				height={200}
				keepOpen={isSearchable}
				className={dropdownClassName}
			>
				{ isSearchable &&
					<DebounceSearch
						value={search}
						onChange={setSearch}
						className={styles.search}
					/>
				}
				<div className={styles.options}>
					{props.children}
					{filteredOptions.filter(o => o.visible ?? true).map((option, i) => (
						<Dropdown.Item
							key={`${id}-option${i}`}
							icon={option.icon}
							iconFill={option.iconFill}
							options={{onClick: () => handleSelect(option.value)}}
							className={classnames(styles.option, option.className, {
								[styles.selected]: option.value === selectedValue,
								[styles.isDarkMode]: isDarkMode,
							})}
						>
							{option.text}
						</Dropdown.Item>
					))}
				</div>
			</Dropdown>
		</>
	);
};

export {Select};
