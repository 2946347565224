
import {BRAND_FRAGMENT, CONTACT_FRAGMENT, DYNAMIC_SEGMENT_FRAGMENT, LOGO_FRAGMENT, SEGMENT_FRAGMENT, USER_FRAGMENT} from "../fragments/fragments";
import {gql} from "@apollo/client";

/**
 * Gets the intercomHash and that's it
 */
export const INTERCOM = gql`
	{
		intercomHash
	}
`;

export const CHECK_LOGIN = gql`
	{
		intercomHash
		me {
			...UserFields
			role
			hasCreatedTrial
			permissions
			experimentalFeatures
		}
	}
	${USER_FRAGMENT}
`;
export const GET_ME = gql`
	query GetCurrentUser {
		me {
			...UserFields
			role
			email
			hasCreatedTrial
			permissions
			experimentalFeatures
		}
	}
	${USER_FRAGMENT}
`;


// This is used to get info needed for the general layout
export const GET_USER_INFO = gql`
	query GetUserInfo {
		intercomHash
		me {
			...UserFields
			role
			email
			permissions
			hasCreatedTrial
			experimentalFeatures
		}
		myWorkspaces {
			items {
				name
				id
				myRole
				plan
				hasSubscription
				logo {
					id
					badge:url (version:BADGE)
				}
			}
		}
	}
	${USER_FRAGMENT}
`;

export const GET_MY_WORKSPACES = gql`
	query GetMyWorkspaces {
		myWorkspaces {
			items {
				id
				name
			}
		}
	}
`;

/**
 * Get a single workspace based on the id
 */
export const GET_WORKSPACE = gql`
	query GetWorkspace($id: GUID!) {
		workspace(id: $id) {
			id
			name
			allowAnonymous
			enableOfflineAssist
			chatbotEnabled
			logoId
			logo {
				id
				thumbnail:url (version:THUMBNAIL)
			}
			plan
			myRole
			permissions
			responseCount
			hasSubscription
			isTrialActive
			trialEnd
            forceLogout
            forceLogoutPeriodMin
			brand {
				...BrandFields
			}
		}
	}
	${BRAND_FRAGMENT}
`;
/**
 * Get just the responseCount from a workspace.
 */
export const GET_WORKSPACE_PENDING = gql`
	query GetWorkspacePending($id: GUID!) {
		workspace(id: $id) {
			plan
		}
	}
`;

export const GET_MEMBERS_NO_EMAIL = gql`
query WorkspaceMembersNoEmail($workspaceId: GUID! $filter: WorkspaceMembersFilter)
	{
		workspaceMembers(workspaceId:$workspaceId filter:$filter) {
			items {
				id
				firstName
				lastName
				lastInitial
				workspaceRole
				role
			}
			cursor
			remaining
		}
	}
`;

// Workspace API get workspace members
export const GET_WORKSPACE_MEMBERS = gql`
query WorkspaceMembers($workspaceId: GUID! $filter: WorkspaceMembersFilter $sort: WorkspacesMembersSort)
	{
		workspaceMembers(workspaceId:$workspaceId filter: $filter sort: $sort) {
			items {
				id
				email
				name
				lastInitial
				workspaceRole
				role
			}
			cursor
			remaining
		}
	}
`;

export const GET_CONTACTS = gql`
	query GetContacts(
		$workspaceId: GUID!
		$filter: ContactsFilter
		$limit: PositiveInt
		$sort: ContactsSort
		$cursor: String
	) {
		contacts (workspaceId: $workspaceId filter: $filter limit: $limit cursor: $cursor sort: $sort) {
			items {
				...ContactFields
			}
			cursor
			remaining
		}
	}
	${CONTACT_FRAGMENT}
`;

/**
 * Returns list of users that have requested to join the workspace
 */
export const GET_FOLLOWERS = gql`
	query GetFollowers($workspaceId: GUID! $limit: PositiveInt $cursor: String $sort: FollowersSort) {
		workspace(id: $workspaceId) {
			id
			followers(limit: $limit cursor: $cursor filter: {requestStatus: requested} sort: $sort) {
				items {
					user {
						...ContactFields
					}
					requestStatus
					createdAt
				}
				cursor
				remaining
			}
		}
	}
	${CONTACT_FRAGMENT}
`;

export const GET_CONTACT_DETAILS = gql`
	query GetContactDetails($workspaceId: GUID! $userId: GUID!) {
		contact(workspaceId: $workspaceId userId: $userId) {
			id
			firstName
			lastInitial
			creatorTag
			answerCount
			totalAnswerDuration
			sensemakeReel {
				id
				name
				description
				videoStatus
				createdAt
				duration
				video {
					id
					transcodingStatus
					video:url(version:VP8_VORBIS)
					mp4:url(version:H264_AAC)
					thumbnail:url(version:THUMBNAIL)
				}
			}
			insights {
				summary
				interests
				sentiment
				engagement
				motivations
				mentions
			}
			picture {
			...PictureFields
			}
		}
	}
	${LOGO_FRAGMENT}
`;
export const CHECK_RESET_TOKEN = gql`
	query CheckReset($token: String!) {
		checkPasswordResetToken(token: $token) {
			id
			email
		}
	}
`;
export const GET_WORKSPACE_SEGMENTS = gql`
	query GetWorkspaceSegments ($workspaceId: GUID!) {
		segments(workspaceId: $workspaceId) {
			items {
				...SegmentFields
			}
		}
		workspace(id: $workspaceId) {
			id
			contactCount
		}
	}
	${SEGMENT_FRAGMENT}
`;

export const GET_SEGMENT_NAMES = gql`
	query GetSegmentNames ($workspaceId: GUID!) {
		segments(workspaceId: $workspaceId) {
			items {
				id
				name
			}
		}
	}
`;

export const GET_SEGMENT_NAME = gql`
	query GetSegmentName ($id: GUID!) {
		segment(id: $id) {
			id
			name
		}
	}
`;

/**
 * Just gets segments, no contact count.
 */
export const GET_SEGMENTS = gql`
	query GetSegments (
		$workspaceId: GUID!
		$limit: PositiveInt
		$cursor: String
		$sort: SegmentSort
		$filter: SegmentsFilter
	) {
		segments(
			workspaceId: $workspaceId
			limit: $limit
			cursor: $cursor
			sort: $sort
			filter: $filter
		) {
			items {
				...SegmentFields
			}
			remaining
			cursor
		}
	}
	${SEGMENT_FRAGMENT}
`;

export const GET_BOTH_SEGMENTS = gql`
	query GetSegmentsWithDynamic ($workspaceId: GUID!) {
		segments(workspaceId: $workspaceId) {
			items {
				...SegmentFields
			}
		}
		dynamicSegments(workspaceId: $workspaceId) {
			items {
				...DynamicSegmentFields
			}
		}
	} ${SEGMENT_FRAGMENT} ${DYNAMIC_SEGMENT_FRAGMENT}
`;

export const GET_DYNAMIC_SEGMENTS = gql`
	query GetDynamicSegments (
		$workspaceId: GUID!
		$limit: PositiveInt
		$cursor: String
		$sort: DynamicSegmentSort
		$filter: DynamicSegmentsFilter
	) {
		dynamicSegments (
			workspaceId: $workspaceId
			limit: $limit
			cursor: $cursor
			sort: $sort
			filter: $filter
		) {
			items {
				...DynamicSegmentFields
			}
			remaining
			cursor
		}
	}
	${DYNAMIC_SEGMENT_FRAGMENT}
`;

export const GET_DYNAMIC_SEGMENT_NAME = gql`
	query GetDynamicSegmentName($id: GUID!) {
		dynamicSegment(id: $id) {
			id
			name
		}
	}
`;

/**
 * Returns item with all the emails of users from the given segment
 */
export const SEGMENT_EMAILS = gql`
	query GetEmailsFromSegment ($workspaceId: GUID! $segmentId: GUID, $cursor: String) {
		contacts(workspaceId: $workspaceId filter: {segmentId: $segmentId} cursor: $cursor) {
			items {
				id
				email
				creatorTag
			}
			cursor
			remaining
		}
	}
`;

export const DYNAMIC_SEGMENT_EMAILS = gql`
	query GetEmailsFromDynamicSegment (
		$workspaceId: GUID!
		$dynamicSegmentId:
		String $cursor: String
	) {
		contacts(
			workspaceId: $workspaceId
			filter: {dynamicSegmentId: $dynamicSegmentId}
			cursor: $cursor
		) {
			items {
				id
				email
				creatorTag
			}
			cursor
			remaining
		}
	}
`;
/**
 * Gets all the country IDs and names
 */
export const GET_COUNTRIES = gql`
	query GetCountries {
		countries {
			id
			name
		}
	}
`;

export const GET_FILTER_DATA = gql`
	query GetFilterData {
		countries {
			id
			name
		}
		states {
			id
			name
		}
		ethnicities {
			id
			name
		}
	}
`;

export const GET_SURVEY_LANGUAGES = gql`
	query GetSurveyLanguages {
		surveyLanguages {
			id
			name
			code
		}
	}
`;

export const GET_ADMIN_WORKSPACES = gql`
	query GetWorkspaces {
		myWorkspaces {
			items {
				id
				name
				plan
				renewalDate
				createdAt
			}
		}
	}
`;

export const GET_ADMIN_FEATURE_WORKSPACES = gql`
	query AllWorkspaces(
		$cursor: String
		$filter: WorkspaceFilter
		$limit: PositiveInt
	) {
		allWorkspaces(filter: $filter, limit: $limit,  cursor: $cursor) {
			items {
				id
				name
				aiInsightsEnabled
				allowAnonymous
				enableOfflineAssist
				plan
				chatbotEnabled
			}
			remaining
			cursor
		}
	}
`;

export const GET_ADMIN_FEATURE_BRANDS = gql`
	query Brands(
		$cursor: String
		$filter: BrandFilter
		$limit: PositiveInt
	) {
		brands(filter: $filter, limit: $limit,  cursor: $cursor) {
			items {
				id
				name
				visible
				isVerified
				workspace {
					id
					name
					aiInsightsEnabled
					allowAnonymous
					enableOfflineAssist
					plan
					chatbotEnabled
				}
			}
			remaining
			cursor
		}
	}
`;

export const GET_METABASE_URL = gql`
	query GetMetabaseUrl {
		metabaseDashboardUrl
	}
`;
