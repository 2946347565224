import {RefObject, useCallback, useState} from "react";
import {useObserver} from "./useObserver";

export const useFocusVisible = (ref: RefObject<HTMLElement>) => {
	const [focusVisible, setFocusVisible] = useState<boolean>(false);

	const callback = useCallback(() => {
		setFocusVisible(!!ref.current?.hasAttribute("data-focus-visible-added"));
	}, [ref]);

	useObserver(ref, MutationObserver, callback, {attributes: true});

	return focusVisible;
}
