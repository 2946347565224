import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./progress-bar.module.scss";

const cx = classNames.bind(styles);

export interface ProgressBarProps {
	className?: string;
}

export const ProgressBar = ({className}: ProgressBarProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	return <div className={cx("progressBar", className, {isDarkMode})}>
		<div className={styles.progressBarFill} />
	</div>;
};
