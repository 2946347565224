import {
	CREATE_COLLECTION,
	REMOVE_VID_FROM_COLLECTION,
} from "../../../graphql/mutations/collections-mutations";
import {Options, updateObject} from "../../../shared";
import {CollectionsReturn, RemovedVideoReturn} from "../../../models/collections";
import {CreateClipsReturn, CreateClipsVars} from "../../../models/clip";
import React, {ReactElement, useContext, useEffect, useState} from "react";
import {SelectContext} from "../../../context/select-context";
import {useLoadingQuery} from "../../../hooks";
import {COLLECTIONS} from "../../../graphql/queries/collections-queries";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {useMutation} from "@apollo/client";
import styles from "./media-lib.module.scss";
import {Card} from "../../../shared/layout/card";
import {ToastContext} from "../../../context/toast-context";
import {MediaSwitcher} from "./media-switcher";
import {CREATE_CLIPS} from "../../../graphql/mutations/clip-mutations";
import {ReelContext} from "../../../context/reel-context";
import {CLIP_FRAGMENT} from "../../../graphql/fragments/fragments";
import {Button, Input, Checkbox, Body, Modal, BaseModalProps} from "../../../shared/v2";

const MediaLibraryModal = (props: BaseModalProps): ReactElement => {
	const {isOpen, onClose} = props;
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const {updateToast} = useContext(ToastContext);
	// Might pass this in if we want to use the modal somewhere else?
	const {reelId} = useContext(ReelContext);
	const {selected, setSelected, emptySelectValues} = useContext(SelectContext);

	const [name, setName] = useState("");
	const [isAdding, setIsAdding] = useState(false);

	const {data, fragment} = useLoadingQuery<CollectionsReturn>(COLLECTIONS, {
		skip: !isOpen,
		what: "library",
		variables: {workspaceId},
	});

	const [removeVideo] = useMutation<RemovedVideoReturn>(REMOVE_VID_FROM_COLLECTION);

	const [createCol] = useMutation(CREATE_COLLECTION);
	const [createClips] = useMutation<CreateClipsReturn, CreateClipsVars>(CREATE_CLIPS);

	useEffect(() => {
		if (!isOpen) emptySelectValues();
	}, [isOpen]);

	const create = (): void => {
		createCol({
			variables: {name, workspaceId},
			onCompleted: () => {
				setName("");
				updateToast({
					description: "Collection created. You will see videos when they are added",
					type: "informational",
				});
			},
		});
	};

	const handleDelete = (collectionId: string, videoId: string): void => {
		removeVideo({
			variables: {collectionId, videoId},

			onCompleted: () => updateToast({description: "Video removed from collection", type: "informational"}),
			update(cache, {data: removeVid}) {
				if (!removeVid) return;
				cache.modify({
					id: `Collection:${collectionId}`,
					fields: {
						videos(existing = [], {readField}) {
							const copy = [...existing];
							const updatedItems = copy.filter(ref => videoId !== readField("id", ref));
							return updatedItems;
						},
					},
				});
			},
		});
	};

	const handleAdd = (): void => {
		setIsAdding(true);
		createClips({
			variables: {reelId, input: selected.map(id => ({sourceVideoId: id}))},
			onCompleted: () => {
				updateToast({description: "Added to reel", type: "informational"});
				emptySelectValues();
				setIsAdding(false);
			},
			onError: () => {
				updateToast({description: "An error occurred, please try again later", type: "failure"});
				setIsAdding(true);
			},
			update(cache, {data: clipData}) {
				if (!clipData) return;
				let duration = 0;
				// Get full duration of all clips together
				clipData.createClips.forEach(clip => {
					duration += (clip.endTime - clip.startTime);
				});
				// Create array of Clip:GUID for updating clips.items
				const clipRefs = clipData.createClips.map(clip =>
					cache.writeFragment({
						data: clip,
						fragment: CLIP_FRAGMENT,
					}));
				if (!clipRefs.length) return;
				cache.modify({
					fields: {
						clips(existing = [], {storeFieldName}) {
							if (!storeFieldName.includes(reelId)) return;
							const items = [...existing.items];
							// Update current items by just adding refs to end
							const updatedPage = updateObject(existing, {
								items: [...items, ...clipRefs],
							});
							return updatedPage;
						},
					},
				});
				// Update duration on reels.
				cache.modify({
					id: `Reel:${reelId}`,
					fields: {
						duration(currentDuration: number) {
							return currentDuration + duration;
						},
					},
				});
			},
		});
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			className={styles.modal}
			title="Media Library"
		>
			<div className={styles.container}>
				{fragment}
				{!fragment && <section className={styles.new}>
					<Body size="xs">Create new collection to add videos to.</Body>
					<div className={styles.input}>
						<Input id="collection-name" onChange={setName} value={name}/>
						<Button disabled={!name} onClick={create}>Create collection</Button>
					</div>
				</section>}
				{data && data.collections.length > 0 ? <section className={styles.content}>
					{data.collections.map(collection =>
						<div key={collection.id} className={styles.collection}>
							<h2 className={styles.collectionName}>{collection.name}</h2>
							<div className={styles.grid}>
								{collection.videos?.map(vid => <Card key={vid.id} className={styles.card}>
									<Checkbox
										onChange={setSelected}
										value={vid.id}
										size="s"
										id={vid.id}
										className={styles.checkbox}
										checked={selected.includes(vid.id)}
									/>
									<MediaSwitcher data={vid} className={styles.video}/>
									<p className={styles.name}>{vid.originalFilename}</p>
									<div className={styles.actions}>
										<Options
											type="menu-vertical"
											position="right"
											options={[{
												name: "Remove",
												actionOptions: {onClick: () => handleDelete(collection.id, vid.id)},
												icon: "trash",
											}]}
										/>
									</div>
								</Card>)}
							</div>
						</div>)}
				</section> : !fragment && <div>
					<h1>There&apos;s nothing here.</h1>
				</div>}
				{selected.length > 0 && <div className={styles.bar}>
					<span>{selected.length} selected</span>
					<Button
						onClick={handleAdd}
						disabled={isAdding}
						// Can't do much else right now
					>
            Add to Reel
					</Button>
				</div>}
			</div>
		</Modal>
	);
};

export {MediaLibraryModal};
