import {AnswerPage, AnswersPageFilter} from "../../../models/answer";
import React, {ReactElement, useContext, useState} from "react";
import {VideoCard} from "../../components/video-card";
import {VideoRow} from "../video-row";
import {WorkspacePlan} from "../../../models/workspace";
import {useSelectContext} from "../../../context/select-context";
import styles from "./video-card-list.module.scss";
import {SurveyContext} from "../../../context/survey-context";
import {ToggleSwitch} from "../../../shared/components/toggle-switch";

export interface VideoCardListProps {
	answers?: AnswerPage;
	/**
	 * If we are loading right now.
	 */
	loading: boolean;
	/**
	 * Results filter passed down so we know if we are currently filtering
	 * our data.
	 */
	resultsFilter: AnswersPageFilter;
	/**
	 * Workspace's plan to determine what to show for cards and if they can go to transcript modal
	 */
	workspacePlan?: WorkspacePlan | null;
	/**
	 * Display type. Either undefined or "list"
	 */
	display: "list" | undefined;
	/**
	 * Create Clip handler. Might move this out of here.
	 */
	addClip: (reelId: string, answerId: string) => void;
	/**
	 * Shows all cards as selected
	 */
	selectAll: boolean;
	/**
	 * Processes when a single video is disselected when selectAll is true
	 */
	disselectOneFromSelectAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
	noDelete?: boolean;
}

/**
 * @param data The data returned from our results query (form of an answer page)
 * @param loading if the data is loading or not
 */
const VideoCardList = React.memo((props: VideoCardListProps): ReactElement => {
	 
	const {answers, display, loading, resultsFilter, workspacePlan, addClip, noDelete = false, selectAll, disselectOneFromSelectAll} = props;
	const {selected} = useSelectContext();
	const {survey: {language}} = useContext(SurveyContext);
	const [showTranslation, setShowTranslation] = useState(false);
	const hasTranslations = !language.name.toLocaleLowerCase().includes("english");

	const handleToggle = (e: React.ChangeEvent<HTMLInputElement>): void => setShowTranslation(e.target.checked);

	if (loading) return <div style={{height: 345}}>Loading items...</div>;
	if (answers && answers.items.length > 0) {
		let videoList: JSX.Element[];
		if (display === "list") {
			videoList = answers.items.filter(answer => answer.video !== null)
				.map(answer => <VideoRow
					answer={answer}
					key={answer.id}
					search={resultsFilter.search || resultsFilter.textAndTranscriptsSearch}
					showTranslation={showTranslation}
				/>);
		} else {
			videoList = answers.items.filter(answer => answer.video !== null)
				.map(answer => <VideoCard
					answer={answer}
					addClip={addClip}
					key={answer.id}
					plan={workspacePlan}
					isSelected={selectAll || Boolean(selected.some(s => s.includes(answer.id)))}
					noDelete={noDelete}
					selectAll={selectAll}
					disselectOneFromSelectAll={disselectOneFromSelectAll}
				/>);
		}
		return (<>
			{display === "list" && hasTranslations && <div className={styles.translationToggle}>
				<span>Translate to English for all available</span>
				<ToggleSwitch id="toggle-all-translation" isChecked={showTranslation} onChange={handleToggle}/>
				<span><i>* Highlighting unavailable for translated transcripts</i></span>
			</div>}
			<div className={display === "list" ? styles.row : styles.cards}>
				{videoList}
			</div>
		</>
		);
	}
	if (answers?.items.length === 0 && (resultsFilter.choiceId || resultsFilter.search)) {
		return <div className={styles["no-responses"]}>
			<img src="images/no-responses.png"/>
			<p className={styles.none}>There are no results with the current filters</p>
		</div>;
	}
	if (answers?.items.length === 0) {
		return <div className={styles["no-responses"]}>
			<img src="images/no-responses.png"/>
			<p className={styles.none}>This question has no responses yet</p>
		</div>;
	}
	return <div>An error occurred</div>;
});

VideoCardList.displayName = "VideoCardList";

export {VideoCardList};
