import {
	CreateARModelVars,
	CreateQuestionArData,
	UploadFileVars,
	UploadItemData,
} from "../models/questions";
import {CREATE_QUESTION_AR_MODEL} from "../graphql/mutations/survey-mutations";
import React, {useState} from "react";
import {UPLOAD_FILE} from "../graphql/mutations/mutations";
import {checkFileType} from "../shared/utility";
import {useMutation} from "@apollo/client";

export interface ModelState {
	usdzPreview?: string;
	glbPreview?: string;
	usdzRaw?: File;
	glbRaw?: File;
}

export interface UseModelUploadReturn {
	media: ModelState;
	hasError: boolean;
	clearMedia: () => void;
	handleUSDZChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleGLBChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleSave: () => Promise<any>;
	mediaId?: string;
	isProcessing: boolean;
}

export const useModelUpload = (): UseModelUploadReturn => {
	const [media, setMedia] = useState<ModelState>({});
	const [mediaId, setMediaId] = useState<string | undefined>(undefined);
	const [hasError, setHasError] = useState<boolean>(false);
	const [isProcessing, setIsProcessing] = useState<boolean>(false);

	const [uploadFile] = useMutation<UploadItemData, UploadFileVars>(UPLOAD_FILE);
	const [createArModel] =
	useMutation<CreateQuestionArData, CreateARModelVars>(CREATE_QUESTION_AR_MODEL);

	/**
	 * This function will allow you to clear the image if need be
	 */
	const clearMedia = (): void => {
		setMedia({});
		setMediaId(undefined);
	};

	/**
	 * Returns the mimetype of the File object passed in
	 * @param file the File object for which we are extracting the correct mimetype
	 */
	const getMimetype = (file: File): string => {
		let mimetype = file.type;

		/**
		 * The File API (browser dependent) may fail to
		 * recognize the correct mimetype for certain file
		 * formats when uploaded. In this senario, we fall
		 * back to manually checking the extension and
		 * setting the mimetype according to the official
		 * media types assignment registry specified in RCF2046
		 * and listed here:
		 * https://www.iana.org/assignments/media-types/media-types.xhtml
		 * */
		if (!mimetype) {
			if (file.name?.toLocaleLowerCase().endsWith(".glb")) {
				mimetype = "model/gltf-binary";
			}
			if (file.name?.toLocaleLowerCase().endsWith(".usdz")) {
				mimetype = "model/vnd.usdz+zip";
			}
		}
		return mimetype;
	};

	const handleUSDZChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (event.target.files?.length) {
			setHasError(false);
			const [file] = event.target.files;
			const mimetype = getMimetype(file);
			const {fileType} = checkFileType(mimetype);
			if (fileType === "usdz") {
				setMedia(current => ({
					...current,
					usdzPreview: URL.createObjectURL(file),
					usdzRaw: file,
				}));
			} else {
				setHasError(true);
			}
		}
	};
	const handleGLBChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (event.target.files?.length) {
			setHasError(false);
			const [file] = event.target.files;
			const mimetype = getMimetype(file);
			const {fileType} = checkFileType(mimetype);
			if (fileType === "glb") {
				setMedia(current => ({
					...current,
					glbPreview: URL.createObjectURL(file),
					glbRaw: file,
				}));
			} else {
				setHasError(true);
			}
		}
	};
	/**
	 * Handles the upload (when clicking on save)
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleSave = async(): Promise<any> => {
		if (!media.usdzRaw || !media.glbRaw) return;
		setIsProcessing(true);
		const {data: usdzFile} = await uploadFile({
			variables: {file: media.usdzRaw},
		});
		const usdzId = usdzFile?.upload?.id;
		const {data: glbFile} = await uploadFile({
			variables: {file: media.glbRaw},
		});
		const glbId = glbFile?.upload?.id;
		if (!glbId || !usdzId) return;
		const {data} = await createArModel({
			variables: {
				glbUploadItemId: glbId,
				usdzUploadItemId: usdzId,
			},
		});
		if (data) {
			setMediaId(data.createQuestionArModel.id);
			setIsProcessing(false);
			return data.createQuestionArModel.id;
		}
	};
	return {
		media,
		hasError,
		isProcessing,
		clearMedia,
		handleUSDZChange,
		handleGLBChange,
		handleSave,
		mediaId,
	};
};
