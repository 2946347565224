import React, {ReactElement} from "react";

import {AnswerPageData} from "../../../models/answer";
import {Body, Checkbox, SearchInput} from "../../../shared/v2";
import {DisplayResults} from "../display-results";
import {NUMBER_SORT_TYPES, TEXT_SORT_TYPES, SORT_TYPES} from "../../../shared/constants/constants";
import {Question, QuestionType} from "../../../models/questions";
import {Select} from "../../../shared";
import {Setter} from "../../../types";

import styles from "./search-and-sort.module.scss";

export interface SearchAndSortProps {
	answerData?: AnswerPageData;
	question: Question;
	searchState: [string, Setter<string>];
	selectAllState: [boolean, (v: boolean) => void];
	highlightState: boolean;
	sort?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	resultsFilter: Record<string, any>;
	handleClearSearch: () => void;
	handleHighlightsCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleSearch: (newSearch: string) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleFilter: (newSort: any) => void;
}
/**
 * Created to remove some of the repetitive logic.
 */
const SearchAndSort = (props: SearchAndSortProps): ReactElement | null=> {
	const {
		searchState,
		selectAllState,
		highlightState,
		resultsFilter,
		answerData,
		question,
		handleClearSearch,
		handleHighlightsCheckbox,
		handleSearch,
		handleFilter,
	} = props;
	const [search, setSearch] = searchState;
	const [allSelected, setAllSelected] = selectAllState;


	const selectAll = (): void => {
		setAllSelected(!allSelected);
	};


	if (!(question.videoResponse || question.type === QuestionType.TEXT) && question.subtype === "VIDUPLOAD") return null;
	return (
		<div className={styles.container}>
			<div className={styles.searchbox}>
				<SearchInput
					key={question.id}
					id="search-input"
					onEnter={handleSearch}
					onChange={setSearch}
					value={search}
					icon="search"
				/>
				{answerData?.answers &&
					<DisplayResults
						searchTerm={resultsFilter.search || resultsFilter.textAndTranscriptsSearch}
						clearSearch={handleClearSearch}
						results={answerData.answers.items.length + answerData.answers.remaining}
					/>
				}
			</div>
			<div className={styles.list}>
				<div className={styles.icons}>
					<Select
						className={styles.sortSelect}
						id="sort-select"
						options={question.subtype === "number" ?
							NUMBER_SORT_TYPES : question.type === QuestionType.TEXT ?
								TEXT_SORT_TYPES : SORT_TYPES}
						onChange={handleFilter}
						selectedValue={resultsFilter.sort || "MOST_LIKES"}
						size="small"
					/>
				</div>
				<div className={styles.checkboxes}>
					<Checkbox
						id="select-all"
						text={(
							<Body size="xs">
								Select All
							</Body>
						)}
						className={styles.checkbox}
						size="s"
						checked={allSelected}
						onChange={selectAll}
					/>
					{Boolean(question.highlightCount) && (
						<Checkbox
							id="view-highlights"
							text={(
								<Body size="xs">
									View highlights
								</Body>
							)}
							className={styles.checkbox}
							size="s"
							checked={highlightState}
							onChange={handleHighlightsCheckbox}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export {SearchAndSort};
