import React, {ReactElement, useContext} from "react";

import {QuestionCard} from "../question-card";
import {SurveyContext} from "../../../context/survey-context";
import {MagicWandIcon, UsersIcon} from "../../../icons";
import {ButtonGroup, ButtonGroupElement} from "../../../shared/components/navigation";

import styles from "./results-nav.module.scss";

const ResultsNav = ({mode}: {mode?: "responses" | "results"}): ReactElement => {
	const {questions} = useContext(SurveyContext);

	return (
		<div className={styles.container}>
			<ButtonGroup className={styles.buttonGroup}>
				<ButtonGroupElement
					to="results"
					icon={<MagicWandIcon />}
					text="Results"
					withSearch
				/>
				<ButtonGroupElement
					to="participation"
					icon={<UsersIcon />}
					text="Participants"
					withSearch
				/>
			</ButtonGroup>

			<div className={styles.questions}>
				{questions?.map((question, index) => <QuestionCard
					key={question.id}
					index={index}
					question={question}
					mode={mode ?? "participation"}
				/>)}
			</div>
		</div>
	);
};

export {ResultsNav};
