import classNames from "classnames/bind";
import React, {ReactElement, useCallback, useState} from "react";

import {Link} from "../../../../hoc/layout/navigation/link";
import {useObserver} from "../../../../hooks/useObserver";
import {Persona} from "../../../../models";
import {Body} from "../../../../shared/v2";
import {SizeTransition} from "../../../../shared/v2/size-transition";
import {AgentCard} from "../agent-card";

import styles from "./agents-section.module.scss";

const cx = classNames.bind(styles);

export interface AgentsSectionProps {
  personas: Persona[];
  handleEdit: (persona: Persona) => void;
  handleDelete: (id: string) => void;
  headline: string;
}

export const AgentsSection = ({
	headline,
	personas,
	handleDelete,
	handleEdit,
}: AgentsSectionProps): ReactElement => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const [listElement, setListElement] = useState<HTMLDivElement | null>(null);
  const [firstChild, setFirstChild] = useState<HTMLDivElement | null>(null);

  const handleResize = useCallback((entries: ResizeObserverEntry[]): void => {
    const {target} = entries[0];
    setIsOverflowing(
      (target as HTMLElement).scrollHeight > maxHeight
    );
  }, [personas.length, maxHeight]);

	useObserver(listElement, ResizeObserver, handleResize);

	const handleChildResize = useCallback((entries: ResizeObserverEntry[]): void => {
		const {target} = entries[0];
		setMaxHeight((target as HTMLElement).clientHeight);
	}, [personas.length]);

	useObserver(firstChild, ResizeObserver, handleChildResize);

  return (
    <div className={styles.agentsSection}>
      <div className={styles.controlPanel}>
        <Body size="xl" type="semibold">
          {headline}
        </Body>

        {isOverflowing && (
          <Link
            type="underline"
            onClick={() => setIsExpanded(!isExpanded)}
            text={isExpanded ? "Show less" : "Show all"}
          />
        )}
      </div>

      <SizeTransition>
        <div
          ref={setListElement}
          className={cx("list", {expanded: isExpanded})}
          style={{maxHeight: isExpanded ? "none" : maxHeight}}
        >
          {personas.map((persona: Persona, index) => (
            <AgentCard
              ref={index === 0 ? setFirstChild : null}
              key={persona.id}
              persona={persona}
              editPersona={handleEdit}
              deletePersona={handleDelete}
            />
          ))}
        </div>
      </SizeTransition>
    </div>
  );
}
