import React, {ReactElement, useMemo, useState} from 'react';
import {Column} from 'react-table';
import {useMutation, useQuery} from '@apollo/client';

import {AiSkill} from '../../../models/ai-model';
import {Body, Button, Dropdown, SearchInput, Spinner} from '../../../shared/v2';
import {AlignText} from '../../../shared/typography/align-text';
import {BinDeleteIcon, DotsIcon, PenEditIcon} from '../../../icons';
import {Table} from '../../../shared/components/table';
import {AgentChanges, SkillModal} from '../../components/skill-modal';
import {AI_SKILLS} from '../../../graphql/queries/ai-models-queries';
import {AI_SKILL_CREATE, AI_SKILL_DELETE, AI_SKILL_UPDATE} from '../../../graphql/mutations/persona-mutations';
import {useToastContext} from '../../../context/toast-context';

import styles from './skills-page.module.scss';

export const SkillsPage = (): ReactElement => {
	const [searchValue, setSearchValue] = useState<string>("");
	const [isSkillModalOpen, setIsSkillModalOpen] = useState<boolean>(false);
	const [selectedSkill, setSelectedSkill] = useState<AiSkill | null>(null);

	const {updateToast} = useToastContext();

	const {data: skillsData, loading} = useQuery(AI_SKILLS, {
		fetchPolicy: "cache-first",
	});

	const [createSkill] = useMutation(AI_SKILL_CREATE, {
		refetchQueries: [{query: AI_SKILLS}],
	});
	const [deleteSkill] = useMutation(AI_SKILL_DELETE, {
		refetchQueries: [{query: AI_SKILLS}],
	});
	const [updateSkill] = useMutation(AI_SKILL_UPDATE);

	const filteredSkills = useMemo(() => {
		if (!skillsData?.aiSkills) {
			return [];
		}

		return skillsData.aiSkills.filter((skill: AiSkill) => {
			return skill.name.toLowerCase().includes(searchValue.toLowerCase());
		});
	}, [skillsData, searchValue]);


	const handleDelete = (skill: AiSkill):void => {
		deleteSkill({
			variables: {
				id: skill.id,
			}
		});
	}

	const columns: Column<AiSkill>[] = [
		{
			Header: "Name",
			accessor: "name",
			Cell: ({value}: {value: string}) => <Body size="s">{value}</Body>,
		},
		{
			Header: "Description",
			accessor: "description",
			Cell: ({value}: {value: string}) => <Body size="s">{value}</Body>,
		},
		{
			id: "options",
			Header: "",
			maxWidth: 20,
			disableSortBy: true,
			Cell: data => {
				return <AlignText align="right">
					<Dropdown
						position="bottom-start"
						trigger={<DotsIcon className={styles.trigger} />}
						items={[
							{
								label: "Edit",
								onClick: () => {
									setSelectedSkill(data.row.original)
									setIsSkillModalOpen(true)
								},
								icon: <PenEditIcon />
							},
							{
								label: "Delete",
								onClick: () => {handleDelete(data.row.original)},
								color: "danger",
								icon: <BinDeleteIcon />
							},
						]}
					/>
				</AlignText>;
			},
		},
	];

	const closeSkillModal = ():void => {
		setIsSkillModalOpen(false);
		setSelectedSkill(null);
	}

	const handleSave = (changes: AgentChanges):void => {
		if (!selectedSkill) {
			createSkill({
				variables: {
					name: changes.name,
					description: changes.description,
				},
				onError: (error) => {
					updateToast({
						type: "failure",
						description: error.message,
					})
				}
			});
		}

		if (selectedSkill) {
			updateSkill({
				variables: {
					id: selectedSkill.id,
					name: changes.name,
					description: changes.description,
				},
				onError: (error) => {
					updateToast({
						type: "failure",
						description: error.message,
					})
				}
			});
		}

		closeSkillModal();
	}

	const renderTable = (): ReactElement => {
		if (loading) {
			return <Spinner/>
		}

		if (!skillsData?.aiSkills) {
			return <Body>No tags found</Body>
		}

		return <Table
			columns={columns}
			data={filteredSkills}
		/>
	}

	return (
		<>

			<div className={styles.container}>
				<div className={styles.topSection}>
					<SearchInput placeholder="Search tags" value={searchValue} onChange={setSearchValue} />
					<Button onClick={() => {setIsSkillModalOpen(true)}}>Add Tag</Button>
				</div>
				{renderTable()}

			</div>

			<SkillModal onSave={handleSave} skill={selectedSkill}  isOpen={isSkillModalOpen} onClose={closeSkillModal} />
		</>
	);
}
