import React, {ReactElement} from "react";

import {SparkAiStarsIcon} from "../../../icons";
import {EmptyState} from "../../../shared/v2";

export const MagicSummariesPage = (): ReactElement => {
	return <EmptyState
		title="Magic Summaries"
		description="Turn your datasets into a connected knowledge base of new insights and ideas. Coming soon."
		icon={<SparkAiStarsIcon />}
	/>
}
