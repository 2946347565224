import React, {ReactElement} from "react";

import {Spinner} from "../..";

import styles from "./loading-page.module.scss";

const LoadingPage = (): ReactElement => {
	return (
		<div className={styles.page}>
			<Spinner/>
		</div>
	);
};

export {LoadingPage};
