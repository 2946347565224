import {Icon, Options} from "../../../shared";
import React, {ReactElement, useContext, useEffect, useRef} from "react";
import {convertToClockTime} from "../../../shared/utility/utility";
import {DragSourceMonitor, useDrag, useDrop} from "react-dnd";
import {Clip} from "../../../models/clip";
import {DragItemTypes} from "../../../shared/constants/constants";
import {ReelContext} from "../../../context/reel-context";
import classNames from "classnames/bind";
import styles from "./clip-list-item.module.scss";


const bStyles = classNames.bind(styles);
export interface ClipListItemProps {
	/**
	 * Clip
	 */
	clip: Clip;
	/**
	 * Index of the clip
	 */
	countIndex: number;
	/**
	 * Dragging function callback
	 */
	dragCallback: (dragIndex: number, hoverIndex: number) => void;
	/**
	 * Drop function callback
	 */
	dropCallback: (dragIndex: number, dropIndex: number) => void;
}

/**
 * This list item will always be a child of a table, so it is a table row
 * that we are returning.
 * @param clip The clip to display
 * @returns
 */
const ClipListItem = React.memo((props: ClipListItemProps): ReactElement => {
	const {clip, countIndex, dragCallback, dropCallback} = props;
	const {
		index,
		handleDeleteClip,
		duplicateClipCallback,
		indexDispatch,
		setClipId,
	} = useContext(ReelContext);
	const rowRef = useRef<HTMLTableRowElement>(null);
	const handleRef = useRef<HTMLDivElement>(null);


	/**
	 * Setting up the drag and drop handlers.
	 */
	const [, drop] = useDrop({
		accept: DragItemTypes.CLIP,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		hover(item: any) {
			if (!rowRef.current) {
				return;
			}
			const dragIndex = item.countIndex;
			const hoverIndex = countIndex;
			dragCallback(dragIndex, hoverIndex);
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		drop(item: any) {
			if (!rowRef.current) {
				return;
			}
			const dragIndex = item.countIndex;
			const dropIndex = countIndex;
			if (dragIndex === dropIndex) return;
			dropCallback(dragIndex, dropIndex);
		},
	});

	const [{isDragging}, drag, preview] = useDrag(
		() => ({
			type: DragItemTypes.CLIP,
			item: {type: DragItemTypes.CLIP, countIndex},
			collect: (monitor: DragSourceMonitor) => ({
				isDragging: monitor.isDragging(),
			}),
		}),
		[countIndex],
	);

	useEffect(() => {
		preview(rowRef.current);
	}, []);

	const handleClickClip = (): void => {
		indexDispatch({type: "set", number: countIndex});
	};

	drag(handleRef);
	drop(rowRef);

	const visibility = isDragging ? "hidden" : "visible";
	return (
		<tr
			className={styles["clip-row"]}
			key={clip.id}
			ref={rowRef}
		>
			<td className={[styles["data-cell"], styles.light].join(" ")}>
				<div className={styles.order}>
					{countIndex + 1}
					{index === countIndex && <div className={styles.play}/>}
				</div>
			</td>
			<td
				style={
					isDragging ? (
						{

							boxShadow: "-1px -1px 0 var(--batterii-purple), 0 1px 0 var(--batterii-purple)",
						}
					) : undefined
				}
				className={[styles["data-cell"], styles.dark, styles.flex].join(" ")}
			>

				<img
					style={{visibility}}
					className={bStyles("image", "hover")}
					src={clip?.answer?.video?.thumbnail || clip.sourceVideo?.thumbnail}
					onClick={handleClickClip}
				/>
				<div
					style={{visibility}}
					className={styles.nameAndSurvey}
				>
					<p className={styles.creatorTag} onClick={() => setClipId(clip.id)}>
						{clip.sourceVideo ? clip.sourceVideo.originalFilename :
							clip.answer ? `${clip.answer.user?.creatorTag}`
								: "Video"
						}
					</p>
					<p className={styles.name}>{clip.answer ? `${clip.answer.user?.firstName} ${clip.answer.user?.lastInitial}` : "Unknown user"}</p>
					<p className={styles.survey}>
						{clip.answer ? `From "${clip.answer?.question?.survey.name}"` : "Uploaded"}
					</p>
				</div>
			</td>
			<td
				style={isDragging
					? (
						{
							borderTop: "1px solid var(--batterii-purple)",
							borderBottom: "1px solid var(--batterii-purple)",
						}
					)
					: undefined}
				className={[styles["data-cell"], styles.dark].join(" ")}
			>
				<span style={{visibility}}>
					{convertToClockTime(clip.endTime - clip.startTime, "milliseconds")}
				</span>
			</td>
			<td
				style={isDragging
					? (
						{
							border: "1px solid var(--batterii-purple)",
							borderLeft: "none",
						}
					)
					: undefined}
				className={styles["data-cell"]}
			>
				<div style={{visibility}} className={[styles.flex, styles.justify].join(" ")}>
					<Icon
						name="scissors"
						size="extrasmall"
						isClickable
						clicked={() => setClipId(clip.id)}
					/>
					<div
						style={{cursor: "grab"}}
						ref={handleRef}
					>
						<Icon
							name="drag"
							size="extrasmall"
							fill="black"
						/>
					</div>
					<Options
						type="menu-vertical"
						options={[
							{
								name: "Copy",
								actionOptions: {
									onClick: () => duplicateClipCallback(clip.id),
								},
								icon: "copy",
							},
							{
								name: "Delete",
								actionOptions: {
									onClick: () => handleDeleteClip(clip.id, countIndex),
								},
								icon: "trash",
							},
						]}
					/>
				</div>
			</td>
		</tr>
	);
});

ClipListItem.displayName = "ClipListItem";

export {ClipListItem};
