import React, {ReactElement, ReactNode} from "react";

import {Body, Header} from "../../../shared/v2";
import {ErrorBoundary} from "../../components";

import styles from "./app-error-boundary.module.scss";

export interface AppErrorBoundaryProps {
  children: ReactNode;
}

export const AppErrorBoundary = ({children}: AppErrorBoundaryProps): ReactElement => {
	const fallback = (
		<div className={styles.appErrorBoundary}>
			<Header>
        Something went wrong
			</Header>

			<Body>
        Try refreshing the page or contact support
			</Body>
		</div>
	)

	return (
		<ErrorBoundary FallbackComponent={() => fallback}>
			{children}
		</ErrorBoundary>
	);
}
