import {markResult, PatternProps, ReplacerFunc, splitTaggedResultIntoChunks} from ".";

export interface InsertMentionPatternProps extends PatternProps {
  mentions: string[];
  selectionStart: number;
}

const extractInsertMention = (text: string, selectionStart: number): [number, number] | false => {
	for(let i = selectionStart - 1; i >= 0; i--) {
		if (text[i] === "@") {
			return [i, selectionStart];
		}
	}

	return false;
}

export const insertMentionPatternReplacer: ReplacerFunc<InsertMentionPatternProps> = (props) => {
	const {text, render, mentions, selectionStart} = props;

	const mentionPosition = extractInsertMention(text, selectionStart);

	if (!mentionPosition) {
		return [text];
	}

	const [start, end] = mentionPosition;

	// Check if the mention is inserted after whitespace
	if (start > 0 && !/\s/.test(text[start - 1])) {
		return [text];
	}

	const mentionText = text.substring(start + 1, end);
	const isMentionValid = mentions.some(
		mention =>
			mention
				.toLowerCase()
				.startsWith(mentionText.toLowerCase())
	);

	if (!isMentionValid) {
		return [text];
	}

	const result = text.substring(0, start) + markResult(`@${mentionText}`) + text.substring(end);

	return splitTaggedResultIntoChunks(result, render);
}
