import React, {ReactElement} from "react";
import styles from "./dirty-reel-modal.module.scss";
import {useNavigate} from "react-router";
import {Reel} from "../../models/reels";
import {useWorkspaceContext} from "../../context/workspace-context";
import {BaseModalProps, Button, Modal} from "../../shared/v2";

export interface DirtyReelProps extends BaseModalProps {
	/**
	 * The name of the Reel that is dirty
	 */
	reel: Reel | undefined;
}

const DirtyReelModal = (props: DirtyReelProps): ReactElement => {
	const {isOpen, reel, onClose} = props;
	const {workspace: {id: workspaceId}} = useWorkspaceContext();

	const navigate = useNavigate();

	/**
	 * Calls handleConfirm callback and then closes the modal.
	 */
	const handleYes = (): void => {
		if (reel) {
			navigate({
				pathname: `/reel/${reel.id}`,
				search: `workspaceId=${workspaceId}`,
			});
		}
		onClose();
	};

	// Maybe could make title be a prop
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size="x-small"
		>
			<div className={styles.container}>
				<h3 className={styles.header}>
					{reel?.name || "The reel"} has unpublished changes.c
				</h3>
				<p className={styles.title}>
					Would you like to go there now?
				</p>
				<br/>
				<div className={styles.actions}>
					<Button style="danger" onClick={onClose}>Not now</Button>
					<Button onClick={handleYes}>Go to the reel</Button>
				</div>
			</div>
		</Modal>
	);
};

export {DirtyReelModal};
