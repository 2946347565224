import React, {ReactElement, useState} from "react";
import {BaseModalProps, Body, Button, Modal, Switch} from "../../../shared/v2";

import styles from "./manage-is-vurvey-modal.module.scss";
import {Persona} from "../../../models";
import {Row} from "react-table";

export interface ManageIsVurveyModalProps extends BaseModalProps {
  selectedAgents: Row<Persona>[];
  onSave: (isVurvey: boolean) => void;
  isDisabled: boolean;
}

export const ManageIsVurveyModal = ({
	isOpen,
	onClose,
	onSave,
	selectedAgents,
	isDisabled,
}: ManageIsVurveyModalProps): ReactElement => {
	const [isVurvey, setIsVurvey] = useState(false);


	const handleSave = (): void => {
		onSave(isVurvey);
	};

	return <Modal
		size="small"
		isOpen={isOpen}
		onClose={onClose}
		title="Do you want to change the isVurvey flag?"
		description={`You are going to change isVurvey flag for this agents: ${selectedAgents.map(agent => agent.original.name).join(", ")}`}
	>
		<div className={styles.form}>
			<Body size="s">Enable</Body>
			<Switch checked={isVurvey} onChange={(value) => {setIsVurvey(value)}} />
		</div>

		<div className={styles.footer}>
			<Button variant="outlined" onClick={onClose}>Cancel</Button>
			<Button disabled={isDisabled} onClick={handleSave}>Save</Button>
		</div>
	</Modal>

}
