import {Icon, InputWithButton} from "../../shared";
import React, {MutableRefObject, ReactElement, useState} from "react";
import styles from "./delete-survey.module.scss";
import {Body, Subheader, Modal, BaseModalProps} from "../../shared/v2";

export interface DeleteSurveyModalProps extends BaseModalProps {
	survey: {id: string; name: string};
	onDelete: (surveyId: string) => void;
}

const DeleteSurveyModal = React.memo((props: DeleteSurveyModalProps): ReactElement => {
	const {isOpen, onClose, survey, onDelete} = props;
	const [isEqual, setIsEqual] = useState<boolean>(false);

	const inputRef = React.createRef() as MutableRefObject<HTMLInputElement>;
	/**
	 *
	 * @returns sets the isEqual state, or keeps it ths same.
	 */
	const handleChange = (): void => {
		if (inputRef.current.value.replaceAll(/\s+/g, " ").trim()
			=== survey.name.replaceAll(/\s+/g, " ").trim()) {
			setIsEqual(true);
		} else if (isEqual === true) {
			setIsEqual(false);
		}
	};

	/**
	 * Resets the input value and closes the modal
	 */
	const handleCloseAndReset = (): void => {
		setIsEqual(false);
		onClose();
		inputRef.current.value = "";
	};
	/**
	 * Handle delete will take the id of the selected survey and call the delete
	 * mutation on it. Deletes everything related to the survey and cannot be retrieved.
	 */
	const handleDelete = (): void => {
		setIsEqual(false);
		onDelete(survey.id);
		onClose();
	};
	return (
		<Modal
			isOpen={isOpen}
			onClose={handleCloseAndReset}
			className={styles.modal}
			title="Delete Survey"
		>
			<div className={styles.container}>
				<div className={styles.message}>
					<Icon name="warning" fill="var(--color-text-body)" />
					<Subheader className={styles.header} type="semibold" size="l">
            Are you sure you want to delete &quot;{survey.name}&quot;?
					</Subheader>

					<Body size="xs" color="text-secondary" className={styles.info}>
            Deleting will remove all responses and videos for that survey.
						This action cannot be undone. To delete, enter the name of the survey
						below and press the &quot;Delete&quot; button.
					</Body>


				</div>
				<InputWithButton
					label="survey name"
					id="delete-survey-input"
					ref={inputRef}
					buttonText="Delete"
					style="danger"
					onClick={handleDelete}
					onChange={handleChange}
					buttonDisabled={!isEqual}
				/>
			</div>
		</Modal>
	);
});

DeleteSurveyModal.displayName = "DeleteSurveyModal";

export {DeleteSurveyModal};
