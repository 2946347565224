import classNames from "classnames/bind";
import React, {ReactElement, useEffect, useMemo, useState} from "react";

import {Commands} from "..";
import {Popup} from "../../../../shared/v2/popup";
import {useKeyboardEventMiddleware} from "../../../../context/keyboard-event-middleware-context";

import styles from "./command.module.scss";

const cx = classNames.bind(styles);

export interface CommandProps {
  text: string;
  onInsert: (value: string) => void;
  commands: Commands[];
}

export const Command = ({text, onInsert, commands}: CommandProps): ReactElement => {
	const [activeIndex, setActiveIndex] = useState<number>(0);
	const [activeOption, setActiveOption] = useState<HTMLDivElement | null>(null);

	const filteredCommands = useMemo(() => {
		return commands.filter((command) => {
			return command.command.startsWith(text);
		});
	}, [text]);

	useEffect(() => {
		if (activeOption) {
			activeOption.scrollIntoView({block: "nearest"});
		}
	}, [activeOption]);

	useKeyboardEventMiddleware((e) => {
		if (e.key === "ArrowDown") {
			setActiveIndex((prev) => (prev + 1) % filteredCommands.length);
		} else if (e.key === "ArrowUp") {
			setActiveIndex((prev) => (prev - 1 + filteredCommands.length) % filteredCommands.length);
		} else if (e.key === "ArrowLeft") {
			onInsert("");
		} else if (e.key === "Enter") {
			onInsert(filteredCommands[activeIndex].command + " ");
		} else {
			return e;
		}

		e.preventDefault();
	}, {name: "command", order: 1, deps: [filteredCommands.length, activeIndex]});

	return (
		<Popup
			position="top-start"
			className={styles.popup}
			popupClassName={styles.popupPanel}
			space={8}
			alwaysOpen
			portal
			trigger={<>{text}</>}
		>
			<div className={styles.commandsList}>
				{filteredCommands.map((command, index) => (
					<div
						ref={index === activeIndex ? setActiveOption : null}
						onClick={() => onInsert(command.command + " ")}
						className={cx("commandItem", {active: index === activeIndex})}
						key={command.command}
					>
						<span className={styles.commandIcon}>{command.icon}</span>
						<span className={styles.commandText}>{command.command}</span>
						<span className={styles.commandDescription}>{command.description}</span>
					</div>
				))}
			</div>
		</Popup>
	);
}
