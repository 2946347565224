import React, {ReactElement} from "react";
import {Argument} from "classnames";
import classnames from "classnames/bind";
import styles from "./container.module.scss";

const bStyles = classnames.bind(styles);

export interface ContainerProps {
	children: React.ReactNode;
	theme?: "light" | "dark";
	className?: Argument;
}

const Container = (props: ContainerProps): ReactElement => (
	<div className={bStyles("container", props.theme, props.className)}>
		{props.children}
	</div>
);

export {Container};
