import classNames from "classnames/bind";
import React, {ReactElement, useMemo} from "react";

import {AGENT_INITIAL_STATE} from "../../../../reducer/agent-creation-reducer";
import {AgentsPreviewChatBubble} from "../../chat-bubble";
import {Body} from "../../../../shared/v2";
import {Chat} from "../../../../canvas/chat";
import {useAgentCreationContext} from "../../../../context/agent-creation-context";
import {useChatConversationContext, useChatMessagesContext} from "../../../../context/chat-contexts";
import {useIsObjectChanged} from "../../../../hooks";
import {UserProfileRefreshIcon} from "../../../../icons";
import {useThemeMode} from "../../../../context/theme-mode-context";

import styles from "./agent-preview-playground.module.scss";

const cx = classNames.bind(styles);

export const AgentPreviewPlayground = (): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {conversation} = useChatConversationContext();
	const {messages} = useChatMessagesContext();
	const {
		state: currentFormState,
	} = useAgentCreationContext();

	const isAgentInitialState = useMemo(() => Object.keys(AGENT_INITIAL_STATE).some(
		key => currentFormState[key] !== AGENT_INITIAL_STATE[key]
	), [currentFormState]);

	const instructionsObject = useMemo(() => ({
		instructions: currentFormState?.instructions,
		background: currentFormState?.background,
		temperature: currentFormState?.temperature,
		model: currentFormState?.model,
	}), [currentFormState]);

	const haveInstructionsChanged = useIsObjectChanged(
		instructionsObject,
		[messages.length, isAgentInitialState],
	);

	const hasConversation = messages.length > 0 || conversation;
	const isChatUpdated = hasConversation && haveInstructionsChanged;

	const noMessagesContent = (
		<Body
			color={
				isDarkMode ?
					"gray-modern-500" :
					"charcoal-elevation-100"
			}
			className={styles.noMessagesInfo}
			size="l"
		>
      Everything’s in place! Test your agent in this <br /> panel to see how it performs.
		</Body>
	);

	const updatesAppliedContent = (
		<div className={styles.updatesAppliedWrapper}>
			<UserProfileRefreshIcon
				className={cx("updatesAppliedIcon", {isDarkMode})}
			/>
			<Body
				className={styles.updatesAppliedInfo}
				color={
					isDarkMode ?
						"charcoal-elevation-25" :
						"gray-modern-800"
				}
				size="l"
			>
        Updates Applied! See how your agent <br /> performs now.
			</Body>
		</div>
	);

	return (
		<>
			<div className={styles.wrapper}>
				{isChatUpdated && updatesAppliedContent}
				{!hasConversation && noMessagesContent}
				<Chat
					className={cx("chat", {dimmed: isChatUpdated})}
					wrapperClassName={styles.chatWrapper}
				/>
			</div>
			<AgentsPreviewChatBubble className={styles.chatBubble} isChatUpdated={isChatUpdated} />
		</>
	);
}
