import {useAuthState} from "react-firebase-hooks/auth";
import {useCallback} from "react";
import {sendEmailVerification, signOut} from "firebase/auth";

import {auth} from "../../utils/firebase";
import {cache} from "../../cache";

 
export const useAuthentication = () => {
	const [user, loading, error] = useAuthState(auth);

	const logout = useCallback(() => {
		cache.reset();
		return signOut(auth);
	}, []);

	const sendVerificationEmail = useCallback(() => {
		if (user) {
			return sendEmailVerification(user);
		}
	}, []);

	return {
		user,
		loading,
		error,
		logout,
		sendVerificationEmail,
	};
};
