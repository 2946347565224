import {DeleteAnswersReturn, DeleteAnswersVars} from "../../models/answer";
import React, {ReactElement, useContext} from "react";
import {DELETE_ANSWERS} from "../../graphql/mutations/results-mutations";
import {SelectContext} from "../../context/select-context";
import {ToastContext} from "../../context/toast-context";
import styles from "./delete-multiple-answer.module.scss";
import {updateCacheDeletePageItemByFieldId} from "../../shared/utility/update-cache";
import {useMutation} from "@apollo/client";
import {useLocation} from "react-router";
import {Body, Button, Subheader, Modal, BaseModalProps} from "../../shared/v2";

const DeleteMultipleAnswerModal = (props: BaseModalProps): ReactElement => {
	const {isOpen, onClose} = props;
	const {selected, emptySelectValues} = useContext(SelectContext);
	const {updateToast} = useContext(ToastContext);
	const {pathname} = useLocation();


	const [deleteAnswers] = useMutation<DeleteAnswersReturn, DeleteAnswersVars>(DELETE_ANSWERS, {
		onCompleted: data => {
			updateToast({
				description: `Deleted ${data.deleteAnswers.length} answers`,
				type: "informational",
			});
			emptySelectValues();
			onClose();
		},
	});

	const handleConfirm = (): void => {
		// We know the strings are ANSWERID_USERID, we want the first id
		const ansIds = selected.map(value => {
			const [x] = value.split("_");
			return x;
		});
		deleteAnswers({
			variables: {ids: ansIds},
			update(cache, {data}) {
				if (data) {
					if (pathname.includes("video-search")) {
						// If this is being used on video search page we need to cache differently
						data.deleteAnswers.forEach(answer => {
							cache.modify({
								id: `Answer:${answer.id}`,
								fields(fieldValue, details) {
									return details.DELETE;
								},
							});
						});
						return;
					}
					data.deleteAnswers.forEach(answer => {
						updateCacheDeletePageItemByFieldId(
							cache,
							"answers",
							"answer",
							answer.question.id,
							answer.id,
						);
					});
				}
			},
		});
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size="x-small"
			title="Delete Answers"
		>
			<div className={styles.container}>
				<Subheader size="l" type="semibold" className={styles.header}>
					Are you sure you want to delete {`${selected.length}`} answers?
				</Subheader>
				<Body size="xs" className={styles.text}>
					Once removed, all of the answers selected will be permanently deleted
				</Body>
				<Button onClick={handleConfirm} style="danger">Delete</Button>
			</div>
		</Modal>
	);
};

export {DeleteMultipleAnswerModal};
