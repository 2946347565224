import {motion} from "framer-motion";
import classNames from "classnames/bind";
import React, {createRef, HTMLAttributes, ReactElement, ReactNode, useCallback, useMemo, useState} from "react";

import {useObserver} from "../../../hooks/useObserver";

import styles from "./size-transition.module.scss";

const cx = classNames.bind(styles);

export type SizeTransitionProps = HTMLAttributes<HTMLDivElement> & {
  active?: boolean;
  children: ReactNode;
  className?: string;
  dimensions?: "width" | "height" | "both";
  duration?: number;
  transitionClassName?: string;
  // Pixels per second
  speed?: number;
  onTransitionEnd?: () => void;
}

export interface Size {
  width?: number;
  height?: number;
}

const DEFAULT_ANIMATION_DURATION = 0.2;

export const SizeTransition = ({
	children,
	dimensions = "both",
	duration,
	speed,
	active = true,
	onTransitionEnd,
	transitionClassName,
	...props
}: SizeTransitionProps): ReactElement => {
	const ref = createRef<HTMLDivElement>();
	const [transitionEl, setTransitionEl] = useState<HTMLDivElement | null>(null);
	const [size, setSize] = useState<Size>({});

	const handleResize = useCallback((entries: ResizeObserverEntry[]) => {
		const {width, height} = entries[0].contentRect;

		let newSize = {};

		if (dimensions === "width" || dimensions === "both") {
			newSize = {width};
		}

		if (dimensions === "height" || dimensions === "both") {
			newSize = {...newSize, height};
		}

		setSize(newSize);
	}, [dimensions]);

	const animationDuration = useMemo(() => {
		if (typeof speed === "undefined" && typeof duration === "undefined") {
			return DEFAULT_ANIMATION_DURATION;
		}

		if (typeof duration === "number") {
			return duration;
		}

		if (dimensions === "both") {
			return DEFAULT_ANIMATION_DURATION;
		}

		if (!transitionEl || typeof speed !== "number") {
			return DEFAULT_ANIMATION_DURATION;
		}

		const {width, height} = transitionEl.getBoundingClientRect();

		if (dimensions === "width") {
			const diff = Math.abs((size.width ?? 0) - width);
			return diff / speed;
		}

		const diff = Math.abs((size.height ?? 0) - height);
		return diff / speed;
	}, [speed, duration, size, dimensions]);

	useObserver(ref, ResizeObserver, handleResize);

	return (
		<motion.div
			ref={setTransitionEl}
			animate={{
				...size,
				transition: {
					duration: active ? animationDuration : 0,
					ease: "linear",
				}
			}}
			onAnimationComplete={definition => {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				if ("transition" in (definition as any)) {
					onTransitionEnd?.();
				}
			}}
			className={cx(transitionClassName, "sizeTransition")}
		>
			<div ref={ref} {...props}>
				{children}
			</div>
		</motion.div>
	);
}
