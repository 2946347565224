import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {AgentPreviewLoader} from "./agent-preview-loader";
import {AgentPreviewPlayground} from "./agent-preview-playground";
import {useAgentCreationContext} from "../../../context/agent-creation-context";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./agent-preview.module.scss";

const cx = classNames.bind(styles);

export const AgentPreview = (): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {
		agent,
		state: currentAgentData,
	} = useAgentCreationContext();

	// Enabled if agentId is present or all fields are filled out
	const isEnabled =
    agent || (
    	currentAgentData?.name &&
      currentAgentData?.description &&
      currentAgentData?.instructions &&
      currentAgentData?.background &&
      currentAgentData?.personaTypeId &&
      currentAgentData?.model
    );

	return (
		<div className={cx("agentPreviewWrapper", {isDarkMode})}>
			{isEnabled ? (
				<AgentPreviewPlayground />
			) : (
				<AgentPreviewLoader />
			)}
		</div>
	);
}
