import {Dropdown, DropdownItemProps} from "../..";
import React, {ReactElement} from "react";
import {useDropdown} from "../../../hooks";
import {Button} from "../../v2";
import {ButtonProps} from "shared/v2/buttons/button";


export interface ButtonWithDropdownProps extends Omit<ButtonProps, "onClick"> {
	options: DropdownItemProps[];
}

const ButtonWithDropdown = (props: ButtonWithDropdownProps): ReactElement => {
	const {options, children} = props;
	const {anchorEl, handleClick, handleClose} = useDropdown();
	return (
		<>
			<Button  onClick={handleClick}>{children}</Button>
			<Dropdown anchorEl={anchorEl} closeMenu={handleClose} position="right">
				{options.map((option, i) => <Dropdown.Item
					key={`${option.children}-${i}`}
					options={option.options}
					className={option.className}
				>
					{option.children}
				</Dropdown.Item>)}
			</Dropdown>
		</>
	);
};

export {ButtonWithDropdown};
