import {useMutation} from "@apollo/client";
import React, {ReactElement, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";

import {ToastContext} from "../../../context/toast-context";
import {CREATE_WORKSPACE_LOGO} from "../../../graphql/mutations/mutations";
import {UPDATE_WORKSPACE} from "../../../graphql/mutations/workspace-settings-mutations";
import {useMediaUpload} from "../../../hooks/useMediaUpload";
import {Workspace, WorkspacePlan} from "../../../models/workspace";
import {ImageInput} from "../../../shared";
import {Button, Input} from "../../../shared/v2";

import styles from "./create-workspace-step.module.scss";

export interface CreateWorkspaceStepProps {
	workspaces: Workspace[];
}

const CreateWorkspaceStep = (props: CreateWorkspaceStepProps): ReactElement => {
	const [isProcessing, setIsProcessing] = useState<boolean>(false);
	const [workspace, setWorkspace] = useState<string>(props?.workspaces[0]?.name || "");

	const [createWorkspaceLogo] = useMutation(CREATE_WORKSPACE_LOGO);
	const [updateWorkspace, {error}] = useMutation(UPDATE_WORKSPACE);

	const navigate = useNavigate();
	const {updateToast} = useContext(ToastContext);

	const {
		media,
		handleChange,
		handleSave,
		mediaId,
	} = useMediaUpload();

	useEffect(() => {
		const handleSavingImage = async(): Promise<void> => {
			setIsProcessing(true);
			await handleSave(createWorkspaceLogo);
			setIsProcessing(false);
		};
		if (media.raw) {
			handleSavingImage();
		}
	}, [media]);

	const handleSaveWorkspace = (): void => {
		if (workspace) {
			const editedWorkspace = props.workspaces[0];

			updateWorkspace({
				variables: {
					id: editedWorkspace.id,
					changes: {
						name: workspace.trim(),
						logoId: mediaId,
						plan: WorkspacePlan.PRO,
					},
				},
				onCompleted() {
					navigate({
						pathname: "/getting-started",
						search: `workspaceId=${editedWorkspace.id}`,
					});

					updateToast({description: "Workspace Created", type: "informational"});
				},
			});
		}
	};

	return <div className={styles.workspaceInfo}>
		<h2 className={styles.header}>Personalize Your Workspace</h2>
		<div className={styles.imageContainer}>
			<ImageInput
				id="workspace-image"
				onChange={handleChange}
				media={media}
				type="profile"
				isProcessing={isProcessing}
			/>
		</div>
		<p className={styles.label}>Upload a Logo</p>

		<Input
			value={workspace}
			onChange={setWorkspace}
			id="workspace-name"
			className={styles.workspaceInput}
			placeholder="Enter a Workspace Name"
		/>
		{error && <span>{error.message}</span>}
		<div className={styles.actions}>
			<Button
				disabled={!workspace?.trim()}
				onClick={handleSaveWorkspace}
			>
				Submit
			</Button>
		</div>
	</div>;
};

export {CreateWorkspaceStep};
