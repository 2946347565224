 
import {GET_ANSWER_BY_ID, GET_UPDATED_MP4} from "../../graphql/queries/survey-queries";
import {
	LIKE_ANSWER,
	REVIEW_ANSWER,
	UNLIKE_ANSWER,
	UNREVIEW_ANSWER,
} from "../../graphql/mutations/survey-mutations";
import React, {ReactElement, useContext, useEffect, useRef, useState} from "react";
import {useLazyQuery, useMutation} from "@apollo/client";
import {useToastContext} from "../../context/toast-context";
import {Transcript} from "../../modal-components/transcript";
import {VideoOptions} from "../../modal-components/video-options";
import styles from "./transcript-modal.module.scss";
import {VideoInsights} from "../../modal-components/video-insights";
import {useLoadingQuery} from "../../hooks";
import {AnswerTranscriptData} from "../../models/answer";
import {orderSubtitles} from "../../shared/utility/utility";
import {ToggleSwitch} from "../../shared/components/toggle-switch";
import {Button, Modal} from "../../shared/v2";
import {AddReelModal} from "../add-reel";
import {useWorkspaceContext} from "../../context/workspace-context";
import {AddReelVars, CreatedReel, ReelVideoStatus} from "../../models/reels";
import {ADD_REEL} from "../../graphql/mutations/reel-mutations";
import {CreateClipsReturn, CreateClipsVars} from "../../models/clip";
import {CREATE_CLIPS} from "../../graphql/mutations/clip-mutations";
import {DirtyReelContext} from "../../context/dirty-reel-context";

export interface TranscriptModalProps {
	/**
	 * ID of the answer we want to look up
	 */
	answerId: string;
	/**
	 * Function to close the modal
	 */
	onClose: () => void;
	/**
	 * Handles creating a clip (passed from main page)
	 */
}

/**
 * @param answerId The answer id required for all the info in the modal
 * @param onClose Function that handles closing the modal
 * @param question Question text to display at top of the modal
 */
const TranscriptModal = (props: TranscriptModalProps): ReactElement => {
	const {answerId, onClose} = props;
	const vidRef = useRef<HTMLVideoElement>(null);
	const [showTranslation, setShowTranslation] = useState(false);
	const [showReelModal, setShowReelModal] = useState(false);
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const {updateToast} = useToastContext();
	const {setDirtyReel} = useContext(DirtyReelContext);

	const {data, fragment} = useLoadingQuery<AnswerTranscriptData>(GET_ANSWER_BY_ID, {
		skip: !answerId,
		variables: {id: answerId},
		what: "Answer details",
	});
	const [getVideo] = useLazyQuery(GET_UPDATED_MP4, {
		fetchPolicy: "cache-and-network",
		onCompleted: result => {
			const link = document.createElement("a");
			link.href = result.answer.video.mp4;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},
	});

	const [setAsReviewed] = useMutation(REVIEW_ANSWER, {
		variables: {id: answerId},
	});
	const [setAsUnreviewed] = useMutation(UNREVIEW_ANSWER, {
		variables: {id: answerId},
	});

	const [likeAnswer] = useMutation(LIKE_ANSWER);
	const [unlikeAnswer] = useMutation(UNLIKE_ANSWER);
	const [createReel] = useMutation<CreatedReel, AddReelVars>(ADD_REEL);
	const [createClips] = useMutation<CreateClipsReturn, CreateClipsVars>(CREATE_CLIPS, {
		onCompleted: data => {
			updateToast({
				description: "Clip added to reel",
				type: "informational",
			});
			const reel = data.createClips[0].reel;
			if (reel && reel?.videoStatus === ReelVideoStatus.DIRTY) {
				setDirtyReel(reel);
			}
		},
	});

	const handleSetAsReviewed = (): void => {
		setAsReviewed();
	}

	const handleSetAsUnreviewed = (): void => {
		setAsUnreviewed();
	}

	/**
	 * Copies the entire transcript to the user's clipboard.
	 * Apparently we have to create a textarea and get rid of it
	 * after we are done copying.
	 */
	const handleCopyTranscript = (): void => {
		const text = document.getElementById("fullTranscript")?.innerText;
		const el = document.createElement("textarea");
		document.body.appendChild(el);
		el.value = text as string;
		el.select();
		document.execCommand("copy");
		document.body.removeChild(el);
		updateToast({
			type: "informational",
			description: "Transcript copied to clipboard",
		});
	};

	const handleCreateClip = (reelId: string, answerId: string): void => {
		createClips({
			variables: {reelId, input: {answerId}},
		});
	}

	const handleSaveReel = (name: string, description?: string): void => {
		createReel({
			variables: {input: {name, workspaceId, description}},
			onCompleted: data => {
				if (!data.createReel) return;
				setShowReelModal(false);
				handleCreateClip(data.createReel.id, answerId)
			}
		});
	};
	/**
	 * Handles copying just the highlighted text
	 * Similar to copying the whole transcript but only grabbing
	 * highlighted portions.
	 */
	const handleCopyHighlights = (): void => {
		const textElements = document.querySelectorAll("mark");
		const text: string[] = [];
		for (const item of textElements) {
			if (typeof item.textContent === "string") {
				text.push(item.textContent);
			}
		}
		const el = document.createElement("textarea");
		document.body.appendChild(el);
		el.value = text.join("\n");
		el.select();
		document.execCommand("copy");
		document.body.removeChild(el);
		updateToast({
			type: "informational",
			description: "Highlights copied to clipboard",
		});
	};

	/**
	 * Function to handle the click on the download button
	 */
	const handleDownload = (): void => {
		getVideo({
			variables: {id: answerId},
		});
	};

	const handleLike = (): void => {
		likeAnswer({variables: {answerId}});
	};

	const handleUnlike = (): void => {
		unlikeAnswer({variables: {answerId}});
	};

	 
	const handleToggleTranslation = (e: React.ChangeEvent<HTMLInputElement>): void => setShowTranslation(e.target.checked);

	useEffect(() => {
		if (!vidRef.current) return;
		const {current} = vidRef;
		const length = current.textTracks.length;
		if (length === 0) return;
		current.textTracks[0].mode = "showing";
		if (length > 1) current.textTracks[1].mode = "hidden";
	}, [vidRef.current, data]);

	return (<>
		<Modal isOpen={Boolean(answerId)} onClose={onClose}>
			{fragment && <div className={styles.loading}>{fragment}</div>}
			{data &&
				<div className={styles.container}>
					<header>
						<h3 className={styles.question}>{data.answer.question.followUp || data.answer.question.text}</h3>
						<h2 className={styles.header}>
							{data.answer.user?.creatorTag || "Unknown Tag"}
						</h2>
						<span className={styles.name}>
							{data.answer.user.firstName &&
								`${data.answer.user.firstName} ${data.answer.user.lastInitial}.`}
						</span>
					</header>
					<div className={styles.body}>
						<div className={styles.vidAndOptions}>
							<div className={styles.vidContainer}>
								<video
									ref={vidRef}
									controls
									disablePictureInPicture
									controlsList="nodownload"
									className={styles.video}
									crossOrigin="anonymous"
								>
									<source src={data.answer.video.video} />
									<source src={data.answer.video.mp4}/>
									{data.answer.video?.subtitles && data.answer.video?.subtitles.length > 0 &&
									orderSubtitles(data.answer.video?.subtitles).map(sub =>
										<track key={sub.id} lang={sub.langCode} src={sub.fileUrl} label={sub.langCode}/>
									)}
								</video>
							</div>
							<VideoOptions
								answer={data.answer}
								like={handleLike}
								unlike={handleUnlike}
								handleDownload={handleDownload}
								handleShowReelModal={setShowReelModal}
								handleAddClip={handleCreateClip}
							/>
						</div>
						<div className={styles.transcriptWithButtons}>
							<div className={styles.transcript}>
								<Transcript
									answerId={answerId}
									transcript={data.answer.video?.transcript}
									showTranslation={showTranslation}
								/>
								<VideoInsights unparsedInsights={data?.answer.video?.insight} />
								{data.answer.video?.transcript?.englishTranslation && <div className={styles.translationToggle}>
									<span>Translate to English</span>
									<ToggleSwitch
										id="modal-english-toggle"
										onChange={handleToggleTranslation}
										isChecked={showTranslation}
									/>
									<span><i>* Highlighting unavailable for translated transcripts</i></span>
								</div>
								}
							</div>
							{(data.answer.video?.transcript?.words
							&& data.answer.video.transcript.words.length > 0) || data.answer.video.transcript?.text && <div className={styles.actions}>
								{
									data.answer.reviewed
										? (
											<div className={styles.reviewed}>
											Reviewed by {data.answer.reviewer.name}
											</div>
										)
										: null
								}
								<Button
									size="small"
									variant={data.answer.reviewed ? "outlined" : "filled"}
									onClick={data.answer.reviewed ? handleSetAsUnreviewed : handleSetAsReviewed}
								>
									{data.answer.reviewed ? "Mark as Unreviewed" : "Mark as Reviewed"}
								</Button>
								<Button
									size="small"
									variant="outlined"
									onClick={handleCopyHighlights}
								>
									{`Copy Highlights (${data.answer.highlightCount})`}
								</Button>
								<Button
									size="small"
									variant="outlined"
									onClick={handleCopyTranscript}
								>
									Copy Transcript
								</Button>

							</div>}
						</div>
					</div>
				</div>
			}
		</Modal>
		<AddReelModal
			isOpen={showReelModal}
			onClose={() => setShowReelModal(false)}
			workspaceId={workspaceId}
			handleSave={handleSaveReel}
		/>
	</>
	);
};

export {TranscriptModal};
