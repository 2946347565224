import {gql} from "@apollo/client";
/**
 * This might not need its own file, really.
 */

/**
 * Delete answer(s) based on id(s)
 */
export const DELETE_ANSWERS = gql`
	mutation DeleteAnswers ($ids: [GUID!]!) {
		deleteAnswers (ids: $ids) {
			id
			question {
				id
			}
		}
	}
`;

export const DELETE_RESPONSE = gql`
	mutation DeleteResponse ($id: GUID!) {
		deleteResponse(id: $id) {
			id
		}
	}
`;
