import {AnswersPageFilter} from "./answer";
import {WorkspaceMembersFilter} from "./filter";
import {RequestStatus} from "./follower";
import {SensemakeReel} from "./reels";
import {Survey} from "./survey";
import {WorkspaceRole} from "./workspace";


export interface Me extends User {
	permissions: string[] | null;
	hasCreatedTrial: boolean | null;
	experimentalFeatures: boolean;
	role: UserRole;
}
export interface User {
	id: string;
	email?: string;
	creatorTag?: string;
	role?: UserRole;
	gender?: string;
	birthdate?: string;
	city?: string;
	firstName: string;
	lastName?: string;
	lastInitial?: string;
	country?: Country;
	picture?: Picture;
	age?: number;
	lastActive?: Date;
	status?: UserStatus;
	workspaceRole?: WorkspaceRole;
	answeredAt?: string;
	answerCount?: number;
}
export enum UserRoles {
	REGULAR_USER = "REGULAR_USER",
	ENTERPRISE_MANAGER = "ENTERPRISE_MANAGER",
	SUPPORT = "SUPPORT",
}
export type UserRole = keyof typeof UserRoles;
export enum UserStatus {
	PENDING = "PENDING",
	REGISTERED = "REGISTERED",
	CONFIRMED = "CONFIRMED",
}
export enum SurveyMemberStatus {
	INVITED = "INVITED",
	PARTIAL = "PARTIAL",
	COMPLETED = "COMPLETED",
}

export enum EmailTemplate {
	EMAIL_CHANGE = "EMAIL_CHANGE",
	EMAIL_CONFIRMATION = "EMAIL_CONFIRMATION",
	EMAIL_CONFLICT = "EMAIL_CONFLICT",
	PASSWORD_RESET = "PASSWORD_RESET",
	PROFILE_INVITE = "PROFILE_INVITE", // apparently not used anywhere
	SURVEY = "SURVEY",
	SURVEY_REMINDER = "SURVEY_REMINDER", // Mostly care about this and SURVEY.
	WORKSPACE_INVITE = "WORKSPACE_INVITE",
	WORKSPACE_INCOMPLETE_REMINDER = "WORKSPACE_INCOMPLETE_REMINDER",
}

/**
 * There is a lot of info in EmailLog.
 * For now we'll only type what we expect to use.
 */
export interface EmailLog {
	// Omitting ID since it only makes sense in the context of a SurveyMember (may chagne this)
	template: EmailTemplate
	createdAt: Date;
}

export interface SurveyMember {
	userId: string;
	user: Invitee;
	surveyId: string;
	survey: Survey;
	status: SurveyMemberStatus;
	emails: EmailLog[];
}

export interface SurveyMemberPage {
	survey: string;
	items: SurveyMember[];
	cursor: string;
	remaining: number;
}

export interface Invitee {
	id: string;
	firstName: string;
	lastInitial?: string;
	creatorTag: string;
	picture?: Picture;
}

export interface Contact extends User {
	contactTags?: ContactTag[];
	insights?: CreatorInsights;
}

export interface CreatorInsights {
	summary: string;
	interests: string;
	engagement:string;
	mentions: string;
	motivations: string;
	sentiment: string;
}

export interface CreatorDetails {
	id: string;
	firstName: string;
	picture?: Picture;
	totalAnswerDuration: number;
	answerCount: number;
	lastInitial?: string;
	creatorTag?: string;
	sensemakeReel?: SensemakeReel;
	insights: CreatorInsights;
}
export interface Picture {
	id: string;
	badge?: string;
	thumbnail?: string;
}

export interface ContactTag {
	id: string;
	workspaceId: string;
	name: string;
}
export interface WorkspaceMemberTags {
	id: string;
	workspaceId?: string;
	name: string;
}

export interface Country {
	id: string;
	name: string;
}
export interface ContactData {
	contact: Contact;
}
export interface CreatorDetailsData {
	contact: CreatorDetails
}
export interface ContactDataVars {
	workspaceId: string;
	userId: string;
}
export interface ContactsPage {
	items: Contact[];
	cursor: string;
	remaining: number;
}
export interface InviteesPage extends Omit<ContactsPage, "items"> {
	items: Invitee[];
}

export enum ContactsSort {
	DEFAULT = "DEFAULT",
	CREATORTAGASC = "CREATOR_TAG_ASC",
	CREATORTAGDESC = "CREATOR_TAG_DESC",
	AGEASC = "AGE_ASC",
	AGEDESC = "AGE_DESC",
	LASTACTIVEASC = "LAST_ACTIVE_ASC",
	LASTACTIVEDESC = "LAST_ACTIVE_DESC",
}

export interface ContactsPageData {
	contacts: ContactsPage;
}

export interface ContactsPageVars {
	workspaceId: string;
	filter?: WorkspaceMembersFilter;
	limit?: number;
	sort?: ContactsSort;
}
export interface SurveyMembersData {
	surveyMembers: SurveyMemberPage;
}

export interface AddSurveyMember {
	survey: {id: string};
	users: Invitee[];
}

export interface AddSurveyMemberData {
	addSurveyMembers: AddSurveyMember;
}

export interface AddSurveyMemberVars {
	surveyId: string;
	emails?: string[];
	anonymous: boolean;
	useUserIds?: boolean;
	userIds?: string[];
	useFilter?: boolean;
	filter?: AnswersPageFilter;
}
export interface AddContactData {
	addContactsByEmail: string[];
}

export interface FollowedBrandsData {
	me: {
		id: string;
		followedBrands: {id: string}[]
		followRequests: {brandId: string; status: RequestStatus}[]
	}
}

export interface WorkspaceMemberPage {
	items: User[];
	cursor: number;
	remaining: number;
}

export interface WorkspaceMemberReturn {
	workspaceMembers: WorkspaceMemberPage;
}
