import {Subtitles, TranscodingStatus} from "./answer";
import {User} from "./user";
import {Workspace} from "./workspace";

export interface ReelCore {
	id: string;
	name: string;
}
/**
 * This is the optional filter object that one can enter
 * for the reels query
 */
export interface ReelsFilter {
	workspaceId?: string;
	name?: string;
	/**
	 * True returns reels only made by user.
	 * False returns reels made by anyone *but* the user.
	 */
	createdByMe?: boolean;
}

export enum ReelDisplayMode {
	LIGHT = "LIGHT",
	DARK = "DARK",
}
/**
 * The data requested from GET_REEL_VIDEO
 */
export interface ReelVideo {
	id: string;
	name: string;
	displayMode: ReelDisplayMode;
	video: PublishedReelVideo | null;
	requiresPassword: boolean;
	password?: string;
	subtitles: Subtitles[];
}

/**
 * This is specific to the GET_REEL_VIDEO query.
 */
export interface ReelVideoData {
	reel: ReelVideo;
}

export interface DownloadableReelVideoData {
	reel: DownloadableReelVideo;
}

export interface DownloadableReelVideo {
	id: string;
	video: {
		id: string;
		url: string;
	}
}

/**
 * Naming is hard. Below is for polling status on both
 * the videoStatus of a reel, and the associated video's transcoding status
 */
export interface ReelPublishStatus {
	id: string;
	videoStatus: ReelVideoStatus;
}
export interface ReelStatusData {
	reel: ReelPublishStatus;
}

export interface VideoStatus {
	id: string;
	video: {
		id: string;
		transcodingStatus: TranscodingStatus;
	}
}

export interface ReelTranscodingData {
	reel: VideoStatus;
}
export interface PublishedReelVideo {
	id: string;
	transcodingStatus: TranscodingStatus;
	/**
	 * The video in webm
	 */
	video: string;
	/**
	 * The video in mp4 to support Safari
	 */
	mp4: string;
}

export interface Reel extends ReelCore {
	description?: string;
	workspace?: Workspace;
	creator: User;
	createdAt?: Date;
	duration: number;
	clip?: ReelClipPreview;
	videoStatus: ReelVideoStatus;
	editors: User[];
	sharedInBrand: boolean;
}

export interface SensemakeReelVideo extends PublishedReelVideo {
	thumbnail: string;
}

export interface SensemakeReel extends Reel {
	sensemakeDetails: CloudReelDetails;
	video: SensemakeReelVideo;
}

export enum ReelTrackersIndicator {
	LIKE = "LIKE",
	DISLIKE = "DISLIKE",
}

export enum ReelVideoStatus {
	UNPUBLISHED = "UNPUBLISHED",
	PENDING = "PENDING",
	CREATED = "CREATED",
	FAILED = "FAILED",
	DIRTY = "DIRTY",
}

/**
 * This is a little messy, might want to clean up?
 */
export interface ReelClipPreview {
	id: string;
	/**
	 * Answer portion of the clip, to get the thumbnail
	 */
	answer: {
		id: string;
		video: {
			id: string;
			thumbnail: string;
		};
	};
	sourceVideo: {
		thumbnail: string
	};
}
/**
 * A singular reel returned from "reel" query
 */
export interface ReelData {
	reel: Reel;
}
export interface ReelsPage {
	items: Reel[];
	cursor: string;
	remaining: number;
}

export interface ReelsAdderPage {
	items: ReelCore[];
	cursor: string;
	remaining: number;
}

export interface ReelsAdderPageData {
	reels: ReelsAdderPage;
}

export interface ReelsPageData {
	reels: ReelsPage;
}
/**
 * Inputs for a new reel.
 */
export interface NewReel {
	name: string;
	workspaceId: string;
	description?: string;
}

export interface CopyReelVars {
	id: string;
}

export interface ReelFeedback {
	logReelTracker: {id: string};
}

export interface ReelFeedbackVars {
	reelId: string;
	indicator: ReelTrackersIndicator;
}

export interface CopiedReel {
	duplicateReel: Reel;
}
export interface DeleteReelVars {
	id: string;
}
export interface DeletedReel {
	deleteReel: Reel;
}

export interface ReelStopEditVar {
	id: string;
}
export interface ReelStopEditingData {
	stopEditingReel: {
		id: string;
		editors: {
			id: string;
		}
	}
}
export interface UpdateReelsData {
	id: string;
	name?: string;
	description?: string;
	displayMode?: ReelDisplayMode;
	requiresPassword: boolean;
	password?: string;
}
export interface UpdateReelVars {
	id: string;
	changes?: UpdateReelChanges;
	deletions?: UpdateReelDeletions;
}
/**
 * The "changes" argument for the update reel mutation
 */
export interface UpdateReelChanges {
	name?: string;
	description?: string;
	displayMode?: string;
	requiresPassword?: boolean;
	password?: string;
}
export interface UpdateReelDeletions {
	description?: boolean;
	password?: boolean;
}
export interface CreatedReel {
	createReel: Reel;
}
export interface AddReelVars {
	/**
	 * Input object needed for new reel.
	 */
	input: {
		name: string;
		workspaceId: string;
		description?: string;
	};
}

export interface CloudReelKeywords {
	word: string;
	count: number;
}

export interface CloudReelSentiment {
	neutral: number;
	negative: number;
	positive: number;
	compound: number;
}

export interface CloudReelDetails {
	keywords: CloudReelKeywords[];
	sentiment: CloudReelSentiment;
}
