import React, {ReactElement, useContext, useEffect, useRef, useState} from "react";
import {DragSourceMonitor, useDrag, useDrop} from "react-dnd";
import {useMutation} from "@apollo/client";
import {getEmptyImage} from "react-dnd-html5-backend";
import classNames from "classnames";

import {DragItemTypes, QUESTION_CHANGES, QUESTION_TYPES} from "../../../shared/constants/constants";
import {Icon, Input, InputProps, Options} from "../../../shared";
import {determineQuestionType, Question} from "../../../models/questions";
import {UPDATE_AND_SET_QUESTION} from "../../../graphql/mutations/survey-mutations";

import {CREATE_QUESTION_TEMPLATE_FROM_QUESTION} from "../../../graphql/mutations/question-templates";
import {ToastContext} from "../../../context/toast-context";
import {cleanupCache} from "../../../shared/utility/cache-cleanup";
import {toggleDeleteQuestionModal} from "../../../cache";
import {SurveyParams} from "../../";
import {useFilter, useParams} from "../../../route";
import {QUESTION_TEMPLATE_FRAGMENT} from "../../../graphql/fragments/fragments";
import {updateCacheAddPageItem} from "../../../shared/utility/update-cache";
import {Setter} from "../../../types";
import {SurveyContext} from "../../../context/survey-context";
import {useWorkspaceContext} from "../../../context/workspace-context";

import styles from "./question-card.module.scss";

/**
 * Not 100% sure where to put this. Currently only will be used in
 * this component and didn't really want to mess with NumberInput being
 * changed specifically for this case.
 */
export interface OrderInputProps extends InputProps<number> {
	min: number;
	max: number;
	readOnly: boolean;
	resetTo: number;
	setReadOnly: Setter<boolean>;
	handleMove: (newValue?: number) => void;
}
// Same
const OrderInput =

	({id, min, max, value = 0, readOnly, resetTo, onChange, handleMove, setReadOnly, ...props}: OrderInputProps): ReactElement => {
		const ref = useRef<HTMLInputElement>(null);

		const handleBlur = (): void => {
			setReadOnly(true);
			// Likely a cleaner way to do this, just first thing that popped in my head.
			onChange(resetTo);
		};

		// There's a little bit of trickery here due to the index shown to the user being not 0 based
		const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
			const enterVal = (value - 1) > max ? max - 1 : value - 1;
			// Reversing input so it's more natural looking to the user when ordering using arrow keys.
			switch (e.key) {
			case "ArrowUp":
				e.preventDefault();
				if (value > min) {
					onChange(value - 1);
					handleMove(value - 2);
				}
				return;
			case "ArrowDown":
				e.preventDefault();
				if (value < max) {
					onChange(value + 1);
					handleMove(value);
				}
				return;
			case "Enter":
				handleMove(enterVal < 0 ? 0 : enterVal);
				setReadOnly(true);
				break;
			case "Escape":
				handleBlur();
				break;
			default:
			}
		};

		useEffect(() => {
			if (!readOnly && ref.current) {
				ref.current.focus();
				ref.current.select();
			}
		}, [readOnly]);

		return <Input id={id} {...props}>
			<input
				type="number"
				ref={ref}
				value={value}
				onChange={e => onChange(Number(e.target.value))}
				min={min}
				max={max}
				onKeyDown={handleKeydown}
				readOnly={readOnly}
				onBlur={handleBlur}
			/>
		</Input>;
	};

OrderInput.displayName = "OrderInput";

export interface QuestionCardProps {
	/**
	 * The question we want to display
	 */
	question: Question;
	/**
	 * Index of the card. This value exists on the question object, but we
	 * provide it so that drag and drop can happen real time.
	 */
	index: number;
	/**
	 * How to handle dragging an item
	 */
	onDrag?: (dragIndex: number, hoverIndex: number) => void;
	/**
	 * How to handle drag and drop (drop action)
	 */
	onDrop?: (dragIndex: number, dropIndex: number) => void;
	/**
	 * How to handle copying an option
	 */
	onCopy?: (id: string) => void;
	/**
	 * What to do when clicking on "delete"
	 */
	onDelete?: (questionId: string) => void;
	onMove?: (question: Question, endIndex: number, startIndex: number) => void;
	mode: "questions" | "results" | "responses" | "participation";
}

const QuestionCard = React.memo((props: QuestionCardProps): ReactElement => {
	const {question, index, onCopy, onDelete, onDrag, onDrop, mode, onMove} = props;
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const {navigateAndKeepFilter} = useFilter();
	const [number, setNumber] = useState(index + 1);
	const [readOnly, setReadOnly] = useState(true);
	const {questionId} = useParams<SurveyParams>();
	const {updateToast} = useContext(ToastContext);
	const {questions: {length: questionCount}} = useContext(SurveyContext);
	const questionRef = useRef<HTMLDivElement>(null);
	const [updateAndSetQuestion] = useMutation(UPDATE_AND_SET_QUESTION, {
		onError: () => updateToast({
			description: "Something went wrong, please try again",
			type: "failure",
		}),
	});

	const [createQuestionTemplate] = useMutation(CREATE_QUESTION_TEMPLATE_FROM_QUESTION, {
		onCompleted: () =>	updateToast({type: "informational", description: "Question Saved"}),
	});

	const questionType = determineQuestionType(question);
	/**
	 * Drag and drop related shenanigans
	 */
	const [, drop] = useDrop({
		accept: DragItemTypes.QUESTIONCARD,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		hover(item: any) {
			if (!questionRef.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;
			if (onDrag) onDrag(dragIndex, hoverIndex);
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		drop(item: any) {
			if (!questionRef.current) {
				return;
			}
			const dragIndex = item.index;
			const dropIndex = index;
			if (dragIndex === dropIndex) return;
			if (onDrop) onDrop(dragIndex, dropIndex);
		},
	});

	const [{isDragging}, drag, preview] = useDrag(
		() => ({
			type: DragItemTypes.QUESTIONCARD,
			item: {
				index,
				text: question.text,
				questionType: QUESTION_TYPES[questionType].text,
				icon: QUESTION_TYPES[questionType].icon,
				hasVideo: question.videoResponse,
			},
			collect: (monitor: DragSourceMonitor) => ({
				isDragging: monitor.isDragging(),
			}),
		}),
		[index, question.text, questionType, question.videoResponse],
	);

	// Allows us to use a custom drag layer
	useEffect(() => {
		preview(getEmptyImage(), {captureDraggingState: true});
	}, []);

	useEffect(() => {
		setNumber(index + 1);
	}, [index]);

	const handleNavigate = (): void => {
		const pathname =
		(mode === "responses" || mode === "participation") ? `../results/${question.id}` :
			questionId ? `../${mode}/${question.id}` : question.id;

		if (mode !== "questions") cleanupCache(["answers", "question", "videoTranscripts", "responses"]);
		navigateAndKeepFilter(pathname);
	};

	// This is starting to be a pretty long function.
	const handleTypeChange = (key: string): void => {
		// If it is the same type / subtype we don't want to do anything
		if (questionType === key) return;
		updateAndSetQuestion({
			variables: {
				id: question.id,
				...QUESTION_CHANGES[key],
			},
			optimisticResponse: {
				setQuestionType: {
					...question,
					...QUESTION_CHANGES[key].changes,
				},
				updateQuestion: {
					...question,
					...QUESTION_CHANGES[key].changes,
				},
			},
		});
	};

	// Takes current ID and the delete mutation to delete current question
	const handleDelete = (e: React.MouseEvent<HTMLDivElement>): void => {
		e.stopPropagation();
		if (onDelete) {
			if (question.answerCount === 0) {
				onDelete(question.id);
			} else {
				toggleDeleteQuestionModal({
					isShowing: true,
					type: "question",
					id: question.id,
				});
			}
		} else {
			throw new Error("Delete is undefined");
		}
	};
	/**
	 * Callback for copying a survey
	 */
	const handleCopy = (): void => {
		if (onCopy) {
			onCopy(question.id);
		} else {
			throw new Error("Copy is undefined");
		}
	};

	const handleSaveAsTemplate = (): void => {
		createQuestionTemplate(
			{
				variables: {questionId: question.id, workspaceId},
				update(cache, {data: createData}) {
					if (createData) {
						const newRef = cache.writeFragment({
							data: createData.createQuestionTemplateFromQuestion,
							fragment: QUESTION_TEMPLATE_FRAGMENT,
						});

						updateCacheAddPageItem(
							cache,
							newRef,
							"questionTemplates",
							createData.createQuestionTemplateFromQuestion.id,
						);
					}
				},
			},
		);
	};

	const handleMove = (newValue?: number): void => {
		// Getting around the "0" case. Probably could be cleaner.
		if (typeof newValue === "number") {
			onMove?.(question, newValue, index);
		} else {
			onMove?.(question, number - 1, index);
		}
	};

	const toggleReadOnly = (): void => setReadOnly(prev => !prev);

	// Set up drag and drop references if onDrop exists
	if (onDrag) drag(drop(questionRef));
	/**
	 * Begin rendering
	 */
	const visibility = isDragging ? "hidden" : "visible";
	return (
		<div
			style={isDragging ? {backgroundColor: "#DDDDDD"} : undefined}
			className={classNames(
				styles.container,
				(questionId === question.id || (!questionId && !index && mode === "results"))
				&& styles.active,
				!onDrag && styles.pointer,
			)}
			onClick={handleNavigate}
			ref={questionRef}
		>
			<OrderInput
				className={styles.index}
				value={number}
				min={1}
				max={questionCount}
				onChange={setNumber}
				readOnly={readOnly}
				setReadOnly={setReadOnly}
				handleMove={handleMove}
				resetTo={index + 1}
			/>
			<div style={{visibility}} className={styles.type}>
				<span>{QUESTION_TYPES[questionType].text.toUpperCase()}</span>
			</div>
			<div style={{visibility}} className={styles.icons}>
				<Icon name={QUESTION_TYPES[questionType].icon} size="extrasmall"/>
				{
					question.type !== "NONE" && question.videoResponse &&
					<Icon name="question-video" size="extrasmall" className={styles["video-icon"]}/>
				}
			</div>
			<div style={{visibility}} className={styles.question}>{question.text}</div>
			{ mode === "questions" && !isDragging && <div className={styles.actions}>
				<Options
					type="menu-vertical"
					position="left"
					options={[
						{
							name: "Copy",
							actionOptions: {onClick: handleCopy},
							icon: "copy",
						},
						{
							name: "Reorder",
							actionOptions: {onClick: toggleReadOnly},
							icon: "reorder",
						},
						{
							name: "Save to Library",
							actionOptions: {onClick: handleSaveAsTemplate},
							icon: "folder",
						},
						{
							name: "Change Type",
							actionOptions: {options: Object.keys(QUESTION_TYPES).map(t => {
								return {
									children: QUESTION_TYPES[t].text,
									icon: QUESTION_TYPES[t].icon,

									options: {onClick: () => handleTypeChange(t)},
								};
							})},
							icon: "help",
							iconFill: "black",
						},
						{
							name: "Delete",
							actionOptions: {onClick: handleDelete},
							icon: "trash",
						},
					]}
				/>
			</div>
			}
		</div>
	);
});

QuestionCard.displayName = "QuestionCard";
export {QuestionCard};
