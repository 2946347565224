import classNames from "classnames/bind";
import React, {cloneElement, ReactElement} from "react";

import {DropdownItem} from "../..";

import styles from "../../items.module.scss";
import {Checkbox} from "../../../../checkbox";

const cx = classNames.bind(styles);

export interface DropdownItemIconProps {
  item: DropdownItem;
};

export const DropdownItemIcon = ({item}: DropdownItemIconProps): ReactElement => {
	if (item.type === "checkbox") {
		return <Checkbox checked={Boolean(item.active)} onChange={() =>{}} />
	}

	if ("icon" in item) {
		if (item.icon) {
			return cloneElement(
				item.icon ?? <div />,
				{className: cx("icon", item.icon?.props.className)}
			)
		}
	} else if ("avatar" in item) {
		return <>{item.avatar}</>
	}
	return <></>;
}
