/* eslint-disable */

/**
 * Returns true or false if we can parse given item
 * @param item The item we are checking if it is parseable
 */
export const isParseable = (item: any): boolean => {
	try {
		const value = JSON.parse(item);
		if (value && typeof value === "object") return true;
	} catch (e) {
		return false;
	}
	return false;
};
