/* eslint-disable */

import React from "react";
import {Tag} from "../tag";
import {getCurrent} from "../../../ref";
import {siftEmails} from "../../utility/sift-emails";
import styles from "./tag-input.module.scss";
import {useThemeMode} from "../../../context/theme-mode-context";
import classNames from "classnames/bind";
import {checkValidTag, maskEmail} from "../../../shared/utility/utility";

interface TagInputProps {
	handleEnter: (newTag: string[]) => void;
	tags: string[];
	removeTag: (index: number) => void;
	label?: string;
	onPaste?: (e: React.ClipboardEvent<HTMLDivElement>) => void;
	type?: "email" | "states";
}

const bStyles = classNames.bind(styles);

const TagInput = React.forwardRef<HTMLDivElement, TagInputProps>((props, ref) => {
	const {handleEnter, tags, removeTag, label, onPaste, type} = props;
	const {isDarkMode} = useThemeMode();

	/**
	 * Handles clicking inside the "textbox" area, and focuses the input
	 */
	const handleClick = (): void => {
		const current = getCurrent(ref);
		current?.focus?.();
	};
	/**
	 * Handles blurring the input field.
	 * Functions similarly to when a user hits enter or "," or "tab"
	 */
	const handleBlur = (): void => {
		const current = getCurrent(ref);
		if (current?.innerText) {
			const allText = current.innerText.split(/[\s\n]+/);
			current.innerText = "";
			const {validEmails, invalidEmails} = siftEmails(allText);
			handleEnter(validEmails);
			if (invalidEmails.length > 0) {
				current.innerText += invalidEmails.join(" ");
			} else {
				current.innerText = "";
			}
		}
	};
	/**
	 * Checks if valid input, then sends value off to parent
	 * @param e React Keyboard event. Contains what key was pressed inside input
	 */
	const handleKeyDown = (e: React.KeyboardEvent): void => {
		const current = getCurrent(ref);
		if ((e.key === "Enter" || e.key === "," || e.key === "Tab") && current?.innerText) {
			e.preventDefault();
			// Sort of a hack for now. Maybe better support in the future for actual states
			if (!type || type === "email") {
				const allText = current.innerText.split(/[\s\n]+/);
				current.innerText = ""; // clear text so nothing repeats
				const {validEmails, invalidEmails} = siftEmails(allText);
				handleEnter(validEmails.map(valid => valid.toLowerCase()));
				if (invalidEmails.length > 0) {
					current.innerText += invalidEmails.join(" ");
				} else {
					current.innerText = "";
				}
			} else {
				handleEnter([current.innerText]);
				current.innerText = "";
			}
		}
	};
	/**
	 * Gets index of tag clicked on then sends index to parent
	 * @param index The index of the tag we want to remove
	 *
	 * @returns index to parent component to remove selected tag
	 */
	const handleRemoveTag = (e: React.MouseEvent<HTMLSpanElement>, index: number): void => {
		e.stopPropagation();
		removeTag(index);
	};

	return (
		<div className={styles.container}>
			{label && <label htmlFor="tag-input" className={styles.label}>{label}</label>}
			<div className={bStyles("inputbox", {isDarkMode})} onClick={handleClick}>
				{ tags && <div className={styles["tag-container"]}>
					{tags.map((tag: string, i: number) => <Tag
						key={`${tag}-${i}`}
						isDeletable
						clicked={e => handleRemoveTag(e, i)}
					>
						{/* Might have a more efficient way to this, but this is fine*/}
						{checkValidTag(tag, "email") ? maskEmail(tag) : tag}
					</Tag>)}
				</div>
				}
				<div
					className={styles.input}
					contentEditable={true}
					suppressContentEditableWarning={true}
					ref={ref}
					onPaste={onPaste}
					onKeyDown={handleKeyDown}
					onBlur={handleBlur}
				/>
			</div>
		</div>
	);
});

TagInput.displayName = "TagInput";

export {TagInput};
