import {Variants} from "framer-motion"

const CHAT_VIEW_ANIMATION_TRANSTION = {
	ease: "linear",
	duration: 0.1,
}

const HOME_VIEW_ANIMATION_TRANSTION = {
	duration: 0,
}

export const transition = (layer = 0) => ({
	...CHAT_VIEW_ANIMATION_TRANSTION,
	delay: CHAT_VIEW_ANIMATION_TRANSTION.duration * layer,
})

interface ChatViewVariants {
  home: object,
  chat: object,
}

export const variants = ({home, chat}: ChatViewVariants, layer = 0): Variants => ({
	home: {
		...home,
		transition: HOME_VIEW_ANIMATION_TRANSTION,
	},
	chat: {
		...chat,
		transition: transition(layer),
	},
})
