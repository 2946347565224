/* eslint-disable */
import React, {ReactElement} from "react";

import {SearchIcon} from "../../../../icons"
import {Input} from "../input"

export const SearchInput = ({value, onChange, ...props}):ReactElement => {
	return <Input
		leftIcon={<SearchIcon />}
		placeholder="Search"
		size="small"
		value={value}
		onChange={onChange}
		{...props}
	/>
}
