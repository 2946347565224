import React, {ReactElement} from "react";

import {useMemoObject} from "../hooks";

interface ResponseContext {
	questionId?: string;
}

const ResponseContext = React.createContext<ResponseContext>({
	questionId: "",
});

export interface ResponseContextProps {
	children: React.ReactNode;
	questionId?: string;
}

/**
 * Created to hopefully help navigation a bit more on the response page.
 * @param questionId current question id for response page
 * @param searchParams the current search parameters to keep track of.
 */
const ResponseContextProvider = (props: ResponseContextProps): ReactElement => {
	const value = useMemoObject({questionId: props.questionId});
	return <ResponseContext.Provider value={value}>{props.children}</ResponseContext.Provider>;
};

export {ResponseContextProvider, ResponseContext};

