export type FileType = "image" | "video" | "usdz" | "glb" | undefined;

interface CheckFileReturn {
	/**
	 * If the file is supported or not
	 */
	isSupported: boolean;
	/**
	 * What kind of file it is.
	 * "model" refers to an AR file.
	 */
	fileType: FileType;
}
/**
 * Given a mime type, checks if we support it and returns boolean value
 * @param mimeType Mimetype we are checking
 */
export const checkFileType = (mimeType: string): CheckFileReturn => {
	if (
		mimeType.includes("png") || mimeType.includes("jpg") ||
		mimeType.includes("jpeg") || mimeType.includes("gif")
	) return {isSupported: true, fileType: "image"};
	if (mimeType.includes("gltf")) {
		return {isSupported: true, fileType: "glb"};
	}
	if (mimeType.includes("usdz")) {
		return {isSupported: true, fileType: "usdz"};
	}
	if (
		mimeType === "video/mp4" || mimeType.includes("quicktime") ||
		mimeType === "video/x-msvideo"
	) return {isSupported: true, fileType: "video"};
	return {isSupported: false, fileType: undefined};
};


