import {
	CREATE_QUESTION_IMAGE,
	CREATE_SURVEY_VIDEO,
	UPDATE_QUESTION,
} from "../../graphql/mutations/survey-mutations";
import {useMutation} from "@apollo/client";
import {GET_QUESTION_IMAGE, GET_QUESTION_VIDEO_STATUS} from "../../graphql/queries/survey-queries";
import {ImageInput} from "../../shared";
import React, {ReactElement, useEffect, useState} from "react";
import {useMediaUpload} from "../../hooks/useMediaUpload";
import {useWaitForUpload} from "../../hooks/useWaitForUpload";
import {BaseModalProps, Modal} from "../../shared/v2";

export interface QuestionImageUploadModalProps extends BaseModalProps {
	/**
	 * ID of the question
	 */
	questionId: string;
}

const QuestionImageUploadModal = (props: QuestionImageUploadModalProps): ReactElement => {
	const {isOpen, questionId, onClose} = props;
	const [createImage] = useMutation(CREATE_QUESTION_IMAGE);
	const [createVideo] = useMutation(CREATE_SURVEY_VIDEO);
	const [isProcessing, setIsProcessing] = useState(false);
	const {
		clearMedia,
		media,
		hasError,
		handleChange,
		handleSave,
		mediaId,
	} = useMediaUpload();
	const [updateQuestion] = useMutation(UPDATE_QUESTION, {
		onCompleted: () => {
			onClose();
			setIsProcessing(false);
			clearMedia();
		},
		onError: () => setIsProcessing(false),
	});

	const close = (): void => {
		clearMedia();
		onClose();
	};

	const status = useWaitForUpload(
		mediaId,
		media.type === "image" ? GET_QUESTION_IMAGE : GET_QUESTION_VIDEO_STATUS,
	)

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const save = (): Promise<any> => {
		setIsProcessing(true);
		return handleSave(media.type === "image" ? createImage : createVideo);
	};

	useEffect(() => {
		if (mediaId && status === "COMPLETED") {
			if (media.type === "image") {
				updateQuestion({
					variables: {id: questionId, changes: {imageId: mediaId}},
				});
			} else if (media.type === "video") {
				updateQuestion({
					variables: {id: questionId, changes: {videoId: mediaId}},
				});
			}
		}
	}, [mediaId, status]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={close}
			size="small"
			title="Add Image or Video"
		>
			<ImageInput
				id="upload-image"
				onChange={handleChange}
				media={media}
				type="question"
				isProcessing={isProcessing}
				hasError={hasError}
				save={save}
			/>
		</Modal>
	);
};


export {QuestionImageUploadModal};
