import {gql} from "@apollo/client";
import {QUESTION_TEMPLATE_FRAGMENT} from "../fragments/fragments";

export const CREATE_QUESTION_TEMPLATE_FROM_QUESTION = gql`
	mutation CreateQuestionTemplateFromQuestion(
		$questionId: GUID!,
		$workspaceId: GUID!,
		$global: Boolean,
	) {
			createQuestionTemplateFromQuestion(questionId: $questionId global: $global) {
				...QuestionTemplateFields
			}
	}
	${QUESTION_TEMPLATE_FRAGMENT}
`;
