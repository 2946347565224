import React, {ReactElement} from "react";
import Lottie from "lottie-react";

import LoadingVurveyLight from "../../../../lottie/LoadingVurveyLight.json";
import LoadingVurveyDark from "../../../../lottie/LoadingVurveyDark.json";
import {useThemeMode} from "../../../../context/theme-mode-context";

const Spinner = ({className}: {className?: string}): ReactElement => {
	const {isDarkMode} = useThemeMode();

	return <Lottie
		className={className}
		animationData={isDarkMode ? LoadingVurveyDark : LoadingVurveyLight}
		loop={true}
	/>
}

export {Spinner};
