import React, {ReactElement, useState} from "react";

import {Survey, SurveyStatus} from "../models/survey";
import {Attribute} from "../models/attribute";
import {Question} from "../models/questions";
import {WorkspacePlan} from "../models/workspace";
import {SurveyAccessLevel} from "../shared/constants/constants";
import {Setter} from "../types";
import {useMemoObject} from "../hooks";
import {TremendousCurrencyType} from "../models/rewards";

interface SurveyContext {
	survey: Survey;
	plan: WorkspacePlan | null;
	questions: Question[];
	attributes: Attribute[];
	isShowingPublish: boolean;
	setShowingPublish: Setter<boolean>;
	viewingResults: boolean;
	setViewingResults: Setter<boolean>;
	showFilter: boolean;
	setShowFilter: Setter<boolean>;
	showShare: boolean;
	setShowShare: Setter<boolean>;
	responsesTabPage: number;
	setResponsesTabPage: Setter<number>;
}

const SurveyContext = React.createContext<SurveyContext>({
	survey: {
		id: "",
		workspaceId: "",
		name: "",
		accessLevel: SurveyAccessLevel.PRIVATE,
		answersPreview: [],
		status: SurveyStatus.DRAFT,
		startText: "",
		introduction: "",
		questionCount: 0,
		responseUrl: "",
		responseCount: 0,
		completedResponseCount: 0,
		totalVideoDuration: 0,
		incentiveCurrency: TremendousCurrencyType.USD,
		estimatedCompletionTime: 0,
		answersPreviewCount: 0,
		offlineAssist: false,
		requiresPassword: false,
		creator: {
			id: "",
			firstName: "",
		},
		notifications: [],
		multiResponse: false,
		autoClosed: false,
		language: {
			id: "",
			name: "",
		},
		redirectUrl: null,
	},
	plan: null,
	questions: [],
	attributes: [],
	isShowingPublish: false,
	setShowingPublish: () => undefined,
	viewingResults: false,
	setViewingResults: () => undefined,
	showFilter: false,
	setShowFilter: () => undefined,
	showShare: false,
	setShowShare: () => undefined,
	responsesTabPage: 0,
	setResponsesTabPage: () => undefined,
});

export interface SurveyContextProps extends Omit<
SurveyContext,
 
"isShowingPublish"|"setShowingPublish"|"viewingResults"|"setViewingResults"|"showFilter"|"setShowFilter"|"responsesTabPage"|"setResponsesTabPage"|"showShare"|"setShowShare"
> {
	children: React.ReactNode;
}

const SurveyContextProvider = ({children, ...props}: SurveyContextProps): ReactElement => {
	const [isShowingPublish, setShowingPublish] = useState(false);
	const [showFilter, setShowFilter] = useState(false);
	const [showShare, setShowShare] = useState(false);
	const [viewingResults, setViewingResults] = useState(false);
	const [responsesTabPage, setResponsesTabPage] = useState<number>(0);
	const value = useMemoObject({
		...props,
		isShowingPublish,
		setShowingPublish,
		viewingResults,
		setViewingResults,
		showFilter,
		setShowFilter,
		showShare,
		setShowShare,
		responsesTabPage,
		setResponsesTabPage,
	});
	return <SurveyContext.Provider value={value}>{children}</SurveyContext.Provider>;
};

export {SurveyContext, SurveyContextProvider};
