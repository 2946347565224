import React, {
	MutableRefObject,
	ReactElement,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import classnames from "classnames/bind";
import {Argument} from "classnames";
import {Link, To} from "react-router-dom";

import {Icon, IconTypes} from "../../";
import {Portal} from "../portal";
import {useThemeMode} from "../../../context/theme-mode-context";
import {Body} from "../../../shared/v2/typography";

import styles from "./dropdown.module.scss";

const bStyles = classnames.bind(styles);

export interface DropdownProps {
	/**
	 * What the dropdown should anchor to
	 */
	anchorEl: HTMLElement | null;
	/**
	 * Children (will usually be DropmenuItems)
	 */
	children: React.ReactNode;
	/**
	 * Handle closing the menu
	 */
	closeMenu: () => void;
	/**
	 * Determines which side the menu should appear on
	 */
	position?: "left" | "right" | "side";
	/**
	 * If dropdown should only close on click of outside
	 * the dropdown
	 */
	keepOpen?: boolean;
	/**
	 * Optional width to determine size of dropdown
	 */
	width?: number;
	/**
	 * Optional height to help determine height of dropdown
	 */
	height?: number;
	icon?: IconTypes;
	iconFill?: string;
	closeOnLeave?: boolean;
	className?: Argument;
}

/**
 *
 * @param anchorEl Element that the dropdown will appear next to
 * @param children Generally the Dropdown.Item component. Options to click on
 * @param closeMenu Function to close menu. Used when clicking on anywhere outside the dropdown
 * @param position "left" or "right". Forces dropdown to appear left or right of element as of now
 * @param keepOpen If true, dropdown will stay open until user clicks outside of the dropdown.
 */
const Dropdown: MenuComponent = (props: DropdownProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	const {anchorEl, children, className, closeMenu, position, keepOpen = false, width, height, closeOnLeave = false} = props;
	const optionCount = useRef<number>(React.Children.count(children));
	const dropdownRef = useRef() as MutableRefObject<HTMLDivElement>;

	const handleClick = ({target}): void => {
		if (dropdownRef.current?.contains(target)) {
			if (keepOpen) return;
			closeMenu();
		} else {
			closeMenu();
		}
	};

	useEffect(() => {
		if (anchorEl) {
			setTimeout(() => {
				document.addEventListener("click", handleClick);
			}, 0);
		}
		return () => {
			setTimeout(() => {
				document.removeEventListener("click", handleClick)
			}, 0);
		};
	}, [dropdownRef.current, anchorEl]);
	/**
	 * Determines the position of the dropdown based on the anchor element..
	 */
	const getPosition = useCallback((): React.CSSProperties | undefined => {
		// 40 is the minimum height the dropdown will be (one option)
		if (anchorEl) {
			let top: number | undefined, bottom: number | undefined;
			const spaceToBottom = window.innerHeight - anchorEl.getBoundingClientRect().bottom;
			if (position === "side") {
				top = anchorEl.getBoundingClientRect().top;
				let left = anchorEl.getBoundingClientRect().left + 178;
				// Account for being too far to the right.
				if (anchorEl.getBoundingClientRect().right + 180 > window.innerWidth) left -= 358;
				return {top, left};
			}
			if (spaceToBottom < 40 * optionCount.current || (height && spaceToBottom < height)) {
				bottom = window.innerHeight - anchorEl.getBoundingClientRect().top;
			} else {
				top = anchorEl.getBoundingClientRect().top +
					anchorEl.getBoundingClientRect().height;
			}
			let {left} = anchorEl.getBoundingClientRect();
			if (
				position === "right" ||
				anchorEl.getBoundingClientRect().left + 180 > window.innerWidth
			) {
				left -= 180 - anchorEl.clientWidth;
			}
			return {top, left, bottom};
		}
		return undefined;
	}, [anchorEl]);

	return (
		<>
			{
				anchorEl &&
				<Portal id="overlay-content">
					<div
						className={bStyles("dropdown", className, {isDarkMode})}
						style={{width, ...getPosition()}}
						ref={dropdownRef}
						onMouseLeave={closeOnLeave ? closeMenu : undefined}
					>
						{children}
					</div>
				</Portal>
			}
		</>
	);
};

type Badge = "PRO" | "ENTERPRISE";

export interface DropdownSectionProps {
	title: string;
	children: React.ReactNode;
	skip?: boolean;
}

export interface DropdownItemProps {
	options: AnyOptions;
	skip?: boolean;
	icon?: IconTypes;
	iconFill?: string;
	children: React.ReactNode;
	className?: Argument;
}
interface DropdownMenuProps {
	options: DropdownItemProps[];
	icon?: IconTypes;
	iconFill?: string;
	name: React.ReactNode;
	badge?: Badge;
	className?: Argument;
}

interface OptionsWithLink {
	isLink: boolean;
	onClick?: never;
	isClickable?: never;
	skip?: boolean;
	badge?: never;
	icon?: IconTypes;
	iconFill?: string;
	linkTo: To;
	options?: never;
	className?: Argument;
}
export interface OptionsWithClick {
	isLink?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onClick: any;
	isClickable?: boolean; // If not defined, defaults to true
	skip?: boolean;
	badge?: Badge;
	icon?: IconTypes;
	iconFill?: string;
	linkTo?: never;
	options?: never;
	className?: Argument;
}
interface OptionsWithOptions {
	isLink?: never;
	onClick?: never;
	isClickable?: never;
	badge?: Badge;
	skip?: boolean;
	linkTo?: never;
	icon?: IconTypes;
	iconFill?: string;
	options: DropdownItemProps[];
	className?: Argument;
}
type AnyOptions = OptionsWithLink | OptionsWithClick | OptionsWithOptions;

export interface DropdownOptions {
	name: string;
	actionOptions: AnyOptions;
	iconFill?: string;
	icon?: IconTypes;
	className?: Argument;
	tooltipText?: string;
}

type SectionComponent = React.FunctionComponent<DropdownSectionProps>;
type ItemComponent = React.FunctionComponent<DropdownItemProps>;
type MenuComponent = React.FunctionComponent<DropdownProps> & {
	Item: ItemComponent,
	Section: SectionComponent,
};

const DropdownMenu = (props: DropdownMenuProps): ReactElement => {
	const {options, name, icon, iconFill, badge, className} = props;
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const handleClose = (): void => setAnchorEl(null);
	const {isDarkMode} = useThemeMode();

	const handleHover = (e: React.MouseEvent<HTMLElement>): void => {
		if (!anchorEl) setAnchorEl(e.currentTarget);
	};
	return (
		<div onMouseLeave={handleClose}>
			<div
				className={bStyles(className, styles.item, styles.menu, {isDarkMode})}
				onMouseOver={handleHover}
			>
				{icon && <Icon name={icon} fill={iconFill} size="extrasmall" className={styles.icon}/>}
				<Body size="s">{name}</Body>
				{
					badge && <span
						className={[
							styles.badge, badge === "PRO" ? styles.light : styles.dark,
						].join(" ")}>
						{badge}
					</span>
				}
				<Icon name="chevron" size="extrasmall" fill="var(--color-text-body)"/>
			</div>
			<Dropdown
				anchorEl={anchorEl}
				closeMenu={handleClose}
				position="side"
			>
				{options.map((o, i) => {
					if (!o.skip) return <Dropdown.Item {...o} key={i}/>;
					return null;
				})}
			</Dropdown>
		</div>
	);
};

/**
 * @param children Children to render. Generally just text
 * @param options Object that contains if action is a link or a function callback
 */
const DropdownItem: ItemComponent = (props: DropdownItemProps): ReactElement | null => {
	const {
		children,
		icon,
		iconFill,
		className,
		options: {isLink, linkTo, onClick, isClickable = true, badge, options, skip},
	} = props;

	const {isDarkMode} = useThemeMode();

	if (skip) return null;

	if (options) return <DropdownMenu options={options} icon={icon} iconFill={iconFill} name={children} badge={badge}/>;
	if (isLink && linkTo) {
		return <Link to={linkTo} className={styles.link}>
			{icon && <Icon name={icon} fill={iconFill} size="extrasmall" className={styles.icon}/>}
			{children}
		</Link>;
	}
	const divClasses = bStyles(className, styles.item, !isClickable && styles.inactive, {isDarkMode});

	return (
		<div className={divClasses} onClick={isClickable ? onClick : undefined}>
			{icon && <Icon name={icon} fill={iconFill} size="extrasmall" className={styles.icon}/>}
			<Body size="s">{children}</Body>			{
				badge && <span
					className={[
						styles.badge, badge === "PRO" ? styles.light : styles.dark,
					].join(" ")}>
					{badge}
				</span>
			}
		</div>
	);
};

const DropdownSection: SectionComponent = (props: DropdownSectionProps): ReactElement | null => {
	return (<>
		{
			!props.skip && <div className={styles.section}>
				<span className={styles.sectionTitle}>{props.title}</span>
				<div className={styles.sectionContent}>
					{props.children}
				</div>
			</div>
		}
	</>);
};

Dropdown.Item = DropdownItem;
Dropdown.Section = DropdownSection;

export {Dropdown, DropdownItem, DropdownSection};
