import React, {createRef, ReactElement, useState} from "react";

import {Body, Subheader} from "../../../shared/v2/typography";
import {Card} from "../../../shared/v2";
import {DotsIcon, FileCorrectIcon, PenEditIcon, SparkAiStarsIcon} from "../../../icons";
import {Dropdown} from "../../../shared/v2/dropdown";
import {EditTrainingSetModal} from "../../modals/edit-training-set-modal";
import {FileUploader} from "../file-uploader";
import {GET_TRAINING_SETS} from "../../../graphql/queries/ai-models-queries";
import {OverflowTooltip} from "../../../shared/v2/tooltip/overflow-tooltip";
import {Separator} from "../../../shared/v2/separator";
import {TrainingSet} from "../../../models/ai-model";
import {useNavigate} from "../../../route";

import styles from "./dataset-card.module.scss";

export interface TrainingCardProps {
	trainingSet: TrainingSet
}


export const DatasetCard = ({trainingSet}: TrainingCardProps): ReactElement => {
	const navigate = useNavigate();
	const subheaderRef = createRef<HTMLDivElement>();
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);

	const filesCount = trainingSet.files.length + trainingSet.videos.length;

	const goToTrainingSet = (): void => {
		navigate(`dataset/${trainingSet.id}`, {workspace: true});
	};

	const openInCopilot = (event?: MouseEvent): void => {
		event?.stopPropagation();
		navigate(
			{
				pathname: "/workflow/conversation",
				search: {trainingSetIds: [trainingSet.id]},
			},
			{workspace: true},
		);

	};

	return (
		<>
			<Card
				onClick={goToTrainingSet}
				className={styles.set}
				id={trainingSet.id}
			>
				<Dropdown
					className={styles.dropdown}
					popupClassName={styles.dropdownPopup}
					trigger={<DotsIcon className={styles.trigger} />}
					items={[
						{
							label: "Start a Conversation",
							icon: <SparkAiStarsIcon />,
							onClick: openInCopilot,
						},
						{
							label: "Edit",
							icon: <PenEditIcon />,
							onClick: () => setIsEditModalOpen(true),

						}
					]}
					position="bottom-start"
				/>
				<div className={styles.contentWrapper}>
					<OverflowTooltip>
						<Subheader
							ref={subheaderRef}
							type="semibold"
							className={styles.header}
						>
							{trainingSet.alias}
						</Subheader>
					</OverflowTooltip>
					<Body size="s" className={styles.description} color="text-secondary">
						{trainingSet.description}
					</Body>
				</div>
				<div className={styles.bottomSection}>
					<Separator />
					<div className={styles.filesWrapper}>
						<div className={styles.filesSection}>
							<FileCorrectIcon className={styles.fileIcon} />
							<Body size="s" type="medium" className={styles.fileText}>
								{filesCount} {filesCount === 1 ? "file" : "files"}
							</Body>
						</div>

						<FileUploader
							trainingSet={trainingSet}
							refetchQuery={GET_TRAINING_SETS}
						/>
					</div>
				</div>
			</Card>

			<EditTrainingSetModal
				trainingSet={trainingSet}
				isOpen={isEditModalOpen}
				onClose={() => setIsEditModalOpen(false)}
			/>
		</>
	)
};
