/* eslint-disable react/prop-types */
import React, {ReactElement, useContext, useMemo, useState} from "react";
import {Column, HeaderGroup, Row} from "react-table";
import {NetworkStatus, useMutation} from "@apollo/client";
import {v4 as uuid} from "uuid";

import {formatDate, Options} from "../../../shared";
import {NavLink} from "../../../route";
import {GET_DYNAMIC_SEGMENTS} from "../../../graphql/queries/queries";
import {DELETE_DYNAMIC_SEGMENTS, DUPLICATE_DYNAMIC_SEGMENT} from "../../../graphql/mutations/mutations";
import {ActionsButton} from "../../components/actions-button";
import {DeleteConfirmModal} from "../../../modals/delete-reel";
import {EmptyScreen} from "../../components/empty-screen";
import {ToastContext} from "../../../context/toast-context";
import {useLoadingQuery} from "../../../hooks";
import {updateCacheAddPageItem, updateCacheDeletePageItem} from "../../../shared/utility/update-cache";
import {AddSegmentModal} from "../../../modals/add-segment";
import {
	DynamicSegment,
	DynamicSegmentData,
	DynamicSegmentRule,
	DynamicSegmentSort,
	MatchType,
} from "../../../models/dynamic-segment";

import styles from "./segments.module.scss";
import {AlignText} from "../../../shared/typography/align-text";
import {DYNAMIC_SEGMENT_FRAGMENT} from "../../../graphql/fragments/fragments";
import {Body} from "../../../shared/v2/typography";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {PaginatedTable} from "../../../shared/components/table/paginated-table";
import {Button, SearchInput} from "../../../shared/v2";

interface SegmentsPageProps {
	selector: JSX.Element;
}

const PAGE_SIZE = 50;

const SegmentsPage = ({selector}: SegmentsPageProps): ReactElement => {
	const {
		workspace: {id: workspaceId},
	} = useWorkspaceContext();
	const [showModal, setShowModal] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [sortBy, setSortBy] = useState(DynamicSegmentSort.DEFAULT);
	const [currentPage, setCurrentPage] = useState(0);
	const [selectedLists, setSelectedLists] = useState<Row<DynamicSegment>[]>([]);
	const [list, setList] = useState("");
	const [editableSegment, setEditableSegment] = useState<null | DynamicSegment>(null);
	const [combinedSegment, setCombinedSegment] = useState<null | DynamicSegment>(null);
	const {updateToast, onError} = useContext(ToastContext);

	const {data, fragment, fetchMoreFragment, handleFetchMore, networkStatus} = useLoadingQuery<DynamicSegmentData>(
		GET_DYNAMIC_SEGMENTS,
		{
			variables: {workspaceId, sort: sortBy, limit: PAGE_SIZE, filter: {name: searchValue}},
			what: "segments",
			fetchPolicy: "cache-and-network",
			notifyOnNetworkStatusChange: true,
		},
	);

	const [deleteDynamicSegments] = useMutation(DELETE_DYNAMIC_SEGMENTS);
	const [copyDynamicSegment] = useMutation(DUPLICATE_DYNAMIC_SEGMENT);

	const handleDeleteSegment = (): void => {
		deleteDynamicSegments({
			variables: {ids: list},
			onCompleted: () => {
				setList("");
				updateToast({description: "Deleted Segment", type: "informational"});
			},
			update(cache, {data: deleteData}) {
				if (!deleteData) return;
				deleteData.deleteDynamicSegments.forEach((segment) => {
					updateCacheDeletePageItem(cache, "dynamicSegments", "dynamicSegment", segment.id);
				});
			},
		});
	};

	const handleCopy = (id: string): void => {
		copyDynamicSegment({
			variables: {id},
			onCompleted: () => updateToast({description: "Segment copied", type: "informational"}),
			update(cache, {data: segment}) {
				if (!segment) return;
				const copiedRef = cache.writeFragment({
					data: segment.duplicateDynamicSegment,
					fragment: DYNAMIC_SEGMENT_FRAGMENT,
				});

				updateCacheAddPageItem(cache, copiedRef, "dynamicSegments", segment.duplicateDynamicSegment.id);
			},
		});
	};

	const handleDeleteAll = (): void => {
		deleteDynamicSegments({
			variables: {ids: selectedLists?.map((r) => r.original.id)},
			onCompleted: () => {
				setShowDelete(false);
				updateToast({description: "Deleted Segments", type: "informational"});
			},
			onError: (e) => {
				setShowDelete(false);
				onError(e);
			},
			update(cache, {data: deleteData}) {
				if (!deleteData) return;
				deleteData.deleteDynamicSegments.forEach((segment) => {
					updateCacheDeletePageItem(cache, "dynamicSegments", "dynamicSegment", segment.id);
				});
			},
		});
	};

	const handleSort = (value: HeaderGroup<DynamicSegment>): void => {
		if (!value.canSort) return;

		switch (value.Header) {
		case "Name":
			if (value.isSorted === false && value.isSortedDesc === undefined) return setSortBy(DynamicSegmentSort.NAME_ASC);
			if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(DynamicSegmentSort.NAME_DESC);
			setSortBy(DynamicSegmentSort.DEFAULT);
			break;
		case "Created":
			if (value.isSorted === false && value.isSortedDesc === undefined)
				return setSortBy(DynamicSegmentSort.CREATED_AT_ASC);
			if (value.isSorted === true && value.isSortedDesc === false)
				return setSortBy(DynamicSegmentSort.CREATED_AT_DESC);
			setSortBy(DynamicSegmentSort.DEFAULT);
			break;
		default:
			setSortBy(DynamicSegmentSort.DEFAULT);
		}

	};

	const editDynamicSegment = (dynamicSegment: DynamicSegment): void => {
		setEditableSegment(dynamicSegment);
		setShowModal(true);
	};

	const createCombinedSegment = (): void => {
		const segments = selectedLists.map((row) => row.original);
		const segment = segments.reduce(
			(acc, cur): DynamicSegment => {
				const rules = cur.rules.map((rule) => {
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					const {__typename, ...rest} = rule;
					return rest;
				});
				acc.rules.push(...rules);
				return acc;
			},
			{
				matchType: MatchType.Any,
				name: "",
				id: uuid() as string,
				rules: [] as DynamicSegmentRule[],
				workspaceId,
			},
		);

		setCombinedSegment(segment);
		setShowModal(true);
	};

	const currentData = useMemo(
		() => data?.dynamicSegments.items.slice(currentPage * PAGE_SIZE, (currentPage + 1) * PAGE_SIZE),
		[data, currentPage],
	);

	const columns = useMemo(
		(): Column<DynamicSegment>[] => [
			{
				id: "name",
				Header: "Name",
				accessor: "name",
				minWidth: 200,
				Cell: ({row: {original}}) => (
					<NavLink
						to={`/audience/segment/${original.id}`}
						state={{title: original.name, dynamicSegments: true}}
						className={styles.link}
					>
						{original.name}
					</NavLink>
				),
			},
			{
				id: "creators",
				Header: "Creators",
				accessor: "memberCount",
				disableSortBy: true,
				Cell: ({value}) => <Body size="s">{value}</Body>,
			},
			{
				Header: "Created",
				accessor: "createdAt",
				Cell: ({value}) => <Body size="s">{value ? formatDate(value) : "Unknown"}</Body>,
			},
			{
				id: "options",
				Header: " ",
				maxWidth: 50,
				disableSortBy: true,
				Cell: ({row: {original}}) => (
					<AlignText align="right">
						<Options
							type="menu-vertical"
							position="right"
							options={[
								{
									name: "Edit",
									actionOptions: {onClick: () => editDynamicSegment(original)},
									icon: "pen",
									iconFill: "var(--color-text-body)",
								},
								{
									name: "Copy",
									actionOptions: {onClick: () => handleCopy(original.id)},
									icon: "copy",
									iconFill: "var(--color-text-body)",
								},
								{
									name: "Delete",
									actionOptions: {onClick: () => setList(original.id)},
									icon: "trash",
									iconFill: "var(--color-text-body)",
								},
							]}
						/>
					</AlignText>
				),
			},
		],
		[data],
	);

	const handleOpenAddModal = (): void => setShowModal(true);

	const handleCloseAddModal = (): void => {
		setEditableSegment(null);
		setCombinedSegment(null);
		setShowModal(false);
	};

	return (
		<>
			<header className={styles.head}>
				<div className={styles.inputs}>
					<SearchInput value={searchValue} onChange={setSearchValue} />
					{selector}
				</div>
				{data && data.dynamicSegments.items.length > 0 && (
					<div className={styles.actions}>
						{selectedLists.length > 0 && (
							<ActionsButton
								text={`Bulk Actions (${selectedLists.length})`}
								options={[
									{
										name: "Delete",
										actionOptions: {onClick: () => setShowDelete(true)},
									},
									{
										name: "Create Combined Segment",
										actionOptions: {onClick: () => createCombinedSegment()},
									},
								]}
							/>
						)}
						<Button onClick={handleOpenAddModal}>New Segment</Button>
					</div>
				)}
			</header>
			{(!data && fragment) ||
				(data && currentData && data.dynamicSegments.items.length > 0 ? (
					<PaginatedTable<DynamicSegment>
						data={currentData}
						columns={columns}
						handleFetchMore={handleFetchMore}
						dataLength={data.dynamicSegments.items.length}
						pageSize={PAGE_SIZE}
						totalCount={data.dynamicSegments.remaining + data.dynamicSegments.items.length}
						pageState={[currentPage, setCurrentPage]}
						fetchMoreFragment={fetchMoreFragment}
						onSelectChange={setSelectedLists}
						selectedValues={selectedLists}
						filters={["name", "memberCount"]}
						sortLoading={networkStatus === NetworkStatus.setVariables}
						onSort={handleSort}
					/>
				) : searchValue ? (
					<Body>No results found</Body>
				) : (
					<EmptyScreen whatIsEmpty="SEGMENT" action={handleOpenAddModal} />
				))}

			{showModal && (
				<AddSegmentModal
					initialState={editableSegment || combinedSegment}
					onClose={handleCloseAddModal}
					isCombined={Boolean(combinedSegment)}
				/>
			)}

			<DeleteConfirmModal
				isOpen={Boolean(list)}
				onClose={() => setList("")}
				handleConfirm={handleDeleteSegment}
				warningText="Are you sure you want to delete this segment?"
			/>
			<DeleteConfirmModal
				isOpen={showDelete}
				onClose={() => setShowDelete(false)}
				handleConfirm={handleDeleteAll}
				warningText="Are you sure you want to delete these segments?"
			/>
		</>
	);
};

export {SegmentsPage};
