import {ChatConversationMessageRole, ChatConversationMessage} from "../../../models/ai-model";
import {CHAT_MESSAGE_FAKE_INPUT_MARKER} from "../../../shared/constants/constants";

export const generateFakeInputMessage = (data: {conversationId?: string, content: string}) => {
	return {
		id: `${CHAT_MESSAGE_FAKE_INPUT_MARKER}${Date.now()}`,
		role: ChatConversationMessageRole.USER,
		...data,
	} as ChatConversationMessage;
};
