import React, {ReactElement, useContext, useEffect, useState} from "react";
import styles from "./copy-answer.module.scss";
import {useLoadingQuery} from "../../../hooks";
import {GET_MY_WORKSPACES} from "../../../graphql/queries/queries";
import {Option, Select} from "../../../shared";
import {MyWorkspacePageData} from "../../../models/workspace";
import {useMutation} from "@apollo/client";
import {COPY_ANSWER_FROM_SURVEY} from "../../../graphql/mutations/survey-mutations";
import {ToastContext} from "../../../context/toast-context";
import {TipBanner} from "../../../shared/components/tip-banner";
import {BaseModalProps, Button, Modal} from "../../../shared/v2";

export interface CopyAnswerModalProps extends BaseModalProps {
	answerId: string;
}

const CopyAnswerModal = (props: CopyAnswerModalProps): ReactElement => {
	const {answerId, isOpen, onClose} = props;
	const [selectedId, setSelectedId] = useState("");
	const {updateToast} = useContext(ToastContext);
	const {data, fragment, error} = useLoadingQuery<MyWorkspacePageData>(GET_MY_WORKSPACES, {
		skip: !isOpen,
		what: "workspaces",
	});

	const [copyAnswer, {loading}] = useMutation(COPY_ANSWER_FROM_SURVEY);

	const options: Option[] = data?.myWorkspaces.items.map(workspace =>
		({text: workspace.name, value: workspace.id})) ?? [{text: "Loading", value: "none"}];

	const handleCopy = (): void => {
		copyAnswer({
			variables: {answerId, workspaceId: selectedId},
			onCompleted: () => {
				updateToast({description: "Story created, check branding page for the workspace", type: "informational"});
				onClose();
			},
			onError: () => {
				updateToast({description: "Error completing request, please try again later", type: "failure"});
			}
		});
	};

	useEffect(() => {
		if (!isOpen) setSelectedId("");
	}, [isOpen]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title="Choose Workspace"
		>
			{fragment || !error && <>
			 	<Select
					id="workspace-select"
					selectedValue={selectedId}
					options={options}
					onChange={setSelectedId}
					disabled={loading}
					isSearchable
				/>
				<Button
					disabled={!selectedId || loading}
					onClick={handleCopy}
					className={styles.button}
				>
					{loading ? "Creating..." : "Create"}
				</Button>
				<TipBanner>
				Answer will appear in related Brand Stories after success
				</TipBanner>
			</>
			}
			{error && <div className={styles.error}>There was an error when loading workspaces</div>}
		</Modal>
	);
};

export {CopyAnswerModal};
