import React, {FormEvent, ReactElement, useEffect, useState} from "react";
import {useCreateUserWithEmailAndPassword} from "react-firebase-hooks/auth";
import {useSignUpModal} from "../../../context/sign-up-modal.context";
import {auth} from "../../../utils/firebase";
import {SignUpModalActionTypes, SignUpModalStep} from "../../../reducer/sign-up-modal-reducer";
import {useToastContext} from "../../../context/toast-context";
import {StepTemplate} from "../../step-template";
import {Input} from "../../input";
import {Button} from "../../../shared/v2";

import styles from "./sign-up-step.module.scss";

export const SignUpStep = (): ReactElement => {
	const {
		dispatch,
		state: {email},
	} = useSignUpModal();

	const [
		createUserWithEmailAndPassword,
		user,
		loading,
		error,
	] = useCreateUserWithEmailAndPassword(auth);

	const {updateToast} = useToastContext();

	const [password, setPassword] = useState("");
	const [repeatPassword, setRepeatPassword] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [repeatPasswordError, setRepeatPasswordError] = useState("");

	const changeStep = (step: SignUpModalStep): void => {
		dispatch({
			type: SignUpModalActionTypes.SET_CURRENT_STEP,
			payload: {step},
		});
	};

	useEffect(() => {
		if (email === undefined) {
			changeStep(SignUpModalStep.Initial);
		}
	}, [email]);

	useEffect(() => {
		if (user) {
			changeStep(SignUpModalStep.VerifyEmail);
		}
	}, [user]);

	useEffect(() => {
		if (error) {
			if (error.code === "auth/weak-password") {
				setPasswordError("Password should be at least 6 characters");
				return;
			}
			setPasswordError(error.message);
		}
	}, [error]);

	const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();

		if (!password) {
			setPasswordError("Please enter a password");
			return;
		}

		if (!repeatPassword) {
			setRepeatPasswordError("Please enter a password");
			return;
		}

		if (password !== repeatPassword) {
			setPasswordError("Passwords do not match");
			setRepeatPasswordError("Passwords do not match");
			return;
		}

		createUserWithEmailAndPassword(email ?? "", password).then(result => {
			if (result) {

				updateToast({
					type: "success",
					description: "Please check your email and verify your account.",
				})
			}
		});
	};

	return (
		<StepTemplate
			header="Create account"
		>
			<form className={styles.form} onSubmit={handleSubmit}>
				<div className={styles.inputsSection}>
					<Input
						label="Email"
						value={email}
						disabled
					/>
					<Input
						label="Password"
						type="password"
						value={password}
						errorMessage={passwordError}
						onChange={e => {
							setPassword(e.target.value);
							setPasswordError("");
							setRepeatPasswordError("");
						}}
					/>
					<Input
						label="Confirm password"
						type="password"
						value={repeatPassword}
						errorMessage={repeatPasswordError}
						onChange={e => {
							setRepeatPassword(e.target.value);
							setPasswordError("");
							setRepeatPasswordError("");
						}}
					/>
				</div>
				<div className={styles.buttonsSection}>
					<Button
						id="create-account-next"
						disabled={loading || Boolean(passwordError) || Boolean(repeatPasswordError)}
						type="submit"
					>
						Next
					</Button>
				</div>
			</form>
		</StepTemplate>
	);
};
