import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import {InputProps} from "../input";
import {InputElements} from "../input-elements";

import styles from "../input/input.module.scss";

const cx = classNames.bind(styles);

export interface NumberInputProps extends Omit<InputProps<number>, "type" | "min" | "max"> {
	min?: number;
	max?: number;
}

export const NumberInput = ({
	value,
	onChange,
	className,
	size = "small",
	variant = "default",
	label,
	hintText,
	leftIcon,
	rightIcon,
	onBlur,
	onEnter,
	max,
	min,
	...props
}: NumberInputProps): ReactElement => {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		if (max && Number(e.target.value) > max) return onChange(max);
		if ((min || min === 0) && Number(e.target.value) < min) return onChange(min);
		onChange(Number(e.target.value));
	}

	const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
		if (e.key === "Enter") {
			e.preventDefault();
			onEnter?.(Number(value));
		}
	}

	const onFocus = (e: React.FocusEvent<HTMLInputElement>): void => e.currentTarget.select();

	return <InputElements
		variant={variant}
		className={className}
		label={label}
		hintText={hintText}
		leftIcon={leftIcon}
		rightIcon={rightIcon}
	>
		<input
			className={cx("input", size, variant, leftIcon && "withLeftIcon", rightIcon && "withRightIcon")}
			value={value}
			onChange={handleChange}
			onBlur={() => onBlur?.(value)}
			onFocus={onFocus}
			onKeyDown={onKeyDown}
			type="number"
			{...props}
		/>
	</InputElements>
}
