import React, {
	createContext,
	ReactElement,
	ReactNode,
	ReactPortal,
	RefObject,
	useContext,
	useState
} from "react";
import {createPortal} from "react-dom";

export interface Portal<T extends Element> {
  name: string;
  ref: RefObject<T>;
}

export interface PortalContextValue {
  register: <T extends Element>(name: string, ref: RefObject<T>) => void;
  unregister: (name: string) => void;
  render: (name: string, children: ReactNode) => ReactNode;
}

export const PortalContext =
  createContext<PortalContextValue | undefined>(undefined);

export const PortalContextProvider = (
	{children}: {children: ReactNode},
): ReactElement => {
	const [portals, setPortals] = useState<Portal<Element>[]>([]);

	const register = <T extends Element>(name: string, ref: RefObject<T>): void => {
		setPortals([...portals, {name, ref}]);
	};

	const unregister = (name: string): void => {
		setPortals(portals.filter((portal) => portal.name !== name));
	};

	const render = (name: string, children: ReactNode): ReactPortal => {
		const portal = portals.find((p) => p.name === name);

		if (!portal) {
			throw new Error(`Portal with name ${name} not found`);
		}

		if (!portal.ref.current) {
			throw new Error(`Ref for portal ${name} not found`);
		}

		return createPortal(children, portal.ref.current);
	}

	return (
		<PortalContext.Provider value={{
			register,
			unregister,
			render,
		}}>
			{children}
		</PortalContext.Provider>
	);
};

export const usePortalContext = (): PortalContextValue => {
	const context = useContext(PortalContext);

	if (context === undefined) {
		throw new Error(
			"usePortalContext must be used within a PortalContextProvider",
		);
	}

	return context;
};
