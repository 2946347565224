import React, {ReactElement, cloneElement} from "react";
import classNames from "classnames/bind";

import {useThemeMode} from "../../../context/theme-mode-context";
import {Body, Caption} from "../typography";

import styles from "./assistive-chip.module.scss";
import {CloseMediumIcon} from "../../../icons";

const cx = classNames.bind(styles);

export interface AssistiveChipProps {
  text: string | ReactElement;
  icon?: ReactElement;
  size?: "small" | "default";
  style?: "full" | "outline";
  color?: "default" | "brand";
  onClick?: (event?) => void;
  onRemove?: () => void;
  className?: string;
}

export const AssistiveChip = ({
	text,
	icon,
	style = "full",
	onClick,
	size = "default",
	color = "default",
	className,
	onRemove,
}: AssistiveChipProps) => {
	const {isDarkMode} = useThemeMode();

	const renderText = (): ReactElement => {
		if (typeof text === "string") {
			if (size === "small") {
				return <Caption className={styles.assistiveChipText}>
					{text}
				</Caption>
			}

			return <Body className={styles.assistiveChipText} size="s" type="medium">
				{text}
			</Body>
		}

		return text;
	}

	return (
		<div
			className={
				cx(
					"assistiveChip",
					size,
					style,
					color,
					className,
					{isDarkMode, withIcon: icon, withRemove: Boolean(onRemove)}
				)
			}
			onClick={onClick}
		>
			{icon && cloneElement(icon, {className: cx("assistiveChipIcon")})}

			{renderText()}

			{onRemove && (
				<button className={styles.removeButton} onClick={onRemove}>
					<CloseMediumIcon />
				</button>
			)}
		</div>
	);
};
