import React, {ChangeEvent, HTMLAttributes, ReactElement, cloneElement} from "react";
import classNames from "classnames/bind";

import {CheckmarkIcon} from "../../../icons";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./checkbox.module.scss";

const cx = classNames.bind(styles);

export interface CheckboxProps extends HTMLAttributes<HTMLInputElement> {
	checked: boolean;
	className?: string;
	icon?: ReactElement;
	// TODO: remove this prop when we no longer need to support the old way of Checkbox component
	value?: string | number;
	id?: string;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
	size?: "s" | "m";
	text?: ReactElement | string;
	style?: React.CSSProperties | undefined;
	disabled?: boolean;
}

export const Checkbox = ({
	id = Math.random().toString(36).substring(7),
	size = "m",
	text,
	icon = <CheckmarkIcon />,
	style,
	className,
	disabled,
	...props
}: CheckboxProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	return (
		<div
			style={style}
			className={cx("checkboxWrapper", className)}
		>
			<div className={cx("checkbox", `size-${size}`, {isDarkMode})}>
				<input
					type="checkbox"
					id={id}
					className={styles.input}
					disabled={disabled}
					{...props}
				/>

				{icon && cloneElement(icon, {className: styles.icon})}
			</div>

			{text && (
				<label onClick={(e) => e.stopPropagation()} htmlFor={id} className={cx("label", {disabled})}>
					{text}
				</label>
			)}
		</div>
	);
}
