import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";

export const useSearchParamState = <T>(key: string, initialValue: T) => {
	const [params, setSearchParam] = useSearchParams();
	const [value, setValue] = useState<T>(initialValue);

	useEffect(() => {
		const param = params.get(key);
		if (param) {
			setValue(JSON.parse(param));
		}
	}, [params, key]);

	const setParam = (newValue: T) => {
		const isEmpty = !newValue || (Array.isArray(newValue) && newValue.length === 0);
		setValue(newValue);
		if (isEmpty) {
			params.delete(key);
		} else {
			params.set(key, JSON.stringify(newValue));
		}
		setSearchParam(params);
	};

	return [value, setParam] as const;
}
