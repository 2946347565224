import {CreatedReel, AddReelVars} from "../../models/reels";
import React, {ReactElement, useContext, useEffect, useState} from "react";
import {ADD_REEL} from "../../graphql/mutations/reel-mutations";
import {CREATE_CLIPS} from "../../graphql/mutations/clip-mutations";
import {REEL_FRAGMENT} from "../../graphql/fragments/fragments";
import {ToastContext} from "../../context/toast-context";
import styles from "./add-reel.module.scss";
import {updateCacheAddPageItem} from "../../shared/utility/update-cache";
import {useMutation} from "@apollo/client";
import {useSelectContext} from "../../context/select-context";
import {Button, Input, TextArea, BaseModalProps, Modal} from "../../shared/v2";

export interface AddReelModalProps extends BaseModalProps {
	workspaceId: string;
	handleSave?: (name: string, description?: string) => void;
	defaultName?: string;
	defaultDesc?: string;
	isCreating?: boolean;
}

const AddReelModal = (props: AddReelModalProps): ReactElement => {
	const {
		isOpen,
		workspaceId,
		onClose,
		handleSave: customSave,
		defaultName = "",
		defaultDesc = "",
		isCreating = false,
	} = props;
	const [name, setName] = useState(defaultName);
	const [description, setDescription] = useState(defaultDesc);
	const {updateToast} = useContext(ToastContext);
	const {selected, emptySelectValues} = useSelectContext();

	useEffect(() => {
		if (isOpen) {
			setName(defaultName);
			setDescription(defaultDesc);
		}
	}, [isOpen]);

	const [createClips] = useMutation(CREATE_CLIPS, {
		onCompleted: () => {
			onClose();
			emptySelectValues();
			updateToast({description: "Clips added to new reel", type: "informational"});
		},
	});
	const [addReel] = useMutation<CreatedReel, AddReelVars>(ADD_REEL, {
		onCompleted: data => {
			updateToast({description: "Reel created", type: "informational"});
			if (selected.length > 0) {
				const vals = selected.map(v => {
					if (v.includes("_")) { // ANSID_USERID if true
						const [x] = v.split("_");
						return {answerId: x};
					}
					return {answerId: v};
				});
				createClips({
					variables: {
						reelId: data.createReel.id,
						input: vals,
					},
				});
				return;
			}
			onClose();
		},
	});

	/**
	 * Calls our handleCreate callback and closes the modal.
	 */
	const handleSave = (): void => {
		if (customSave) {
			customSave(name.trim(), description?.trim());
			return;
		}
		addReel({
			variables: {input: {name: name.trim(), description: description?.trim(), workspaceId}},
			update(cache, {data: reel}) {
				if (!reel) return;
				const newReelRef = cache.writeFragment({
					data: reel.createReel,
					fragment: REEL_FRAGMENT,
				});
				updateCacheAddPageItem(cache, newReelRef, "reels", reel.createReel.id);
			},
		});
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size="small"
			title="Add Reel"
		>
			<div className={styles.inputs}>
				<Input
					id="reel-name-input"
					onChange={setName}
					value={name}
					label="Name"
					maxLength={255}
				/>
				<TextArea
					id="reel-description-input"
					onChange={setDescription}
					value={description || ""}
					label="Description (optional)"
				/>
			</div>
			<div className={styles.actions}>
				<Button
					onClick={handleSave}
					disabled={isCreating || !name?.trim()}
				>
					{isCreating ? "Creating Reel..." : "Save"}
				</Button>
			</div>
		</Modal>
	);
};

export {AddReelModal};
