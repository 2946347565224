import React, {ReactElement} from "react";

import {HandOutputStarIcon} from "../../../icons";
import {EmptyState} from "../../../shared/v2";

export const OutputsPage = (): ReactElement => {
	return <EmptyState
		title="Outputs"
		description="Outputs combine all of your custom data, agents, and flows into a shareable (and talkable) deliverable."
		icon={<HandOutputStarIcon />}
	/>
}
