import React, {ReactElement} from "react";
import {Icon} from "../icon";
import styles from "./image-modal.module.scss";

export interface ImageModalProps {
	/**
	 * The image we want to display
	 */
	image?: string;
	/**
	 * If the modal should be showing or not
	 */
	isShowing: boolean;
	isVideo: boolean;
	/**
	 * Handle closing the modal
	 */
	handleClose: () => void;
}

const ImageModal = ({image, isShowing, isVideo, handleClose}: ImageModalProps): ReactElement =>
	<div
		style={{display: isShowing ? "flex" : "none"}}
		className={styles.container}
		onClick={handleClose}
	>
		<Icon name="cross" className={styles.cross}/>
		{isVideo ? <div className={styles.video}>
			<video
				src={image}
				controls
				controlsList="nodownload"
				playsInline
				onContextMenu={(event => event.preventDefault())}
			/>
		</div> : <img src={image} style={{maxWidth: "80%", maxHeight: "80%"}}/>}
	</div>;

export {ImageModal};
