import {useEffect} from "react";
import {useToastContext} from "../context/toast-context";
import ky from "ky";

export interface UseSafeFetchFileProps {
  url?: string;
  setFile: (file: File | null) => void;
  maxFetchAttempts?: number;
}

export const useSafeFetchFile = ({url, setFile, maxFetchAttempts = 5}: UseSafeFetchFileProps) => {
	const {updateToast} = useToastContext();

	const fetchFile = async (url: string | undefined, fetchAttempt = 0) => {
		if (!url) {
			return setFile(null);
		}
		try {
			const blob = await ky.get(url).blob();

			if (blob.type !== "application/xml") {
				return setFile(new File([blob], "picture.png"));
			}
		} catch {
			if (fetchAttempt >= maxFetchAttempts) {
				updateToast({
					type: "failure",
					description: "Failed to fetch file",
				});
				return;
			}

			setTimeout(() => {
				fetchFile(url, fetchAttempt + 1);
			}, 1000);
		}
	}
	// eslint-disable-next-line @typescript-eslint/no-unused-expressions
	useEffect(() => {url && fetchFile(url)}, [url]);

	return {
		refetch: () => fetchFile(url),
	}
}
