import {gql} from "@apollo/client";
import {GROUNDING_FRAGMENT} from "../fragments/chat";

export const GET_AI_MODELS = gql`
  query GetAiModels($workspaceId: GUID!) {
    workspaceAiModels(workspaceId: $workspaceId) {
      aiModel {
        id
        name
        description
        provider
        status
        task
        modality
        useCase
        modelCategory {
          name
          description
          id
        }
      }
    }
  }
`;

export const GET_AI_MODEL_CATEGORIES = gql`
  query GetAiModelCategories {
    aiModelCategories {
      id
      name
      description
    }
  }
`;

export const GET_TRAINING_SETS = gql`
  query GetTrainingSets($workspaceId: GUID!) {
    trainingSets(workspaceId: $workspaceId) {
      name
      alias
      description
      id
      files {
        id
        originalFilename
        url
      }
      videos {
        id
        originalFilename
        url(version: H264_AAC)
      }
    }
  }
`;

export const GET_TRAINING_SET = gql`
  query GetTrainingSet($trainingSetId: GUID!) {
    trainingSet(id: $trainingSetId) {
      name
      alias
      id
      description
      files {
        id
        originalFilename
        url
        originalMimetype
        hasEmbeddings
      }
      videos {
        id
        originalFilename
        url(version: H264_AAC)
        originalMimetype
        transcodingStatus
        hasEmbeddings
      }
    }
  }
`;

export const GET_CHAT_CONVERSATIONS = gql`
  query GetChatConversations(
    $limit: Int
    $workspaceId: GUID!
    $filter: ChatConversationFilterInput
  ) {
    chatConversations(limit: $limit, workspaceId: $workspaceId, filter: $filter) {
      id
      name
      workspaceId
      createdAt
      isPreview
    }
  }
`;

export const GET_CHAT_CONVERSATIONS_WITH_PREVIEW = gql`
  query GetAllChatConversations(
    $workspaceId: GUID!
    $filter: ChatConversationFilterInput
  ) {
    chatConversations(workspaceId: $workspaceId, filter: $filter) {
      id
      name
      createdAt
      isPreview
      preview {
        images
        creator {
          firstName
          lastName
        }
        content
        createdAt
      }
    }
  }
`;

export const GET_CHAT_CONVERSATION = gql`
  query GetChatConversation($chatConversationId: GUID!) {
    chatConversation(id: $chatConversationId) {
      id
      name
      workspaceId
      isPreview
      surveys {
        id
        name
      }
      messages {
        id
        content
        conversationId
        persona {
          id
          name
          voiceId
          voiceName
          description
          personaCategory {
            id
            name
          }
          personaStatus
          picture {
            id
            url(version: THUMBNAIL)
          }
        }
        role
        type
        position
        likedByUsers {
          id
        }
        dislikedByUsers {
          id
        }
        groundingData {
          ...GroundingFields
        }
      }
      trainingSets {
        id
        name
        alias
      }
      aiPersona {
        id
        name
        voiceId
        voiceName
        description
        personaCategory {
          name
        }
        picture {
          id
          url(version: THUMBNAIL)
        }
      }
    }
  }
  ${GROUNDING_FRAGMENT}
`;

export const GET_PERSONAS = gql`
  query AiPersonasForWorkspace(
    $workspaceId: GUID!
    $version: PersonaPictureVersion!
    $personaStatus: PersonaStatus
  ) {
    aiPersonasForWorkspace(workspaceId: $workspaceId, personaStatus: $personaStatus) {
      id
      name
      description
      instructions
      personaStatus

      personaCategory {
        id
        name
      }
      picture {
        id
        url(version: $version)
      }
      personaType {
        id
        name
      }
      voiceId
      voiceName
      isVurvey
      parsedInstructions @client
      createdAt
      skills {
        id
        name
      }
    }
  }
`;
export const AGENTS_PUBLISHED = gql`
  query AgentsPublished($workspaceId: GUID!) {
    publishedAgents: aiPersonasForWorkspace(
      workspaceId: $workspaceId
      personaStatus: PUBLISHED
    ) {
      id
      name
      description
      picture {
        id
        url(version: THUMBNAIL)
      }
      isVurvey
      personaStatus
      personaType {
        id
        name
      }
    }
  }
`;

export const AGENTS_PUBLISHED_WITH_TASKS = gql`
  query AiPersonasWithOrchTasks(
    $workspaceId: GUID!
    $version: PersonaPictureVersion!
    $orchestrationId: GUID
  ) {
    aiPersonasForWorkspace(workspaceId: $workspaceId, personaStatus: PUBLISHED) {
      id
      name
      description
      voiceId
      voiceName
      personaCategory {
        id
        name
      }
      picture {
        id
        url(version: $version)
      }
      tasks(orchestrationId: $orchestrationId) {
        id
        name
        instructions
        parsedInstructions @client
        index
        tools {
          id
          name
          code
        }
      }
      isVurvey
      personaStatus
      personaType {
        id
        name
      }
    }
  }
`;

// TODO: add personaType to the query
export const AI_PERSONA_BY_ID = gql`
  query AiPersonaById(
    $personaId: GUID!
    $workspaceId: GUID!
    $version: PersonaPictureVersion!
  ) {
    aiPersonaById(personaId: $personaId, workspaceId: $workspaceId) {
      id
      name
      description
      instructions
      voiceId
      voiceName
      personaType {
        id
        name
      }
      personaCategory {
        id
        name
      }
      picture {
        id
        url(version: $version)
      }
      isVurvey
      personaStatus
      parsedInstructions @client
      skills {
        id
      }
    }
  }
`;

export const GET_AI_TOOLS = gql`
  query GetAiTools($limit: PositiveInt, $cursor: String) {
    aiTools(limit: $limit, cursor: $cursor) {
      items {
        id
        name
        code
      }
    }
  }
`;

export const GET_AI_PERSONA_CATEGORIES = gql`
  query AiPersonaCategories {
    aiPersonaCategories {
      id
      name
    }
  }
`;

export const GET_CAMPAIGNS = gql`
  query GetActiveCampaigns($workspaceId: GUID!) {
    campaigns: workspaceSurveys(workspaceId: $workspaceId, filter: {status: [CLOSED, OPEN], withResponsesOnly: true}) {
      items {
        id
        name
        questionCount
        completedResponseCount
        responseCount
        totalVideoDuration
      }
    }
  }
`;

export const AI_PERSONA_TYPES = gql`
  query AiPersonaTypes {
    aiPersonaTypes {
      id
      name
      description
      outputType {
        id
        code
      }
    }
  }
`;

export const PROMPT_TEMPLATES = gql`
  query PromptTemplates {
    promptTemplates {
      id
      name
      description
      template
      type
      workspaceId
    }
  }
`

export const SYSTEM_AGENTS = gql`
  query SystemAgents {
    systemAgents {
      id
      name
      code
      description
    }
  }
`;

export const AI_SKILLS = gql`
  query AiSkills {
    aiSkills {
      id
      name
      description
    }
  }
`;
