import {
	CREATE_INTRO_IMAGE,
	CREATE_SURVEY_VIDEO,
	UPDATE_SURVEY,
} from "../../graphql/mutations/survey-mutations";
import {GET_INTRO_IMAGE, GET_QUESTION_VIDEO_STATUS} from "../../graphql/queries/survey-queries";
import {ImageInput} from "../../shared";
import React, {ReactElement, useEffect, useState} from "react";
import {useMediaUpload} from "../../hooks/useMediaUpload";
import {useMutation} from "@apollo/client";
import {useWaitForUpload} from "../../hooks/useWaitForUpload";
import {BaseModalProps, Modal} from "../../shared/v2";

export interface IntroImageUploadModalProps extends BaseModalProps {
	/**
	 * Survey ID
	 */
	surveyId: string;
}

const IntroImageUploadModal = (props: IntroImageUploadModalProps): ReactElement => {
	const {isOpen, onClose, surveyId} = props;
	const [isProcessing, setIsProcessing] = useState(false);
	const [createImage] = useMutation(CREATE_INTRO_IMAGE);
	const [createVideo] = useMutation(CREATE_SURVEY_VIDEO);

	const {
		media,
		hasError,
		handleChange,
		handleSave,
		mediaId,
	} = useMediaUpload();
	const [updateSurvey] = useMutation(UPDATE_SURVEY, {
		onCompleted: () => onClose(),
		onError: () => setIsProcessing(false),
	});

	const status = useWaitForUpload(
		mediaId,
		media.type === "image" ? GET_INTRO_IMAGE : GET_QUESTION_VIDEO_STATUS,
	);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const save = (): Promise<any> => {
		setIsProcessing(true);
		return handleSave(media.type === "image" ? createImage : createVideo);
	};

	useEffect(() => {
		if (mediaId && status === "COMPLETED") {
			if (media.type === "image") {
				updateSurvey({
					variables: {id: surveyId, changes: {introImageId: mediaId}},
				});
			}	else {
				updateSurvey({
					variables: {id: surveyId, changes: {introVideoId: mediaId}},
				});
			}
		}
	}, [status, mediaId]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size="small"
			title="Add Image"
		>
			<ImageInput
				id="upload-image"
				onChange={handleChange}
				media={media}
				type="question"
				isProcessing={isProcessing}
				hasError={hasError}
				save={save}
			/>
		</Modal>
	);
};

export {IntroImageUploadModal};
