import React, {ReactElement, useEffect, useRef} from "react";
import {Icon, Portal} from "../../shared";
import styles from "./cut-dropdown.module.scss";

export interface CutDropdownProps {
	range?: Range;
	rect?: DOMRect;
	closeDropdown: () => void;
	updateClip: (start: number, end: number) => void;
}

const CutDropdown = ({range, rect, closeDropdown, updateClip}: CutDropdownProps): ReactElement => {
	const ref = useRef<HTMLDivElement>(null);

	const handleClip = (): void => {
		if (!range) return;
		const firstEl = range.startContainer.parentElement;
		const endEl = range.endContainer.parentElement;
		if (firstEl && endEl) {
			const start = parseInt(firstEl.getAttribute("data-start") as string);
			const end = parseInt(endEl.getAttribute("data-end") as string);
			updateClip(start, end);
			closeDropdown();
		}
	};

	const handleMousedown = ({target}): void => {
		if (ref.current?.contains(target)) return;
		closeDropdown();
	};
	useEffect(() => {
		if (ref.current) {
			document.addEventListener("mousedown", handleMousedown);
		}
		return () => document.removeEventListener("mousedown", handleMousedown);
	}, [ref.current]);
	return (
		<Portal id="cut-dropdown">
			<div
				className={styles.dropdown}
				ref={ref}
				style={{
					top: rect && rect.top - 16,
					left: (rect && (rect.left + rect.right) / 2),
				}}
			>
				<div className={styles.action} onClick={handleClip}>
					<Icon name="scissors" size="extrasmall" fill="white"/>
					<span>Clip</span>
				</div>
			</div>
		</Portal>
	);
};

export {CutDropdown};
