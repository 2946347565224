import React, {ReactElement} from "react";

import {SurveyTemplate} from "../../../models/survey";
import {Icon} from "../../../shared";
import {currencySymbol} from "../../../shared/utility/utility";
import {Body, Subheader} from "../../../shared/v2/typography";
import {Button} from "../../../shared/v2";

import styles from "./survey-template-card.module.scss";

export interface SurveyTemplateCardProps {
	template: SurveyTemplate;
	onClick: (templateId: string) => void;
}

export const SurveyTemplateCard = ({template, onClick}: SurveyTemplateCardProps): ReactElement =>
	<div
		id={template.id}
		className={styles.templateCard}
	>
		<div>
			<Subheader type="semibold"  className={styles.title}>{template.templateHeadline}</Subheader>
			{template.templateDescription &&
        <Body size="s" color="text-secondary">{template.templateDescription}</Body>
			}
		</div>
		<div className={styles.bottom}>
			<div className={styles.stats}>
				<div className={styles.statLabel}>
					<Icon name="help" fill="var(--color-text-secondary)" size="extrasmall"/>
					<Body size="xs">{template.questionCount} questions</Body>
				</div>
				{ template.incentiveAmount &&
					<div className={styles.statLabel}>
						<Icon name="star" fill="var(--color-text-secondary)" size="extrasmall" />
						<Body size="xs">
							{currencySymbol(template.incentiveCurrency)} {template.incentiveAmount} reward
						</Body>
					</div>
				}
				{ template.estimatedCompletionTime &&
					<div className={styles.statLabel}>
						<Icon name="clock" fill="var(--color-text-secondary)" size="extrasmall"/>
						<Body size="xs">{template.estimatedCompletionTime} minutes</Body>
					</div>
				}
			</div>
			<Button
				onClick={() => onClick(template.id)}
			>
				Use Template
			</Button>
		</div>
	</div>;

