import {markResult, PatternProps, ReplacerFunc, splitTaggedResultIntoChunks} from ".";

export interface CommandPatternProps extends PatternProps {
  commands: string[];
  selectionStart: number;
}

const extractCommand = (text: string, selectionStart: number): [number, number] | false => {
	for(let i = selectionStart - 1; i >= 0; i--) {
		if (text[i] === "/") {
			return [i, selectionStart];
		}
	}

	return false;
}

export const commandPatternReplacer: ReplacerFunc<CommandPatternProps> = (props) => {
	const {text, render, commands, selectionStart} = props;

	const commandPosition = extractCommand(text, selectionStart);

	if (!commandPosition || commandPosition[0] !== 0) {
		return [text];
	}

	const [start, end] = commandPosition;

	if (end < text.length && !/\s/.test(text[end])) {
		return [text];
	}

	const commandText = text.substring(start + 1, end);
	const isCommandValid = commands.some(command => command.startsWith(commandText));

	if (!isCommandValid) {
		return [text];
	}

	const result = text.substring(0, start) + markResult(`/${commandText}`) + text.substring(end);

	return splitTaggedResultIntoChunks(result, render);
}
