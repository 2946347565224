import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {useThemeMode} from "../../../../../../context/theme-mode-context";

import styles from "./bar-scale.module.scss";

const cx = classNames.bind(styles);

export interface BarScaleProps {
  score: number;
}

const countBars = (score: number): number => {
	if (score < 0.5) {
		return 1;
	}

	if (score < 1) {
		return 2;
	}

	return 3;
}

export const BarScale = ({score}: BarScaleProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	return (
		<div className={cx("barScale", {isDarkMode})}>
			{Array.from({length: countBars(score)}).map((_, index) => (
				<div key={index} className={styles.bar} />
			))}
		</div>
	);
}
