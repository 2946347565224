import React, {ReactElement, useCallback, useState} from "react";
import {useMemoObject} from "../hooks";

import {DirtyReelModal} from "../modals/dirty-reel-modal";
import {Reel, ReelVideoStatus} from "../models/reels";

interface DirtyReelContext {
	dirtyReel: Reel | undefined;
	setDirtyReel: (val: Reel | undefined) => void;
}

const DirtyReelContext = React.createContext<DirtyReelContext>({
	dirtyReel: undefined,
	setDirtyReel: () => undefined,
});

const DirtyReelContextProvider = (props: {children: React.ReactNode}): ReactElement => {
	const [dirtyReel, baseSetDirtyReel] = useState<Reel | undefined>(undefined);

	const setDirtyReel = useCallback((newReel: Reel | undefined): void => {
		if (newReel === undefined) {
			baseSetDirtyReel(undefined);
		} else if (newReel?.videoStatus === ReelVideoStatus.DIRTY) {
			baseSetDirtyReel(newReel);
		}
	}, []);

	const value = useMemoObject({dirtyReel, setDirtyReel});

	return <DirtyReelContext.Provider value={value}>
		{props.children}
		<DirtyReelModal
			isOpen={Boolean(dirtyReel)}
			reel={dirtyReel}
			onClose={() => setDirtyReel(undefined)}
		/>
	</DirtyReelContext.Provider>;
};

export {DirtyReelContext, DirtyReelContextProvider};
