export {updateObject} from "./utility";
export {updateHighlight} from "./mutation-update";
export {checkIfExists} from "./check-if-exists";
export {checkFileType} from "./check-filetype";
export type {FileType} from "./check-filetype";

export const roundify = (val: number): string => {
	let affix = "";
	if (val > 1000000) {
		affix = "MM";
		val = Math.round(val / 100000);
	} else if (val > 1000) {
		affix = "k";
		val = Math.round(val / 100);
	} else {
		val *= 10;
	}
	return `${val % 10 ? (val / 10).toFixed(1) : val / 10}${affix}`;
};

export const displayTime = (ms: number): string => {
	let unit = "min";
	ms /= 6000;
	if (ms > 1000) {
		unit = "hr";
		ms /= 60;
	}
	return `${ms % 10 ? (ms / 10).toFixed(1) : ms / 10} ${unit}`;
};
