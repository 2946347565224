import React, {createContext, useContext, useState, ReactNode, useEffect, useMemo} from "react";
import {useQuery, useMutation} from "@apollo/client";
import {useParams} from "react-router";

import {AiOrchestration, AiOrchestrationData, AiPersonaTask} from "../../models/ai-orchestration";
import {GET_AI_ORCHESTRATION} from "../../graphql/queries/ai-orchestration-queries";
import {CREATE_PERSONA_TASK, DELETE_PERSONA_TASK, RUN_WORKFLOW, UPDATE_PERSONA_TASK} from "../../graphql/mutations/ai-mutations";
import {Survey} from "../../models/survey";
import {TrainingSet} from "../../models";
import {useToastContext} from "../toast-context";

interface WorkflowContextProps {
  workflow: AiOrchestration | undefined;
  agentTasks: AiPersonaTask[];
  activeAgentTasks: AiPersonaTask[];
  isWorkflowDirty: boolean;
  isSaving: boolean;
  setAgentTasks: React.Dispatch<React.SetStateAction<AiPersonaTask[]>>;
  handleSaveWorkflow: () => Promise<void>;
  handleRunWorkflow: () => Promise<void>;
  currentSources: (Survey | TrainingSet)[];
  isLoadingWorkflow: boolean;
  isWorkflowRunning: boolean;
}

const WorkflowContext = createContext<WorkflowContextProps | undefined>(undefined);

export const WorkflowProvider = ({children}: { children: ReactNode }) => {
	const {updateToast} = useToastContext();
	const {workflowId} = useParams();
	const [agentTasks, setAgentTasks] = useState<AiPersonaTask[]>([]);
	const [isSaving, setIsSaving] = useState(false);

	const {data: workflowData, loading: isLoadingWorkflow, refetch} = useQuery<AiOrchestrationData>(
		GET_AI_ORCHESTRATION,
		{
			variables: {id: workflowId},
			skip: !workflowId,
			fetchPolicy: "cache-and-network",
		}
	);

	const [createTask] = useMutation(CREATE_PERSONA_TASK);
	const [updateTask] = useMutation(UPDATE_PERSONA_TASK);
	const [deleteTask] = useMutation(DELETE_PERSONA_TASK);
	const [runWorkflow] = useMutation(RUN_WORKFLOW);

	const workflow = useMemo(() => workflowData?.aiOrchestration, [workflowData]);

	useEffect(() => {
		if (workflow?.aiPersonaTasks) {
			const newAgents = workflow.aiPersonaTasks.map((task) => {
				return {...task};
			});
			setAgentTasks(newAgents.sort((a, b) => (a.index || 0) - (b.index || 0)));
		}
	}, [workflow?.aiPersonaTasks]);

	const activeAgentTasks = agentTasks.filter(a => a.operation !== "DELETE");

	const isWorkflowDirty = useMemo(() => {
		return agentTasks.some(a => a.operation === "CREATE" || a.operation === "UPDATE" || a.operation === "DELETE");
	}, [agentTasks]);

	const isWorkflowRunning = useMemo(() => {
		return agentTasks.some(a => a.processingState === "processing");
	}, [agentTasks]);


	const handleSaveWorkflow = async () => {
		if (!isWorkflowDirty) {
			return;
		}
		setIsSaving(true);
		const agentsToRemove = agentTasks.filter(a => a.operation === "DELETE");

		const removingPromises = agentsToRemove.map((agent) => {
			return deleteTask({variables: {id: agent.id}});
		});

		const createUpdatePromises = activeAgentTasks.map((agent, index) => {
			if (agent.id.startsWith("NEW")) {
				return createTask({
					variables: {
						input: {
							index: index,
							personaId: agent.persona?.id || "",
							orchestrationId: workflowId,
							task: agent.task,
						},
					},
				});
			} else {
				return updateTask({
					variables: {
						input: {
							id: agent?.id || "",
							index: agent.index,
							task: {
								taskPrompt: agent.task?.taskPrompt || "",
							},
						},
					},
				});
			}
		});

		await Promise.all([...removingPromises, ...createUpdatePromises]);

		await refetch();
		setIsSaving(false);
		updateToast({
			type: "success",
			description: "Workflow saved successfully",
		})
	};

	const handleRunWorkflow = async () => {
		await runWorkflow({variables: {orchestrationId: workflowId}});
	};

	const currentSources = useMemo(() => {
		const surveys = workflow?.surveys || [];
		const trainingSets = workflow?.trainingSets || [];

		return [...surveys, ...trainingSets];
	}, [workflow?.trainingSets, workflow?.surveys]);

	return (
		<WorkflowContext.Provider
			value={{
				workflow,
				agentTasks,
				isWorkflowDirty,
				isSaving,
				setAgentTasks,
				handleSaveWorkflow,
				handleRunWorkflow,
				currentSources,
				isLoadingWorkflow,
				activeAgentTasks,
				isWorkflowRunning,
			}}
		>
			{children}
		</WorkflowContext.Provider>
	);
};


export const useWorkflowContext = () => {
	const context = useContext(WorkflowContext);
	if (!context) {
		throw new Error("useWorkflowContext must be used within a WorkflowProvider");
	}
	return context;
};
