import {RadioGroup as Base} from '@headlessui/react';
import classNames from 'classnames/bind';
import React, {Children, cloneElement, isValidElement, ReactElement, ReactNode} from 'react';

import {Body} from '../typography';
import {RadioOption, RadioOptionSize} from './radio-option';

import styles from "./radio-group.module.scss";

const cx = classNames.bind(styles);

export interface RadioGroupProps<T> {
  label?: string;
  className?: string;
  value?: T;
  size?: RadioOptionSize;
  children?: ReactNode;
  onChange: (value: T) => void;
}

function RadioGroup<T>(
	{
		children,
		className,
		label,
		onChange,
		size = "m",
		value,
	}: RadioGroupProps<T>
): ReactElement {
	return (
		<Base className={cx("radioGroup", className)} value={value} onChange={onChange}>
			{label && (
				<Base.Label className={styles.label}>
					<Body
						color="text-secondary"
						size="s"
						type="medium"
					>
						{label}
					</Body>
				</Base.Label>
			)}

			{Children.map(children, (child) => {
				if (!isValidElement(child)) {
					return null;
				}

				return (
					cloneElement(
            child as ReactElement,
            {size: child.props.size || size}
					)
				)
			})}
		</Base>
	)
}

RadioGroup.Option = RadioOption;

export {RadioGroup};
