import React, {useState} from 'react';
import classNames from 'classnames/bind';

import {Body} from '../../../../shared/v2/typography';
import {ChatConversation} from '../../../../models';
import {NavLink} from '../../../../route';
import {useThemeMode} from '../../../../context/theme-mode-context';
import {Dropdown} from '../../../../shared/v2';
import {BinDeleteIcon, DotsIcon, PenEditIcon} from '../../../../icons';

import styles from './chat-session-container.module.scss';
import {EditChatModal} from '../../../../workflow/components/edit-chat-modal';
import {ConfirmActionModal} from '../../../../shared/v2/modals/confirm-action-modal';
import {useMutation} from '@apollo/client';
import {CHAT_DELETE_CONVERSATION} from '../../../../graphql/mutations/ai-mutations';
import {GET_CHAT_CONVERSATIONS, GET_CHAT_CONVERSATIONS_WITH_PREVIEW} from '../../../../graphql/queries/ai-models-queries';
import {useToastContext} from '../../../../context/toast-context';
import {useLocation, useNavigate} from 'react-router';

const cx = classNames.bind(styles);

export interface ChatSessionContainerProps {
  chatConversation: ChatConversation
}

export const ChatSessionContainer = ({chatConversation}: ChatSessionContainerProps) => {
	const location = useLocation();
	const navigate = useNavigate();
	const {isDarkMode} = useThemeMode();
	const {updateToast} = useToastContext();
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const [deleteConversation] = useMutation(CHAT_DELETE_CONVERSATION, {refetchQueries: [GET_CHAT_CONVERSATIONS_WITH_PREVIEW, GET_CHAT_CONVERSATIONS]})

	const handleRenameClick = (event?: MouseEvent):void => {
		event?.preventDefault();
		setIsEditModalOpen(true);
	}

	const handleDeleteClick = (event?: MouseEvent):void => {
		event?.preventDefault();
		setIsDeleteModalOpen(true);
	}

	const handleDelete = () => {
		deleteConversation({
			variables: {
				chatDeleteConversationId: chatConversation.id
			},
			onCompleted: () => {
				if (location.search.includes(chatConversation.id)) {
					navigate("/");
				}

				updateToast({description: "Conversation deleted", type: "informational"})
			}
		})
		setIsDeleteModalOpen(false)
	}

	return (
		<>
			<NavLink
				to={{
					pathname: "/workflow/conversation",
					search: {canvasId: chatConversation.id},
				}}
				className={cx('chatSessionContainer', {isDarkMode})}
			>
				<Body size="s" className={styles.text}>
					{chatConversation.name || "Untitled chat"}
				</Body>
				<div className={styles.dropdownWrapper}>
					<div className={cx("gradient", {isDarkMode})}></div>
					<Dropdown
						trigger={<DotsIcon />}
						space={4}
						triggerClassName={styles.dropdownTrigger}
						items={[
							{
								label: "Rename",
								icon: <PenEditIcon />,
								onClick: handleRenameClick,
							},
							{
								label: "Delete",
								icon: <BinDeleteIcon />,
								color: "danger",
								onClick: handleDeleteClick,
							}

						]}
						position="bottom-start"
					/>

				</div>
			</NavLink>

			<EditChatModal
				isOpen={isEditModalOpen}
				onClose={() => setIsEditModalOpen(false)}
				conversation={chatConversation}
			/>

			<ConfirmActionModal
				isOpen={isDeleteModalOpen}
				onClose={() => setIsDeleteModalOpen(false)}
				onConfirm={handleDelete}
				title="Delete conversation"
				description="Are you sure you want to delete this conversation? This action cannot be undone."
				confirmText="Delete"
			/>
		</>
	);
}
