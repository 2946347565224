
import React, {ReactElement} from "react";
import {CustomPropsFilter, WorkspaceMembersFilter} from "../../../models/filter";

import styles from "./filter-display.module.scss";
import {Attribute, AttributeWithExtant} from "../../../models/attribute";
import {Icon} from "../icon";
import {useQuery} from "@apollo/client";
import {GET_FILTER_DATA} from "../../../graphql/queries/queries";
import {FilterDemoData} from "../../../survey/components/filter-panel";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {formatDateFromString} from "../../../shared/utility/utility";

export interface FilterDisplayProps {
	filter: WorkspaceMembersFilter;
	customProperties?: CustomPropsFilter[];
	attributes?: Attribute[] | AttributeWithExtant[];
	handleClearFilters: () => void;
	handleRemoveFilter: (clearKey, clearValue?) => void;
	handleRemoveCustomProp: (id, clearValue?) => void;
}

/**
 * Looks at the current search query and displays
 * the filters of the page. (Will ignore workspaceId when implemented)
 */
const FilterDisplay = (props: FilterDisplayProps): ReactElement | null => {
	const {workspace: {id: workspaceId}} =useWorkspaceContext();
	const {
		filter,
		attributes,
		handleClearFilters,
		handleRemoveFilter,
		handleRemoveCustomProp,
	} = props;

	const {
		ageRange,
		countryId,
		stateId,
		gender,
		customProperties,
		hasTakenSurvey, // Comes from the contacts filter.
		ethnicityId,
		firstSurveyResponseAt,
		lastSurveyResponseAt,
		firstFeedbackSurveyResponseAt,
		lastFeedbackSurveyResponseAt,
	} = filter;
	const {min, max} = ageRange ?? {};
	const {surveyIds, haveNot, any} = hasTakenSurvey ?? {};

	const {data} = useQuery<FilterDemoData>(GET_FILTER_DATA, {
		variables: {workspaceId},
		fetchPolicy: "cache-first",
	});

	if (!(ageRange || countryId || gender || customProperties || stateId || ethnicityId || surveyIds
		|| firstSurveyResponseAt || firstFeedbackSurveyResponseAt || lastFeedbackSurveyResponseAt || lastSurveyResponseAt)) return null;

	return <ul className={styles.headerFilters}>
		{gender?.map((genderItem, index) => (
			<li key={`${index}-${genderItem}`} className={styles.filterItem}>
				<b>Gender:</b>&nbsp;{genderItem.charAt(0) + genderItem.slice(1).toLocaleLowerCase()}
				<Icon
					clicked={() => handleRemoveFilter("gender", genderItem)}
					name="cross"
					size="tiny"
					isClickable
				/>
			</li>
		))}
		{ageRange ? (
			<li className={styles.filterItem}>
				<b>Age range:</b>&nbsp;{
					min && max ? `${min}-${max}` :
						min ? `At least ${min}` : `${max} & under`
				}
				<Icon
					clicked={() => handleRemoveFilter("ageRange")}
					name="cross"
					size="tiny"
					isClickable
				/>
			</li>
		) : <></>}
		{countryId?.map((id, index) => (
			<li key={`${id}-${index}`} className={styles.filterItem}>
				<b>Country:</b>&nbsp;{data?.countries?.find(i => i.id === id)?.name}
				<Icon
					clicked={() => handleRemoveFilter("countryId", id)}
					name="cross"
					size="tiny"
					isClickable
				/>
			</li>
		))}
		{stateId?.map((id, index) => (
			<li key={`${id}-${index}`} className={styles.filterItem}>
				<b>State:</b>&nbsp;{data?.states?.find(i => i.id === id)?.name}
				<Icon
					clicked={() => handleRemoveFilter("stateId", id)}
					name="cross"
					size="tiny"
					isClickable
				/>
			</li>
		))}
		{ethnicityId?.map((id, index) => (
			<li key={`${id}-${index}`} className={styles.filterItem}>
				<b>Ethnicity:</b>&nbsp;{data?.ethnicities?.find(i => i.id === id)?.name}
				<Icon
					clicked={() => handleRemoveFilter("ethnicityId", id)}
					name="cross"
					size="tiny"
					isClickable
				/>
			</li>
		))
		}
		{customProperties?.map(prop => (
			prop.values.map(value => (prop?.id ?
				<li key={`customProp${prop.id}-${value}`} className={styles.filterItem}>
					<b>{attributes?.find(i => i.id === prop.id)?.name
							|| "Not Found."}
						: </b>&nbsp;{(value.length > 50) ? `${value.substring(0, 47)}...` : value}
					{}
					<Icon
						clicked={() => handleRemoveCustomProp(prop.id, value)}
						name="cross"
						size="tiny"
						isClickable
					/>
				</li> : <></>))
		))}
		{surveyIds && surveyIds.length > 0 &&
			<li className={styles.filterItem}>
				<span>
					Has <b>{haveNot ? "NOT completed" : "completed"}</b>
					<b>&nbsp;{any ? "ANY" : "ALL"}</b> of&nbsp;
					{surveyIds.length} selected campaigns
				</span>
				<Icon
					clicked={() => handleRemoveFilter("hasTakenSurvey", "")}
					name="cross"
					size="tiny"
					isClickable
				/>
			</li>
		}
		{firstSurveyResponseAt &&
		<li className={styles.filterItem}>
			<span>First survey completed <b>between</b>:&nbsp;</span>
			{formatDateFromString(firstSurveyResponseAt.start)}&nbsp;<b>and</b>&nbsp;{formatDateFromString(firstSurveyResponseAt.end)}
			<Icon
				clicked={() => handleRemoveFilter("firstSurveyResponseAt")}
				name="cross"
				size="tiny"
				isClickable
			/>
		</li>
		}
		{lastSurveyResponseAt &&
		<li className={styles.filterItem}>
			<span>Last survey completed <b>between</b>:&nbsp;</span>
			{formatDateFromString(lastSurveyResponseAt.start)}&nbsp;<b>and</b>&nbsp;{formatDateFromString(lastSurveyResponseAt.end)}
			<Icon
				clicked={() => handleRemoveFilter("lastSurveyResponseAt")}
				name="cross"
				size="tiny"
				isClickable
			/>
		</li>
		}
		{firstFeedbackSurveyResponseAt &&
		<li className={styles.filterItem}>
			<span>First story completed <b>between</b>:&nbsp;</span>
			{formatDateFromString(firstFeedbackSurveyResponseAt.start)}&nbsp;<b>and</b>&nbsp;{formatDateFromString(firstFeedbackSurveyResponseAt.end)}
			<Icon
				clicked={() => handleRemoveFilter("firstFeedbackSurveyResponseAt")}
				name="cross"
				size="tiny"
				isClickable
			/>
		</li>
		}
		{lastFeedbackSurveyResponseAt &&
		<li className={styles.filterItem}>
			<span>Last story completed <b>between</b>:&nbsp;</span>
			{formatDateFromString(lastFeedbackSurveyResponseAt.start)}&nbsp;<b>and</b>&nbsp;{formatDateFromString(lastFeedbackSurveyResponseAt.end)}
			<Icon
				clicked={() => handleRemoveFilter("lastFeedbackSurveyResponseAt")}
				name="cross"
				size="tiny"
				isClickable
			/>
		</li>
		}
		<div className={styles.crossTag}>
			<span onClick={handleClearFilters}>Clear filters</span>
		</div>
	</ul>;
};

export {FilterDisplay};
