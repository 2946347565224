import {gql} from "@apollo/client";

export const CREATE_AI_PERSONA = gql`
	mutation CreateAiPersona($input: AiPersonaInputCreate!) {
		createAiPersona(input: $input) {
			id
			name
			description
			instructions
			voiceId
			voiceName
			personaCategory {
				id
				name
			}
      personaType {
        id
        name
        description
      }
			picture {
				id
				url(version: THUMBNAIL)
			}
      skills {
        id
        name
      }
		}
	}
`;

export const UPDATE_PERSONA = gql`
	mutation UpdateAiPersona($personaId: GUID! $input: AiPersonaInputUpdate!) {
		updateAiPersona(personaId: $personaId input: $input) {
			id
			name
			description
			instructions
			voiceId
			voiceName
			personaCategory {
				name
			}
      personaType {
        id
        name
        description
      }
			picture {
				id
				url(version: THUMBNAIL)
			}
      skills {
        id
        name
      }
		}
	}
`;

export const DELETE_PERSONA = gql`
	mutation DeleteAiPersona($id: GUID!) {
		deleteAiPersona(id: $id) {
			id
		}
	}
`;


export const CREATE_PERSONA_PICTURE = gql`
	mutation CreatePersonaPicture($uploadItemId: String!) {
		createPersonaPicture(uploadItemId: $uploadItemId) {
			id
			transcodingStatus
			url: url(version: FULL_SIZE)
		}
	}
`

export const PERSONA_PUBLISH = gql`
  mutation PublishPersona($personaId: GUID!) {
    publishPersona(personaId: $personaId) {
      id
      name
      description
      personaStatus
    }
  }
`;

export const PERSONA_DEACTIVATE = gql`
  mutation DeactivatePersona($personaId: GUID!) {
    deactivatePersona(personaId: $personaId) {
      id
      name
      description
      personaStatus
    }
  }
`;

export const PERSONA_CHANGE_VURVEY_FLAG = gql`
  mutation PersonaChangeVurveyFlag($personaIds: [GUID!]!, $isVurvey: Boolean!) {
    aiPersonaUpdateIsVurvey(personaIds: $personaIds, isVurvey: $isVurvey) {
      id
      isVurvey
    }
  }
`;

export const AI_SKILL_CREATE = gql`
  mutation AiSkillCreate($name: String!, $description: String!) {
    aiSkillCreate(name: $name, description: $description) {
      id
      name
      description
    }
  }
`;

export const AI_SKILL_UPDATE = gql`
  mutation AiSkillUpdate($id: GUID!, $name: String, $description: String) {
    aiSkillUpdate(id: $id, name: $name, description: $description) {
      id
      name
      description
    }
  }
`;

export const AI_SKILL_DELETE = gql`
  mutation AiSkillDelete($id: GUID!) {
    aiSkillDelete(id: $id) {
      id
    }
  }
`;
