import classNames from "classnames/bind";
import React, {ReactElement, ReactNode} from "react";

import {Body, Tooltip} from "../../../../../../shared/v2";
import {Link, LinkProps} from "../../../../../../hoc/layout/navigation/link";
import {OverflowTooltip} from "../../../../../../shared/v2/tooltip/overflow-tooltip";

import styles from "./grounding-link.module.scss";

const cx = classNames.bind(styles);

export type GroundingLinkProps = Omit<LinkProps, "className" | "type" | "text"> & {
	className?: string;
	text?: string;
	tooltip?: ReactNode;
	content?: ReactElement;
	to: string;
};

export const GroundingLink = ({text, content, tooltip, className, ...props}: GroundingLinkProps): ReactElement => {
	const renderContent = (): ReactElement => {
		if (content) {
			return content;
		}

		if (tooltip) {
			return (
				<Tooltip
					className={styles.tooltip}
					content={
						<Body size="xs">
							{tooltip}
						</Body>
					}
				>
					{text}
				</Tooltip>
			)
		}

		return (
			<OverflowTooltip>
				{text}
			</OverflowTooltip>
		);
	}

	return (
		<Link
			{...props}
			className={cx("groundingLink", className)}
			omitSearch
			target="_blank"
			type="redirect"
			weight="regular"
			colorVariant="blue-dark"
		>
			{renderContent()}
		</Link>
	);
}
