import {uniq} from "lodash-es";
import React from "react";
import {siftEmails} from "../shared/utility/sift-emails";


export interface TagInputHookReturn {
	/**
	 * What to do when an tag is inputted
	 */
	handleInput: (newTag: string[]) => void;
	/**
	 * Handles removal of a tag
	 */
	handleRemove: (index: number) => void;
	/**
	 * Handles what happens on submit of the tags
	 */
	handleSubmit: () => void;
	/**
	 * Handles when pasting into the input field
	 */
	handlePaste: (e: React.ClipboardEvent<HTMLDivElement>) => void;
	/**
	 * The ref to attach to the tag input
	 */
	inputRef: React.MutableRefObject<HTMLDivElement>;
}

/**
 * Returns the functions we need to use our tag input
 * @param tags The tags list we are manipulating
 * @param setTags the setter for the tags list
 */
export const useTagInput = (
	tags: string[],
	setTags: React.Dispatch<React.SetStateAction<string[]>>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onSubmit?: (tags: string[]) => Promise<any>,
): TagInputHookReturn => {
	const inputRef = React.createRef() as React.MutableRefObject<HTMLDivElement>;
	/**
	 * @param newTag The new tag we want to add to the list
	 */
	const handleInput = (newTag: string[]): void => {
		const updatedList = [...tags, ...newTag];
		setTags(uniq(updatedList));
	};

	const handleRemove = (index: number): void => {
		const updatedTags = [...tags];
		updatedTags.splice(index, 1);
		setTags(updatedTags);
	}

	/**
	 * How to handle hitting a submit button. Generally given from parent
	 */
	const handleSubmit = (): void => {
		onSubmit?.(tags);
	};

	/**
	 * We use this to get all the values that a user may have pasted into the
	 * input field.
	 * @param e ClipboardEvent. Used to get what user pasted.
	 */
	const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>): void => {
		e.preventDefault();
		const pasted = e.clipboardData.getData("text/plain").split(/[\s,\n]+/);
		const {validEmails, invalidEmails} = siftEmails(pasted);
		const updatedList = [...tags, ...validEmails];
		const lowerCase = updatedList.map(val => val.toLowerCase());
		setTags(uniq(lowerCase));
		if (invalidEmails.length > 0) {
			inputRef.current.innerText += invalidEmails.join(" ");
		}
	};
	return {handleInput, handleRemove, handleSubmit, handlePaste, inputRef};
};
