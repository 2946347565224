import React, {ReactElement} from "react";
import styles from "./delete-reel.module.scss";
import {BaseModalProps, Body, Button, Modal} from "../../shared/v2";

export interface DeleteConfirmModalProps extends BaseModalProps {
	/**
	 * Handle confirming
	 */
	handleConfirm: () => void;
	warningText: string | JSX.Element;
}

/**
 * Modal for confirming user action of deleting a reel
 * @param isOpen If modal is open or not
 * @param handleClose Callback to close modal
 * @param handleConfirm Callback to confirm action
 */
const DeleteConfirmModal = (props: DeleteConfirmModalProps): ReactElement => {
	const {isOpen, warningText, handleConfirm, onClose} = props;
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			className={styles.modal}
		>
			{typeof warningText === "string" ?
				<Body type="semibold" className={styles.warning}>
					{warningText}
				</Body> : warningText
			}
			<div className={styles.actions}>
				<Button
					onClick={handleConfirm}
				>
					Yes
				</Button>
				<Button
					style="danger"
					onClick={onClose}
				>
					No
				</Button>
			</div>
		</Modal>
	);
};

export {DeleteConfirmModal};
