import React, {ReactElement, ReactNode, createContext, useCallback, useContext, useState} from "react";

export interface ChatScrollContextValue {
  setContainer: (container: HTMLDivElement | null) => void;
  scrollListToBottom: () => void;
}

export const ChatScrollContext =
  createContext<ChatScrollContextValue | undefined>(undefined);

export const ChatScrollContextProvider = (
	{children}: {children: ReactNode},
): ReactElement => {
	const [container, setContainer] = useState<HTMLDivElement | null>(null);

	const scrollListToBottom = useCallback(() => {
		if (container) {
			container.scrollTop = container.scrollHeight;
		}
	}, [container]);

	return (
		<ChatScrollContext.Provider value={{
			setContainer,
			scrollListToBottom,
		}}>
			{children}
		</ChatScrollContext.Provider>
	);
};

export const useChatScrollContext = (): ChatScrollContextValue => {
	const context = useContext(ChatScrollContext);

	if (context === undefined) {
		throw new Error(
			"useChatScrollContext must be used within a ChatScrollContextProvider",
		);
	}

	return context;
};
