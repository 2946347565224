import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import {useThemeMode} from "../../../../context/theme-mode-context";

import styles from "./loader.module.scss";

const bStyles = classNames.bind(styles);

export interface LoaderProps {
  size?: "small" | "medium"
}


export const Loader = ({size}: LoaderProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	return <div className={bStyles("loader", size, {isDarkMode})} />;
}
