import React, {ReactElement, useCallback, useEffect, useState} from "react";
import {Clip} from "../../../models/clip";
import {ClipListItem} from "../clip-list-item";
import styles from "./clips-list.module.scss";

export interface ClipsListProps {
	/**
	 * The clips array
	 */
	clipsList: Clip[];
	/**
	 * The callback function to handle moving a clip.
	 * It is only one level up, so thought this was fine
	 */
	moveClipCallback: (clip: Clip, dragIndex: number, dropIndex: number) => void;
}
/**
 * Clips list component
 * Displays the data inside of a table. An *actual* table, since it seemed
 * simplest to use that instead.
 */
const ClipsList = (props: ClipsListProps): ReactElement => {
	const {clipsList, moveClipCallback} = props;
	const [clips, setClips] = useState<Clip[]>(() => clipsList);


	/**
	 * Handles the drag action when we are reordering. Makes it look like it's changing
	 */
	const handleDragging = useCallback((dragIndex: number, hoverIndex: number) => {
		const copiedList = clipsList.slice();
		copiedList.splice(hoverIndex, 0, copiedList.splice(dragIndex, 1)[0]);
		setClips(copiedList);
	}, [clipsList]);
	/**
	 * Handles the drop action. Will only trigger if drag / dropIndex are not the same.
	 * @param dragIndex The index of the object we are dragging
	 * @param dropIndex The index
	 * @returns Calls the moveClip mutation on parent.
	 */
	const handleDrop = useCallback((dragIndex: number, dropIndex: number) => {
		const clip = clipsList[dragIndex]; // Gets the clip
		moveClipCallback(clip, dragIndex, dropIndex);
		// }
	}, [clipsList]);

	useEffect(() => {
		setClips(clipsList);
	}, [clipsList, setClips]);
	return (
		<table className={styles.container}>
			<thead className={styles.thead}>
				<tr>
					<th className={[styles["thead-cell"], styles.small].join(" ")}>#</th>
					<th className={styles["thead-cell"]}>Video</th>
					<th className={[styles["thead-cell"], styles.medium].join(" ")}>Duration</th>
					<th className={[styles["thead-cell"], styles.large].join(" ")}>&nbsp;</th>
				</tr>
			</thead>
			<tbody>
				{
					clips.map((clip, i) => <ClipListItem
						key={clip.id}
						clip={clip}
						countIndex={i}
						dragCallback={handleDragging}
						dropCallback={handleDrop}
					/>)
				}
			</tbody>
		</table>
	);
};

export {ClipsList};
