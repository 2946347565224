import {useMutation} from "@apollo/client";
import classNames from "classnames/bind";
import React, {ReactElement, useMemo, useState} from "react";

import {GET_REWARD_SETTINGS, GET_REWARD_SETTINGS_NOCONFIG} from "../../../../../graphql/queries/reward-queries";
import {Separator, Spinner, Body, Button} from "../../../../../shared/v2";
import {SettingsGearIcon} from "../../../../../icons";
import {TremendousIntegrateModal} from "../../../../../modals/tremendous";
import {UPDATE_TREMENDOUS_SETTINGS} from "../../../../../graphql/mutations/reward-mutations";
import {UpdateTremendousSettingsReturn, UpdateTremendousSettingsInput, RewardSettingReturnNoConfig} from "../../../../../models/rewards";
import {useLoadingQuery} from "../../../../../hooks";
import {useThemeMode} from "../../../../../context/theme-mode-context";
import {useWorkspaceContext} from "../../../../../context/workspace-context";

import styles from "./tremendous-card.module.scss";

const cx = classNames.bind(styles);

export const TremendousCard = (): ReactElement => {
	const {workspace: {id, permissions}} = useWorkspaceContext();
	const {isDarkMode} = useThemeMode();

	const [isTremendousIntegrateModalOpen, setIsTremendousIntegrateModalOpen] = useState(false);

	const {
		data: {
			workspaceRewardSettings: [
				{enabledStatus} = {enabledStatus: false},
			] = [],
		} = {},
		loading: isLoadingEnabledStatus,
	} =
		useLoadingQuery<RewardSettingReturnNoConfig>(GET_REWARD_SETTINGS_NOCONFIG, {
			variables: {workspaceId: id},
		});

	const canManageTremendousSettings = useMemo(() => {
		if (!permissions) return false;
		return permissions.some(value => value.includes("tremendousSettings"));
	}, []);

	const [updateTremendous] =

		useMutation<UpdateTremendousSettingsReturn, UpdateTremendousSettingsInput>(
			UPDATE_TREMENDOUS_SETTINGS,
			{
				// May eventually try to do this through cache. Little convoluted.
				refetchQueries: [
					{
						query: GET_REWARD_SETTINGS,
						variables: {workspaceId: id},
					},
				],
			},
		);

	const handleDisable = (): void => {
		updateTremendous({variables: {workspaceId: id, disable: true}});
	};

	const handleEnable = (): void => {
		updateTremendous({variables: {workspaceId: id, enable: true}});
	};

	const buttons = enabledStatus ? (
		<>
			<Button
				disabled={!canManageTremendousSettings}
				variant="outlined"
				leftIcon={<SettingsGearIcon />}
				onClick={() => setIsTremendousIntegrateModalOpen(true)}
			>
        Configure
			</Button>

			<Button
				disabled={!canManageTremendousSettings}
				style="danger"
				variant="outlined"
				onClick={handleDisable}
			>
        Disable
			</Button>
		</>
	) : (
		<Button
			disabled={!canManageTremendousSettings}
			onClick={handleEnable}
		>
      Enable
		</Button>
	)

	return (
		<>
			<div className={cx("tremendousCard", {isDarkMode})}>
				<div className={styles.content}>
					<img src="/images/tremendous-logo.png" alt="Tremendous" />

					<Body
						size="s"
						color="text-secondary"
						className={styles.description}
					>
            Vurvey&apos;s Tremendous integration makes rewarding your audience easy. Once set up, you&apos;ll be able to send digital rewards with a couple clicks, all from within the Vurvey platform. Your audience members will receive an email containing their reward with options to easily redeem. For more information on Tremendous, please visit
						{" "}
						<a
							target="_blank"
							rel="noreferrer"
							href="https://www.tremendous.com"
							className={styles.tremendousLink}
						>
              www.tremendous.com
						</a>.
					</Body>
				</div>
				<Separator
					orientation="vertical"
					className={styles.separator}
					color={isDarkMode ? "charcoal-elevation-400" : "gray-modern-200"}
				/>
				<div className={styles.buttonsWrapper}>
					{
						isLoadingEnabledStatus ?
							<Spinner className={styles.loading} /> :
							buttons
					}
				</div>
			</div>
			<TremendousIntegrateModal
				isOpen={isTremendousIntegrateModalOpen}
				onClose={() => setIsTremendousIntegrateModalOpen(false)}
			/>
		</>
	)
}
