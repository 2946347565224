/* eslint-disable */

import React, {forwardRef, ReactElement} from "react";
import classnames, {Argument} from "classnames";
import {
	NavLink as OriginalNavLink,
	NavLinkProps as ONLProps,
	LinkProps as OLProps,
	Link as OriginalLink,
} from "react-router-dom";

import {useBuild} from "./useSearchParams";
import {To} from "./types";

export interface NavLinkProps extends Omit<ONLProps, "to" | "className"> {
	to: To;
	omitSearch?: boolean;
	workspace?: boolean;
	className?: Argument;
	activeClassName?: Argument;
}

export interface LinkProps extends Omit<OLProps, "to" | "className"> {
	to: To;
	search?: boolean;
	workspace?: boolean;
	className?: Argument;
}


export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>((
	{to, omitSearch, workspace, className, activeClassName, ...props},
	ref,
): ReactElement => {
	const build = useBuild();

	return <OriginalNavLink
		{...props}
		ref={ref}
		to={build(to, {search: !omitSearch, workspace})}
		className={({isActive}) => classnames(className, isActive && activeClassName)}
	/>;
});


export const Link = forwardRef<HTMLAnchorElement, LinkProps>((
	{to, search, workspace, className, ...props}: LinkProps,
	ref
): ReactElement => {
	const build = useBuild();

	return <OriginalLink
		{...props}
		ref={ref}
		to={build(to, {search, workspace})}
		className={classnames(className)}
	/>;
});
