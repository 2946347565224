import {gql} from "@apollo/client";

export const GET_SELECTED_ANSWER = gql`
	{
		selectedAnswer @client
	}
`;
export const GET_CREATE_WORKSPACE_MODAL = gql`
	{
		toggleCreateWorkspaceModal @client
	}
`;
export const GET_DELETE_QUESTION_MODAL = gql`
	{
		toggleDeleteQuestionModal @client
	}
`;
export const GET_DELETE_RESPONSE_MODAL = gql`
	{
		toggleDeleteResponseModal @client
	}
`;
export const GET_DELETE_CONFIRM_MODAL = gql`
	{
		toggleDeleteConfirmModal @client
	}
`;
export const GET_INVITE_SURVEY_MODAL = gql`
	{
		toggleInviteToSurveyModal @client
	}
`;
// Gets intro image upload modal
export const GET_INTRO_IMAGE_UPLOAD = gql`
	{
		toggleIntroImageUpload @client
	}
`;
export const GET_SHOW_FILTER = gql`
	{
		toggleShowFilter @client
	}
`;
export const GET_RECRUIT_MODAL = gql`
	{
		toggleRecruitModal @client
	}
`;
// For checking if the user  modal is open or not.
export const GET_SHOW_USER = gql`
	{
		toggleShowUserModal @client
	}
`;
// Checks if upload modal should show or not.
export const GET_UPLOAD_MODAL = gql`
	{
		toggleShowUploadModal @client
	}
`;
// The current selected user to display in the user modal
export const GET_SELECTED_USER = gql`
	{
		selectedUser @client
	}
`;
export const GET_SELECTED_RESPONSE = gql`
	{
		selectedResponse @client
	}
`;
export const GET_SHOW_INVITE = gql`
	{
		toggleShowInviteModal @client
	}
`;
// Combination of above two in order to get info for modal in component
export const GET_MODAL_INFO = gql`
	{
		toggleShowUserModal @client
		selectedUser @client
	}
`;
export const GET_REMOVE_MEMBER_MODAL = gql`
	{
		toggleShowRemoveMemberModal @client
	}
`;
