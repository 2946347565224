import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {CalendarIcon} from "../../../../icons";
import {Input, InputProps} from "../input";

import styles from "./date-input.module.scss";

const cx = classNames.bind(styles);

export interface DateInputProps extends Omit<InputProps<string>, "type" | "min" | "max"> {
	min?: string;
	max?: string;
}

export const DateInput = ({
	className,
	...props
}: DateInputProps): ReactElement => {

	return (
		<Input
			type="date"
			placeholder="dd.mm.yyyy"
			rightIcon={<CalendarIcon />}
			className={cx(className, "dateInput")}
			{...props}
		/>
	)
}
