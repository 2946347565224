import React, {ReactElement, ReactNode} from "react";

import {usePortalContext} from "../../../context/portal-context";

export interface PortalProps {
  name: string;
  children: ReactNode;
}

export const Portal = ({name, children}: PortalProps): ReactElement  => {
	const {render} = usePortalContext();

	return (
		<>
			{render(name, children)}
		</>
	);
}
