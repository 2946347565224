import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import {Caption} from "../../../../shared/v2/typography";

import styles from "./status.module.scss";

const bStyles = classNames.bind(styles);

export interface StatusProps {
	status: "deployed" | "beta" | "coming-soon";
}

export const Status = ({status}: StatusProps): ReactElement =>
	<Caption className={bStyles("status", status.toLowerCase())} type="bold">
		{status}
	</Caption>;
