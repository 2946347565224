import {useApolloClient, useQuery, useSubscription} from "@apollo/client";
import classNames from "classnames/bind";
import React, {useEffect} from "react";

import {useThemeMode} from "../../../../context/theme-mode-context";
import {useWorkspaceContext} from "../../../../context/workspace-context";
import {GET_CHAT_CONVERSATIONS} from "../../../../graphql/queries/ai-models-queries";
import {WORKSPACE_EVENTS} from "../../../../graphql/subscriptions";
import {PlusSmallIcon, SparkAiStarsIcon} from "../../../../icons";
import {ChatConversation} from "../../../../models";
import {useNavigate} from "../../../../route";
import {COPILOT_CONVERSATIONS_LIMIT} from "../../../../shared/constants/constants";
import {Body, ButtonIcon, Spinner, Tooltip} from "../../../../shared/v2";
import {ChatSessionContainer} from "../chat-session-container";
import {Link} from "../link";

import styles from "./copilot.module.scss";

const cx = classNames.bind(styles);

export const Copilot = () => {
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const apolloClient = useApolloClient();
	const {isDarkMode} = useThemeMode();
	const navigate = useNavigate();

	const {data: {workspaceUpdates} = {}} = useSubscription(WORKSPACE_EVENTS, {
		variables: {
			workspaceId,
			options: {
				isPreview: false,
				limit: COPILOT_CONVERSATIONS_LIMIT,
			}
		},
	});

	useEffect(() => {
		if (workspaceUpdates?.type !== "WorkspaceRecentConversationsReordered") {
			return;
		}

		const {recentConversations} = workspaceUpdates.data;

		apolloClient.writeQuery({
			query: GET_CHAT_CONVERSATIONS,
			variables: {
				workspaceId,
				limit: COPILOT_CONVERSATIONS_LIMIT,
				filter: {isPreview: false},
			},
			data: {
				chatConversations: recentConversations,
			},
		});
	}, [workspaceUpdates]);

	const {
		data: {chatConversations = []} = {},
		loading,
	} = useQuery<
    {chatConversations: ChatConversation[]}
  >(GET_CHAT_CONVERSATIONS, {
  	variables: {
  		limit: COPILOT_CONVERSATIONS_LIMIT,
  		filter: {isPreview: false},
  		workspaceId,
  	},
  });

	const startNewChat = (): void => {
		navigate(
			{
				pathname: "/",
				search: {canvasId: ""},
			},
			{workspace: true},
		);
	};

	const hasConversations = chatConversations.length > 0;

	const renderListContent = () => {
		if (hasConversations) {
			return (
				<>
					<div className={styles.chatSessionList}>
						{chatConversations.map((chatConversation) => (
							<ChatSessionContainer key={chatConversation.id} chatConversation={chatConversation} />
						))}
					</div>

					<Link
						className={styles.viewAllLink}
						type="redirect"
						text="View all"
						to="workflow"
					/>
				</>
			);
		} else if (loading) {
			return <Spinner className={styles.loader}/>;
		}
	}

	return (
		<div className={cx("copilot", {isDarkMode, empty: !hasConversations})}>
			<div className={styles.copilotHeader}>
				<SparkAiStarsIcon className={styles.copilotIcon} />
				<Body size="xs" type="medium" className={styles.copilotHeadline}>
          Conversations
				</Body>
				<Tooltip content={(
					<Body size="xs">
            Start a new conversation
					</Body>
				)}>
					<ButtonIcon
						size="small"
						className={styles.plusIcon}
						color="text-secondary"
						icon={<PlusSmallIcon />}
						onClick={startNewChat}
					/>
				</Tooltip>
			</div>

			{renderListContent()}
		</div>
	);
};
