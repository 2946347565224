import React, {KeyboardEventHandler, KeyboardEvent, ReactElement, ReactNode, createContext, useCallback, useEffect, useState} from "react";

export interface KeyboardEventMiddlewareContextValue {
  onKeyDown: KeyboardEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  registerMiddleware: (middleware: Middleware) => void
}

export const KeyboardEventMiddlewareContext =
  createContext<KeyboardEventMiddlewareContextValue | undefined>(undefined);

interface Middleware {
  name: string;
  order?: number;
  callback: (e: KeyboardEvent) => KeyboardEvent | void;
}

export const KeyboardEventMiddlewareContextProvider = (
	{children}: {children: ReactNode},
): ReactElement => {
	const [middlewares, setMiddlewares] = useState<Middleware[]>([]);

	const handleKeyDown = (e: KeyboardEvent) => {
		const callbacks: string[] = [];

		for (const {callback, name} of middlewares) {
			const result = callback(e);

			callbacks.push(name);

			if (!result) {
				break;
			}
		}
	}

	const registerMiddleware = (middleware: Middleware) => {
		setMiddlewares(prev => [...prev, middleware].sort((a, b) => (b.order ?? 0) - (a.order ?? 0)));

		return () => {
			setMiddlewares(prev => prev.filter((m) => m.name !== middleware.name));
		}
	}

	return (
		<KeyboardEventMiddlewareContext.Provider value={{
			onKeyDown: handleKeyDown,
			registerMiddleware,
		}}>
			{children}
		</KeyboardEventMiddlewareContext.Provider>
	);
};

export const useKeyboardEventMiddlewareContext = (): KeyboardEventMiddlewareContextValue => {
	const context = React.useContext(KeyboardEventMiddlewareContext);

	if (context === undefined) {
		throw new Error(
			"useKeyboardEventMiddlewareContext must be used within a KeyboardEventMiddlewareContextProvider",
		);
	}

	return context;
};

interface MiddlewareOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deps?: any[];
  name: string;
  order?: number;
}

export function useKeyboardEventMiddleware(fn: (e: KeyboardEvent) => KeyboardEvent | void, opts: MiddlewareOptions): void {
	const {registerMiddleware} = useKeyboardEventMiddlewareContext();

	const callback = useCallback((e) => {
		return fn(e);
	}, opts.deps ?? []);

	useEffect(() => registerMiddleware({name: opts.name, order: opts.order, callback}), [callback]);
}
