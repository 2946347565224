import {gql} from "@apollo/client";

/*
* There is no way to get a name of a collection right now,
* so this will return an id until that is possible
*/
export const GET_COLLECTION_NAMES = gql`
	query CollectionNames ($workspaceId: GUID!) {
		collections(workspaceId: $workspaceId) {
			id
			name
		}
	}
`;

export const COLLECTIONS = gql`
	query Collections ($workspaceId: GUID!) {
		collections (workspaceId: $workspaceId) {
			id
			name
			# name should be included but doesn't exist in the API yet
			videos {
				id
				duration
				transcodingStatus
				originalFilename
				image: url(version:THUMBNAIL)
				mp4:url(version:H264_AAC)
			}
		}
	}
`;
