import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {Caption} from "../../../../shared/v2/typography";
import {PersonaTransformedStatus} from "../../../../models/persona";
import {SurveyStatus} from "../../../../models/survey";
import {useThemeMode} from "../../../../context/theme-mode-context";

import styles from "./badge.module.scss";

const bStyles = classNames.bind(styles);

export interface BadgeProps {
	text: "foundation" | "transcript" | "question" | "campaign" | "creator" | "answer-video" |
	"language" |"speech" | "multimodal" | "video" | "document" | "image" | "generative" | "active" | "inactive" |
	SurveyStatus | PersonaTransformedStatus;
}

export const Badge = ({text}: BadgeProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	return (
		<div className={bStyles("badge", text?.toLowerCase(), {isDarkMode})}>
			<Caption type="medium" className={bStyles("text", text?.toLowerCase())}>{text?.toLowerCase()}</Caption>
		</div>
	);
}
