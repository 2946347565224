import {gql} from "@apollo/client";
import {BRAND_FRAGMENT} from "../fragments/fragments";

export const UPDATE_BRAND = gql`
	mutation UpdateBrand(
		$brandId: GUID!
		$changes: UpdateBrandChanges
		$deletions: UpdateBrandDeletions
	) {
		updateBrand (brandId: $brandId changes: $changes deletions: $deletions) {
			...BrandFields
		}
	}
	${BRAND_FRAGMENT}
`;

export const CREATE_BRAND_LOGO = gql`
	mutation CreateBrandLogo($uploadItemId: String!) {
		createBrandLogo(uploadItemId: $uploadItemId) {
			id
			transcodingStatus
		}
	}
`;

export const CREATE_BRAND_BANNER = gql`
	mutation CreateBrandLogo($uploadItemId: String!) {
		createBrandBanner(uploadItemId: $uploadItemId) {
			id
			transcodingStatus
		}
	}
`;

export const CHANGE_BRAND_VISIBILITY = gql`
	mutation ChangeBrandVisibility($brandId: GUID! $visible: Boolean!) {
		changeBrandVisibility(brandId: $brandId visible: $visible) {
			id
			visible
		}
	}
`;

export const FOLLOW_BRAND = gql`
	mutation FollowBrand($brandId: GUID!) {
		followBrand(brandId: $brandId) {
			id
		}
	}
`;

/**
 * Ha.
 */
export const ADD_BRAND_BENEFIT = gql`
	mutation AddBenefit ($brandId: GUID! $benefitId: GUID!) {
		brandBenefitsAdd(brandId: $brandId benefitId: $benefitId) {
			id
		}
	}
`;

// Just returns a boolean I guess.
export const REMOVE_BRAND_BENEFIT = gql`
	mutation RemoveBenefit ($brandId: GUID! $benefitId: GUID!) {
		brandBenefitsRemove(brandId: $brandId benefitId: $benefitId)
	}
`;
