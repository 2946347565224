/* eslint-disable react/prop-types */
import {BugsnagErrorBoundary} from "@bugsnag/plugin-react";
import {OnErrorCallback} from "@bugsnag/js";
import React, {Component, ErrorInfo} from "react";

export interface DummyBugsnagErrorBoundaryProps {
  children?: React.ReactNode | undefined
  // Doesn't actually do anything, just here to match the BugsnagErrorBoundary interface
  onError?: OnErrorCallback
  FallbackComponent?: React.ComponentType<{
    error: Error
    info: React.ErrorInfo
    clearError: () => void
  }>
  name?: string
}

export interface DummyBugsnagErrorBoundaryState {
  error: Error | null;
  info: ErrorInfo | null;
}

class DummyBugsnagErrorBoundary extends Component<DummyBugsnagErrorBoundaryProps, DummyBugsnagErrorBoundaryState> {
	constructor(props) {
		super(props);
		this.state = {error: null, info: null};
	}

	static getDerivedStateFromError(error: Error, info: ErrorInfo) {
		return {error, info};
	}

	render() {
		const {error, info} = this.state;
		const {children, FallbackComponent} = this.props;

		if (error && FallbackComponent) {
			return (
				<FallbackComponent
					error={error}
					info={info as ErrorInfo}
					clearError={() => this.setState({error: null, info: null})}
				/>
			)
		}

		return children;
	}
}

export default DummyBugsnagErrorBoundary as unknown as BugsnagErrorBoundary;
