import React, {ReactElement} from "react";
import styles from "./color-picker.module.scss";
import classNames, {Argument} from "classnames";
import {DebounceInput} from "react-debounce-input";

export interface ColorPickerProps {
	id: string;
	value: string;
	onChange: (newValue: string) => void;
	className?: Argument;
	children?: React.ReactNode;
}

const ColorPicker = ({
	id,
	value,
	onChange,
	children,
	className,
}: ColorPickerProps): ReactElement => (
	<div className={classNames(styles.container, className)}>
		<DebounceInput
			id={id}
			debounceTimeout={300} // Could make this a variable
			type="color"
			value={value}
			onChange={e => onChange(e.target.value)}
			className={styles.color}
		/>
		{children}
	</div>
);

export {ColorPicker};
