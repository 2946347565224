/* eslint-disable */

// import Editor, {createEditorStateWithText} from "@draft-js-plugins/editor";
import {Descendant, createEditor, Transforms} from "slate";
import {Editable, RenderElementProps, RenderLeafProps, Slate, withReact} from "slate-react";
import React, {ReactElement, useCallback, useEffect, useMemo, useRef, useState} from "react";
import classNames, {Argument} from "classnames";
import {RichToolbar} from "./rich-toolbar";
import isHotkey from "is-hotkey";
import {isParseable} from "../../utility/helpers";
import styles from "./rich-text-input.module.scss";
import {toggleMark} from "./editor-helpers";
import {withHistory} from "slate-history";

/**
 * Leaf is a node of text that is rendered by our RTE above.
 * Allows us to style a section of Text based on props received
 * @param props RenderLeafProps
 */
const Leaf = (props: RenderLeafProps): JSX.Element => {
	return (
		<span
			{...props.attributes}
			style={{
				fontWeight: props.leaf.bold ? "bold" : "normal",
				fontStyle: props.leaf.italic ? "italic" : "normal",
				textDecoration: props.leaf.under ? "underline" : "none",
				textAlign: "center",
			}}>
			{props.children}
		</span>
	);
};

/**
 * An element. General wrapper, always has at least one
 * @param props RenderElementProps
 */
const Element = (props: RenderElementProps): JSX.Element => {
	const {attributes, element, children} = props;
	switch (element.type) {
		case "heading-one":
			return <h1
				className={styles.h1}
				style={{textAlign: props.element.textAlign}}
				{...attributes}
			>
				{children}
			</h1>;
		case "heading-two":
			return <h1
				className={styles.h2}
				style={{textAlign: props.element.textAlign}}
				{...attributes}
			>
				{children}
			</h1>;
		case "heading-three":
			return <h1
				className={styles.h3}
				style={{textAlign: props.element.textAlign}}
				{...attributes}
			>
				{children}
			</h1>;
		case "numbered-list":
			return <ol
				className={styles.ol}
				style={{textAlign: props.element.textAlign}}
				{...attributes}
			>
				{children}
			</ol>;
		case "list-item":
			return <li {...attributes} style={{textAlign: props.element.textAlign}}>{children}</li>;
		case "unordered-list":
			return <ul
				className={styles.ul}
				style={{textAlign: props.element.textAlign}}
				{...attributes}
			>
				{children}
			</ul>;
		case "right-align":
			return <div style={{textAlign: "right"}} {...attributes}>{children}</div>;
		case "center-align":
			return <div style={{textAlign: "center"}} {...attributes}>{children}</div>;
		default:

			return <p
				className={styles.p}
				style={{textAlign: props.element.textAlign}}
				{...attributes}>
				{children}
			</p>;
	}
};

const HOTKEYS = {
	"mod+b": "bold",
	"mod+i": "italic",
	"mod+u": "under",
};
export interface RichTextInputProps {
	/**
	 * Initial value.
	 */
	text: string;
	onDoneEditing?: (newValue: Descendant[]) => void;
	/**
	 * Placeholder if needed
	 */
	placeholder?: string;
	readOnly?: boolean;
	className?: Argument;
	toolbarPosition?: "left" | "right";
	"aria-label"?: string;
}


const RichTextInput = (props: RichTextInputProps): ReactElement => {
	const {className, text, onDoneEditing, placeholder, readOnly = false, toolbarPosition} = props;
	const editor = useMemo(() => withHistory(withReact(createEditor())), []);
	const [isFocused, setIsFocused] = useState<boolean>(false);
	const [value, setValue] = useState<Descendant[]>(
		isParseable(text)
			? (
				JSON.parse(text)
			)
			: [
				{
					type: "paragraph",
					children: [{text: text || placeholder || "Enter content..."}],
					textAlign: "left",
				},
			],
	);
	const [initialValueKey, setInitialValueKey] = useState(0);
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (isParseable(text)) {
			setValue(JSON.parse(text));
		}
	}, [text]);
	/**
	 * @param target Event target of mouse click
	 */
	const handleMouseClick = ({target}): void => {
		if (ref.current && ref.current.contains(target)) {
			return;
		}
		setIsFocused(false);
	};

	useEffect(() => {
		if (ref.current) {
			document.addEventListener("mousedown", handleMouseClick);
		}
		return () => document.removeEventListener("mousedown", handleMouseClick);
	}, []);
	/**
	 * Handles hotkeys
	 * @param event Keyboard event contains keys pressed
	 */
	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
		for (const hotkey in HOTKEYS) {
			if (isHotkey(hotkey, event as any)) {
				event.preventDefault();
				const mark = HOTKEYS[hotkey];
				toggleMark(editor, mark);
			}
		}
	};

	const handleBlur = (): void => {
		setIsFocused(false);
		Transforms.deselect(editor);
		onDoneEditing?.(value);
	};

	const handleFocus = (): void => {
		setIsFocused(true);
		if (!placeholder) return;
		if (text.includes(placeholder)) {
			const point = {path: [0, 0], offset: 0}
			editor.selection = {anchor: point, focus: point},
			editor.history = {redos: [], undos: []};
			editor.children = [{
				type: "paragraph",
				children: [{text: ""}],
			}];
		}
	};

	const renderElement = useCallback(elementProps => <Element {...elementProps}/>, []);
	const renderLeaf = useCallback(leafProps => <Leaf {...leafProps}/>, []);

	useEffect(() => {
		setInitialValueKey(n => n + 1);
	}, [text]);

	return (
		<div
			ref={ref}
			className={classNames(styles.container, className, readOnly && styles.unbound)}
			onFocus={handleFocus}
			key={initialValueKey}
		>
			<Slate
				editor={editor}
				value={value}
				onChange={newValue => setValue(newValue)}
			>
				{ isFocused && !readOnly &&
					<RichToolbar toolbarPosition={toolbarPosition}/>
				}
				<Editable
					className={styles.editor}
					onKeyDown={handleKeyDown}
					renderElement={renderElement}
					renderLeaf={renderLeaf}
					onBlur={handleBlur}
					readOnly={readOnly}
					placeholder={placeholder}
					aria-label={props["aria-label"]}
					renderPlaceholder={({children, attributes}) => {
						if (isFocused) return <></>
						return <span {...attributes} className={styles.alignLeft}>
							{children}
						</span>
					}}
				/>
			</Slate>
		</div>
	);
};


export {RichTextInput};
