import {CLIP_FRAGMENT} from "../fragments/fragments";
import {gql} from "@apollo/client";

export const CREATE_CLIPS = gql`
	mutation CreateClips (
		$reelId: GUID!
		$input: [NewClip!],
		$useFilter: Boolean,
		$filter: AnswersFilter
	) {
		createClips (reelId: $reelId input: $input useFilter: $useFilter filter: $filter) {
			...ClipFields
		}
	} ${CLIP_FRAGMENT}
`;

/**
 * API says that this is deprecated but it's the only way (I see) to
 * add a clip from an uploaded video as NewSingleClip has a sourceVideoId and an
 * optional answerId, while NewClip has a required answerId and no sourceVideoId.
 *
 * I should probably bring it up but I just want to work.
 */
export const CREATE_CLIP = gql`
	mutation CreateClip ($input: NewSingleClip) {
		createClip(input: $input) {
			...ClipFields
		}
	} ${CLIP_FRAGMENT}
`;

/**
 * Updates a clip.
 */
export const UPDATE_CLIP = gql`
	mutation UpdateClip ($id: GUID! $changes: UpdateClipChanges!) {
		updateClip (id: $id changes: $changes) {
			id
			startTime
			endTime
			reel {
				id
				name
				videoStatus
			}
		}
	}
`;

/**
 * Mutation to delete a clip (!)
 */
export const DELETE_CLIP = gql`
	mutation DeleteClip ($id: GUID!) {
		deleteClip (id: $id) {
			id
			startTime
			endTime
			reel {
				id
				name
				videoStatus
			}
		}
	}
`;
/**
 * Mutation to move a clip to a new given index
 */
export const MOVE_CLIP = gql`
	mutation MoveClip ($id: GUID! $index: NonNegativeInt!) {
		moveClip (id: $id index: $index) {
			...ClipFields
		}
	}
	${CLIP_FRAGMENT}
`;
/**
 * Mutation to copy a clip (!)
 */
export const COPY_CLIP = gql`
	mutation CopyClip ($id: GUID!) {
		duplicateClip (id: $id) {
			...ClipFields
		}
	} ${CLIP_FRAGMENT}
`;


export const CREATE_CLIP_SOURCE = gql`
	mutation CreateClipSourceVideo ($uploadItemId: GUID!) {
		createClipSourceVideo(uploadItemId: $uploadItemId) {
			id
			transcodingStatus
			duration
		}
	}
`;
