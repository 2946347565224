import classNames from "classnames/bind";
import React, {ReactElement, useEffect, useMemo, useState} from "react";

import {Avatar, Body} from "../../../../shared/v2";
import {Persona} from "../../../../models";
import {Popup} from "../../../../shared/v2/popup";
import {useKeyboardEventMiddleware} from "../../../../context/keyboard-event-middleware-context";
import {useThemeMode} from "../../../../context/theme-mode-context";

import styles from "./insert-mention.module.scss";

const cx = classNames.bind(styles);

export interface InsertMentionProps {
  text: string;
  personas: Persona[];
  onInsert: (value: string) => void;
}

export const InsertMention = ({text, personas, onInsert}: InsertMentionProps): ReactElement => {
	const [activeIndex, setActiveIndex] = useState(0);
	const [activeOption, setActiveOption] = useState<HTMLDivElement | null>(null);
	const {isDarkMode} = useThemeMode();

	const filteredPersonas = useMemo(() => personas.filter((persona) => {
		const textWithoutAt = text.slice(1);
		return persona.name.toLowerCase().startsWith(textWithoutAt.toLowerCase());
	}), [personas, text]);

	useEffect(() => {
		setActiveIndex(0);
	}, [filteredPersonas.length]);

	useEffect(() => {
		if (activeOption) {
			activeOption.scrollIntoView({block: "nearest"});
		}
	}, [activeOption]);

	useKeyboardEventMiddleware(event => {
		if (event.key === "ArrowDown") {
			setActiveIndex((prev) => (prev + 1) % filteredPersonas.length);
		} else if (event.key === "ArrowUp") {
			setActiveIndex((prev) => (prev - 1 + filteredPersonas.length) % filteredPersonas.length);
		} else if (event.key === "Enter") {
			onInsert(filteredPersonas[activeIndex].name);
		} else {
			return event;
		}
		event.preventDefault();
	}, {name: "insert-mention", order: 1, deps: [filteredPersonas, activeIndex]});

	if (!filteredPersonas.length) {
		return <>{text}</>;
	}

	return (
		<Popup
			position="top-start"
			className={styles.popup}
			popupClassName={styles.popupPanel}
			space={8}
			alwaysOpen
			portal
			trigger={<>{text}</>}
		>
			<div className={cx('wrapper', {isDarkMode})}>
				<div className={styles.items}>
					{filteredPersonas.map((agent, index) => (
						<div
							ref={index === activeIndex ? setActiveOption : null}
							className={cx("item", {focused: index === activeIndex})}
							key={agent.id}
							onClick={() => onInsert(agent.name)}
						>
							<div className={styles.content}>
								<Avatar firstName={agent.name} url={agent?.picture?.url} size="xs" />
								<Body color="text-secondary" className={styles.name} type="medium">
									{agent.name}
								</Body>
								<Body color="text-secondary" className={styles.description} size="s">
									{agent.description}
								</Body>
							</div>
						</div>
					))}
				</div>
			</div>
		</Popup>
	);
}
