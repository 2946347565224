import React, {useRef} from "react";
import {motion} from "framer-motion";

import {ObjectiveCard} from "../../components/objective-card";
import {SourcesCard} from "../../components/sources-card";
import {AgentTaskContainer} from "../../components/agent-task-container";
import {Button, Spinner} from "../../../shared/v2";
import {PlusSmallIcon} from "../../../icons";
import {useWorkflowContext, useCarouselScrollContext} from "../../../context/workflow-contexts";

import styles from "./workflow-canvas.module.scss";
import {AiPersonaTask} from "../../../models/ai-orchestration";

export interface WorklfowCanvasProps {
	setIsSelectAgentModalOpen: (isOpen: boolean) => void;
	setIsSourcesModalOpen: (isOpen: boolean) => void;
}

export const WorkflowCanvas = ({setIsSelectAgentModalOpen, setIsSourcesModalOpen}: WorklfowCanvasProps) => {
	const {
		workflow,
		agentTasks,
		setAgentTasks,
		currentSources,
		isLoadingWorkflow,
		activeAgentTasks,
		isWorkflowRunning,
	} = useWorkflowContext();
	const {x} = useCarouselScrollContext();
	const constraintsRef = useRef(null);

	const handleTaskChange = (agent: AiPersonaTask, value: string) => {
		setAgentTasks(
			agentTasks.map(item => {
				if (item.id === agent.id) {
					return {...item, operation: "UPDATE", task: {taskPrompt: value}};
				}
				return item;
			}),
		);
	};

	const handleRemoveAgent = agentTask => {
		if (agentTask.id.startsWith("NEW")) {
			setAgentTasks(agentTasks.filter(a => a.id !== agentTask.id));
			return;
		}

		agentTask.operation = "DELETE";
		setAgentTasks(agentTasks.map(item => (item.id === agentTask.id ? agentTask : item)));
	};

	const handleAddAgent = () => {
		setIsSelectAgentModalOpen(true);
	};

	const handleIndexChange = (agent: AiPersonaTask, newIndex: number) => {
		setAgentTasks(
			agentTasks.map(item => {
				if (item.id === agent.id) {
					item = {...item, index: newIndex, operation: "UPDATE"};
					return item;
				}
				return item;
			}),
		);
	};

	const renderWorkflow = () => {
		if (isLoadingWorkflow) {
			return (
				<div className={styles.loadingContainer}>
					<Spinner className={styles.spinner} />
				</div>
			);
		}
		if (!workflow) {
			return null;
		}

		return (
			<>
				<ObjectiveCard objective={workflow?.description} />
				<motion.div
					className={styles.carouselContent}
					drag={"x"}
					dragConstraints={constraintsRef}
					id="workflow-carousel"
					style={{x}}
				>
					<SourcesCard
						workflow={workflow}
						openSourcesModal={() => setIsSourcesModalOpen(true)}
						currentSources={currentSources}
					/>

					{activeAgentTasks.map((agent, index) => (
						<AgentTaskContainer
							key={index}
							agentTask={agent}
							onRemove={handleRemoveAgent}
							onChangeTask={value => handleTaskChange(agent, value)}
							onChangeIndex={handleIndexChange}
						/>
					))}

					<Button
						id="add-agent-button"
						leftIcon={<PlusSmallIcon />}
						className={styles.addAgentButton}
						onClick={handleAddAgent}
						disabled={isWorkflowRunning}
					>
						Add Agent
					</Button>
				</motion.div>
			</>
		);
	};

	return (
		<div className={styles.canvas} ref={constraintsRef}>
			{renderWorkflow()}
		</div>
	);
};

