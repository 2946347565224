// import classNames from "classnames/bind";
import React, {ReactElement, useMemo, useState} from "react";
// import {useQuery} from "@apollo/client";
import {useLocation} from "react-router";
import {AnimatePresence, motion, Variants} from "framer-motion";

import {Chat} from "../chat";
import {ChatBubble} from "../chat-bubble";
import {ChatNamePill} from "../chat/chat-name-pill";
import {Header, Subheader} from "../../shared/v2";
// import {PROMPT_TEMPLATES} from "../../graphql/queries/ai-models-queries";
// import {Prompt} from "../../models/ai-model";
// import {PromptCard} from "../prompt-card";
import {useUserContext} from "../../context/user-context";
import {variants, transition} from "./animation-helpers";

import styles from "./chat-view.module.scss";

// const cx = classNames.bind(styles);

export const ChatView = (): ReactElement => {
	const {user} = useUserContext();
	const location = useLocation();

	// const {data: promptsData, loading: promptsLoading} = useQuery(PROMPT_TEMPLATES, {
	//   fetchPolicy: "cache-first",
	// })

	const [promptValue, setPromptValue] = useState("");

	// const prompts = promptsData?.promptTemplates;

	// const handleSelectPrompt = (prompt: Prompt): void => {
	//   setPromptValue(prompt.template);
	//   document.querySelector<HTMLTextAreaElement>("#command-text-area")?.focus()
	// }

	// const renderPrompts = ():ReactElement | undefined => {
	//   if (promptsLoading) {
	//     return <div className={styles.loadingPrompts}><Spinner /></div>
	//   }

	//   if (prompts.length) {
	//     return  <div className={styles.prompts}>
	//       {prompts.map((prompt, index) => {
	//         return <PromptCard key={index} prompt={prompt} onClick={handleSelectPrompt} />
	//       })}
	//     </div>
	//   }
	// }

	const homeMode = useMemo(() => {
		return !location.search.includes("canvasId")
	}, [location.search])

	const variant = homeMode ? "home" : "chat";

	const containerVariants = variants({
		home: {
			paddingTop: "160px",
			// // Restore once prompt cards are back
			// // height: "394px",
			height: "262px",
		},
		chat: {
			paddingTop: "0px",
			height: "calc(100% - 32px)",
		}
	});

	const chatContentWrapperVariants: Variants = variants({
		home: {
			opacity: 0,
			y: "-100%",
		},
		chat: {
			opacity: 1,
			y: "0%",
		}
	}, 1);

	// Hopefully we get rid of this expanded ifs with events system
	return (
		<div className={styles.background}>
			<motion.div
				animate={variant}
				className={styles.container}
				initial={false}
				variants={containerVariants}
			>
				<div className={styles.content}>
					<AnimatePresence>
						{homeMode && (
							<motion.div
								className={styles.homeContentWrapper}
								exit={{
									opacity: 0,
									y: "-100%",
									transition: transition(1)
								}}
							>
								<Header className={styles.header} type="medium">
                  Hi {user.firstName}!
								</Header>

								<Subheader size="xl" className={styles.subheader} color="text-secondary">
                  What might we create today?
								</Subheader>

								{/* {renderPrompts()} */}
							</motion.div>
						)}
					</AnimatePresence>
					<motion.div
						className={styles.chatContentWrapper}
						variants={chatContentWrapperVariants}
					>
						<div className={styles.pillWrapper}>
							<ChatNamePill className={styles.chatNamePill} />
						</div>
						<Chat wrapperClassName={styles.chat} />
					</motion.div>
				</div>
				<ChatBubble
					promptValue={promptValue}
					clearPrompt={() => setPromptValue("")}
					context={homeMode ? "home" : "chat"}
					className={styles.chatBubble}
				/>
			</motion.div>
		</div>
	);
};
