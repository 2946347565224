/* eslint-disable react/prop-types */

import React, {ReactElement, useCallback, useContext, useMemo, useState} from "react";
import {Column} from "react-table";
import TimeAgo from "react-timeago";
import {useMutation} from "@apollo/client";

import {useLoadingQuery} from "../../../hooks";
import {GET_ALL_REELS} from "../../../graphql/queries/reel-queries";
import {CopiedReel, CopyReelVars, DeleteReelVars, DeletedReel, Reel, ReelVideoStatus, ReelsPageData} from "../../../models/reels";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {ReelLink} from "../../../reels/components/reel-link";
import {convertToClockTime, Options} from "../../../shared";
import {AlignText} from "../../../shared/typography/align-text";
import {Body} from "../../../shared/v2/typography";
import {REEL_FRAGMENT} from "../../../graphql/fragments/fragments";
import {DELETE_REEL, DUPLICATE_REEL} from "../../../graphql/mutations/reel-mutations";
import {updateCacheAddPageItem, updateCacheDeletePageItem} from "../../../shared/utility/update-cache";
import {ToastContext} from "../../../context/toast-context";
import {Table} from "../../../shared/components/table";
import {reelStatusSort} from "../../../shared/utility/utility";
import {Button, DebounceSearch, EmptyState, LoadingContainer} from "../../../shared/v2";
import {PlusSmallIcon, SparkAiStarsIcon} from "../../../icons";
import {DeleteConfirmModal} from "../../../modals/delete-reel";
import {AddReelModal} from "../../../modals/add-reel";

import styles from "./magic-reels-page.module.scss";

export const MagicReelsPage = (): ReactElement => {
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const {updateToast} = useContext(ToastContext);

	const [reelId, setReelId] = useState("");
	const [search, setSearch] = useState("");
	const [isAddReelModalOpen, setIsAddReelModalOpen] = useState<boolean>(false);

	const {data, loading} = useLoadingQuery<ReelsPageData>(GET_ALL_REELS, {
		variables: {filter: {workspaceId, sharedInBrand: false, magicReel: false}},
		errorPolicy: "all",
		fetchPolicy: "cache-and-network",
		nextFetchPolicy: "cache-and-network",
	});

	const [copyReel] = useMutation<CopiedReel, CopyReelVars>(DUPLICATE_REEL, {
		onCompleted: () => updateToast({description: "Reel copied", type: "informational"}),
	});

	const [deleteReel] = useMutation<DeletedReel, DeleteReelVars>(DELETE_REEL, {
		onCompleted: () => updateToast({description: "Reel deleted", type: "informational"}),
	});

	const handleReelStatus = useCallback((status: ReelVideoStatus): ReactElement => {
		let text;
		switch (status) {
		case ReelVideoStatus.CREATED:
			text = <>Published</>;
			break;
		case ReelVideoStatus.DIRTY:
			text = <>Unpublished<br/>Changes</>;
			break;
		case ReelVideoStatus.FAILED:
			text = <>Failed</>;
			break;
		case ReelVideoStatus.PENDING:
			text = <>Processing</>;
			break;
		case ReelVideoStatus.UNPUBLISHED:
			text = <>Draft</>;
			break;
		}
		return <span>{text}</span>;
	}, []);

	const handleDuplicateReel = (id: string): void => {
		copyReel({
			variables: {id},
			update(cache, {data: reel}) {
				if (reel) {
					const copiedRef = cache.writeFragment({
						data: reel.duplicateReel,
						fragment: REEL_FRAGMENT,
					});
					updateCacheAddPageItem(cache, copiedRef, "reels", reel.duplicateReel.id);
				}
			},
		});
	};

	const handleDeleteReel = (): void => {
		deleteReel({
			variables: {id: reelId},
			update(cache, {data: deleteData}) {
				if (deleteData) {
					updateCacheDeletePageItem(cache, "reels", "reel", deleteData.deleteReel.id);
				}
			},
		});
		setReelId("");
	};

	const columns = useMemo((): Column<Reel>[] => (
		[
			{
				Header: "Name",
				accessor: "name",
				width: 400,
				Cell: ({row: {original: reel}}) => <ReelLink reel={reel}/>,
			},
			{
				Header: "Length",
				accessor: "duration",
				Cell: ({value}) => (
					<Body size="s">
						{convertToClockTime(value as number, "milliseconds", "written")}
					</Body>
				),
			},
			{
				Header: "Status",
				accessor: "videoStatus",
				sortType: reelStatusSort,
				Cell: ({value}) => (
					<Body size="s">
						{handleReelStatus(value)}
					</Body>
				),
			},
			{
				Header: "Last updated",
				accessor: "createdAt",
				Cell: ({value}) => (
					<Body size="s">
						<TimeAgo date={value as Date} live={false}/>
					</Body>
				),
			},
			{
				id: "Options",
				Header: "",
				disableSortBy: true,
				Cell: ({row: {original: reel}}) => <AlignText align="right">
					<Options
						type="menu-vertical"
						options={[{
							name: "Copy",
							actionOptions: {onClick: () => handleDuplicateReel(reel.id)},
							icon: "copy",
							iconFill: "var(--color-text-body)",
						},
						{
							name: "Delete",
							actionOptions: {onClick: () => setReelId(reel.id)},
							icon: "trash",
							iconFill: "var(--color-text-body)",
						}]}
					/>
				</AlignText>,
			},
		]
	), []);

	if (loading) {
		return <LoadingContainer />;
	}

	if (!data?.reels.items.length) {
		return <EmptyState
			title="Magic Reels"
			description="Turn your videos into a highlight reel of the most important topics and themes. All powered by AI."
			icon={<SparkAiStarsIcon />}
		/>
	}
	return <>
		<div className={styles.headerWrapper}>
			<DebounceSearch
				value={search}
				onChange={setSearch}
				className={styles.search}
				placeholder="Search Reels"
			/>
			<Button
				onClick={() => setIsAddReelModalOpen(true)}
				leftIcon={<PlusSmallIcon />}
			>
        Add Reel
			</Button>
		</div>
		<Table
			columns={columns}
			data={data.reels.items}
			searchValue={search}
		/>

		<AddReelModal
			isOpen={isAddReelModalOpen}
			onClose={() => setIsAddReelModalOpen(false)}
			workspaceId={workspaceId}
		/>

		<DeleteConfirmModal
			isOpen={!!reelId}
			onClose={() => setReelId("")}
			handleConfirm={handleDeleteReel}
			warningText="Are you sure you want to delete this reel?"
		/>
	</>
}
