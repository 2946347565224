import React, {ReactElement, useEffect, useMemo, useState} from "react";
import styles from "./add-property.module.scss";
import {BaseModalProps, Body, Button, Modal, RadioGroup} from "../../../shared/v2";
import {AttributesWithExtantPageData, RuleAction} from "../../../models/attribute";
import {Select, Option} from "../../../shared";
import {useQuery} from "@apollo/client";
import {GET_ALL_ATTRIBUTES_W_EXTANT} from "../../../graphql/queries/attribute-queries";
import {useWorkspaceContext} from "../../../context/workspace-context";

export interface AddPropertyModalProps extends BaseModalProps {
	handleSave: (id: string, action: RuleAction, name?: string, ) => void;
	loading: boolean;
}

type IdWithVals<T> = {
	[key: string]: Option<T>[]
}

const AddPropertyModal = (props: AddPropertyModalProps): ReactElement => {
	const {isOpen, loading, onClose, handleSave} = props;
	const [selectedAttr, setSelectedAttr]  = useState("");
	const [attrValue, setAttrValue] = useState("");
	const [action, setAction] = useState(RuleAction.ADD);
	const {workspace: {id: workspaceId}} = useWorkspaceContext();

	const {data} = useQuery<AttributesWithExtantPageData>(GET_ALL_ATTRIBUTES_W_EXTANT, {variables: {workspaceId}});

	const attributes = data?.attributes?.items;

	const [propertyOptions, propertyValues] = useMemo((): [Option<string>[], IdWithVals<string>] => {
		if (!attributes || attributes.length === 0) return [[], {}];
		const options: Option[] = attributes.map(attr => ({
			text: attr.name,
			value: attr.id,
		}));
		const propValues =
		attributes.reduce((prev: {[key: string]: Option<string>[]}, current) => {
			const extantValues = current.extantValues ?? []
			const values = extantValues.map(value => ({
				text: value,
				value,
			}));
			return {
				...prev,
				[current.id]: values,
			}
		}, {});
		return [options, propValues];
	}, [attributes]);

	const getValues = (propertyId: string): Option[] => {
		let values = propertyValues[propertyId];
		values = Array.isArray(values) ? values : [];

		return values.filter(value => value.text?.length > 0)
	};

	const handleSubmit = (): void => handleSave(selectedAttr, action, attrValue,);

	useEffect(() => {
		if (!isOpen) {
			setSelectedAttr("");
			setAttrValue("");
		}
	}, [isOpen]);

	return (
		<Modal isOpen={isOpen} onClose={onClose} title="Add Property" className={styles.modal}>
			<Body>Choose what property to add to selected user(s)</Body>
			<Select
				id="property-select"
				onChange={setSelectedAttr}
				options={propertyOptions}
				selectedValue={selectedAttr}
				label="Property"
			/>
			{selectedAttr && getValues(selectedAttr).length ?
				<Select
					id="property-value-select"
					onChange={setAttrValue}
					selectedValue={attrValue}
					options={getValues(selectedAttr)}
					label="Value"
				/> : <Body>No values for selected property</Body>
			}
			<RadioGroup
				onChange={setAction}
				value={action}
				className={styles.radioGroup}
			>
				<RadioGroup.Option value={RuleAction.ADD} label="Add value"/>
				<RadioGroup.Option value={RuleAction.SET} label="Replace value" />
			</RadioGroup>
			<Button className={styles.button} disabled={!selectedAttr || loading} onClick={handleSubmit}>
				Add
			</Button>
		</Modal>
	);
};

export {AddPropertyModal};
