import {
	AlignFormat,
	BlockFormat,
	MarkFormat,
	isAlignActive,
	isBlockActive,
	isMarkActive,
	toggleAlign,
	toggleBlock,
	toggleMark,
} from "./editor-helpers";
import {Icon, IconTypes} from "../icon";
import React, {ReactElement} from "react";
import styles from "./toolbar-styles.module.scss";
import {useSlate} from "slate-react";
import classNames from "classnames";

export interface MarkButtonProps {
	/**
	 * The format we want to apply.
	 */
	format: MarkFormat;
	/**
	 * The icon name
	 */
	icon: IconTypes;
}
/**
 * @param format The format we are applying when clicking
 * @param icon The icon name to display.
 */
const MarkButton = (props: MarkButtonProps): ReactElement => {
	const editor = useSlate();
	const {format, icon} = props;
	return (
		<span
			onMouseDown={event => {
				event.preventDefault();
				toggleMark(editor, format);
			}}
			className={[
				styles.button,
				isMarkActive(editor, format) ? styles.active : undefined,
			].join(" ")}
		>
			<Icon name={icon} size="extrasmall" fill={
				isMarkActive(editor, format) ? "var(--batterii-purple)" : "black"
			}/>
		</span>
	);
};

export interface BlockButtonProps {
	/**
	 * The format we want to achieve
	 */
	format: BlockFormat;
	/**
	 * Icon of the button
	 */
	icon: IconTypes;
}
/**
 * Block button triggers actions like lists or headers
 * @param format Format we are toggling
 * @param icon Icon for the button
 */
const BlockButton = (props: BlockButtonProps): ReactElement => {
	const editor = useSlate();
	const {format, icon} = props;
	return (
		<span
			className={[
				styles.button,
				isBlockActive(editor, format) ? styles.active : undefined,
			].join(" ")}
			onMouseDown={event => {
				event.preventDefault();
				toggleBlock(editor, format);
			}}
		>
			<Icon name={icon} size="extrasmall" fill={
				isBlockActive(editor, format) ? "var(--batterii-purple)" : "black"
			}/>
		</span>
	);
};

export interface AlignButtonProps {
	/**
	 * The format we want to achieve
	 */
	format: AlignFormat;
	/**
	 * Icon of the button
	 */
	icon: IconTypes;
}
/**
 * Block button triggers actions like lists or headers
 * @param format Format we are toggling
 * @param icon Icon for the button
 */
const AlignButton = (props: AlignButtonProps): ReactElement => {
	const editor = useSlate();
	const {format, icon} = props;
	return (
		<span
			className={[
				styles.button,
				isAlignActive(editor, format) ? styles.active : undefined,
			].join(" ")}
			onMouseDown={event => {
				event.preventDefault();
				toggleAlign(editor, format);
			}}
		>
			<Icon name={icon} size="extrasmall" fill={
				isAlignActive(editor, format) ? "var(--batterii-purple)" : "black"
			}/>
		</span>
	);
};

export interface RichToolbarProps {
	toolbarPosition?: "left" | "right"
}
/**
 * Toolbar for the rich text editor.
 * We may want to allow more customization of the buttons available in the future,
 * but right now we always want the same ones.
 */
const RichToolbar = ({toolbarPosition = "left"}: RichToolbarProps): ReactElement => {
	return (
		<div className={classNames(styles.toolbar, styles[toolbarPosition])} >
			<MarkButton format="bold" icon="bold"/>
			<MarkButton format="italic" icon="italic"/>
			<MarkButton format="under" icon="under"/>
			<BlockButton format="numbered-list" icon="numbered-list"/>
			<BlockButton format="unordered-list" icon="bulleted-list"/>
			<BlockButton format="paragraph" icon="paragraph"/>
			<BlockButton format="heading-one" icon="header-one"/>
			<BlockButton format="heading-two" icon="header-two"/>
			<BlockButton format="heading-three" icon="header-three"/>
			<AlignButton format="left" icon="align-left" />
			<AlignButton format="center" icon="align-center" />
			<AlignButton format="right" icon="align-right" />
		</div>
	);
};

export {RichToolbar};
