import React, {ReactElement} from "react";
import {AreaChart, Area, ResponsiveContainer, Tooltip} from "recharts";

import {Icon, formatDate} from "../../../shared";
import {Historical} from "../../../models/workspace";
import {Body, Header, Subheader} from "../../../shared/v2/typography";

import styles from "./activity-charts.module.scss";

interface CardProps {
	title: string;
	units?: string;
	value: Historical;
	factor?: number;
}

const Card = ({
	title,
	units = title.toLowerCase(),
	value: {thisMonth, older, lastMonth},
	factor = 1,
}: CardProps): ReactElement => {
	const current30 = thisMonth.reduce((acc, cur) => acc + cur, 0);
	const change = Math.round(((current30 / lastMonth) - 1) * 100);
	const offset = older + lastMonth;

	let acc = 0;
	const graphData = thisMonth.map((v, i) => {
		acc += v;
		const date = new Date();
		date.setDate(date.getDate() - (29 - i));
		return {
			uv: acc,
			name: formatDate(date),
		};
	});

	return (
		<div className={styles.card}>
			<Subheader className={styles.title} size="l">{title}</Subheader>
			{ }
			<Header className={styles.value} size="l">
				{Math.round((current30 + offset) * factor).toLocaleString()}
			</Header>
			<Body className={styles.thisMonth} size="s" color="text-secondary">
				{Math.round(current30 * factor).toLocaleString()} {units} in the last 30 days
			</Body>
			<Body color="text-secondary" className={styles.change} size="xs">
				{lastMonth ? change === 0 ? <>
					<Icon name="change-no" />
					No change vs. prior 30 days
				</> : <>
					<Icon name={change > 0 ? "change-up" : "change-down"} />
					{Math.abs(change)}% vs. prior 30 days
				</> : <></>}
			</Body>
			<ResponsiveContainer width="100%" height={50}>
				<AreaChart data={graphData}>
					<defs>
						<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
							<stop offset="0%" stopColor="var(--batterii-purple)" />
							<stop offset="100%" stopColor="var(--batterii-white)" stopOpacity={0}/>
						</linearGradient>
					</defs>
					<Tooltip<number, string>
						content={({active, payload}) => {
							if (!active || !payload?.length) return null;

							return (
								<div className={styles.tooltip}>
									<div>{payload[0].payload.name}</div>
									{ }
									<div>{Math.round(((payload[0].value ?? 0) + offset) * factor).toLocaleString()}</div>
								</div>
							);
						}}
					/>
					<Area
						type="monotone"
						dataKey="uv"
						stroke="var(--batterii-purple)"
						fillOpacity={0.5}
						fill="url(#colorUv)"
					/>
				</AreaChart>
			</ResponsiveContainer>
		</div>
	);
};

export interface ActivityChartsProps {
	data: {
		contactCount: Historical;
		answerDuration: Historical;
		answerCount: Historical;
	}
}

const ActivityCharts = ({data}: ActivityChartsProps): ReactElement =>
	<div className={styles.container}>
		<Card
			title="Creators"
			value={data.contactCount}
		/>
		<Card
			title="Engagements"
			units="responses"
			value={data.answerCount}
		/>
		<Card
			title="Insights"
			units="video minutes"
			value={data.answerDuration}
			factor={1 / 60000}
		/>
	</div>;

export {ActivityCharts};
