import React, {createRef, ReactElement, ReactNode, useCallback, useState} from "react";

import {Body} from "../../typography";
import {Tooltip} from "..";
import {useObserver} from "../../../../hooks/useObserver";

import styles from "./overflow-tooltip.module.scss";

// Checks if the text is overflowing the element or its children
const isTextOverflowing = (element: HTMLElement): boolean => {
	const children = Array.from(element.childNodes);

	if (!children.length) {
		return false;
	}

	const textNodes = children.filter((child) => child.nodeType === Node.TEXT_NODE);

	if (textNodes.length) {
		return (
			(element.scrollWidth > element.clientWidth) ||
      (element.scrollHeight > element.clientHeight)
		);
	}

	return children.some((child) => isTextOverflowing(child as HTMLElement));
}

export type OverflowTooltipProps = {
  content: string;
} | {
  children: ReactNode
};

export const OverflowTooltip = (props: OverflowTooltipProps): ReactElement => {
	const content = "content" in props ? props.content : props.children;
	const ref = createRef<HTMLDivElement>();

	const [isOverflowing, setIsOverflowing] = useState(false);

	const handleResize = useCallback((entries: ResizeObserverEntry[]) => {
		const {target} = entries[0];
		setIsOverflowing(isTextOverflowing(target as HTMLElement));
	}, [setIsOverflowing]);

	useObserver(ref, ResizeObserver, handleResize);

	const renderContent = () => {
		if (isOverflowing) {
			return (
				<Tooltip
					content={
						<Body size="s">
							{content}
						</Body>
					}
					containerClassname={styles.tooltip}
				>
					<>{content}</>
				</Tooltip>
			);
		}

		return content;
	}

	return (
		<div ref={ref} className={styles.overflowToolitpWrapper}>
			{renderContent()}
		</div>
	);
}
