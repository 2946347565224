import React, {ReactElement} from "react";

import {GroundingBase} from "./grounding-base";
import {QuestionSummaryGrounding} from "../../../../../models/ai-model";

export interface QuestionGroundingProps {
  position: number;
  entry: QuestionSummaryGrounding;
}

const QuestionGrounding = ({entry, position}: QuestionGroundingProps): ReactElement => {
	const {questionId, questionText, surveyId} = entry.questionSummary;

	return (
		<GroundingBase
			links={[{to: `/survey/${surveyId}/results/${questionId}`, text: questionText}]}
			position={position}
			label="Question"
		/>
	);
}

export default QuestionGrounding;
