import React, {ReactElement} from "react";
import {Dropdown, DropdownOptions} from "../../../shared";
import {useDropdown} from "../../../hooks";
import styles from "./actions-button.module.scss";
import {Button} from "../../../shared/v2";
import {ChevronDownIcon} from "../../../icons";

export interface ActionsButtonProps {
	text: string;
	options: DropdownOptions[];
	className?: string;
}

const ActionsButton = ({text, options, className}: ActionsButtonProps): ReactElement => {
	const {anchorEl, handleClick, handleClose} = useDropdown();
	return (
		<>
			<Button onClick={handleClick} className={className} variant="outlined" rightIcon={<ChevronDownIcon />}>
				{text}
			</Button>
			<Dropdown anchorEl={anchorEl} closeMenu={handleClose} height={(options.length * 28) + 10}>
				<div className={styles.container}>
					{options.map(({name, icon, actionOptions}) => (
						<Dropdown.Item
							key={name} options={actionOptions}
							icon={icon} iconFill="var(--color-text-body)">
							{name}
						</Dropdown.Item>
					))}
				</div>
			</Dropdown>
		</>
	);
};

export {ActionsButton};
