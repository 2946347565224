import React, {ReactElement, useEffect, useState} from "react";
import {BaseModalProps, Button, Input, Modal} from "../../shared/v2";

import styles from "./attribute-creator.module.scss";

export interface AttributeCreatorModalProps extends BaseModalProps {
	handleCreate: (name: string) => void;
}

const AttributeCreatorModal = (props: AttributeCreatorModalProps): ReactElement => {
	const {isOpen, onClose, handleCreate} = props;
	const [name, setName] = useState("");

	const createAttr = (): void => {
		handleCreate(name);
		onClose();
	};

	useEffect(() => {
		if (!isOpen) setName("");
	}, [isOpen]);
	return (
		<Modal isOpen={isOpen} onClose={onClose} title="Create Custom Property">
			<div className={styles.inputs}>
				<Input label="Name" id="attribute-name" value={name} onChange={setName} />
			</div>
			<div className={styles.actions}>
				<Button disabled={!name} onClick={createAttr}>Save</Button>
			</div>
		</Modal>
	);
};

export {AttributeCreatorModal};
