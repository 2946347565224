
import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./status-indicator.module.scss";

const bStyles = classNames.bind(styles);

export interface StatusIndicatorProps {
  active?: boolean;
  hovered?: boolean;
}

export const StatusIndicator = ({active, hovered}: StatusIndicatorProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	return <div className={bStyles("outerCircle", {hovered, isDarkMode})}>
		<div className={bStyles("innerCircle", {active, hovered, isDarkMode})}>
		</div>
	</div>
}
