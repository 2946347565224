import {Heading, Icon} from "../../../shared";
import React, {ReactElement} from "react";
import styles from "./clips-empty.module.scss";

const ClipsEmpty = (): ReactElement =>
	<div className={styles.container}>
		<section className={styles.info}>
			<Icon name="play-plus" size="extralarge" className={styles["big-icon"]}/>
			<Heading size="lg">Start building your Reel</Heading>
			<p>
				Head over to your Vurvey results page and look for this symbol
				<Icon name="play-plus" className={styles.purple} size="medium"/> to add
				clips to a Reel. Then come back and edit the clips into a compilation.
			</p>
		</section>
	</div>;

export {ClipsEmpty};
