import React, {ReactElement, useMemo} from "react";
import {useQuery} from "@apollo/client";
import {useIntercom} from "react-use-intercom";
import LogRocket from "logrocket";

import {DisconnectedPage} from "../shared/layout/disconnected";
import {GET_USER_INFO} from "../graphql/queries/queries";
import {LoadingPage} from "../shared/v2";
import {Me} from "../models";
import {Workspace} from "../models/workspace";
import {UserRoles} from "../models/user";
import {WelcomePage} from "../welcome-page";

type UserContextProps = {
	user: Me;
	isEnterpriseManagerOrSupport: boolean;
	workspaces: Workspace[];
  currentWorkspaceId: string;
};
const UserContext = React.createContext<UserContextProps>({
	user: {
		id: "",
		firstName: "",
		email: "",
		experimentalFeatures: false,
		permissions: null,
		hasCreatedTrial: null,
		role: UserRoles.REGULAR_USER,
	},
	isEnterpriseManagerOrSupport: false,
	workspaces: [],
	currentWorkspaceId: "fake",
});

// const UserContext = React.createContext({});

const UserContextProvider = (props: {children: ReactElement}): ReactElement => {
	const {loading: workspacesLoading, data, error} = useQuery(GET_USER_INFO);
	const {boot} = useIntercom();
	const isEnterpriseManagerOrSupport = useMemo(() => {
		if (!data?.me) {
			return false;
		}

		return data.me.role === UserRoles.ENTERPRISE_MANAGER ||
			data.me.role === UserRoles.SUPPORT;
	}, [data?.me?.role]);

	if (workspacesLoading) return <LoadingPage />;
	if (data) {
		if (import.meta.env.REACT_APP_STAGE !== "development") {
			const workspaces = data.myWorkspaces?.items;
			const intercomCompanies = workspaces.map(workspace => {
				return {name: workspace.name, companyId: workspace.id, plan: workspace.plan};
			});
			if (data.me.role === "REGULAR_USER") {
				boot({
					userId: data.me.id,
					userHash: data.intercomHash,
					name: data.me.name,
					email: data.me.email,
					companies: intercomCompanies,
				});
			}
			const userIdentity = {
				userId: data.me.id,
				name: data.me.firstName + " " + data.me.lastName,
				email: data.me.email,
			};
			LogRocket.identify(userIdentity.email, userIdentity);
		}

		const storedWorkspaceId = localStorage.getItem("workspaceId");
		let currentWorkspaceId: string = data.myWorkspaces.items[0]?.id;

		if (storedWorkspaceId && data.myWorkspaces.items.some(workspace => workspace.id === storedWorkspaceId)) {
			currentWorkspaceId = storedWorkspaceId;
		}

		if (!currentWorkspaceId) {
			return <WelcomePage />;
		}

		return (
			<UserContext.Provider
				value={{
					user: data.me,
					workspaces: data.myWorkspaces.items,
					isEnterpriseManagerOrSupport,
					currentWorkspaceId,
				}}
			>
				{props.children}
			</UserContext.Provider>
		);
	}
	if (error) throw new Error(error.message);
	return <DisconnectedPage />;
};

const useUserContext = (): UserContextProps => {
	const context = React.useContext(UserContext);
	if (context === undefined) {
		throw new Error("useUserContext must be used within a UserContextProvider");
	}
	return context;
};

export {UserContext, useUserContext, UserContextProvider};
