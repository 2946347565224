import {useEffect, useState} from "react";

import {useChatConversationContext, useChatMessagesContext, useChatSendQuestionContext} from "../../../context/chat-contexts";
import {useChatListInitialPopulate} from "../common";

export const useChatWithPolling = (): void => {
	const {isSendingQuestion} = useChatSendQuestionContext();
	const [isInitial, setIsInitial] = useState(true);
	const {refetch: refetchConversation} = useChatConversationContext();
	const {setMessages} = useChatMessagesContext();

	useChatListInitialPopulate();

	useEffect(() => {
		if (isInitial) {
			setIsInitial(false);
			return;
		}

		if (isSendingQuestion) {
			return;
		}

		refetchConversation().then(result => {
			const messages = result?.messages || [];

			if (messages.length > 0) {
				setMessages(messages);
			}
		})
	}, [isSendingQuestion]);
};
