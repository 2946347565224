import {useEffect, useMemo, useState} from "react";
import {validate, ValidationError, Validations} from "../shared/utility/validate";

export type UseValidationResults<T> = Record<keyof T, UseValidationResult>

export interface UseValidationResult {
  isError: boolean;
  errors: ValidationError[];
}

export const useValidation = <T extends object>(object: T, validations: Validations<T>) => {
	const [errors, setErrors] = useState<ValidationError[]>([]);

	const results = useMemo(
		() => Object
			.keys(object)
			.reduce(
				(acc, field) => {
					const fieldErrors = errors.filter(error => error.field === field);
					return {
						...acc,
						[field]: {
							isError: fieldErrors.length > 0,
							errors: fieldErrors,
						}
					}
				},
        {} as UseValidationResults<T>,
			),
		[errors]
	);

	// Clear errors when value that caused error is changed
	useEffect(
		() => setErrors(errors.filter(error => error.value === object[error.field])),
		[object],
	);

	return {
		results,
		validate: () => {
			const {isValid, errors} = validate(object, validations);
			setErrors(errors);
			return isValid;
		},
		isValid: errors.length === 0,
		pushError: (error: ValidationError) => setErrors([...errors, error]),
		clearErrors: () => setErrors([]),
	}
}
