import React, {ReactElement} from "react";
import {CssSpinner} from "../css-spinner";
import {Icon} from "../icon";
import {MediaState} from "../../../hooks/useMediaUpload";
import classnames from "classnames/bind";
import styles from "./image-input.module.scss";
import {Button} from "../../../shared/v2";

const bStyles = classnames.bind(styles);

interface AllProps {
	id: string;
	isProcessing: boolean;
	media: MediaState;
	label?: string;
	onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onDelete?: any;
}

interface QuestionProps extends AllProps {
	type: "question";
	hasError: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	save: () => Promise<any>;
}

interface ProfileProps extends AllProps {
	type: "profile";
}

export type ImageInputProps = QuestionProps | ProfileProps;

const ImageInput = (props: ImageInputProps): ReactElement => {
	const {id, label, isProcessing, media, type, onChange} = props;
	const {hasError, save} = props as QuestionProps;
	const fileType = media?.type ?? "image";
	let labelDiv: JSX.Element | null = null;
	if (label) {
		labelDiv = <div>{label}</div>;
	}

	const divStyles = bStyles({
		container: true,
		overlay: isProcessing,
		"image-input": !isProcessing && !media?.preview,
		question: type === "question",
	});

	const uploader = (
		<label htmlFor={`image-upload-${id}`}>
			<div className={divStyles}>
				{labelDiv}
				{isProcessing	? (
					<CssSpinner className={styles.spinner} text="Processing"/>
				) : media?.preview ? (
					fileType === "image" ? (
						<img
							src={media?.preview}
							alt="Image preview"
							className={styles.image}
						/>
					)	: <video
						src={media?.preview}
						controls
						className={styles.video}
					/>
				) : <>
					<Icon name="plus" size="medium" fill="var(--batterii-blue)"/>
					{
						type === "question" && <span className={styles["help-text"]}>
							Upload an image (JPG, GIF, PNG) or video (MOV, MP4, MPEG4, AVI)
						</span>
					}
					<input
						style={{display: "none"}}
						id={`image-upload-${id}`}
						type="file"
						onChange={onChange}
					/>
				</>}
			</div>
		</label>
	);

	if (type === "profile") return uploader;
	return (
		<div className={styles.outerContainer}>
			<div className={styles.image}>{uploader}</div>
			{hasError && <p className={styles.error}>Format not accepted</p>}
			<div className={styles.actions}>
				<Button
					onClick={save}
					disabled={isProcessing || !media.preview}
				>
					Save
				</Button>
			</div>
		</div>
	);
};

export {ImageInput};
