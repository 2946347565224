/* eslint-disable @typescript-eslint/no-explicit-any*/

import React, {useCallback} from "react";
import {
	useQuery,
	OperationVariables,
	DocumentNode,
	TypedDocumentNode,
	QueryHookOptions,
	QueryResult,
	NetworkStatus,
} from "@apollo/client";
import classnames, {Argument} from "classnames";

import {Spinner} from "../shared/v2";

import styles from "./hookStyles.module.scss";


export interface LoadingQueryResult<TData = any, TVariables extends OperationVariables = OperationVariables>
	extends QueryResult<TData, TVariables> {
	fragment: React.ReactElement | null;
	fetchMoreFragment: React.ReactElement | null;
	handleScroll: (e: React.UIEvent<HTMLElement>) => void;
	handleFetchMore: (limit: number) => void;
}


export interface LoadingQueryHookOptions<TData = any, TVariables extends OperationVariables = OperationVariables>
	extends QueryHookOptions<TData, TVariables> {
	loadingClassName?: Argument;
	fetchMoreClassName?: Argument;
	display?: "horizontal" | "vertical";
	errorClassName?: Argument;
	what?: string;
}

function useLoadingQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(
	query: DocumentNode | TypedDocumentNode<TData, TVariables>,
	options?: LoadingQueryHookOptions<TData, TVariables>,
): LoadingQueryResult<TData, TVariables> {
	const ret = useQuery<TData, TVariables>(query, options);

	const {what, loadingClassName, errorClassName, display = "horzontal", fetchMoreClassName} = options ?? {};
	const text = what ? ` ${what}` : "";
	const modeStyles = {
		[styles.loading]: true,
		[styles.vertical]: display === "vertical",
	};


	const fragment = ret.networkStatus === NetworkStatus.loading || ret.networkStatus === NetworkStatus.setVariables ? (
		<div className={classnames(modeStyles, loadingClassName)}>
			<Spinner />
		</div>
	) : ret.networkStatus === NetworkStatus.error ? (
		<div className={classnames(modeStyles, errorClassName)}>
			Could not load{text}: {ret.error?.message}
		</div>
	) : null;
	// Want it separate so we can continue showing old info without page "refresh"
	const fetchMoreFragment = ret.networkStatus === NetworkStatus.fetchMore ? (
		<div className={classnames(modeStyles, fetchMoreClassName)}>
			<Spinner />
		</div>
	) : null;

	const handleScroll = useCallback((e: React.UIEvent<HTMLElement>): void => {
		if (
			e.currentTarget.scrollTop + e.currentTarget.clientHeight <
			e.currentTarget.scrollHeight
		) return;
		if (ret.networkStatus === NetworkStatus.fetchMore) return;
		if (!ret.data || ret.data[Object.keys(ret.data)[0]].remaining <= 0) return;
		ret.fetchMore({
			variables: {...options?.variables, cursor: ret.data[Object.keys(ret.data)[0]].cursor},
		});
	}, [ret]);

	const handleFetchMore = useCallback((limit: number): void => {
		if (ret.networkStatus === NetworkStatus.fetchMore) return;
		if (!ret.data || ret.data[Object.keys(ret.data)[0]].remaining <= 0) return;
		ret.fetchMore({
			variables: {...options?.variables, cursor: ret.data[Object.keys(ret.data)[0]].cursor, limit},
		});
	}, [ret]);

	return {...ret, fragment, fetchMoreFragment, handleScroll, handleFetchMore};
}

export {useLoadingQuery};
