import {ReactElement} from "react";

export enum TextToHTMLPatternReplacerOption {
  InsertMention = "insert-mention",
  Mention = "mention",
  Command = "command",
}

export interface PatternProps {
  render: (text: string) => ReactElement;
}

export interface ReplacerFunc<T extends PatternProps> {
  (props: T & {text: string}): (string | ReactElement)[];
}

const RESULT_WRAPPER = "<<!4fd4b1f1-6497-4061-9912-6e32c6de2efc>>";
const RESULT_TAG = "<<!ec5dd6df-06ec-45ec-841a-7d52599eb7ab>>";

export const markResult = (text: string) => {
	return `${RESULT_WRAPPER}${RESULT_TAG}${text}${RESULT_WRAPPER}`;
};

export const splitTaggedResultIntoChunks = (text: string, render: (text: string) => ReactElement) => {
	const chunks = text.split(RESULT_WRAPPER);

	return chunks.map(chunk => {
		if (!chunk.includes(RESULT_TAG)) {
			return chunk;
		}

		chunk = chunk.replace(RESULT_TAG, "");

		return render(chunk);
	});
}
