/**
 * These don't necessarily all directly mutate a brand, but they are directly
 * related to the "branding" pages.
 */

import {gql} from "@apollo/client";

export const CREATE_FEEDBACK_QUESTION = gql`
	mutation CreateVideoQuestion($surveyId: GUID!) {
		createQuestion(input: {
			surveyId: $surveyId
			type: TEXT
			subtype: "long"
			rank: false
			text: "UNTITLED QUESTION"
			videoResponse: true
		}) {
			id
			text
			type
			videoResponse
		}
	}
`;

