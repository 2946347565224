
import {Icon, UploadButton} from "../../shared";
import React, {ReactElement} from "react";
import {UPDATE_QUESTION} from "../../graphql/mutations/survey-mutations";
import styles from "./ar-upload.module.scss";
import {useModelUpload} from "../../hooks";
import {useMutation} from "@apollo/client";
import {Button, Spinner, Modal, BaseModalProps} from "../../shared/v2";

export interface ARUploadModalProps extends BaseModalProps {
	questionId: string;
}

const ARUploadModal = (props: ARUploadModalProps): ReactElement => {
	const {isOpen, questionId, onClose} = props;
	const {
		clearMedia,
		media,
		handleUSDZChange,
		handleGLBChange,
		handleSave,
		isProcessing,
	} = useModelUpload();
	const [updateQuestion] = useMutation(UPDATE_QUESTION, {
		onCompleted: () => {
			clearMedia();
			onClose();
		},
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const save = (): Promise<any> => handleSave().then(arModelId => updateQuestion({
		variables: {id: questionId, changes: {arModelId}},
	}));

	const close = (): void => {
		clearMedia();
		onClose();
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={close}
			size="medium"
			title="Add AR Model"
		>
			<div className={styles.container}>
				<div className={styles.left}>
					<UploadButton
						emptyText="Upload USDZ"
						file={media.usdzRaw}
						onRemove={clearMedia}
						onUpload={handleUSDZChange}
						fullSize
						accept="model/vnd.usdz+zip"
					/>
					<UploadButton
						emptyText="Upload GLB"
						file={media.glbRaw}
						onRemove={clearMedia}
						onUpload={handleGLBChange}
						fullSize
						accept="model/gltf-binary"
					/>
					<p>
            For 3D/AR viewing, both a .USDZ and .GLB file are required.
            For help preparing your files, check out this resource:
					</p>
					<a
						href="https://help.vurvey.com/en/articles/7183906-augmented-reality-3d-file-type-quick-workflow"
						target="_blank"
						rel="noreferrer"
					>Preparing AR Files</a>
				</div>
				<div className={styles.right}>
					{media.usdzPreview && media.glbPreview ? (
						<model-viewer
							style={{
								width: "100%",
								height: 240,
								border: "1px dashed var(--batterii-grey-light)",
							}}
							src={media?.glbPreview}
							ios-src={media?.usdzPreview}
							alt="A 3D model"
							auto-rotate
							camera-controls
							background-color="#ffffff"
							ar
							magic-leap
							unstable-webxr
						/>
					) : (
						<div className={styles.preview}>
							<Icon name="box" size="medium" />
              Preview
						</div>
					)}
					{isProcessing
						? <Spinner />
						: <Button
							onClick={save}
							disabled={isProcessing || !media.usdzPreview || !media.glbPreview}
						>
              Save
						</Button>
					}

				</div>

			</div>
		</Modal>
	);
};


export {ARUploadModal};
