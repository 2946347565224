/* eslint-disable react/prop-types */
import React, {ReactElement, useCallback, useContext, useMemo, useState} from "react";
import styles from "./brand-reels.module.scss";
import {EmptyScreen} from "../../../contacts/components/empty-screen";
import {AddReelModal} from "../../../modals/add-reel";
import {DeleteConfirmModal} from "../../../modals/delete-reel";
import {
	Options,
	convertToClockTime,
} from "../../../shared";
import {Table} from "../../../shared/components/table";
import {GET_ALL_REELS} from "../../../graphql/queries/reel-queries";
import {useLoadingQuery} from "../../../hooks";
import {
	CopiedReel,
	CopyReelVars,
	DeleteReelVars,
	DeletedReel,
	Reel,
	ReelVideoStatus,
	ReelsPageData,
} from "../../../models/reels";
import {useWorkspaceContext} from "../../../context/workspace-context";
import TimeAgo from "react-timeago";
import {Column} from "react-table";
import {ReelLink} from "../../../reels/components/reel-link";
import {AlignText} from "../../../shared/typography/align-text";
import {useMutation} from "@apollo/client";
import {REEL_FRAGMENT} from "../../../graphql/fragments/fragments";
import {DELETE_REEL, DUPLICATE_REEL} from "../../../graphql/mutations/reel-mutations";
import {
	updateCacheAddPageItem,
	updateCacheDeletePageItem,
} from "../../../shared/utility/update-cache";
import {ToastContext} from "../../../context/toast-context";
import {Link} from "../../../route";
import {Body} from "../../../shared/v2/typography";
import {reelStatusSort} from "../../../shared/utility/utility";
import {DebounceSearch} from "../../../shared/v2/inputs";

const BrandReels = (): ReactElement => {
	const [reelId, setReelId] = useState("");
	const [search, setSearch] = useState("");
	const [showModal, setShowModal] = useState(false);
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const {updateToast} = useContext(ToastContext);

	const {data, fragment} = useLoadingQuery<ReelsPageData>(GET_ALL_REELS, {
		variables: {filter: {workspaceId, sharedInBrand: true}},
		errorPolicy: "all",
		fetchPolicy: "cache-and-network",
		nextFetchPolicy: "cache-and-network",
	});

	const handleToggleDeleteModal = (): void => setReelId("");

	const [deleteReel] = useMutation<DeletedReel, DeleteReelVars>(DELETE_REEL, {
		onCompleted: () => updateToast({description: "Reel deleted", type: "informational"}),
	});
	const [copyReel] = useMutation<CopiedReel, CopyReelVars>(DUPLICATE_REEL, {
		onCompleted: () => updateToast({description: "Reel copied", type: "informational"}),
	});

	/**
 * Delete Reel callback. Handles calling the deleteReel mutation
 * @param id ID of reel we want to delete
 */
	const handleDeleteReel = (): void => {
		deleteReel({
			variables: {id: reelId},
			update(cache, {data: deleteData}) {
				if (deleteData) {
					updateCacheDeletePageItem(cache, "reels", "reel", deleteData.deleteReel.id);
				}
			},
		});
		handleToggleDeleteModal();
	};
	/**
	 * Calls mutation to copy a reel. Will be named "Copy of XXX"
	 */
	const handleDuplicateReel = (id: string): void => {
		copyReel({
			variables: {id},
			update(cache, {data: reel}) {
				if (reel) {
					const copiedRef = cache.writeFragment({
						data: reel.duplicateReel,
						fragment: REEL_FRAGMENT,
					});
					updateCacheAddPageItem(cache, copiedRef, "reels", reel.duplicateReel.id);
				}
			},
		});
	};
	/**
	 * Displays the status of a reel
	 */
	const handleReelStatus = useCallback((status: ReelVideoStatus): ReactElement => {
		let text;
		switch (status) {
		case ReelVideoStatus.CREATED:
			text = <>Published</>;
			break;
		case ReelVideoStatus.DIRTY:
			text = <>Unpublished<br/>Changes</>;
			break;
		case ReelVideoStatus.FAILED:
			text = <>Failed</>;
			break;
		case ReelVideoStatus.PENDING:
			text = <>Processing</>;
			break;
		case ReelVideoStatus.UNPUBLISHED:
			text = <>Draft</>;
			break;
		}
		return <span>{text}</span>;
	}, []);

	// Column setup
	const columns = useMemo((): Column<Reel>[] => (
		[
			{
				Header: "Name",
				accessor: "name",
				width: 400,
				Cell: ({row: {original: reel}}) => <ReelLink reel={reel}/>,
			},
			{
				Header: "Length",
				accessor: "duration",
				Cell: ({value}) => <Body size="s">
					{convertToClockTime(value as number, "milliseconds", "written")}
				</Body>,
			},
			{
				Header: "Status",
				accessor: "videoStatus",
				sortType: reelStatusSort,
				Cell: ({value}) => <Body size="s">{handleReelStatus(value)}</Body>,
			},
			{
				Header: "Last updated",
				accessor: "createdAt",
				Cell: ({value}) => <Body size="s"><TimeAgo date={value as Date} live={false}/></Body>,
			},
			{
				id: "Options",
				Header: "",
				disableSortBy: true,
				Cell: ({row: {original: reel}}) => <AlignText align="right">
					<Options
						type="menu-vertical"
						options={[{
							name: "Copy",
							actionOptions: {onClick: () => handleDuplicateReel(reel.id)},
							icon: "copy",
							iconFill: "var(--color-text-body)",
						},
						{
							name: "Delete",
							actionOptions: {onClick: () => setReelId(reel.id)},
							icon: "trash",
							iconFill: "var(--color-text-body)",

						}]}
					/>
				</AlignText>,
			},
		]
	), []);

	return (
		<div className={styles.container}>
			{
				fragment || (data && data.reels.items.length > 0 ? <>
					<DebounceSearch
						value={search}
						onChange={setSearch}
						className={styles.search}
						placeholder="Search Reels"
					/>
					<Table
						columns={columns}
						data={data.reels.items}
						searchValue={search}
					/>
				</> : <EmptyScreen whatIsEmpty="REELS_BRAND">
					<Link search to="/reel-dashboard" className={styles.link}>
						Go to reel dashboard
					</Link>
				</EmptyScreen>
				)
			}
			<AddReelModal
				isOpen={showModal}
				onClose={() => setShowModal(false)}
				workspaceId={workspaceId}
			/>
			<DeleteConfirmModal
				isOpen={!!reelId}
				onClose={() => setReelId("")}
				handleConfirm={handleDeleteReel}
				warningText="Are you sure you want to delete this reel?"
			/>
		</div>
	);
};

export {BrandReels};
