import {useQuery} from "@apollo/client";
import {useMemo, useState} from "react";
import {GET_MEMBERS_NO_EMAIL} from "../graphql/queries/queries";
import {WorkspaceMemberReturn} from "../models/user";
import {Option} from "../shared";
import {Setter} from "../types";

export interface UseEmailHookReturn {
	from: string;
	message: string;
	jobTitle: string;
	selectOptions: Option<string | undefined>[];
	setFrom: Setter<string>;
	setMessage: Setter<string>;
	setJobTitle: Setter<string>;
	resetValues: () => void;
}

export const useEmailer = (workspaceId: string, userId: string): UseEmailHookReturn => {
	const [message, setMessage] = useState("");
	const [jobTitle, setJobTitle] = useState("");
	const [from, setFrom] = useState(userId);
	const {data} = useQuery<WorkspaceMemberReturn>(GET_MEMBERS_NO_EMAIL, {
		variables: {workspaceId},
	});

	const selectOptions = useMemo((): Option<string | undefined>[] => {
		if (!data) return [];
		const vals: Option<string | undefined>[] = data.workspaceMembers.items.map(member =>{
			return ({text: `${member.firstName} ${member.lastName}`, value: member.id});
		});
		// ({text: `${member.firstName} ${member.lastName}`, value: member.id}));
		vals.unshift({text: "Anonymous", value: ""});
		return vals;
	}, [data]);

	const resetValues = (): void => {
		setMessage("");
		setJobTitle("");
		setFrom("");
	};

	return {
		from,
		message,
		jobTitle,
		selectOptions,
		setFrom,
		setMessage,
		setJobTitle,
		resetValues,
	};
};
