import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import {AgentForm} from "../../components/agent-form";
import {AgentPreview} from "../../components/agent-preview";
import {AgentTopBar} from "../../components/agent-top-bar";
import {ChatContextsAndHandlers} from "../../../canvas/chat-contexts-and-handlers";
import {Spinner} from "../../../shared/v2";
import {useAgentCreationContext} from "../../../context/agent-creation-context";
import {useMount} from "../../../hooks/useMount";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./create-page.module.scss";

const bStyles = classNames.bind(styles);

export const AgentsCreatePage = (): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {isLoading} = useAgentCreationContext();
	const {
		setScrollTop,
	} = useAgentCreationContext();

	useMount(() => {document.title = "Vurvey - Agents"})

	return (
		<>
			<AgentTopBar />
			<div className={bStyles("agentsCreatePageContainer", {isDarkMode})}>
				<div className={styles.formContainer}  onScroll={(e) => {
					const target = e.target as HTMLDivElement;
					setScrollTop("form", target.scrollTop);
				}}>
					{isLoading ?
						<Spinner /> :
						<AgentForm />
					}
				</div>
				<ChatContextsAndHandlers>
					<AgentPreview />
				</ChatContextsAndHandlers>
			</div>
		</>
	);
};
