import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import styles from "./nav-item.module.scss";
import {NavLink} from "../../../../route";
import {Body} from "../../../../shared/v2/typography";
import {useThemeMode} from "../../../../context/theme-mode-context";

const bStyles = classNames.bind(styles);

export interface NavItemProps {
	to: string;
	icon: ReactElement;
	text: string;
}

export const NavItem = ({
	to,
	icon,
	text,
}: NavItemProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	return (
		<NavLink
			className={bStyles("navItem", {isDarkMode})}
			to={to}
			workspace
			omitSearch
			activeClassName={styles.active}
		>
			<div className={styles.iconWrapper}>{icon}</div>
			<Body className={styles.text} size="s"type="semibold" color="text-secondary">{text}</Body>
		</NavLink>
	);
};
