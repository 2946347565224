import React, {ReactElement} from "react";
import classnames from "classnames/bind";
import styles from "./layout-container.module.scss";
import {Argument} from "classnames";

const bStyles = classnames.bind(styles);

export interface LayoutContainerProps {
	children: React.ReactNode;
	theme?: "light" | "dark";
	className?: Argument;
}

const LayoutContainer = (props: LayoutContainerProps): ReactElement => {
	const {
		children,
		theme = "light",
		className,
	} = props;

	const pageClasses = bStyles(
		"container",
		theme,
		className,
	);

	return 	<div className={pageClasses}>
		{children}
	</div>;
};

export {LayoutContainer};
