import React, {ReactElement} from "react";

import {BaseModalProps, Modal} from "../modal";
import {Button} from "../../../../shared/v2";

import styles from "./confirm-exit-modal.module.scss";

export interface ConfirmActionModalProps extends BaseModalProps {
	title: string;
	description: string;
	onConfirm: () => void;
  confirmText?: string;
  cancelText?: string;
  className?: string;
}

export const ConfirmActionModal = ({
	isOpen,
	onClose,
	onConfirm,
	title,
	description,
	confirmText = "Delete",
	cancelText = "Cancel",
	className,
}: ConfirmActionModalProps): ReactElement => {
	return <Modal
		isOpen={isOpen}
		onClose={onClose}
		title={title}
		description={description}
		className={className}
		size="x-small"
	>
		<div className={styles.footer}>
			<Button
				className={styles.button}
				variant="outlined"
				style="danger"
				onClick={onConfirm}
			>
				{confirmText}
			</Button>

			<Button
				className={styles.button}
				onClick={onClose}
			>
				{cancelText}
			</Button>
		</div>
	</Modal>;
};
