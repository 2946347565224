import React, {ReactElement} from "react";
import classnames, {Argument} from "classnames";
import styles from "./css-spinner.module.scss";


export interface CssSpinnerProps {
	text?: string;
	className?: Argument;
}
const CssSpinner = (props: CssSpinnerProps): ReactElement => <div
	className={classnames(props.className, styles.container)}>
	<div className={styles.loader}/>
	{props.text && <span className={styles.text}>{props.text}</span>}
</div>;

export {CssSpinner};
