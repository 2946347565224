import React, {ReactElement, useContext} from "react";

import {AddReelDropdown} from "../add-reel-dropdown";
import {Icon} from "../../../shared";
import {useDropdown} from "../../../hooks/useDropdown";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {CreateModalContext} from "../../../context/create-modal-context";

import styles from "./reel-adder.module.scss";
import {Setter} from "../../../types";

export interface ReelAdderProps {
	/**
	 * Handles adding clip to a reel
	 */
	addClip: (reelId: string) => void;
	answerId: string;
	handleShowReelModal?: Setter<boolean>;
}

/**
 * Reel adder uses a filter dropdown.
 * @param addClip Callback to handle adding the current video to a reel.
 */
const ReelAdder = (props: ReelAdderProps): ReactElement => {
	const {addClip, answerId, handleShowReelModal} = props;
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const {anchorEl, handleClick, handleClose} = useDropdown();
	const {setSingleAnswerId} = useContext(CreateModalContext);

	// We want to be aware of what the answer id is as soon as we open the dropdown
	const handleOpen = (e: React.MouseEvent<HTMLElement>): void => {
		setSingleAnswerId(answerId);
		handleClick(e);
	};

	return (
		<>
			<Icon
				size="extrasmall"
				name="play-plus"
				clicked={handleOpen}
				className={styles.icon}
			/>
			<AddReelDropdown
				anchorEl={anchorEl}
				workspaceId={workspaceId}
				addClip={addClip}
				closeCallback={handleClose}
				showModal={handleShowReelModal}
			/>
		</>
	);
};

export {ReelAdder};
