import {useEffect, useRef} from "react";
import {usePortalContext} from "../context/portal-context";

export const useMakePortal = <T extends Element>(portalId: string) => {
	const {register, unregister} = usePortalContext();
	const ref = useRef<T>(null);

	useEffect(() => {
		register(portalId, ref);

		return () => {
			unregister(portalId);
		};
	}, [portalId]);

	return ref;
}
