import React, {ReactElement} from "react";
import {Outlet} from "react-router-dom";
import {LayoutContainer} from "../../../shared";
import {Header} from "../../../shared/v2/typography";
import {useMount} from "../../../hooks/useMount";

const BrandingNavigation = (): ReactElement => {
	useMount(() => {document.title = "Vurvey - Branding"})

	return (
		<LayoutContainer theme="dark">
			<Header type="semibold">Branding</Header>
			<Outlet/>
		</LayoutContainer>
	);
};

export {BrandingNavigation};
