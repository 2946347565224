export interface WorkspaceSegment {
	/**
	 * Id of the segment. Should always be included
	 */
	id: string;
	/**
	 * Name. Also always included since this is how we
	 * will display them to the user
	 */
	name: string;
	/**
	 * Amount of contacts in segment. Might not be included, but
	 * often is
	 */
	memberCount?: number;
	/**
	 * Workspace ID. Sometimes needed or useful to include
	 */
	workspaceId?: string;
	createdAt?: Date;
}

export enum SegmentSort {
	DEFAULT = "DEFAULT",
	NAME_ASC = "NAME_ASC",
	NAME_DESC = "NAME_DESC",
	CREATED_AT_ASC = "CREATED_AT_ASC",
	CREATED_AT_DESC = "CREATED_AT_DESC",
	MEMBER_COUNT_ASC = "MEMBER_COUNT_ASC",
	MEMBER_COUNT_DESC = "MEMBER_COUNT_DESC",
}
/**
 * Going to name this better in a sec.
 */
export interface WorkspaceSegmentItems {
	/**
	 * An array of segments
	 */
	items: WorkspaceSegment[];
	remaining: number;
	cursor: string;
}

export interface SegmentData {
	/**
	 * The SegmentItem object
	 */
	segments: WorkspaceSegmentItems;
}

export interface NewSegment {
	workspaceId: string;
	name: string;
}
export interface CreateSegmentVars {
	input: NewSegment
}
export interface CreateSegmentData {
	createSegment: WorkspaceSegment;
}

export interface SegmentUsers {
	segment: WorkspaceSegment;
	users: {id: string}[]; // Only use the id of users to identify them in cache.
}

export interface RemoveSegmentMemberData {
	removeSegmentMembers: SegmentUsers;
}

export interface DeleteSegmentsData {
	deleteSegments: {id: string}[];
}

export interface DuplicateSegmentData {
	duplicateSegment: WorkspaceSegment;
}

export interface UpdateSegmentData {
	updateSegment: WorkspaceSegment;
}

export interface SegmentChanges {
	name: string;	
}

export interface UpdateSegmentVars {
	id: string;
	changes: Partial<SegmentChanges>
}

export interface CombineSegmentsVars {
	ids: string[];
}

export interface CombineSegmentData {
	combineSegments: WorkspaceSegment;
}
