import React, {ReactElement} from "react";
import {useMutation, useQuery} from "@apollo/client";

import {EditWorkflowModal} from "../../modals/edit-workflow-modal";
import {SelectAgentModal} from "../select-agent-modal";
import {SelectSourcesModal} from "../../modals/select-sources-modal";
import {useWorkflowContext} from "../../../context/workflow-contexts";
import {Persona} from "../../../models/persona";
import {AGENTS_PUBLISHED} from "../../../graphql/queries/ai-models-queries";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {AiPersonaTask} from "../../../models/ai-orchestration";
import {UPDATE_AI_ORCHESTRATION} from "../../../graphql/mutations/ai-mutations";

export interface WorkflowModalsProps {
  isEditWorkflowModalOpen: boolean;
  isSelectAgentModalOpen: boolean;
  isSourcesModalOpen: boolean;
  setIsEditWorkflowModalOpen: (isOpen: boolean) => void;
  setIsSelectAgentModalOpen: (isOpen: boolean) => void;
  setIsSourcesModalOpen: (isOpen: boolean) => void;
}

export const WorkflowModals = ({
	isEditWorkflowModalOpen,
	setIsEditWorkflowModalOpen,
	isSelectAgentModalOpen,
	setIsSelectAgentModalOpen,
	isSourcesModalOpen,
	setIsSourcesModalOpen,
}: WorkflowModalsProps): ReactElement => {
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const {workflow, setAgentTasks, agentTasks, currentSources} = useWorkflowContext();

	const {data: agentsData, loading: isLoadingAgents} = useQuery(
		AGENTS_PUBLISHED,
		{
			variables: {workspaceId, version: "THUMBNAIL", orchestrationId: workflow?.id},
			skip: !workspaceId || !workflow?.id,
			fetchPolicy: "cache-first",
		}
	);

	const [updateWorkflow] = useMutation(UPDATE_AI_ORCHESTRATION);

	const handleAddAgentTask = (persona: Persona) => {
		const agentTask: AiPersonaTask = {
			persona: persona,
			index: agentTasks.length,
			processingState: "idle",
			output: "",
			operation: "CREATE",
			id: `NEW-${new Date().getTime()}`,
			isNew: true,
			task: {
				taskPrompt: "",
			}
		}

		setAgentTasks([...agentTasks, agentTask]);
		setIsSelectAgentModalOpen(false);
	}

	const handleSaveSources = (surveyIds: string[], trainingSetIds: string[]) => {
		updateWorkflow({
			variables: {
				id: workflow && workflow.id,
				trainingSetIds,
				surveyIds
			}
		})
	}

	return <>
		{isEditWorkflowModalOpen && (
			<EditWorkflowModal
				workflow={workflow}
				isOpen={isEditWorkflowModalOpen}
				onClose={() => setIsEditWorkflowModalOpen(false)}
			/>
		)}
		<SelectAgentModal
			isOpen={isSelectAgentModalOpen}
			onClose={() => setIsSelectAgentModalOpen(false)}
			onSelect={handleAddAgentTask}
			agents={agentsData?.publishedAgents || []}
			isLoadingAgents={isLoadingAgents}
		/>
		<SelectSourcesModal
			isOpen={isSourcesModalOpen}
			onClose={() => setIsSourcesModalOpen(false)}
			currentSourceIds={currentSources.map(s => s.id)}
			onSave={handleSaveSources}
		/>
	</>
}
