import React, {ReactElement, useRef} from "react";
import {Portal} from "../portal";
import classNames, {Argument} from "classnames";
import styles from "./tooltip.module.scss";

export interface TooltipProps {
	text?: string;
	anchorEl: HTMLElement | SVGElement | null;
	id: string;
}

export interface BetterProps extends Omit<TooltipProps, "text"> {
	children?: React.ReactNode
	className?: Argument;
	position?: "top" | "left" | "right";
	showArrow?: boolean;
}

/**
 * Only used in one place for now so nothing too complicated.
 */
const Tooltip = (props: TooltipProps): ReactElement => {
	const {text, anchorEl, id} = props;
	const tipRef = useRef<HTMLSpanElement>(null);

	const getPosition = (): React.CSSProperties | undefined => {
		if (!(anchorEl && tipRef.current)) return undefined;
		const anchorRect = anchorEl.getBoundingClientRect();
		const tipRect = tipRef.current.getBoundingClientRect();
		const left = anchorRect.left + 5 + (tipRect.width / 2);
		const {top} = anchorRect;
		return ({top, left});
	};
	return (
		<Portal id={`${id}-tooltip`}>
			<span
				id={id}
				ref={tipRef}
				style={{...getPosition()}}
				className={classNames(styles.tooltip, anchorEl && styles.visible)}
			>
				{text}
			</span>
		</Portal>
	);
};

/**
 * Ideally a bit better than the one above. Should replace it if so.
 */
const BetterTooltip = (props: BetterProps): ReactElement => {
	const {children, className, anchorEl, id, position = "right", showArrow = true} = props;
	const ref = useRef<HTMLDivElement>(null);
	const arrowRef = useRef<HTMLSpanElement>(null);

	const getArrow = (): React.CSSProperties | undefined => {
		if (!(anchorEl && arrowRef)) return undefined;
		const anchorRect = anchorEl.getBoundingClientRect();
		if (position === "right") {
			return ({
				left: anchorRect.right + (anchorRect.width / 2) - 2.5,
				top: anchorRect.y + (anchorRect.height / 2),
			});
		}
	};
	const getPosition = (): React.CSSProperties | undefined => {
		if (!(anchorEl && ref.current && arrowRef)) return undefined;
		const anchorRect = anchorEl.getBoundingClientRect();
		const tipRect = ref.current.getBoundingClientRect();
		if (position === "right") {
			return ({
				left: anchorRect.x + anchorRect.width + 10,
				top: anchorRect.y - (anchorRect.height / 2),
			});
		}
		if (position === "left") {
			return ({
				right: anchorRect.x - (anchorRect.width) - 10,
				top: anchorRect.y - (anchorRect.height / 2),
			});
		}
		return ({top: anchorRect.y - (tipRect.height) - 10, left: anchorRect.x});
	};

	return (
		<Portal id={`${id}-tooltip`}>

			{showArrow && <span ref={arrowRef} style={{...getArrow()}} className={styles.arrow}/>}
			<div
				ref={ref}
				style={{...getPosition()}}
				className={classNames(styles.better, anchorEl && styles.visible, className)}>
				{children}
			</div>
		</Portal>
	);
};


export {BetterTooltip, Tooltip};
