import React, {ReactElement} from "react";

import {
	AnswerGrounding as AnswerGroundingType,
	ChatMessageGroundingEntry,
	QuestionSummaryGrounding,
	TrainingSetFileGrounding,
	TrainingSetVideoGrounding,
} from "../../../../../models/ai-model";
import {BarScale} from "./bar-scale";
import AnswerGrounding from "./answer-grounding";
import DatasetGrounding from "./dataset-grounding";
import QuestionGrounding from "./question-grounding";

import styles from "./grounding-entry.module.scss";

export interface GroundingSectionProps {
  entry: ChatMessageGroundingEntry;
  position: number;
}

const isCampaignGrounding = (entry: ChatMessageGroundingEntry): entry is AnswerGroundingType => {
	return entry.type === "answer";
}

const isDatasetGrounding = (entry: ChatMessageGroundingEntry): entry is (TrainingSetFileGrounding | TrainingSetVideoGrounding) => {
	return entry.type === "trainingSetFile" || entry.type === "trainingSetVideo";
}

const isQuestionGrounding = (entry: ChatMessageGroundingEntry): entry is QuestionSummaryGrounding => {
	return entry.type === "questionSummary";
}

export const GroundingEntry = ({entry, position}: GroundingSectionProps): ReactElement => {
	const renderGrounding = () => {
		if (isCampaignGrounding(entry)) {
			return <AnswerGrounding entry={entry} position={position} />;
		}

		if (isDatasetGrounding(entry)) {
			return <DatasetGrounding entry={entry} position={position} />;
		}

		if (isQuestionGrounding(entry)) {
			return <QuestionGrounding entry={entry} position={position} />;
		}

		throw new Error(`Unknown grounding type`);
	}

	return (
		<div className={styles.groundingEntryWrapper}>
			<BarScale score={Number(entry.score)} />
			{renderGrounding()}
		</div>
	);
}
