import React, {ReactElement} from "react";

import {Answer} from "../../models/answer";
import {HeartIcon as Heart, Icon} from "../../shared";
import {InteractionCounter} from "../../survey/components/interaction-counter";
import {ReelAdder} from "../../survey/components/reel-adder";
import {Setter} from "../../types";

import styles from "./video-options.module.scss";

export interface VideoOptionsProps {
	answer: Answer;
	like?: () => void;
	unlike?: () => void;
	/**
	 * File to download
	 */
	handleDownload?: () => void;
	/**
	 * Handles adding this clip
	 */
	handleAddClip?: (reelId: string, answerId: string) => void;
	handleShowReelModal?: Setter<boolean>;

}

const VideoOptions = (props: VideoOptionsProps): ReactElement => {
	const {
		answer,
		like,
		unlike,
		handleDownload,
		handleAddClip,
		handleShowReelModal,
	} = props;
	return (
		<div className={styles.container}>
			<div className={styles.counters}>
				{like && unlike &&
				<InteractionCounter
					count={answer.likeCount}
					icon={<Heart isLiked={Boolean(answer.likedAt)} like={like} unlike={unlike}/>}
					reverse
				/>
				}
				<InteractionCounter
					count={answer.highlightCount}
					icon={<Icon name="highlight" size="extrasmall"/>}
					reverse
				/>
				{handleAddClip && <ReelAdder addClip={id => handleAddClip(id, answer.id)}
					answerId={answer.id} handleShowReelModal={handleShowReelModal}/>}
			</div>
			{handleDownload &&
				<div className={styles.other}>
					<Icon
						name="download"
						size="extrasmall"
						clicked={handleDownload}
						className={styles.download}
					/>
				</div>
			}
		</div>
	);
};

export {VideoOptions};
