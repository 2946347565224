import React, {ReactElement} from "react";
import previewStyles from "./custom-drag-layer.module.scss";
import styles from "../../../survey/components/question-card/question-card.module.scss";
import {Icon, IconTypes} from "../icon";

interface PreviewProps {
	index: number;
	text: string;
	questionType: string;
	icon: IconTypes;
	hasVideo: boolean;
}

const QuestionCardDragPreview = (props: PreviewProps): ReactElement =>
	<div className={[styles.container, previewStyles.questionCard].join(" ")}>
		<span className={styles.index}>{props.index + 1}</span>
		<div className={styles.type}>
			{props.questionType.toUpperCase()}
		</div>
		<div className={styles.icons}>
			<Icon name={props.icon} size="extrasmall"/>
			{
				props.questionType !== "VIDEO" && props.hasVideo &&
				<Icon name="question-video" size="extrasmall" className={styles["video-icon"]}/>
			}
		</div>
		<div className={styles.question}>{props.text}</div>
	</div>;

export {QuestionCardDragPreview};
