import React, {ReactElement, useEffect, useRef} from "react";
import {GET_ANSWER_VIDEO} from "../../../graphql/queries/survey-queries";
import styles from "./video-card-player.module.scss";
import {useQuery} from "@apollo/client";
import {orderSubtitles} from "../../../shared/utility/utility";

export interface VideoCardPlayerProps {
	/**
	 * Id of the answer to query on
	 */
	answerId: string;
}
/**
 * A component that, when rendered, will begin loading a video
 * for the specified question. Displays loading while loading, and
 * the video when the video is done loading.
 * @param answerId The ID from the answer we want the video to.
 */
const VideoCardPlayer = (props: VideoCardPlayerProps): ReactElement => {
	const {answerId} = props;
	const {data, loading, error} = useQuery(GET_ANSWER_VIDEO, {
		variables: {id: answerId},
		fetchPolicy: "cache-and-network",
	});
	const vidRef = useRef<HTMLVideoElement>(null);

	/**
	 * Auto starts the video when it is loaded.
	 */
	const startVideo = (): void => {
		if (vidRef.current) {
			vidRef.current.play();
		}
	};
	useEffect(() => {
		if (!vidRef.current) return;
		const {current} = vidRef;
		current.onloadedmetadata = startVideo;
		const length = current.textTracks.length;
		if (length === 0) return;
		current.textTracks[0].mode = "showing";
		if (length > 1) current.textTracks[1].mode = "hidden";
	}, [vidRef.current, data]);

	if (loading) {
		return <div style={{
			height: 200,
			width: 200,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		}}>
			Loading video...
		</div>;
	}
	if (data) {
		return (
			<video
				className={styles.video}
				controls
				disablePictureInPicture
				controlsList="nodownload"
				playsInline
				ref={vidRef}
				crossOrigin="anonymous"
			>
				<source src={data.answer.video.video}/>
				<source src={data.answer.video.mp4}/>
				{data.answer.video?.subtitles?.length > 0 &&
					orderSubtitles(data.answer.video?.subtitles).map(sub =>
						<track key={sub.id} lang={sub.langCode} src={sub.fileUrl} label={sub.langCode}/>)
				}
			</video>
		);
	}
	return <div>{error?.message}</div>;
};

export {VideoCardPlayer};
