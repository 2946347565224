import React, {ReactElement, useEffect, useState} from "react";
import styles from "./feedback-card.module.scss";
import {FeedbackQuestion} from "../../../models/questions";
import {Icon} from "../../../shared";
import {Setter} from "types";
import classNames from "classnames";
import {Input, NumberInput} from "../../../shared/v2/inputs";

export interface FeedbackCardProps {
	question: FeedbackQuestion;
	otherActions: boolean;
	questionCount: number;
	position: number;
	handleUpdate: (value: string | number, questionId: string) => void;
	handlePosition: (newPosition: number, currentPosition: number, id: string) => void
	handleDelete: Setter<string>;
}

const FeedbackCard = (props: FeedbackCardProps): ReactElement => {
	 
	const {question, otherActions, questionCount, position, handleDelete, handlePosition, handleUpdate} = props;
	const [text, setText] = useState(question.text);
	const [points, setPoints] = useState(question.points || 0);
	const [pos, setPos] = useState(position + 1);

	const canDelete = question.answerCount === 0;

	useEffect(() => {
		setPos(position + 1);
	}, [position]);

	const handleNumber = (value: number): void => {
		if (value === question.points) return;
		handleUpdate(value, question.id);
	};

	const handleText = (newValue: string): void => {
		if (newValue === question.text) return;
		handleUpdate(newValue, question.id);
	};

	const handleReorder = (): void => {
		if (pos - 1 === position) return;
		handlePosition(pos - 1, position, question.id);
	};

	return (
		<div className={styles.card}>
			<Input
				value={text}
				onChange={setText}
				onBlur={handleText}
				onEnter={handleText}
			/>
			<div className={styles.pointsContainer}>
				<span>Point value:</span>
				<NumberInput
					id={`points-${question.id}`}
					value={points}
					className={styles.points}
					onChange={setPoints}
					onEnter={handleNumber}
					onBlur={handleNumber}
					min={0}
					max={100}
				/>
				{otherActions && <>
					<span>Position:</span>
					<NumberInput
						id={`index-${question.id}`}
						value={pos}
						onChange={setPos}
						onEnter={handleReorder}
						onBlur={handleReorder}
						min={2} // Can't reorder to be first
						max={questionCount}
						className={styles.points}
					/>
					<Icon
						name="trash"
						size="small"
						className={classNames(styles.icon, !canDelete && styles.disabled)}
						clicked={canDelete ? () => handleDelete(question.id) : undefined}
					/>
				</>}
			</div>
		</div>
	);
};

export {FeedbackCard};
