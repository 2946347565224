import React, {ReactElement, useEffect} from "react";
import {useNavigate} from "react-router";

import {useChatConversationContext} from "../context/chat-contexts";
import {ChatView} from "./chat-view";
import {useWorkspaceContext} from "../context/workspace-context";


export const CanvasPage = (): ReactElement => {
	const navigate = useNavigate();
	const {
		conversation,
	} = useChatConversationContext();
	const {workspace: {chatbotEnabled} = {}} = useWorkspaceContext();

	useEffect(() => {
		if (!chatbotEnabled) {
			navigate("/campaigns")
		}
	}, [chatbotEnabled])

	useEffect(() => {
		document.title = `Vurvey - ${conversation?.name ?? "Conversation"}`;
	}, [conversation?.name])

	return <ChatView />
};
