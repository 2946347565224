import {parse, stringify} from "query-string";
import {useLocation} from "react-router-dom";

import {Build} from "./types";

const config = {
	parseNumbers: true,
	parseBooleans: true,
	arrayFormat: "bracket" as "bracket" | "none",
	skipNull: false,
	skipEmptyString: true,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSearchParams = (): Record<string, any> => {
	const {search} = useLocation();
	return parse(search, config);
};

// Todo: Possibly we can remove it now
export const useBuild = (): Build => {
	const parsedSearch = useSearchParams();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const build = (parsed: any): string => stringify(parsed, config);

	return (to, {search, workspace} = {}) => {
		if (typeof to === "string") {
			if (!search && !workspace) return to;
			return {
				pathname: to,
				search: build(search ? parsedSearch : {}),
			};
		}
		return {
			...to,
			search: build({

				...(search ? parsedSearch : {}),
				...to?.search ?? {},
			}),
		};
	};
};
