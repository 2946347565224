import React, {ReactElement} from "react";

import {SparkAiStarsIcon} from "../../../icons";
import {EmptyState} from "../../../shared/v2";

export const MagicSegmentsPage = (): ReactElement => {
	return <EmptyState
		title="Magic Segments"
		description="Stay tuned! We're working on unveiling the patterns and connections within your community."
		icon={<SparkAiStarsIcon />}
	/>
}
