import React, {ReactElement, useRef} from "react";
import {Icon} from "../..";
import classnames from "classnames/bind";
import styles from "./button.module.scss";
import {Argument} from "classnames";

const bStyles = classnames.bind(styles);

export interface UploadButtonProps {
	emptyText: string;
	onUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
	file: File | undefined;
	isDisabled?: boolean;
	size?: "medium" | "small";
	tabIndex?: number;
	className?: Argument;
	fullSize?: boolean;
	onRemove?: (event: React.MouseEvent<HTMLSpanElement>) => void;
	accept?: string;
}

const UploadButton = (props: UploadButtonProps): ReactElement => {
	const fileRef = useRef<HTMLInputElement>(null);
	const {size, isDisabled, file, fullSize} = props;

	const bClasses = bStyles(props.className, "button", "uploadButton", "purpleOutline", {
		[size as string]: size,
		fullSize,
	});

	if (!file) {
		return (
			<>
				<button
					className={bClasses}
					disabled={isDisabled}
					tabIndex={props.tabIndex}
					onClick={() => fileRef?.current?.click()}
				>
					<Icon name="upload" size="extrasmall" className={styles.icon}/>
					{props.emptyText}
				</button>
				<input
					style={{display: "none"}}
					type="file"
					onChange={props.onUpload}
					ref={fileRef}
					accept={props.accept}
				/>
			</>
		);
	}

	return (
		<div className={bStyles("uploaded", props.className)}>
			<Icon name="checkmark" size="extrasmall" />
			<span className={styles.fileName}>{file.name}</span>
			{!isDisabled && props.onRemove && (
				<span onClick={props.onRemove} className={styles.remove}>Remove</span>
			)}
		</div>
	);
};

export {UploadButton};

