/* eslint-disable */

import {Bar, BarChart, ResponsiveContainer, Text, XAxis, YAxis} from "recharts";
import React, {ReactElement} from "react";

import {Checkbox} from "../../../shared/v2";
import {ChoiceQuestion} from "../../../models/questions";

import styles from "./choice-bar-chart.module.scss";

interface ChoiceBarChartProps {
	question: ChoiceQuestion;
	copyButton?: JSX.Element;
	selected?: string[] | null;
	changeSelected: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomizedTick = (tickInfo: any): ReactElement => <>
	<Text x={tickInfo.x + 10} y={tickInfo.y - 15} fill="var(--batterii-black)" fontSize={13} textAnchor="start" width={300} maxLines={2}>
		{tickInfo.payload.value}
	</Text>
	<text x="99%" textAnchor="end" y={tickInfo.y - 15} fontSize={13}>
		{tickInfo.data[tickInfo.index].answerCount}
	</text>
</>;

const ChoiceBarChart = React.forwardRef<HTMLDivElement, ChoiceBarChartProps>((props, ref) => {
	const {
		question: {choices, answerCount, videoResponse},
		copyButton,
		selected,
		changeSelected,
	} = props;
	let highestNumber = 0;
	let topResponse;
	choices?.forEach(option => {
		if (option.answerCount > highestNumber) {
			highestNumber = option.answerCount;
			topResponse = option;
		}
	});


	return (
		<div className={styles.container} ref={ref}>
			<header className={styles.header}>
				{ }
				<h3>{topResponse ? `${(topResponse.answerCount / answerCount * 100).toFixed(0)}% responded "${topResponse.text}"` : ""}</h3>
				{copyButton}
			</header>
			<div className={styles.flex}>
				{
					videoResponse && (
						<div className={styles.checkboxContainer}>
							{choices?.map(choice => ( choice.id !== "SKIPPED" &&
								<Checkbox
									key={choice.id}
									id={choice.id}
									value={choice.id}
									className={styles.checkbox}
									size="s"
									checked={selected?.some(value => value === choice.id) || false}
									onChange={changeSelected}
								/>
							))}
						</div>
					)
				}
				<ResponsiveContainer width="100%" height={66 * choices.length}>
					<BarChart
						data={choices}
						layout="vertical"
						barSize={20}
					>
						<XAxis type="number" hide domain={[0, answerCount]}/>
						<YAxis
							type="category"
							width={1}
							axisLine={false}
							dataKey="text"
							tickLine={false}
							tick={<CustomizedTick data={choices}/>}
						/>
						<Bar
							dataKey="answerCount"
							fill="var(--batterii-purple)"
							background={{fill: "var(--batterii-grey-extralight)"}}
						/>
					</BarChart>
				</ResponsiveContainer>
			</div>
		</div>
	);
});

ChoiceBarChart.displayName = "ChoiceBarChart";

export {ChoiceBarChart};
