import {useQuery} from "@apollo/client";
import React, {ReactNode, createContext, useCallback, useEffect, useMemo, useState} from "react";

import {GET_TRAINING_SETS} from "../../graphql/queries/ai-models-queries";
import {TrainingSet} from "../../models";
import {useChatConversationContext} from "./chat-conversation-context";
import {useNavigate, useSearchParams} from "../../route";
import {useWorkspaceContext} from "../../context/workspace-context";

export interface ChatTrainingSetContextValue {
	isLoading?: boolean;
	isUpdatingDisabled?: boolean;
	trainingSets: TrainingSet[];
	activeTrainingSets?: TrainingSet[];
	saveTrainingSetIds: (ids: string[]) => Promise<void>;
	refetch: () => Promise<TrainingSet[]>;
}

export const ChatTrainingSetContext =
  createContext<ChatTrainingSetContextValue | undefined>(undefined);

export const ChatTrainingSetContextProvider = (
	{children}: {children: ReactNode},
): React.ReactElement => {
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const {
		conversation,
		updateConversation,
		isLoading: isLoadingConversation,
	} = useChatConversationContext();
	const {trainingSetIds = []} = useSearchParams();
	const navigate = useNavigate();
	const [activeTrainingSets, setActiveTrainingSets] = useState<TrainingSet[] | undefined>();

	const {
		data: {trainingSets = []} = {},
		loading: isLoading,
		refetch,
	} = useQuery<{
		trainingSets: TrainingSet[];
	}>(GET_TRAINING_SETS, {
		variables: {
			workspaceId,
		},
	});

	const handleRefetch = useCallback(async () => {
		const {data: {trainingSets: result}} = await refetch();

		return result;
	}, [refetch]);

	const isUpdatingDisabled = Boolean((isLoadingConversation || isLoading) && workspaceId);

	const saveTrainingSetIds = useCallback(async(ids: string[]): Promise<void> => {
		if (isUpdatingDisabled) {
			throw new Error("Training sets updating is disabled");
		}

		const datasets = trainingSets.filter(trainingSet => ids.includes(trainingSet.id));
		setActiveTrainingSets(datasets);

		if (conversation) {
			await updateConversation({
				trainingSetIds: ids,
			});
		} else {
			navigate({search: {
				trainingSetIds: ids,
			}}, {search: true});
		}
	}, [
		updateConversation,
		navigate,
		trainingSets.join(""),
		isUpdatingDisabled,
	]);

	const savedTrainingSets = useMemo(() => {
		if (conversation) {
			return conversation.trainingSets;
		}
		if (trainingSetIds) {
			return trainingSets.filter(trainingSet => trainingSetIds.includes(trainingSet.id));
		}
	}, [
		conversation?.trainingSets,
		trainingSets.map(({id}) => id).join(""),
		trainingSetIds.join(""),
	]);

	useEffect(() => {
		setActiveTrainingSets(savedTrainingSets);
	}, [savedTrainingSets]);

	useEffect(() => {
		if (conversation && trainingSetIds?.length) {
			navigate({search: {
				trainingSetIds: undefined,
			}}, {search: true});
		}
	}, [conversation?.id, trainingSetIds.join("")]);

	return (
		<ChatTrainingSetContext.Provider
			value={{
				activeTrainingSets,
				trainingSets,
				isUpdatingDisabled,
				isLoading,
				saveTrainingSetIds,
				refetch: handleRefetch,
			}}
		>
			{children}
		</ChatTrainingSetContext.Provider>
	);
};

export const useChatTrainingSetContext = (): ChatTrainingSetContextValue => {
	const context = React.useContext(ChatTrainingSetContext);

	if (context === undefined) {
		throw new Error(
			"useChatTrainingSetContext must be used within a ChatTrainingSetContextProvider",
		);
	}

	return context;
};
