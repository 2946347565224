import React, {ReactElement, ReactNode, createContext, useState} from "react";
import {Persona} from "../../models";

export interface ChatMentionsContextValue {
  mention: Persona | null;
  setMention: (mention: Persona | null) => void;
}

export const ChatMentionsContext =
  createContext<ChatMentionsContextValue | undefined>(undefined);

export const ChatMentionsContextProvider = (
	{children}: {children: ReactNode},
): ReactElement => {
	const [mention, setMention] = useState<Persona | null>(null);

	return (
		<ChatMentionsContext.Provider value={{mention, setMention}}>
			{children}
		</ChatMentionsContext.Provider>
	);
};

export const useChatMentionsContext = (): ChatMentionsContextValue => {
	const context = React.useContext(ChatMentionsContext);

	if (context === undefined) {
		throw new Error(
			"useChatMentionsContext must be used within a ChatMentionsContextProvider",
		);
	}

	return context;
};
