/* eslint-disable @typescript-eslint/no-explicit-any*/

import {useState, useEffect} from "react";

function _useElementObserver(
	element: HTMLElement | null,
	observerClass: any,
	callback: any,
	init?: any
): void{
	const isIntersectionObserver = observerClass === IntersectionObserver;
	const [isObserving, setIsObserving] = useState(false);
	const [reobserveTrigger, setReobserverTrigger] = useState(0);

	const reobserve = () => {
		setIsObserving(false);
		setReobserverTrigger(reobserveTrigger + 1);
	}

	useEffect(() => {
		if (!element || isObserving) {
			return;
		}

		const observer = new observerClass(callback, isIntersectionObserver ? init : undefined);
		observer.observe(element, isIntersectionObserver ? undefined : init);

		setIsObserving(true);
		return () => {
			observer.disconnect();
			reobserve();
		};
	}, [element, callback, reobserveTrigger]);
}

function _useRefObserver(
	ref: React.RefObject<HTMLElement>,
	observerClass: any,
	callback: any,
	init?: any
): void {
	const [element, setElement] = useState<HTMLElement | null>(null);

	useEffect(() => {
		setElement(ref.current);
	}, [ref.current]);

	_useElementObserver(element, observerClass, callback, init);
}

export function useObserver(
  refOrElement: React.RefObject<HTMLElement> | HTMLElement | null,
  observerClass: typeof ResizeObserver,
  callback: ResizeObserverCallback,
  init?: ResizeObserverOptions
): void;
export function useObserver(
  refOrElement: React.RefObject<HTMLElement> | HTMLElement | null,
  observerClass: typeof MutationObserver,
  callback: MutationCallback,
  init?: MutationObserverInit
): void;
export function useObserver(
  refOrElement: React.RefObject<HTMLElement> | HTMLElement | null,
  observerClass: typeof IntersectionObserver,
  callback: IntersectionObserverCallback,
  init?: IntersectionObserverInit
): void;
export function useObserver(
	refOrElement: React.RefObject<HTMLElement> | HTMLElement | null,
	observerClass: typeof ResizeObserver | typeof MutationObserver | typeof IntersectionObserver,
	callback: ResizeObserverCallback | MutationCallback | IntersectionObserverCallback,
	init?: ResizeObserverOptions | MutationObserverInit | IntersectionObserverInit
): void {
	if (refOrElement && "current" in refOrElement) {
		return _useRefObserver(
			refOrElement,
      observerClass as any,
      callback as any,
      init as any
		);
	} else {
		return _useElementObserver(
			refOrElement,
      observerClass as any,
      callback as any,
      init as any,
		);
	}
}
