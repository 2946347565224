import React, {ReactElement, useEffect} from "react";

import {Connector} from "../connector";
import {AiPersonaTask} from "../../../models/ai-orchestration";
import {OutputCard} from "../output-card";
import {AgentTaskCard} from "../agent-task-card";
import {useCarouselScrollContext, useWorkflowContext} from "../../../context/workflow-contexts";

import styles from "./agent-task-container.module.scss";

export interface AgentTaskContainerProps {
	agentTask: AiPersonaTask;
	onRemove: (agent: AiPersonaTask) => void;
	onChangeTask: (value: string) => void;
	onChangeIndex: (agent: AiPersonaTask, newIndex: number) => void;
}

export const AgentTaskContainer = ({
	agentTask,
	onRemove,
	onChangeTask,
	onChangeIndex,
}: AgentTaskContainerProps): ReactElement => {
	const {setAgentTasks} = useWorkflowContext();
	const {scrollToElement} = useCarouselScrollContext();

	useEffect(() => {
		if (agentTask.isNew) {
			scrollToElement(agentTask.id);

			setAgentTasks(prevAgents => {
				return prevAgents.map(agent => {
					if (agent.id === agentTask.id) {
						return {...agent, isNew: false};
					}
					return agent;
				});
			});
		}
	}, [agentTask.isNew]);

	return (
		<>
			<Connector />
			<div className={styles.agentTaskContainer} id={agentTask.id}>
				<AgentTaskCard
					agentTask={agentTask}
					onRemove={onRemove}
					onChangeTask={onChangeTask}
					onChangeIndex={onChangeIndex}
				/>

				<OutputCard agentTask={agentTask} />
			</div>
		</>
	);
};
