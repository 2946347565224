import React, {ReactElement, useContext, useState} from "react";

import {Icon, Options} from "../../../shared";
import {SHARE_REEL_WITH_BRAND, UPDATE_REEL} from "../../../graphql/mutations/reel-mutations";
import {ReelContext} from "../../../context/reel-context";
import {UserContext} from "../../../context/user-context";
import styles from "./reel-actions.module.scss";
import classNames from "classnames";
import {useMutation} from "@apollo/client";
import {ToggleSwitch} from "../../../shared/components/toggle-switch";
import {useNavigate} from "react-router";
import {UserRoles} from "../../../models/user";
import {Button, Input} from "../../../shared/v2";

export interface ReelActionsProps {
	publishStatus: React.ReactNode;
	reelError: boolean;
	hasClips: boolean;
	isDownloadable: boolean;
	reelLoading: boolean;
	name: string;
	sharedInBrand: boolean;
	/**
	 * Toggle delete modal
	 */
	handleToggleDelete: () => void;
	/**
	 * Duplicate reel callback
	 */
	duplicateReelCallback: () => void;
	/**
	 * Publish reel callback
	 */
	publishCallback: () => Promise<void>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	downloadReelCallback: () => Promise<any>;
}

/**
 * This component is the action bar at the top of the reel page.
 * @param editors User array of current editors.
 * @param handleToggleDelete Callback to toggle delete modal
 * @param duplicateReelCallback Callback to duplicate the reel
 * @param startEditingCallback Callback to call startEditingReel mutation
 * @param stopEditingCallback Callback to call stopEditingReel mutation
 */
const ReelActions = (props: ReelActionsProps): ReactElement => {
	const {
		name,
		hasClips,
		reelError,
		reelLoading,
		publishStatus,
		sharedInBrand,
		isDownloadable,
		publishCallback,
		handleToggleDelete,
		downloadReelCallback,
		duplicateReelCallback,
	} = props;
	const {toggleShareScreen, error, reelId} = useContext(ReelContext);
	const {user: {role}} = useContext(UserContext);
	const [value, setValue] = useState(name);
	const nav = useNavigate();
	const [updateReel] = useMutation(UPDATE_REEL);
	const [shareReel] = useMutation(SHARE_REEL_WITH_BRAND);

	const handleBack = (): void => nav(-1);

	const handleDone = (): void => {
		if (error) return;
		if (hasClips) publishCallback();
	};

	const handleRetry = (): void => {
		if (hasClips) publishCallback();
	};

	const openShareScreen = (): void => {
		updateReel({
			variables: {
				id: reelId,
				changes: {shared: true},
			},
		}).then(() => toggleShareScreen());
	};


	const handleUpdate = (newValue: string): void => {
		updateReel({
			variables: {
				id: reelId,
				changes: {name: newValue},
			},
		});
	};

	const handleShare = (e: React.ChangeEvent<HTMLInputElement>): void => {
		shareReel({
			variables: {reelId, share: e.target.checked},
		});
	};

	const canShareToBrand = role === UserRoles.ENTERPRISE_MANAGER || role === UserRoles.SUPPORT;
	return (
		<header className={styles.container}>
			<button className={styles.backButton} onClick={handleBack}>
				<Icon name="left-arrow" />
			</button>

			<div className={styles.editorContainer}>
				<Input
					value={value || ""}
					placeholder="Enter reel name"
					onChange={setValue}
					className={classNames(styles.nameInput, styles.edit)}
					onBlur={handleUpdate}
				/>
			</div>
			<div className={styles.buttons}>
				{canShareToBrand && <div className={styles.toggle}>
					<span>Share on brand page</span>
					<ToggleSwitch
						id="brand-share-toggle"
						isChecked={sharedInBrand}
						onChange={handleShare}
					/>
				</div>}
				{publishStatus}
				{reelError && <Button style="danger" onClick={handleRetry}>Retry publish</Button>}
				{hasClips &&
				<>
					<Button variant="outlined" onClick={openShareScreen}>Share</Button>
					<Button
						onClick={handleDone}
						disabled={reelLoading}
					>
						Save & Publish
					</Button>
					<Options
						type="menu-vertical"
						options={[
							{
								name: "Share",
								actionOptions: {
									onClick: openShareScreen,
								},
								icon: "link",
							},
							{
								name: "Save & Publish",
								actionOptions: {
									onClick: handleDone,
									isClickable: !reelLoading,
								},
								icon: "checkmark",
							},
							{
								name: "Copy",
								actionOptions: {
									onClick: duplicateReelCallback,
								},
								icon: "copy",
							},
							{
								name: "Download",
								actionOptions: {
									onClick: downloadReelCallback,
									isClickable: isDownloadable,
								},
								icon: "download",
								tooltipText: !isDownloadable ? "You must save and publish the reel before you may download" : "",
							},
							{
								name: "Delete",
								actionOptions: {
									onClick: handleToggleDelete,
								},
								icon: "trash",
							},
						]}
					/>
				</>
				}
			</div>
		</header>
	);
};

export {ReelActions};
