import React, {ReactElement, useEffect} from "react";
import {Outlet} from "react-router";

import {useUserContext} from "../../../context/user-context";
import {useNavigate} from "../../../route/useNavigate";
import {LoadingContainer} from "../../../shared/v2";
import {NavigationPageLayout} from "../../../hoc/layout/navigation-page-layout";
import {PageSubNavigation} from "../../../hoc/layout/layout/page-sub-navigation";
import {ButtonGroup, ButtonGroupElement} from "../../../shared/components/navigation";
import {ChartsUsageIcon, HornMegaphoneCampaignIcon , PersonaIcon, PersonaUserAgentIcon, TableTemplateEditIcon, UserProfileIcon} from "../../../icons";
import {useToastContext} from "../../../context/toast-context";

import styles from "./admin-page.module.scss";

const AdminPage = React.memo((): ReactElement => {
	const {user, isEnterpriseManagerOrSupport} = useUserContext();
	const navigate = useNavigate();
	const {updateToast} = useToastContext();

	useEffect(() => {
		document.title = "Vurvey - Admin";
	}, []);

	if (!user) {
		return <LoadingContainer />;
	}

	useEffect(() => {
		if (!isEnterpriseManagerOrSupport) {
			updateToast({
				description: "You do not have access to this page",
				type: "failure"
			})
			navigate("/");
		}
	}, [isEnterpriseManagerOrSupport]);

	return (
		<NavigationPageLayout className={styles.fullHeight}>
			<PageSubNavigation header="Super Admin">
				<ButtonGroup>
					<ButtonGroupElement
						icon={<ChartsUsageIcon />}
						text="Dashboard"
						to=""
						end
					/>
					<ButtonGroupElement
						icon={<HornMegaphoneCampaignIcon />}
						text="Manage Workspaces"
						to="workspaces"
					/>
					<ButtonGroupElement
						icon={<UserProfileIcon />}
						text="Manage Brands"
						to="brands"
					/>
					<ButtonGroupElement
						icon={<TableTemplateEditIcon />}
						text="Global Camapign Templates"
						to="campaign-templates"
					/>
					<ButtonGroupElement
						icon={<PersonaIcon />}
						text="Manage Agents"
						to="agents"
					/>
					<ButtonGroupElement
						icon={<PersonaUserAgentIcon />}
						text="Manage Tags"
						to="tags"
					/>
				</ButtonGroup>
			</PageSubNavigation>

			<Outlet />
		</NavigationPageLayout>
	);
});

AdminPage.displayName = "AdminPage";
export {AdminPage};
