import React, {ReactElement} from "react";
import {Icon, IconTypes} from "../../../shared";
import styles from "./star-ranking.module.scss";

export interface StarRankingProps {
	starCount: number;
	icon: IconTypes;
	na: boolean;
}

const StarRanking = (props: StarRankingProps): ReactElement => {
	const {starCount, icon, na} = props;
	const stars: ReactElement[] = [];
	for (let i = 0; i < starCount; i++) {
		stars.push(<Icon key={`star-${i}`} name={icon} className={styles.icon}/>);
	}

	return (
		<div className={styles.container}>
			<div className={styles.stars}>
				{stars}
				{na && <div className={styles.checkContainer}>
					<div className={styles.check}>
						<Icon name="checkmark" fill="var(--batterii-grey-light)" size="extrasmall"/>
					</div>
					<span>N/A</span>
				</div>
				}
			</div>
		</div>
	);
};

export {StarRanking};
