import classNames from "classnames/bind";
import React, {ReactElement} from "react";
import {Heading} from "../../typography/heading";
import {Icon} from "../icon";
import {Portal} from "../portal";
import styles from "./panel.module.scss";

const bStyles = classNames.bind(styles);

export interface PanelProps {
	title: string;
	children: React.ReactNode;
	theme: "blue" | "purple"
	handleClose: () => void;
}

/**
 * A Panel is (currently) a top to bottom window on the right side of the screen (fixed).
 * Used mostly to pop in on the right for something like Filters or Details. Maybe eventually
 * used for other areas.
 */
const Panel = ({title, children, theme, handleClose}: PanelProps): ReactElement => (
	<Portal id={`${title}-panel`}>
		<div className={styles.container}>
			<header className={bStyles("header", theme)}>
				<Heading size="md">{title}</Heading>
				<Icon name="cross" size="small" isClickable clicked={handleClose}/>
			</header>
			{children}
		</div>
	</Portal>
);

export {Panel};
