import {Argument} from "classnames";
import classNames from "classnames/bind";
import React, {ReactElement, useState} from "react";

import {Body, Checkbox, SearchInput} from "../../../shared/v2";
import {Icon, Dropdown, IconTypes} from "../..";
import {useDropdown} from "../../../hooks";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./searchable-filter.module.scss";

const bStyles = classNames.bind(styles);

export interface SelectValue {
	id: string;
	name: string;
	icon?: IconTypes;
}

export interface SearchableFilterProps {
	options: SelectValue[];
	value: string[];
	selectedLabel: string;
	placeholder?: string;
	className?: Argument;
	link?: JSX.Element;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onChange: (newValue: any) => void;
	onClear?: () => void;
	selectAll?: (option: string[]) => void;
}

const SearchableFilter = (props: SearchableFilterProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	const {options, value, onChange, selectedLabel, onClear, placeholder, className, link, selectAll} = props;

	const [searchValue, setSearchValue] = useState<string>("");

	let filtered = options;

	if (searchValue?.length > 1) {
		filtered = options.filter(item => {
			const lowerCase = item.name.toLowerCase();
			return lowerCase.includes(searchValue.toLowerCase());
		});
	}

	const {anchorEl, handleClick, handleClose} = useDropdown();

	const toggleAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
		if (!selectAll) return;
		if (e.target.checked) {
			const allValues = options.map(o => o.id);
			return selectAll(allValues);
		}
		return selectAll([]);
	};

	return (
		<>
			<div className={bStyles("inputContainer", className, {isDarkMode})} onClick={handleClick}>
				<input
					id="searchable-input"
					className={bStyles("input", {isDarkMode})}
					placeholder={placeholder}
					readOnly
					value={value.length ? selectedLabel : ""}
				/>

				<Icon
					name="chevron"
					size="medium"
					fill="var(--color-text-body)"
				/>
			</div>
			<Dropdown
				className={styles.dropdown}
				anchorEl={anchorEl}
				closeMenu={handleClose}
				keepOpen
				width={240}
			>
				<SearchInput
					id="searchable-filter-input"
					className={styles.searchInput}
					value={searchValue}
					onChange={setSearchValue}
				/>
				<div className={styles.optionsList}>
					{link}
					{selectAll && (
						<Checkbox
							id="select-all-options"
							text={(
								<Body size="xs">
									Select all
								</Body>
							)}
							size="s"
							className={styles.option}
							checked={value.length === options.length}
							onChange={e => toggleAll(e)}
						/>
					)}
					{filtered?.map(option => (
						<Checkbox
							className={bStyles("option", {isDarkMode})}
							key={option.id}
							id={option.id}
							size="s"
							text={(
								<Body size="xs">
									{option.name}
								</Body>
							)}
							checked={value.includes(option.id) || value.includes(option.name)}
							onChange={() => onChange(option)}
						/>
					))}
				</div>
				{onClear &&
					<button
						className={styles.clearFilter}
						onClick={onClear}
					>
						Clear filters
					</button>
				}
			</Dropdown>
		</>
	);
};

export {SearchableFilter};
