import {CLIP_FRAGMENT} from "../fragments/fragments";
import {gql} from "@apollo/client";

/**
 * @param limit Limit of how many reels to get at once
 * @param cursor Cursor for retrieving additional reels
 * @param filter ReelsFilter
 */
export const GET_ALL_REELS = gql`
	query GetAllReels ($limit: PositiveInt $cursor: String $filter: ReelsFilter) {
		reels(limit: $limit cursor: $cursor filter: $filter) {
			items {
				id
				name
				videoStatus
				questionId
				creator {
					id
					firstName
					lastName
					lastInitial
				}
				createdAt
				duration
				sharedInBrand
				clip {
					id
					answer {
						id
						video {
							id
							thumbnail:url(version:THUMBNAIL)
						}
					}
					sourceVideo {
						thumbnail:url(version:THUMBNAIL)
					}
				}
			}
			cursor
			remaining
		}
	}
`;

/**
 * This query is specifically for the reel adder component.
 * We only need the id and name of the reel to display
 * @param limit Limit of how many reels to get at once
 * @param cursor Cursor for retrieving additional reels
 * @param filter ReelsFilter
 */
export const GET_ALL_REEL_NAMES = gql`
		query GetReelNames ($limit: PositiveInt $cursor: String $filter: ReelsFilter) {
		reels(limit: $limit cursor: $cursor filter: $filter) {
			items {
				id
				name
			}
			cursor
			remaining
		}
	}
`;

/**
 * Gets a single reel by its ID
 */

export const GET_REEL = gql`
	query GetReel ($id: GUID!) {
		reel(id: $id) {
			id
			name
			questionId
			creator {
				id
				name
			}
			createdAt
			duration
		}
	}
`;

export const GET_CLIP = gql`
	query GetClip($id: GUID!) {
		clip(id: $id) {
			...ClipFields
		}
	} ${CLIP_FRAGMENT}
`;
/**
 * Gets all clips AND the reel associated to the clips at once.
 */
export const GET_REEL_AND_CLIPS = gql`
	query GetReelAndClips ($reelId: GUID! $limit: PositiveInt) {
		reel(id: $reelId) {
			id
			name
			description
			questionId
			creator {
				id
				firstName
				lastName
				lastInitial
				creatorTag
				picture {
					id
					badge:url(version:BADGE)
				}
			}
			editors {
				id
				firstName
				lastName
				lastInitial
			}
			videoStatus
			createdAt
			duration
			sharedInBrand
		}
		clips(reelId: $reelId limit: $limit) {
			items {
				...ClipFields
			}
		}
	} ${CLIP_FRAGMENT}
`;

/**
 * This is the query used on the share screen and modal.
 * Video will be null if the reel has not been published.
 */
export const GET_REEL_VIDEO = gql`
	query GetReelVideo ($reelId: GUID! $shared: Boolean $password: String) {
		reel (id: $reelId shared: $shared password: $password) {
			id
			name
			requiresPassword
			password
			displayMode
			subtitles {
				id
				fileUrl
				langCode
			}
			video {
				id
				transcodingStatus
				video:url(version:VP8_VORBIS)
				mp4:url(version:H264_AAC)
			}
		}
	}
`;

export const GET_PUBLIC_REEL = gql`
	query GetPublicReel ($reelId: GUID! $shared: Boolean $password: String) {
		reel (id: $reelId shared: $shared password: $password) {
			id
			name
			requiresPassword
			displayMode
			video {
				id
				transcodingStatus
				video:url(version:VP8_VORBIS)
				mp4:url(version:H264_AAC)
			}
			subtitles {
				id
				fileUrl
				langCode
			}
		}
	}
`;
/**
 * Returns just the id and videoStatus (published state for compiled video)
 * on a given reel.
 */
export const GET_REEL_PUBLISH_STATUS = gql`
	query GetPublishStatus ($reelId: GUID!) {
		reel (id: $reelId) {
			id
			videoStatus
		}
	}
`;

export const GET_REEL_VIDEO_STATUS = gql`
	query GetReelVideoStatus ($reelId: GUID!) {
		reel (id: $reelId) {
			id
			video {
				id
				transcodingStatus
			}
		}
	}
`;

export const GET_REEL_DOWNLOAD_URL = gql`
	query GetReelDownloadUrl ($reelId: GUID!) {
		reel (id: $reelId) {
			id
			video {
				id
				url(version: H264_AAC, isDownload: true)
			}
		}
	}
`;
