import {Dropdown, Heading} from "../../shared";
import React, {ReactElement, useContext, useEffect, useState} from "react";
import {GET_BOTH_SEGMENTS} from "../../graphql/queries/queries";
import {SegmentData} from "../../models/segment";
import {SurveyContext} from "../../context/survey-context";
import styles from "./segment-adder.module.scss";
import {useLazyQuery} from "@apollo/client";
import {DynamicSegmentData} from "../../models/dynamic-segment";
import {Button, SearchInput} from "../../shared/v2";
import {UsersIcon} from "../../icons";

export interface SegmentAdderProps {
	/**
	 * Handles adding a particular segment
	 */
	addEmails: (workspaceId: string, segmentId: string) => void;
	addDynamic: (workspaceId: string, dynamicSegmentId: string) => void;
}

/**
 * Makes use of the filter input and calls out to the workspaceID
 * in order to get the segments to populat for a way to add
 * segments to a survey.
 */
const SegmentAdder = (props: SegmentAdderProps): ReactElement => {
	const {addEmails, addDynamic} = props;
	const [value, setValue] = useState<string>("");
	const {survey: {workspaceId}} = useContext(SurveyContext);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [getSegments, {data, loading}] =
	useLazyQuery<SegmentData & DynamicSegmentData>(GET_BOTH_SEGMENTS);

	const handleClick = (e: React.MouseEvent<HTMLElement>): void => {
		setAnchorEl(e.currentTarget);
	};
	const handleClose = (): void => {
		setAnchorEl(null);
	};

	useEffect(() => {
		if (anchorEl) {
			getSegments({
				variables: {workspaceId},
			});
		}
	}, [anchorEl]);

	/**
	 * Handles displaying all of our segments, based on filter
	 * and if there are any available.
	 */
	const displaySegments = (): JSX.Element | JSX.Element[] => {
		if (loading) return <span>Loading...</span>;
		 
		const lists = data?.segments.items.filter(segment => segment.name.toLowerCase().includes(value.toLowerCase()))
			.map(segment => <div
				key={segment.id}
				className={[styles.segment, styles.clickable].join(" ")}
				onClick={() => addEmails(workspaceId, segment.id)}
			>
				{segment.name} <span className={styles.count}>
					({segment.memberCount})
				</span>
			</div>);
		const dynamicSegments = data?.dynamicSegments.items.filter(segment => (
			segment.name.toLowerCase().includes(value.toLowerCase())
		)).map(segment => <div
			key={segment.id}
			className={[styles.segment, styles.clickable].join(" ")}
			onClick={() => addDynamic(workspaceId, segment.id)}
		>
			{segment.name}<span className={styles.count}>
				({segment.memberCount})
			</span>
		</div>);

		if (lists?.length === 0 && dynamicSegments?.length === 0) {
			return <div className={styles.segment}>
				No lists found
			</div>;
		}

		return <>
		Lists
			{lists}
		Segments
			{dynamicSegments}
		</>;
	};
	return (
		<div className={styles.container}>
			<Button
				onClick={handleClick}
				size="small"
				className={styles.link}
				leftIcon={<UsersIcon />}
			>
				Invite from List or Segment
			</Button>
			<Dropdown
				anchorEl={anchorEl}
				closeMenu={handleClose}
				width={240}
				keepOpen
				position="left"
			>
				<div className={styles.padding}>
					<Heading
						size="sm"
						additionalClasses={styles.heading}
					>
						Lists & Segments
					</Heading>
					<SearchInput
						id="filter-segments"
						value={value}
						onChange={setValue}
					/>
					<div className={styles.segments}>
						{displaySegments()}
					</div>
				</div>
			</Dropdown>
		</div>
	);
};

export {SegmentAdder};
