import {useMemo} from "react";
import classNames from "classnames/bind";

import {DropdownItem} from "../..";
import {useThemeMode} from "../../../../../../context/theme-mode-context";

import styles from "../../items.module.scss";

const cx = classNames.bind(styles);

export function useItemClassName(
	{color, disabled, active}: DropdownItem,
): string {
	const {isDarkMode} = useThemeMode();

	return useMemo(() => cx(
		"dropdownItem",
		color,
		{disabled: disabled, active: active, isDarkMode}
	), [color, disabled, active]);
}
