import classNames from "classnames/bind";
import React, {FormEvent, ReactElement, useState} from "react";

import {Button} from "../../../shared/v2";
import {useAgentCreationContext} from "../../../context/agent-creation-context";
import {useChatSendQuestionContext} from "../../../context/chat-contexts";
import {useThemeMode} from "../../../context/theme-mode-context";
import CommandTextArea from "../../../canvas/chat-bubble/command-text-area";
import {KeyboardEventMiddlewareContextProvider} from "../../../context/keyboard-event-middleware-context";

import styles from "./chat-bubble.module.scss";

const cx = classNames.bind(styles);

export interface AgentsPreviewChatBubbleProps {
  className?: string;
  isChatUpdated?: boolean;
}

export const AgentsPreviewChatBubble = ({
	className,
	isChatUpdated,
}: AgentsPreviewChatBubbleProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const [value, setValue] = useState("");
	const {sendQuestion, isSendingQuestion} = useChatSendQuestionContext();
	const {stringifiedInstructions} = useAgentCreationContext();
	const [uploadedFile, setUploadedFile] = useState<File | null>(null);

	const isDisabled = !value || isSendingQuestion;

	const handleResponse = async (e?: FormEvent<HTMLFormElement>): Promise<void> => {
		e?.preventDefault();

		setValue("");
		await sendQuestion(value, {
			conversationInput: {isPreview: true},
			sendQuestionInput: {instructions: stringifiedInstructions},
			createNewConversation: isChatUpdated,
		});
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const file = e.target.files?.[0] || null;
		setUploadedFile(file);
	};

	return (
		<form className={cx("inputWrapper", className, {isDarkMode})} onSubmit={handleResponse}>
			<KeyboardEventMiddlewareContextProvider>
				<CommandTextArea
					value={value}
					textAreaProps={{"aria-label":"chat-message"}}
					placeholder="Test your Agents..."
					onChange={setValue}
					handleSubmit={handleResponse}
					focusOnMount={false}
					commands={[
						{command: "/table", description: " (returns response formatted as a table)", icon: "📊"},
						{
							command: "/image",
							description:
								" (Uses a mix of Imagen, Stable Diffusion, and DALL-E to generate images based on your description)",
							icon: "📸",
						},
						{
							command: "/imagesd",
							description: " (Uses Stable Diffusion to generate images based on your description)",
							icon: "📸",
						},
						{
							command: "/imagede",
							description: " (Uses OpenAI DALL-E to generate images based on your description)",
							icon: "📸",
						},
						{
							command: "/imagen",
							description: " (Uses Google Imagen to generate images based on your description)",
							icon: "📸",
						},
						{
							command: "/scrape",
							description: "Will scrape the URL (/scrape [url]) that you provide and return a summary of the content while adding the full content to the Scraped-Data Dataset folder.",
							icon: "🌐",
						},
						{
							command: "/scrape_advanced",
							description: "Similar to /scrape [url] but will sometimes provide more detail on complicated websites where the default /scrape under performs. This can take much longer though and should only be used when /scrape fails.",
							icon: "🌐",
						},
						{
							command: "/web",
							description: "Will use the web to find the answer to your question with supporting images and links if appropriate. Example: /web Who is current president of the USA?",
							icon: "🌐",
						},
						{command: "/help", description: " (shows help)", icon: "❓"},
					]}
				/>
			</KeyboardEventMiddlewareContextProvider>
			<div className={styles.controlPanel}>
				{/*<input type="file" accept="image/*" onChange={handleFileChange} style={{display: "none"}} id="fileUpload" />
				<label htmlFor="fileUpload" className={styles.uploadButton}>
					<span role="img" aria-label="upload">
						➕
					</span>
				</label>*/}
				{uploadedFile && (
					<div className={styles.thumbnailWrapper}>
						<img src={URL.createObjectURL(uploadedFile)} alt="Uploaded file thumbnail" className={styles.thumbnail} />
					</div>
				)}
				<Button disabled={isDisabled} type="submit" size="small">
					Generate
				</Button>
			</div>
		</form>
	);
}
