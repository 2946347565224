import React, {ReactElement, useRef} from "react";
import TextAreaAutosize, {TextareaAutosizeProps} from "react-textarea-autosize";
import classnames from "classnames/bind";
import styles from "../input/input.module.scss";
import autoStyles from "./auto-resize-textarea.module.scss";
import {InputElements} from "../input-elements";

const bind = classnames.bind(styles);

export interface AutoResizeTextareaProps extends Omit<TextareaAutosizeProps, "size" | "value" | "onChange" | "onBlur"> {
	onChange: (value: string) => void;
	value: string;
	hintText?: string;
	label?: string;
	leftIcon?: ReactElement;
	rightIcon?: ReactElement;
	variant?: "default" | "error",
	size?: "small" | "medium"
	maxLength?: number;
	onBlur?: (value: string) => void;
}

const handleEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
	if (e.key === "Enter" && !e.shiftKey) e.currentTarget.blur();
}

export const AutoResizeTextarea = ({
	value,
	onChange,
	onBlur,
	className,
	size = "small",
	variant = "default",
	label,
	hintText,
	leftIcon,
	rightIcon,
	maxLength,
	...props
}: AutoResizeTextareaProps): ReactElement => {
	const ref = useRef<HTMLTextAreaElement>(null);
	// VU-1752 - When we focus on this we want to hide the "sample" text
	const handleFocus = (): void => {
		if (!ref.current) return;
		const {placeholder} = props;
		if (placeholder === ref.current.value) {
			onChange("");
		}
	};
	return <InputElements
		variant="default"
		className={className}
		label={label}
		hintText={hintText}
		leftIcon={leftIcon}
		rightIcon={rightIcon}
	>
		<TextAreaAutosize
			ref={ref}
			className={bind("input", autoStyles.autoText, size, variant,
				leftIcon && "withLeftIcon", rightIcon && "withRightIcon", (maxLength && value.length > maxLength) && "error")}
			value={value}
			onChange={e => onChange(e.target.value)}
			onBlur={e => onBlur?.(e.target.value)}
			onKeyDown={handleEnter}
			onFocus={handleFocus}
			{...props}
		/>
		{(maxLength && maxLength >= 0 && value.length > maxLength) ?
			<p className={autoStyles.errorText}>Max length is {maxLength} &#40;{value.length}/{maxLength}&#41;</p> : <></>
		}
	</InputElements>
};
