import {TranscodingStatus} from "./answer";
import {GenericPage, IdName} from "./generic";
import {Persona} from "./persona";
import {UploadResult} from "./upload-result";
import {User} from "./user";
import {Workspace} from "./workspace";

export interface AIModelCategory {
	id: string;
	name: string;
	description: string;
}

export interface AIModel {
	id: string;
	name: string;
	description: string;
	provider: string;
	useCase: string;
	task: "foundation" | "transcript" | "question" | "campaign" | "creator" | "answer-video" |
	"language" |"speech" | "multimodal" | "video" | "document" | "image" | "generative";
	modality: "foundation" | "transcript" | "question" | "campaign" | "creator" | "answer-video" |
	"language" |"speech" | "multimodal" | "video" | "document" | "image" | "generative";
	status: "deployed" | "beta" | "coming-soon";
	modelCategory: AIModelCategory;
}

export interface CategoryWithAIModels {
	id: string;
	name: string;
	description: string;
	aiModels: AIModel[];
}

export interface TrainingSet {
	id: string;
	name: string;
	alias: string;
	description?: string;
	files: TrainingSetFile[];
	videos: TrainingSetVideo[];
}

export interface TrainingSetFile {
	id: string;
	originalFilename: string;
	originalMimetype: string;
	url: string;
	hasEmbeddings: boolean;
}

export interface TrainingSetVideo {
	id: string;
	originalFilename: string;
	originalMimetype: string;
	url: string;
	transcodingStatus: TranscodingStatus;
	hasEmbeddings: boolean;
}

export type TrainingSetMedia = TrainingSetFile | TrainingSetVideo;

export interface FileWithUploadResult extends File {
	uploadResult: UploadResult;
}
export enum ChatConversationMessageRole {
	USER = "user",
	ASSISTANT = "assistant",
	SYSTEM = "system",
	GENERIC = "generic",
	FUNCTION = "function",
	MEMORY = "memory"
}


interface UserId {
	id: string;
}

export interface ChatConversationMessage {
	id: string;
	content: string;
	conversation: ChatConversation;
	conversationId: string;
	persona: Persona;
	survey: Campaign;
	surveyId?: string;
	role: ChatConversationMessageRole;
	type: string;
	position: number;
	suggestedFollowUps: string[];
	likedByUsers: UserId[];
	dislikedByUsers: UserId[];
	groundingData?: ChatMessageGroundingEntry[];
}

export interface ChatConversationPreview {
	images: string[];
	creator: {
		firstName: string;
		lastName: string;
	};
	content: string;
	createdAt: string;
}

export interface ChatConversation {
	id: string;
	createdAt: string;
	messages: ChatConversationMessage[];
	name: string;
	user: User;
	userId: string;
	workspace: Workspace;
	trainingSets: TrainingSet[];
	aiPersona?: Persona;
	workspaceId: string;
	surveys?: Campaign[];
	preview?: ChatConversationPreview;
	isPreview?: boolean;
}

export interface Campaign {
	id: string;
	name: string;
	questionCount: number;
	totalVideoDuration: number;
	completedResponseCount: number
  responseCount: number;
}

export interface AiPersonaCategoryData {
	aiPersonaCategories: IdName[];
}

export interface AiPersonaByIdData {
	aiPersonaById: Persona;
}

export interface AiTool {
	id: string;
	name: string;
	code: string;
}

export interface AiToolsPageData {
	aiTools: GenericPage<AiTool>;
}

export interface QuestionSummaryGroundingDetails {
	questionId: string;
	surveyId: string;
	questionText: string;
}

export interface AnswerGroundingDetails {
	surveyId: string;
	surveyTitle: string;
	questionId: string;
	questionText: string;
	answerVideoThumbnailUrl: string;
	answerId: string;
	creatorTag: string;
}

export interface TrainingSetFileGroundingDetails {
	trainingSetId: string;
	trainingSetName: string;
	fileUrl: string;
	originalFilename: string;
	trainingSetDescription: string;
}

export interface ChatMessageGroundingBase {
	score: number;
  page?: number;
}

export interface QuestionSummaryGrounding extends ChatMessageGroundingBase {
	type: "questionSummary";
	questionSummary: QuestionSummaryGroundingDetails;
}

export interface AnswerGrounding extends ChatMessageGroundingBase {
	type: "answer";
	answer: AnswerGroundingDetails;
}

export interface TrainingSetFileGrounding extends ChatMessageGroundingBase {
	type: "trainingSetFile";
	trainingSetFile: TrainingSetFileGroundingDetails;
}

export interface TrainingSetVideoGrounding extends ChatMessageGroundingBase {
	type: "trainingSetVideo";
	trainingSetVideo: TrainingSetFileGroundingDetails;
}

export type ChatMessageGroundingEntry = QuestionSummaryGrounding | AnswerGrounding | TrainingSetFileGrounding | TrainingSetVideoGrounding;

export interface Prompt {
  id: string;
  name: string;
  description: string;
  template: string;
  type: string;
  workspaceId: string;
}

export interface SystemAgent {
  id: string;
  code: string;
  name: string;
  description: string;
}

export interface AiSkill {
  id: string;
  name: string;
  description: string;
}
