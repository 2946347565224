import {useEffect, useState} from "react";
import {useConnectionHealthContext} from "../context/connection-health-context";

// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
export const useUponReconnecting = (func: Function) => {
	const {isConnected} = useConnectionHealthContext();
	const [isInitial, setIsInitial] = useState(true);

	useEffect(() => {
		if (isInitial) {
			setIsInitial(false);
			return;
		}

		if (isConnected) {
			func();
		}
	}, [isConnected]);
};
