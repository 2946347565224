import {Dropdown} from "../../../shared";
import React, {ReactElement} from "react";
import {GET_WORKSPACE_SEGMENTS} from "../../../graphql/queries/queries";
import styles from "./segment-button.module.scss";
import {useDropdown} from "../../../hooks/useDropdown";
import {useQuery} from "@apollo/client";
import {Button} from "../../../shared/v2";

export interface SegmentButtonProps {
	/**
	 * Function to send users
	 */
	sendUsers: (id: string, userIds: string[]) => void;
	/**
	 * Workspace ID
	 */
	workspaceId: string;

	userIds: string[];
}

const SegmentButton = (props: SegmentButtonProps): ReactElement => {
	const {sendUsers, workspaceId, userIds} = props;
	const {anchorEl, handleClick: openDropdown, handleClose} = useDropdown();
	const {data, loading} = useQuery(GET_WORKSPACE_SEGMENTS, {
		variables: {workspaceId},
		skip: !anchorEl,
		fetchPolicy: "cache-and-network",
	});

	return (
		<div className={styles.container}>
			<Button variant="outlined" onClick={openDropdown}>Add to List</Button>
			<Dropdown
				anchorEl={anchorEl}
				closeMenu={handleClose}
				width={180}
				position="left"
			>
				{loading
					? (
						<p>Loading creator lists</p>
					)
					: (
						data
							? (
								data.segments.items.map(segment => <div
									className={styles.segment}
									key={segment.id}
									onClick={() => sendUsers(segment.id, userIds)}
								>
									{segment.name}
								</div>)
							)
							: <p>An error occurred while getting the creator lists</p>
					)
				}
			</Dropdown>
		</div>
	);
};

export {SegmentButton};
