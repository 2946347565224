import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import {Body, Caption, Subheader} from "../../../shared/v2/typography";
import {
	ChatBubbleCameraIcon,
	ChatBubbleCheckmarkIcon,
	ChatBubbleCompletesIcon,
	ChatBubbleQuestionIcon,
} from "../../../icons";
import {useThemeMode} from "../../../context/theme-mode-context";
import {Campaign} from "../../../models/ai-model";
import {convertTimeFromMiliseconds} from "../../../shared/utility/utility";

import styles from "./campaign-card.module.scss";

const bStyles = classNames.bind(styles);

export interface CampaignCardProps {
	campaign: Campaign
	isSelected?: boolean;
	onSelect: (campaign: Campaign) => void;
}

export interface CounterItemProps {
	icon: ReactElement;
	label: string;
	value: number | string;
}

export const CounterContainer = ({icon, label, value}: CounterItemProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	return <div className={styles.counterContainer}>
		{icon}
		<Body color="text-secondary" className={styles.inline} type="medium" size="s">{label}</Body>
		<Body className={bStyles("value", {isDarkMode})} type="bold" size="s">{value}</Body>
	</div>;
};

export const CampaignCard = ({campaign, isSelected, onSelect}: CampaignCardProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	return <div
		className={bStyles("cardWrapper", {isSelected, isDarkMode})}
		onClick={() => onSelect(campaign)}
		tabIndex={0}
	>
		<div className={styles.content}>
			<div className={styles.headerContainer}>
				<Caption size="s" type="semibold" className={bStyles("kicker", {isDarkMode})}>
          Campaign
				</Caption>
				<Subheader type="semibold">
					{campaign.name}
				</Subheader>
			</div>

			<div className={styles.countersContainer}>
				<CounterContainer
					icon={<ChatBubbleQuestionIcon className={styles.icon} />}
					label="Questions:"
					value={campaign.questionCount}
				/>

				<CounterContainer
					icon={<ChatBubbleCheckmarkIcon className={styles.icon} />}
					label="Responses:"
					value={campaign.responseCount}
				/>

				<CounterContainer
					icon={<ChatBubbleCompletesIcon className={styles.icon} />}
					label="Completes:"
					value={campaign.completedResponseCount}
				/>

				<CounterContainer
					icon={<ChatBubbleCameraIcon className={styles.icon} />}
					label="Video Minutes:"
					 
					value={`${Math.floor(convertTimeFromMiliseconds(campaign.totalVideoDuration, "minutes"))} min`}
				/>
			</div>
		</div>
	</div>;
};
