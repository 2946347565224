import {useQuery} from "@apollo/client";
import {TranscodingStatus} from "../models/answer";
import {CHECK_TRANSCODING} from "../graphql/queries/video-queries";
import {TranscodingReturn} from "../models/video";

/**
 * This hook purely uses the checkVideoTranscodingStatus so that we can check
 * any recently uploaded video for its transcoding status before we do anything else
 * with it.
 *
 * @param id Id of the video
 *
 * @returns Transcoding status / undefined
 */

export const usePollForStatus = (id: string | undefined): TranscodingStatus | undefined => {
	const {data, error, stopPolling} = useQuery<TranscodingReturn>(CHECK_TRANSCODING, {
		pollInterval: 1000,
		skip: !id,
		fetchPolicy: "cache-and-network",
		variables: {videoIds: id},
	});

	if (data) {
		const {transcodingStatus} = data.checkVideoTranscodingStatus[0];
		if (transcodingStatus === TranscodingStatus.COMPLETED
				|| transcodingStatus === TranscodingStatus.FAILED) {
			stopPolling();
		}
		return transcodingStatus;
	}
	if (error) stopPolling();
	return undefined;
};
