import {Switch as HeadlessuiSwitch} from "@headlessui/react";
import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./switch.module.scss";

const cx = classNames.bind(styles);

export interface SwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
}

export const Switch = ({checked, onChange, disabled}: SwitchProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	return (
		<HeadlessuiSwitch
			checked={checked}
			onChange={onChange}
			className={cx("track", {checked, isDarkMode, disabled})}
			disabled={disabled}
		>
			<span className={styles.switch} />
		</HeadlessuiSwitch>
	);
}
