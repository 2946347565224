import React, {ReactElement, useContext, useEffect, useRef, useState} from "react";
import {useLazyQuery, useMutation} from "@apollo/client";

import {
	DYNAMIC_SEGMENT_EMAILS,
	SEGMENT_EMAILS,
} from "../../graphql/queries/queries";
import {AnswersPageFilter} from "../../models/answer";
import {Body, Button, Checkbox, Spinner, Modal, BaseModalProps} from "../../shared/v2";
import {Emailer} from "../../shared/components/emailer";
import {SegmentAdder} from "../../modal-components/segment-adder";
import {SEND_SURVEY_TO_MEMBERS} from "../../graphql/mutations/survey-mutations";
import {StatusBanner} from "../../survey/components/status-banner";
import {Survey} from "../../models/survey";
import {SurveyContext} from "../../context/survey-context";
import {TagInput} from "../../shared/components/tag-input";
import {useEmailer} from "../../hooks/useEmailer";
import {UserContext} from "../../context/user-context";

import styles from "./invite-to-survey.module.scss";
import {uniq} from "lodash-es";
import {siftEmails} from "../../shared/utility/sift-emails";

export interface InviteToSurveyModalProps extends BaseModalProps {
	/**
	 * What happens when we click on send invites
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onSend: (emails: string[], anonymous: boolean) => Promise<any>;

	/**
	 * Set to true to supply new members via userIds. Takes precedence over useFilter.
	 */
	useUserIds?: boolean;

	/**
	 * The ids of users to add. Used only if useUserIds is set to true.
	 */
	userIds?: string[];

	/**
	 * Set to true to supply new members via a filter. "useUserIds" takes precedence over this.
	 */
	useFilter?: boolean;

	/**
	 * The filter to use to find users to add. Used only if useFilter is set to true.
	 */
	filter?: AnswersPageFilter;

	/**
	 * The number of items selected by the filter
	 */
	numFilterSelected?: number;

	/**
	 * Set to send the invites to a survey you are not in
	 */
	overrideSurvey?: Survey;
}

const InviteToSurveyModal = (props: InviteToSurveyModalProps): ReactElement => {
	const {
		isOpen,
		onClose,
		onSend,
		useUserIds,
		userIds,
		useFilter,
		filter,
		numFilterSelected,
		overrideSurvey,
	} = props;
	const {
		survey: {id: surveyId, workspaceId},
	} = useContext(SurveyContext);
	const inputRef = useRef<HTMLDivElement>(null);
	const {user: {id: userId}} = useContext(UserContext);
	const [emails, setEmails] = useState<string[]>([]);
	const [displayValues, setDisplayedValues] = useState<string[]>([]);
	const [isUpdating, setIsUpdating] = useState(false);
	const [anonymous, setAnonymous] = useState(false);
	const [skipPartials, setSkipPartials] = useState(false);
	const [loading, setLoading] = useState(false);
	const [notifyViaEmail, setNotifyViaEmail] = useState(true);
	const emailerProps = useEmailer(workspaceId, userId);
	const {resetValues, ...emailer} = emailerProps;
	const [getEmailsFromSegment, {variables}] = useLazyQuery(SEGMENT_EMAILS, {
		fetchPolicy: "cache-and-network",
		onError: () => setLoading(false),
		onCompleted: data => {
			if (!data) return;
			const newEmails: string[] = data.contacts.items.map(user => user.email);
			const newTags: string[] = data.contacts.items.map(user => user.creatorTag);
			const updatedEmails = emails.concat(newEmails);
			const updatedDisplay = displayValues.concat(newTags);
			setEmails([...new Set(updatedEmails)]);
			setDisplayedValues([...new Set(updatedDisplay)]);

			if (data.contacts.remaining) {
				getEmailsFromSegment({
					variables: {...variables, cursor: data.contacts.cursor},
				});
			} else {
				setLoading(false);
			}
		},
	});
	const [getEmailsFromDynamicSegment, {variables: dynVar}] = useLazyQuery(
		DYNAMIC_SEGMENT_EMAILS,
		{
			fetchPolicy: "cache-and-network",
			onError: () => setLoading(false),
			onCompleted: data => {
				if (!data) return;
				const newEmails: string[] = data.contacts.items.map(user => user.email);
				const newTags: string[] = data.contacts.items.map(user => user.creatorTag);
				const updatedEmails = emails.concat(newEmails);
				const updatedDisplay = displayValues.concat(newTags);
				setDisplayedValues([...new Set(updatedDisplay)]);
				setEmails([...new Set(updatedEmails)]);

				if (data.contacts.remaining) {
					getEmailsFromDynamicSegment({
						variables: {...dynVar, cursor: data.contacts.cursor},
					});
				} else {
					setLoading(false);
				}
			},
		},
	);
	const [sendSurveyToMembers] = useMutation(SEND_SURVEY_TO_MEMBERS);

	const handleSubmit = async(): Promise<void> => {
		setIsUpdating(true);
		await onSend(emails, anonymous);
		const {message, from, jobTitle} = emailer;
		if (notifyViaEmail) {
			sendSurveyToMembers({
				variables: {
					surveyId: overrideSurvey ? overrideSurvey.id : surveyId,
					customMessage: message,
					senderId: from || undefined,
					jobTitle,
					emailsToNotify: emails,
					useUserIds,
					userIds,
					skipPartials,
					useAnswersFilter: useFilter,
					answersFilter: filter,
				},
			});
			resetValues();
			setNotifyViaEmail(true);
		}
		setIsUpdating(false);
	};

	/**
	 * If there are values on open, we want to clear all the values to reset.
	 */
	useEffect(() => {
		if (isOpen && displayValues.length > 0) {
			setDisplayedValues([]);
			setEmails([]);
			if (inputRef.current) inputRef.current.innerText = "";
		}
	}, [isOpen]);


	const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>): void => {
		e.preventDefault();
		const pasted = e.clipboardData.getData("text/plain").split(/[\s,\n]+/);
		const {validEmails, invalidEmails} = siftEmails(pasted);
		const updatedList = [...displayValues, ...validEmails];
		const lowerCase = updatedList.map(val => val.toLowerCase());
		const uniqueEmails = uniq(validEmails);
		const newEmails = uniqueEmails.map(val => val.toLocaleLowerCase());
		const concatedEmails = emails.concat(newEmails);
		setDisplayedValues(uniq(lowerCase));
		setEmails(uniq(concatedEmails));
		if (invalidEmails.length > 0 && inputRef.current) {
			inputRef.current.innerText += invalidEmails.join(" ");
		}
	}

	const handleNotifyViaEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setNotifyViaEmail(event.target.checked);
	};

	const handleAnonymous = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setAnonymous(event.target.checked);
		if (event.target.checked) emailer.setFrom("");
	};

	const handleSkipPartial = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setSkipPartials(!e.target.checked);
	};

	const handleInput = (newTag: string[]): void => {
		const updatedDisplayVals = displayValues.concat(newTag);
		const updatedEmails = emails.concat(newTag);
		setDisplayedValues([...new Set(updatedDisplayVals)]);
		setEmails([...new Set(updatedEmails)]);
	}

	const handleRemove = (index: number): void => {
		const updatedEmails = [...emails];
		const updatedDisplay = [...displayValues];
		updatedEmails.splice(index, 1);
		updatedDisplay.splice(index, 1);
		setDisplayedValues(updatedDisplay);
		setEmails(updatedEmails);
	}

	/**
	 * @param workspaceId workspace ID for looking up the contacts
	 * @param segmentId segmentID for the filter to get the contacts
	 */
	const handleAddFromSegment = (
		workspaceIdValue: string,
		segmentId: string,
	): void => {
		setLoading(true);
		getEmailsFromSegment({
			variables: {workspaceId: workspaceIdValue, segmentId},
		});
	};

	const handleAddFromDynamic = (
		workspaceIdVal: string,
		dynamicSegmentId: string,
	): void => {
		setLoading(true);
		getEmailsFromDynamicSegment({
			variables: {workspaceId: workspaceIdVal, dynamicSegmentId},
		});
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title="Invite Creators"
			size="medium"
			className={styles.modal}
		>
			<StatusBanner
				overrideSurvey={overrideSurvey}
			/>
			{!(useFilter || useUserIds) && (
			// disable adding names manually if the parent component is providing the selection
				<>
					<SegmentAdder
						addEmails={handleAddFromSegment}
						addDynamic={handleAddFromDynamic}
					/>
					<TagInput
						tags={displayValues}
						handleEnter={handleInput}
						ref={inputRef}
						removeTag={handleRemove}
						label="Enter one or more email addresses separated by commas"
						onPaste={handlePaste}
					/>
				</>
			)}
			<div className={styles.checkboxes}>
				<Checkbox
					className={(useFilter || useUserIds) ? styles.checkboxNoMargin : styles.checkbox}
					id="notify-checkbox"
					size="s"
					text={(
						<Body size="xs" color="text-tertiary">
							Notify via email
						</Body>
					)}
					checked={notifyViaEmail}
					onChange={handleNotifyViaEmail}
				/>
				<Checkbox
					className={(useFilter || useUserIds) ? styles.checkboxNoMargin : styles.checkbox}
					id="anonymous-checkbox"
					size="s"
					text={(
						<Body size="xs" color="text-tertiary">
							Send anonymously
						</Body>
					)}
					checked={anonymous}
					onChange={handleAnonymous}
				/>
				<Checkbox
					className={(useFilter || useUserIds) ? styles.checkboxNoMargin : styles.checkbox}
					id="partial-checkbox"
					size="s"
					text={(
						<Body size="xs" color="text-tertiary">
							Allow sending to partial completes
						</Body>
					)}
					checked={!skipPartials}
					onChange={handleSkipPartial}
				/>
			</div>
			{notifyViaEmail && <Emailer {...emailerProps} anonymous={anonymous} />}
			<div className={styles.cta}>
				<Button
					onClick={handleSubmit}
					disabled={
						(displayValues.length === 0 && !useUserIds && !useFilter) ||
						isUpdating ||
						loading}
				>
					{/* Not sure what happened here, but this should be refactored. */}
					{
						loading
							? "Adding creators..."
							: notifyViaEmail
								? `Save & Notify (
									${
		useFilter
			? numFilterSelected
			: useUserIds
				? userIds?.length
				: displayValues.length
		}
																)`
								: `Save (
																	${
		useFilter
			? numFilterSelected
			: useUserIds
				? userIds?.length
				: displayValues.length
		}
						)`
					}
				</Button>
				{isUpdating && (
					<div className={styles.blocker}>
						<Spinner />
						<span>Processing...</span>
					</div>
				)}
			</div>
		</Modal>
	);
};

export {InviteToSurveyModal};
