
import {Outlet, useLocation} from "react-router";
import classNames from "classnames/bind";
import React, {ReactElement, useMemo} from "react";

import {Navigation} from "../navigation";
import {NavigationContentErrorBoundary} from "../../../error-boundaries/views/navigation-content-error-boundary";
import {ROUTES_WITHOUT_NAVIGATION_PANEL} from "../../../shared/constants/constants";

import styles from "./layout.module.scss";

const bStyles = classNames.bind(styles);

export const Layout = (): ReactElement => {
	const location = useLocation();

	const displaySubNavigation = useMemo(
		() => !ROUTES_WITHOUT_NAVIGATION_PANEL.some(route => location.pathname.includes(route)),
		[location.pathname]
	);

	return (
		<div className={bStyles("layoutWrapper", {displaySubNavigation})}>
			{displaySubNavigation && <Navigation />}

			<NavigationContentErrorBoundary>
				<Outlet />
			</NavigationContentErrorBoundary>
		</div>
	);
};
