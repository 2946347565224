import React, {ReactElement, useEffect, useRef, useState} from "react";
import classNames from "classnames/bind";

import {client} from "../../utility/client";
import {CloseMediumIcon, PauseStopSoundMusicIcon, PlayMusicSoundIcon} from "../../../icons";
import {Loader} from "../loaders/loader";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./audio-player.module.scss";

const bStyles = classNames.bind(styles);

export interface AudioPlayerProps {
  apiUrl: string;
  onClose: () => void;
}

export const AudioPlayer = ({apiUrl, onClose}: AudioPlayerProps): ReactElement | null=> {
	const {isDarkMode} = useThemeMode();
	const audioRef = useRef<HTMLAudioElement>(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [progress, setProgress] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [isClosed, setIsClosed] = useState(false);
	const [audioSrc, setAudioSrc] = useState<string | undefined>(undefined);

	useEffect(() => {
		const fetchAudio = async () => {
			try {
				setIsLoading(true);

				const blob = await client.get(apiUrl).blob();
				const blobUrl = URL.createObjectURL(blob);
				setAudioSrc(blobUrl);
			} catch (error) {
				console.error('Error fetching audio:', error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchAudio();
	}, []);

	useEffect(() => {
		const audio = audioRef.current;

		const updateProgress = () => {
			if (audio) {
				setProgress((audio.currentTime / audio.duration) * 100);
			}
		};

		const onLoadedData = () => {
			setIsLoading(false);
			if (audio) {
				audio.play().then(() => {
					setIsPlaying(true);
				}).catch(err => {
					console.log('Autoplay was prevented:', err);
					setIsPlaying(false);
				});
			}
		};

		if (audio) {
			audio.addEventListener('timeupdate', updateProgress);
			audio.addEventListener('loadeddata', onLoadedData);

			// Check if the audio is already loaded
			if (audio.oncanplaythrough) {
				onLoadedData();
			}
		}

		return () => {
			if (audio) {
				audio.removeEventListener('timeupdate', updateProgress);
				audio.removeEventListener('loadeddata', onLoadedData);
			}
		};
	}, []);

	const togglePlayPause = () => {
		const audio = audioRef.current;
		if (audio) {
			if (isPlaying) {
				audio.pause();
			} else {
				audio.play();
			}
			setIsPlaying(!isPlaying);
		}
	};

	const handleClose = () => {
		setIsClosed(true);
		const audio = audioRef.current;
		if (audio) {
			audio.pause();
			audio.currentTime = 0;
		}
		onClose();
	};

	const handleProgressClick = (e: React.MouseEvent<HTMLDivElement>) => {
		const audio = audioRef.current;
		if (audio) {
			const progressBar = e.currentTarget;
			const rect = progressBar.getBoundingClientRect();
			const clickPosition = e.clientX - rect.left;
			const clickProgress = clickPosition / rect.width;
			const newTime = clickProgress * audio.duration;

			audio.currentTime = newTime;
			setProgress(clickProgress * 100);
		}
	};

	if (isClosed) {
		return null;
	}

	return <div className={bStyles("wrapper", {
		open: !isLoading && !isClosed,
		closing: isClosed,
	})}>
		<audio
			ref={audioRef}
			src={audioSrc}
			preload="metadata"
		/>

		{isLoading ? <Loader size="small"/> :
			<div className={bStyles("controls", {isDarkMode})}>
				{isPlaying ?
					<PauseStopSoundMusicIcon className={bStyles("icon")} onClick={togglePlayPause} /> :
					<PlayMusicSoundIcon className={bStyles("icon")} onClick={togglePlayPause} />
				}
				<div
					className={styles.progressContainer}
					onClick={handleProgressClick}
				>
					<div className={styles.progressBar} style={{width: `${progress}%`}}></div>
				</div>
				<div
					tabIndex={0}
					onClick={handleClose}
					className={styles.closeButtonWrapper}
				>
					<CloseMediumIcon className={bStyles("icon", "closeIcon")} />
				</div>
			</div>
		}
	</div>
}
