import React, {ReactElement, useEffect, useRef, useState} from "react";
import {Transcript} from "../../models/transcript";
import {CutDropdown} from "../cut-dropdown";
import styles from "./clip-transcript.module.scss";

export interface ClipTranscriptProps {
	startTime: number;
	endTime: number;
	updateClip: (start: number, end: number) => void;
	disabled?: boolean;
	transcript?: Transcript;
}

const ClipTranscript = (props: ClipTranscriptProps): ReactElement => {
	const {startTime, endTime, updateClip, disabled = false, transcript} = props;
	const [selection, setSelection] = useState<Range>();
	const ref = useRef<HTMLDivElement>(null);

	const handleSelection = ({target}): void => {
		if (ref.current?.contains(target) && window.getSelection()?.toString()) {
			setSelection(window.getSelection()?.getRangeAt(0));
		}
	};

	const cleanUpEmptyElem = (collection, parent): void => {
		collection.forEach(elem => {
			if (elem.textContent.length < 1) {
				parent.removeChild(elem);
			}
		});
	};

	const resetSelection = (): void => setSelection(undefined);

	/**
	 * Little messier than highlights.
	 * Need to rid of the previous mark every time, then find new anchors and highlight
	 * between them.
	 */
	useEffect(() => {
		 
		if (!(transcript && ref.current && startTime && endTime && !disabled)) return;
		try {
			const {current} = ref;
			const currentMark = current.querySelector("mark");
			if (currentMark) currentMark.replaceWith(...currentMark.children);
			const range = new Range();
			for (const child of current.children) {
				const start = Number(child.getAttribute("data-start"));
				const end = Number(child.getAttribute("data-end"));
				if (startTime >= start && startTime <= end) {
					range.setStart(child, 0);
				}
				if (endTime >= start && endTime <= end) {
					range.setEnd(child, 2);
					break; // Break because start should already be set.
				}
			}
			const mark = document.createElement("mark");
			if (range.startContainer.isSameNode(range.endContainer)) {
				mark.appendChild(range.startContainer);
				range.insertNode(mark);
				return;
			}
			const contents = range.extractContents();
			mark.appendChild(contents);
			range.insertNode(mark);
			cleanUpEmptyElem(
				[].slice.call(range.commonAncestorContainer.childNodes),
				range.commonAncestorContainer,
			);
		} catch {
			 
			console.log("Range not found for word");
		}
	}, [startTime, endTime, transcript]);

	return (
		<>
			{
				transcript && transcript.words.length > 0 ?
					<div ref={ref} onMouseUp={handleSelection} className={styles.container}>
						{transcript.words.map((word, i) =>
							<span
								key={`${word.word}-${i}`}
								data-start={word.startAt}
								data-end={word.endAt}
							> {word.word}</span>)}
					</div> : transcript && transcript.text?.length > 0 ? <span>transcript.text</span> : <span>Transcript not available</span>
			}
			<CutDropdown
				range={selection}
				rect={selection?.getBoundingClientRect()}
				closeDropdown={resetSelection}
				updateClip={updateClip}
			/>
		</>
	);
};

export {ClipTranscript};
