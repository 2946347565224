import styles from "./builder-form.module.scss";
import React, {ReactElement} from "react";
import classnames, {Argument} from "classnames";

export interface FormHeaderProps {
	title: string;
	className?: Argument;
	toggle?: JSX.Element;
}

export interface FormProps {
	children: React.ReactNode;
	title?: string;
	toggle?: JSX.Element;
}
export interface FormControlProps {
	children: React.ReactNode;
	className?: Argument;
	labelClassName?: Argument;
	label?: string;
	id?: string;
}

const FormHeader = (props: FormHeaderProps): ReactElement => <>
	<div className={classnames(styles.header, props.className)}>
		{props.title}
		{props.toggle}
	</div>
</>;

const Form = (props: FormProps): ReactElement => <>
	{props.title &&
		<FormHeader title={props.title} toggle={props.toggle} />
	}
	<div className={styles.grid}>
		{props.children}
	</div>
</>;

const FormControl = (props: FormControlProps): ReactElement => <>
	<label
		className={classnames(styles.label, props.labelClassName)}
		htmlFor={props.id}
	>
		{props.label}
	</label>

	<div className={classnames(styles.control, props.className)}>
		{props.children}
	</div>
</>;

export {Form, FormControl, FormHeader};
