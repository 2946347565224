import React, {ReactElement} from "react";
import {BaseModalProps, Button, Modal} from "../../shared/v2";
import styles from "./confirm-modal.module.scss";

export interface ConfirmModalProps extends BaseModalProps {
	/**
	 * What to do when user clicks on "I'm sure" button
	 */
	confirm: () => void;
	text: string | JSX.Element;
}

const ConfirmModal = ({isOpen, onClose, confirm, text}: ConfirmModalProps): ReactElement =>
	<Modal isOpen={isOpen} onClose={onClose} className={styles.modal}>
		{typeof text === "string" ? <p className={styles.default}>
			{text}
		</p> : text}
		<div className={styles.actions}>
			<Button
				onClick={confirm}
			>
				Yes
			</Button>
			<Button
				style="danger"
				onClick={onClose}
			>
				No
			</Button>
		</div>
	</Modal>;


export {ConfirmModal};
