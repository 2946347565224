import React, {ReactElement} from "react";
import {Reel} from "../../../models/reels";
import {Link} from "../../../route";
import styles from "./reel-link.module.scss";

export interface ReelLinkProps {
	reel: Reel;
}

const ReelLink = ({reel}: ReelLinkProps): ReactElement =>
	<div className={styles.container}>
		{
			reel.clip && reel.clip.answer ? (
				<img
					src={reel.clip.answer.video.thumbnail}
					className={styles.image}
				/>
			) : (reel.clip?.sourceVideo &&
					<img
						src={reel.clip.sourceVideo?.thumbnail}
						className={styles.image}
					/>) ?? <div className={styles.placeholder}/>
		}
		<div className={styles.reel}>
			<div className={styles.info}>
				<Link workspace to={`/reel/${reel.id}`} className={styles.link}>
					{reel.name}
				</Link>
				<p className={styles.name}>
					by {reel.creator?.firstName}{" "}
					{reel.creator?.lastName || `${reel.creator?.lastInitial}.`}
				</p>
			</div>
		</div>
	</div>;

export {ReelLink};
