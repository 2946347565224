/* eslint-disable @typescript-eslint/no-explicit-any*/
import {ApolloCache, Reference} from "@apollo/client";
import {updateObject} from "./utility";

/**
 *
 * @param cache ApolloCache to modify
 * @param workspaceId WorkspaceID if defined
 * @param newSurveyRef New reference created from writeFragment
 *
 * @returns boolean if successful or not
 */
export const updateSurveyDashboard = (
	cache: ApolloCache<any>,
	newSurveyRef: Reference | undefined,
): boolean => {
	return cache.modify({
		fields: {
			workspaceSurveys(existingSurveys = []) {
				const updatedSurveys = updateObject(existingSurveys, {
					items: [...existingSurveys.items, newSurveyRef],
				});
				return updatedSurveys;
			},
		},
	});
};

/**
 *
 * @param cache ApolloCache to modify
 * @param workspaceId WorkspaceID if any
 * @param survey The identified survey to delete from list
 * @param id ID of the survey
 *
 * @returns Boolean if successful or not
 */
export const deleteSurveyDashboard = (
	cache: ApolloCache<any>,
	survey: string | undefined,
	id: string,
): boolean => {
	cache.modify({
		fields: {
			workspaceSurveys(existingSurveys, {readField}) {
				// Updating the SurveyPage to remove selected ID
				const updatedSurveys = updateObject(existingSurveys, {
					items: existingSurveys.items.filter(
						surveyRef => id !== readField("id", surveyRef),
					),
				});
				return updatedSurveys;
			},
		},
	});
	return cache.evict({id: survey});
};
