import React, {ReactElement} from "react";
import styles from "./simple-image-input.module.scss";
import {Icon, IconTypes} from "../icon";
import {CssSpinner} from "../css-spinner";
import {MediaState} from "../../../hooks/useMediaUpload";
import classNames, {Argument} from "classnames";
import {Body} from "../../../shared/v2/typography";

export interface SimpleImageInputProps {
	id: string;
	media: MediaState;
	emptyIcon?: IconTypes;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	className?: Argument
	helpText?: string;
	iconSize?: "small" | "medium" | "tiny" | "extrasmall" | "large" | "extralarge" | "supreme";
	isProcessing?: boolean;
	disabled?: boolean;
}

/**
 * Use case is to make a simpler image input whose styles are more easily controlled
 * from outside of the component.
 *
 * Might make this the default image input if I figure out how I want to do this.
 */
const SimpleImageInput = (props: SimpleImageInputProps): ReactElement => {
	const {emptyIcon, id, className, media, onChange, isProcessing, helpText, iconSize, disabled = false} = props;
	const fileType = media?.type ?? "image";

	return (
		<label htmlFor={id} className={classNames(className, fileType !== "image" && styles.error)}>
			{media?.preview && fileType === "image" ? (
				<>
					<img src={media?.preview} className={styles.loader}/>
					{isProcessing && <CssSpinner className={styles.loading}/> }
				</>
			 
			)	: fileType !== "image" ? <div>.png, .jpg, .jpeg, .gif only</div>
				: <div className={styles.empty}>
					<Icon name={emptyIcon || "plus"} size={iconSize}/>
					{helpText && <Body size="s" color="gray-modern-900">{helpText}</Body>}
				</div>}
			<input
				style={{display: "none"}}
				id={id}
				className={styles.input}
				type="file"
				onChange={onChange}
				disabled={disabled}
			/>
		</label>
	);
};

export {SimpleImageInput};
