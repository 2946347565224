import React, {ReactElement} from "react";

import {InputBubble} from "./input-bubble";
import {isUserInputMessage} from "./utils/isUserInputMessage";
import {ResponseBubble} from "./response-bubble";
import {ScrollableList} from "./scrollable-list";
import {Spinner} from "../../shared/v2";
import {useChatConversationContext, useChatMessagesContext, useChatSendQuestionContext} from "../../context/chat-contexts";

export interface ChatProps {
	className?: string;
  wrapperClassName?: string;
}

export const Chat = (props: ChatProps): ReactElement => {
	const {isInitialLoading: isLoadingChat} = useChatConversationContext();
	const {isSendingFirstQuestion} = useChatSendQuestionContext();
	const {messages} = useChatMessagesContext();

	if (isLoadingChat && !isSendingFirstQuestion) {
		return <Spinner />;
	}

	return (
		<ScrollableList {...props}>
			{messages
				?.slice()
				.sort((a, b) => a.position - b.position)
				.map((message, index) =>
					isUserInputMessage(message) ? (
						<InputBubble key={message.id} content={message.content} />
					) : (
						<ResponseBubble
							key={index}
							message={message}
						/>
					)
				)}
		</ScrollableList>
	);
};
