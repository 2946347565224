import React, {ReactElement} from "react";
import {SurveyStatus} from "../../../models/survey";
import styles from "./status-badge.module.scss";

export interface StatusBadgeProps {
	/**
	 * Status to display. Current are open, draft, archived, and closed.
	 * Case insensitive
	 */
	status: SurveyStatus;
}

/**
 * Is a display component only.
 * @param status - Status to display. If unsupported value, this component will not
 * display.
 *
 * @returns A status badge ReactElement
 */
const StatusBadge = (props: StatusBadgeProps): ReactElement =>	<span
	className={[
		styles.status,
		styles[props.status.toLowerCase()],
	].join(" ")}
>
	{props.status === "OPEN" ? "live" : props.status.toLowerCase()}
</span>;

export {StatusBadge};
