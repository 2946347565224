import React, {ReactElement} from "react";

import styles from "./card.module.scss";
import classNames from "classnames/bind";
import {useThemeMode} from "../../../../context/theme-mode-context";

const bStyles = classNames.bind(styles);

export interface CardProps {
  className?: string;
  children?: ReactElement | ReactElement[] | null;
  id?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const Card = ({
	className,
	children,
	id,
	onClick,
	onMouseEnter,
	onMouseLeave,
}: CardProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	return <div
		className={bStyles("card", className, {isDarkMode})}
		id={id}
		onClick={onClick}
		onMouseEnter={onMouseEnter}
		onMouseLeave={onMouseLeave}
	>
		{children}
	</div>
}
