import classNames from "classnames/bind";
import React, {ReactElement, ReactNode} from "react";

import {CloseMediumIcon} from "../../../icons";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./close-icon-wrapper.module.scss";

const cx = classNames.bind(styles);

export interface CloseIconWrapperProps {
  onClose: () => void;
  className?: string;
  disabled?: boolean;
  children: ReactNode;
}

export const CloseIconWrapper = ({onClose, children, disabled, className}: CloseIconWrapperProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	return (
		<div className={cx("closeIconWrapper", className, {disabled})}>
			{children}

			<button
				className={cx("closeIcon", {isDarkMode})}
				onClick={onClose}
				type="button"
			>
				<CloseMediumIcon />
			</button>
		</div>
	);
}
