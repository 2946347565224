import {Choice} from "../../models/choice";
import {RechartBarData} from "../components/ranked-bar-chart";

export const computeRankedAverage = (array: number[], total: number): number => {
	// + 1 is for offset so we start counting at 1 instead of 0.
	const reduced = array.reduce((prev, current, index) => prev + (current * (index + 1)), 0);
	return parseFloat((reduced / total).toPrecision(2));
};

interface Frequency {
	[key: number]: number;
}
export const computeMode = (f: Frequency): string[] => {
	const keys = Object.keys(f);
	const values = Object.values(f);
	const highest = Math.max(...values.map(v => (v === null ? 0 : v)));
	const modes: string[] = [];

	// With the highest repeated value, only return keys that are equal
	if (highest === 0) return modes;
	keys.forEach(key => {
		if (f[key] === highest) modes.push(key);
	});
	return modes;
};

/**
 * Returns data in the format that rechart can read.
 */
export const convertToRechartData = (data: Choice[], total: number): RechartBarData[] => {
	const barData: RechartBarData[] = data.map(choice => {
		const freq = choice.frequency.reduce((prev, currentValue = 0, index) => {
			prev[index + 1] = currentValue;
			return prev;
		}, {});
		return {
			...freq,
			name: choice.text,
			average: computeRankedAverage(choice.frequency, total),
			mode: computeMode(freq),
		};
	}).sort((a, b) => a.average - b.average);

	return barData;
};
