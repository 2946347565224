/* eslint-disable @typescript-eslint/no-explicit-any*/

import {ApolloCache, gql} from "@apollo/client/core";
import {SurveyNotification} from "../../models/survey";

export const updateAddSurveyNotification = (
	cache: ApolloCache<any>,
	surveyId: string,
	notification: SurveyNotification,

): boolean => {
	return cache.modify({
		id: `Survey:${surveyId}`,
		fields: {
			notifications(existingNotificationRefs = [], {readField}) {
				const newNotificationRef = cache.writeFragment({
					data: notification,
					fragment: gql`
						fragment NewSurveyNotification on SurveyNotification {
							id
							hoursBefore
							surveyId
						}
					`,
				});

				if (existingNotificationRefs.some(
					ref => readField("id", ref) === notification.id,
				)) {
					return existingNotificationRefs;
				}

				return [...existingNotificationRefs, newNotificationRef];
			},
		},
	});
};



export const updateDeleteSurveyNotification = (
	cache: ApolloCache<any>,
	surveyId: string,
	id: string,
): void => {
	cache.modify({
		id: `Survey:${surveyId}`,
		fields: {
			notifications(existingNotificationsRef, {readField}) {
				return existingNotificationsRef.filter(
					notificationRef => id !== readField("id", notificationRef),
				);
			},
		},
	});
};

