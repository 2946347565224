import React, {ReactElement, useMemo} from "react";

import {Campaign} from "../../../models/ai-model";
import {DocumentsFolderFileIcon, HornMegaphoneCampaignIcon} from "../../../icons";
import {TrainingSet} from "../../../models";
import {useChatCampaignContext, useChatTrainingSetContext} from "../../../context/chat-contexts";

import styles from "./sources-section.module.scss";
import {InputChip} from "../../../shared/components/input-chip";


export const isTrainingSet = (source: TrainingSet | Campaign): source is TrainingSet => {
	return "alias" in source;
}

export const SourcesSection = (): ReactElement => {
	const {
		activeTrainingSets,
		saveTrainingSetIds,
		isUpdatingDisabled: isTrainingSetUpdatingDisabled,
	} = useChatTrainingSetContext();
	const {
		activeCampaigns,
		saveCampaigns,
		isUpdatingDisabled: isCampaignUpdatingDisabled,
	} = useChatCampaignContext();

	const handleRemoveTrainingSet = (trainingSet: TrainingSet) => {
		saveTrainingSetIds(activeTrainingSets?.filter(ts => ts.id !== trainingSet.id).map(ts => ts.id) || []);
	}

	const handleRemoveCampaign = (campaign: Campaign) => {
		saveCampaigns(activeCampaigns.filter(c => c.id !== campaign.id).map(c => c.id));
	}

	const sources = useMemo(() => {
		return [...(activeTrainingSets || []), ...activeCampaigns];
	}, [activeTrainingSets, activeCampaigns]);

	const renderSource = (source: TrainingSet | Campaign) => {
		if (isTrainingSet(source)) {
			return (
				<InputChip
					className={styles.source}
					icon={<DocumentsFolderFileIcon />}
					key={source.id}
					onRemove={() => handleRemoveTrainingSet(source)}
					disabled={isTrainingSetUpdatingDisabled}
					label={source.alias}
					variant="squared"
					size="small"
				/>
			);
		}

		return (
			<InputChip
				variant="squared"
				className={styles.source}
				icon={<HornMegaphoneCampaignIcon />}
				key={source.id}
				disabled={isCampaignUpdatingDisabled}
				onRemove={() => handleRemoveCampaign(source)}
				label={source.name}
				size="small"
			/>
		);
	};

	if (!sources.length) {
		return <></>;
	}

	return (
		<div className={styles.sourcesSection}>
			{sources.map(renderSource)}
		</div>
	);
}
