import {useDropzone} from 'react-dropzone'
import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {PhotoCameraIcon} from "../../../icons";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./image-drop-area.module.scss";

const cx = classNames.bind(styles);

export interface ImageDropAreaProps {
  file: File | null;
  onDrop: (acceptedFiles: File) => void;
  size?: "sm" | "md";
  className?: string;
}

export const ImageDropArea = ({file, onDrop, size = "md", className}: ImageDropAreaProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {getRootProps, getInputProps} = useDropzone({
		onDrop: (acceptedFiles) => onDrop(acceptedFiles[0]),
		maxFiles: 1,
		accept: {
			"image/*": [".jpg", ".jpeg", ".png"],
		},
	})

	return (
		<div
			className={cx("dropzone", `size-${size}`, className, {isDarkMode, hasFile: Boolean(file)})}
			{...getRootProps()}
		>
			<input {...getInputProps()} />
			<div className={styles.iconOuterLayer}>
				<div className={styles.iconInnerLayer}>
					<PhotoCameraIcon className={styles.icon} />
				</div>
			</div>

			{file && (
				<>
					<img
						alt="preview"
						className={styles.preview}
						src={URL.createObjectURL(file)}
					/>
					<div className={styles.previewHoverEffect} />
				</>
			)}

			<div className={styles.customBorder} />
		</div>
	);
};
