import React, {ReactElement, useEffect, useRef, useState} from "react";
import styles from "./media-switcher.module.scss";
import {CollectionVideo} from "../../../models/collections";
import classNames, {Argument} from "classnames";
import {Icon, convertToClockTime} from "../../../shared";

export interface MediaSwitcherProps {
	data: CollectionVideo;
	className?: Argument;
}

const MediaSwitcher = ({data, className}: MediaSwitcherProps): ReactElement => {
	const [showVideo, setShowVideo] = useState(false);
	const ref = useRef<HTMLDivElement>(null);

	const handleSwitchToVideo = (): void => setShowVideo(true);
	const checkClick = ({target}): void => {
		if (!ref.current) return;
		const {current} = ref;
		if (current?.contains(target)) return;
		setShowVideo(false);
	};

	useEffect(() => {
		if (!showVideo) return;
		document.addEventListener("mousedown", checkClick);
		return () => document.removeEventListener("mousedown", checkClick);
	}, [showVideo]);

	return (
		<div className={classNames(className, styles.container)} ref={ref}>
			{showVideo ? <video src={data.mp4} className={styles.media} controls/>
				: <>
					<img src={data.image} className={styles.media}/>
					<Icon
						clicked={handleSwitchToVideo}
						name="play-solid"
						className={styles.play}
						size="extrasmall"
						isClickable
					/>
					<p className={styles.time}>
						{convertToClockTime(data.duration, "milliseconds")}
					</p>
				</>}
		</div>
	);
};

export {MediaSwitcher};
