
import React, {ReactElement} from "react";
import classNames, {Argument} from "classnames";
import styles from "./input.module.scss";

export interface InputContainerProps {
	className?: Argument;
	label?: string;
	id?: string;
	children?: React.ReactNode;
	html2CanvasIgnore?: boolean;
	isDarkMode?: boolean;
}

export interface InputProps<T> extends InputContainerProps{
	value?: T;
	onChange: (newValue: T) => void;
	disabled?: boolean;
	name?: string;
}

const Input = ({className, label, id, children, html2CanvasIgnore}: InputContainerProps): ReactElement =>
	<div className={classNames(styles.container, className)} data-html2canvas-ignore={html2CanvasIgnore}>
		{label && <label htmlFor={id} className={styles.label}>{label}</label>}
		{children}
	</div>;

export {Input};

// All exports here
export {DatetimeInput} from "./datetime-input";
export type {DatetimeInputProps} from "./datetime-input";
// End exports
