import React, {ReactElement, cloneElement} from "react";

import {Body} from "../typography";

import styles from "./empty-state.module.scss";

export interface EmptyStateProps {
	title: string;
	description: string;
	icon: ReactElement;
}

export const EmptyState = ({
	title,
	description,
	icon,
}: EmptyStateProps): ReactElement => {
	return <div className={styles.container}>
		<div className={styles.content}>
			{cloneElement(icon, {className: styles.icon})}
			<div className={styles.descriptions}>
				<Body size="xl" type="semibold">{title}</Body>

				<Body color="text-secondary">{description}</Body>
			</div>
		</div>
	</div>
}
