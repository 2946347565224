import classNames from "classnames/bind";
import {ButtonHTMLAttributes, ReactElement, ReactNode, createElement} from "react";

import {useThemeMode} from "../../../../context/theme-mode-context";

import styles from "./button-icon.module.scss";

const cx = classNames.bind(styles);

export interface ButtonIconProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	className?: string;
	icon: ReactNode;
	color?: "text-body" | "text-secondary" | "gray-modern";
  size?: "small" | "default";
	filledIcon?: boolean;
}

const ButtonIcon = ({
	className,
	icon,
	color = "text-body",
	size = "default",
	filledIcon,
	...props
}: ButtonIconProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	return createElement(
		props.onClick ? "button" : "div",
		{className: cx("buttonIcon", className, color, size, {filledIcon}, {isDarkMode}), ...props},
		icon,
	);
};

export {ButtonIcon}
