import classNames from "classnames/bind";
import React, {ReactElement} from "react";
import styles from "./align-text.module.scss";

const bStyles = classNames.bind(styles);
export interface AlignTextProps {
	align: "left" | "right" | "center"
	children: React.ReactNode;
}

const AlignText = ({align, children}: AlignTextProps): ReactElement =>
	<div className={bStyles(align)}>
		{children}
	</div>;

export {AlignText};
