import React, {ReactElement, useReducer, useState} from "react";
import {Setter} from "../types";
import {ClipDeleteData} from "../models/clip";
import {DELETE_CLIP} from "../graphql/mutations/clip-mutations";
import {ToastItem} from "../shared";
import {useMutation} from "@apollo/client";
import {updateCacheDeletePageItemByFieldId} from "../shared/utility/update-cache";

interface ReelContext {
	/**
	 * The ID of the reel
	 */
	reelId: string;
	/**
	 * Error. Currently we only have a "title" error.
	 */
	error: "title" | undefined;
	/**
	 * Index for determining which clip we are playing
	 */
	index: number;
	/**
	 * If the reel is playing
	 */
	isPlaying: boolean;
	/**
	 * If the reel is currently buffering / waiting for more data to load
	 */
	isBuffering: boolean;
	/**
	 * If we are showing the share screen or not
	 */
	isShowingShareScreen: boolean;
	/**
	 * Set playing state
	 */
	togglePlaying: () => void;
	handleSetError: Setter<"title" | undefined>;
	/**
	 * Toggle the share screen state
	 */
	toggleShareScreen: () => void;
	/**
	 * Set buffering state
	 */
	setIsBuffering: Setter<boolean>;
	/**
	 * Delete clip callback
	 */
	handleDeleteClip: (id: string, i: number) => void;
	/**
	 * Callback to function to duplicate a clip
	 */
	duplicateClipCallback: (id: string) => void;
	/**
	 * Dispatcher for index reducer
	 */
	indexDispatch: React.Dispatch<IndexReducerActions>;
	updateToast: (newToast: ToastItem) => void;
	setClipId: Setter<string>;
}

const ReelContext = React.createContext<ReelContext>({
	reelId: "",
	error: undefined,
	index: 0,
	isBuffering: false,
	isPlaying: false,
	isShowingShareScreen: false,
	handleDeleteClip: () => undefined,
	duplicateClipCallback: () => undefined,
	handleSetError: () => undefined,
	togglePlaying: () => undefined,
	toggleShareScreen: () => undefined,
	setIsBuffering: () => undefined,
	indexDispatch: () => undefined,
	updateToast: () => undefined,
	setClipId: () => undefined,
});

export interface ReelContextProps {
	/**
	 * Children to render for the context
	 */
	children: React.ReactNode;
	/**
	 * Reel ID
	 */
	reelId: string;
	/**
	 * Duplicate clip callback
	 */
	duplicateClipCallback: (id: string) => void;
	updateToast: (newToast: ToastItem) => void;
	setClipId: Setter<string>;
}

interface IndexReducerActions {
	/**
	 * Action type
	 */
	type: "increment" | "decrement" | "reset" | "set";
	/**
	 * Used to set to a specific index number.
	 */
	number?: number;
}

const ReelContextProvider = (props: ReelContextProps): ReactElement => {
	const {
		children,
		reelId,
		duplicateClipCallback,
		updateToast,
		setClipId,
	} = props;

	const [deleteClip] = useMutation<ClipDeleteData, {id: string}>(DELETE_CLIP, {
		onCompleted: () => updateToast({description: "Clip removed", type: "informational"}),
	});
	const [error, setError] = useState<"title" | undefined>();
	/**
	 * This state will be used to determine if the reel is playing or not.
	 * Helps to determine what clip is playing as well as telling us that we are actually
	 * playing the reel.
	 */
	const [isPlaying, setIsPlaying] = useState<boolean>(false);
	const [showShareScreen, setShowShareScreen] = useState<boolean>(false);

	/**
	 * State used to determine if we are buffering / loading more data.
	 * Will help stop / start timer as well as the ticker
	 */
	const [isBuffering, setIsBuffering] = useState<boolean>(false);

	/**
	 * Trying to use a reducer for this particular state since it is a counter and it might
	 * be useful to attempt it this way.
	 *
	 * Will be used to keep track of where the current video is and what should be playing.
	 */
	const [index, dispatch] = useReducer((state: number, action: IndexReducerActions) => {
		switch (action.type) {
		case "increment":
			if (isPlaying) setIsPlaying(false);
			return state + 1;
		case "decrement":
			if (isPlaying) setIsPlaying(false);
			return state - 1;
		case "set":
			if (action.number !== undefined) {
				if (isPlaying) setIsPlaying(false);
				return action.number;
			}
			return state;
		case "reset":
			if (isPlaying) setIsPlaying(false);
			return 0;
		default:
			throw Error("Failed state update");
		}
	}, 0);

	/**
	 * Toggles the isPlaying state.
	 */
	const togglePlaying = (): void => {
		setIsPlaying(prev => !prev);
	};

	const toggleShareScreen = (): void => {
		setShowShareScreen(prev => !prev);
	};

	const handleDeleteClip = (id: string, i: number): void => {
		if (i && i === index) dispatch({type: "decrement"});
		deleteClip({
			variables: {id},
			update(cache, {data: deleteData}) {
				if (deleteData) {
					const clipDuration =
					deleteData.deleteClip.endTime - deleteData.deleteClip.startTime;
					updateCacheDeletePageItemByFieldId(
						cache,
						"clips",
						"clip",
						reelId,
						deleteData.deleteClip.id,
					);
					cache.modify({
						id: `Reel:${reelId}`,
						fields: {
							duration(currentDuration: number) {
								return currentDuration - clipDuration;
							},
						},
					});
				}
			},
		});
	};

	return (
		<ReelContext.Provider value={{
			reelId,
			error,
			index,
			isBuffering,
			isPlaying,
			isShowingShareScreen: showShareScreen,
			handleDeleteClip,
			duplicateClipCallback,
			handleSetError: setError,
			setIsBuffering,
			togglePlaying,
			toggleShareScreen,
			indexDispatch: dispatch,
			updateToast,
			setClipId,
		}}>
			{children}
		</ReelContext.Provider>
	);
};

export {ReelContextProvider, ReelContext};
