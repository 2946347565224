import {DocumentNode, useMutation} from "@apollo/client";
import {useState} from "react";

import {UPLOAD_FILE} from "../graphql/mutations/mutations";
import {UploadItemData, UploadFileVars} from "../models/questions";
import {useToastContext} from "../context/toast-context";
import {useSafeFetchFile} from "./useSafeFetchFile";

export interface UseFileUploadProps {
  createPictureMutation: DocumentNode;
  imageUrl: string;
}

export const useFileUpload = <T>(props: UseFileUploadProps) => {
	const {
		imageUrl,
		createPictureMutation,
	} = props;
	const [uploadFile] = useMutation<UploadItemData, UploadFileVars>(UPLOAD_FILE);
	const {updateToast} = useToastContext();
	const [createPicture] = useMutation(createPictureMutation);
	const [file, setFile] = useState<File | null>(null);

	const {refetch} = useSafeFetchFile({url: imageUrl, setFile})

	const upload = (file: File): File => {
		setFile(file);
		return file;
	}

	const save = async (innerFile?: File | null): Promise<T | undefined> => {
		innerFile = innerFile || file;
		if (!innerFile) {
			return;
		}

		const {data: uploadData} = await uploadFile({
			variables: {file: innerFile},
			onError: () => {
				updateToast({
					type: "failure",
					description: "Failed to upload picture",
				});
			}
		});

		if  (!uploadData) {
			return;
		}

		const {data: createPictureData} = await createPicture({
			variables: {
				uploadItemId: uploadData.upload.id,
			},
			onError: () => {
				updateToast({
					type: "failure",
					description: "Failed to create picture",
				});
			}
		});

		if (createPictureData) {
			return createPictureData[Object.keys(createPictureData)[0]] as T;
		}
	}

	return {
		upload,
		save,
		file,
		reset: refetch,
	}
}
