import React, {ReactNode, useState} from "react";

import {CreateTrainingSetModal} from "./create-training-set-modal";
import {TrainingSet} from "../models/ai-model";
import {UploadFilesModal} from "../shared/v2/modals/upload-files-modal";
import {useTrainingSetUpload} from "../hooks/useTrainingSetUpload";

export interface UseFileUploaderProps {
	setTrainingSet: (trainingSet: TrainingSet) => void;
}

export interface UseFileUploaderReturn {
	modals: ReactNode;
	openUploadModal: () => void;
}

export const useFileUploader = ({setTrainingSet}: UseFileUploaderProps): UseFileUploaderReturn => {
	const {
		files,
		setFiles,
	} = useTrainingSetUpload();

	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

	const [isCreateTrainingSetModalOpen, setIsCreateTrainingSetModalOpen] = useState(false);

	const handleClearFiles = (): void => {
		setIsUploadModalOpen(false);
		setFiles([]);
	};

	const handleSave = ():void => {
		setIsCreateTrainingSetModalOpen(true);
	};

	return ({
		modals: (
			<>
				<UploadFilesModal
					isOpen={isUploadModalOpen}
					onClose={() => setIsUploadModalOpen(false)}
					onFinish={handleSave}
					files={files}
					setFiles={setFiles}
				/>

				<CreateTrainingSetModal
					isOpen={isCreateTrainingSetModalOpen}
					onClose={() => setIsCreateTrainingSetModalOpen(false)}
					clearFiles={handleClearFiles}
					files={files}
					setTrainingSet={setTrainingSet}
				/>
			</>
		),
		openUploadModal: () => setIsUploadModalOpen(true),
	});
};
