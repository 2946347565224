import React, {ReactElement, useState} from "react";
import {ListsPage} from "..";
import {Select} from "../../../shared";
import {SegmentsPage} from "../segments";
import styles from "./list-segments.module.scss";

const ListAndSegments = (): ReactElement => {
	const [view, setView] = useState("lists");

	const select = <Select
		id="select-view"
		selectedValue={view}
		options={[{
			text: "Lists",
			value: "lists",
		},
		{
			text: "Segments",
			value: "segments",
		}]}
		onChange={setView}
		className={styles.select}
	/>;
	return (
		<>
			{view === "lists" ? <ListsPage selector={select} /> : <SegmentsPage selector={select}/>}
		</>
	);
};

export {ListAndSegments};
