import {RadioGroup as Base} from '@headlessui/react';
import classNames from 'classnames/bind';
import React, {ReactElement} from 'react';

import {Body} from '../../typography';
import {useThemeMode} from '../../../../context/theme-mode-context';

import styles from "./radio-option.module.scss";

const cx = classNames.bind(styles);

export type RadioOptionSize = "s" | "m";

export interface RadioOptionProps<T> {
  className?: string;
  label?: string | ReactElement;
  disabled?: boolean;
  size?: RadioOptionSize;
  value: T;
}

export function RadioOption<T>(
	{
		value,
		disabled,
		className,
		size = "m",
		label,
	}: RadioOptionProps<T>
): ReactElement {
	const {isDarkMode} = useThemeMode();

	return (
		<Base.Option
			disabled={disabled}
			className={cx("option", className, `size-${size}`, {isDarkMode})}
			value={value}
		>
			{({checked}) => (
				<>
					<div className={cx("optionOuterCircle", {checked})}>
						<span className={styles.optionInnerCircle} />
					</div>

					{label && (
						<Body color="text-secondary">
							{label}
						</Body>
					)}
				</>
			)}
		</Base.Option>
	)
}
