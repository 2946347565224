 
import React, {ReactElement} from "react";
import classNames, {Argument} from "classnames";

export interface PlayButtonProps {
	/**
	 * If the current video is playing or not
	 */
	isPlaying: boolean;
	/**
	 * Action to take on pressing button
	 */
	onClick: () => void;
	/**
	 * Fill color if needed
	 */
	fill?: string;
	className?: Argument;
}
/**
 * A play or pause button determined by props passed to it.
 * @param isPlaying Determines whether to show the play button or pause button
 * @param onClick Function to happen when clicking on the button
 */
const PlayButton = (props: PlayButtonProps): ReactElement => {
	const {isPlaying, onClick, fill, className} = props;

	/**
	 * Returns what button we should display
	 */
	const getButton = (): JSX.Element => {
		if (isPlaying) {
			return <>
				<rect width="10" height="24" rx="1" fill={fill || "white"}/>
				<rect x="15" width="10" height="24" rx="1" fill={fill || "white"}/>
			</>;
		}
		return <>
			<path d="M23.2509 12.2669L8.0506 2.88324C6.71814 2.06067 5 3.01917 5 4.58507V23.4045C5 24.9726 6.72251 25.9307 8.05481 25.1037L23.2551 15.6679C24.5172 14.8845 24.5149 13.0472 23.2509 12.2669Z" fill={fill || "black"}/>
			<path d="M23.2509 12.2669L8.0506 2.88324C6.71814 2.06067 5 3.01917 5 4.58507V23.4045C5 24.9726 6.72251 25.9307 8.05481 25.1037L23.2551 15.6679C24.5172 14.8845 24.5149 13.0472 23.2509 12.2669Z" fill={fill || "white"}/>
		</>;
	};
	return (
		<svg
			onClick={onClick}
			width={24}
			className={classNames(className)}
			height={24}
			viewBox={"0 0 28 28"}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			{getButton()}
		</svg>
	);
};

export {PlayButton};
