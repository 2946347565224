/* eslint-disable react/prop-types */

import React, {ReactElement, useEffect} from "react";
import classNames, {Argument} from "classnames";
import Mark from "mark.js";
import {getCurrent} from "../../../ref";

interface KeywordHighlighterProps {
	children: React.ReactNode;
	id?: string;
	searchValue?: string;
	className?: Argument;
	highlightClassName?: Argument;
	onMouseUp: ({target}) => void;
}
/**
 * The goal of this component is to, at least for now, replace the old
 * search keyword highlighter on transcripts.
 *
 * This will look through the entire containining div, rather than each individual span.
 */

const KeywordHighlighter = React.forwardRef<HTMLDivElement, KeywordHighlighterProps>((props, ref): ReactElement => {
	const {children, className, highlightClassName, id, searchValue, onMouseUp} = props;

	const replaced = searchValue?.replace(/['"]+/g, "");
	useEffect(() => {
		const current = getCurrent(ref);
		if (!current) return;
		const marker = new Mark(current);
		marker.unmark();
		if (replaced) {
			marker.mark(replaced, {className: classNames(highlightClassName),
				element: "span",
				// Each node in this case would be the direct child of the span that has a data-id.

				each: node => node.setAttribute("data-id", String(node?.parentElement?.getAttribute("data-id")))});
		}
	}, [searchValue, ref]);
	return <div ref={ref} id={id} className={classNames(className)} onMouseUp={onMouseUp}>
		{children}
	</div>;
});

KeywordHighlighter.displayName = "KeywordHighlighter";

export {KeywordHighlighter};
