import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import {useThemeMode} from "../../../../context/theme-mode-context";

import styles from "./button-group.module.scss";

const bStyles = classNames.bind(styles);

export interface ButtonGroupProps {
	children: ReactElement[] | ReactElement;
  className?: string;
}

export const ButtonGroup = ({children, className}: ButtonGroupProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	return <div className={bStyles("group", className, {isDarkMode})}>
		{children}
	</div>;
};
