import React, {ReactElement, useContext} from "react";
import {useNavigate} from "react-router";
import {useMutation} from "@apollo/client";

import {DELETE_CHOICE, DELETE_QUESTION} from "../../graphql/mutations/survey-mutations";
import {Question} from "../../models/questions";
import {SurveyContext} from "../../context/survey-context";
import {BaseModalProps, Body, Button, Modal} from "../../shared/v2";
import {updateCacheQuestionCount} from "../../shared/utility/update-questions";
import {toggleDeleteQuestionModal} from "../../cache";
import {updateCacheDeletePageItem} from "../../shared/utility/update-cache";

import styles from "./delete-question.module.scss";

export interface DeleteQuestionModalProps extends BaseModalProps {
	/**
	 * Type of deletion (mostly for text)
	 */
	type: "question" | "choice";
	question?: Question;
}

/**
 * A confirm modal for deleting a question OR a singular choice.
 * Shows up only if selected question / choice has at least one answer associated
 * with it.
 */
const DeleteQuestionModal = (props: DeleteQuestionModalProps): ReactElement | null => {
	const {isOpen, type, question, onClose} = props;
	const {survey: {id: surveyId, workspaceId}} = useContext(SurveyContext);
	const navigate = useNavigate();

	const [deleteQuestionMutation] = useMutation(DELETE_QUESTION, {
		onCompleted: ({deleteQuestion}) => {
			if (question?.id === deleteQuestion.id) {
				navigate({
					pathname: `/survey/${surveyId}/questions`,
					search: `workspaceId=${workspaceId}`,
				});
			}
		},
	});
	const [deleteChoice] = useMutation(DELETE_CHOICE);

	const handleDeleteOption = (id: string): void => {
		deleteChoice({
			variables: {id},
			update(cache, {data}) {
				cache.modify({
					id: `Question:${question?.id}`,
					fields: {
						choices(existingChoices = [], {readField}) {
							const items = [...existingChoices];
							const updatedChoices = items.filter(
								ref => {
									return data.deleteChoice.id !== readField("id", ref);
								},
							);
							return updatedChoices;
						},
					},
				});
				cache.evict({id: `Choice:${data.deleteChoice.id}`, broadcast: false});
				cache.gc();
			},
		});
	};
	const handleDeleteQuestion = (id: string): void => {
		deleteQuestionMutation({
			variables: {id},
			update(cache, {data: deleteData}) {
				if (deleteData) {
					 
					updateCacheDeletePageItem(cache, "questions", "question", deleteData.deleteQuestion.id);
					updateCacheQuestionCount(cache, "subtract", surveyId);
				}
			},
		});
	};

	/**
	 * Calls handleConfirm callback and then closes the modal.
	 */
	const handleYes = (): void => {
		if (type === "choice") {
			handleDeleteOption(toggleDeleteQuestionModal().id);
		} else {
			handleDeleteQuestion(toggleDeleteQuestionModal().id);
		}
		onClose();
	};
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			className={styles.modal}
		>
			<Body type="bold" size="s" className={styles.warning}>
				There are responses associated with this {type}. Deleting
				this {type} will delete all responses. Are you sure you want to delete?
			</Body>
			<div className={styles.actions}>
				<Button
					onClick={handleYes}
				>
					Yes
				</Button>
				<Button
					style="danger"
					onClick={onClose}
				>
					No
				</Button>
			</div>
		</Modal>
	);
};

export {DeleteQuestionModal};
