import React, {FormEvent, ReactElement, useState} from "react";
import {fetchSignInMethodsForEmail} from "firebase/auth";
import {FirebaseError} from "@firebase/util";

import {Button} from "../../../shared/v2";
import {Input} from "../../input";
import {useToastContext} from "../../../context/toast-context";
import {auth} from "../../../utils/firebase";
import {StepTemplate} from "../../step-template";
import {SignUpModalActionTypes, SignUpModalStep} from "../../../reducer/sign-up-modal-reducer";
import {useSignUpModal} from "../../../context/sign-up-modal.context";

import styles from "./sign-in-login-step.module.scss";

export const SignInLoginStep = (): ReactElement => {
	const {dispatch} = useSignUpModal();
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState("");
	const {updateToast} = useToastContext();

	const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		changeEmail(email);
		isEmailInDatabase();
	};

	const changeStep = (step: SignUpModalStep): void => {
		dispatch({
			type: SignUpModalActionTypes.SET_CURRENT_STEP,
			payload: {step},
		});
	};

	const changeEmail = (payload: string): void => {
		dispatch({type: SignUpModalActionTypes.SET_EMAIL, payload});
	};

	const isEmailInDatabase = async(): Promise<void> => {
		try {
			const options = await fetchSignInMethodsForEmail(auth, email);

			if (options.includes("password")) {
				changeStep(SignUpModalStep.Password);
			} else if (options.length > 0) {
				if (options[0] === "google.com") {
					updateToast({
						description: "You already have an account. Please log in with Google.",
						type: "informational",
					});
				}

				changeStep(SignUpModalStep.Initial);
			} else {
				changeStep(SignUpModalStep.SignUp);
			}
		} catch (error) {
			if (!(error instanceof FirebaseError)) {
				throw error;
			}
			const {code} = error;
			if (code === "auth/invalid-email") {
				setEmailError("Please enter a valid email address.");
				return;
			}
			setEmailError(error.message);
		}
	};

	const handleChangeEmail = (e: FormEvent<HTMLInputElement>): void => {
		setEmail(e.currentTarget.value);
		setEmailError("");
	};

	return <StepTemplate header="Log in">
		<form className={styles.loginForm} onSubmit={handleSubmit}>
			<Input
				placeholder="Email"
				value={email}
				onChange={handleChangeEmail}
				errorMessage={emailError}
			/>

			<div className={styles.buttonsSection}>
				<Button
					disabled={Boolean(emailError)}
					type="submit"
				>
            Next
				</Button>
			</div>
		</form>
	</StepTemplate>

};
