
import React, {FormEvent, ReactElement, useEffect, useState} from "react";
import {useSignInWithEmailAndPassword} from "react-firebase-hooks/auth";

import {Input} from "../../input";
import {Body, Button} from "../../../shared/v2";
import {auth} from "../../../utils/firebase";
import {useSignUpModal} from "../../../context/sign-up-modal.context";
import {SignUpModalActionTypes, SignUpModalStep} from "../../../reducer/sign-up-modal-reducer";
import {StepTemplate} from "../../step-template";

import styles from "./sign-in-password-step.module.scss";

export const SignInPasswordStep = (): ReactElement => {
	const [password, setPassword] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const {
		state: {
			email,
		},
		dispatch,
	} = useSignUpModal();

	const [
		signInWithEmailAndPassword,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		userCredential,
		loading,
		error,
	] = useSignInWithEmailAndPassword(auth);

	const changeStep = (step: SignUpModalStep): void => {
		dispatch({
			type: SignUpModalActionTypes.SET_CURRENT_STEP,
			payload: {step},
		});
	};

	useEffect(() => {
		if (email === undefined) {
			changeStep(SignUpModalStep.Initial);
		}
	}, [email]);

	useEffect(() => {
		if (error) {
			if (error.code === "auth/wrong-password") {
				setPasswordError("The password you entered doesn't match");
				return;
			}
			if (error.code === "auth/too-many-requests") {
				setPasswordError("Access to this account has been temporarily disabled due to too many failed login attempts. You can immediate restore access by resetting your password.");
				return;
			}
			setPasswordError(error.message);
		}
	}, [error]);

	const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		if (!password) {
			setPasswordError("Please enter a password");
			return;
		}

		signInWithEmailAndPassword(email ?? "", password);
	};

	const handleRecoverPassword = (): void => {
		dispatch({
			type: SignUpModalActionTypes.SET_CURRENT_STEP,
			payload: {step: SignUpModalStep.RecoverPassword},
		});
	};

	return <StepTemplate header="Log in">
		<form className={styles.form} onSubmit={handleSubmit}>
			<Input
				placeholder="Password"
				type="password"
				value={password}
				errorMessage={passwordError}
				onChange={e => {
					setPassword(e.target.value);
					setPasswordError("");
				}}
			/>
			<Body className={styles.recoverPasswordWrapper} onClick={handleRecoverPassword}>
        Trouble signing in?
			</Body>
			<div className={styles.buttonsSection}>
				<Button
					id="sign-in-email-next"
					disabled={loading || Boolean(passwordError)}
					type="submit"
				>
          Next
				</Button>
			</div>
		</form>
	</StepTemplate>


}
