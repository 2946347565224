import React, {ReactElement} from "react";
import styles from "./heading.module.scss";

export interface HeadingProps {
	/**
	 * children. Just the text for the heading, usually
	 */
	children: React.ReactNode;
	/**
	 * Size of heading
	 */
	size: "xl" | "lg" | "md" | "sm";
	/**
	 * Any additional class names to give to heading
	 */
	additionalClasses?: string;
}

const elements = {
	xl: "h1",
	lg: "h2",
	md: "h3",
	sm: "h4",
};

const Heading = (props: HeadingProps): ReactElement => {
	const {children, size, additionalClasses} = props;
	return React.createElement(
		elements[size] || elements.md,
		{className: [styles[size], additionalClasses].join(" ")},
		children,
	);
};

export {Heading};
