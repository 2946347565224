import React, {ReactElement, cloneElement, useMemo} from "react";
import classNames from "classnames/bind";

import {Caption} from "../../../../shared/v2/typography";
import {ChevronDownIcon, ChevronRightIcon, ChevronUpIcon} from "../../../../icons";
import {NavLink} from "../../../../route";
import {NavLinkProps} from "../../../../route/components";
import {useThemeMode} from "../../../../context/theme-mode-context";

import styles from "./link.module.scss";

const cx = classNames.bind(styles);

export interface RedirectProps extends Omit<NavLinkProps, "className"> {
	type: "redirect";
}

interface ExpandProps {
	type: "expand";
	onClick: () => void;
	isExpanded: boolean;
}

interface UnderlineBaseProps {
	type: "underline";
}
export type UnderlineProps = UnderlineBaseProps & ({onClick: () => void} | Omit<RedirectProps, "type">);

export type LinkProps = {
	className?: string;
	text?: string;
	children?: ReactElement | string;
	type: "redirect" | "expand" | "underline";
	size?: "base" | "l";
	weight?: "regular" | "semibold" | "medium";
  colorVariant?: "blue-dark"
} & (RedirectProps | ExpandProps | UnderlineProps)

export const Link = ({
	className,
	text,
	children,
	size = "base",
	type,
	weight = "semibold",
	colorVariant,
	...props
}: LinkProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	const onClick = "onClick" in props ? props.onClick : undefined;
	const to = "to" in props ? props.to : undefined;
	const isExpanded = "isExpanded" in props ? props.isExpanded : undefined;
	const isWebLink = to && to.startsWith("http");

	const icon = useMemo(() => {
		if (type === "redirect") {
			return <ChevronRightIcon />;
		}

		if (type === "expand") {
			return isExpanded ? <ChevronUpIcon /> :  <ChevronDownIcon />;
		}

		return <></>;
	}, [type, props]);

	const content = (
		<>
			<Caption
				color="blue-dark-400"
				type={weight}
				size={size}
				className={styles.text}
			>
				{text ?? children}
			</Caption>

			{cloneElement(icon, {className: styles.icon})}
		</>
	)

	if (isWebLink) {
		return (
			<a
				className={cx("link", className, type, size, weight, colorVariant, {isDarkMode})}
				href={to.toString()}
				target="_blank"
				rel="noreferrer"
			>
				{content}
			</a>
		);
	}
	return (
		<NavLink
			className={cx("link", className, type, size, weight, colorVariant, {isDarkMode})}
			to={to}
			onClick={onClick}
			{...props}
		>
			{content}
		</NavLink>
	);
}
