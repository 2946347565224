import React, {ReactElement, useState} from "react";
import {useMutation} from "@apollo/client";
import classNames from "classnames/bind";

import {CREATE_TRAINING_SET} from "../../../graphql/mutations/ai-mutations";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {GET_TRAINING_SETS} from "../../../graphql/queries/ai-models-queries";
import {useToastContext} from "../../../context/toast-context";
import {BaseModalProps, Button, Modal} from "../../../shared/v2";
import {useThemeMode} from "../../../context/theme-mode-context";
import {TrainingSetForm} from "../../../datasets/components";

import styles from "./add-training-set-modal.module.scss";

const bStyles = classNames.bind(styles);

export const AddTrainingSetModal = (props: BaseModalProps): ReactElement => {
	const {updateToast} = useToastContext();
	const {isDarkMode} = useThemeMode();

	const {isOpen, onClose} = props;
	const [createTraingingSet, {loading: isCreating}] = useMutation(CREATE_TRAINING_SET, {
		refetchQueries: [GET_TRAINING_SETS],
	});
	const {
		workspace: {id: workspaceId},
	} = useWorkspaceContext();

	const [name, setName] = useState("");
	const [description, setDescription] = useState("");

	const handleCreate = (): void => {
		createTraingingSet({
			variables: {
				name,
				workspaceId,
				description,
			},
			onCompleted: () => {
				setName("");
				setDescription("");
				onClose();
			},
			onError: error => {
				const errorMessage = error.message.includes("trainingsets_workspaceid_name_unique") ?
					"The name needs to be unique across workspace." : error.message;

				updateToast({
					description: errorMessage,
					type: "failure",
				});
			},
		});
	};

	return (
		<Modal
			title="Create new dataset"
			description="A dataset groups data for Al analysis,
      enhancing targeted insights and content generation based on your specific needs."
			isOpen={isOpen}
			onClose={onClose}
			className={bStyles("modal", {isDarkMode})}
		>

			<TrainingSetForm
				name={name}
				setName={setName}
				description={description}
				setDescription={setDescription}
			/>
			<Button
				disabled={!name || isCreating}
				onClick={handleCreate}
				className={styles.button}
			>
        Create dataset
			</Button>
		</Modal>
	);
};
