import {USER_FRAGMENT} from "../fragments/fragments";
import {gql} from "@apollo/client";

/**
 * Updates the workspace
 */
export const UPDATE_WORKSPACE = gql`
 mutation UpdateWorkspace (
	 $id: GUID!
	 $changes: UpdateWorkspaceChanges
	 $deletions: UpdateWorkspaceDeletions
	) {
		updateWorkspace (id: $id changes: $changes deletions: $deletions) {
			id
			name
			logoId
			myRole
			plan
            forceLogout 
            forceLogoutPeriodMin
		}
	}
`;
/**
 * Removes a member of the workspace
 * Can do multiple, but currently UI only supports removing one at a time.
 */
export const REMOVE_WORKSPACE_MEMBER = gql`
	mutation RemoveWorkspaceMembers ($workspaceId: GUID! $userIds: [GUID!]!) {
		removeWorkspaceMembers(workspaceId: $workspaceId userIds: $userIds) {
			workspace {
				id
				name
			}
			users {
				...UserFields
			}
		}
	}
	${USER_FRAGMENT}
`;


/**
 * Sets a workspace plan.
 * Requires permission of the form set{Plan}Plan (camel case)
 * If someone has manageBilling, they have all the set permissions.
 */
export const SET_WORKSPACE_PLAN = gql`
	mutation SetWorkspacePlan ($id: GUID! $plan: WorkspacePlan!) {
		setWorkspacePlan(id: $id plan: $plan) {
			id
			name
			plan
			billingStatus
			hasSubscription
		}
	}
`;
