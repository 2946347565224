import {useNavigate} from "react-router";
import {useMutation, useQuery} from "@apollo/client";
import React, {ReactElement, useMemo, useState} from "react";

import {AiOrchestrationDeleteData, AiOrchestrationsData} from "../../../models/ai-orchestration";
import {Button, EmptyState, LoadingContainer, SearchInput} from "../../../shared/v2";
import {ModelsIcon, PlusSmallIcon} from "../../../icons";
import {DELETE_AI_ORCHESTRATION} from "../../../graphql/mutations/ai-mutations";
import {GET_AI_ORCHESTRATIONS} from "../../../graphql/queries/ai-orchestration-queries";
import {DeleteConfirmModal} from "../../../modals/delete-reel";
import {WorkflowCard} from "../../components/workflow-card";
import {UserRoles} from "../../../models/user";
import {useToastContext} from "../../../context/toast-context";
import {useUserContext} from "../../../context/user-context";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {CreateWorkflowModal} from "../../modals/create-workflow-modal";
import {CreateCard} from "../../../shared/v2/cards";

import styles from "./flows-page.module.scss";

const FlowsPage = (): ReactElement => {
	const {workspace: {id}} = useWorkspaceContext();
	const {user: {role}} = useUserContext();
	const {updateToast} = useToastContext();
	const navigate = useNavigate();

	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const [idForDelete, setIdForDelete] = useState("");
	const [searchValue, setSearchValue] = useState<string>("");

	const {data, loading} = useQuery<AiOrchestrationsData>(GET_AI_ORCHESTRATIONS, {
		skip: role !== UserRoles.ENTERPRISE_MANAGER,
		variables: {workspaceId: id},
		fetchPolicy: "cache-first",
	});

	const [deleteOrchestration] = useMutation<AiOrchestrationDeleteData>(DELETE_AI_ORCHESTRATION);

	const filteredWorkflows = useMemo(() => {
		if (!data) return [];
		if (!searchValue) return data.aiOrchestrations;
		return data.aiOrchestrations.filter(workflow => workflow.name.toLowerCase().includes(searchValue.toLowerCase()));
	}, [data, searchValue]);

	const openWorkflowPage = (id: string): void => {
		navigate(`/workflow/flows/${id}`);
	}

	const handleDeleteWorkflow = (): void => {
		deleteOrchestration({
			variables: {id: idForDelete},
			onCompleted: () => {
				updateToast({description: "Deleted Workflow", type: "informational"});
				setIdForDelete("");
			},
			onError: () => {
				updateToast({description: "Failed to delete, please try again later", type: "failure"});
				setIdForDelete("");
			},
			update(cache, {data}) {
				if (!data) return;
				cache.modify({
					fields: {
						aiOrchestrations(existing = [], {readField}) {
							const copied = [...existing];
							const updatedOrchestras = copied.filter(
								ref => data.deleteAiOrchestration.id !== readField("id", ref),
							);
							return updatedOrchestras;
						},
					},
				});
				cache.evict({id: `AiOrchestra:${data.deleteAiOrchestration.id}`, broadcast: false});
			},
		});
	};

	const handleAddWorkflow = (id: string): void => {
		setIsCreateModalOpen(false);
		openWorkflowPage(id);
	};

	const renderContent = (): ReactElement => {
		if (loading) return <LoadingContainer />;

		return <div className={styles.cards}>
			<CreateCard wrapperClassname={styles.cardWrapper}>
				<Button
					leftIcon={<PlusSmallIcon className={styles.plusIcon} />}
					color="purple"
					onClick={() => setIsCreateModalOpen(true)}
				>
					Create new
				</Button>
			</CreateCard>
			{filteredWorkflows.map(workflow => (
				<WorkflowCard
					key={workflow.id}
					workflow={workflow}
					onClick={openWorkflowPage}
					handleDelete={id => setIdForDelete(id)}
				/>
			))}
		</div>
	}

	if (role !== UserRoles.ENTERPRISE_MANAGER) return (
		<EmptyState
			title="Flows"
			icon={<ModelsIcon />}
			description="Stay tuned! We're developing an easy way for you to create your own custom workflows using your data and agents"
		/>
	)
	return(
		<div className={styles.container}>
			<SearchInput
				className={styles.searchInput}
				value={searchValue}
				onChange={setSearchValue}
			/>
			{renderContent()}

			<DeleteConfirmModal
				isOpen={Boolean(idForDelete)}
				onClose={() => setIdForDelete("")}
				handleConfirm={handleDeleteWorkflow}
				warningText="Are you sure you want to delete this flow?"
			/>

			<CreateWorkflowModal
				isOpen={isCreateModalOpen}
				onClose={() => setIsCreateModalOpen(false)}
				onAdd={handleAddWorkflow}
			/>
		</div>
	);
};

export {FlowsPage};
