import {gql} from "@apollo/client";

import {SURVEY_FRAGMENT} from "../fragments/fragments";

export const CREATE_SURVEY_FROM_TEMPLATE = gql`
	mutation CreateSurveyFromTemplate($id: GUID!, $workspaceId: GUID!) {
		createSurveyFromTemplate(id: $id, workspaceId: $workspaceId) {
			...SurveyFields
		}
	}
	${SURVEY_FRAGMENT}
`;

export const CREATE_TEMPLATE_FROM_SURVEY = gql`
	mutation CreateSurveyTemplateFromSurvey(
		$surveyId: GUID!
		$changes: SurveyTemplateChanges!
	) {
		createSurveyTemplateFromSurvey(
			surveyId: $surveyId
			changes: $changes
		) {
			id
			title
			templateDescription
			templateHeadline
			questionCount
			categories {
				id
				name
			}
		}
	}
`;

export const UPDATE_SURVEY_TEMPLATE = gql`
	mutation CreateSurveyTemplateFromSurvey(
		$surveyTemplateId: GUID!
		$changes: SurveyTemplateChanges!
	) {
		updateSurveyTemplate(
			surveyTemplateId: $surveyTemplateId
			changes: $changes
		) {
			id
			title
			templateDescription
			templateHeadline
			questionCount
			categories {
				id
				name
			}
		}
	}
`;

export const DELETE_SURVEY_TEMPLATE = gql`
	mutation DeleteSurveyTemplate($surveyTemplateId: GUID!) {
		deleteSurveyTemplate(surveyTemplateId: $surveyTemplateId)
	}
`;
