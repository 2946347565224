import React, {FormEvent, ReactElement} from "react";
import {useSendPasswordResetEmail} from "react-firebase-hooks/auth";

import {Button} from "../../../shared/v2";
import {auth} from "../../../utils/firebase";
import {useSignUpModal} from "../../../context/sign-up-modal.context";
import {SignUpModalActionTypes, SignUpModalStep} from "../../../reducer/sign-up-modal-reducer";
import {StepTemplate} from "../../step-template";
import {Input} from "../../input";

import styles from "./recover-password-step.module.scss";

export const RecoverPasswordStep = (): ReactElement => {
	const [sendPasswordResetEmail] = useSendPasswordResetEmail(auth);
	const {
		state: {
			email,
		},
		dispatch,
	} = useSignUpModal();

	const changeStep = (step: SignUpModalStep): void => {
		dispatch({
			type: SignUpModalActionTypes.SET_CURRENT_STEP,
			payload: {step},
		});
	};

	const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		sendPasswordResetEmail(email ?? "");
		changeStep(SignUpModalStep.CheckYourEmail);
	};

	return (
		<StepTemplate
			header="Recover Password"
			description="Get instructions sent to this email that explain how to reset your password"
		>
			<form className={styles.form} onSubmit={handleSubmit}>
				<Input
					placeholder="Email"
					type="text"
					disabled
					value={email ?? ""}
				/>

				<div className={styles.buttonsSection}>
					<Button type="submit">Send</Button>
				</div>
			</form>
		</StepTemplate>
	);
};
