import {
	MultiSelect,
	MultiSelectProps,
} from "../../../shared";
import React, {ReactElement, useMemo, useState} from "react";
import {AgeRange, CustomPropsFilter, DateRange, Gender} from "../../../models/filter";
import {GET_ALL_ATTRIBUTES_W_EXTANT} from "../../../graphql/queries/attribute-queries";
import {Setter} from "../../../types";
import styles from "./filter-panel.module.scss";
import {Link, useFilter} from "../../../route";
import {useQuery} from "@apollo/client";
import {Panel} from "../../../shared/components/panel";
import {groupBy} from "lodash-es";
import {GET_FILTER_DATA} from "../../../graphql/queries/queries";
import {SearchableFilter} from "../../../shared/components/searchable-filter";
import {GET_ALL_SURVEY_NAMES} from "../../../graphql/queries/survey-queries";
import {SurveyNamePageData} from "../../../models/survey";
import {useLoadingQuery} from "../../../hooks";
import {ToggleSwitch} from "../../../shared/components/toggle-switch";
import {IdName} from "../../../models/generic";
import {useDateRange} from "../../../hooks/useDateRange";
import {DateRangeInput} from "../../../shared/components/date-range";
import {getCurrentDate} from "../../../shared/utility/utility";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {Body, Button, DebounceSearch, NumberInput} from "../../../shared/v2";

type ViewGroup = "properties" | "demographics" | "completes" | "all";

export type MultiSelectFilter = Omit<MultiSelectProps<string>, "onChange">;
interface FilterSelects {
	[key: string]: MultiSelectFilter;
}

export interface FilterPanelProps {
	segmentId?: string;
	campaignFilter?: boolean;
	closeFilter: Setter<boolean>;
	setPage?: Setter<number>;
}

export interface FilterDemoData {
	countries: IdName[];
	states: IdName[];
	ethnicities: IdName[];
	workspaceSurveys: {
		items: IdName[];
	}
}

const cleanDateRange = (dateRange: DateRange): DateRange | undefined => {
	if (dateRange.start && dateRange.end) return dateRange;
	if (dateRange.start && !dateRange.end) return {...dateRange, end: getCurrentDate()};
	return undefined;
};

const FilterPanel = (
	{segmentId, closeFilter, setPage, campaignFilter = false}: FilterPanelProps,
): ReactElement => {
	const {membersFilter, updateFilter} = useFilter(segmentId);
	const [demoSelect, setDemoSelect] = useState<FilterSelects>({
		gender: {
			id: "gender",
			label: "Gender",
			options: [
				{id: Gender.MALE, name: "Male"},
				{id: Gender.FEMALE, name: "Female"},
				{id: Gender.NONBINARY, name: "Nonbinary"},
			],
			value: membersFilter?.gender ?? [],
			isSearchable: false,
		},
		countries: {
			id: "countries",
			label: "Countries",
			options: [{id: "loading", name: "Loading..."}],
			isSearchable: true,
			value: membersFilter.countryId ?? [],
		},
		ethnicities: {
			id: "ethnicities",
			label: "Ethnicity",
			options: [{id: "loading", name: "Loading..."}],
			isSearchable: false,
			value: membersFilter.ethnicityId ?? [],
		},
		states: {
			id: "states",
			label: "State / Province",
			options: [{id: "loading", name: "Loading..."}],
			isSearchable: true,
			value: membersFilter.stateId ?? [],
		},
	});
	const [multipleSelects, setMultipleSelects] = useState<FilterSelects>({});
	const [minAge, setMinAge] = useState<number>(membersFilter?.ageRange?.min || 0);
	const [maxAge, setMaxAge] = useState<number>(membersFilter?.ageRange?.max || 0);
	const [search, setSearch] = useState("");
	const [surveyIds, setSurveyIds] = useState(membersFilter?.hasTakenSurvey?.surveyIds || []);
	const [haveNot, setHaveNot] = useState<boolean>(membersFilter?.hasTakenSurvey?.haveNot || false);
	const [any, setAny] = useState(membersFilter?.hasTakenSurvey?.any || false);
	const [view, setView] = useState<ViewGroup>("all");
	const [firstSurvey, setFirstSurvey, resetFirstSurvey]
		= useDateRange(membersFilter?.firstSurveyResponseAt);
	const [lastSurvey, setLastSurvey, resetLastSurvey]
		= useDateRange(membersFilter?.lastSurveyResponseAt);
	const [firstFeedback, setFirstFeedback, resetFirstFeedback] =
		useDateRange(membersFilter?.firstFeedbackSurveyResponseAt);
	const [lastFeedback, setLastFeedback, resetLastFeedback]
		= useDateRange(membersFilter?.lastFeedbackSurveyResponseAt);
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const demoKeys = useMemo(() => Object.keys(demoSelect).map(key => key), []);

	/**
	 * I don't really like this right now, but because of how apollo works with cache,
	 * and by timing (race condition?), it seems that if we load the countries from the cache,
	 * it seems to overwrite the country data in the state as it is too fast and triggers
	 * re-renders at different times than on first load. no-cache will keep it consistent,
	 * but also require the user to query for the countries each time they open up the filter.
	 */
	const {loading: cLoading} = useQuery<FilterDemoData>(GET_FILTER_DATA, {
		fetchPolicy: "cache-first",
		onCompleted: data => {
			if (!data) return;
			// onCompleted seems to run just on render of the component so we check for data
			setDemoSelect(current => ({
				...current,
				countries: {
					...current.countries,
					options: data.countries,
				},
				ethnicities: {
					...current.ethnicities,
					options: data.ethnicities,
				},
				states: {
					...current.states,
					options: data.states,
				},
			}));
		},
	});

	/**
	 * Loads the attributes, and adds them to multipleSelects,
	 * Which is then map to the filter dropdowns (demographicsFilters customPropsFilters)
	 */
	const {loading} = useQuery(GET_ALL_ATTRIBUTES_W_EXTANT, {
		variables: {workspaceId},
		onCompleted: data => {
			// onCompleted loads the attributes into multipleSelects
			if (data?.attributes?.items) {
				const newValues = {};
				data.attributes.items.forEach(a => {
					// ignore partial & unused attributes
					if (a?.name && a?.id && a?.extantValues) {
						// UPDATE: we no longer ignore long values because of foreign languages, mostly
						const options = a.extantValues?.map(v => v).sort((A, B) => {
							if (isNaN(A) && isNaN(B)) {
								if (A < B) return -1;
								if (B < A) return 1;
								return 0;
							}
							if (isNaN(A)) return 1;
							if (isNaN(B)) return -1;
							return A - B;
						})?.map(v => {
							return {id: v, name: v};
						}) || [];
						// ignores attributes with only long text
						if (options.length > 0) {
							newValues[a.id] = {
								id: a.id,
								label: a.name,
								category: a.category.name,
								options,
								isSearchable: true,
								value: membersFilter?.customProperties?.find(
									(x: CustomPropsFilter): boolean =>
										(x.id === a?.id),
								)?.values || [],
							};
						}
					}
				});
				setMultipleSelects(current => ({
					...current,
					...newValues,
				}));
			}
		},
	});

	const {data: campaignsData, fragment} =
	useLoadingQuery<SurveyNamePageData>(GET_ALL_SURVEY_NAMES, {
		skip: view !== "completes",
		variables: {workspaceId},
		what: "Campaigns",
	});

	/** ****************************************
	 * Functions to handle user interaction
	 ******************************************/

	const handleMultiSelect = (value: string[], id: string): void =>
		setMultipleSelects(current => ({
			...current,
			[id]: {...current[id], value},
		}));

	const handleDemoSelect = (value: string[], id: string): void =>
		setDemoSelect(current => ({
			...current,
			[id]: {...current[id], value},
		}));

	const resetDateRanges = (): void => {
		resetFirstFeedback();
		resetFirstSurvey();
		resetLastFeedback();
		resetLastSurvey();
	};

	const clearAllSelected = (): void => {
		setMinAge(0);
		setMaxAge(0);
		setSurveyIds([]);
		setHaveNot(false);
		setAny(false);
		Object.keys(multipleSelects).forEach(key => {
			handleMultiSelect([], key);
		});
		demoKeys.forEach(key => handleDemoSelect([], key));
		resetDateRanges();
	};

	const handleCampaignsFilter = (selected: {id: string, name: string}): void => {
		if (surveyIds.includes(selected.id)) {
			return setSurveyIds(prev => prev.filter(val => val !== selected.id));
		}
		setSurveyIds(prev => ([...prev, selected.id]));
	};

	const handleClearFilter = (): void => {
		setSurveyIds([]);
	};

	/**
	 * @returns Void. Pushes the new search string onto history and refreshes the page
	 */
	const handleFilter = (): void => {
		let age: AgeRange | undefined;
		if (maxAge) age = {min: minAge || 0, max: maxAge};
		const customProps: CustomPropsFilter[] = [];
		for (const key in multipleSelects) {
			if (multipleSelects[key]?.id && (multipleSelects[key]?.value?.length || 0) > 0) {
				customProps.push({
					id: multipleSelects[key].id,
					values: multipleSelects[key].value,
				});
			}
		}
		const newFilter = {
			gender: demoSelect.gender.value as Gender[],
			ageRange: age,
			countryId: demoSelect.countries.value,
			segmentId: membersFilter.segmentId,
			stateId: demoSelect.states.value,
			ethnicityId: demoSelect.ethnicities.value,
			customProperties: customProps,
			firstSurveyResponseAt: cleanDateRange(firstSurvey),
			lastSurveyResponseAt: cleanDateRange(lastSurvey),
			firstFeedbackSurveyResponseAt: cleanDateRange(firstFeedback),
			lastFeedbackSurveyResponseAt: cleanDateRange(lastFeedback),
			surveyIds,
			completed: campaignFilter ? surveyIds.length > 0 : undefined,
			haveNot: surveyIds.length > 0 ? haveNot : undefined,
			any: surveyIds.length > 0 ? any : undefined,
		};
		setPage?.(0);
		updateFilter(newFilter);
		closeFilter(false);
	};

	const campaigns = useMemo(() => campaignsData?.workspaceSurveys.items.map(
		c => ({id: c.id, name: c.name}),
	), [campaignsData]);

	const groupedFilters = useMemo(() => {
		const mapped = Object.keys(multipleSelects).map(key => multipleSelects[key]);
		return groupBy(mapped, "category");
	}, [multipleSelects, search]);

	const numFiltersUsed = useMemo(
		() => {
			const allButAge = Object.keys(multipleSelects)
				.map(key => multipleSelects[key]?.value)
				.filter(value => value?.length && value.length > 0).length;
			const demographicsCount = demoKeys.map(key => demoSelect[key]?.value)
				.filter(val => val?.length && val.length > 0).length;
			return (allButAge + demographicsCount)
			+ (maxAge > 0 && maxAge > minAge ? 1 : 0) + (surveyIds.length ? 1 : 0);
		}
		, [multipleSelects, maxAge, demoSelect, surveyIds],
	);

	const renderQueryPreview = (): JSX.Element | JSX.Element[] | undefined => {
		if (!(surveyIds && surveyIds.length > 0)) return undefined;
		if (surveyIds.length === campaignsData?.workspaceSurveys.items.length) {
			return <p className={styles.queryLine}>
				{haveNot ? "Have not completed" : "Have completed"}
				<b>&nbsp;{any ? "ANY" : "ALL"}&nbsp;</b>
				campaigns
			</p>;
		}
		return surveyIds.map((id, idx) =>
			<p className={styles.queryLine} key={id}>
				{haveNot ? "Have not completed" : "Have completed"}
				{" "}{campaignsData?.workspaceSurveys.items.find(i => i.id === id)?.name}
				{surveyIds.length > 1 && idx !== surveyIds.length - 1 &&
					<b>&nbsp;{any ? "OR" : "AND"}&nbsp;</b>
				}
			</p>);
	};

	const handleSelectAllCampaigns = (options: string[]): void => setSurveyIds(options);
	const categories = Object.keys(groupedFilters).sort();
	return (
		<Panel title="Filter" handleClose={() => closeFilter(false)} theme="purple">
			<div className={styles.container}>
				<div className={styles.properties}>
					<div className={styles.subHeading}>
						{!loading && !cLoading && view === "all" ?
							<DebounceSearch
								id="filter-input"
								onChange={value => setSearch(value.toLocaleLowerCase())}
								placeholder="Search"
								value={search}
							/> : <div className={styles.back} onClick={() => setView("all")}>&larr; Back</div>
						}
					</div>
					{view === "all" && !search && <div className={styles.views}>
						<span onClick={() => setView("demographics")}>Creator Information</span>
						<span onClick={() => setView("properties")}>Custom Properties</span>
						<span onClick={() => setView("completes")}>Survey Completion</span>
					</div>}
					{(view === "demographics" || (search && "age".includes(search))) &&
						<div className={styles["age-filter"]}>
							<label className={styles["age-label"]}>Age</label>
							<div className={styles["age-container"]}>
								<NumberInput
									id="min-age-range"
									min={0}
									value={minAge || 0}
									onChange={setMinAge}
								/>
								<NumberInput
									id="max-age-range"
									min={0}
									value={maxAge || 0}
									onChange={setMaxAge}
								/>
								{minAge > 0 && maxAge > 0 && maxAge < minAge &&
									<Body className={styles.warning}>Max age should be higher than min age</Body>
								}
							</div>
						</div>}
					{!cLoading && (view === "demographics" || search) &&
						demoKeys.filter(key => key.includes(search)).map(key => <MultiSelect
							key={demoSelect[key].id}
							{...demoSelect[key]}
							onChange={handleDemoSelect}
						/>)
					}
					{(view === "demographics" ||
						(search && "survey".includes(search.toLocaleLowerCase()))) && campaignFilter && <>
						<DateRangeInput
							id="first-survey-date"
							label="First Survey Completed"
							value={firstSurvey}
							onChange={setFirstSurvey}
						/>
						<DateRangeInput
							id="last-survey-date"
							label="Last Survey Completed"
							value={lastSurvey}
							onChange={setLastSurvey}
						/>
						<DateRangeInput
							id="first-feedback-date"
							label="First Story Completed"
							value={firstFeedback}
							onChange={setFirstFeedback}
						/>
						<DateRangeInput
							id="last-feedback-date"
							label="Last Story Completed"
							value={lastFeedback}
							onChange={setLastFeedback}
						/>
					</>}
					{(view === "properties" || search) &&
						(
							!loading && categories.length > 0 ?
							// Finding which categories have a select that matches search
								categories.filter(cat => {
									const grouped = groupedFilters[cat].filter(select => {
										if (search !== "" && !select.label.toLocaleLowerCase().includes(search)) {
											return null;
										}
										return select;
									});
									return grouped.length > 0;
								}).map(key => <div key={key} className={styles.category}>
									<h3>{key}</h3>
									{groupedFilters[key].filter(select => (search ?
										select.label.toLocaleLowerCase().includes(search) : select)).map(select =>
										<MultiSelect
											key={select.id}
											{...select}
											maxLength={50}
											onChange={handleMultiSelect}
										/>)}
								</div>) : <div className={styles.empty}>
									<p>Currently you have no available properties to filter on</p>
									<p>Click <Link to="/audience/properties" workspace>here</Link> create some!</p>
								</div>
						)
					}
					{view === "completes" &&
						<div className={styles.completesPanel}>
							{fragment || (campaigns && <>
								<span className={styles.label}>Campaigns</span>
								<SearchableFilter
									className={styles.spacing}
									options={campaigns}
									value={surveyIds}
									placeholder=" "
									onChange={handleCampaignsFilter}
									selectAll={handleSelectAllCampaigns}
									onClear={handleClearFilter}
									selectedLabel={surveyIds.length === 1 ? "1 campaign" :
										`${surveyIds.length} campaigns`}
								/>
								<div className={styles.spacing}>
									<div className={styles.toggles}>
										<div className={styles.toggle}>
											<span>Completed</span>
											<ToggleSwitch
												id="complete-toggle"
												isChecked={!haveNot}
												onChange={e => setHaveNot(!e.target.checked)}
												disabled={surveyIds.length === 0}
											/>
										</div>
										<div className={styles.toggle}>
											<span>Any</span>
											<ToggleSwitch
												id="any-toggle"
												isChecked={any}
												onChange={e => setAny(e.target.checked)}
												disabled={surveyIds.length === 0}
											/>
										</div>
									</div>
								</div>
							</>
							)
							}
							<div className={styles.queryPreview}>
								<p className={styles.queryHead}>Current filter will find creators that:</p>
								{renderQueryPreview()}
							</div>
						</div>
					}
					{(loading || cLoading) && <div>Loading filters... </div>}
				</div>
				<div className={styles.footer}>
					<Button
						onClick={handleFilter}
					>
						{numFiltersUsed ? `Apply Filters (${String(numFiltersUsed)})` : "Apply Filters"}
					</Button>

					<Button variant="outlined" onClick={clearAllSelected}>Clear Filters</Button>
				</div>
			</div>
		</Panel>
	);
};

export {FilterPanel};
