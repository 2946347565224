import classNames from "classnames/bind";
import React, {ReactElement} from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import {Body} from "../../../../shared/v2";
import {ChatConversationMessage} from "../../../../models/ai-model";
import LinkRenderer from "./link-reader";

import styles from "./content.module.scss";

const cx = classNames.bind(styles);

export interface ContentProps {
  message: ChatConversationMessage;
}

export const Content = ({message}: ContentProps): ReactElement => {
	const handleChart = (content: string) => {
		const imageUrlRegex = /(https:\/\/\S+\.(?:png|jpg|jpeg|gif))/i;
		const match = content.match(imageUrlRegex);

		if (match) {
			const imageUrl = match[0];
			const remainingText = content.replace(imageUrlRegex, "").trim();
			const resultArray = [imageUrl, remainingText];
			return resultArray;
		} else {
			return ["", content];
		}
	};

	return (
		<Body
			className={cx(message.type === "image" ? styles.imageContent : styles.content)}
			color="text-secondary"
		>
			{message.type === "chart" ? (
				<>
					<img style={{width: "100%"}} src={handleChart(message.content)[0]} />
					<ReactMarkdown
						remarkPlugins={[remarkGfm]}
						components={{
							a: LinkRenderer,
						}}
					>
						{handleChart(message.content)[1]}
					</ReactMarkdown>
				</>
			) : (
				<ReactMarkdown
					remarkPlugins={[remarkGfm]}
					components={{
						a: LinkRenderer,
					}}
				>
					{message.content}
				</ReactMarkdown>
			)}
		</Body>
	);
}
