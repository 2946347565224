import React, {ReactElement, useEffect, useState} from "react";
import {useMutation} from "@apollo/client";

import {Button, Input, Modal} from "../../../../../shared/v2";
import {UPDATE_WORKSPACE} from "../../../../../graphql/mutations/workspace-settings-mutations";
import {useWorkspaceContext} from "../../../../../context/workspace-context";

import styles from "./change-workspace-name-modal.module.scss";

export interface ChangeWorkspaceNameModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ChangeWorkspaceNameModal = (props: ChangeWorkspaceNameModalProps): ReactElement => {
	const {workspace} = useWorkspaceContext();
	const {isOpen, onClose} = props;

	const [name, setName] = useState(workspace?.name || "");

	const [updateWorkspace] = useMutation(UPDATE_WORKSPACE);

	useEffect(() => {
		setName(workspace?.name || "");
	}, [isOpen, workspace]);

	const handleSave = async () => {
		await updateWorkspace({
			variables: {
				id: workspace.id,
				changes: {name},
			},
		});

		onClose();
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title="Workspace name"
			description="Customize your workspace by giving it a unique name. This name will be visible to all members of your workspace."
			size="x-small"
		>
			<Input
				value={name}
				onChange={(value) => setName(value)}
				placeholder={workspace?.name}
			/>

			<div className={styles.buttons}>
				<Button
					className={styles.button}
					variant="outlined"
					onClick={onClose}
				>
          Cancel
				</Button>
				<Button
					className={styles.button}
					onClick={handleSave}
				>
          Save
				</Button>
			</div>
		</Modal>
	);
};
