import React, {ReactElement} from "react";
import {
	PolarAngleAxis,
	PolarGrid,
	PolarRadiusAxis,
	Radar,
	RadarChart,
	ResponsiveContainer,
	Tooltip,
} from "recharts";
import {SimplifiedTopics} from "../topic-tab";
import styles from "./radar-chart.module.scss";

export interface RadarGraphProps {
	data: SimplifiedTopics[];
	names?: string[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomTooltip = (props: any): ReactElement | null => {
	if (!(props.active && props.payload && props.payload.length)) return null;
	const {payload, label} = props;
	const [pay] = payload;
	const {payload: values} = pay;
	return <div className={styles.tooltip}>
		<p>Parent topic: {(values.parentTopic as string).split("_").join(" ")}</p>
		<p>Subtopic: {label}</p>
		<p>Magnitude: {values.magnitude.toFixed(2)}</p>
		<p>Sentiment:&nbsp;
			<span style={{color: values.sentiment >= 0 ? "green" : "red"}}>
				{values.sentiment.toFixed(3)}
			</span>
		</p>
	</div>;
};

const RadarGraph = ({data}: RadarGraphProps): ReactElement =>
	<ResponsiveContainer width="100%" height="100%">
		<RadarChart data={data}>
			<PolarGrid/>
			<PolarAngleAxis dataKey="subTopic"/>
			<PolarRadiusAxis angle={90} domain={[-1, 1]}/>
			<Radar dataKey="sentiment" stroke="#5F4DFF" fill="#5F4DFF" fillOpacity={0.3}/>
			<Tooltip
				content={<CustomTooltip />}
			/>
		</RadarChart>
	</ResponsiveContainer>;

export {RadarGraph};
