import {insertMentionPatternReplacer} from "./insert-mention";
import {mentionPatternReplacer} from "./mention";
import {TextToHTMLPatternReplacerOption} from "./helpers";
import {commandPatternReplacer} from "./command";

export const patternReplacersMap = {
	[TextToHTMLPatternReplacerOption.InsertMention]: insertMentionPatternReplacer,
	[TextToHTMLPatternReplacerOption.Mention]: mentionPatternReplacer,
	[TextToHTMLPatternReplacerOption.Command]: commandPatternReplacer,
}

export * from "./insert-mention";
export * from "./mention";
export * from "./helpers";
