import React, {ReactElement} from "react";

import {useSignUpModal} from "../../../context/sign-up-modal.context";
import {SignUpModalActionTypes, SignUpModalStep} from "../../../reducer/sign-up-modal-reducer";
import {StepTemplate} from "../../step-template";
import {Button} from "../../../shared/v2";

import styles from "./check-your-email-step.module.scss";

export const CheckYourEmailStep = (): ReactElement => {
	const {
		state: {
			email,
		},
		dispatch,
	} = useSignUpModal();

	const changeStep = (step: SignUpModalStep): void => {
		dispatch({
			type: SignUpModalActionTypes.SET_CURRENT_STEP,
			payload: {step},
		});
	};

	const handleClick = (): void => {
		changeStep(SignUpModalStep.Initial);
	};

	return (
		<StepTemplate
			header="Check Your Email"
			description={`Follow the instructions sent to ${email} to recover your password`}
		>
			<div className={styles.buttonsSection}>
				<Button type="submit" onClick={handleClick}>Done</Button>
			</div>
		</StepTemplate>
	);
};
