import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact, {BugsnagErrorBoundary} from "@bugsnag/plugin-react";
import LogRocket from "logrocket";
import React, {ReactElement, ReactNode, createContext} from "react";
import setupLogRocketReact from "logrocket-react";

import {DummyBugsnagErrorBoundary} from "../error-boundaries";
import config from "../config";

export interface ErrorBoundaryContextValue {
  ErrorBoundary: BugsnagErrorBoundary;
}

export const ErrorBoundaryContext =
  createContext<ErrorBoundaryContextValue | undefined>(undefined);

export const ErrorBoundaryContextProvider = (
	{children}: {children: ReactNode},
): ReactElement => {
	const ErrorBoundary: BugsnagErrorBoundary = React.useMemo(() => {
		let ErrorBoundary = DummyBugsnagErrorBoundary;

		if (import.meta.env.REACT_APP_STAGE !== "development") {
			LogRocket.init(config.logRocketEnvironmentId);
			setupLogRocketReact(LogRocket);
			const bugsnag = Bugsnag.start({
				apiKey: config.bugsnagApiKey,
				plugins: [new BugsnagPluginReact()],
				releaseStage: config.bugsnagReleaseStage,
				appVersion: import.meta.env.REACT_APP_VERSION,
			});
			bugsnag.addMetadata("LogRocket", {
				sessionURL: LogRocket.sessionURL,
			});
			ErrorBoundary = Bugsnag?.getPlugin("react")?.createErrorBoundary(React) ?? DummyBugsnagErrorBoundary;
		}

		return ErrorBoundary;
	}, []);

	return (
		<ErrorBoundaryContext.Provider value={{ErrorBoundary}}>
			{children}
		</ErrorBoundaryContext.Provider>
	);
};

export const useErrorBoundaryContext = (): ErrorBoundaryContextValue => {
	const context = React.useContext(ErrorBoundaryContext);

	if (context === undefined) {
		throw new Error(
			"useErrorBoundaryContext must be used within a ErrorBoundaryContextProvider",
		);
	}

	return context;
};
