/**
 * May add more to this in the future. General video queries
 */
import {gql} from "@apollo/client";

export const CHECK_TRANSCODING = gql`
	query CheckVideoTranscodingStatus($videoIds: [GUID!]!) {
		checkVideoTranscodingStatus(videoIds: $videoIds) {
			id
			transcodingStatus
			# transcriptStatus also exists, but not needed right now.
		}
	}
`;
