import React, {ReactElement} from "react";

import {AnswerGrounding as AnswerGroundingType} from "../../../../../../models/ai-model";
import {GroundingBase} from "../grounding-base";
import {Tooltip} from "../../../../../../shared/v2";

import styles from "./answer-grounding.module.scss";

export interface AnswerGroundingProps {
	position: number;
	entry: AnswerGroundingType;
}

const AnswerGrounding = ({entry, position}: AnswerGroundingProps): ReactElement => {
	const {answerVideoThumbnailUrl, questionId, questionText, surveyId, surveyTitle, creatorTag, answerId} = entry.answer;

	return (
		<GroundingBase
			links={[
				{to: `/survey/${surveyId}`, text: surveyTitle},
				{to: `/survey/${surveyId}/results/${questionId}`, text: questionText},
				{
					to: `/survey/${surveyId}/results/${questionId}?answerId="${answerId}"`,
					className: styles.userLink,
					content: (
						<Tooltip
							className={styles.answerPreview}
							content={<img src={answerVideoThumbnailUrl} alt={creatorTag} className={styles.thumbnail} />}
						>
							{creatorTag}
						</Tooltip>
					),
				},
			]}
			position={position}
			label="Video Response"
		/>
	);
};

export default AnswerGrounding;
