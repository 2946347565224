import React, {ReactElement} from "react";
import {Input, InputProps} from "./";
import {DebounceInput} from "react-debounce-input";
import {format} from "date-fns";
import classnames from "classnames/bind";
import styles from "./input.module.scss";

const bStyles = classnames.bind(styles);

export interface DatetimeInputProps extends InputProps<Date> {
	minDate?: Date;
	errorMessage?: string;
}

const dateFormat = "yyyy-MM-dd'T'HH:mm";

export const DatetimeInput = (props: DatetimeInputProps): ReactElement => {
	const {value, onChange, className, minDate, id, errorMessage} = props;

	const handleChange = (event): void => {
		const date = event.target.value && new Date(event.target.value).toISOString();
		onChange(date);
	};

	const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
	const GMTOffset = format(new Date(), "OOOO");
	const timeZoneLabel = `Timezone (${GMTOffset}) ${timeZone}`;

	return (
		<Input id={id} className={className}>
			{errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>}
			<DebounceInput
				debounceTimeout={300}
				id={id}
				className={bStyles("input", {error: errorMessage})}
				type="datetime-local"
				value={value ? format(new Date(value), dateFormat) : ""}
				onChange={handleChange}
				min={minDate && format(minDate, dateFormat)}
			/>

			<span className={styles.timeZone}>
				{timeZoneLabel}
			</span>
		</Input>
	);
};
