import React, {ReactElement, useCallback, useEffect, useMemo, useState} from "react";
import {useMutation} from "@apollo/client";
import classNames from "classnames/bind";

import {Body, ButtonIcon, Dropdown, Tooltip} from "../../shared/v2";
import {CopyDocumentsIcon, HornMegaphoneCampaignIcon, LightingBoltStrikeIcon, MusicSoundWaveReadingIcon, PersonaIcon, ThumbsDownDislikeIcon, ThumbsUpLikeIcon} from "../../icons";
import {useToastContext} from "../../context/toast-context";
import {ChatConversationMessage} from "../../models/ai-model";
import {
	CHAT_DISLIKE_MESSAGE,
	CHAT_LIKE_MESSAGE,
	CHAT_REMOVE_MESSAGE_REACTION,
} from "../../graphql/mutations/ai-mutations";
import {useUserContext} from "../../context/user-context";
import Config from "../../config";
import {AudioPlayer} from '../../shared/v2/audio-player/index';
import {useThemeMode} from "../../context/theme-mode-context";
import {useChatConversationContext, useChatSendQuestionContext} from "../../context/chat-contexts";

import styles from "./chat-actions.module.scss";

enum SYSTEM_AGENTS_CODES {
  CREATE_CAMPAIGN = "create_campaign",
  CREATE_AGENT = "create_agent",
}

const bStyles = classNames.bind(styles);

interface ButtonTooltipProps {
	icon: ReactElement;
	text: string;
	onClick?: (event) => void;
	iconClassName?: string;
}

export const ButtonTooltip = ({icon, text, onClick, iconClassName}: ButtonTooltipProps) => {
	return (
		<Tooltip
			content={
				<Body size="xs" color="text-tertiary">
					{text}
				</Body>
			}
			placement={"top"}
		>
			<ButtonIcon
				size="small"
				color="gray-modern"
				className={iconClassName}
				icon={icon}
				onClick={onClick}
			/>
		</Tooltip>
	);
};

export interface ChatActionProps {
	className?: string;
	message: ChatConversationMessage;
	hideAudio?: boolean;
	hideCopy?: boolean;
	hideLike?: boolean;
	hideDislike?: boolean;
	voiceId?: string;
}

export const ChatActions = ({
	className,
	message,
	hideCopy,
	hideLike,
	hideDislike,
	voiceId,
}: ChatActionProps): ReactElement => {
	const {systemAgents} = useChatConversationContext();
	const {sendQuestion} = useChatSendQuestionContext();

	const {isDarkMode} = useThemeMode();
	const {updateToast} = useToastContext();
	const {user, isEnterpriseManagerOrSupport} = useUserContext();

	const [likeMessage] = useMutation(CHAT_LIKE_MESSAGE);
	const [dislikeMessage] = useMutation(CHAT_DISLIKE_MESSAGE);
	const [removeMessageReaction] = useMutation(CHAT_REMOVE_MESSAGE_REACTION);

	const [isLiked, setIsLiked] = useState(false);
	const [isDisliked, setIsDisliked] = useState(false);

	const like = () => {
		setIsLiked(true);
		setIsDisliked(false);
	};

	const dislike = () => {
		setIsDisliked(true);
		setIsLiked(false);
	}

	const isInLikedByUser = useMemo(() => {
		return message?.likedByUsers?.map((user) => user.id).includes(user.id);
	}, [message?.likedByUsers?.length]);

	const isInDislikedByUser = useMemo(() => {
		return message?.dislikedByUsers?.map((user) => user.id).includes(user.id);
	}, [message?.dislikedByUsers?.length]);

	useEffect(() => {
		if (isInLikedByUser) {
			like();
		} else {
			setIsLiked(false);
		}
	}, [isInLikedByUser]);

	useEffect(() => {
		if (isInDislikedByUser) {
			dislike();
		} else {
			setIsDisliked(false);
		}
	}, [isInDislikedByUser]);

	const handleCopy = (event) => {
		event.stopPropagation();
		navigator.clipboard.writeText(message.content);
		updateToast({
			description: "Copied to clipboard",
			type: "informational",
		});
	};

	const handleLike = (event) => {
		event.stopPropagation();
		like();
		likeMessage({
			variables: {
				messageId: message.id,
			},
			onCompleted: () => {
				updateToast({
					description: "Message liked",
					type: "success",
				});
			},
			onError: (error) => {
				setIsLiked(false);
				updateToast({
					description: error.message,
					type: "failure",
				});
			},
		});
	};

	const handleDislike = (event) => {
		event.stopPropagation();
		dislike();
		dislikeMessage({
			variables: {
				messageId: message.id,
			},
			onCompleted: () => {
				updateToast({
					description: "Message disliked",
					type: "success",
				});
			},
			onError: (error) => {
				setIsDisliked(false);
				updateToast({
					description: error.message,
					type: "failure",
				});
			},
		});
	};

	const handleRemoveReaction = (reaction: "like" | "dislike") => {
		if (reaction === "like") {
			setIsLiked(false);
		} else {
			setIsDisliked(false);
		}

		removeMessageReaction({
			variables: {
				messageId: message.id,
			},
			onCompleted: () => {
				updateToast({
					description: "Reaction removed",
					type: "success",
				});
			},
			onError: (error) => {
				updateToast({
					description: error.message,
					type: "failure",
				});
			},
		});
	};

	const renderLikeIcon = useCallback(() => {
		if (isLiked) {
			return (
				<ButtonTooltip
					iconClassName={bStyles("likeIcon", "filled")}
					icon={<ThumbsUpLikeIcon />}
					text="Message liked. Click to remove reaction"
					onClick={() => handleRemoveReaction("like")}
				/>
			);
		}
		return (
			<ButtonTooltip
				iconClassName={styles.likeIcon}
				icon={<ThumbsUpLikeIcon />}
				text="Good response"
				onClick={handleLike}
			/>
		);
	}, [isLiked, user.id]);

	const renderDislikeIcon = useCallback(() => {
		if (isDisliked) {
			return (
				<ButtonTooltip
					iconClassName={bStyles("likeIcon", "filled")}
					icon={<ThumbsDownDislikeIcon />}
					text="Message disliked. Click to remove reaction"
					onClick={() => handleRemoveReaction("dislike")}
				/>
			);
		}
		return (
			<ButtonTooltip
				iconClassName={styles.likeIcon}
				icon={<ThumbsDownDislikeIcon />}
				text="Bad response"
				onClick={handleDislike}
			/>
		);
	}, [isDisliked, user.id]);

	const handleGenerateCampaign = () => {
		const campaignSystemAgentId = systemAgents?.find(agent => agent.code === SYSTEM_AGENTS_CODES.CREATE_CAMPAIGN)?.id;

		if (campaignSystemAgentId) {
			sendQuestion("Generate a campaign using insights from our last conversation.", {messageId: message.id, systemAgentId: campaignSystemAgentId})
		}
	}

	const handleGenerateAgent = () => {
		const agentSystemAgentId = systemAgents?.find(agent => agent.code === SYSTEM_AGENTS_CODES.CREATE_AGENT)?.id;

		if (agentSystemAgentId) {
			sendQuestion("Generate an agent based on that response.", {messageId: message.id, systemAgentId: agentSystemAgentId})
		}
	}

	return (
		<div className={bStyles("wrapper", className, {isDarkMode})}>
			{hideLike ? undefined : renderLikeIcon()}
			{hideDislike ? undefined : renderDislikeIcon()}
			{hideCopy ? undefined : <ButtonTooltip icon={<CopyDocumentsIcon />} text="Copy" onClick={handleCopy} />}
			{(message?.persona?.isVurvey || isEnterpriseManagerOrSupport) && <ChatAudioPlayer message={message} voiceId={voiceId} />}
			{message.type !== "image" &&
        <Dropdown
        	trigger={
        		<ButtonTooltip
        			icon={<LightingBoltStrikeIcon />}
        			text="More actions"
        			iconClassName={bStyles("lightingIcon", {isDarkMode})}
        		/>
        	}
        	items={[
        		{
        			label: "Generate Campaign",
        			icon: <HornMegaphoneCampaignIcon />,
        			onClick: handleGenerateCampaign,
        		},
        		{
        			label: "Create Agent",
        			icon: <PersonaIcon />,
        			onClick: handleGenerateAgent
        		}
        	]}
        	position="top-start"
        />
			}
		</div>
	);
};

export interface ChatAudioPlayerProps {
  message: ChatConversationMessage;
  voiceId?: string;
}

export const ChatAudioPlayer = ({message, voiceId}: ChatAudioPlayerProps) => {
	const apiUrl = `${Config.apiHost}/rest/tts?text=${encodeURIComponent(message.content)}&voice=${encodeURIComponent(message.persona?.voiceId || voiceId || "")}`;
	const safeUrl = encodeURI(apiUrl.replace(/#/g, "").replace(/\*/g, ""));
	const [isOpen, setIsOpen] = useState(false);

	if (isOpen) {
		return <AudioPlayer apiUrl={safeUrl} onClose={() => setIsOpen(false)} />
	}

	return <ButtonTooltip icon={<MusicSoundWaveReadingIcon />} text="Listen " onClick={() => setIsOpen(true)} />
}
