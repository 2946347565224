import {useEffect} from "react";

import {useChatConversationContext, useChatMessagesContext} from "../../../context/chat-contexts";

export const useChatListInitialPopulate = () => {
	const {conversation: {id, messages} = {}} = useChatConversationContext();
	const {setMessages} = useChatMessagesContext();

	const trigger = `${id}-${messages?.length}`;

	useEffect(() => {
		if (Number(messages?.length) < 2) {
			return;
		}
		setMessages(messages || []);
	}, [trigger]);
};
