import React, {ReactElement} from "react";
import {ReelVideoStatus} from "../../../models/reels";
import {CssSpinner, Icon} from "../../../shared";
import styles from "./publish-status.module.scss";

export interface PublishStatusProps {
	reelLoading: boolean;
	reelStatus: ReelVideoStatus | undefined;
}

const PublishStatus = (props: PublishStatusProps): ReactElement => {
	const {reelLoading, reelStatus} = props;
	return (
		<div className={styles.container}>
			{
				reelLoading || reelStatus === ReelVideoStatus.PENDING
					? <CssSpinner className={styles.spinner}/>
					: reelStatus === ReelVideoStatus.UNPUBLISHED
						? null : reelStatus === ReelVideoStatus.CREATED
							? <Icon name="checkmark" size="extrasmall" fill="var(--batterii-green)"/>
							: <Icon name="cross" size="extrasmall" fill="var(--batterii-red)"/>
			}
			<span className={styles.text}>
				{reelLoading || reelStatus === ReelVideoStatus.UNPUBLISHED
					? "Draft" : reelStatus === ReelVideoStatus.PENDING
						? "Publishing" : reelStatus === ReelVideoStatus.CREATED
							? "Published" : reelStatus === ReelVideoStatus.DIRTY
								? "Unpublished Changes" : "Publishing failed"}
			</span>
		</div>
	);
};

export {PublishStatus};
