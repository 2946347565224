import {GenericPage} from "./generic";
import {Question} from "./questions";

export interface Attribute {
	id: string;
	workspaceId?: string;
	name: string;
	description?: string;
	type: AttributeType;
	rules: AttributeRule[];
	category?: AttributeCategory;
}

export interface AttributeWithExtant extends Omit<Attribute, "rules" | "category"> {
	extantValues: string[];
	rules?: AttributeRule[];
	category?: {name: string};
}

export enum AttributeType {
	USER = "USER",
	SURVEY = "SURVEY",
}

export interface AddAttributeToUserVars {
	attributeId: string;
	value?: string;
	userIds: string | string[];
	ruleAction?: RuleAction;
}

export enum AttributesSort {
	DEFAULT = "DEFAULT",
	NAME_ASC = "NAME_ASC",
	NAME_DESC = "NAME_DESC",
	CREATOR_NAME_DESC = "CREATOR_NAME_DESC",
	CREATOR_NAME_ASC = "CREATOR_NAME_ASC",
	CAT_ASC = "CATEGORY_NAME_ASC",
	CAT_DESC = "CATEGORY_NAME_DESC",
	RULES_CNT_ASC = "RULES_CNT_ASC",
	RULES_CNT_DESC = "RULES_CNT_DESC",
}

export interface AttributeValue {
	id: string;
	attribute: {
		name: string;
	}
	user: {
		id: string;
		firstName: string;
		lastInitial: string;
		picture: {
			id: string;
			badge: string;
		}
	}
	value: string;
}

export interface AttributeRule {
	id: string;
	questionId: string;
	picked: boolean;
	action: RuleAction;
	question?: Partial<Question> & {survey: {id: string, name: string}};
	attributeId?: string;
	choiceId?: string;
	value?: string;
}

export enum RuleAction {
	ADD = "ADD",
	SET = "SET",
	REMOVE = "REMOVE",
}


export interface AttributeCategory {
	id: string;
	name: string;
	description?: string;
	isDefault?: boolean;
	attributes?: Attribute[];
}

export interface AttributeCategoriesData {
	attributeCategories: {
		items: AttributeCategory[];
	}
}

export interface AttributeCategoryData {
	attributeCategory: AttributeCategory;
}

export interface AttributesPageData {
	attributes: GenericPage<Attribute>;
}

export interface AttributeData {
	attribute: Attribute;
}

export interface AttributesWithExtantPageData {
	attributes: GenericPage<AttributeWithExtant>;
}

export interface CreateAttributeVars {
	workspaceId: string;
	name: string;
	type: AttributeType;
}

export interface CreateAttributeData {
	createAttribute: Attribute;
}

export interface CreateAttributeRuleVars {
	attributeId: string;
	action: RuleAction;
	picked: boolean;
	questionId: string;
	choiceId?: string;
	value?: string;
}

export interface CreateAttributeRuleData {
	createAttributeRule: AttributeRule;
}

export interface UpdateAttributeRuleData {
	updateAttributeRule: AttributeRule;
}

export interface UpdateAttributeRuleVars {
	id: string;
	changes: CreateAttributeRuleVars;
}

export type AttributeValuePage = GenericPage<AttributeValue>

export interface AttributeValuesPageData {
	attributeValues: AttributeValuePage;
}
