import {useQuery} from "@apollo/client";
import React, {ReactElement, useMemo} from "react";

import {GET_WORKSPACE} from "../graphql/queries/queries";
import {Workspace} from "../models/workspace";
import {LayoutContainer} from "../shared";
import {MANAGE_WORKSPACE_PERMISSIONS} from "../shared/constants/constants";
import {filterArrays} from "../shared/utility/utility";
import {useUserContext} from "./user-context";
import {LoadingPage} from "../shared/v2";

type WorkspaceContextProps = {
	/**
	 * The workspace and everything inside of it
	 */
	workspace: Workspace;
	/**
	 * Permissions array that was filtered down to relevant permissions
	 * for the current user.
	 */
	permissions: string[];
};

const WorkspaceContext = React.createContext<WorkspaceContextProps>({
	workspace: {id: "", name: "", allowAnonymous: false, enableOfflineAssist: false},
	permissions: [],
});

const WorkspaceContextProvider =
(props: {children: ReactElement}): ReactElement => {
	const {currentWorkspaceId: workspaceId} = useUserContext();
	const {loading, data, error} = useQuery(GET_WORKSPACE, {
		variables: {id: workspaceId},
		fetchPolicy: "cache-and-network",
		skip: !workspaceId,
	});

	const permissions = useMemo((): string[] => {
		if (data) {
			return filterArrays(data.workspace.permissions, MANAGE_WORKSPACE_PERMISSIONS);
		}
		return [];
	}, [data]);

	if (loading) {
		return <LoadingPage />;
	}

	if (error) {
		return <LayoutContainer theme="light">An error occurred {error.message}</LayoutContainer>;
	}

	return (
		<WorkspaceContext.Provider
			value={{
				workspace: data?.workspace,
				permissions,
			}}
		>
			{props.children}
		</WorkspaceContext.Provider>
	);
};

const useWorkspaceContext = (): WorkspaceContextProps => {
	const context = React.useContext(WorkspaceContext);

	if (context === undefined) {
		throw new Error("useWorkspaceContext must be used within a WorkspaceContextProvider");
	}

	return context;
};

export {WorkspaceContext, useWorkspaceContext, WorkspaceContextProvider};
