import {Heading, Icon, ReelVideo} from "../shared";
import React, {ReactElement, useEffect, useState} from "react";
import {ReelVideoData} from "../models/reels";
import {GET_PUBLIC_REEL} from "../graphql/queries/reel-queries";
import {TranscodingStatus} from "../models/answer";
import styles from "./shared-reel.module.scss";
import {useParams} from "react-router";
import {useQuery} from "@apollo/client";
import {useWindowSize} from "../hooks/useWindowSize";
import {Button, Input, LoadingPage} from "../shared/v2";

/**
 * This page will be available when a user is not logged in, and when they are logged in
 * (May switch it so it's not nested inside shared-reel/containers/shared-reel)
 */
const SharedReelPage = (): ReactElement => {
	const {reelId} = useParams<{reelId: string}>();
	const {width, height} = useWindowSize();
	const [password, setPassword] = useState("");
	const {data, loading, error, stopPolling, refetch} = useQuery<ReelVideoData>(GET_PUBLIC_REEL, {
		variables: {reelId, shared: true},
		fetchPolicy: "cache-and-network",
		nextFetchPolicy: "cache-and-network",
		pollInterval: 3000,
		notifyOnNetworkStatusChange: true,
		onCompleted: vidData => {
			if (
				vidData.reel.video?.transcodingStatus === TranscodingStatus.COMPLETED ||
				vidData.reel.video?.transcodingStatus === TranscodingStatus.FAILED
			) stopPolling();
		},
		onError: () => {
			stopPolling();
		},
	});

	useEffect(() => {
		document.title = "Reel - Vurvey";
	}, []);

	const handleSubmitPassword = (): void => {
		refetch({password});
	};
	/**
	 * Kind of hacky. Doing this until we officially start supporting / developing
	 * a fully responsive app.
	 * For background: width is set to 1280 right now. Was requested by Matt B. until
	 * we have actual responsive pages.
	 */
	useEffect(() => {
		document.querySelector("[name=viewport]")?.setAttribute(
			"content",
			"width=device-width, initial-scale=1.0",
		);
	}, []);

	if (loading && !data) return <LoadingPage />;
	if (error) {
		const name = error.graphQLErrors[0]?.extensions?.name;
		if (name === "PasswordRequiredError" || name === "IncorrectPasswordError") {
			return <div
				className={styles.protected}
				style={{width: document.documentElement.clientWidth}}
			>
				<form className={styles["password-input"]} onSubmit={handleSubmitPassword}>
					<div className={styles["input-container"]}>
						<Input
							id="reel-password"
							label="Enter a password"
							type="password"
							onChange={setPassword}
							value={password}
						/>
						<Button
							type="submit"
						>
							Submit
						</Button>
					</div>
					{name === "IncorrectPasswordError" &&
						<p className={styles["password-error"]}>
							Password incorrect
						</p>
					}
				</form>
			</div>;
		}
		return <p>An error occurred {error.message}</p>;
	}
	if (data) {
		const {reel: {name, video, displayMode, subtitles}} = data;
		return (
			<div
				className={`${styles.container} ${styles[displayMode.toLowerCase()]}`}
				style={{height, width}}
			>
				<header>
					<Heading size="md">{name}</Heading>
				</header>
				<div className={styles["video-container"]}>
					<ReelVideo video={video} subtitles={subtitles} />
				</div>
				<footer className={styles.footer}>
					created with <Icon name="vurvey-full-logo"/>
				</footer>
			</div>
		);
	}
	return <div className={styles.container}>
		<p>There is nothing to see here right now</p>
	</div>;
};

export {SharedReelPage};
