import {CreateSegmentData, CreateSegmentVars} from "../../models/segment";
import React, {ReactElement, useContext, useEffect, useState} from "react";
import {ADD_WORKSPACE_SEGMENT_MEMBERS, CREATE_SEGMENT} from "../../graphql/mutations/mutations";
import {SEGMENT_FRAGMENT} from "../../graphql/fragments/fragments";
import {ToastContext} from "../../context/toast-context";
import styles from "./add-list.module.scss";
import {useMutation} from "@apollo/client";
import {updateCacheAddItemByFieldId} from "../../shared/utility/update-cache";
import {SelectContext} from "../../context/select-context";
import {CreateModalContext} from "../../context/create-modal-context";
import {useWorkspaceContext} from "../../context/workspace-context";
import {BaseModalProps, Button, Input, Modal} from "../../shared/v2";
import {GET_SEGMENTS} from "../../graphql/queries/queries";

export interface AddListModalProps extends BaseModalProps {
	// If we are given this, we will use this function instead of the internal one
	handleSaveList?: (name: string) => Promise<void>;
}

const AddListModal = ({isOpen, onClose, handleSaveList}: AddListModalProps): ReactElement => {
	const [name, setName] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const {updateToast} = useContext(ToastContext);
	const {
		workspace: {id: workspaceId},
	} = useWorkspaceContext();
	const {singleUserId, setSingleUserId} = useContext(CreateModalContext);
	const {selected, emptySelectValues} = useContext(SelectContext);

	const [sendToSegment] = useMutation(ADD_WORKSPACE_SEGMENT_MEMBERS, {
		onCompleted: (data) => {
			const addedUsersLength = data.addSegmentMembers.addedUsers.length;
			const existingUsersLength = data.addSegmentMembers.existingUsers.length;
			updateToast({
				description: (
					<div>
						{addedUsersLength > 0 && <div>{addedUsersLength} added to list</div>}
						{existingUsersLength > 0 && <div>{existingUsersLength} already in list</div>}
					</div>
				),
				type: "informational",
			});
			onClose();
			emptySelectValues();
			setSingleUserId("");
		},
	});
	const [createList] = useMutation<CreateSegmentData, CreateSegmentVars>(CREATE_SEGMENT, {
		onCompleted: (data) => {
			if (!data) return;
			if (singleUserId) {
				sendToSegment({
					variables: {
						segmentId: data.createSegment.id,
						userIds: singleUserId,
						workspaceId,
					},
					onError: (error) => {
						updateToast({description: error.message, type: "failure"});
					},
				});
				return;
			}
			if (selected.length > 0) {
				const vals = selected.map((s) => {
					if (s.includes("_")) {
						// ANSID_USERID if true
						const [, x] = s.split("_"); // Get the second value only
						return x;
					}
					return s;
				});
				const userIds = [...new Set(vals)]; // Unique values so no errors
				sendToSegment({
					variables: {
						segmentId: data.createSegment.id,
						userIds,
						workspaceId,
					},
				});
				return;
			}
			onClose();
			updateToast({description: "New list created", type: "informational"});
		},
		refetchQueries: [GET_SEGMENTS],
	});

	useEffect(() => {
		if (!isOpen) {
			setIsLoading(false);
			setName("");
		}
	}, [isOpen]);

	const handleSave = async (): Promise<void> => {
		setIsLoading(true);
		if (handleSaveList) {
			await handleSaveList(name);
			setIsLoading(false);
			return;
		}
		createList({
			variables: {input: {workspaceId, name}},
			onError: (error) => {
				if (error.graphQLErrors[0].extensions?.name === "UniqueViolationError") {
					updateToast({description: "This list name is already taken", type: "failure"});
				} else {
					updateToast({description: "An error occurred", type: "failure"});
				}
				setIsLoading(false);
			},
			update(cache, {data: createSeg}) {
				if (createSeg) {
					const newSegmentRef = cache.writeFragment({
						data: createSeg.createSegment,
						fragment: SEGMENT_FRAGMENT,
					});
					updateCacheAddItemByFieldId(cache, "segments", newSegmentRef, workspaceId, createSeg.createSegment.id);
				}
			},
		});
	};

	return (
		<Modal title="New List" isOpen={isOpen} onClose={onClose}>
			<div className={styles.content}>
				<Input id="new-list-name" value={name} onChange={setName} label="List Name" />
				<Button onClick={handleSave} disabled={!name || isLoading}>
					Save
				</Button>
			</div>
		</Modal>
	);
};

export {AddListModal};
