import React, {ReactElement, useContext, useMemo, useState} from "react";
import {useLocation} from "react-router-dom";
import {useMutation} from "@apollo/client";

import {Icon, Tooltip} from "../../../shared";
import {
	COPY_QUESTION,
	CREATE_QUESTION,
	DELETE_QUESTION,
	MOVE_QUESTION,
} from "../../../graphql/mutations/survey-mutations";
import {Question} from "../../../models/questions";
import {
	updateCacheAddPageItem,
	updateCacheDeletePageItem,
} from "../../../shared/utility/update-cache";
import {IntroCard} from "../intro-card";
import {QuestionList} from "../question-list";
import {SurveyContext} from "../../../context/survey-context";
import {updateCacheQuestionCount} from "../../../shared/utility/update-questions";
import {updateObject} from "../../../shared/utility";
import {useNavigate, useParams} from "../../../route";
import {QUESTION_FRAGMENT} from "../../../graphql/fragments/fragments";
import {QuestionTemplatesModal} from "../../../modals/question-templates";
import {QUESTION_DEFAULTS, QUESTION_TYPES} from "../../../shared/constants/constants";
import {Dropdown} from "../../../shared/v2";

import styles from "./question-nav.module.scss";

interface AddQuestionButtonProps {
  openModal: () => void;
  addQuestion: (key: string) => void;
}

const AddQuestionButton = ({
	openModal,
	addQuestion,
}: AddQuestionButtonProps): ReactElement => {
	const [tipAnchor, setTipAnchor] = useState<HTMLElement | null>(null);

	const items = useMemo(() => {
		return Object.keys(QUESTION_TYPES).map((type) => {
			return {
				icon: <Icon name={QUESTION_TYPES[type].icon} />,
				label: QUESTION_TYPES[type].text,
				onClick: () => addQuestion(type),
			}
		})
	}, [])

	return (
		<>

			<div id="add-new-question" className={styles.addQuestionButton}>
				<Dropdown
					popupContentClassName={styles.dropdownContent}
					className={styles.dropdown}
					position="bottom-start"
					trigger={<div className={styles.addQuestionDropdownTrigger}>+ Add Question</div>}
					items={items}
				/>

				<span className={styles.questionTriggerWrapper}>
					<span
						className={styles.questionLibraryModalTrigger}
						onClick={openModal}
						onMouseEnter={(e) => setTipAnchor(e.currentTarget)}
						onMouseLeave={() => setTipAnchor(null)}
					>
						<Icon name="folder" size="extrasmall" fill="white" />
					</span>
				</span>
				<Tooltip id="saved-tooltip" anchorEl={tipAnchor} text="Saved Questions" />
			</div>
		</>
	);
};

interface NavParams {
  questionId: string;
}

/**
 * Portion of the sidebar that contains all the current questions created.
 * Allows user to navigate to them and delete / change question type.
 * (also move, eventually)
 */
const QuestionNav = (): ReactElement => {
	const [isTemplatesModalOpen, setIsTemplatesModalOpen] = useState<boolean>(false);
	const {pathname} = useLocation();
	const {questionId} = useParams<NavParams>();
	const navigate = useNavigate();
	const {
		survey: {id: surveyId},
		questions,
	} = useContext(SurveyContext);

	const [createQuestionMutation] = useMutation(CREATE_QUESTION);

	const [deleteQuestionMutation] = useMutation(DELETE_QUESTION, {
		onCompleted: ({deleteQuestion}) => {
			if (questionId === deleteQuestion.id) {
				navigate(`/survey/${surveyId}/questions`, {workspace: true});
			}
		},
	});

	const [copyQuestion] = useMutation(COPY_QUESTION, {
		onCompleted: ({duplicateQuestion}) => {
			navigate(
				{
					pathname: questionId
						? pathname.replace(/[^/]*$/, duplicateQuestion.id)
						: `${pathname}/${duplicateQuestion.id}`,
				},
				{workspace: true},
			);
		},
	});

	/**
   * Right now we are just refetching the questions since updating
   * Everything might proove tricky. Future optimization?
   */
	const [moveQuestion] = useMutation(MOVE_QUESTION);
	/**
   * Calls the mutation to copy question
   * @param id ID of the question to copy
   */
	const handleCopyQuestion = (id: string): void => {
		copyQuestion({
			variables: {id},
			update(cache, {data: copyData}) {
				const newRef = cache.writeFragment({
					data: copyData.duplicateQuestion,
					fragment: QUESTION_FRAGMENT,
					fragmentName: "QuestionFields",
				});

				updateCacheAddPageItem(cache, newRef, "questions", copyData.duplicateQuestion.id);

				cache.modify({
					fields: {
						questions(currentQuestions = {items: []}) {
							const items = [...currentQuestions.items];
							items.splice(copyData.duplicateQuestion.index, 0, items.splice(-1, 1)[0]);
							return {...currentQuestions, items};
						},
					},
				});
				// updateCopyQuestion(cache, copyData.duplicateQuestion);
				updateCacheQuestionCount(cache, "add", surveyId);
			},
		});
	};
	/**
   * @param id Question ID received from the question card component
   * @returns Deletes the question with the received ID and updates questions.
   */
	const handleDeleteQuestion = (id: string): void => {
		deleteQuestionMutation({
			variables: {id},
			update(cache, {data: deleteData}) {
				if (deleteData) {

					updateCacheDeletePageItem(
						cache,
						"questions",
						"question",
						deleteData.deleteQuestion.id,
					);
					// Updates our question count number
					updateCacheQuestionCount(cache, "subtract", surveyId);
				}
			},
		});
	};

	/**
   * Handles moving a question to a new spot
   * @param id id of the question
   * @param index new index to give the question
   */
	const handleMoveQuestion = (
		question: Question,
		dragIndex: number,
		dropIndex: number,
	): void => {
		moveQuestion({
			variables: {id: question.id, index: dropIndex},
			optimisticResponse: {
				moveQuestion: {
					...question,
					index: dropIndex,
				},
			},
			update(cache) {
				cache.modify({
					fields: {
						questions(currentQuestions = []) {
							const items = [...currentQuestions.items];
							items.splice(dropIndex, 0, items.splice(dragIndex, 1)[0]);
							const updatedQuestions = updateObject(currentQuestions, {
								items,
							});
							return updatedQuestions;
						},
					},
				});
			},
		});
	};

	// User entered input
	const handleMove = (question: Question, endIndex: number, startIndex: number): void => {
		moveQuestion({
			variables: {id: question.id, index: endIndex},
			optimisticResponse: {
				moveQuestion: {
					...question,
					index: endIndex,
				},
			},
			update(cache) {
				cache.modify({
					fields: {
						questions(currentQuestions = []) {
							const items = [...currentQuestions.items];
							items.splice(endIndex, 0, items.splice(startIndex, 1)[0]);
							const updatedQuestions = updateObject(currentQuestions, {
								items,
							});
							return updatedQuestions;
						},
					},
				});
			},
		});
	};

	const addQuestion = (key: string): void => {
		// We want to add the new question to the end of the list.
		const count = questions?.length || 0;
		createQuestionMutation({
			variables: {
				input: {
					surveyId,
					index: count,
					text: "Untitled Question",
					...QUESTION_DEFAULTS[key],
				},
			},
			update(cache, {data: createData}) {
				if (createData) {
					const newRef = cache.writeFragment({
						data: createData.createQuestion,
						fragment: QUESTION_FRAGMENT,
						fragmentName: "QuestionFields",
					});

					updateCacheAddPageItem(
						cache,
						newRef,
						"questions",
						createData.createQuestion.id,
					);
					updateCacheQuestionCount(cache, "add", surveyId);
				}
			},
			onCompleted: ({createQuestion}) => {
				navigate(
					{
						pathname: questionId
							? pathname.replace(/[^/]*$/, createQuestion.id)
							: `${pathname}/${createQuestion.id}`,
					},
					{workspace: true},
				);
			},
		});
	};

	return (
		<div className={styles.container}>
			<div className={styles.button}>
				<AddQuestionButton
					openModal={() => setIsTemplatesModalOpen(true)}
					addQuestion={addQuestion}
				/>
				<QuestionTemplatesModal
					isOpen={isTemplatesModalOpen}
					onClose={() => setIsTemplatesModalOpen(false)}
				/>
			</div>
			<div className={styles.questions}>
				<IntroCard />
				<QuestionList
					questions={questions}
					onDelete={handleDeleteQuestion}
					moveQuestion={handleMoveQuestion}
					onCopy={handleCopyQuestion}
					move={handleMove}
				/>
			</div>
		</div>
	);
};

export {QuestionNav};
