import React, {ReactElement, useEffect} from "react";
import {animated, useSpring} from "@react-spring/web";
import styles from "./time-indicator.module.scss";

export interface TimeIndicatorProps {
	/**
	 * Bar. The HTMLDivElement that represents the range
	 */
	bar: HTMLDivElement;
	/**
	 * Initial time of animation
	 */
	time: number;
	/**
	 * If we are playing right now or not
	 */
	isPlaying: boolean;
	/**
	 * If we are buffering the video
	 */
	isBuffering: boolean;
}

const TimeIndicator = (props: TimeIndicatorProps): ReactElement | null => {
	const {bar, time, isPlaying, isBuffering} = props;

	const finish = parseInt(bar.style.left) + parseInt(bar.style.width);
	const [animation, api] = useSpring(() => ({
		to: {left: `${finish}%`},
		from: {left: bar.style.left},
		config: {duration: time * 1000},
		pause: !isPlaying,
	}));

	useEffect(() => {
		api.start({pause: !isPlaying || isBuffering});
	}, [isPlaying, isBuffering]);

	useEffect(() => {
		api.start({
			to: {left: `${finish}%`},
			from: {left: bar.style.left},
			config: {duration: time * 1000},
		});
	}, [finish, bar.style.left, time, isPlaying]);
	if (!isPlaying) return null;
	return (
		<animated.div style={animation} className={styles.ticker}>
		</animated.div>
	);
};

export {TimeIndicator};
