import {useEffect, useState} from "react";
import {useLazyQuery} from "@apollo/client";

import {PING_ANSWER_STATUS} from "../graphql/queries/survey-queries";
import {TranscodingStatus} from "../models/answer";

export const usePingStatus = (
	status: TranscodingStatus,
	id: string,
): void => {
	const [stopPolling, setStopPolling] = useState<() => void | undefined>();

	const [pingStatus, {startPolling, stopPolling: stopPollingQuery}] = useLazyQuery(PING_ANSWER_STATUS, {
		fetchPolicy: "cache-and-network",
	});

	useEffect(() => {
		if (status !== TranscodingStatus.COMPLETED) {
			pingStatus({
				variables: {id},
			});
			startPolling(15000);
			setStopPolling(() => stopPollingQuery);
		} else {
			if (stopPolling) {
				stopPolling();
			}
		}

		return () => {
			if (stopPolling) {
				stopPolling();
			}
		};
	}, [status, id, pingStatus, startPolling, stopPollingQuery]);
};

