import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import {Body} from "../../../../shared/v2/typography";
import {useThemeMode} from "../../../../context/theme-mode-context";
import {UseValidationResult} from "../../../../hooks/useValidation";

import styles from "../input/input.module.scss";

const cx = classNames.bind(styles);

export interface InputElementsProps {
	children: ReactElement | ReactElement[];
	className?: string;
	variant?: "default" | "error",
	label?: string;
	hintText?: string;
	leftIcon?: ReactElement;
	rightIcon?: ReactElement;
	filledIcon?: boolean;
	validation?: UseValidationResult;
}

export const InputElements = ({
	children,
	className,
	variant,
	label,
	hintText,
	leftIcon,
	rightIcon,
	filledIcon,
	validation,
}: InputElementsProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	const innerVariant = validation?.isError ? "error" : variant;
	const innerHintText = validation?.isError ? validation.errors[0]?.message : hintText;

	return <div className={cx("inputContainer", className, {isDarkMode})}>
		{label && <label className={styles.label}>
			<Body color="text-secondary" size="s" type="medium">{label}</Body>
		</label>}

		<div className={cx("inputWrapper", innerVariant)}>
			{leftIcon && <div className={cx("icon", "leftIcon", {filledIcon})}>{leftIcon}</div>}
			{children}
			{rightIcon && <div className={cx("icon", "rightIcon", {filledIcon})}>{rightIcon}</div>}
		</div>

		{innerHintText && <Body
			size="s"
			type="regular"
			color={innerVariant === "error" ? (isDarkMode ? "red-500": "red-600") : "text-tertiary"}
			className={styles.hintText}
		>
			{innerHintText}
		</Body>}
	</div>
}
