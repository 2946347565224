import React, {ReactElement, useEffect, useRef} from "react";
import {Icon} from "../icon";
import {PublishedReelVideo} from "../../../models/reels";
import {Subtitles, TranscodingStatus} from "../../../models/answer";
import styles from "./reel-video.module.scss";
import {orderSubtitles} from "../../../shared/utility/utility";

export interface ReelVideoProps {
	/**
	 * The video data.
	 */
	video: PublishedReelVideo | null;
	/**
	 * Subtitles, if they exist
	 */
	subtitles: Subtitles[];
}

/**
 * Purpose is to display the video depending on transcoding status.
 * Handles the logic to display video, processing, or failed.
 *
 * The video will take up the space of its container, so this will
 * need to be wrapped in a div generally.
 * @param video The PublishedReelVideo object
 */
const ReelVideo = (props: ReelVideoProps): ReactElement => {
	const {video, subtitles} = props;
	const vidRef = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		if (!vidRef.current) return;
		const {current} = vidRef;
		const length = current.textTracks.length;
		if (length === 0) return;
		current.textTracks[0].mode = "showing";
		if (length > 1) current.textTracks[1].mode = "hidden";
	}, [vidRef.current, video]);

	if (video) {
		const {transcodingStatus, video: webm, mp4} = video;
		return transcodingStatus === TranscodingStatus.COMPLETED ? (
			<video
				ref={vidRef}
				className={styles.video}
				onContextMenu={event => event.preventDefault()}
				controls
				crossOrigin="anonymous"
			>
				<source src={mp4}/>
				<source src={webm}/>
				{subtitles?.length > 0 && orderSubtitles(subtitles).map(sub =>
					<track key={sub.id} lang={sub.langCode} src={sub.fileUrl} label={sub.langCode}/>)}
			</video>
		) : transcodingStatus === TranscodingStatus.PENDING ? (
			<div className={styles.empty}>
				<Icon name="video-slate" size="medium"/>
				<p className={styles.processing}>
					Video processing...
				</p>
				<p className={styles.processing}>
				Check back later.
				</p>
			</div>
		) : (
			<div className={styles.empty}>
				<p className={styles.error}>
					There was an error while processing this video.
				</p>
			</div>
		);
	}
	return (
		<div className={styles.empty}>
			<Icon name="video-slate" size="medium"/>
			<p className={styles.processing}>
				Video processing...
			</p>
			<p className={styles.processing}>
			Check back later.
			</p>
		</div>
	);
};

export {ReelVideo};
