import React, {ReactElement, ReactNode, useEffect} from "react";
import classNames from "classnames/bind";

import {ChatProps} from "..";
import {useChatMessagesContext} from "../../../context/chat-contexts";
import {useChatScrollContext} from "../../../context/chat-contexts/chat-scroll-context";

import styles from "./scrollable-list.module.scss";

const cx = classNames.bind(styles);

export interface ScrollableListProps extends ChatProps {
  children: ReactNode
}

export const ScrollableList = ({children, className, wrapperClassName}: ScrollableListProps): ReactElement => {
	const {setContainer, scrollListToBottom} = useChatScrollContext();
	const {messages} = useChatMessagesContext();

	useEffect(() => scrollListToBottom(), [messages.length]);

	return (
		<>
			<div ref={setContainer} className={cx("chatWrapper", wrapperClassName)}>
				<div className={cx("messageList", className)}>
					{children}
				</div>
			</div>
			<div className={styles.space} />
		</>
	);
}
