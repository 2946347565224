import React, {ReactElement} from "react";

import styles from "./sources-card.module.scss";
import {Body, Button} from "../../../shared/v2";
import {DocumentsFolderFileIcon, HornMegaphoneCampaignIcon, PlusSmallIcon, SourcesIcon} from "../../../icons";
import {Campaign, TrainingSet} from "../../../models/ai-model";
import {isTrainingSet} from "../../../canvas/chat-bubble/sources-section";
import {InputChip} from "../../../shared/components/input-chip";
import {AiOrchestration} from "../../../models/ai-orchestration";
import {useMutation} from "@apollo/client";
import {UPDATE_AI_ORCHESTRATION} from "../../../graphql/mutations/ai-mutations";

export interface SourcesCardProps {
  workflow: AiOrchestration;
  onUpdate?: () => void;
  openSourcesModal: () => void;
  currentSources: (Campaign | TrainingSet)[];
}

export const SourcesCard = ({workflow, onUpdate, openSourcesModal, currentSources}: SourcesCardProps): ReactElement => {
	const [updateWorkflow] = useMutation(UPDATE_AI_ORCHESTRATION);

	const renderSource = (source: TrainingSet | Campaign) => {
		if (isTrainingSet(source)) {
			return (
				<InputChip
					className={styles.source}
					icon={<DocumentsFolderFileIcon />}
					key={source.id}
					onRemove={() => handleRemoveSource(source)}
					label={source.alias}
					variant="squared"
					size="small"
				/>
			);
		}

		return (
			<InputChip
				className={styles.source}
				variant="squared"
				icon={<HornMegaphoneCampaignIcon />}
				key={source.id}
				onRemove={() => handleRemoveSource(source)}
				label={source.name}
				size="small"
			/>
		);
	};


	const renderSourcesOrPlaceholder = (): ReactElement => {
		if (currentSources.length === 0) {
			return <Body color="charcoal-elevation-200">
        Add datasets, campaigns, or other sources to enhance your workflow (optional).
			</Body>
		}

		return <div className={styles.sourcesList}>
			{currentSources.map(renderSource)}
		</div>
	}


	const handleRemoveSource = (source: TrainingSet | Campaign): void => {
		updateWorkflow({
			variables: {
				id: workflow.id,
				trainingSetIds: currentSources.filter(s => s.id !== source.id).filter(isTrainingSet).map(s => s.id),
				surveyIds: currentSources.filter(s => s.id !== source.id).filter(s => !isTrainingSet(s)).map(s => s.id)
			}
		})
		onUpdate?.();
	}

	return <>
		<div className={styles.container}>
			<div className={styles.headerWrapper}>
				<SourcesIcon />
				<Body type="medium">Sources</Body>
			</div>
			<div className={styles.content}>
				{renderSourcesOrPlaceholder()}
			</div>

			<Button
				size="small"
				variant="text"
				leftIcon={<PlusSmallIcon />}
				onClick={openSourcesModal}
			>
        Add Source
			</Button>
		</div>
	</>
}
