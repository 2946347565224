/* eslint-disable */
import React, {ReactElement, useContext, useMemo, useState} from "react";
import {useMutation} from "@apollo/client";
import {
	MultiSelect,
	Options,
} from "../../shared";
import {Column} from "react-table";
import {ToastContext} from "../../context/toast-context";
import {useLoadingQuery} from "../../hooks/useLoadingQuery";
import styles from "./index.module.scss";
import {
	GET_SURVEY_TEMPLATES_WITH_CATEGORIES,
	GET_SURVEY_TEMPLATE_CATEGORIES,
} from "../../graphql/queries/survey-template-queries";
import {Table} from "../../shared/components/table";
import {
	CREATE_TEMPLATE_FROM_SURVEY,
	DELETE_SURVEY_TEMPLATE,
	UPDATE_SURVEY_TEMPLATE,
} from "../../graphql/mutations/survey-template-mutations";
import {AlignText} from "../../shared/typography/align-text";
import {GET_SURVEY_BASICS} from "../../graphql/queries/survey-queries";
import {Body, Button, DebounceSearch, Input, Modal} from "../../shared/v2";
import {SearchIcon} from "../../icons";

const CampaignTemplateManagement = React.memo((): ReactElement => {
	const {updateToast} = useContext(ToastContext);
	// States
	const [search, setSearch] = useState<string>("");
	const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
	const [createArgs, setCreateArgs] = useState<{
		surveyId?: string,
		categories?: string[],
		templateHeadline?: string,
		templateDescription?: string,
	}>({});
	const updateCreateArgs =
		(value: string | string[] | undefined, id: string): void => {
			setCreateArgs({...createArgs, [id]: value});
		};
	const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
	const [updateArgs, setUpdateArgs] = useState<{
		surveyTemplateId?: string,
		categories?: string[],
		templateHeadline?: string,
		templateDescription?: string,
	}>({});
	const updateUpdateArgs =
		(value: string | string[] | undefined, id: string): void => {
			setUpdateArgs({...updateArgs, [id]: value});
		};

	/** ******************************
	 * Queries start below
	 *********************************/
	const loadingQuery = useLoadingQuery(
		GET_SURVEY_TEMPLATES_WITH_CATEGORIES,
		{
			errorPolicy: "all",
			fetchPolicy: "cache-and-network",
			nextFetchPolicy: "cache-and-network",
			notifyOnNetworkStatusChange: true,
		},
	);
	const {data, error, refetch} =
		loadingQuery;
	const currentData =
		useMemo(
			() =>
				data?.surveyTemplates?.items,
			[data],
		);
	const categoriesLoadingQuery = useLoadingQuery(
		GET_SURVEY_TEMPLATE_CATEGORIES,
		{
			errorPolicy: "all",
			fetchPolicy: "cache-and-network",
			nextFetchPolicy: "cache-and-network",
			notifyOnNetworkStatusChange: true,
		},
	);
	const categories = categoriesLoadingQuery.data?.surveyTemplateCategories?.items;
	const surveyLoadingQuery = useLoadingQuery(
		GET_SURVEY_BASICS,
		{
			variables: {
				id: createArgs.surveyId,
			},
			errorPolicy: "all",
			fetchPolicy: "cache-and-network",
			nextFetchPolicy: "cache-and-network",
			notifyOnNetworkStatusChange: true,
		},
	);
	const survey = surveyLoadingQuery.data?.survey;
	/** ******************************
	 * End of Queries
	 *********************************/
	/** ******************************
	 * Mutations start below
	 *********************************/
	const [createTemplate] = useMutation<any>(CREATE_TEMPLATE_FROM_SURVEY, {
		onCompleted: () => {
			updateToast({
				description: "The template was created.",
				type: "success",
			});
			refetch();
		},
		onError: () => {
			updateToast({
				description: "An error occured, and the template could not be created.",
				type: "failure",
			});
			refetch();
		},
	});
	const handleCreate = (): void => {
		createTemplate({
			variables: {
				surveyId: createArgs.surveyId,
				changes: {
					categoryIds: createArgs.categories,
					templateHeadline: createArgs.templateHeadline,
					templateDescription: createArgs.templateDescription,
				},
			},
		});

		setShowCreateModal(false);
	};

	const [updateTemplate] = useMutation<any>(UPDATE_SURVEY_TEMPLATE, {
		onCompleted: () => {
			updateToast({
				description: "The template was created.",
				type: "success",
			});
			refetch();
		},
		onError: () => {
			updateToast({
				description: "An error occured, and the template could not be updated.",
				type: "failure",
			});
			refetch();
		},
	});
	const handleUpdate = (): void => {
		updateTemplate({
			variables: {
				surveyTemplateId: updateArgs.surveyTemplateId,
				changes: {
					categoryIds: updateArgs.categories,
					templateHeadline: updateArgs.templateHeadline,
					templateDescription: updateArgs.templateDescription,
				},
			},
		});

		setShowUpdateModal(false);
	};
	const [deleteTemplate] = useMutation<any>(DELETE_SURVEY_TEMPLATE, {
		onCompleted: () => {
			updateToast({
				description: "The template was created.",
				type: "success",
			});
			refetch();
		},
		onError: () => {
			updateToast({
				description: "An error occured, and the template could not be deleted.",
				type: "failure",
			});
			refetch();
		},
	});
	/** ******************************
	 * End of Mutations
	 *********************************/
	const columns: Column<any>[] = [
		{
			Header: "Template Headline",
			accessor: "templateHeadline",
			Cell: ({value}) => <Body size="s">{value}</Body>,
		},
		{
			Header: "Template Description",
			accessor: "templateDescription",
			Cell: ({value}) => <Body size="s">{value}</Body>,
		},
		{
			Header: "Categories",
			accessor: "categories",
			Cell: ({value}) => (value.length > 0 ? value.map(v =>
				<Body size="s" key={v.id}>
					{v.name}
				</Body>) : <></>),
		},
		{
			id: "options",
			Header: "",
			Cell: ({row: {original}}) => <AlignText align="right">
				<Options
					type="menu-vertical"
					options={[{
						name: "Update",
						actionOptions: {
							isLink: false,
							onClick: () => {
								setUpdateArgs({
									surveyTemplateId: original.id,
									categories: original.categories.map(c => c.id),
									templateHeadline: original.templateHeadline,
									templateDescription: original.templateDescription,
								});
								setShowUpdateModal(true);
							},
						},
						icon: "trash",
						iconFill: "var(--color-text-body)",
					}, {
						name: "Delete",
						actionOptions: {
							isLink: false,
							onClick: () =>
								deleteTemplate({
									variables: {
										surveyTemplateId: original.id,
									},
								}),
						},
						icon: "envelope",
						iconFill: "var(--color-text-body)",
					}]}
				/>
			</AlignText>,
		},
	];

	return (
		<>
			{error && (
				<Body size="s">
					Error loading workspace list: {error.message}
				</Body>
			)}
			{currentData &&
			currentData?.length === 0 ? (
					<></>
				) : (
					<header className={styles.header}>
						<DebounceSearch
							id="search-input"
							value={search}
							onChange={setSearch}
							placeholder="Search Template Names"
							leftIcon={<SearchIcon />}
						/>
						<Button
							onClick={() => {
								setCreateArgs({});
								setShowCreateModal(true);
							}}
						>
							Create Template
						</Button>
						<div className={styles.actions}>
						</div>
					</header>
				)}
			{
				(currentData && currentData?.length > 0 ? (
					<>
						<Table columns={columns} data={currentData} searchValue={search} />
					</>
				) : (
					<Body size="xs">No results found</Body>
				))}
			<Modal
				isOpen={showCreateModal}
				onClose={() => setShowCreateModal(false)}
				title="Creating Template"
			>
				<div>
					<div className={styles.modelSelect}>
						<MultiSelect
							id="create-categories"
							key="create-categories"
							value={createArgs.categories || []}
							label="Template Categories"
							options={categories || []}
							onChange={newValue => updateCreateArgs(newValue, "categories")}
							isSearchable
						/>
					</div>
					<div className={styles.modelSelect}>
						<Input
							size="small"
							id="create-surveyId"
							value={createArgs.surveyId || ""}
							label="Survey Id"
							onChange={newValue => {
								updateCreateArgs(newValue, "surveyId");
								surveyLoadingQuery.refetch();
							}
							}
						/>
					</div>
					<div className={styles.modelSelect}>
						<Body size="xs">{survey?.name ? `Survey Name: ${survey?.name}` : "Survey Not Found"}</Body>
					</div>
					<div className={styles.modelSelect}>
						<Input
							size="small"
							id="create-templateHeadline"
							value={createArgs.templateHeadline || ""}
							label="Template Headline"
							onChange={newValue => updateCreateArgs(newValue, "templateHeadline")}
						/>
					</div>
					<div className={styles.modelSelect}>
						<Input
							size="small"
							id="create-templateDescription"
							value={createArgs.templateDescription || ""}
							label="Template Description"
							onChange={newValue => updateCreateArgs(newValue, "templateDescription")}
						/>
					</div>
				</div>
				<div className={styles.modelFooter}>
					<Button
						onClick={() => setShowCreateModal(false)}
						variant="outlined"
					>
						Cancel
					</Button>
					<Button
						onClick={handleCreate}
						disabled={!survey?.name}
					>
						Create
					</Button>
				</div>
			</Modal>
			<Modal
				isOpen={showUpdateModal}
				onClose={() => setShowUpdateModal(false)}
				title="Updating Template"
			>
				<div>
					<div className={styles.modelSelect}>
						<MultiSelect
							id="update-categories"
							key="update-categories"
							value={updateArgs.categories || []}
							label="Template Categories"
							options={categories || []}
							onChange={newValue => updateUpdateArgs(newValue, "categories")}
							isSearchable
						/>
					</div>
					<div className={styles.modelSelect}>
						<Input
							size="small"
							id="update-templateHeadline"
							value={updateArgs.templateHeadline || ""}
							label="Template Headline"
							onChange={newValue => updateUpdateArgs(newValue, "templateHeadline")}
						/>
					</div>
					<div className={styles.modelSelect}>
						<Input
							size="small"
							id="update-templateDescription"
							value={updateArgs.templateDescription || ""}
							label="Template Description"
							onChange={newValue => updateUpdateArgs(newValue, "templateDescription")}
						/>
					</div>
				</div>
				<div className={styles.modelFooter}>
					<Button
						onClick={() => setShowUpdateModal(false)}
						variant="outlined"
					>
						Cancel
					</Button>
					<Button
						onClick={handleUpdate}
					>
						Update
					</Button>
				</div>
			</Modal>
		</>
	);
});

CampaignTemplateManagement.displayName = "CampaignTemplateManagement";
export {CampaignTemplateManagement};
