import React, {ReactElement, useState} from "react";
import {useMemoObject} from "../hooks";
import {HighlightDropdown} from "../shared/components/highlight-dropdown";
import {HighlightCatDefaults} from "../shared/constants/constants";
import {Setter} from "../types";

interface DropdownState {
	range?: Range;
	id?: string;
	answerId?: string
}

interface HighlightContext {
	setDropdownState: Setter<DropdownState>
}

const HighlightContext = React.createContext<HighlightContext>({
	setDropdownState: () => undefined,
});

export interface HighlightContextProps {
	children: React.ReactNode;
}


const initialState: DropdownState = {
	range: undefined,
	id: "",
	answerId: "",
};
/**
 * This context will mainly be used in the Transcript component.
 * Should wrap any container that we plan on using the Transcript to highlight.
 */
const HighlightContextProvider = (props: HighlightContextProps): ReactElement => {
	const {children} = props;
	const [dropdownState, setDropdownState] = useState<DropdownState>(initialState);

	const closeDropdown = (): void => setDropdownState(initialState);

	const value = useMemoObject({setDropdownState});
	return (
		<HighlightContext.Provider value={value}>
			{children}
			<HighlightDropdown
				range={dropdownState.range}
				rect={dropdownState.range?.getBoundingClientRect()}
				transcriptId={dropdownState.id}
				highlightCategories={HighlightCatDefaults}
				closeDropdown={closeDropdown}
				answerId={dropdownState.answerId}
			/>
		</HighlightContext.Provider>
	);
};

export {HighlightContextProvider, HighlightContext};
