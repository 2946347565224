import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {ChevronRightIcon} from "../../../../../icons";
import {Dropdown, DropdownProps} from "../..";
import {DropdownItem} from "..";
import {DropdownItemContent} from "../_helpers/content";
import {DropdownItemSpacing, useItemClassName} from "../_helpers";

import itemStyles from "../items.module.scss";
import styles from "./nested.module.scss";

const cx = classNames.bind(styles);

export interface DropdownNestedItemProps {
	item: DropdownItem & {dropdown: Omit<DropdownProps, "trigger" | "position">};
}

export const DropdownNestedItem = ({item}: DropdownNestedItemProps): ReactElement => {
	const {popupClassName, ...rest} = item.dropdown;

	const itemClassName = useItemClassName(item);

	return (
		<DropdownItemSpacing active={item.active}>
			<Dropdown
				popupClassName={cx("nestedDropdown", popupClassName)}
				{...rest}
				position="right-start"
				trigger={(
					<div className={itemClassName}>
						<DropdownItemContent item={item} />
						<ChevronRightIcon className={itemStyles.icon} />
					</div>
				)}
			/>
		</DropdownItemSpacing>
	);
};
