import React, {HTMLProps, ReactElement} from "react";
import {useHover} from "../../../hooks";

export interface HoverProps extends Omit<HTMLProps<HTMLDivElement>, "children" | "onMouseEnter" | "onMouseLeave"> {
  children: (isHovering: boolean) => ReactElement;
}

export const Hover = ({children, ...props}: HoverProps): ReactElement => {
	const {isHovered, onMouseEnter, onMouseLeave} = useHover();

	return (
		<div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} {...props}>
			{children(isHovered)}
		</div>
	);
}
