import {Icon, IconTypes} from "../../../shared";
import React, {ReactElement} from "react";
import classNames, {Argument} from "classnames";
import styles from "./survey-nav-link.module.scss";
import {NavLink} from "../../../route";
import {useLocation} from "react-router";


export interface SurveyNavLinkProps {
	linkTo: string;
	icon: IconTypes;
	text: string;
	disabled?: boolean;
	className?: Argument;
	alsoActive?: string[];
}

const SurveyNavLink = (props: SurveyNavLinkProps): ReactElement => {
	const {pathname} = useLocation();
	const {linkTo, icon, text, disabled, className, alsoActive} = props;

	const activeClass = alsoActive ?
		[linkTo, ...alsoActive].some(v => pathname.includes(v))
		: pathname.includes(linkTo);

	return (
		<NavLink
			to={linkTo}
			className={classNames(
				styles.container,
				className,
				disabled && styles.disabled,
				activeClass && styles.active,
			)}
			tabIndex={disabled ? -1 : 0}
		>
			<Icon
				name={icon}
				className={styles.icon}
				size="small"
				fill="var(--gray-modern-900)"
			/>
			<span>{text}</span>
		</NavLink>
	);
};

export {SurveyNavLink};
