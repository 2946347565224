import React, {ReactElement, useContext} from "react";
import {QuestionPreviewer} from "../../components/question-previewer";
import {useParams} from "../../../route";
import {SurveyIntro} from "../survey-intro";
import {SurveyContext} from "../../../context/survey-context";
import styles from "./question-editor.module.scss";
import {QuestionPanel} from "../../components/question-panel";
import {DeleteQuestionModal} from "../../../modals/delete-question";
import {useQuery} from "@apollo/client";
import {GET_DELETE_QUESTION_MODAL} from "../../../graphql/queries/client-queries";
import {toggleDeleteQuestionModal} from "../../../cache";

interface QuestionQueryParams {
	surveyId: string;
	questionId: string;
}

const QuestionEditor = (): ReactElement => {
	const {questionId} = useParams<QuestionQueryParams>();

	const {questions} = useContext(SurveyContext);

	const {data: modalData} = useQuery(GET_DELETE_QUESTION_MODAL);
	const {toggleDeleteQuestionModal: deleteModal} = modalData;

	const handleCloseDeleteModal = (): void => {
		toggleDeleteQuestionModal({
			...toggleDeleteQuestionModal(),
			isShowing: false,
		});
	};

	const question = questions.find(value => value.id === questionId);

	return (
		<>
			<div className={styles.container}>
				<QuestionPanel />
				{question ? (
					<QuestionPreviewer question={question} key={question.id} />
				) : (
					<SurveyIntro />
				)}
			</div>
			<DeleteQuestionModal
				isOpen={deleteModal.isShowing}
				type={deleteModal.type}
				onClose={handleCloseDeleteModal}
				question={question}
			/>
		</>
	);
};

export {QuestionEditor};
