import classNames from "classnames/bind";
import React, {HTMLAttributes, ReactElement} from "react";

import {CheckmarkIcon} from "../../../icons";
import {Body} from "../../v2/typography";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./filter-chip.module.scss";

const cx = classNames.bind(styles);

export interface FilterChipProps extends HTMLAttributes<HTMLButtonElement> {
	label: string;
	disabled?: boolean;
	isSelected: boolean;
	onClick: () => void;
}

export const FilterChip = (
	{label, disabled, isSelected, onClick, ...props}: FilterChipProps,
): ReactElement => {
	const {isDarkMode} = useThemeMode();
	return (
		<button
			className={cx("filterChip", {selected: isSelected, isDarkMode})}
			disabled={disabled}
			onClick={onClick}
			{...props}
		>
			<CheckmarkIcon className={styles.icon} />
			<Body size="s" className={styles.label}>{label}</Body>
		</button>
	);
};
