import React, {
	FormEvent,
	ReactElement,
	useCallback,
	useEffect,
	useState,
} from "react";
import classNames from "classnames/bind";
import {AnimatePresence, motion} from "framer-motion";

import {ActionsDropdown} from "./actions-dropdown";
import {ButtonIcon} from "../../shared/v2";
import {KeyboardEventMiddlewareContextProvider} from "../../context/keyboard-event-middleware-context";
import {PersonaTabWrapper} from "./persona-tab-wrapper";
import {PlusIcon} from "../../icons";
import {SourcesSection} from "./sources-section";
import {
	useChatConversationContext,
	useChatFollowUpsContext,
	useChatPersonaContext,
	useChatSendQuestionContext,
} from "../../context/chat-contexts";
import {UploadedImageSection} from "./uploaded-image-section";
import {useThemeMode} from "../../context/theme-mode-context";
import {useWorkspaceContext} from "../../context/workspace-context";
import CommandTextArea from "./command-text-area";
import {SubmitButton} from "./submit-button";
import {transition, variants} from "../chat-view/animation-helpers";

import styles from "./chat-bubble.module.scss";

const cx = classNames.bind(styles);

export type ChatBubbleContext = "home" | "chat";

export interface ChatInputBoxProps {
	className?: string;
  promptValue?: string;
  clearPrompt: () => void;
  context: ChatBubbleContext;
}

const COMMANDS = [
	{command: "/table", description: " (returns response formatted as a table)", icon: "📊"},
	{
		command: "/image",
		description:
			" (Uses a mix of Imagen, Stable Diffusion, and DALL-E to generate images based on your description)",
		icon: "📸",
	},
	{
		command: "/imagesd",
		description: " (Uses Stable Diffusion to generate images based on your description)",
		icon: "📸",
	},
	{
		command: "/imagede",
		description: " (Uses OpenAI DALL-E to generate images based on your description)",
		icon: "📸",
	},
	{
		command: "/imagen",
		description: " (Uses Google Imagen to generate images based on your description)",
		icon: "📸",
	},
	{
		command: "/scrape",
		description: "Will scrape the URL (/scrape [url]) that you provide and return a summary of the content while adding the full content to the Scraped-Data Dataset folder.",
		icon: "🌐",
	},
	{
		command: "/scrape_advanced",
		description: "Similar to /scrape [url] but will sometimes provide more detail on complicated websites where the default /scrape under performs. This can take much longer though and should only be used when /scrape fails.",
		icon: "🌐",
	},
	{
		command: "/web",
		description: "Will use the web to find the answer to your question with supporting images and links if appropriate. Example: /web Who is current president of the USA?",
		icon: "🌐",
	},
	{command: "/help", description: " (shows help)", icon: "❓"},
];

export const ChatBubble = ({promptValue, clearPrompt, context}: ChatInputBoxProps): ReactElement => {
	const {conversation} = useChatConversationContext();
	const {sendQuestion} = useChatSendQuestionContext();
	const {followUps} = useChatFollowUpsContext();
	const {workspace} = useWorkspaceContext();
	const {isDarkMode} = useThemeMode();

	const [value, setValue] = useState("");
	const isDisabled = !workspace.id || !value.length;
	const {personas} = useChatPersonaContext();

	useEffect(() => {
		if (promptValue) {
			setValue(promptValue);
		}
	}, [promptValue])

	const clearInput = () => {
		setValue("");
		clearPrompt();
	}

	const handleResponse = useCallback(async (e?: FormEvent<HTMLFormElement>): Promise<void> => {
		e?.preventDefault();

		if (isDisabled) {
			return;
		}

		clearInput();

		await sendQuestion(value);
	}, [isDisabled, value, sendQuestion, conversation, setValue]);

	const homeTextAreaSection = (
		<>
			<UploadedImageSection />

			<KeyboardEventMiddlewareContextProvider>
				<CommandTextArea
					followUps={followUps}
					value={value}
					onChange={setValue}
					placeholder={'Ask anything...'}
					commands={COMMANDS}
					personas={personas}
					handleSubmit={handleResponse}
					className={styles.textarea}
				/>
			</KeyboardEventMiddlewareContextProvider>
		</>
	)

	const chatAndSourcesWrapperVariants = variants({
		home: {
			opacity: 0,
			y: "-100%",
		},
		chat: {
			opacity: 1,
			y: "0%",
		}
	}, 2);

	return (
		<PersonaTabWrapper>
			<div className={cx("chatBubble", {isDarkMode})}>
				<AnimatePresence>
					{context === "home" && (
						<motion.div
							exit={{
								height: 0,
								margin: 0,
								opacity: 0,
								padding: 0,
								transition: transition(1),
							}}
							className={styles.homeContentWrapper}
						>
							{homeTextAreaSection}
						</motion.div>
					)}
				</AnimatePresence>
				<div className={styles.bottomLine}>
					<ActionsDropdown>
						<ButtonIcon
							icon={<PlusIcon />}
							className={styles.icon}
							aria-label='plus-button'
						/>
					</ActionsDropdown>

					<div className={styles.chatContentWrapper}>
						<motion.div
							className={styles.chatAndSourcesWrapper}
							variants={chatAndSourcesWrapperVariants}
						>
							{
								context === "chat" && (
									<>
										<UploadedImageSection />

										<KeyboardEventMiddlewareContextProvider>
											<CommandTextArea
												followUps={followUps}
												value={value}
												onChange={setValue}
												placeholder={'Ask anything...'}
												commands={COMMANDS}
												personas={personas}
												handleSubmit={handleResponse}
											/>
										</KeyboardEventMiddlewareContextProvider>
									</>
								)
							}
						</motion.div>
						<SourcesSection />
					</div>

					<SubmitButton context={context} onSubmit={handleResponse} />
				</div>
			</div>
		</PersonaTabWrapper>
	);
};
