import {
	DELETE_ATTRIBUTE_CATEGORY,
	UPDATE_ATTRIBUTE_CATEGORY,
} from "../../../graphql/mutations/attribute-mutations";
import {Option, Select} from "../../../shared";
import React, {ReactElement, useContext, useEffect, useMemo, useState} from "react";
import {AttributeCategory} from "../../../models/attribute";
import {ToastContext} from "../../../context/toast-context";
import styles from "./edit-category.module.scss";
import {updateCacheDeletePageItem} from "../../../shared/utility/update-cache";
import {useMutation} from "@apollo/client";
import {BaseModalProps, Button, Input, Modal} from "../../../shared/v2";

export interface EditCategoryModalProps extends BaseModalProps {
	categories: AttributeCategory[];
}

const EditCategoryModal = (props: EditCategoryModalProps): ReactElement => {
	const {isOpen, onClose, categories} = props;
	const {updateToast} = useContext(ToastContext);
	const [selected, setSelected] = useState("");
	const [text, setText] = useState("");

	const [deleteAttrCat] = useMutation(DELETE_ATTRIBUTE_CATEGORY);
	const [updateCategory] = useMutation(UPDATE_ATTRIBUTE_CATEGORY);

	const mappedCats = useMemo((): Option[] => categories.filter(v => !v.isDefault).map(
		cat => ({text: cat.name, value: cat.id}),
	), [categories]);

	// Set text to be the name of the currently selected category.
	useEffect(() => setText(categories.find(v => v.id === selected)?.name || ""), [selected]);

	useEffect(() => {
		if (!isOpen) {
			setSelected("");
			setText("");
		}
	}, [isOpen]);

	const handleUpdate = (): void => {
		updateCategory({
			variables: {
				id: selected,
				changes: {
					name: text,
				},
			},
			onCompleted: () => updateToast({description: "Name changed", type: "informational"}),
		});
	};


	const handleDelete = (): void => {
		deleteAttrCat({
			variables: {id: selected},
			onCompleted: () => {
				setText("");
				setSelected("");
				updateToast({description: "Deleted category", type: "informational"});
			},
			update(cache, {data: deleteData}) {
				if (!deleteData) return;
				updateCacheDeletePageItem(
					cache,
					"attributeCategories",
					"attributeCategory",
					deleteData.deleteAttributeCategory.id,
				);
			},
		});
	};
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title="Edit Categories"
			size="medium"
		>
			<div className={styles.container}>
				<Select
					id="select-category"
					label="Category"
					selectedValue={selected}
					onChange={setSelected}
					options={mappedCats}
					size="small"
				/>
				<div className={styles.inputs}>
					<Input
						label="Name"
						value={text}
						onChange={setText}
					/>
					<Button
						size="small"
						disabled={!text || !selected}
						onClick={handleUpdate}
					>
						Save name
					</Button>
					<Button
						size="small"
						style="danger"
						disabled={!selected}
						onClick={handleDelete}
					>
						Delete Category
					</Button>
				</div>
			</div>
			<Button onClick={onClose} className={styles.done}>Done</Button>
		</Modal>
	);
};

export {EditCategoryModal};
