import React, {ReactElement, useContext, useState} from "react";
import {Toast, ToastItem} from "../shared/components/toast";
import {ApolloError} from "@apollo/client";

type ToastContextProps = {
	/**
	 * The function that will update the toast
	 */
	updateToast: (newToast: ToastItem) => void;
	onError: (error: ApolloError) => void;
};

const ToastContext = React.createContext<ToastContextProps>({
	updateToast: () => undefined,
	onError: () => undefined,
});

const ToastContextProvider = (props: {children: ReactElement}): ReactElement => {
	const [toast, setToast] = useState<ToastItem>();

	const updateToast = (newToast: ToastItem): void => setToast(newToast);

	const onError = (error: ApolloError): void => updateToast({description: error.message, type: "failure"});

	return (
		<ToastContext.Provider value={{updateToast, onError}}>
			<Toast toastItem={toast} position="top"/>
			{props.children}
		</ToastContext.Provider>
	);
};

export {ToastContext, ToastContextProvider};

export const useToastContext = (): ToastContextProps => {
	const context = useContext(ToastContext);

	if (context === undefined) {
		throw new Error("useToastContext must be used within a ToastContextProvider");
	}

	return context;
};
