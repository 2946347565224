/* eslint-disable react/prop-types */

import React, {ReactElement, useCallback, useEffect} from "react";
import {getCurrent} from "../../ref";
import styles from "./clip-bar.module.scss";

export interface ClipBarProps {
	min: number;
	max: number;
	start: number;
	end: number;
}


const ClipBar = React.forwardRef<HTMLDivElement, ClipBarProps>(({min, max, start, end}, ref): ReactElement => {
	const getPercent = useCallback(
		(value: number) => Math.round(((value - min) / (max - min)) * 100),
		[min, max, start, end],
	);

	useEffect(() => {
		const current = getCurrent(ref);
		if (!current) return;
		const minPercent = getPercent(start);
		const maxPercent = getPercent(end);
		current.style.left = `${minPercent}%`;
		current.style.width = `${maxPercent - minPercent}%`;
	}, [start, end, getPercent]);

	return (
		<div className={styles.container}>
			<div className={styles.bar} ref={ref}/>
		</div>
	);
});

ClipBar.displayName = "ClipBar";

export {ClipBar};
