import React, {ReactElement, useEffect, useRef, useState} from "react";
import classNames, {Argument} from "classnames";
import {Icon} from "../..";
import styles from "./content-editable.module.scss";

export type ElementTypes = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";

export interface ContentEditableProps {
	children: React.ReactNode;
	el: ElementTypes;
	handleUpdate?: (newText: string) => void;
	className?: Argument;
}

const ContentEditable = (props: ContentEditableProps): ReactElement => {
	const {children, el, className, handleUpdate} = props;
	const [edit, setEdit] = useState(false);
	const ref = useRef<HTMLElement>(null);
	const textRef = useRef<string>();

	const handleEdit = (): void => {
		if (!ref.current) return;
		setEdit(true);
	};
	const handleClick = (): void => {
		if (!ref.current) return;
		setEdit(false);
		handleUpdate?.(ref.current.innerText);
	};

	const revertToLast = (): void => {
		if (!(ref.current && textRef.current)) return;
		ref.current.innerText = textRef.current;
		setEdit(false);
	};

	const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
		if (e.key === "Enter" && !e.shiftKey) handleClick();
	};
	const element = React.createElement(
		el,
		{
			contentEditable: edit,
			className: classNames(className, edit && styles.editing),
			ref,
			suppressContentEditableWarning: true,
			onKeyDown,
		},
		children,
	);

	useEffect(() => {
		if (!ref.current) return;
		if (edit) return ref.current.focus();
		ref.current.blur();
	}, [ref.current, edit]);

	/**
	 * This should work for setting up how it was originally when clicking "X"
	 */
	useEffect(() => {
		if (!ref.current) return;
		textRef.current = ref.current.innerText;
	}, [ref.current]);

	if (!handleUpdate) return element;
	return (
		<div className={styles.container}>
			{element}
			<div className={classNames(styles.actions, edit && styles.visible)}>
				{ }
				{ edit ? <>
					<Icon name="checkmark" size="extrasmall" isClickable clicked={handleClick} fill="var(--batterii-green)"/>
					<Icon name="cross" size="extrasmall" isClickable clicked={revertToLast} fill="var(--batterii-red)"/>
				</> :
					<Icon name="pen" fill="grey" size="extrasmall" isClickable clicked={handleEdit}/>
				}
				{ }
			</div>
		</div>
	);
};

export {ContentEditable};
