import React, {ReactElement, useContext, useMemo, useRef} from "react";
import {ParsedTopics} from "../../../models/questions";
import styles from "./topic-tab.module.scss";
import {useNavigate} from "../../../route";
import {RadarGraph} from "../radar-chart";
import {Tag} from "../../../shared";
import {uniqBy} from "lodash-es";
import html2canvas from "html2canvas";
import sanitize from "sanitize-filename";
import {SurveyContext} from "../../../context/survey-context";
import {Button} from "../../../shared/v2";

export interface TopicTabProps {
	topics: string; // Need to parse
	parsed?: ParsedTopics[];
	pointCount?: number;
	handleSetIndex: (index: number) => void;
	questionIndex?: number;
}

export interface SimplifiedTopics {
	salience: number;
	parentTopic: string;
	subTopic: string;
	sentiment: number;
	magnitude: number;
}

 
const TopicTab = ({topics, pointCount = 25, handleSetIndex, questionIndex}: TopicTabProps): ReactElement => {
	const parsed: ParsedTopics[] = JSON.parse(topics);
	const nav = useNavigate();
	const ref = useRef<HTMLDivElement>(null);
	const {survey: {name}} = useContext(SurveyContext);

	const handleSearch = (searchTerm: string): void => {
		nav({search: {display: "list", search: `"${searchTerm}"`}}, {workspace: true});
		handleSetIndex(0);
	};

	// Might not be necessary exactly, but potentially can save this calculating every refresh
	const simplified: SimplifiedTopics[] | null = useMemo((): SimplifiedTopics[] | null => {
		if (!parsed.length) return null;
		const allTopics = parsed[0].topics;
		// Turning the entirity into our simplified topic array
		// This keeps topic and subtopic along with the other values all in one entry in an array
		const bigArray = Object.keys(allTopics).flatMap(key => {
			return Object.keys(allTopics[key])
				.map(sub => ({parentTopic: key, subTopic: sub, ...allTopics[key][sub]}));
		});
		// Getting our top X based on salience, then sort by sentiment.
		const unique = uniqBy(bigArray, "subTopic");
		return unique.sort((a, b) => b.salience - a.salience)
			.slice(0, pointCount).sort((a, b) => b.sentiment - a.sentiment);
	}, []);

	if (!simplified || simplified.length === 0) {
		return <div className={styles.container}>
			<p>There are currently no topics</p>
		</div>;
	}

	const handleCopyChart = (): void => {
		if (!ref.current) return;
		const {current} = ref;
		if (current) {
			html2canvas(
				current,
				{
					width: current.clientWidth + 20,
					height: current.clientHeight + 20,
					// give some extra whitespace around image
					y: -10,
					x: -10,
				},
			).then(canvas => {
				const dataURL = canvas.toDataURL("image/png");
				const fileName = sanitize(`${name.replace(" ", "-")}-q${questionIndex || 0 + 1}-topics`);
				const a = document.createElement("a");
				a.href = dataURL;
				a.download = fileName;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			});
		}
	};

	return (
		<div className={styles.container}>
			<div className={styles.graph} ref={ref}>
				<Button className={styles.download} onClick={handleCopyChart}>Download Chart</Button>
				<RadarGraph data={simplified} />
			</div>
			<div className={styles.tags}>
				{simplified.map(val => <Tag
					key={`${val.parentTopic}-${val.subTopic}`}
					clicked={() => handleSearch(val.subTopic)}>
					{val.subTopic}
				</Tag>)}
			</div>
		</div>
	);
};

export {TopicTab};
