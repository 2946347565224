import React, {ReactElement, useEffect, useState} from "react";
import {QuickSummaryPage} from "../survey/containers/quick-summary";
import {useParams} from "../route";
import {useQuery} from "@apollo/client";
import {SummaryReturn} from "../models/questions";
import {GET_SUMMARY} from "../graphql/queries/survey-queries";
import styles from "./shared-summary.module.scss";
import {Button, Input, LoadingPage} from "../shared/v2";

const SharedSummary = (): ReactElement => {
	const {surveyId} = useParams<{surveyId: string}>();
	const [password, setPassword] = useState<string | undefined>();
	const {data, error, loading, refetch} = useQuery<SummaryReturn>(GET_SUMMARY, {
		variables: {surveyId},
		notifyOnNetworkStatusChange: true,
	});

	const handleSubmitPassword = (e: React.FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		refetch({password});
	};

	useEffect(() => {
		document.title = "Vurvey - Summary";
	}, []);

	if (loading && !data) return <LoadingPage />;
	if (error) {
		const name = error.graphQLErrors[0]?.extensions?.name;
		if (name === "PasswordRequiredError" || name === "IncorrectPasswordError") {
			return <div
				className={styles.protected}
			>
				<form className={styles.passwordInput} onSubmit={handleSubmitPassword}>
					<div className={styles.inputContainer}>
						<Input
							id="reel-password"
							label="Enter a password"
							type="password"
							onChange={setPassword}
							value={password || ""}
						/>
						<Button type="submit">Submit</Button>
					</div>
					{name === "IncorrectPasswordError" &&
						<p className={styles.passwordError}>
							Password incorrect
						</p>
					}
				</form>
			</div>;
		}
		return <p>An error occurred {error.message}</p>;
	}
	return (
		<QuickSummaryPage surveyId={surveyId} password={password}/>
	);
};

export {SharedSummary};
