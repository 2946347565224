/* eslint-disable */
import React, {ReactElement} from "react";
import styles from "./display-results.module.scss";

export interface DisplayResultsProps {
	/**
	 * Total results
	 */
	results: number | undefined;
	/**
	 * Search term
	 */
	searchTerm: string | undefined;
	/**
	 * Handle clear search
	 */
	clearSearch: () => void;
}

const DisplayResults = (props: DisplayResultsProps): ReactElement | null => {
	const {results, searchTerm, clearSearch} = props;
	const count = typeof results === undefined ? 0 : results as number;
	const text = count === 0 || count > 1 ? "results" : "result";
	if (searchTerm && count >= 0) {
		return (
			<div>
				<span className={styles.text}>
					Your search for <strong>&#34;{searchTerm}&#34;</strong> has {count} {text}
				</span>
				<button
					className={styles["fake-button"]}
					onClick={clearSearch}
				>
					clear search
				</button>
			</div>
		);
	}
	return null;
};

export {DisplayResults};
