import React, {ReactElement, useCallback, useContext, useState} from "react";

import {useMemoObject} from "../hooks";

interface SelectContext {
	selected: string[];
	setSelected: (e: React.ChangeEvent<HTMLInputElement>) => void;
	emptySelectValues: () => void;
	bulkAdd: (values: string[]) => void;
}

const SelectContext = React.createContext<SelectContext>({
	selected: [],
	setSelected: () => undefined,
	emptySelectValues: () => undefined,
	bulkAdd: () => undefined,
});

const SelectContextProvider = (props: {children: React.ReactNode}): ReactElement => {
	const [selected, baseSetSelected] = useState<string[]>([]);

	const bulkAdd = useCallback((values: string[]): void => {
		const updated = selected.slice();
		values.forEach(v => {
			if (!updated.includes(v)) {
				updated.push(v);
			}
		});
		baseSetSelected(updated);
	}, [selected]);

	const setSelected = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
		const updated = selected.slice();
		let exists = false;
		let index = -1;
		const newSelected = e.target.value;

		updated.forEach((value, i) => {
			if (value === newSelected) {
				exists = true;
				index = i;
			}
		});

		if (exists) {
			updated.splice(index, 1);
		} else {
			updated.push(newSelected);
		}
		baseSetSelected(updated);
	}, [selected]);

	const emptySelectValues = useCallback((): void => {
		baseSetSelected([]);
	}, []);

	const value = useMemoObject({selected, setSelected, emptySelectValues, bulkAdd});

	return <SelectContext.Provider value={value}>{props.children}</SelectContext.Provider>;
};

const useSelectContext = (): SelectContext => {
	const context = useContext(SelectContext);

	if (context === undefined) {
		throw new Error("useSelectContext must be used within a SelectContextProvider");
	}

	return context;
}

export {SelectContext, useSelectContext, SelectContextProvider};
