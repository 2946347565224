import {useNavigate as useOriginal} from "react-router-dom";

import {To, NavigateOptions, NavigateFunction} from "./types";
import {useBuild} from "./useSearchParams";

// Todo: Possibly we can remove it now
export const useNavigate = (): NavigateFunction => {
	const build = useBuild();
	const navigate = useOriginal();
	return (to: To, {workspace, search, ...options}: NavigateOptions = {}): void => {
		navigate(build(to, {workspace, search}), options);
	};
};
