import {ApolloCache} from "@apollo/client/core";

/**
 * Used to clean up the question-nav a little bit since this needs to be updated.
 * @param cache Apollo cache we are updating
 * @param type What operation we should be doing
 * @param surveyId The survey ID
 */
export const updateCacheQuestionCount = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	cache: ApolloCache<any>,
	type: "add" | "subtract",
	surveyId: string,
): void => {
	cache.modify({
		id: `Survey:${surveyId}`,
		fields: {
			questionCount(questionCount: number) {
				if (type === "add") {
					return questionCount + 1;
				}
				return questionCount - 1;
			},
		},
	});
};
