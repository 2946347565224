import {ApolloClient, ApolloLink, ApolloProvider, from, split} from "@apollo/client";
import {createClient} from "graphql-ws";
import {createUploadLink} from "apollo-upload-client";
import {getMainDefinition} from "@apollo/client/utilities";
import {GraphQLWsLink} from "@apollo/client/link/subscriptions";
import {onError} from "@apollo/client/link/error";
import {setContext} from "@apollo/client/link/context";
import {v4 as uuid} from "uuid";
import Bugsnag from "@bugsnag/js";
import React, {ReactElement, ReactNode, useMemo} from "react";

import {auth} from "../utils/firebase";
import {cache} from "../cache";
import {typeDefs, resolvers} from "../graphql/resolvers/resolvers";
import config from "../config";

export const ApolloContextProvider = ({children}: {children: ReactNode}): ReactElement => {
	const applyDecorators = (link: ApolloLink, decorators: ApolloLink[]) => {
		return decorators.reduce((acc, decorator) => decorator.concat(acc), link);
	};

	const getToken = async () => {
		const user = auth?.currentUser;
		let token: string | null = null;

		if (user) {
			token = await user.getIdToken();
			localStorage.setItem("token", token);
		}
		return token;
	};

	const reportError = (error): void => {
		if (config.bugsnagReleaseStage === "development") {
			return console.error(error);
		}
		return Bugsnag.notify(error);
	};

	// Links

	const errorHandler = useMemo(
		() =>
			onError(({graphQLErrors, networkError}) => {
				if (graphQLErrors) {
					graphQLErrors.forEach(({message, locations, path}) => {
						if (message.includes("Please re-authenticate")) {
							localStorage.removeItem("token");
							auth.signOut();
						}
						reportError(
							new Error(`[GraphQL error]: Message: ${message},
        Location: ${JSON.stringify(locations)},
        Path: ${path}`),
						);
					});
				}
				if (networkError) reportError(new Error(`[Network error]: ${networkError}`));
			}),
		[],
	);

	const graphqlLink = useMemo(
		() =>
			createUploadLink({
				uri: `${config.apiHost}/graphql`,
				credentials: "include",
				fetchOptions: {
					timeout: 180000,
				},
			}),
		[],
	);

	const wsLink = useMemo(() => {
		return new GraphQLWsLink(
			createClient({
				url: `${config.pubsubHost}/graphql`,
				connectionParams: async () => {
					const token = await getToken();
					return {
						authToken: token,
					};
				},
			}),
		);
	}, []);

	// Decorators

	const authDecorator = useMemo(
		() =>
			setContext(async (_, {headers}) => {
				const token = await getToken();
				return {
					headers: {
						...headers,
						authorization: token ? `Bearer ${token}` : "",
					},
				};
			}),
		[],
	);

	const requestIdDecorator = useMemo(
		() =>
			setContext((_, {headers}) => {
				return {
					headers: {
						...headers,
						"X-Request-ID": uuid(),
					},
				};
			}),
		[],
	);

	// Merge links into one

	const link = useMemo(() => {
		return from([
			errorHandler,
			split(
				({query}) => {
					const definition = getMainDefinition(query);
					return definition.kind === "OperationDefinition" && definition.operation === "subscription";
				},
				wsLink,
				applyDecorators(graphqlLink, [authDecorator, requestIdDecorator]),
			),
		]);
	}, [errorHandler, graphqlLink, authDecorator, requestIdDecorator, wsLink]);

	const client = useMemo(
		() =>
			new ApolloClient({
				link,
				cache,
				typeDefs,
				resolvers,
				connectToDevTools: true,
				defaultOptions: {
					watchQuery: {
						fetchPolicy: "cache-and-network",
						nextFetchPolicy: "cache-and-network",
					},
				},
			}),
		[link],
	);

	return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
