import React, {ReactElement} from "react";

import {BaseModalProps, Modal} from "../modal";
import {Button} from "../../buttons";

import styles from "./confirm-logout-modal.module.scss";

export interface ConfirmLogoutModalProps extends BaseModalProps {
	onConfirm: () => void;
}

export const ConfirmLogoutModal = ({
	isOpen,
	onClose,
	onConfirm,
}: ConfirmLogoutModalProps): ReactElement => {
	return <Modal
		size="x-small"
		isOpen={isOpen}
		onClose={onClose}
		title="Logging Out?"
		description="Are you sure you want to log out? Make sure you've saved any ongoing work."
	>
		<div className={styles.footer}>
			<Button
				variant="outlined"
				className={styles.button}
				onClick={onClose}
			>
				Cancel
			</Button>

			<Button
				className={styles.button}
				onClick={onConfirm}
			>
        Log Out
			</Button>
		</div>
	</Modal>;
};
