import classNames from "classnames/bind";
import React, {ReactElement, useEffect} from "react";

import {Hover} from "../../../../shared/v2/hover";
import {Persona} from "../../../../models";
import {Popup} from "../../../../shared/v2/popup";
import {useChatMentionsContext} from "../../../../context/chat-contexts/chat-mentions-context";
import {useThemeMode} from "../../../../context/theme-mode-context";
import {AgentCard} from "../../../../agents/components/agent-card";

import styles from "./mention.module.scss";

const cx = classNames.bind(styles);

export interface MentionProps {
  persona: Persona;
  register?: boolean;
}

export const Mention = ({persona, register}: MentionProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {setMention} = useChatMentionsContext();

	if (register) {
		useEffect(() => {
			setMention(persona);
			return () => setMention(null);
		}, [])
	}

	const personaTag = (
		<span className={cx("personaTag", {isDarkMode})}>
      @{persona.name}
		</span>
	);

	return (
		<Hover className={styles.hoverWrapper}>
			{isHovering => isHovering ? (
				<Popup
					alwaysOpen
					className={styles.popup}
					popupClassName={styles.popupPanel}
					portal
					position="top-start"
					space={8}
					trigger={personaTag}
				>
					<AgentCard className={styles.personaCard} persona={persona} />
				</Popup>
			) : personaTag}
		</Hover>
	);
}
