import React, {ReactElement} from "react";
import styles from "./toggle-switch.module.scss";

export interface ToggleSwitchProps {
	id: string;
	isChecked: boolean;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
}

const ToggleSwitch = (props: ToggleSwitchProps): ReactElement =>
	<label className={styles.switch} htmlFor={props.id}>
		<input
			checked={props.isChecked}
			id={props.id}
			type="checkbox"
			className={styles.checkbox}
			onChange={props.onChange}
			disabled={props.disabled}
		/>
		<span className={styles.slider}/>
	</label>;

export {ToggleSwitch};
