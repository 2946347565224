import React, {ReactElement} from "react";
import {createPortal} from "react-dom";
import {usePortal} from "../../../hooks/usePortal";

export interface PortalProps {
	/**
	 * Children to give to the portal to render
	 */
	children: React.ReactNode;
	/**
	 * ID of div we are going to render
	 */
	id: string;
}

const Portal = (props: PortalProps): ReactElement => {
	const {children, id} = props;
	const target = usePortal(id);
	return createPortal(children, target);
};

export {Portal};
