 
import React, {ReactElement} from "react";
import styles from "./heart-icon.module.scss";

export interface HeartIconProps {
	/**
 	* If the icon is liked or not
	*/
	isLiked: boolean;
	/**
	 * Function to call when unliking
	 */
	unlike: () => void;
	/**
	 * Function to call when liking
	 */
	like: () => void;
}


/**
 * Heart width is 16, height is 17, 0 0 16 17 for viewBox
 * @param props
 */
const HeartIcon = (props: HeartIconProps): ReactElement => {
	const {isLiked, unlike, like} = props;

	return (
		<svg
			width={16}
			height={17}
			viewBox="0 0 16 17"
			className={isLiked ? styles.liked : styles.heart}
			onClick={isLiked ? unlike : like}
		>
			<path opacity="1.0" d="M7.18054 13.5549L7.17983 13.5542C5.10994 11.6363 3.43191 10.0805 2.26534 8.62316C1.10482 7.17338 0.5 5.88012 0.5 4.49591C0.5 2.24412 2.22225 0.5 4.4 0.5C5.63653 0.5 6.83427 1.09066 7.6159 2.02855L8 2.48944L8.3841 2.02855C9.16573 1.09066 10.3635 0.5 11.6 0.5C13.7777 0.5 15.5 2.24412 15.5 4.49591C15.5 5.88013 14.8952 7.17341 13.7346 8.62432C12.5688 10.0816 10.8926 11.6381 8.82491 13.558L8.82085 13.5618L8.81978 13.5628L8.00129 14.3183L7.18054 13.5549Z"/>
		</svg>
	);
};

export {HeartIcon};
