import React, {
	Dispatch,
	ReactElement,
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useReducer,
	useState,
} from "react";
import {SignUpModalAction, SignUpModalActionTypes, SignUpModalReducer, SignUpModalState, SignUpModalStep} from "../reducer/sign-up-modal-reducer";

export interface ModalContextProviderPropsType {
	children?: ReactNode;
}

export interface ModalContextType {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
}


export interface SetIsSignUpModalOpenOptions {
	step: SignUpModalStep;
}

export interface SignUpModalContextType extends Omit<ModalContextType, "setIsOpen"> {
	state: SignUpModalState;
	dispatch: Dispatch<SignUpModalAction>;
	setIsOpen: (value: boolean, options?: SetIsSignUpModalOpenOptions) => void;
}

export const SignUpModalContext = createContext<SignUpModalContextType | undefined>(undefined);

export const SignUpModalContextProvider = (
	{children}: ModalContextProviderPropsType,
): ReactElement => {
	const [isOpen, setIsOpen] = useState(false);
	const [state, dispatch] = useReducer(SignUpModalReducer, {
		currentStep: SignUpModalStep.Initial,
		history: [],
	});

	const handleSetIsOpen = (value: boolean, options?: SetIsSignUpModalOpenOptions): void => {
		if (options?.step) {
			dispatch({
				type: SignUpModalActionTypes.SET_CURRENT_STEP,
				payload: options,
			});
		}
		setIsOpen(value);
	};

	useEffect(() => {
		if (!isOpen) {
			dispatch({type: SignUpModalActionTypes.RESET_STATE});
		}
	}, [isOpen]);

	return (
		<SignUpModalContext.Provider value={{
			isOpen,
			setIsOpen: handleSetIsOpen,
			state,
			dispatch,
		}}>
			{children}
		</SignUpModalContext.Provider>
	);
};

export const useSignUpModal = (): SignUpModalContextType => {
	const context = useContext(SignUpModalContext);

	if (context === undefined) {
		throw new Error("useSignUpModal must be used within a SignUpModalContextProvider");
	}

	return context;
};
