import {getAuth} from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import config from "../config";
import {getAnalytics} from "firebase/analytics";

export const firebaseProvider = firebase;
export const app = firebase.initializeApp(config.firebaseConfig);
export const auth = getAuth(app);
getAnalytics(app);

