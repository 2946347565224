import {useQuery} from "@apollo/client";
import {useMemo} from "react";
import {GET_SEGMENTS} from "../graphql/queries/queries";
import {SegmentData} from "../models/segment";
import {DropdownItemProps} from "../shared";
import {useWorkspaceContext} from "../context/workspace-context";

/**
 * Not stricly a needed hook, but just in case.
 * @param handleClick Function that requires a string to send an id
 * @param handleCreateNew if defined, will have the Create New List option
 * @returns DropdownItemProps[] for selecting segments to send users to
 */
export const useSegmentOptions = (
	handleClick: (id: string) => void,
	handleCreateNew?: () => void,
): DropdownItemProps[] | undefined => {
	const {workspace: {id}} = useWorkspaceContext();
	const {data} = useQuery<SegmentData>(GET_SEGMENTS, {variables: {workspaceId: id}});

	const options = useMemo((): DropdownItemProps[] | undefined => {
		if (!handleCreateNew && (data && data.segments.items.length === 0)) return undefined;
		const actions: DropdownItemProps[] = [];
		if (handleCreateNew) {
			actions.push({
				children: "+ Create New List",
				options: {onClick: handleCreateNew},
			});
		}
		if (!data) return actions;
		for (const segment of data.segments.items) {
			actions.push({
				children: segment.name,
				options: {onClick: () => handleClick(segment.id)}
			});
		}
		return actions;
	}, [data, handleClick, handleCreateNew]);

	return options;
};

