import React, {ReactElement} from "react";

import {BaseModalProps, Button, Modal} from "../../../shared/v2";
import {client} from "../../../shared/utility/client";

import styles from "./use-filter-modal.module.scss";

export interface CSVUseFilterModalProps extends BaseModalProps {
	filteredUrl: string;
	unfilteredUrl: string;
}

const CSVUseFilterModal = ({
	isOpen,
	onClose,
	filteredUrl,
	unfilteredUrl,
}: CSVUseFilterModalProps): ReactElement => {
	const handleClick = async (url: string) => {
		const blob = await client.get(url).blob();

		// create a link element with the blob URL and the download attribute
		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.download = "data.csv";

		// simulate a click on the link to trigger the download
		link.click();

		onClose();
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size="x-small"
			title="Would you like your download to use your current filter?"
			className={styles.container}
		>
			<div className={styles.items}>
				<Button onClick={() => handleClick(filteredUrl)}>
          Yes
				</Button>
				<Button style="danger" onClick={() => handleClick(unfilteredUrl)}>
          No
				</Button>

			</div>
		</Modal>
	);
};

export {CSVUseFilterModal};
