import {gql} from "@apollo/client";

export const CREATE_COLLECTION = gql`
	mutation CreateCollection ($name: String! $description: String $workspaceId: GUID!) {
		createCollection (name: $name description: $description workspaceId: $workspaceId) {
			id
		}
	}
`;

export const CREATE_COLLECTION_VIDEO = gql`
	mutation CreateCollectionVideo ($uploadItemId: String!) {
		createCollectionVideo (uploadItemId: $uploadItemId) {
			id
			transcodingStatus
		}
	}
`;

export const CREATE_COLLECTION_FILE = gql`
	mutation CreateCollectionVideo ($uploadItemId: String!) {
		createCollectionVideo (uploadItemId: $uploadItemId) {
			id
			uploadStatus
		}
	}
`;

export const ADD_VID_TO_COLLECTION = gql`
	mutation AddVideoToCollection ($collectionId: GUID! $videoId: String!) {
		addVideoToCollection(collectionId: $collectionId videoId: $videoId) {
			id
			videos {
				id
				transcodingStatus
			}
		}
	}
`;

export const REMOVE_VID_FROM_COLLECTION = gql`
	mutation RemoveVideoFromCollection ($collectionId: GUID! $videoId: String!) {
		removeVideoFromCollection(collectionId: $collectionId videoId: $videoId) {
			id
			videos {
				id
			}
		}
	}
`;
