import {Argument} from "classnames";
import classNames from "classnames/bind";
import React, {ReactElement, ReactNode, useCallback} from "react";
import Tippy, {type TippyProps} from "@tippyjs/react/headless";

import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./tooltip.module.scss";

type TooltipProps = Omit<TippyProps, "render" | "children"> & {
	startDelay?: number;
	containerClassname?: Argument;
	children?: ReactNode;
  custom?: boolean;
};

type RenderAttrs = Parameters<Required<TippyProps>["render"]>[0];

const bStyles = classNames.bind(styles);

export const Tooltip = ({
	children,
	content,
	startDelay = 100,
	placement = "auto",
	className,
	containerClassname,
	custom,
	...restProps
}: TooltipProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	const renderTippy = useCallback(
		(attrs: RenderAttrs) => {
			if (custom) {
				return <>{content}</>
			}

			return <div className={bStyles("tooltipContent", className, {isDarkMode})} tabIndex={-1} {...attrs}>
				{content}
			</div>
		 },
		[content],
	);

	return (
		<Tippy render={renderTippy} delay={[startDelay, 0]} placement={placement} {...restProps}>
			<div className={bStyles(containerClassname)}>{children}</div>
		</Tippy>
	);
};
