import {Editor, Element as SlateElement, Transforms} from "slate";

const LIST_TYPES = ["numbered-list", "unordered-list"];
export type AlignFormat = "left" | "center" | "right";
export type BlockFormat = "numbered-list" | "unordered-list" | "heading-one" |
"heading-two" | "heading-three" | "paragraph";
export type MarkFormat = "bold" | "italic" | "under";

export const isBlockActive = (editor: Editor, format: BlockFormat): boolean => {
	const [match] = Editor.nodes(editor, {
		match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
	});
	return Boolean(match);
};
export const isMarkActive = (editor: Editor, format: MarkFormat): boolean => {
	const marks = Editor.marks(editor);
	return marks ? marks[format] === true : false;
};
export const isAlignActive = (editor: Editor, format: AlignFormat): boolean => {
	const [match] = Editor.nodes(editor, {
		match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.textAlign === format,
	});

	return Boolean(match);
};
/**
 * Toggles a "mark", like bold, italic, etc.
 * @param editor Current editor
 * @param format The format we are toggling
 */
export const toggleMark = (editor: Editor, format: MarkFormat): void => {
	const isActive = isMarkActive(editor, format);
	if (isActive) {
		Editor.removeMark(editor, format);
	} else {
		Editor.addMark(editor, format, true);
	}
};

/**
 * Toggles a block level (element) style
 * @param editor current editor
 * @param format format we are toggling
 */
export const toggleBlock = (editor: Editor, format): void => {
	const isActive = isBlockActive(editor, format);
	const isList = LIST_TYPES.includes(format);
	Transforms.unwrapNodes(editor, {
		match: n => {
			if (!Editor.isEditor(n) && SlateElement.isElement(n)) {
				return LIST_TYPES.includes(n.type);
			}
			return false;
		},
		split: true,
	});
	const newProperties: Partial<SlateElement> = {
		 
		type: isActive ? "paragraph" : isList ? "list-item" : format,
	};
	Transforms.setNodes(editor, newProperties);
	if (!isActive && isList) {
		const block = {type: format, children: []};
		Transforms.wrapNodes(editor, block);
	}
};

/**
 * Specifically for aligning content
 * @param editor Current editor
 * @param format Format we want to use
 */
export const toggleAlign = (editor: Editor, format: AlignFormat): void => {
	const {selection} = editor;
	if (selection !== null && selection.anchor !== null) {
		const selected = editor.children[selection.anchor.path[0]];
		if (SlateElement.isElement(selected)) {
			const type = LIST_TYPES.includes(selected.type) ? "list-item" : selected.type;
			const newProperties: Partial<SlateElement> = {
				type,
				textAlign: format,
			};
			Transforms.setNodes(editor, newProperties);
		}
	}
};
