import {DocumentNode} from "@apollo/client";
import React, {ReactElement, ReactNode, useEffect} from "react";

import {Body, Button, Modal} from "../../../../shared/v2";
import {ImageDropArea} from "../../../../shared/v2/image-drop-area";
import {useFileUpload} from "../../../../hooks";
import {useTask} from "../../../../hooks/useTask";
import {useThemeMode} from "../../../../context/theme-mode-context";

import styles from "./edit-picture-modal.module.scss";

export interface EditPictureModalProps<T> {
  isOpen: boolean;
  title: string;
  description: string;
  imageUrl: string;
  createPictureMutation: DocumentNode;
  responseType?: string;
  onUpdate: (picture: T) => void;
  onClose: () => void;
}

const Mark = ({children}: {children: ReactNode}): ReactElement => {
	const {isDarkMode} = useThemeMode();

	return (
		<Body
			className={styles.mark}
			type="bold"
			color={isDarkMode ? "purple-brand-400" : "purple-brand-500"}
		>
			{children}
		</Body>
	);
}

export function EditPictureModal<T>(props: EditPictureModalProps<T>): ReactElement {
	const {
		isOpen,
		title,
		description,
		imageUrl,
		createPictureMutation,
		onUpdate,
		onClose,
	} = props;
	const {
		file,
		upload,
		save,
		reset,
	} = useFileUpload<T>({imageUrl, createPictureMutation});

	useEffect(() => {
		if (!isOpen) {
			reset();
		}
	}, [isOpen]);

	const {run: handleSave, loading: isLoading} = useTask(async () => {
		const picture = await save();
		if (picture) {
			onUpdate(picture);
		}
		onClose();
	})

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title={title}
			description={description}
			size="x-small"
		>
			<div className={styles.imageArea}>
				<ImageDropArea file={file} onDrop={upload} />
				<Body className={styles.text}>
          Upload a <Mark>JPG</Mark> or <Mark>PNG</Mark> file. {"\n"}
          Recommended size: <Mark>200 x 200 px.</Mark>
				</Body>
			</div>

			<div className={styles.buttons}>
				<Button
					className={styles.button}
					variant="outlined"
					onClick={onClose}
				>
          Cancel
				</Button>
				<Button
					className={styles.button}
					disabled={!file || isLoading}
					onClick={handleSave}
				>
          Save
				</Button>
			</div>
		</Modal>
	);
};
