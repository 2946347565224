import React, {ReactElement} from "react";

import {Icon} from "../../../shared";
import {useNavigate, useParams} from "../../../route";

import styles from "./intro-card.module.scss";
import classNames from "classnames";


// This is used twice now so probably should put it in a "model" of some sort
interface MatchParams {
	questionId: string;
	surveyId: string;
}
/**
 * This may get abstracted to be another version of the QuestionCard in the future
 * But as of now it seems to make sense to have it be its own thing
 *
 * Also: may be able to rewrite this and the question card to use useEffect to style
 * themselves and avoid some needless re-rendering. >:(
 */
const IntroCard = (): ReactElement => {
	const params = useParams<MatchParams>();
	const navigate = useNavigate();

	/**
	 * handleNavigate will trigger when user clicks on the intro and
	 * will navigate to the introduction page
	 */
	const handleNavigate = (): void => {
		navigate({
			pathname: `/survey/${params.surveyId}/questions`,
		});
	};

	return (
		<div onClick={handleNavigate}
			className={classNames(styles.container, !params.questionId && styles.active)}>
			<Icon name="text" size="extrasmall" fill="black"/>
			<span className={styles.intro}>Introduction</span>
		</div>
	);
};

export {IntroCard};
