import {gql} from "@apollo/client";

export const UPDATE_ME = gql`
	mutation UpdateMe($changes: UpdateMeChanges $deletions: UpdateMeDeletions) {
		updateMe(changes: $changes deletions: $deletions){
			id
			name
			firstName
			lastName
			email
			age
			gender
			pictureId
			picture {
				id
				badge:url(version:BADGE)
				thumbnail:url(version:THUMBNAIL)
			}
		}
	}
`;

/**
 * This mutation just deletes the account of the user.
 * I believe we do a "soft" delete right now, where most of their
 * info is still available (especially videos they've made for surveys)
 */
export const DELETE_ME = gql`
	mutation DeleteMe {
		deleteMe
	}
`;

/**
 * Mutation for creating new workspace + stripe session. Called on WelcomePage
 */
export const CREATE_NEW_WORKSPACE_CHECKOUT_SESSION = gql`
	mutation CreateNewWorkspaceCheckoutSession (
		$plan: WorkspacePlan!
		$successUrl: String!
		$cancelUrl: String!
	) {
		createNewWorkspaceCheckoutSession (
			plan: $plan
			successUrl: $successUrl
			cancelUrl: $cancelUrl
		) {
			id
		}
	}
`;
