

import React, {ReactElement, useContext, useState} from "react";
import {Route, Routes} from "react-router-dom";
import {useNavigate, useParams} from "../../../route";
import {GET_SURVEY} from "../../../graphql/queries/survey-queries";
import {AttributesPageData} from "../../../models/attribute";
import {PublishSurveyModal} from "../../../modals/publish-confirm";
import {QuestionEditor} from "../question-editor";
import {CreateModalContextProvider} from "../../../context/create-modal-context";
import {ResponsesTab} from "../../components/responses-tab";
import {Results} from "../results";
import {ResultsTable} from "../../../survey/components/results-table";
import {SurveyContextProvider} from "../../../context/survey-context";
import {SurveyData} from "../../../models/survey";
import {SurveyParticipants} from "../survey-participants";
import {SurveyResponsePage} from "../survey-response";
import {SurveySettings} from "../survey-settings";
import {LoadingPage} from "../../../shared/v2";
import {UserContext} from "../../../context/user-context";
import {WorkspacePlan} from "../../../models/workspace";
import {Question} from "../../../models/questions";
import {CustomDragLayer} from "../../../shared/components/custom-drag-layer";
import {useQuery} from "@apollo/client";
import styles from "./survey.module.scss";
import {DirtyReelContextProvider} from "../../../context/dirty-reel-context";
import {SurveyTopNav} from "../../components/survey-top-nav";
import {QuickSummaryPage} from "../quick-summary";
import {SelectContextProvider} from "../../../context/select-context";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {useMount} from "../../../hooks/useMount";

interface SurveyWorkspaceData {
	workspace: {
		id: string;
		responseCount: number;
		plan: WorkspacePlan;
	};
}

interface SurveyQuestionsData {
	questions: {
		items: Question[];
	}
}

const SurveyPage = (): ReactElement => {
	const [showAddList, setShowAddList] = useState(false);
	const [isShowingReelModal, setIsShowingReelModal] = useState(false);
	const [singleAnswerId, setSingleAnswerId] = useState("");
	const {surveyId} = useParams<{surveyId: string}>();
	const navigate = useNavigate();
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	// We are using this to check to see if the user can see the survey
	const {workspaces} = useContext(UserContext);
	/**
	 * Note: it might make more sense to make the query call inside of the context
	 * if we want to store more variables
	 *
	 * The whole survey *should* be aware of the status, id, and probably question count.
	 */
	const {
		data,
		loading,
		error,
	 
	} = useQuery<SurveyData & SurveyWorkspaceData & SurveyQuestionsData & AttributesPageData>(GET_SURVEY, {
		variables: {id: surveyId, workspaceId},
	});

	useMount(() => {document.title = "Vurvey - Campaign Builder"});

	const canSee = workspaces.some(workspace => workspace.id === workspaceId);

	if (loading) {
		return <LoadingPage />;
	}
	if (error) {
		return <div>An error occurred: {error}</div>;
	}
	if (!data || !canSee) {
		navigate("/", {replace: true});
		return <></>;
	}

	return (
		<SurveyContextProvider
			survey={data.survey}
			plan={data.workspace.plan}
			questions={data.questions.items}
			attributes={data.attributes.items}
		>
			<SelectContextProvider>
				<CreateModalContextProvider
					isShowingReelModal={isShowingReelModal}
					setIsShowingReelModal={setIsShowingReelModal}
					singleAnswerId={singleAnswerId}
					setSingleAnswerId={setSingleAnswerId}
					showAddList={showAddList}
					setShowAddList={setShowAddList}
				>
					<DirtyReelContextProvider>
						<CustomDragLayer/>
						<div className={styles.container}>
							<SurveyTopNav />
							<Routes>
								<Route path="questions" element={<QuestionEditor />} />
								<Route path="questions/:questionId" element={<QuestionEditor />} />
								<Route path="participants" element={<SurveyParticipants />} />
								<Route path="settings" element={<SurveySettings />} />
								<Route path="summary" element={<QuickSummaryPage />} />
								<Route path="*" element={<Results />}>
									<Route path="results" element={<SurveyResponsePage />} />
									<Route path="results/:questionId" element={<SurveyResponsePage />} />
									<Route path="results/:questionId/:answerId" element={<SurveyResponsePage />} />
									<Route path="participation" element={<ResponsesTab />} />
									<Route path="analyze" element={<ResultsTable />} />
									<Route path="analyze/:answerId" element={<ResultsTable />}/>
								</Route>
							</Routes>
						</div>
						<PublishSurveyModal />
					</DirtyReelContextProvider>
				</CreateModalContextProvider>
			</SelectContextProvider>
		</SurveyContextProvider>
	);
};

export {SurveyPage};
