import React, {ReactElement, useCallback, useContext, useEffect, useState} from "react";
import styles from "./upload-video.module.scss";
import {Select} from "../../../shared";
import {SimpleVideoInput} from "../../../shared/components/input/simple-video-input";
import {useMutation, useQuery} from "@apollo/client";
import {GET_COLLECTION_NAMES} from "../../../graphql/queries/collections-queries";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {CollectionsNameReturn} from "../../../models/collections";
import {useMediaUpload} from "../../../hooks/useMediaUpload";
import {
	ADD_VID_TO_COLLECTION,
	CREATE_COLLECTION_VIDEO,
} from "../../../graphql/mutations/collections-mutations";
import {CREATE_CLIP, CREATE_CLIP_SOURCE} from "../../../graphql/mutations/clip-mutations";
import {ToastContext} from "../../../context/toast-context";
import {UploadFileVars, UploadItemData} from "../../../models/questions";
import {UPLOAD_FILE} from "../../../graphql/mutations/mutations";
import {ReelContext} from "../../../context/reel-context";
import {CreateClipReturn} from "../../../models/clip";
import {CLIP_FRAGMENT} from "../../../graphql/fragments/fragments";
import {updateCacheAddItemByFieldId} from "../../../shared/utility/update-cache";
import {CreateCollectionModal} from "../../../modals/create-collection";
import {usePollForStatus} from "../../../hooks/usePollForStatus";
import {TranscodingStatus} from "../../../models/answer";
import {BaseModalProps, Button, Modal} from "../../../shared/v2";

// I hate this
const UploadVideoModal = ({isOpen, onClose}: BaseModalProps): ReactElement => {
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const {updateToast} = useContext(ToastContext);
	const {reelId} = useContext(ReelContext);
	const [collection, setCollection] = useState("");
	const [isUploading, setIsUploading] = useState(false);
	const [showCreate, setShowCreate] = useState(false);
	const [bothIds, setBothIds] = useState({clipId: "", vidId: ""});

	const [createCollectionVideo] = useMutation(CREATE_COLLECTION_VIDEO);
	const [createClipSourceVideo] = useMutation(CREATE_CLIP_SOURCE);
	const [createClip] = useMutation<CreateClipReturn>(CREATE_CLIP);

	const [uploadFile] = useMutation<UploadItemData, UploadFileVars>(UPLOAD_FILE);
	const [addToCollection] = useMutation(ADD_VID_TO_COLLECTION);

	/**
	 * We're gonna do a sort of component specific usage for this.
	 */
	const {
		isProcessing,
		setIsProcessing,
		media,
		handleChange,
		hasError,
		clearMedia,
	} = useMediaUpload("video");

	const status = usePollForStatus(bothIds.clipId);

	const {data} = useQuery<CollectionsNameReturn>(GET_COLLECTION_NAMES, {
		skip: !isOpen,
		variables: {workspaceId},
		fetchPolicy: "cache-and-network",
		nextFetchPolicy: "cache-and-network",
	});

	useEffect(() => {
		if (!status) return;
		if (status === TranscodingStatus.COMPLETED || status === TranscodingStatus.FAILED) {
			setIsProcessing(false);
		}
	}, [status]);

	const reset = (): void => {
		clearMedia();
		setCollection("");
		setBothIds({clipId: "", vidId: ""});
		setIsUploading(false);
		setIsProcessing(false);
	};

	const closeAndReset = (): void => {
		reset();
		onClose();
	};

	const handleSelect = (newValue: string): void => {
		if (newValue === "createCollection") {
			setCollection(""); // Reset in case it had a value
			return setShowCreate(true);
		}
		setCollection(newValue);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleAddAndUpload = async(): Promise<any> => {
		if (!(bothIds.clipId && bothIds.vidId)) return;
		setIsUploading(true);
		await addToCollection({
			variables: {collectionId: collection, videoId: bothIds.vidId},
		});
		await createClip({
			variables: {input: {reelId, sourceVideoId: bothIds.clipId}},
			onCompleted: () => {
				updateToast({description: "Uploaded clip to reel", type: "informational"});
				setIsUploading(false);
				closeAndReset();
			},
			// Should have to do this
			update(cache, {data: newClip}) {
				if (!newClip) return;
				const duration = newClip.createClip.endTime - newClip.createClip.startTime;
				const clipRef = cache.writeFragment({
					data: newClip.createClip,
					fragment: CLIP_FRAGMENT,
				});
				updateCacheAddItemByFieldId(
					cache,
					"clips",
					clipRef,
					reelId,
					newClip.createClip.id,
					newClip.createClip.index,
				);
				cache.modify({
					id: `Reel:${reelId}`,
					fields: {
						duration(currentDuration: number) {
							return currentDuration + duration;
						},
					},
				});
			},
		});
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleSave = async(): Promise<any> => {
		if (!media.raw) return;
		setIsProcessing(true);
		const {data: file} = await uploadFile({
			variables: {file: media.raw},
		});
		const id = file?.upload?.id;
		if (!id) return;
		const {data: vidData} = await createCollectionVideo({
			variables: {uploadItemId: id},
		});
		const {data: clipData} = await createClipSourceVideo({
			variables: {uploadItemId: id},
		});

		if (!(vidData && clipData)) return;
		// const {createClipSourceVideo, createCollectionVideo} = bothData || {};
		setBothIds({
			clipId: clipData.createClipSourceVideo.id,
			vidId: vidData.createCollectionVideo.id,
		});
	};

	useEffect(() => {
		if (media.raw) handleSave();
	}, [media.raw]);

	const createOptions = useCallback((): {text: string; value: string}[] => {
		const options = [{text: "Create Collection", value: "createCollection"}];
		const dataOptions = data?.collections?.map(c => ({value: c.id, text: c.name}));
		if (dataOptions) options.push(...dataOptions);
		return options;
	}, [data]);

	return (<>
		<Modal onClose={closeAndReset} isOpen={isOpen}>
			<div className={styles.container}>
				<SimpleVideoInput
					id="video-upload-input"
					media={media}
					onChange={handleChange}
					className={styles.upload}
					helpText="Upload a video (MOV, MP4, MPEG4, AVI)"
					disabled={!collection}
					isProcessing={isProcessing}
					clearMedia={clearMedia}
					hasError={hasError}
				/>
				<Select
					id="select-collection"
					label="Upload to collection:"
					selectedValue={collection}
					onChange={handleSelect}
					// Currently name is not returned from collection query
					options={createOptions()}
				/>
				<Button
					onClick={handleAddAndUpload}
					className={styles.button}
					disabled={!(collection && media.raw && !isProcessing && !isUploading)
						|| status === TranscodingStatus.FAILED || status === TranscodingStatus.PENDING}
				>
					Upload
				</Button>
			</div>
		</Modal>
		<CreateCollectionModal isOpen={showCreate} onClose={() => setShowCreate(false)}/>
	</>
	);
};

export {UploadVideoModal};
