import {useMutation} from "@apollo/client";
import React, {ReactElement, useState} from "react";

import {
	ChoiceQuestion,
	Question,
	QuestionSubType,
	QuestionType,
	SliderQuestion,
	TextQuestion,
	UpdateQuestionChanges,
	ImageUploadQuestion,
} from "../../../models/questions";
import {AutoResizeTextarea} from "../../../shared/v2/inputs";
import {Body, Checkbox} from "../../../shared/v2";
import {FormControl} from "../builder-form";
import {OptionList} from "../option-list";
import {RangeSelect} from "../range-select";
import {StarRanking} from "../star-ranking";
import {ToggleSwitch} from "../../../shared/components/toggle-switch";
import {UPDATE_QUESTION} from "../../../graphql/mutations/survey-mutations";

import styles from "./form-builder.module.scss";
import {useToastContext} from "../../../context/toast-context";

export interface FormBuilderProps {
	question: Question;
	questionType: QuestionSubType;

}
// Move to utility?
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasChoices = (m: any): m is ChoiceQuestion => m.choices;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSlider = (m: any): m is SliderQuestion => m.type === QuestionType.SLIDER;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isText = (m: any): m is TextQuestion => m.type === QuestionType.TEXT;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isImageUpload = (m: any): m is ImageUploadQuestion =>
	m.type === QuestionType.PICTURE && m.subtype === "picture";

const FormBuilder = (props: FormBuilderProps): ReactElement | null => {
	const {question, questionType} = props;
	const {updateToast} = useToastContext();
	const [updateQuestion] = useMutation(UPDATE_QUESTION);

	const [leftLabel, setLeftLabel] = useState(question.leftLabel || "");
	const [rightLabel, setRightLabel] = useState(question.rightLabel || "");

	const handleUpdate = (values: UpdateQuestionChanges): void => {
		updateQuestion({
			variables: {
				id: question.id,
				changes: {...values},
			},
			onError: () => {
				updateToast({description: "Failed to update field", type: "failure"});
			},
		});
	};

	const handleToggle = (): void => {
		updateQuestion({
			variables: {
				id: question.id,
				changes: {
					max: question.max === 3 ? 1 : 3,
				},
			},
		});
	};

	if (question.type === QuestionType.NONE) return null;
	if (hasChoices(question)) {
		return (
			<FormControl label="Choices" id="choices">
				<OptionList
					question={question}
					questionType={questionType}
				/>
			</FormControl>
		);
	}
	if (isSlider(question)) {
		if (question.subtype === "star") {
			return <FormControl id="star-ranking">
				<StarRanking starCount={question.max} icon="rankStar" na={question.na}/>
				<Checkbox
					id="allow-na"
					text={(
						<Body size="xs">
							Include &quot;Not applicable&quot; option
						</Body>
					)}
					size="s"
					checked={question.na}
					onChange={e => handleUpdate({na: e.currentTarget.checked})}
					className={styles.checkbox}
				/>
			</FormControl>;
		}
		return <>
			<FormControl id="range" label="From">
				<RangeSelect min={question.min} max={question.max} updateRange={handleUpdate}/>
			</FormControl>
			<FormControl id="left-label" label="Left label">
				<AutoResizeTextarea
					id="left-label-input"
					placeholder="Enter your label..."
					maxLength={20}
					value={leftLabel}
					onChange={setLeftLabel}
					onBlur={value => handleUpdate({leftLabel: value})}
					className={styles.textInput}
				/>
			</FormControl>
			<FormControl id="right-label" label="Right label">
				<AutoResizeTextarea
					id="right-label-input"
					value={rightLabel}
					placeholder="Enter your label..."
					onChange={setRightLabel}
					onBlur={value => handleUpdate({rightLabel: value})}
					className={styles.textInput}
					maxLength={20}
				/>
			</FormControl>
		</>;
	}
	if (isImageUpload(question)) {
		return <FormControl
			id="allow-multiple"
			label="Allow Multiple Uploads?"
			className={styles.allowMultiple}
		>
			<ToggleSwitch
				isChecked={question.max === 3}
				id="toggle-multiple"
				onChange={handleToggle}
			/>
			<span className={styles.aside}>Maximum uploads is 3</span>
		</FormControl>;
	}
	return null;
};

export {FormBuilder};
