import {CreateClipsReturn, CreateClipsVars} from "../../../models/clip";
import React, {ReactElement, useContext} from "react";
import {Icon, Portal} from "../../../shared";
import {CREATE_CLIPS} from "../../../graphql/mutations/clip-mutations";
import {AddReelDropdown} from "../add-reel-dropdown";
import {useSelectContext} from "../../../context/select-context";
import {ToastContext} from "../../../context/toast-context";
import styles from "./bulk-actions-bar.module.scss";
import {useDropdown} from "../../../hooks";
import {useMutation} from "@apollo/client";
import {Setter} from "../../../types";
import {DirtyReelContext} from "../../../context/dirty-reel-context";
import {ADD_WORKSPACE_SEGMENT_MEMBERS} from "../../../graphql/mutations/mutations";
import {AddListDropdown} from "../add-list-dropdown";
import {InviteCampaignDropdown} from "../invite-campaign-dropdown";
import {AnswersPageFilter} from "../../../models/answer";

export interface BulkActionsBarProps {
	setIsShowingDelete?: Setter<boolean>;
	workspaceId: string;
	allSelected?: boolean;
	totalItems?: number;
	filter: AnswersPageFilter;
	onClearSelection: () => void;
	addProperty: () => void;
}

const BulkActionsBar = (props: BulkActionsBarProps): ReactElement | null => {
	const {setIsShowingDelete, workspaceId, allSelected, totalItems, filter, addProperty, onClearSelection} = props;
	const {setDirtyReel} = useContext(DirtyReelContext);
	const {selected, emptySelectValues} = useSelectContext();
	const {updateToast} = useContext(ToastContext);
	const [sendToSegment] = useMutation(ADD_WORKSPACE_SEGMENT_MEMBERS);
	const [createClips] = useMutation<CreateClipsReturn, CreateClipsVars>(CREATE_CLIPS, {
		onCompleted: (x) => {
			updateToast({description: "Added clips to reel", type: "informational"});
			emptySelectValues();
			setDirtyReel(x.createClips[0].reel);
		},
	});

	const {anchorEl: anchorReel, handleClick: handleReel, handleClose: closeReel} = useDropdown();
	const {anchorEl: anchorList, handleClick: handleList, handleClose: closeList} = useDropdown();
	const {anchorEl: anchorInvite, handleClick: handleInvite, handleClose: closeInvite} = useDropdown();

	const handleClear = (): void => {
		emptySelectValues();
		onClearSelection();
	}

	const handleAddClips = (reelId: string): void => {
		// For this selection, strings are ANSID_USERID, we want the first
		const ansIds = selected.map((s) => {
			const [x] = s.split("_");
			return {answerId: x};
		});
		createClips({
			variables: {
				reelId,
				input: ansIds,
				useFilter: allSelected || false,
				filter,
			},
		});
	};

	const handleAddToList = (segmentId: string): void => {
		// For this selection, strings are ANSID_USERID, we want the second
		const vals = selected.map((s) => {
			const [, x] = s.split("_");
			return x;
		});
		const userIds = [...new Set(vals)];

		sendToSegment({
			variables: {
				segmentId,
				userIds,
				workspaceId,
				useAnswersFilter: allSelected || false,
				answersFilter: filter,
			},
			onCompleted: (data) => {
				const addedUsersLength = data.addSegmentMembers.addedUsers.length;
				const existingUsersLength = data.addSegmentMembers.existingUsers.length;
				updateToast({
					description: (
						<div>
							{addedUsersLength > 0 && <div>{addedUsersLength} added to list</div>}
							{existingUsersLength > 0 && <div>{existingUsersLength} already in list</div>}
						</div>
					),
					type: "informational",
				});
				emptySelectValues();
			},
			onError: (err) => {
				updateToast({description: err.message, type: "failure"});
			},
		});
	};

	if (selected.length === 0 && (!allSelected || totalItems === undefined || totalItems === 0)) return null;

	return (
		<Portal id="bulk-actions">
			<div className={styles.container}>
				<div className={styles.grid}>
					<p className={styles.count}>{allSelected ? totalItems : selected.length} selected</p>
					<div className={styles.action} onClick={handleReel}>
						<Icon name="play-plus" fill="white" size="small" />
						<span>Add to reel</span>
					</div>
					<AddReelDropdown
						anchorEl={anchorReel}
						workspaceId={workspaceId}
						addClip={handleAddClips}
						closeCallback={closeReel}
					/>
					<div className={styles.action} onClick={handleList}>
						<Icon name="add-members" fill="white" size="small" />
						<span>Add to list</span>
					</div>
					<AddListDropdown
						anchorEl={anchorList}
						workspaceId={workspaceId}
						addToList={handleAddToList}
						closeCallback={closeList}
					/>
					<div className={styles.action} onClick={handleInvite}>
						<Icon name="envelope" fill="white" size="small" />
						<span>
							Invite to
							<br />
							Campaign
						</span>
					</div>
					<div className={styles.action} onClick={addProperty}>
						<Icon name="note" fill="white" size="small" />
						<span>Add Property</span>
					</div>
					<InviteCampaignDropdown
						anchorEl={anchorInvite}
						workspaceId={workspaceId}
						closeCallback={closeInvite}
						useFilter={allSelected}
						filter={filter}
						useUserIds={selected.length > 0}
						userIds={selected.map((s): string => s.split("_")[1])}
						numFilterSelected={totalItems}
					/>
					{setIsShowingDelete && (
						<div className={styles.action} onClick={() => setIsShowingDelete(true)}>
							<Icon name="trash" fill="white" size="small" />
							<span>Delete</span>
						</div>
					)}
				</div>
				<p className={styles.clear} onClick={handleClear}>
					clear selection
				</p>
			</div>
		</Portal>
	);
};

export {BulkActionsBar};
