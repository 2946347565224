import React, {ReactElement} from "react";

import {SparkAiStarsIcon} from "../../../icons";
import {EmptyState} from "../../../shared/v2";

export const MagicTopicsPage = (): ReactElement => {
	return <EmptyState
		title="Magic Topics"
		description="Identify important themes and topics that people are talking about the most. Coming soon."
		icon={<SparkAiStarsIcon />}
	/>
}
