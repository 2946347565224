import {useState} from "react";

export const useTask = <T, RunProps extends Array<unknown>>(task: (...args: RunProps) => Promise<T>) => {
	const [loading, setLoading] = useState(false);

	const run = async (...args: RunProps) => {
		setLoading(true);
		try {
			return await task(...args);
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
		} catch (e) {
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	return {loading, run};
}
