import classNames from 'classnames/bind';
import React, {ReactElement, useMemo, useState} from 'react';

import {Body, Button, Separator, Subheader, Tooltip} from '../../../shared/v2';
import {ChevronLeftIcon} from '../../../icons';
import {SubheaderProps} from '../../../shared/v2/typography/Subheader';
import {useAgentCreationContext} from '../../../context/agent-creation-context';
import {useNavigate} from '../../../route';
import {useThemeMode} from '../../../context/theme-mode-context';
import {ConfirmActionModal} from '../../../shared/v2/modals/confirm-action-modal';

import styles from './agent-top-bar.module.scss';

const cx = classNames.bind(styles);

export const AgentTopBar = (): ReactElement => {
	const navigate = useNavigate();
	const {
		activate: activateAgent,
		deactivate: deactivateAgent,
		agent,
		changes,
		isCreating: isCreatingAgent,
		isNavigationOpen,
		isUpdating: isUpdatingAgent,
		save: saveAgent,
	} = useAgentCreationContext();
	const {isDarkMode} = useThemeMode();
	const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] = useState(false);
	const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);

	const isPublished = useMemo(() => {
		return agent?.personaStatus === "published";
	}, [agent?.personaStatus]);

	const handleLeave = () => (
		Object.keys(changes).length ?
			setIsUnsavedChangesModalOpen(true) :
			navigate("/agents")
	);

	const buttonSize = isNavigationOpen ? undefined : "small";

	const subheaderProps = {
		animated: true,
		className: styles.header,
		color: isDarkMode ? "charcoal-elevation-200" : "gray-modern-600",
		size: isNavigationOpen ? "l" : undefined,
		type: isNavigationOpen ? "medium" : undefined,
	} as SubheaderProps;

	const renderActivateDeactivateButton = () => {
		if (isPublished) {
			return <Button
				animated
				disabled={!agent}
				onClick={() => setIsDeactivateModalOpen(true)}
				size={buttonSize}
				variant="filled"
			>
        Deactivate
			</Button>
		}

		return <Tooltip content={(
			<Body size="xs">
        Activate your agent to start using it in your conversations.
			</Body>
		)}>
			<Button
				animated
				disabled={!agent}
				onClick={activateAgent}
				size={buttonSize}
				variant="filled"
			>
        Activate
			</Button>
		</Tooltip>
	}

	return (
		<>
			<div className={cx("agentTopBar", {closed: !isNavigationOpen, isDarkMode})}>
				<div className={styles.leftSide}>
					<Button
						animated
						filledIcon
						leftIcon={<ChevronLeftIcon />}
						onClick={handleLeave}
						size={buttonSize}
						variant="text"
					>
            Back
					</Button>

					<Subheader {...subheaderProps}>
            Agent Builder
					</Subheader>
				</div>

				<Separator color='gray-modern-300' orientation="vertical" className={cx("separator", {isDarkMode})} />

				<div className={styles.rightSide}>
					<Subheader {...subheaderProps}>
            Preview
					</Subheader>

					<div className={styles.actionButtons}>
						<Button
							animated
							disabled={isCreatingAgent || isUpdatingAgent}
							onClick={() => saveAgent()}
							size={buttonSize}
							variant="outlined"
						>
              Save
						</Button>

						{renderActivateDeactivateButton()}
					</div>
				</div>
			</div>

			<ConfirmActionModal
				title="Unsaved Changes"
				description="Looks like you have made changes that haven't been saved yet. Do you want to leave this page and discard your changes, or stay and save them?"
				isOpen={isUnsavedChangesModalOpen}
				onClose={() => setIsUnsavedChangesModalOpen(false)}
				onConfirm={() => {
					navigate("/agents");
					setIsUnsavedChangesModalOpen(false);
				}}
				confirmText="Discard Changes"
				cancelText="Continue Editing"
			/>

			<ConfirmActionModal
				title="Deactivate Agent"
				description="Are you sure you want to deactivate this agent? Once deactivated, the agent will be removed from all conversations. You can reactivate it at any time."
				isOpen={isDeactivateModalOpen}
				onClose={() => setIsDeactivateModalOpen(false)}
				onConfirm={() => {
					deactivateAgent();
					setIsDeactivateModalOpen(false);
				}}
				confirmText="Deactivate"
				cancelText="Cancel"
			/>
		</>
	)
};
