import React, {ReactElement} from "react";
import {Icon, IconTypes} from "../../../shared";
import styles from "./empty-screen.module.scss";
import {Button, Body, Header} from "../../../shared/v2";

// More probably added in near future
export type EmptyTable = "LIST" | "PROPERTY" | "CATEGORY"
| "CREATORS" | "CAMPAIGN" | "REQUEST" | "REELS" | "REELS_OTHER" | "REWARDS" | "SEGMENT"
| "REELS_BRAND";

interface EmptyText {
	header: string;
	text: string;
	icon: IconTypes;
}

type IEmptyText = {
	[key in EmptyTable]: EmptyText;
};

const EMPTY_REASONS: IEmptyText = {
	LIST: {
		header: "Organize your creators",
		text: "It pays to be organized. Lists let you group creators and speed up sharing campaigns.",
		icon: "two-people",
	},
	PROPERTY: {
		header: "Manage custom properties",
		 
		text: "Properties help automate data collection and organization. Set properties and map campaign responses directly to creator profiles.",
		icon: "bullseye",
	},
	CREATORS: {
		header: "Start building your audience",
		 
		text: "Creators are individuals ready to provide feedback and opinions to make your brand better. Import your contact list or add new creators via email. Build your audience and start co-creating together",
		icon: "two-people",
	},
	CATEGORY: {
		header: "Group your custom properties",
		 
		text: "Categories let you group your custom properties to make them easier to find and use. For example, you might group Age and Gender under a \"Demographics\" category",
		icon: "four-grid",
	},
	CAMPAIGN: {
		header: "Get started with your first campaign",
		 
		text: "Use campaigns to ask questions, gather feedback, test concepts, and gain insight from creators",
		icon: "icon-megaphone",
	},
	REQUEST: {
		header: "You have no requests right now",
		 
		text: "Make sure you have set up your Brand landing page and have shared it across social media.",
		icon: "two-people",
	},
	REELS: {
		header: "Video editing made easy",
		 
		text: "Reels is an all-in-one video highlight maker. Using video responses from your campaigns, just add the clips you want, save it, and share with the world",
		icon: "video-slate",
	},
	REELS_OTHER: {
		header: "No reels here",
		text: "There aren't any reels under this category yet.",
		icon: "video-slate",
	},
	REELS_BRAND: {
		header: "Share reels to your brand page",
		 
		text: "Head over to the reels page to share some reels to your brand. They'll start showing up here",
		icon: "video-slate",
	},
	REWARDS: {
		header: "Your rewards aren't set up",
		 
		text: "Head to your settings and integrate with Tremendous to be able to send your creators some rewards. If you've set them up already, make sure it is enabled",
		icon: "star",
	},
	SEGMENT: {
		header: "Organize your creators",
		text: "Segments are dynamic groups of creators based on property values.",
		icon: "two-people",
	},
};

export interface EmptyScreenProps {
	whatIsEmpty: EmptyTable;
	children?: React.ReactNode;
	action?: () => void
	invite?: () => void;
}

const EmptyScreen = ({children, whatIsEmpty, action, invite}: EmptyScreenProps): ReactElement => {
	return (
		<div className={styles.container}>
			<Icon name={EMPTY_REASONS[whatIsEmpty].icon} fill="var(--color-text-body)" size="extralarge"/>
			<Header className={styles.center} type="semibold">{EMPTY_REASONS[whatIsEmpty].header}</Header>
			<Body
				className={styles.center}
				color="text-secondary"
			>
				{EMPTY_REASONS[whatIsEmpty].text}
			</Body>
			{whatIsEmpty === "CREATORS" ? <>
				<Button onClick={invite}>Invite Creators</Button>
			</>
				: action ? <span onClick={action}>+ Create {whatIsEmpty.toLowerCase()}</span> : undefined
			}
			{children}
		</div>
	);
};

export {EmptyScreen};
