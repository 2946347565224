import React, {ReactElement, cloneElement} from "react";
import classNames from "classnames/bind";

import {Avatar} from "../avatar";
import {Body, Caption} from "../../../shared/v2/typography";
import {CloseMediumIcon} from "../../../icons";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./input-chip.module.scss";

const cx = classNames.bind(styles);

export interface InputChipProps {
	label: string;
	image?: string;
  icon?: ReactElement;
	disabled?: boolean;
	className?: string;
  variant?: "rounded" | "squared";
	onRemove?: () => void;
  size?: "default" | "small";
}

export const InputChip = (
	{
		label,
		image,
		icon,
		className,
		disabled,
		onRemove,
		variant = "rounded",
		size = "default"
	}: InputChipProps,
): ReactElement => {
	const {isDarkMode} = useThemeMode();

	const renderContent = () => {
		if (size === "small") {
			return <Caption className={styles.label} type="medium" color="text-body">{label}</Caption>
		}

		return <Body className={styles.label} size="s" color="text-secondary" type="medium">
			{label}
		</Body>
	}

	return (
		<button
			className={cx("inputChip", className, variant, size, {isDarkMode})}
			type="button"
			disabled={disabled}
		>
			{image &&
				<Avatar additionalClasses={styles.avatar} imageUrl={image} />
			}
			{icon && cloneElement(icon, {className: cx("icon", size)})}

			{renderContent()}

			<CloseMediumIcon
				className={cx("closeIcon", size)}
				onClick={disabled ? () => undefined : onRemove}
			/>
		</button>
	)
}
