import React, {ReactElement} from "react";

import {AgentCard} from "../../../../agents/components/agent-card";
import {useUnsafeAgentCreationContext} from "../../../../context/agent-creation-context";
import {ChatConversationMessage} from "../../../../models/ai-model";
import {Avatar, Tooltip} from "../../../../shared/v2";
import {AvatarProps} from "../../../../shared/v2/avatar";

import styles from "./response-avatar.module.scss";

export interface ResponseAvatarProps {
  message: ChatConversationMessage;
}

export const ResponseAvatar = ({message}: ResponseAvatarProps): ReactElement => {
	const {state: {picture: agentCreationPicture, name: agentCreationName} = {}} =
    useUnsafeAgentCreationContext() ?? {};

	const avatarProps: Pick<AvatarProps, "size" | "type"> = {
		size: "md",
		type: "square"
	};

	if (message.persona) {
		let content = <Avatar firstName={message.persona.name} {...avatarProps} />;

		if (message.persona.picture?.url) {
			content = <Avatar key={message.persona.picture.url} {...avatarProps} url={message.persona.picture.url} />;
		}
		return (
			<Tooltip
				placement="top-start"
				containerClassname={styles.tooltip}
				custom
				content={<AgentCard className={styles.agentCard} persona={message.persona} />}
			>
				{content}
			</Tooltip>
		);
	} else if (agentCreationPicture) {
		return <Avatar key={agentCreationPicture.url} {...avatarProps} url={agentCreationPicture.url} />;
	} else if (agentCreationName) {
		return <Avatar firstName={agentCreationName} {...avatarProps} />;
	}

	return <Avatar {...avatarProps} url="/images/vurvey-light.svg" />;
}
