import React, {PropsWithChildren, ReactElement} from "react";
import classNames from "classnames/bind";

import styles from "./Header.module.scss";

const bStyles = classNames.bind(styles);

export type HeaderProps = PropsWithChildren<{
	className?: string;
	size?: "xl" | "l" | "base";
	type?: "regular" | "medium" | "semibold" | "bold";
	color?: "text-body" | "text-secondary";
}>;

export const Header = ({
	children,
	size = "base",
	type = "regular",
	color = "text-body",
	className,
}: HeaderProps): ReactElement => {
	return <div className={bStyles("header", size, type, color, className)}>
		{children}
	</div>;
};
