import {useSignInWithGoogle} from "react-firebase-hooks/auth";
import React, {ReactElement} from "react";

import styles from "./Buttons.module.scss";
import {auth} from "../../utils/firebase";

export interface GoogleSignInButtonProps {
	text?: string;
	id?: string;
}

export const GoogleSignInButton = ({
	text = "Sign in with Google",
	id,
}: GoogleSignInButtonProps): ReactElement => {
	const [signInWithGoogle] = useSignInWithGoogle(auth);

	const handleSignIn = (): void => {
		signInWithGoogle();
	};

	return (
		<button id={id} onClick={handleSignIn} className={styles.authButton}>
			<img src="/images/google-icon.svg" alt="Google Icon" />
			<span className={styles.authButtonText}>{text}</span>
		</button>
	);
};
