import React, {ReactElement} from "react";
import {Outlet} from "react-router";

import {Button} from "../../../shared/v2";
import {NavigationPageLayout} from "../../../hoc/layout/navigation-page-layout";
import {PageSubNavigation} from "../../../hoc/layout/layout/page-sub-navigation";
import {PlusIcon} from "../../../icons";
import {useMount} from "../../../hooks/useMount";
import {useNavigate} from "../../../route";

export const AgentsPage = (): ReactElement => {
	const navigate = useNavigate();

	useMount(() => {document.title = "Vurvey - Agents"})

	return <NavigationPageLayout>
		<PageSubNavigation
			header="Agents"
			controlPanelContent={
				<Button
					leftIcon={<PlusIcon />}
					onClick={() => navigate("/agents/builder")}
				>
          Create Agent
				</Button>
			}
		/>
		<Outlet />
	</NavigationPageLayout>
}
