import React, {cloneElement, ReactElement} from "react";

import {DropdownItem} from "../..";
import {DropdownItemIcon} from "..";
import {Body} from "../../../../typography";
import {DropdownItemTooltip} from "../tooltip";

import styles from "../../items.module.scss";

export interface DropdownItemContentProps {
  item: DropdownItem;
}

export const DropdownItemContent = ({item}: DropdownItemContentProps): ReactElement => {
	return (
		<>
			<DropdownItemIcon item={item} />

			<DropdownItemTooltip item={item}>
				<Body type="medium" className={styles.label}>{item.label}</Body>
			</DropdownItemTooltip>

			{item.rightIcon &&
        cloneElement(item.rightIcon, {className: styles.icon})
			}
		</>
	);
}
