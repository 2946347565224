import {useQuery} from "@apollo/client";
import React, {ReactNode, createContext, useCallback, useEffect, useMemo, useState} from "react";

import {Campaign} from "../../models/ai-model";
import {GET_CAMPAIGNS} from "../../graphql/queries/ai-models-queries";
import {useChatConversationContext} from "./chat-conversation-context";
import {useNavigate, useSearchParams} from "../../route";
import {useWorkspaceContext} from "../workspace-context";

export interface ChatCampaignContextValue {
	isLoading?: boolean;
	isUpdatingDisabled?: boolean;
	campaigns: Campaign[];
	activeCampaigns: Campaign[];
	saveCampaigns: (surveyIds?: string[]) => Promise<void>;
	refetch: () => Promise<Campaign[]>;
}

export const ChatCampaignContext =
  createContext<ChatCampaignContextValue | undefined>(undefined);

export const ChatCampaignContextProvider = (
	{children}: {children: ReactNode},
): React.ReactElement => {
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const {
		conversation,
		isLoading: isLoadingConversation,
		updateConversation,
	} = useChatConversationContext();
	const [activeCampaigns, setActiveCampaigns] = useState<Campaign[]>([]);
	const {surveyIds: surveySavedIds} = useSearchParams();
	const navigate = useNavigate();

	const {
		data,
		loading: isLoading,
		refetch,
	} = useQuery<{
		campaigns: {
			items: Campaign[];
			cursor: string;
			remaining: number;
		};
	}>(GET_CAMPAIGNS, {
		variables: {
			workspaceId,
		},
	});

	const handleRefetch = useCallback(async () => {
		const {data: {campaigns: {items: result}}} = await refetch();

		return result;
	}, [refetch]);

	const isUpdatingDisabled = Boolean((isLoadingConversation || isLoading) && workspaceId);

	const saveCampaigns = useCallback(async(surveyIds?: string[]): Promise<void> => {
		if (isUpdatingDisabled) {
			throw new Error("Campaigns updating is disabled");
		}

		const campaigns = data?.campaigns?.items.filter(campaign => surveyIds?.includes(campaign.id)) || [];
		setActiveCampaigns(campaigns);

		if (conversation) {
			await updateConversation({
				surveyIds: surveyIds || [],
			});
		} else {
			navigate({search: {
				surveyIds,
			}}, {search: true});
		}
	}, [updateConversation, navigate, isUpdatingDisabled, data?.campaigns?.items]);

	const surveys = useMemo(() => {
		return data?.campaigns?.items || [];
	}, [data]);

	const savedCampaigns = useMemo(() => {
		if (conversation?.surveys?.length) {
			const selectedIds = conversation?.surveys?.map(survey => survey.id) || [];
			return surveys.filter(survey => selectedIds.includes(survey.id));
		}
		if (surveySavedIds) {
			return surveys.filter(survey => (surveySavedIds as string[]).includes(survey.id)) || [];
		}
		return [];
	}, [
		conversation?.surveys?.length,
		surveys.map(survey => survey.id).join(),
		surveySavedIds?.join(),
	]);

	useEffect(() => {
		setActiveCampaigns(savedCampaigns);
	}, [savedCampaigns]);

	useEffect(() => {
		if (conversation && surveySavedIds?.length) {
			navigate({search: {
				surveyIds: undefined,
			}}, {search: true});
		}
	}, [
		conversation?.id,
		surveySavedIds?.join(),
	]);

	return (
		<ChatCampaignContext.Provider
			value={{
				activeCampaigns,
				campaigns: surveys,
				isLoading,
				isUpdatingDisabled,
				saveCampaigns,
				refetch: handleRefetch,
			}}
		>
			{children}
		</ChatCampaignContext.Provider>
	);
};

export const useChatCampaignContext = (): ChatCampaignContextValue => {
	const context = React.useContext(ChatCampaignContext);

	if (context === undefined) {
		throw new Error(
			"useChatCampaignContext must be used within a ChatCampaignContextProvider",
		);
	}

	return context;
};
