import {OAuthCredential} from "firebase/auth";

export enum SignUpModalStep {
	Initial,
	ChooseLoginMethod,
	Email,
	Password,
	SignUp,
	VerifyEmail,
	RecoverPassword,
	CheckYourEmail,
}

export interface SignUpModalHistoryEntry {
	step: SignUpModalStep;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	customData?: any;
}

export enum SignUpModalActionTypes {
	SET_FIREBASE_CREDENTIALS = "SET_FIREBASE_CREDENTIALS",
	SET_EMAIL = "SET_EMAIL",
	SET_CURRENT_STEP = "SET_CURRENT_STEP",
	REVERT_STEP = "REVERT_STEP",
	RESET_STATE = "RESET_STATE",
}

export interface SignUpModalState {
	firebaseCredentials?: OAuthCredential;
	email?: string;
	currentStep: SignUpModalStep;
	history: SignUpModalHistoryEntry[];
}

export interface SetFirebaseCredentialsAction {
	type: SignUpModalActionTypes.SET_FIREBASE_CREDENTIALS;
	payload: OAuthCredential;
}

export interface SetEmailAction {
	type: SignUpModalActionTypes.SET_EMAIL;
	payload: string;
}

export interface SetCurrentStepAction {
	type: SignUpModalActionTypes.SET_CURRENT_STEP;
	payload: SignUpModalHistoryEntry;
}

export interface RevertStepAction {
	type: SignUpModalActionTypes.REVERT_STEP;
}

export interface ResetStateAction {
	type: SignUpModalActionTypes.RESET_STATE;
}

export type SignUpModalAction =
	| SetFirebaseCredentialsAction
	| SetEmailAction
	| SetCurrentStepAction
	| RevertStepAction
	| ResetStateAction;

export const SignUpModalReducer = (
	state,
	action: SignUpModalAction,
): SignUpModalState => {
	const fullBackTransitionSteps = [
		SignUpModalStep.VerifyEmail,
		SignUpModalStep.CheckYourEmail,
	];
	switch (action.type) {
	case SignUpModalActionTypes.SET_FIREBASE_CREDENTIALS:
		return {
			...state,
			firebaseCredentials: action.payload,
		};
	case SignUpModalActionTypes.SET_EMAIL:
		return {
			...state,
			email: action.payload,
		};
	case SignUpModalActionTypes.SET_CURRENT_STEP:
		return {
			...state,
			currentStep: action.payload.step,
			history: [...state.history, action.payload],
		};
	case SignUpModalActionTypes.REVERT_STEP:
		if (state.history.length === 1) {
			return state;
		}
		if (fullBackTransitionSteps.includes(state.currentStep)) {
			return {
				...state,
				currentStep: SignUpModalStep.Initial,
				history: [{step: SignUpModalStep.Initial}],
			};
		}

		return {
			...state,
			currentStep: state.history[state.history.length - 2].step,
			history: state.history.slice(0, state.history.length - 1),
		};
	case SignUpModalActionTypes.RESET_STATE:
		return {
			currentStep: SignUpModalStep.Initial,
			history: [{step: SignUpModalStep.Initial}],
		};
	default:
		return state;
	}
};
