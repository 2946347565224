import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import {Body} from "../../../shared/v2/typography";
import {useThemeMode} from "../../../context/theme-mode-context";
import {Input, TextArea} from "../../../shared/v2/inputs";

import styles from "./training-set-form.module.scss";

const bStyles = classNames.bind(styles);

export interface TrainingSetFormProps {
	name: string;
	setName: (value: string) => void;
	description: string;
	setDescription: (value: string) => void;
}

export const TrainingSetForm = ({
	name,
	setName,
	description,
	setDescription,
}: TrainingSetFormProps):ReactElement => {
	const {isDarkMode} = useThemeMode();
	return <div className={styles.container}>
		<div className={styles.inputContainer}>
			<Input
				label="Name"
				placeholder="My-Dataset"
				value={name}
				onChange={setName}
				maxLength={35}
				onKeyUp={e => setName(e.currentTarget.value.replace(/\s/g, "-"))}
			/>
			<Body
				size="xs"
				className={bStyles("nameRequirements", {isDarkMode})}
			>
      Spaces in the name will be automatically replaced with dashes (-) due to
      system requirements.
			</Body>
		</div>

		<TextArea
			value={description}
			onChange={setDescription}
			label="Description"
			placeholder="Enter a brief description of your dataset"
			maxLength={255}
		/>
	</div>;
};
