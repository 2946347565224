import React, {ReactElement} from "react";
import styles from "./video-insights.module.scss";
import {isParseable} from "../../shared/utility/helpers";
import {VideoIntelligence} from "../../models/google-video-ai";
import {Tag} from "../../shared";

export interface VideoInsightsProps {
	unparsedInsights?: string; // URL given to us from the url(version:INSIGHTS)
}

const VideoInsights = (props: VideoInsightsProps): ReactElement | null => {
	const {unparsedInsights} = props;
	if (!(typeof unparsedInsights === "string" && isParseable(unparsedInsights))) return null;
	const insights = JSON.parse(unparsedInsights) as VideoIntelligence;

	const {annotation_results} = insights || {};
	const [results] = annotation_results;
	const {detection_details, shot_label_annotations} = results || {};
	return (
		<>
			{shot_label_annotations?.length && <div className={styles.tags}>
				<h2>Smart Tags</h2>
				{shot_label_annotations?.map(logo => <Tag key={logo.entity.entity_id}>
					{logo.entity.description}</Tag>)}
			</div>
			}
			{detection_details?.length && <div className={styles.detected}>
				<h2>Smart Objects</h2>
				{detection_details?.map(item => <div key={item.model} className={styles.item}>
					<img src={`data:image/png;base64,${item.image}`} />
					<div >
						<h3 className={styles.model}>{item.model}</h3>
						<p>Category: {item.category}</p>
						<p>Confidence: {item.confidence * 100}%</p>
					</div>
				</div>)}
			</div>}
		</>
	);
};

export {VideoInsights};
