import React, {ReactElement} from "react";
import {Setter} from "../types";
import {useMemoObject} from "../hooks";

interface TranscriptModalContext {
	answerId: string;
	setAnswerId: Setter<string>;
}

const TranscriptModalContext = React.createContext<TranscriptModalContext>({
	answerId: "",
	setAnswerId: () => undefined,
});

export interface TranscriptModalContextProps extends TranscriptModalContext {
	children: React.ReactNode;
}

 
const TranscriptModalContextProvider = ({children, ...props}: TranscriptModalContextProps): ReactElement => {
	const value = useMemoObject(props);
	return <TranscriptModalContext.Provider value={value}>
		{children}
	</TranscriptModalContext.Provider>;
};

export {TranscriptModalContext, TranscriptModalContextProvider};
