import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";

export const HashRedirect = () => {
	const {hash} = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (hash && hash.includes("#/")) {
			const path = hash.slice(1);
			navigate(path, {replace: true});
		}
	}, [hash]);

	return null;
};
