import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import {InputProps} from "../input";
import {InputElements} from "../input-elements";
import {DebounceInput} from "react-debounce-input";

import styles from "../input/input.module.scss";

const bStyles = classNames.bind(styles);

export const DebounceSearch = ({
	onChange,
	className,
	size = "small",
	variant = "default",
	label,
	hintText,
	leftIcon,
	rightIcon,
	id,
	...props
}: InputProps): ReactElement => {
	return <InputElements variant={variant}
		className={className}
		label={label}
		hintText={hintText}
		leftIcon={leftIcon}
		rightIcon={rightIcon
		}>
		<DebounceInput
			className={bStyles("input", size, variant, leftIcon && "withLeftIcon", rightIcon && "withRightIcon")}
			id={id}
			onChange={(e) => onChange(e.target.value)}
			debounceTimeout={300}
			{...props}
		/>

	</InputElements>
}
