import classNames, {Argument} from "classnames";
import React, {ReactElement, useContext, useEffect, useRef, useState} from "react";
import {GET_AUTO_REEL} from "../../../graphql/queries/survey-queries";
import {useLoadingQuery} from "../../../hooks";
import {GetSensemakeQueryReturn} from "../../../models/questions";
import {Tab, Tabs, TabList, TabPanel} from "react-tabs";
import {TopicTab} from "../topic-tab";
import styles from "./auto-reel-info.module.scss";
import {HelpTooltip} from "../../../shared/components/help-tooltip";
import {Icon} from "../../../shared";
import {useMutation} from "@apollo/client";
import {DUPLICATE_REEL, REEL_FEEDBACK} from "../../../graphql/mutations/reel-mutations";
import {
	CopiedReel,
	CopyReelVars,
	ReelFeedback,
	ReelFeedbackVars,
	ReelTrackersIndicator,
} from "../../../models/reels";
import {ToastContext} from "../../../context/toast-context";
import {useNavigate} from "../../../route";
import {isParseable} from "../../../shared/utility/helpers";
import {Button} from "../../../shared/v2";

export interface AutoReelInfoProps {
	id: string;
	className?: Argument
	senseData?: GetSensemakeQueryReturn;
	senseFragment?: React.ReactElement | null;
	answerTable: React.ReactElement | null;
}

export interface GenericTitleDesc {
	title: string;
	description: string;
}

export type ParsedTitleDesc = GenericTitleDesc[];

interface ReelSummaryProps {
	summary: GenericTitleDesc[] | string // Need to parse.
}
const ReelSummary = ({summary}: ReelSummaryProps): ReactElement => {
	if (typeof summary === "string") return <p>{summary}</p>;
	const {title, description} = summary[0];
	return (
		<>
			<h3 className={styles.heading}>{title}</h3>
			<p>{description}</p>
		</>
	);
};

const ReelInsights = ({insights}: {insights: string}): ReactElement => {
	if (isParseable(insights)) {
		const parsed: ParsedTitleDesc = JSON.parse(insights);
		return (
			<>
				{parsed.map(value => <div
					className={styles.insight}
					key={`${value.title}-${value.description.slice(0, 5)}`}>
					<h3>{value.title}</h3>
					<p>{value.description}</p>
				</div>)}
			</>
		);
	}
	return <p className={styles.paragraph}>{insights}</p>;
};

/**
 * This is now named poorly.
 * Handles basically all video response questions, puts everything into
 * separate tabs if there are questionInsights / reel stuff.
 * Otherwise, it just displays the table we pass to it.
 */
 
const AutoReelInfo = ({id, className, senseData, senseFragment, answerTable}: AutoReelInfoProps): ReactElement | null => {
	const ref = useRef<HTMLDivElement>(null);
	const navigate = useNavigate();
	const {updateToast} = useContext(ToastContext);
	const [showVideo, setShowVideo] = useState(false);
	const [tabIndex, setTabIndex] = useState(0);
	const {data, fragment} = useLoadingQuery<GetSensemakeQueryReturn>(GET_AUTO_REEL, {
		skip: Boolean(senseData) || Boolean(senseFragment),
		variables: {id},
		what: "summary reel",
	});
	const handleToggleVideo = (): void => setShowVideo(prev => !prev);


	const checkClick = ({target}): void => {
		if (!ref.current) return;
		const {current} = ref;
		if (current.contains(target)) return;
		setShowVideo(false);
	};

	const [copyReel] = useMutation<CopiedReel, CopyReelVars>(DUPLICATE_REEL, {
		onCompleted: ({duplicateReel}) => {
			updateToast({description: "Reel duplicated", type: "informational"});
			navigate(`/reel/${duplicateReel.id}`, {search: true});
		},
		onError: () => {
			updateToast({
				description: "Copy failed. Please check your connection and try again",
				type: "failure",
			});
		},
	});

	const handleCopy = (): void => {
		const reelId = data?.question.sensemakeReel.id || senseData?.question.sensemakeReel.id;
		if (!reelId) return;
		copyReel({variables: {id: reelId}});
	};

	const [logReel] = useMutation<ReelFeedback, ReelFeedbackVars>(REEL_FEEDBACK, {
		onCompleted: () => {
			updateToast({description: "Feedback sent.", type: "informational"});
		},
		onError: () => {
			updateToast({
				description: "Feedback could not be sent. Please check your connection and try again",
				type: "failure",
			});
		},
	});
	const sendFeedback = (feedback: boolean): void => {
		const reel = data?.question.sensemakeReel || senseData?.question.sensemakeReel;
		if (!reel) return;
		if (feedback) {
			logReel({variables: {
				reelId: reel.id,
				indicator: ReelTrackersIndicator.LIKE,
			}});
		} else {
			logReel({variables: {
				reelId: reel.id,
				indicator: ReelTrackersIndicator.DISLIKE,
			}});
		}
	};


	const handleSetIndex = (index: number): void => setTabIndex(index);

	useEffect(() => {
		if (showVideo) return document.addEventListener("mousedown", checkClick);
		document.removeEventListener("mousedown", checkClick);
		return () => document.removeEventListener("mousedown", checkClick);
	}, [showVideo]);

	// Feels like a little roundabout way of doing this.
	const summary = data?.question.questionInsights?.summary
		|| senseData?.question.questionInsights?.summary;
	const senseReel = data?.question.sensemakeReel?.video
		|| senseData?.question?.sensemakeReel?.video;
	const topicsString = data?.question.questionInsights?.topics
		|| senseData?.question?.questionInsights?.topics;
	const insights = data?.question.questionInsights?.insights
		|| senseData?.question?.questionInsights?.insights;
	const frag = fragment || senseFragment;

	if (!senseReel && !frag) return answerTable;
	return (
		<Tabs
			selectedIndex={tabIndex}
			onSelect={handleSetIndex}
			className={styles.tabs}
			selectedTabClassName={styles.selected}
		>
			<TabList className={styles.tabList}>
				{answerTable &&
				<Tab><Icon name="play-solid" size="extrasmall" className={styles.tabIcon}/>Videos</Tab>}
				<Tab><Icon name="video-slate" size="extrasmall" className={styles.tabIcon}/>Magic Reel</Tab>
				<Tab>
					<Icon name="bolt" size="extrasmall" className={styles.tabIcon}/>
					Key Takeaways
				</Tab>
				<Tab><Icon name="hash" size="extrasmall" className={styles.tabIcon}/>Topics</Tab>
			</TabList>
			{answerTable && <TabPanel>
				{answerTable}
			</TabPanel>}
			<TabPanel>
				{frag || <div className={classNames(styles.container, className)} ref={ref}>
					<div className={styles.summary}>
						{/* <p>{summary || "There is no summary available right now."}</p> */}
						<ReelSummary summary={summary || "There is no summary available right now."}/>
					</div>
					<div className={styles.videoContainer}>
						{showVideo ?
							<video
								controls
								controlsList="nodownload"
								autoPlay
								disablePictureInPicture
								src={senseReel?.mp4}
								playsInline /> :
							<>
								<img
									src={senseReel?.thumbnail}
									alt="Highlight Reel Thumbnail" />
								<div className={styles.playWrap} onClick={handleToggleVideo}>
									<span className={styles.playButton} />
								</div>
							</>}
					</div>
					<div className={styles.actions}>
						<div className={styles.helpful}>
							<p>Was this helpful?</p>
							<div className={styles.thumbs}>
								<div onClick={() => sendFeedback(true)}>
									<Icon name="thumbs-up" size="extrasmall" isClickable/>
								</div>
								<div onClick={() => sendFeedback(false)}>
									<Icon name="thumbs-down" size="extrasmall" isClickable/>
								</div>
							</div>
						</div>
						<Button
							id="copy"
							onClick={handleCopy}
						>
							Copy Reel
						</Button>
					</div>
				</div>
				}
			</TabPanel>
			<TabPanel>
				<div className={styles.panel}>
					{frag ||
						<ReelInsights insights={insights || "Currently there are no available insights"}/>
					}
				</div>
			</TabPanel>
			<TabPanel>
				{frag || <div className={styles.panel}>
					{topicsString &&
						<TopicTab
							topics={topicsString}
							handleSetIndex={handleSetIndex}
							questionIndex={data?.question.index}
						/>
					}
					<p className={styles.helpFooter}>What does this mean? <HelpTooltip id="help">
						<p className={styles.helpText}>
							<b>Sentiment</b> ranges between -1.0 (negative) and 1.0 (positive) and<br />
						corresponds to the overall emotional leaning of the text.</p>
						<p className={styles.helpText}>
							<b>Magnitude</b> indicates the overall strength of emotion<br/>
						(both positive and negative) within the given text, between 0.0 and +inf.
						</p>
					</HelpTooltip></p>
				</div>}
			</TabPanel>
		</Tabs>
	);
};



export {AutoReelInfo, ReelSummary};
