import React, {ReactElement} from "react";

import {ErrorBoundary} from "../../components";

import styles from "./main-error-boundary.module.scss";

export interface MainErrorBoundaryProps {
  children: ReactElement;
}

export const MainErrorBoundary = ({children}: MainErrorBoundaryProps): ReactElement => {
	const fallback = (
		<div className={styles.mainErrorBoundary}>
			<h1>Something went wrong</h1>
			<p>Try refreshing the page or contact support</p>
		</div>
	)

	return (
		<ErrorBoundary FallbackComponent={() => fallback}>
			{children}
		</ErrorBoundary>
	);
}
