import {GET_METABASE_URL} from "../../../graphql/queries/queries";
import React, {ReactElement, useState} from "react";
import {useQuery} from "@apollo/client";
import styles from "./metabase-dashboard.module.scss";

const MetabaseDashboard = (): ReactElement => {
	const [metabaseUrl, setMetabaseUrl] = useState<string | undefined>(undefined);
	const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
	useQuery(GET_METABASE_URL, {
		onCompleted(x) {
			setMetabaseUrl(x.metabaseDashboardUrl);
		},
		onError() {
			setFailedToLoad(true);
		},
		fetchPolicy: "cache-and-network",
	});
	return (
		<>
			{metabaseUrl ? (
				<iframe
					width="100%"
					height="100%"
					src={metabaseUrl}
					frameBorder="0"
					allowFullScreen
				/>
			) : failedToLoad ? (
				<div className={styles.container}>
					<header>
						<h2 className={styles.h2}>
							{"The metabase dashboard couldn't be accessed."}
						</h2>
					</header>
				</div>
			) : (
				<></>
			)}
		</>
	);
};

export {MetabaseDashboard};
