import {LinkProps} from "react-router-dom";
import {Popover} from "@headlessui/react";
import React, {ReactElement} from "react";

import {DropdownItem} from "..";
import {DropdownItemContent} from "../_helpers/content";
import {DropdownItemSpacing, useItemClassName} from "../_helpers";
import {Link} from "../../../../../route";

export interface DropdownLinkItemProps {
	item: DropdownItem & {link: Pick<LinkProps, "to">}
}

export const DropdownLinkItem = ({item}: DropdownLinkItemProps): ReactElement => {
	const itemClassName = useItemClassName(item);

	return (
		<DropdownItemSpacing active={item.active}>
			<Popover.Button as={Link} className={itemClassName} to={item.link} workspace>
				<DropdownItemContent item={item} />
			</Popover.Button>
		</DropdownItemSpacing>
	);
};
