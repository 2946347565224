import {gql} from "@apollo/client";
import {QUESTION_TEMPLATE_FRAGMENT} from "../fragments/fragments";

export const GET_QUESTION_TEMPLATES = gql`
	query GetQuestionTemplates(
		$workspaceId: GUID!,
		$excludeWorkspace: Boolean,
		$excludeGlobals: Boolean,
		$sort: QuestionTemplatesSort,
		$filter: QuestionTemplateFilter) {
		questionTemplates(
			workspaceId: $workspaceId,
			excludeWorkspace: $excludeWorkspace,
			excludeGlobals: $excludeGlobals,
			sort: $sort,
			filter: $filter
		) {
			items {
				...QuestionTemplateFields
			}
		}
	}
	${QUESTION_TEMPLATE_FRAGMENT}
`;
