export enum WorkspaceRole {
	OWNER = "OWNER",
	ADMINISTRATOR = "ADMINISTRATOR",
	MANAGER = "MANAGER",
	CREATOR = "CREATOR",
}

export enum Gender {
	MALE = "MALE",
	FEMALE = "FEMALE",
	NONBINARY = "NONBINARY",
}
export interface AgeRange {
	min: number | undefined;
	max: number | undefined;
}
// Backend takes yyyy-mm-dd for the format of start and end dates
export interface DateRange {
	start: string;
	end: string;
}
export interface CustomPropsFilter {
	id: string;
	values: string[];
}
export interface WorkspaceMembersFilter {
	role?: WorkspaceRole | null;
	gender?: Gender[];
	ageRange?: AgeRange | undefined;
	countryId?: string[];
	firstFeedbackSurveyResponseAt?: DateRange;
	lastFeedbackSurveyResponseAt?: DateRange;
	firstSurveyResponseAt?: DateRange;
	lastSurveyResponseAt?: DateRange;
	customProperties?: CustomPropsFilter[];
	tagId?: string[];
	segmentId?: string;
	name?: string;
	dynamicSegmentId?: string;
	ethnicityId?: string[];
	stateId?: string[];
	surveyIds?: string[];
	any?: boolean;
	completed?: boolean;
	haveNot?: boolean;
	hasTakenSurvey?: {
		surveyIds?: string[];
		any?: boolean;
		completed?: boolean;
		haveNot?: boolean;
	}
}

export interface WorkspaceMembersSearchQuery {
	role?: WorkspaceRole | null;
	gender?: Gender[] | null;
	ageRange?: string;
	countryId?: string[];
	tagId?: string[];
	segmentId?: string;
	name?: string;
}
