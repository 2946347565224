import {gql} from "@apollo/client";

export const GROUNDING_FRAGMENT = gql`
	fragment GroundingFields on Grounding {
    type
    score
    page
    questionSummary {
      questionId
      surveyId
      questionText
    }
    answer {
      surveyId
      surveyTitle
      questionId
      questionText
      answerVideoThumbnailUrl
      answerId
      creatorTag
    }
    trainingSetFile {
      trainingSetId
      trainingSetName
      fileUrl
      originalFilename
      trainingSetDescription
    }
    trainingSetVideo {
      trainingSetId
      trainingSetName
      fileUrl
      originalFilename
      trainingSetDescription
    }
	}
`;
