import {gql} from "@apollo/client";

export const GET_ALL_ATTRIBUTES = gql`
	query GetAttributes(
		$workspaceId: GUID!
		$limit: PositiveInt
		$cursor: String
		$sort: AttributesSort
		$filter: AttributeFilter
	) {
		attributes(
			workspaceId: $workspaceId
			limit: $limit
			cursor: $cursor
			sort: $sort
			filter: $filter
		) {
			items {
				id
				name
				type
				creator {
					name
				}
				rules {
					id
				}
				category {
					name
					id
				}
			}
			cursor
			remaining
		}
	}
`;

export const GET_ALL_ATTRIBUTES_W_EXTANT = gql`
	query GetAttributes($workspaceId: GUID! $limit: PositiveInt $cursor: String) {
		attributes(workspaceId: $workspaceId limit: $limit cursor: $cursor) {
			items {
				id
				name
				extantValues
				category {
					name
				}
			}
		}
	}
`;

export const GET_ALL_ATTRIBUTES_WITH_VALUES = gql`
	query GetAttributes($workspaceId: GUID! $limit: PositiveInt $cursor: String) {
		attributes(workspaceId: $workspaceId limit: $limit cursor: $cursor) {
			items {
				id
				name
				type
				extantValues
				rules {
					id
				}
			}
		}
	}
`;

export const GET_ATTR_DETAILS = gql`
	query GetAttribute($id: GUID!) {
		attribute(id: $id) {
			id
			name
			description
			type
			rules {
				id
				question {
					id
					text
					surveyId
					type
					subtype
					rank
					max
					survey {
						name
					}
				}
			}
			category {
				id
				name
			}
		}
	}
`;

export const GET_ATTRIBUTE_VALUES = gql`
	query GetAttributeValues ($id: GUID! $limit: PositiveInt $cursor: String) {
		attributeValues(attributeId: $id limit: $limit cursor: $cursor) {
			items {
				id
				attribute {
					name
				}
				user {
					id
					firstName
					lastInitial
					creatorTag
					picture {
						id
						badge:url(version:BADGE)
					}
				}
				value
			}
		}
	}
`;

export const GET_ATTR_CATEGORIES = gql`
	query GetAttributeCategories($workspaceId: GUID!) {
		attributeCategories(workspaceId: $workspaceId) {
			items {
				id
				name
				isDefault
			}
		}
	}
`;

export const GET_ATTRIBUTE_CATEGORY = gql`
	query GetAttributeCategory($id: GUID!) {
		attributeCategory(id: $id) {
			id
			name
		}
	}
`;
