import classNames from "classnames";
import React, {ReactElement} from "react";
import previewStyles from "./custom-drag-layer.module.scss";
import styles from "../../../survey/components/option-card/option-card.module.scss";

interface PreviewProps {
	text: string;
	index: number;
	choiceType: string;
}

const OptionCardDragPreview = (props: PreviewProps): ReactElement =>
	<div className={[styles.container, previewStyles.optionCard].join(" ")}>
		<div className={classNames(styles.button, styles[props.choiceType.toLowerCase()])}>
			{props.choiceType === "MULTISELECT" && <div className={styles.fakeCheckbox}/>}
			{props.choiceType === "RANKED" &&
				<div className={styles.number}>{props.index + 1}</div>
			}
			<div className={[styles.input, previewStyles.optionText].join(" ")}>
				{props.text}
			</div>
		</div>
	</div>;

export {OptionCardDragPreview};
