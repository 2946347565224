import {
	CHOICE_FRAGMENT,
	QUESTION_FRAGMENT,
	SURVEY_FRAGMENT,
} from "../fragments/fragments";
import {gql} from "@apollo/client";

export const DELETE_SURVEY = gql`
	mutation DeleteSurvey($id: GUID!) {
		deleteSurvey(id: $id) {
			id
			workspaceId
			name
		}
	}
`;
/**
 * Reverts the introduction page on the survey to default values
 */
export const REVERT_SURVEY = gql`
	mutation RevertSurvey($id: GUID!) {
		revertSurvey(id: $id) {
			...SurveyFields
		}
	}
	${SURVEY_FRAGMENT}
`;
/**
 * Copies a survey based on its ID
 */
export const COPY_SURVEY = gql`
	mutation CopySurvey($id: GUID!) {
		duplicateSurvey(id: $id) {
			...SurveyFields
		}
	}
	${SURVEY_FRAGMENT}
`;

export const CREATE_SURVEY = gql`
	mutation CreateSurvey($input: NewSurvey!) {
		createSurvey(input: $input) {
			...SurveyFields
		}
	}
	${SURVEY_FRAGMENT}
`;

export const UPDATE_SURVEY = gql`
	mutation UpdateSurvey(
		$id: GUID!
		$changes: UpdateSurveyChanges
		$deletions: UpdateSurveyDeletions
	) {
		updateSurvey(id: $id changes: $changes deletions: $deletions) {
			...SurveyFields
			offlineAssist
			requiresPassword
			password
			introImage {
				id
				small:url(version:SMALL)
			}
			introVideo {
				id
				small:url(version:SMALL_STILL)
				video:url(version:VP8_VORBIS)
				mp4:url(version:H264_AAC)
			}
			incentiveAmount
			incentiveCurrency
			estimatedCompletionTime
		}
	}
	${SURVEY_FRAGMENT}
`;
/**
 * Creates a new question. NewQuestion requires a survey id, type, index, and text.
 */
export const CREATE_QUESTION = gql`
	mutation CreateQuestion($input: NewQuestion!) {
		createQuestion(input: $input) {
			...QuestionFields
		}
	}
	${QUESTION_FRAGMENT}
`;
/**
 * Delete a question by its ID.
 */
export const DELETE_QUESTION = gql`
	mutation DeleteQuestion($id: GUID!) {
		deleteQuestion(id: $id) {
			...QuestionFields
		}
	}
	${QUESTION_FRAGMENT}
`;

/**
 * Copies a question by its ID
 */
export const COPY_QUESTION = gql`
	mutation CopyQuestion($id: GUID!) {
		duplicateQuestion(id: $id) {
			...QuestionFields
		}
	}
	${QUESTION_FRAGMENT}
`;

/**
 * Update a question by its ID
 * Can change the type, text, imageId, or followUp
 * Delete takes imageId and followUp
 */
export const UPDATE_QUESTION = gql`
	mutation UpdateQuestion (
		$id: GUID!
		$changes: UpdateQuestionChanges
		$deletions: UpdateQuestionDeletions
	) {
		updateQuestion(id: $id changes: $changes deletions: $deletions) {
			...QuestionFields
		}
	}
	${QUESTION_FRAGMENT}
`;

export const UPDATE_AND_SET_QUESTION = gql`
	mutation UpdateAndChangeQuestion (
		$id: GUID!
		$changes: UpdateQuestionChanges
		$deletions: UpdateQuestionDeletions
		$type: QuestionType!
	) {
		setQuestionType(id: $id type: $type) {
			...QuestionFields
		}
		updateQuestion(id: $id changes: $changes deletions: $deletions) {
			...QuestionFields
		}
	}
	${QUESTION_FRAGMENT}
`;

/**
 * Sets the question type of a single question
 * Backend handles deleting anything needed to change question type
 */
export const SET_QUESTION_TYPE = gql`
	mutation SetQuestionType ($id: GUID! $type: QuestionType!) {
		setQuestionType(id: $id type: $type) {
			...QuestionFields
		}
	}
	${QUESTION_FRAGMENT}
`;

/**
 * Moves a question to the selected index
 * API will update indexes accordingly of all questions
 */
export const MOVE_QUESTION = gql`
	mutation MoveQuestion ($id: GUID! $index: NonNegativeInt!) {
		moveQuestion(id: $id index: $index) {
			...QuestionFields
		}
	}
	${QUESTION_FRAGMENT}
`;
/**
 * Creates a new choice on given question
 */
export const CREATE_CHOICE = gql`
	mutation CreateChoice ($input: NewChoice!) {
		createChoice(input: $input) {
			id
			index
			text
			answerCount
			frequency
		}
	}
`;

/**
 * Updates a given choice based on its id
 */
export const UPDATE_CHOICE = gql`
	mutation UpdateChoice ($id: GUID! $changes: UpdateChoiceChanges!) {
		updateChoice (id: $id changes: $changes) {
			id
			text
		}
	}
`;
/**
 * Moves a given choice to new location in the choice array
 */
export const MOVE_CHOICE = gql`
	mutation MoveChoice ($id: GUID! $index: NonNegativeInt!) {
		moveChoice (id: $id index: $index) {
			id
			index
		}
	}
`;

/**
 * Deletes a choice by its ID
 */
export const DELETE_CHOICE = gql`
	mutation DeleteChoice ($id: GUID!) {
		deleteChoice(id: $id) {
			...ChoiceFields
		}
	}
	${CHOICE_FRAGMENT}
`;

/**
* Creates a question image to associate on specific questions
*/
export const CREATE_QUESTION_IMAGE = gql`
	mutation CreateQuestionImage ($uploadItemId: String!) {
		createQuestionImage (uploadItemId: $uploadItemId) {
			id
			transcodingStatus
			medium: url(version:MEDIUM)
			small: url(version:SMALL)
		}
	}
`;
/**
 * Creates an intro image
 * @param uploadItemId The id for the image to upload
 */
export const CREATE_INTRO_IMAGE = gql`
	mutation CreateIntroImage ($uploadItemId: String!) {
		createIntroImage (uploadItemId: $uploadItemId) {
			id
			transcodingStatus
			small: url(version:SMALL)
		}
	}
`;
/**
 * Creates a Video to upload to a survey.
 */
export const CREATE_SURVEY_VIDEO = gql`
	mutation CreateSurveyVideo ($uploadItemId: String!) {
		createSurveyVideo (uploadItemId: $uploadItemId) {
			id
			transcodingStatus
			medium: url(version:MEDIUM)
		}
	}
`;

export const CREATE_QUESTION_ZIP = gql`
	mutation CreateQuestionZip ($questionId: GUID!) {
		createQuestionZip(questionId: $questionId) {
			id
			url
			uploadStatus
			originalFilename
		}
	}
`;

/**
 * Creates an AR model from file
 */
export const CREATE_QUESTION_AR_MODEL = gql`
	mutation CreateArModel ($glbUploadItemId: String!, $usdzUploadItemId: String!) {
		createQuestionArModel(
			glbUploadItemId: $glbUploadItemId,
			usdzUploadItemId: $usdzUploadItemId,
		) {
			id
			glbUrl:url(version:GLB)
			usdzUrl:url(version:USDZ)
		}
	}
`;
/**
 * Opens the survey up
 */
export const PUBLISH_SURVEY = gql`
	mutation PublishSurvey ($id: GUID!) {
		openSurvey(id: $id) {
			...SurveyFields
		}
	}
	${SURVEY_FRAGMENT}
`;
/**
 * Opens survey up and emails everyone that hasn't responded.
 * Which, by the way, should be everyone at this point
 */
export const PUBLISH_AND_EMAIL = gql`
	mutation PublishAndEmail ($id: GUID!) {
		openSurvey(id: $id) {
			...SurveyFields
		}
		sendSurveyToMembers(surveyId: $id)
	}
	${SURVEY_FRAGMENT}
`;
export const UNPUBLISH_SURVEY = gql`
	mutation UnpublishSurvey ($id: GUID!) {
		closeSurvey(id: $id) {
			...SurveyFields
		}
	}
	${SURVEY_FRAGMENT}
`;

export const SEND_SURVEY_TO_MEMBERS = gql`
	mutation SendSurveyToMembers (
		$surveyId: GUID!,
		$customMessage: String,
		$senderId: GUID,
		$jobTitle: String,
		$emailsToNotify: [String],
		$allowResend: Boolean,
		$useFilter: Boolean,
		$filter: SurveyMembersFilter,
		$useUserIds: Boolean,
		$userIds: [String],
		$useAnswersFilter: Boolean,
		$answersFilter: AnswersFilter,
		$skipPartials: Boolean,
	) {
		sendSurveyToMembers(
			surveyId: $surveyId,
			customMessage: $customMessage,
			senderId: $senderId,
			jobTitle: $jobTitle,
			emailsToNotify: $emailsToNotify,
			allowResend: $allowResend,
			useFilter: $useFilter,
			filter: $filter
			useUserIds: $useUserIds,
			userIds: $userIds,
			useAnswersFilter: $useAnswersFilter,
			answersFilter: $answersFilter,
			skipPartials: $skipPartials,
		)
	}
`;

export const CLEAR_SURVEY_SCHEDULE = gql`
	mutation ClearSurveySchedule ($id: GUID!) {
		updateSurvey(id: $id, deletions: {closeAt: true, closeAfterResponsesCount: true}) {
			...SurveyFields
		}
	}
	${SURVEY_FRAGMENT}
`;

export const CREATE_SURVEY_NOTIFICATION = gql`
	mutation CreateSurveyNotificaton ($surveyId: GUID!, $hoursBefore: Int!) {
		createSurveyNotificaton(surveyId: $surveyId, hoursBefore: $hoursBefore) {
			id
			surveyId
			hoursBefore
		}
	}
`;

export const REMOVE_SURVEY_NOTIFICATION = gql`
	mutation RemoveSurveyNotification ($id: GUID!) {
		removeSurveyNotification(id: $id) {
			id
			surveyId
			hoursBefore
		}
	}
`;

export const ADD_SURVEY_MEMBERS = gql`
	mutation AddSurveyMembers(
		$surveyId: GUID!
		$emails: [EmailAddress!]
		$anonymous: Boolean
		$useUserIds: Boolean
		$userIds: [String!]
		$useFilter: Boolean
		$filter: AnswersFilter
	) {
		addSurveyMembers(
			surveyId: $surveyId
			emails: $emails
			anonymous: $anonymous
			useUserIds: $useUserIds
			userIds: $userIds
			useFilter: $useFilter
			filter: $filter
		) {
			survey {
				id # May need for cache updates
			}
			users {
				id
				firstName
				lastInitial
				picture {
					id
					badge:url(version:BADGE)
				}
			}
		}
	}
`;

export const REMOVE_SURVEY_MEMBER = gql`
	mutation RemoveSurveyMembers ($surveyId: GUID! $userIds: [GUID!]!) {
		removeSurveyMembers(surveyId: $surveyId userIds: $userIds) {
			survey {
				id
			}
			users {
				id # Only really need id to remove a user.
			}
		}
	}
`;
/**
 * Promotion mutations
 */
export const PROMOTE_SURVEY = gql`
	mutation PromoteSurvey (
			$surveyId: GUID!
			$autoApprove: Boolean
			$filter: SurveyPromotionFilter!
			$sendEmails: Boolean
			$customMessage: String,
			$senderId: GUID,
			$jobTitle: String
			$allowResend: Boolean
		) {
		promoteSurvey (
			surveyId: $surveyId
			autoApprove: $autoApprove
			filter: $filter
			sendEmails: $sendEmails
			customMessage: $customMessage
			senderId: $senderId
			jobTitle: $jobTitle
			allowResend: $allowResend
		) {
			id
			status
			numberOfMatches
		}
	}
`;

export const APPROVE_SURVEY_PROMOTION = gql`
	mutation ApprovePromotion (
		$id: String!
		$sendEmails: Boolean
		$customMessage: String
		$senderId: GUID
		$jobTitle: String
		$allowResend: Boolean
	) { # Probably should be GUID! 
		approveSurveyPromotion(
			id: $id,
			sendEmails: $sendEmails,
			customMessage: $customMessage,
			senderId: $senderId,
			jobTitle: $jobTitle,
			allowResend: $allowResend,
		) {
			id
			status
			numberOfMatches
		}
	}
`;



/** ****************************************************
 * Mutations on a transcript. Might make a file for it?
 * *****************************************************/

export const HIGHLIGHT_TRANSCRIPT = gql`
	mutation HighlightTranscript (
	$transcriptId: GUID!
	$highlightCategoryId: GUID!
	$firstWord: NonNegativeInt!
	$lastWord: NonNegativeInt
) {
	highlightTranscript(
		transcriptId: $transcriptId
		highlightCategoryId: $highlightCategoryId
		firstWord: $firstWord
		lastWord: $lastWord
	) {
		id
		transcriptId
		firstWord
		lastWord
	}
}
`;

export const DELETE_HIGHLIGHT = gql`
	mutation DeleteHighlight ($id: GUID!) {
		deleteHighlight(id: $id) {
			id
			transcriptId
		}
	}
`;

export const CORRECT_TRANSCRIPT = gql`
	mutation CorrectTranscript(
		$transcriptId: GUID!
		$firstWord: NonNegativeInt!
		$lastWord: NonNegativeInt!
		$correction: String!
	) {
		correctTranscript(
			transcriptId: $transcriptId
			firstWord: $firstWord
			lastWord: $lastWord
			correction: $correction
		) {
			id
			firstWord
			lastWord
			correction
		}
	}
`;

export const UPDATE_SURVEY_INSIGHTS = gql`
	mutation UpdateSurveyInsights	(
		$id: GUID!
		$surveyInsightsId: GUID!
		$changes: UpdateSurveyInsightsChanges
	) {
		updateSurveyInsights(id: $id surveyInsightsId: $surveyInsightsId changes: $changes) {
			id
			summary
			objective
			insights
			headlines
			pros
			cons
			ideas
			edited
		}
	}
`;

export const DELETE_CORRECTION = gql`
	mutation DeleteCorrection($correctionId: GUID!) {
		deleteCorrection(correctionId: $correctionId) {
			id
			firstWord
			lastWord
			correction
		}
	}
`;

export const ADD_HIGHLIGHT_CATEGORY = gql`
	mutation AddHighlightCategory ($transcriptId: GUID! $name: String! $color: String!) {
		addHighlightCategory(transcriptId: $transcriptId name: $name color: $color) {
			id
			transcriptId
			name
			color
		}
	}
`;

export const COPY_ANSWER_FROM_SURVEY = gql`
	mutation CopyAnswer($answerId: GUID! $workspaceId: GUID!) {
		copyAnswerToWorkspace(answerId: $answerId workspaceId: $workspaceId) {
			answerId
			success
		}
	}
`;

/**
 * Likes an answer video
 */
export const LIKE_ANSWER = gql`
	mutation LikeAnswer ($answerId: GUID!) {
		likeAnswer (answerId: $answerId) {
			id
			likeCount
			likedAt
		}
	}
`;

/**
 * Unlikes an answer video
 */
export const UNLIKE_ANSWER = gql`
	mutation UnlikeAnswer ($answerId: GUID!) {
		unlikeAnswer (answerId: $answerId) {
			id
			likeCount
			likedAt
		}
	}
`;

/**
 * Sets an answer as reviewed
 */
export const REVIEW_ANSWER = gql`
	mutation ReviewAnswer ($id: GUID!) {
		reviewAnswer (id: $id) {
			id
			reviewed
			reviewerId
			reviewer {
				id
				name
			}
		}
	}
`;

/**
 * Sets an answer as not reviewed
 */
export const UNREVIEW_ANSWER = gql`
	mutation UnreviewAnswer ($id: GUID!) {
		unreviewAnswer(id: $id) {
			id
			reviewed
			reviewerId
			reviewer {
				id
				name
			}
		}
	}
`;

// Just returns a boolean
export const REGEN_INSIGHTS = gql`
	mutation RegenInsights ($id: GUID!) {
		regenerateInsights(id: $id)
	}
`;
