import React, {ReactElement} from "react";
import classnames from "classnames/bind";
import styles from "./table-grid.module.scss";
import {Argument} from "classnames";


const bStyles = classnames.bind(styles);

export interface TableGridProps {
	/**
	 * Children!
	 */
	children: React.ReactNode;
	/**
	 * Size of the table (8 columns vs 16)
	 */
	size: "medium" | "large";
	className?: Argument;
}

export interface GridCellProps {
	className?: Argument;
	/**
	 * Children!
	 */
	children?: React.ReactNode;
	/**
	 * How many columns the cell should take up
	 */
	cols?: "two" | "three" | "four" | "five" | "six" | "eight";
	onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export interface GridRowProps {
	/**
	 * Children!
	 */
	children: React.ReactNode;
	className?: Argument;
}
export interface GridHeaderProps {
	children: React.ReactNode;
	className?: Argument;
}

type RowComponent = React.FunctionComponent<GridRowProps>;
type HeaderComponent = React.FunctionComponent<GridHeaderProps>;
type CellComponent = React.FunctionComponent<GridCellProps>;
type TableComponent = React.FunctionComponent<TableGridProps> & {Cell: CellComponent}
& {Row: RowComponent} & {Header: HeaderComponent};
/**
 * Our main "Table" container for display: grid
 * @param children The children to display
 */
const TableGrid: TableComponent = (props: TableGridProps): ReactElement =>
	<div className={bStyles("grid", `${props.size}`, props.className)}>
		{props.children}
	</div>;

/**
 * A table cell. Right now each "cell" is displayed as a flex item.
 * This may change in the future if we decide to try some different ideas.
 * @param children Children for component to display
 * @param align How to align the text inside the cell
 * @param cols How many columns for the cell to take up
 * @param isHoverable Determines if a hover effect should happen. Used on headers mostly.
 * @returns
 */
const GridCell: CellComponent = (props: GridCellProps): ReactElement => {
	const {children, cols, className} = props;
	return (
		<div
			onClick={props.onClick}
			className={
				bStyles("cell", cols, {isHoverable: Boolean(props.onClick)}, className)
			}
		>
			{children}
		</div>
	);
};

const GridHeader: HeaderComponent = ({children, className}: GridHeaderProps): ReactElement =>
	<div className={bStyles("header", className)}>
		{children}
	</div>;

/**
 * A "row" component. Probably can make this more customizable.
 * @param children Children to display
 */
const GridRow: RowComponent = (props: GridRowProps): ReactElement =>
	<div className={bStyles("row", "item", props.className)}>
		{props.children}
	</div>;

TableGrid.Cell = GridCell;
TableGrid.Row = GridRow;
TableGrid.Header = GridHeader;

export {TableGrid};
