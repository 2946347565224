import React, {ReactElement, useContext, useState} from "react";
import styles from "./create-collection.module.scss";
import {gql, useMutation} from "@apollo/client";
import {CREATE_COLLECTION} from "../../graphql/mutations/collections-mutations";
import {CreateCollectionReturn} from "../../models/collections";
import {useWorkspaceContext} from "../../context/workspace-context";
import {ToastContext} from "../../context/toast-context";
import {BaseModalProps, Button, Input, Modal} from "../../shared/v2";

const CreateCollectionModal = (props: BaseModalProps): ReactElement => {
	const {isOpen, onClose} = props;
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const {updateToast} = useContext(ToastContext);

	const [name, setName] = useState("");
	const [isCreating, setIsCreating] = useState(false);

	const [createCollection] = useMutation<CreateCollectionReturn>(CREATE_COLLECTION);

	const handleCreate = (): void => {
		setIsCreating(true);
		createCollection({
			variables: {name, workspaceId},
			onCompleted: () => {
				setIsCreating(false);
				updateToast({description: "Collection created", type: "informational"});
				setName("");
				onClose();
			},
			update(cache, {data: createData}) {
				if (!createData) return;
				const newRef = cache.writeFragment({
					data: createData.createCollection,
					fragment: gql`
						fragment NewCollection on Collection {
							id
						}
					`,
				});
				cache.modify({
					fields: {
						collections(existing = []) {
							return [...existing, newRef];
						},
					},
				});
			},
		});
	};
	return (
		<Modal onClose={onClose} isOpen={isOpen}>
			<div className={styles.container}>
				<Input id="collection-name-input" value={name} onChange={setName} label="Collection Name"/>
				<Button disabled={!name || isCreating} onClick={handleCreate}>Save</Button>
			</div>
		</Modal>
	);
};

export {CreateCollectionModal};
