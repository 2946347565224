import React, {ReactElement} from "react";
import {UpdateQuestionChanges} from "../../../models/questions";
import {Select} from "../../../shared";
import styles from "./range-select.module.scss";

export interface RangeSelectProps {
	min: number;
	max: number;
	updateRange: (values: UpdateQuestionChanges) => void;
}

const maxRange = Array(8).fill(1).map((_x, i) => ({text: `${i + 3}`, value: i + 3}));
const RangeSelect = ({min, max, updateRange}: RangeSelectProps): ReactElement => {
	return (
		<div className={styles.container}>
			<Select
				id="min-value"
				selectedValue={min}
				onChange={value => updateRange({min: value})}
				options={[{text: "0", value: 0}, {text: "1", value: 1}]}
				className={styles.select}
			/>
			<span>to</span>
			<Select
				id="min-value"
				selectedValue={max}
				onChange={value => updateRange({max: value})}
				options={maxRange}
				className={styles.select}
			/>
		</div>
	);
};

export {RangeSelect};
