/**
 * This probably should be renamed.
 * Function checks to see if given value is within the filter, and will return
 * an updated choiceIndex for the object.
 * @param choiceIndex The choiceIndex portion of our answers page filter
 * @param value Value to check against in the choiceIndex array / value.
 *
 * @returns Updated number array / number, or null (if we delete the number)
 */
export const checkIfExists = <T>(
	choiceId: T[] | undefined | null,
	value: T,
): T[] | undefined => {
	if (choiceId?.includes(value)) {
		return choiceId.filter(choice => choice !== value);
	}
	const newChoices = [value];
	if (choiceId) {
		newChoices.push(...choiceId);
	}
	return newChoices;
};
