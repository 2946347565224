import React, {ReactElement} from "react";

import {Button, Body, Subheader, Modal, BaseModalProps} from "../../shared/v2";

import styles from "./delete-account.module.scss";

export interface DeleteAccountModalProps extends BaseModalProps{
	/**
	 * What happens when clicking "confirm"
	 */
	confirm: () => void;
}

const DeleteAccountModal = (props: DeleteAccountModalProps): ReactElement => {
	const {isOpen, confirm, onClose} = props;
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size="x-small"
			title="Confirm action"
		>
			<div className={styles.container}>
				<Subheader size="l" type="semibold" color="text-body">
          Are you sure you want to delete your account?
				</Subheader>
				<Body color="text-secondary">
          You will no longer be able to access any past surveys that you&apos;ve created
				</Body>
				<Button onClick={confirm} style="danger">Delete</Button>
			</div>
		</Modal>
	);
};

export {DeleteAccountModal};
