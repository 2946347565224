import classNames, {Argument} from "classnames";
import React, {ReactElement, useEffect, useRef} from "react";
import styles from "./editable-span.module.scss";

export interface EditableSpanProps {
	handleInput: (value: string) => void;
	value: string;
	className?: Argument;
	readOnly?: boolean;
}

const EditableSpan = (props: EditableSpanProps): ReactElement => {
	const {value, handleInput, className, readOnly = false} = props;
	const ref = useRef<HTMLSpanElement>(null);

	const handleBlur = (): void => {
		if (!ref.current) return;
		if (ref.current.innerText === "") {
			ref.current.innerText = value;
			return;
		}
		handleInput(ref.current.innerText);
	};

	const handleKeyDown = (e: React.KeyboardEvent): void => {
		if (!ref.current) return;
		if (e.key === "Enter") ref.current.blur();
	};
	useEffect(() => {
		if (!ref.current) return;
		if (ref.current.innerText === "UNTITLED CAMPAIGN") {
			ref.current.focus();
			const selection = window.getSelection();
			const range = document.createRange();
			range.selectNodeContents(ref.current);
			selection?.removeAllRanges();
			selection?.addRange(range);
		}
	}, [ref.current]);

	return (
		<span
			contentEditable={!readOnly}
			suppressContentEditableWarning={true}
			ref={ref}
			onKeyDown={handleKeyDown}
			onBlur={handleBlur}
			className={classNames(styles.span, className)}
		>
			{value}
		</span>
	);
};

export {EditableSpan};
