/* eslint-disable */
import React, {ReactElement, useMemo} from "react";
import {WordCount} from "../../../models/questions";
import styles from "./word-cloud.module.scss";
import {TagCloud} from "react-tagcloud";

interface WordCloudChartProps {
	data: WordCount[];
	copyButton?: JSX.Element
}

const WordCloudChart = React.forwardRef<HTMLDivElement, WordCloudChartProps>((props, ref): ReactElement => {
	const {data, copyButton} = props;
	const wordData = useMemo(
		() => data.map(value => ({value: value.word, count: value.count * 1000}))
			.sort((a, b) => b.count - a.count).slice(0, 20),
		[data],
	);
	const maxFontSize = 50;

	return (
		<div className={styles.container}>
			<div className={styles.wordcloud} ref={ref}>
				<TagCloud
					minSize={12}
					maxSize={maxFontSize}
					disableRandomColor
					className={styles.words}
					tags={wordData}
				/>
			</div>
			{copyButton}
		</div>
	);
});

WordCloudChart.displayName = "WordCloudChart";

export {WordCloudChart};
