import {useCallback, useState} from "react";

export const useModalActions = (init: boolean = false) => {
	const [isOpen, setIsOpen] = useState(init);

	const open = useCallback(() => setIsOpen(true), [setIsOpen]);
	const close = useCallback(() => setIsOpen(false), [setIsOpen]);

	return {
		value: isOpen,
		open,
		close,
	};
};
