import React, {ReactElement, useState} from "react";

import {useMemoObject} from "../hooks";
import {Setter} from "../types";

interface CreateModalContext {
	isShowingReelModal: boolean;
	setIsShowingReelModal: Setter<boolean>;
	singleAnswerId: string;
	setSingleAnswerId: Setter<string>;
	showAddList: boolean;
	setShowAddList: Setter<boolean>;
	singleUserId: string;
	setSingleUserId: Setter<string>;
	copyAnswerId: string;
	setCopyAnswerId: Setter<string>;
}

const CreateModalContext = React.createContext<CreateModalContext>({
	isShowingReelModal: false,
	setIsShowingReelModal: () => undefined,
	singleAnswerId: "",
	setSingleAnswerId: () => undefined,
	showAddList: false,
	setShowAddList: () => undefined,
	singleUserId: "",
	setSingleUserId: () => undefined,
	copyAnswerId: "",
	setCopyAnswerId: () => undefined,
});

export interface CreateModalContextProps extends Omit<
CreateModalContext,
"singleUserId" |
"setSingleUserId" |
"copyAnswerId" |
"setCopyAnswerId"
> {
	children: React.ReactNode;
}

const CreateModalContextProvider =
({children, ...props}: CreateModalContextProps): ReactElement => {
	const [singleUserId, setSingleUserId] = useState("");
	const [copyAnswerId, setCopyAnswerId] = useState("");
	 
	const value = useMemoObject({...props, singleUserId, setSingleUserId, copyAnswerId, setCopyAnswerId});
	return <CreateModalContext.Provider value={value}>{children}</CreateModalContext.Provider>;
};

export {CreateModalContextProvider, CreateModalContext};

