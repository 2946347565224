import React, {ReactElement, ReactNode} from "react";
import classNames from "classnames/bind";

import {Body, Subheader} from "../../../../shared/v2/typography";
import {CloseMediumIcon} from "../../../../icons";
import {useThemeMode} from "../../../../context/theme-mode-context";

import styles from "./modal.module.scss";
import {Portal} from "../../portal";
import {VIEWPORT_PORTAL_NAME} from "../../../../portals";

const bStyles = classNames.bind(styles);

export interface BaseModalProps {
	isOpen: boolean;
	onClose: (event?: React.MouseEvent) => void;
	onClick?: (event?: React.MouseEvent) => void;
}

export interface ModalProps extends BaseModalProps {
	title?: string;
	portal?: boolean;
	description?: string | ReactElement;
	children: ReactNode;
	className?: string;
	type?: "centered" | "sidebar";
	size?: "x-small" | "small" | "medium" | "large" | "mask";
	headerClassName?: string;
	descriptionClassName?: string;
	"aria-label"?: string;
}

export const Modal = ({
	title = "",
	portal = false,
	description,
	children,
	isOpen,
	onClose,
	className,
	onClick,
	type = "centered",
	size,
	headerClassName,
	descriptionClassName,
	...rest
}: ModalProps): ReactElement | null=> {
	const {isDarkMode} = useThemeMode();

	const content = (
		<div className={bStyles("wrapper", type)} {...rest}>
			<div className={bStyles("modal", className, type, size)} onClick={onClick}>
				<CloseMediumIcon onClick={onClose} className={bStyles("closeIcon")} />
				<div className={styles.header}>
					<Subheader
						size="l"
						type="semibold"
						color="text-body"
						className={headerClassName}
					>
						{title}
					</Subheader>
					{description && <Body
						color="text-secondary"
						className={descriptionClassName}
					>
						{description}
					</Body>}
				</div>
				{children}
			</div>

			<div className={bStyles("backdrop", {isDarkMode})} onClick={onClose}/>
		</div>
	);

	if (!isOpen) return null;

	return portal ? (
		<Portal name={VIEWPORT_PORTAL_NAME}>
			{content}
		</Portal>
	) : content;
};
