import {useMutation, useQuery} from "@apollo/client";
import classNames from "classnames";
import React, {ReactElement, useState, useReducer, useContext, useEffect} from "react";

import {
	APPROVE_SURVEY_PROMOTION,
	PROMOTE_SURVEY,
} from "../../../graphql/mutations/survey-mutations";
import {Select, TagInput} from "../../../shared";
import {
	FetchPromotionReturn,
	PromoteSurveyReturn,
	SurveyPromotionStatus,
} from "../../../models/survey";
import {GET_COUNTRIES} from "../../../graphql/queries/queries";
import {GET_SURVEY_PROMOTION} from "../../../graphql/queries/survey-queries";
import {SearchableFilter} from "../../../shared/components/searchable-filter";
import {SurveyContext} from "../../../context/survey-context";
import {ToastContext} from "../../../context/toast-context";
import {useTagInput} from "../../../hooks/useTagInput";
import {Emailer} from "../../../shared/components/emailer";
import {useEmailer} from "../../../hooks/useEmailer";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {UserContext} from "../../../context/user-context";
import {Body, Button, Checkbox, Spinner, Modal, BaseModalProps} from "../../../shared/v2";

import styles from "./promote.module.scss";

/**
 * Perhaps this up until the reducer could be moved to a separate file? It would
 * mostly just clean up this one a little
 */
const gender = [
	{id: "MALE", value: "MALE", text: "Male"},
	{id: "FEMALE", value: "FEMALE", text: "Female"},
	{id: "NONBINARY", value: "NONBINARY", text: "Nonbinary"},
	{id: "OTHER_GENDER", value: "OTHER", text: "Other"},
	{id: "TRANSGENDER_FEMALE", value: "TRANSGENDER_FEMALE", text: "Transgender Female"},
	{id: "TRANSGENDER_MALE", value: "TRANSGENDER_MALE", text: "Transgender Male"},
];

const ethnicity = [
	{id: "BLACK", value: "BLACK", text: "Black"},
	{id: "WHITE", value: "WHITE", text: "White"},
	{id: "ALASKAN_NATIVE", value: "ALASKAN_NATIVE", text: "Alaskan Native"},
	{id: "EAST_ASIAN", value: "EAST_ASIAN", text: "East Asian"},
	{id: "HISPANIC", value: "HISPANIC", text: "Hispanic"},
	{id: "NO_ANSWER", value: "NO_ANSWER", text: "Prefer not to answer"},
	{id: "OTHER_ETHNICITY", value: "OTHER", text: "Other"},
];

const userScores = [
	{id: "A_PLUS", value: "A_PLUS", text: "A+"},
	{id: "A", value: "A", text: "A"},
	{id: "A_MINUS", value: "A_MINUS", text: "A-"},
	{id: "B_PLUS", value: "B_PLUS", text: "B+"},
	{id: "B", value: "B", text: "B"},
	{id: "B_MINUS", value: "B_MINUS", text: "B-"},
	{id: "C_PLUS", value: "C_PLUS", text: "C+"},
	{id: "C", value: "C", text: "C"},
	{id: "C_MINUS", value: "C_MINUS", text: "C-"},
	{id: "D", value: "D", text: "D"},
	{id: "F", value: "F", text: "F"},
	{id: "X", value: "X", text: "Unrated"},
];

const lbtqValues = [
	{id: "STRAIGHT", value: "STRAIGHT", text: "Straight"},
	{id: "BISEXUAL_PANSEXUAL", value: "BISEXUAL_PANSEXUAL", text: "Bi or Pansexual"},
	{id: "LESBIAN", value: "LESBIAN", text: "Lesbian"},
	{id: "GAY", value: "GAY", text: "Gay"},
	{id: "QUEER", value: "QUEER", text: "Queer"},
	{id: "NONCONFORMING", value: "NONCONFORMING", text: "Nonconforming"},
	{id: "ASEXUAL_AROMANTIC_DEMISEXUAL", value: "ASEXUAL_AROMANTIC_DEMISEXUAL", text: "Asexual"},
	{id: "OTHER_LGBTQ", value: "OTHER", text: "Other"},
	{id: "PREFER_NOT_TO_ANSWER", value: "PREFER_NOT_TO_ANSWER", text: "Prefer not to answer"},
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state: {checkedIds: string[]}, action: {id: string}): any => {
	if (action.id === "RESET") {
		return {
			...state,
			checkedIds: [],
		};
	}
	if (state.checkedIds.includes(action.id)) {
		return {
			...state,
			checkedIds: state.checkedIds.filter(id => id !== action.id),
		};
	}
	return {
		...state,
		checkedIds: [
			...state.checkedIds,
			action.id,
		],
	};
};

const PromoteModal = ({isOpen, onClose}: BaseModalProps): ReactElement => {
	const {survey: {id}} = useContext(SurveyContext);
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const {user: {id: userId}} = useContext(UserContext);
	const {updateToast} = useContext(ToastContext);

	const [genders, dispatch] = useReducer(reducer, {checkedIds: []});
	const [ethnicityChecked, ethnicityDispatch] = useReducer(reducer, {checkedIds: []});
	const [userScoreChecked, userScoreDispatch] = useReducer(reducer, {checkedIds: []});
	const [lgbtqChecked, lgbtqDispatch] = useReducer(reducer, {checkedIds: []});
	const [childrenOrExpecting, setChildrenOrExpecting] = useState(false);
	const [childrenMinors, setChildrenMinors] = useState(false);
	const [hasNotReplied, setHasNotReplied] = useState(false);
	const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
	const [states, setStates] = useState<string[]>([]);
	const [all, setAll] = useState(false);
	const [disableButton, setDisableButton] = useState(false);
	const [sendEmails, setSendEmails] = useState(true);
	const emailerProps = useEmailer(workspaceId, userId);
	const {resetValues: resetEmail, ...emailer} = emailerProps;

	const [currentPromoteId, setCurrentPromoteId] = useState("");

	const {handleInput, handleRemove, inputRef} = useTagInput(states, setStates);

	const {data} = useQuery(GET_COUNTRIES);
	const {data: promoData, startPolling, stopPolling, loading: loadPromo} =
		useQuery<FetchPromotionReturn>(GET_SURVEY_PROMOTION, {
			skip: !currentPromoteId,
			variables: {id: currentPromoteId},
			notifyOnNetworkStatusChange: true,
		});

	const [promoteSurvey] = useMutation<PromoteSurveyReturn>(PROMOTE_SURVEY);
	const [approvePromote] = useMutation(APPROVE_SURVEY_PROMOTION);

	const handlePromote = (): void => {
		setDisableButton(true);
		promoteSurvey({
			variables: {
				surveyId: id,
				autoApprove: false,
				filter: {
					state: states,
					gender: genders.checkedIds,
					ethnicity: ethnicityChecked.checkedIds,
					country: selectedCountries,
					scoreLabel: userScoreChecked.checkedIds,
					lgbtqIdentifier: lgbtqChecked.checkedIds,
					childrenUnder18: childrenMinors || undefined,
					childrenOrPregnant: childrenOrExpecting || undefined,
					hasntRepliedInWorkspace: hasNotReplied,
					all,
				},
			},
			onCompleted: promotion => {
				if (!promotion) return;
				setCurrentPromoteId(promotion.promoteSurvey.id);
				setDisableButton(false);
			},
		});
	};

	useEffect(() => {
		if (promoData && currentPromoteId) {
			const {fetchSurveyPromotion: {status}} = promoData;
			if (status === SurveyPromotionStatus.FETCHED || status === SurveyPromotionStatus.FAILED) {
				stopPolling();
				return;
			}
		}
		if (currentPromoteId) {
			startPolling(2000);
		}
	}, [currentPromoteId, promoData, promoData?.fetchSurveyPromotion.status]);


	const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>): void => setChildrenOrExpecting(e.target.checked);
	const handleHasChildren = (e: React.ChangeEvent<HTMLInputElement>): void => setChildrenMinors(e.target.checked);
	const handleHasReplied = (e: React.ChangeEvent<HTMLInputElement>): void => setHasNotReplied(e.target.checked);
	const handleEmailCheckbox = (e: React.ChangeEvent<HTMLInputElement>): void => setSendEmails(e.target.checked);


	const handleCountriesFilter = (selected: {id: string, name: string}): void => {
		if (selectedCountries.includes(selected.name)) {
			setSelectedCountries(selectedCountries.filter(c => c !== selected.name));
		} else {
			setSelectedCountries([...selectedCountries, selected.name]);
		}
	};

	const resetValues = (): void => {
		dispatch({id: "RESET"});
		ethnicityDispatch({id: "RESET"});
		lgbtqDispatch({id: "RESET"});
		userScoreDispatch({id: "RESET"});
		setChildrenOrExpecting(false);
		setChildrenMinors(false);
		setHasNotReplied(false);
		setSelectedCountries([]);
		setStates([]);
		setCurrentPromoteId("");
		setAll(false);
		resetEmail();
		setDisableButton(false);
	};

	useEffect(() => {
		if (!isOpen) resetValues();
	}, [isOpen]);

	const handleTryAnother = (): void => {
		setCurrentPromoteId("");
	};

	const handleApprove = (): void => {
		const {message, from, jobTitle} = emailer;
		setDisableButton(true);
		approvePromote({
			variables: {
				id: currentPromoteId,
				sendEmails,
				customMessage: sendEmails ? message : undefined,
				senderId: sendEmails ? from : undefined,
				jobTitle: sendEmails ? jobTitle : undefined,
			},
			onCompleted: () => {
				updateToast({

					description: "Promotion approved. It may take some time for Creators to show up in the invite list",
					type: "informational",
				});
				onClose();
			},
			onError: () => {
				updateToast({
					description: "Promotion failed to approve. Try again in a few minutes",
					type: "failure",
				});
				setDisableButton(false);
			},
		});
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title="Launch your campaign to thousands of Creators ready to collaborate"
			size="large"
		>
			{/* It may be worth putting this into a function or a component so it's less confusing*/}
			{currentPromoteId ? <div className={styles.matchScreen}>
				{(promoData?.fetchSurveyPromotion.status === SurveyPromotionStatus.QUEUED || loadPromo) ? <>
					<Spinner /><p>Finding Creators matching your criteria</p>
				</> : promoData?.fetchSurveyPromotion.status === SurveyPromotionStatus.FAILED ? <>
					<p>No matches or the query failed, please try another.</p>
				</> : promoData?.fetchSurveyPromotion.status === SurveyPromotionStatus.FETCHED ? <>
					{promoData.fetchSurveyPromotion.numberOfMatches ? <>
						<p>We identified
							<b> {promoData?.fetchSurveyPromotion.numberOfMatches} </b>
							creators that match your criteria
						</p>
						<p>
							Click continue to invite Creators.
						</p>
					</> : <><p>
						There are no matches for this criteria, try another search.
					</p>
					</>}
				</> : <div>Something went wrong, please try again or a new search.</div>}
				<div className={styles.matchBottom}>
					{promoData?.fetchSurveyPromotion.status !== SurveyPromotionStatus.QUEUED &&
						promoData?.fetchSurveyPromotion.status !== SurveyPromotionStatus.PROCESSING &&
						<Button onClick={handleTryAnother}>Try another search </Button>
					}
					{promoData?.fetchSurveyPromotion.status === SurveyPromotionStatus.FETCHED &&
					promoData?.fetchSurveyPromotion.numberOfMatches && <>
						<Button
							onClick={handleApprove}
							disabled={(sendEmails && !emailer.from) || disableButton}
						>
							Continue
						</Button>
						<Checkbox
							checked={sendEmails}
							text="Email Creators"
							id="send-email-creators"
							onChange={handleEmailCheckbox}
						/>
					</>
					}
				</div>
				{promoData?.fetchSurveyPromotion.status === SurveyPromotionStatus.FETCHED &&
					promoData?.fetchSurveyPromotion.numberOfMatches && <div>
					{sendEmails && <Emailer {...emailer} />}
				</div>}
			</div> : <div className={styles.container}>
				<section className={styles.demographics}>
					<h3>Demographics</h3>
					<div className={styles.demoGrid}>
						<div>
							<span className={styles.label}>Gender</span>
							{gender.map(({text, ...g}) => (
								<Checkbox
									key={g.id}
									{...g}
									className={styles.checkbox}
									size="s"
									text={(
										<Body size="xs" color="text-tertiary">
											{text}
										</Body>
									)}
									checked={genders.checkedIds.includes(g.value)}
									onChange={() => dispatch({id: g.value})}
								/>
							))}
						</div>
						<div>
							<span className={styles.label}>Ethnicity</span>
							{ethnicity.map(({text, ...e}) => (
								<Checkbox
									key={e.id}
									{...e}
									text={(
										<Body size="xs" color="text-tertiary">
											{text}
										</Body>
									)}
									className={styles.checkbox}
									size="s"
									checked={ethnicityChecked.checkedIds.includes(e.value)}
									onChange={() => ethnicityDispatch({id: e.value})}
								/>
							))}
						</div>
						<div className={styles.family}>
							<span className={styles.label}>Family</span>
							<Checkbox
								id="childrenOrPregnant"
								value="childrenOrPregnant"
								checked={childrenOrExpecting}
								onChange={handleCheckbox}
								size="s"
								className={styles.checkbox}
								text={(
									<Body size="xs" color="text-tertiary">
										Has children or is expecting
									</Body>
								)}
							/>
							<Checkbox
								id="childrenUnder18"
								value="childrenUnder18"
								size="s"
								className={styles.checkbox}
								checked={childrenMinors}
								onChange={handleHasChildren}
								text={(
									<Body size="xs" color="text-tertiary">
										Has children under the age of 18
									</Body>
								)}
							/>
						</div>
						<span className={classNames(styles.label, styles.spacing)}>Sexual Orientation</span>
						<div className={styles.lgbtq}>
							{lbtqValues.map(({text, ...l}) => (
								<Checkbox
									key={l.id}
									{...l}
									text={(
										<Body size="xs" color="text-tertiary">
											{text}
										</Body>
									)}
									className={styles.checkbox}
									size="s"
									checked={lgbtqChecked.checkedIds.includes(l.value)}
									onChange={() => lgbtqDispatch({id: l.value})}
								/>
							))}
						</div>
					</div>
				</section>
				<section className={styles.location}>
					<h3>Location</h3>
					<span className={styles.label}>Country</span>
					<SearchableFilter
						options={data?.countries}
						placeholder="Filter by country"
						value={selectedCountries}
						selectedLabel={selectedCountries.length === 1 ? "1 country" :
							`${selectedCountries.length} countries`}
						onChange={handleCountriesFilter}
					/>
					<span className={styles.label}>States</span>
					<span className={styles.helper}>Hit enter after each state (full names)</span>
					<TagInput
						removeTag={handleRemove}
						tags={states}
						handleEnter={handleInput}
						ref={inputRef}
						type="states"
					/>
				</section>
				<section className={styles.user}>
					<h3>Vurvey internal</h3>
					<span className={styles.label}>User score</span>
					<div className={styles.scoreGrid}>
						{userScores.map(({text, ...u}) => (
							<Checkbox
								key={u.id}
								{...u}
								text={(
									<Body size="xs" color="text-tertiary">
										{text}
									</Body>
								)}
								className={styles.checkbox}
								size="s"
								checked={userScoreChecked.checkedIds.includes(u.id)}
								onChange={() => userScoreDispatch({id: u.id})}
							/>
						))}
					</div>
					<span className={styles.label}>Other</span>
					<Checkbox
						id="hasReplied"
						value="hasReplied"
						checked={hasNotReplied}
						onChange={handleHasReplied}
						text={(
							<Body size="xs" color="text-tertiary">
								Has not replied to any of your campaigns
							</Body>
						)}
						className={styles.checkbox}
						size="s"
					/>
					<div className={styles.allSelect}>
						<span>Match</span>
						<Select
							id="select-all-or-any"
							onChange={setAll}
							options={[
								{text: "Any", value: false},
								{text: "All", value: true},
							]}
							selectedValue={all}
							className={styles.select}
						/>
						<span>of the selected filters</span>
					</div>
				</section>
				<div className={styles.bottom}>
					<Button
						onClick={handlePromote}
						disabled={disableButton}
					>
						Find matches
					</Button>
				</div>
			</div>
			}
		</Modal>
	);
};

export {PromoteModal};
