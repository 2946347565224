import React, {ReactElement} from "react";

import styles from "./objective-card.module.scss";
import {Body} from "../../../shared/v2";

export interface ObjectiveCardProps {
  objective?: string;
}

export const ObjectiveCard = ({objective}: ObjectiveCardProps): ReactElement => {
	return <div className={styles.container}>
		<Body className={styles.objective}>{objective}</Body>
	</div>

}
