/* eslint-disable react/prop-types */
import classNames from "classnames/bind";
import React, {ReactElement, useCallback} from "react";
import {matchSorter} from "match-sorter";
import {
	Column,
	HeaderGroup,
	IdType,
	Row,
	useExpanded,
	useFlexLayout,
	useGlobalFilter,
	useGroupBy,
	useMountedLayoutEffect,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from "react-table";

import {Button, Checkbox, Spinner} from "../../../shared/v2";
import {Setter} from "../../../types";
import {TablePagination} from "./table-pagination";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./table.module.scss";

const bStyles = classNames.bind(styles);

export interface PaginatedTableProps<T extends object> {
	columns: Column<T>[];
	data: T[];
	editableRowIndex?: number;
	selectedValues?: Row<T>[];
	onSelectChange?: (rows: Row<T>[]) => void;
	handleFetchMore: (limit: number) => void;
	onSort?: (column: HeaderGroup<T>) => void;
	filters?: string[];
	pageSize: number;
	pageState: [number, Setter<number>];
	selectAllState?: [boolean, Setter<boolean>];
	totalCount: number;
	sortLoading?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fetchMoreFragment?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | null;
	groupBy?: string;
	sortBy?: string;
	manualSortBy?: boolean;
	/**
	 * Total size of our data object.
	 */
	dataLength: number;
}

/**
 * This is our PaginatedTable. In the (perhaps near) future, most tables on the CRM will
 * likely use this table. For now, we cannot do all the same filters that the Creators can
 * (filter by name, country, etc.), so we are just making it simpler to use it just for
 * the Creators for now.
 *
 * Consider this a stopgap until we're able to have all the filters and search values
 * in the API.
 */
const PaginatedTable = <T extends object>({
	columns,
	data,
	dataLength,
	editableRowIndex,
	onSelectChange,
	handleFetchMore,
	onSort,
	selectedValues,
	filters,
	pageState,
	pageSize,
	totalCount,
	selectAllState,
	groupBy,
	sortLoading,
	fetchMoreFragment,
}: PaginatedTableProps<T>): ReactElement => {
	const [currentPage, setCurrentPage] = pageState;
	const [selectAll, setSelectAll] = selectAllState || [];
	/**
	 * Custom filter function
	 */
	const globalFilterFunction =
	useCallback((rows: Row<T>[], ids: IdType<T>[], query: string): Row<T>[] => {
		if (!filters) return rows;
		return matchSorter(rows, query, {keys: filters?.map(name => `values.${name}`)});
	}, [filters]);

	const {isDarkMode} = useThemeMode();



	const handleUncheckBox = useCallback((e: React.ChangeEvent<HTMLInputElement>, f?: ((e: React.ChangeEvent<HTMLInputElement>) => void)): void => {
		setSelectAll?.(false);
		f?.(e);
	}, []);


	const {
		getTableProps,
		headerGroups,
		rows,
		prepareRow,
		selectedFlatRows,
		toggleAllRowsSelected,
		pageCount,
	} = useTable(
		{
			columns,
			manualSortBy: Boolean(onSort),
			data,
			globalFilter: filters ? globalFilterFunction : undefined,
			initialState: {
				pageSize,
				groupBy: groupBy ? [groupBy] : [""],
			},
			manualPagination: true,
			pageCount: Math.ceil(totalCount / pageSize),
			// orderByFn(row, sortFns, directions) {
			// 	console.log("uh we hit it", row);
			// 	// return row;
			// 	return {...row};
			// },
		},
		useFlexLayout,
		useGlobalFilter,
		useGroupBy,
		useSortBy,
		useExpanded,
		usePagination,
		useRowSelect,
		hooks => {
			if (onSelectChange) {
				hooks.visibleColumns.push(allColumns => [
					{
						id: "selection",
						width: 25,
						Header: ({getToggleAllRowsSelectedProps}) => {
							// eslint-disable-next-line @typescript-eslint/no-unused-vars
							const {indeterminate, ...props} = getToggleAllRowsSelectedProps();
							const {onChange, checked, ...rest} = props;
							return (
								<Checkbox
									size="s"
									checked={Boolean(checked)}
									{...rest}
									className={styles.checkbox}
									onChange={e => handleUncheckBox(e, onChange)}
								/>
							);
						},
						Cell: ({row}) => {
							// eslint-disable-next-line @typescript-eslint/no-unused-vars
							const {indeterminate, ...props} = row.getToggleRowSelectedProps();
							const {onChange, checked, ...rest} = props;
							return (
								<Checkbox
									size="s"
									checked={Boolean(checked)}
									{...rest}
									className={styles.checkbox}
									onChange={e => handleUncheckBox(e, onChange)}
								/>
							);
						},
					},
					...allColumns,
				]);
			}
		},
	);

	const startingRow = currentPage === 0 ? currentPage + 1 : (currentPage * pageSize) + 1;
	const endRow = ((currentPage + 1) * pageSize) > totalCount
		? totalCount : (currentPage + 1) * pageSize;
	useMountedLayoutEffect(() => {
		if (selectedValues?.length !== selectedFlatRows.length) onSelectChange?.(selectedFlatRows);
	}, [selectedFlatRows]);

	/**
	 * Not 100% efficient, but we want the checkboxes to reset whenever we do a big selection
	 * action. Whenever our passed in values is empty and the internal state of React Table is not,
	 * we need to reset selected rows.
	 */
	useMountedLayoutEffect(() => {
		if (selectedValues?.length === 0 && selectedFlatRows.length !== 0) toggleAllRowsSelected(false);
	}, [selectedValues]);

	const handleNext = (): void => {
		setSelectAll?.(false);
		if ((currentPage + 2) * pageSize > dataLength) handleFetchMore(pageSize);
		setCurrentPage(prev => prev + 1);
	};
	// Can't think of a time we'd need to fetch more when clicking the back arrow
	const handlePrev = (): void => {
		setSelectAll?.(false);
		setCurrentPage(prev => prev - 1);
	};
	// If the new page is higher than the current, we'll need to fetch some more values
	const gotoPage = (newPage: number): void => {
		setSelectAll?.(false);
		if (currentPage < newPage && (newPage + 1) * pageSize > dataLength) {
			handleFetchMore((newPage - currentPage) * pageSize);
		}
		setCurrentPage(newPage);
	};

	const handleDeselectAll = (): void => {
		toggleAllRowsSelected();
		setSelectAll?.(false);
	};

	const handleSort = (c: HeaderGroup<T>): void => {
		onSort?.(c);
		c.toggleSortBy();
		setCurrentPage(0);
	};

	return (
		<div className={styles.mainContainer}>
			<div className={styles.tableContainer}>
				<table {...getTableProps()} className={bStyles("table", {isDarkMode})}>
					<thead className={styles.header}>
						{headerGroups.map((headerGroup, i) => (
							<tr {...headerGroup.getHeaderGroupProps()} key={i}>
								{headerGroup.headers.map((column, j) => (
									<th
										{...column.getHeaderProps(column.getSortByToggleProps())}
										onClick={column.canSort ? () => handleSort(column) : undefined}
										key={j}
										className={column.canSort ? styles.sortable : undefined}
									>
										{column.render("Header")}
										{column.isSorted ?
											<span className={bStyles(column.isSortedDesc ? styles.down : styles.up)}/>
											: null}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody>
						{
							onSelectChange && setSelectAll && selectedValues?.length === rows.length &&
							!fetchMoreFragment && (rows.length !== totalCount) &&
							<tr className={styles.selectionRow}>
								<td className={styles.selectAll}>
									<div>
										{selectAll ? <>
											All {totalCount} rows selected.
											<Button
												onClick={handleDeselectAll}
												variant="outlined"
												className={styles.smallButton}
											>
												Clear selection
											</Button>
										</> : <>
										Selected all {selectedValues?.length} rows on this page.
											<Button
												size="small"
												onClick={() => setSelectAll(true)}
												className={styles.smallButton}
											>
												{`Select all ${totalCount}`}
											</Button>
										</>
										}
									</div>
								</td>
							</tr>
						}
						{sortLoading ? <tr className={styles.sorting}>
							<td><Spinner /></td>
						</tr> :
							rows.map((row, i) => {
								prepareRow(row);
								return (
									<tr {...row.getRowProps()} key={i}>
										{row.cells.map((cell, j) => (
											<td {...cell.getCellProps()} key={j}>
												{cell.isGrouped ? (<>
													<span {...row.getToggleRowExpandedProps()}>
														{row.isExpanded ? "V" : ">"}
													</span>
													{cell.render("Cell")} ({row.subRows.length})
												</>
												) : cell.isAggregated ? (
													<span>
														{cell.render("Aggregated")}
													</span>
												) : cell.isPlaceholder ? null : (
													cell.render("Cell", {editableRowIndex})
												)}
											</td>
										))}
									</tr>
								);
							})}
						{fetchMoreFragment}
					</tbody>
				</table>
			</div>
			<div className={styles.footer}>
				<span className={styles.rowCount}>{startingRow} - {endRow} of {totalCount}</span>
				<TablePagination
					currentPage={currentPage}
					pageCount={pageCount}
					handleNext={handleNext}
					handlePrev={handlePrev}
					gotoPage={gotoPage}
				/>
			</div>
		</div>
	);
};

export {PaginatedTable};
