import {Brand} from "./brand";
import {GenericPage, IdName} from "./generic";
import {UserRole} from "./user";

export interface Workspace {
	id: string;
	name: string;
	plan?: WorkspacePlan;
	logoId?: string;
	logo?: WorkspaceLogo;
	myRole?: WorkspaceRole;
	responseCount?: number;
	responderCount?: number;
	totalVideoDuration?: number;
	totalAnswerDuration?: number;
	hasSubscription?: boolean;
	permissions?: string[];
	renewalDate?: Date;
	createdAt?: Date;
	lastActive?: Date;
	isTrialActive?: boolean;
	trialEnd?: Date;
	brand?: Brand;
	allowAnonymous: boolean;
	enableOfflineAssist: boolean;
	aiInsightsEnabled?: boolean;
	chatbotEnabled?: boolean;
    forceLogout?: boolean;
    forceLogoutPeriodMin?: number;
}

export enum WorkspaceMembersSort {
	DEFAULT = "DEFAULT",
	NAME_AZ = "NAME_AZ",
	NAME_ZA = "NAME_ZA",
	EMAIL_ASC = "EMAIL_ASC",
	EMAIL_DESC = "EMAIL_DESC",
}

export interface WorkspaceLogo {
	id: string;
	badge: string;
	thumbnail?: string;
	originalMimeType?: string;
	originalFilename?: string;
}
export enum WorkspacePlan {
	PENDING = "PENDING",
	TRIAL = "TRIAL",
	BASIC = "BASIC",
	PRO = "PRO",
	ENTERPRISE = "ENTERPRISE",
	ARCHIVED = "ARCHIVED"
}
export enum WorkspaceRole {
	OWNER = "OWNER",
	ADMINISTRATOR = "ADMINISTRATOR",
	MANAGER = "MANAGER",
	CREATOR = "CREATOR",
}

export interface WorkspaceCountData {
	workspace: {
		id: string;
		contactCount: number;
	}
}

export interface MyWorkspaceData {
	items: IdName[];
}

export interface MyWorkspacePageData {
	myWorkspaces: MyWorkspaceData;
}

export interface Historical {
	older: number;
	lastMonth: number;
	thisMonth: number[];
}

export interface WorkspacesPage {
	items: Workspace[];
	cursor: string;
	remaining: number;
}

export interface WorkspacesPageData {
	allWorkspaces: WorkspacesPage;
}

export interface Historical {
	older: number;
	lastMonth: number;
	thisMonth: number[];
}

export interface WorkspaceHistory {
	contactCount: Historical;
	responseCount: Historical;
	requestCount: Historical;
	answerDuration: Historical;
	answerCount: Historical;
}

export interface WorkspaceHistoryReturn {
	workspaceHistory: Pick<WorkspaceHistory, "answerDuration" | "contactCount" | "answerCount">;
}

export interface WorkspaceFilter {
	/**
	 * If provided, will filter to records for Workspaces with a name that begins with this string,
	 * ignoring case.
	 */
	name?: string;
	/**
	 * If provided, will filter to records for Workspaces with the aiInsightsEnabled value provided.
	 */
	aiInsightsEnabled?: boolean;
	/**
	 * If provided, will filter to records for Workspaces with the allowAnonymous value provided.
	 */
	allowAnonymous?: boolean;
	/**
	 * If provided, will filter to records for Workspaces with the enableOfflineAssist value provided.
	 */
	enableOfflineAssist?: boolean;
	/**
	 * If provided, will filter to records for Workspaces with one of the plans provided.
	 */
	plan?: WorkspacePlan[];

	chatbotEnabled?: boolean;
}

export interface WorkspacesPageVars {
	filter?: WorkspaceFilter;
	limit?: number;
}

export interface WorkspaceChanges {
	/**
	 * If provided, will set aiInsightsEnabled to the value provided.
	 */
	aiInsightsEnabled?: boolean;
	/**
	 * If provided, will set allowAnonymous to the value provided.
	 */
	allowAnonymous?: boolean;
	/**
	 * If provided, will set enableOfflineAssist to the value provided.
	 */
	enableOfflineAssist?: boolean;
	/**
	 * If provided, will set the workspace plan to the value provided.
	 */
	plan?: WorkspacePlan;

	/**
	 * If provided, will set chatbot to show in workspace.
	 */
	chatbotEnabled?: boolean;
}


export interface WorkspacesData {
	manageWorkspaces: number;
}

export interface WorkspaceMember {
	id: string;
	email: string;
	name: string;
	lastInitial: string;
	workspaceRole: WorkspaceRole;
	role: UserRole;
}


export interface WorkspaceMemberPageData {
	workspaceMembers: GenericPage<WorkspaceMember>;
} 
