import classNames from "classnames/bind";
import React, {cloneElement, ReactElement} from "react";

import {Body} from "../../../typography";
import {ButtonProps} from "..";
import {useThemeMode} from "../../../../../context/theme-mode-context";

import styles from "../button.module.scss";

const cx = classNames.bind(styles);

export type DummyButtonProps = Pick<
  ButtonProps,
  "children" | "variant" | "style" | "size" |
  "leftIcon" | "rightIcon" | "className" | "ariaLabel"
>

export const DummyButton = ({
	children,
	variant = "filled",
	style = "brand",
	size = "default",
	leftIcon,
	rightIcon,
	className,
	ariaLabel,
}: DummyButtonProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	const renderButtonContent = () :ReactElement => {
		if (size === "small") {
			return <Body size="s" type="medium" className={styles.content}>{children}</Body>
		}

		return <Body type="medium" className={styles.content}>{children}</Body>
	}

	return (
		<div
			aria-label={ariaLabel}
			className={cx("button", variant, style, size, className, {hasLeftIcon: leftIcon, hasRightIcon: rightIcon, isDarkMode})}
		>
			{leftIcon && cloneElement(leftIcon, {className: styles.icon})}
			{renderButtonContent()}
			{rightIcon && cloneElement(rightIcon, {className: styles.icon})}
		</div>
	)
}
