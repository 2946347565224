import React, {ReactElement, useMemo, useState} from "react";
import {useMediaQuery} from "react-responsive";

import {Subheader} from "../../../shared/v2/typography";
import {ModelCard} from "../model-card";
import {CategoryWithAIModels} from "models/ai-model";
import {Link} from "../../../hoc/layout/navigation/link";

import styles from "./model-group.module.scss";

export interface ModelGroupProps {
	group: CategoryWithAIModels;
}

export const ModelGroup = ({group}: ModelGroupProps): ReactElement => {
	const [isExpanded, setIsExpanded] = useState<boolean>(false);

	const isFullHd = useMediaQuery({query: "(min-width: 1920px)"});
	const sliceValue = isFullHd ? 4 : 3;

	const models = useMemo(() => {
		if (isExpanded) {
			return group.aiModels;
		}
		return group.aiModels.slice(0, sliceValue);
	}, [isExpanded, group.aiModels, sliceValue]);

	return <div className={styles.wrapper}>
		<div className={styles.headerWrapper}>
			<Subheader
				size="xl"
				type="medium"
			>
				{group.description}
			</Subheader>

			{group.aiModels.length > sliceValue &&
				<Link
					type="expand"
					isExpanded={isExpanded}
					text={isExpanded ? "Show less" : "Show more"}
					onClick={() => setIsExpanded(!isExpanded)}
					size="l"
				/>
			}
		</div>

		<div className={styles.models}>
			{models.map(model => <ModelCard key={model.id} model={model} />)}
		</div>
	</div>;
};
