import React, {ReactElement} from "react";
import {Heading} from "../../typography/heading";
import styles from "./disconnected.module.scss";

const DisconnectedPage = (): ReactElement => {
	return (
		<div className={styles.page}>
			<div className={styles.container}>
				<img style={{marginBottom: 42}} src="images/robo-cat.png" />
				<Heading size="lg">
					Sorry... we&apos;re running into some problems right now
				</Heading>
				<p className={styles.text}>
					Vurvey will be back online soon. Thank you for your patience.
				</p>
			</div>
		</div>
	);
};

export {DisconnectedPage};
