import React, {ReactElement} from "react";

import {
	useChatCampaignContext,
	useChatConversationContext,
	useChatEventsContext,
	useChatMessagesContext,
	useChatPersonaContext,
	useChatTrainingSetContext,
} from "../../../context/chat-contexts";
import {ChatPollingHandlers} from "./polling-handlers";
import {ChatEventHandlers} from "./event-handlers";
import {useUponReconnecting} from "../../../hooks/useUponReconnecting";

export const ChatHandlers = (): ReactElement => {
	const {isBroken: isWsDisabled} = useChatEventsContext();
	const {refetch: refetchConversation} = useChatConversationContext();
	const {refetch: refetchCampaigns} = useChatCampaignContext();
	const {refetch: refetchPersonas} = useChatPersonaContext();
	const {refetch: refetchTrainingSets} = useChatTrainingSetContext();
	const {setMessages} = useChatMessagesContext();

	useUponReconnecting(async () => {
		refetchCampaigns();
		refetchPersonas();
		refetchTrainingSets();
		const {messages} = await refetchConversation();
		setMessages(messages || []);
	})

	return isWsDisabled ? (
		<ChatPollingHandlers />
	) : (
		<ChatEventHandlers />
	)
}
