import React from "react";
import {useNavigate} from "react-router";

import {ButtonIcon, Subheader, Button} from "../../../shared/v2";
import {ChevronLeftIcon, LightingBoltStrikeIcon, PenEditIcon, PlusSmallIcon} from "../../../icons";
import {useWorkflowContext} from "../../../context/workflow-contexts";
import {ButtonGroup, ButtonGroupElement} from "../../../shared/components/navigation";

import styles from "./top-bar.module.scss";

export interface TopBarProps {
  setIsEditWorkflowModalOpen: (isOpen: boolean) => void;
}

export const TopBar = ({setIsEditWorkflowModalOpen}: TopBarProps) => {
	const navigate = useNavigate();
	const {workflow, isWorkflowDirty, handleRunWorkflow, handleSaveWorkflow, isSaving, isWorkflowRunning} = useWorkflowContext();

	const goBack = () => {
		navigate("/workflow/flows");
	};

	return (
		<div className={styles.topBar}>
			<div className={styles.leftSide}>
				<ButtonIcon onClick={goBack} filledIcon icon={<ChevronLeftIcon />} />

				<ButtonGroup>
					<ButtonGroupElement text="Build" onClick={() => {}} />
					<ButtonGroupElement text="History" onClick={() => {}} />
				</ButtonGroup>
			</div>

			<div className={styles.centerSide}>
				{workflow && (
					<>
						<Subheader type="medium">{workflow?.name || ""}</Subheader>
						<ButtonIcon disabled={isWorkflowRunning}  onClick={() => setIsEditWorkflowModalOpen(true)} icon={<PenEditIcon />} />
					</>
				)}
			</div>

			<div className={styles.rightSide}>
				{workflow && (
					<>
						{isWorkflowDirty ?
							<Button
								className={styles.actionButton}
								disabled={isSaving}
								onClick={handleSaveWorkflow}
								size="small"
								leftIcon={<PlusSmallIcon />}
							>
              Save
							</Button>
							:
							<Button
								className={styles.actionButton}
								onClick={handleRunWorkflow}
								disabled={isWorkflowRunning}
								size="small"
								style="ai"
								leftIcon={<LightingBoltStrikeIcon />}
							>
              Run Workflow
							</Button>
						}
					</>
				)}
			</div>
		</div>
	);
};
