import {gql} from "@apollo/client";

export const GET_ORCH_OUTPUT_TYPES = gql`
	query GetAllOrchestrationOutputTypes {
		aiOrchestrationOutputTypes {
      id
			code
			name
			vurveyOnly
			createdAt
			updatedAt
		}
	}
`;

export const GET_AI_ORCHESTRATIONS = gql`
 # the ID should be a GUID, not a String
  query GetAllAiOrchestrations($workspaceId: String!) {
    aiOrchestrations(workspaceId: $workspaceId) {
      id
      workspaceId
      name
      description
      instructions
      aiPersonaTasks {
        persona {
          id
          name
          picture {
            id
            url(version: THUMBNAIL)
          }
        }
      }
    }
  }
`;

// Get a single one, and all info (except workspace)
export const GET_AI_ORCHESTRATION = gql`
 # Again, better if this were a GUID not a String
  query GetAiOrchestration($id: String!) {
    aiOrchestration(id: $id) {
      id
      workspaceId
      name
      description
      inputParameters
      instructions
      outputTypeId
      outputType {
        id
        code
        name
        vurveyOnly
        createdAt
        updatedAt
      }
      surveys {
        id
        name
      }
      trainingSets {
        id
        alias
      }
      aiPersonaTasks {
        id
        name
        instructions
        index
        persona {
          id
          name
          picture {
            id
            url(version: THUMBNAIL)
          }
        }
        task {
            id
            taskPrompt
            output
        }
      }
      history {
		id
		aiOrchestrationId
		startedAt
		completedAt
		status
		output
        outputHistoryTasks {
            taskId
            personaId
            personaName
            input
            output
            processState
        }
      }
    }
  }
`;
