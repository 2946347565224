import {useEffect, useState} from "react";

export interface WindowSize {
	width: number;
	height: number;
}

/**
 * Hook that returns the window size in an object of type "WindowSize"
 *
 * Hopefully useful with resizing certain elements or determining where to
 * put p
 */
export const useWindowSize = (): WindowSize => {
	const [windowSize, setWindowSize] = useState<WindowSize>({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	useEffect(() => {
		const handleResize = (): void => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);
	return windowSize;
};
