/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {ReactElement, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useTable, useResizeColumns, useFlexLayout, Column, useSortBy, HeaderGroup} from "react-table";
import {useOutletContext} from "react-router";
import {useSticky} from "react-table-sticky";
import {useMutation} from "@apollo/client";
import classNames from "classnames";

import {GET_ALL_RESPONSES} from "../../../graphql/queries/survey-queries";
import {QuestionType, Question} from "../../../models/questions";
import {convertToClockTime, formatLong as format, ImageModal} from "../../../shared";
import {HighlightContextProvider} from "../../../context/highlight-context";
import {SurveyContext} from "../../../context/survey-context";
import {Transcript} from "../../../modal-components/transcript";
import {TranscriptModal} from "../../../modals/transcript-modal";
import {useLoadingQuery} from "../../../hooks";
import {useFilter} from "../../../route";
import {SegmentButton} from "../../../contacts/components/segment-button";
import {ADD_WORKSPACE_SEGMENT_MEMBERS} from "../../../graphql/mutations/mutations";
import {User} from "../../../models";

import styles from "./results-table.module.scss";
import {ResponsePageData, Response} from "../../../models/response";
import {TablePagination} from "../../../shared/components/table/table-pagination";
import {useCallback} from "react";
import {ToggleSwitch} from "../../../shared/components/toggle-switch";
import {ResponsesSort} from "../../../models/rewards";
import {useToastContext} from "../../../context/toast-context";

const TABLE_PAGE_SIZE = 25;

const answerFinder = (questionId) => (row) => row.answers.find((a) => a.questionId === questionId);
const choiceCell = ({value}): string =>
	value?.skipped ? "Skipped" : value?.choices?.map((c) => c.text)?.join(", ") || "";
const numberCell = ({value}): string => (value?.skipped ? "Skipped" : value?.numberAnswer?.toString() || "");
const textCell = ({value}): string =>
	value?.skipped ? "Skipped" : value?.numberAnswer?.toString() || value?.text || "";
const starCell = ({value}): string =>
	value?.skipped ? "Skipped" : "\u2605".repeat(value?.numberAnswer?.toString() || 1);

const makeHeader = (q: Question, index: number): (() => ReactElement) => {
	const ret = (): ReactElement => (
		<>
			<div className={styles.question}>Question {index + 1}:</div>
			{q.text}
		</>
	);
	ret.displayName = "Header";
	return ret;
};

const makeFollowupHeader = (q: Question, index: number): (() => ReactElement) => {
	const isNone = q.type === QuestionType.NONE;
	const ret = (): ReactElement => (
		<>
			<div className={styles.question}>{isNone ? `Video Question ${index + 1}:` : "Video Follow Up:"}</div>
			{ }
			{isNone ? q.text : q.followUp!}
		</>
	);
	ret.displayName = "FollowupHeader";
	return ret;
};

const ResultsTable = (): ReactElement => {
	// const {answerId} = useParams<SurveyParams>();
	const [answerId, setAnswerId] = useState("");
	const [showTranslation, setShowTranslation] = useState(false);
	const [sortBy, setSortBy] = useState(ResponsesSort.DEFAULT);
	const tableContainerRef = useRef<HTMLDivElement>(null);
	const {
		survey: {id: surveyId, workspaceId, language},
		questions: cQuestions,
	} = useContext(SurveyContext);
	const [sendToSegment] = useMutation(ADD_WORKSPACE_SEGMENT_MEMBERS);
	const {membersFilter} = useFilter();
	const {updateToast} = useToastContext();

	// This page and everything it loads puts a heavy load on the cache. (if there are transcripts)
	// We may need to find a better way to display / fetch as it slows everything down.
	const {data, fragment, fetchMoreFragment, handleFetchMore} = useLoadingQuery<ResponsePageData>(GET_ALL_RESPONSES, {
		variables: {surveyId, limit: TABLE_PAGE_SIZE, filter: membersFilter, sort: sortBy},
		fetchPolicy: "cache-and-network",
		nextFetchPolicy: "cache-and-network", // In order to do pagination, we need the cache.
		notifyOnNetworkStatusChange: true,
		fetchMoreClassName: styles.maxWidth,
		loadingClassName: styles.maxWidth,
		what: "responses",
	});
	const [currentPage, setCurrentPage] = useState(0);
	const [imgModal, setImgModal] = useState("");

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const {setHandleScroll} = useOutletContext<any>();
	// We don't want to use the page scroll, only on table scrolling
	useEffect(() => setHandleScroll(undefined), []);

	const closeTranscript = (): void => setAnswerId("");

	const fileCell = useCallback(({value}): ReactElement => {
		if (!value) return <></>;
		if (value?.skipped) return <span>Skipped</span>;
		return value.uploadedImages.map((v) => (
			<div key={v.originalFilename} className={styles.upload}>
				<img src={v.thumbnail} className={styles.img} />
				<span onClick={() => setImgModal(v.full)}>{v.originalFilename}</span>
			</div>
		));
	}, []);

	const barcodeCell = useCallback(({value}): ReactElement => {
		if (!value) return <></>;
		if (value?.skipped) return <span>Skipped</span>;
		return value.barcodes.map((v) => (
			<div key={v.product?.barcodeNumber} className={styles.upload}>
				<img src={v.product?.images[0]} className={styles.img} />
				<span onClick={() => setImgModal(v.product?.images[0])}>{v.product?.title}</span>
			</div>
		));
	}, []);

	const transcriptCell = useCallback(
		({value}): ReactElement => {
			if (value?.skipped) return <div>Skipped</div>;
			const video = value?.video;

			const openTranscript = (): void => setAnswerId(value.id);
			return (
				<div className={styles.transcript}>
					{video && (
						<div className={styles.video} onClick={openTranscript}>
							<img src={video.badge} />
							Video ({convertToClockTime(video.duration)})
						</div>
					)}
					{video?.id && value?.id && (
						<div style={{maxHeight: 213, overflow: "auto"}}>
							<Transcript
								key={video.id}
								transcript={video?.transcript}
								answerId={value?.id}
								showTranslation={showTranslation}
							/>
						</div>
					)}
				</div>
			);
		},
		[showTranslation],
	);

	const handleSend = (id: string, userIds: string[]): void => {
		sendToSegment({
			variables: {segmentId: id, userIds, workspaceId},
			onError: (error) => {
				updateToast({description: error.message, type: "failure"});
			},
			onCompleted: (data) => {
				const addedUsersLength = data.addSegmentMembers.addedUsers.length;
				const existingUsersLength = data.addSegmentMembers.existingUsers.length;
				updateToast({
					description: (
						<div>
							{addedUsersLength > 0 && <div>{addedUsersLength} added to list</div>}
							{existingUsersLength > 0 && <div>{existingUsersLength} already in list</div>}
						</div>
					),
					type: "informational",
				});
			},
		});
	};

	const currentData =

		useMemo(
			() => data?.responses?.items?.slice(currentPage * TABLE_PAGE_SIZE, (currentPage + 1) * TABLE_PAGE_SIZE),
			[data, currentPage],
		);
	const columns = useMemo((): Column<Response>[] => {
		const questions = [...cQuestions].sort((a, b) => a.index - b.index);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const ret: any[] = [
			{
				Header: "Name",
				minWidth: 200,
				sticky: "left",
				accessor: (row) => row.user,
				Cell: ({value}: {value: User}) => {
					const name = value ? `${value.firstName} ${value.lastInitial}.` : "Unknown";
					return (
						<div className={styles.nameColumn}>
							<span className={styles.tag}>{value?.creatorTag || "Unknown Tag"}</span>
							<span className={styles.name}>{name}</span>
							{!name.toLowerCase().includes("anonymous") && (
								<SegmentButton sendUsers={handleSend} userIds={[value.id]} workspaceId={workspaceId} />
							)}
						</div>
					);
				},
				cellProps: () => ({className: styles.name}),
			},
			{
				Header: "Date Submitted",
				minWidth: 200,
				accessor: (row) => row?.completedAt,
				Cell: ({value}) => (value ? format(value) : "Incomplete"),
				cellProps: () => ({className: styles.time}),
			},
		];

		questions.forEach((q, i) => {
			if (q.type !== QuestionType.NONE) {
				ret.push({
					Header: makeHeader(q, i),
					accessor: answerFinder(q.id),
					disableSortBy: true,
					minWidth: 350,
					maxWidth: 3000,
					id: q.id,
					Cell:
						q.type === QuestionType.CHOICE
							? choiceCell
							: q.type === QuestionType.BARCODE
								? barcodeCell
								: q.type === QuestionType.TEXT
									? textCell
									: q.type === QuestionType.PICTURE
										? q.subtype === "video"
											? transcriptCell
											: fileCell
										: q.type === QuestionType.SLIDER
											? q.subtype === "star"
												? starCell
												: numberCell
											: () => "???",
					headerProps: () => ({className: styles.choice}),
				});
			}

			if (q.videoResponse) {
				ret.push({
					Header: makeFollowupHeader(q, i),
					accessor: answerFinder(q.id),
					minWidth: 350,
					maxWidth: 3000,
					disableSortBy: true,
					id: `${q.id}-video`,
					Cell: transcriptCell,
					headerProps: () => ({className: styles.followup}),
				});
			}
		});

		return ret;
	}, [showTranslation, sortBy]);

	const {getTableProps, headerGroups, rows, prepareRow, pageCount} = useTable(
		{
			columns,
			data: currentData ?? [],
			initialState: {pageSize: TABLE_PAGE_SIZE},
			manualSortBy: true,
			manualPagination: true,

			pageCount: data ? Math.ceil((data.responses.items.length + data.responses.remaining) / TABLE_PAGE_SIZE) : 0,
		},
		(hooks) => {
			hooks.getCellProps.push((props, {cell}) => ({
				...props,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				...(cell.column as any).cellProps?.(),
			}));
			hooks.getHeaderProps.push((props, {column}) => ({
				...props,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				...(column as any).headerProps?.(),
			}));
		},
		useFlexLayout, // Required for resize
		useSortBy,
		useResizeColumns,
		useSticky,
	);

	const handleToggleTranslation = (e: React.ChangeEvent<HTMLInputElement>): void =>
		setShowTranslation(e.target.checked);
	/**
	 * Pagination related functions
	 */
	const handleNext = (): void => {
		if (!data) return;

		if ((currentPage + 2) * TABLE_PAGE_SIZE > data.responses.items.length) {
			handleFetchMore(TABLE_PAGE_SIZE);
		}
		setCurrentPage((prev) => prev + 1);
	};
	// Can't think of a time we'd need to fetch more when clicking the back arrow
	const handlePrev = (): void => setCurrentPage((prev) => prev - 1);
	// If the new page is higher than the current, we'll need to fetch some more values
	const gotoPage = (newPage: number): void => {
		if (!data) return;
		if (currentPage < newPage && (newPage + 1) * TABLE_PAGE_SIZE > data?.responses.items.length) {
			handleFetchMore((newPage - currentPage) * TABLE_PAGE_SIZE);
		}
		setCurrentPage(newPage);
	};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const sortCallback = (value: HeaderGroup<any>): void => {
		if (!value.canSort) return;
		switch (value.Header) {
		case "Name":
			if (value.isSorted === false && value.isSortedDesc === undefined)
				return setSortBy(ResponsesSort.CREATOR_TAG_DESC);
			if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(ResponsesSort.CREATOR_TAG_ASC);
			setSortBy(ResponsesSort.DEFAULT);
			break;
		case "Date Submitted":
			if (value.isSorted === false && value.isSortedDesc === undefined)
				return setSortBy(ResponsesSort.DATE_COMPLETED_OLDEST);
			if (value.isSorted === true && value.isSortedDesc === false)
				return setSortBy(ResponsesSort.DATE_COMPLETED_MOST_RECENT);
			setSortBy(ResponsesSort.DEFAULT);
			break;
		default:
			setSortBy(ResponsesSort.DEFAULT);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleSort = (column: HeaderGroup<any>): void => {
		sortCallback(column);
		column.toggleSortBy();
		setCurrentPage(0);
	};

	return (
		<HighlightContextProvider>
			<>
				{!language.name.toLocaleLowerCase().includes("english") && (
					<div className={styles.languageToggleContainer}>
						<span>Translate to English for all available</span>
						<ToggleSwitch id="toggle-all-english" onChange={handleToggleTranslation} isChecked={showTranslation} />
						<span>
							<i>* Highlighting unavailable for translated transcripts</i>
						</span>
					</div>
				)}
				<div className={styles.container} ref={tableContainerRef}>
					<table {...getTableProps()} className={classNames(styles.table, styles.sticky)}>
						<thead className={styles.header}>
							{headerGroups.map((headerGroup, i) => (
								<tr {...headerGroup.getHeaderGroupProps()} key={i}>
									{headerGroup.headers.map((column, j) => (
										<th
											{...column.getHeaderProps(column.getSortByToggleProps())}
											key={j}
											onClick={column.canSort ? () => handleSort(column) : undefined}
										>
											{column.render("Header")}
											{column.isSorted ? (
												<span className={classNames(column.isSortedDesc ? styles.down : styles.up)} />
											) : null}
											<div
												// Library doesn't recognize their own functions
												// @ts-ignore
												{...column.getResizerProps()}
												// @ts-ignore
												className={classNames(styles.resizer, column.isResizing ? styles.dragging : undefined)}
											/>
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody>
							{fragment ? (
								<tr>
									<td colSpan={headerGroups?.[0]?.headers?.length}>{fragment}</td>
								</tr>
							) : (

								rows.map((virtualRow) => {
									const row = rows[virtualRow.index];
									prepareRow(row);
									return (
										<tr
											{...row.getRowProps()}
											key={virtualRow.index}
											// ref={virtualRow.measureRef}
										>
											{row.cells.map((cell, j) => (
												<td {...cell.getCellProps()} key={j}>
													{cell.render("Cell")}
												</td>
											))}
										</tr>
									);
								})
							)}
							{fetchMoreFragment && (
								<tr>
									<td colSpan={headerGroups?.[0]?.headers?.length}>{fetchMoreFragment}</td>
								</tr>
							)}
						</tbody>
					</table>
					{answerId && <TranscriptModal answerId={answerId} onClose={closeTranscript} />}
				</div>
				<div className={styles.pagination}>
					<TablePagination
						currentPage={currentPage}
						pageCount={pageCount}
						handleNext={handleNext}
						handlePrev={handlePrev}
						gotoPage={gotoPage}
					/>
				</div>
				<ImageModal
					image={imgModal}
					isShowing={Boolean(imgModal)}
					handleClose={() => setImgModal("")}
					isVideo={false}
				/>
			</>
		</HighlightContextProvider>
	);
};

export {ResultsTable};
