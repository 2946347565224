import {Icon} from "../../../shared";
import React, {ReactElement, useEffect, useRef, useState} from "react";
import {DragSourceMonitor, useDrag, useDrop} from "react-dnd";
import {Choice} from "../../../models/choice";
import {DragItemTypes} from "../../../shared/constants/constants";
import {QuestionSubType} from "../../../models/questions";
import styles from "./option-card.module.scss";
import {toggleDeleteQuestionModal} from "../../../cache";
import classnames from "classnames/bind";
import {getEmptyImage} from "react-dnd-html5-backend";
import {AutoResizeTextarea} from "../../../shared/v2/inputs";

const bStyles = classnames.bind(styles);

export interface OptionCardProps {
	/**
	 * Choice
	 */
	choice: Choice;
	/**
	 * Index of the card
	 */
	index: number;
	/**
	 * Determines if user can delete the option.
	 * This should only be false when options list is exactly two.
	 */
	canDelete: boolean;
	choiceType: QuestionSubType;
	/**
	 * How to handle dragging card
	 */
	dragCard: (dragIndex: number, hoverIndex: number) => void;
	/**
	 * What to do when renaming the option
	 */
	onRename: (newValue: string, id: string) => void;
	/**
	 * What to do when deleting an option
	 */
	onDelete: (id: string) => void;
	/**
	 * What to do when moving a card
	 */
	moveCard: (dragIndex: number, hoverIndex: number) => void;
}
/**
 * The option card. When editable will allow user to edit text, delete, and move it.
 * Otherwise it just displays as a pseudo-button.
 * @param choice The Choice object.
 * @param index What position the card is in in the list of options
 * @param canDelete determines if the delete option will show or not
 * @param onRename Callback function to determine what to do when renaming item
 * @param onDelete Callback function for delete method
 * @param moveCard Callback for how to handle moving the card (dragging)
 * @param canEdit Determines if editable options appear on card
 *
 * @returns styled option card that can be reorderd, change text, and deleted.
 */
const OptionCard = React.memo((props: OptionCardProps): ReactElement => {
	const {
		choice: {text, id, answerCount},
		index,
		canDelete,
		choiceType,
		dragCard,
		moveCard,
		onRename,
		onDelete,
	} = props;
	const containerRef = useRef<HTMLDivElement>(null);
	const handleRef = useRef<HTMLDivElement>(null);
	const [textValue, setTextValue] = useState<string>(text || "");

	/**
	 * Drop hook handler
	 */
	const [, drop] = useDrop({
		accept: DragItemTypes.OPTIONCARD,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		hover(item: any) {
			if (!containerRef.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;
			dragCard(dragIndex, hoverIndex);
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		drop(item: any) {
			if (!containerRef.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			if (dragIndex === hoverIndex) {
				return;
			}
			moveCard(dragIndex, hoverIndex);
		},
	});

	const [{isDragging}, drag, preview] = useDrag(
		() => ({
			type: DragItemTypes.OPTIONCARD,
			item: {text, index, choiceType},
			collect: (monitor: DragSourceMonitor) => ({
				isDragging: monitor.isDragging(),
			}),
		}),
		[index, text, choiceType],
	);

	useEffect(() => {
		preview(getEmptyImage(), {captureDraggingState: true});
	}, []);
	/**
	 * Updates the option value
	 * @param newValue New text value coming from input
	 */
	const handleFinishEditing = (newValue: string): void => {
		onRename(newValue, id);
	};
	/**
	 * Sends the position of option to delete
	 */
	const handleDelete = (): void => {
		if (answerCount === 0) {
			onDelete(id);
		} else {
			toggleDeleteQuestionModal({
				isShowing: true,
				type: "choice",
				id,
			});
		}
	};

	// Set up the drag and drop handlers
	drag(handleRef);
	drop(containerRef);
	const visibility = isDragging ? "hidden" : "visible";
	return (
		<div
			ref={containerRef}
			className={styles.container}
		>
			<div
				className={bStyles("button", choiceType.toLowerCase(), {empty: isDragging})}
			>
				{choiceType === "MULTISELECT" && <div className={styles.fakeCheckbox}/>}
				{choiceType === "RANKED" && <div className={styles.number}>{index + 1}</div>}

				<AutoResizeTextarea
					value={textValue}
					onChange={setTextValue}
					onBlur={handleFinishEditing}
					id={id}
					className={styles.input}
					maxLength={255}
				/>
			</div>
			<div className={styles.options} style={{visibility}}>
				<div ref={handleRef} className={styles.grab}>
					<Icon
						name="drag"
						size="extrasmall"
						fill="black"
					/>
				</div>
				{
					canDelete &&
					<Icon
						name="close"
						size="extrasmall"
						isClickable
						clicked={handleDelete}
						className={styles.cancel}
					/>
				}
			</div>
		</div>
	);
});

OptionCard.displayName = "OptionCard";

export {OptionCard};
