import classNames from "classnames/bind";
import React, {ReactElement, ReactNode, useEffect, useState} from "react";

import styles from "./spacing.module.scss";

const cx = classNames.bind(styles);

export interface dropdownItemSpacingProps {
  children: ReactNode;
  active?: boolean;
  className?: string;
}

export const DropdownItemSpacing = ({children, className, active = false}: dropdownItemSpacingProps): ReactElement => {
	const [element, setElement] = useState<HTMLDivElement | null>(null);

	useEffect(() => {
		if (active && element) {
			element.scrollIntoView({block: "nearest"});
		}
	}, [active, element]);

	return (
		<div ref={setElement} className={cx("dropdownItemSpacing", className)}>
			{children}
		</div>
	);
}
