/**
 * May not need a whole file for this
 */
import {gql} from "@apollo/client";

/**
 * URLs must be unique. Enforced by backend by should show the error on front
 * end if an entered URL is not unique on the builder page
 */
export const GET_BRAND_BY_URL = gql`
	query BrandByUrl($url: String) {
		brand(url: $url) {
			id
			name
			brandAbout
			description
			visible
			categories {
				id
				name
			}
			bannerPicture {
				id
				original:url(version: ORIGINAL)
			}
			logoPicture {
				id
				medium:url(version: MEDIUM)
			}
			buttonText
			buttonUrl
			survey {
				title
				questionCount
				status
				closeAt
				closeAfterResponsesCount
				completedResponseCount
			}
		}
	}
`;

export const GET_MY_FOLLOWED_BRANDS = gql`
	query MyFollowedBrands {
		me {
			id
			followedBrands {
				id
			}
			followRequests {
				brandId
				status
			}
		}
	}
`;

export const GET_BRAND_CATEGORIES = gql`
	query Categories {
		brandCategories {
			id
			name
		}
	}
`;

export const GET_BRAND_BENEFITS = gql`
	query BrandBenefits {
		benefits {
			id
			name
		}
	}
`;

export const GET_ACTIVITIES = gql`
	query Activities {
		activities {
			id
			name
		}
	}
`;

export const GET_ALL_BRAND_ATTRIBUTES = gql`
	query BrandAttributes {
		brandCategories {
			id
			name
		}
		benefits {
			id
			name
		}
		activities {
			id
			name
		}
	}
`;

/**
 * Shouldn't something that says "ID" be a GUID! not a String! ?
 * Seems like we've been inconsistent about this lately.
 */
export const BRAND_PRIMARY_CATS = gql`
	query BrandPrimaryCategory ($brandId: GUID!) {
		brandPrimaryCategory (brandId: $brandId) {
			category {
				id
				name
			}
		}
	}
`;

/**
 * Same as above ?
 */
export const BRAND_SECONDARY_CATS = gql`
	query BrandSecondaryCategories ($brandId: GUID!) {
		brandSecondaryCategories (brandId: $brandId) {
			category {
				id
				name
			}
		}
	}
`;
