import React, {Fragment, HTMLProps, ReactElement, ReactNode} from "react";
import {
	InsertMentionPatternProps,
	MentionPatternProps,
	TextToHTMLPatternReplacerOption,
	patternReplacersMap
} from "./patterns";
import {CommandPatternProps} from "./patterns/command";

export interface TextToHTMLPatternReplacerProps extends HTMLProps<HTMLSpanElement> {
  text: string;
  noWrapperElement?: boolean;
  patterns: {
    [TextToHTMLPatternReplacerOption.InsertMention]?: InsertMentionPatternProps;
    [TextToHTMLPatternReplacerOption.Mention]?: MentionPatternProps;
    [TextToHTMLPatternReplacerOption.Command]?: CommandPatternProps;
  };
}

export const TextToHTMLPatternReplacer = ({
	text,
	patterns,
	noWrapperElement = false,
	...props
}: TextToHTMLPatternReplacerProps): ReactElement => {
	const patternKeys = Object.keys(patterns) as TextToHTMLPatternReplacerOption[];

	const applyPattern = (elements: ReactNode[], patternKey: TextToHTMLPatternReplacerOption): ReactNode[] => {
		const replacerProps = patterns[patternKey];
		const replacer = patternReplacersMap[patternKey];

		if (!replacer || replacerProps === undefined) {
			return elements;
		}

		return elements.flatMap(
			(text) =>
				typeof text === "string" ?
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
					replacer({...replacerProps, text} as any) :
					text
		);
	}

	const giveIndexes = (element: ReactNode, index: number) => {
		return (
			<Fragment key={index}>
				{element}
			</Fragment>
		);
	}

	const content = patternKeys.reduce(applyPattern, [text]).map(giveIndexes);

	return noWrapperElement ? (
		<>{content}</>
	) : (
		<span {...props}>{content}</span>
	)
}
