import React, {ReactElement, useContext} from "react";
import {hasChoices, isSlider, isText} from "../form-builder";
import {AnswersPageQueryParams} from "../../../models/answer";
import {SurveyContext} from "../../../context/survey-context";
import {Question, QuestionType} from "../../../models/questions";
import {Button} from "../../../shared/v2";
import {RankedBarChart} from "../../../shared/components/ranked-bar-chart";
import {ChoiceBarChart} from "../choice-bar-chart";
import {SliderChart} from "../slider-chart";
import {StarChart} from "../star-chart";
import {WordCloudChart} from "../word-cloud-chart";
import {checkIfExists} from "../../../shared/utility";
import {getCurrent} from "../../../ref";
import html2canvas from "html2canvas";
import sanitize from "sanitize-filename";
import styles from "./chart-display.module.scss";

export interface ChartDisplayProps {
	question?: Question;
	changeHistorySearch: (filter: Partial<AnswersPageQueryParams>) => void;
	resultsFilter: AnswersPageQueryParams;
	noButton?: boolean;
}

const ChartDisplay = (props: ChartDisplayProps): ReactElement | null => {
	const {question, changeHistorySearch, resultsFilter, noButton = false} = props;
	const chartRef = React.createRef<HTMLDivElement>();
	const {survey: {name}} = useContext(SurveyContext);
	if (!question || question.type === QuestionType.NONE) return null;

	const handleChoiceFilter = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const updatedChoices = checkIfExists(resultsFilter.choiceId, e.target.value);
		changeHistorySearch({choiceId: updatedChoices});
	};

	const handleNumberFilter = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const {value} = e.target;
		 
		const updatedNumberAnswers = checkIfExists(resultsFilter.numberAnswer, value === "null" ? null : parseFloat(value));
		changeHistorySearch({numberAnswer: updatedNumberAnswers});
	};
	const handleCopyChart = (): void => {
		const current = getCurrent(chartRef);
		if (current) {
			html2canvas(
				current,
				{
					width: current.clientWidth + 20,
					height: current.clientHeight + 20,
					// give some extra whitespace around image
					y: -10,
					x: -10,
				},
			).then(canvas => {
				const dataURL = canvas.toDataURL("image/png");
				const fileName = sanitize(`${name.replace(" ", "-")}-q${question.index + 1}-chart`);
				const a = document.createElement("a");
				a.href = dataURL;
				a.download = fileName;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			});
		}
	};
	const button = noButton ? undefined
		: <Button className={styles.button} variant="outlined" onClick={handleCopyChart}>
		Download chart
		</Button>;

	if (isSlider(question) && question.answerCount > 0) {
		const Chart = question.subtype === "star" ? StarChart : SliderChart;
		return <Chart
			question={question}
			ref={chartRef}
			selected={resultsFilter.numberAnswer}
			changeSelected={handleNumberFilter}
			copyButton={button}
		/>;
	}
	if (hasChoices(question) && question.answerCount > 0) {
		const Chart = question.rank ? RankedBarChart : ChoiceBarChart;
		return <Chart
			question={question}
			ref={chartRef}
			changeSelected={handleChoiceFilter}
			copyButton={button}
			selected={resultsFilter.choiceId}
		/>;
	}
	if (isText(question) && question.subtype !== "number") {
		return <WordCloudChart
			data={question.textWordCounts}
			ref={chartRef}
			copyButton={button}
		/>;
	}
	return null;
};

export {ChartDisplay};
