import classNames from "classnames/bind";
import React, {cloneElement, ReactElement, useMemo} from "react";

import {CheckmarkIcon, ChevronDownIcon} from "../../../../icons";
import {Dropdown} from "../../../../shared/v2/dropdown";
import {InputElements} from "../input-elements";
import {InputProps} from "../input";
import {useThemeMode} from "../../../../context/theme-mode-context";

import inputStyles from "../input/input.module.scss";
import styles from "./select-input.module.scss";

const cx = classNames.bind(inputStyles);
const selectCx = classNames.bind(styles);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface Option<T = any> {
	id: T;
	name: string;
  tooltip?: string;
	icon?: ReactElement;
};

export interface SelectInputProps extends Omit<
	InputProps<string>,
	"type" | "value" | "rightIcon" | "onEnter" | "onBlur"
> {
	value?: string | undefined;
	options: Option[];
	onChange: (value: string) => void;
}

export const SelectInput = ({
	className,
	value,
	size = "small",
	variant = "default",
	label,
	hintText,
	validation,
	options,
	leftIcon,
	onChange,
	...props
}: SelectInputProps): ReactElement => {
	const innerVariant = validation?.isError ? "error" : variant;
	const {isDarkMode} = useThemeMode();

	const currentOption = useMemo(
		() =>  options.find(option => option.id === value),
		[options, value]
	);

	return (
		<InputElements
			className={className}
			filledIcon
			hintText={hintText}
			label={label}
			rightIcon={<ChevronDownIcon />}
			validation={validation}
			variant={innerVariant}
		>
			<Dropdown
				space={8}
				position="bottom-start"
				sameWidth
				triggerClassName={selectCx("trigger", innerVariant, {isDarkMode})}
				popupClassName={styles.popup}
				popupContentClassName={styles.popupContent}
				trigger={
					<>
						{leftIcon && cloneElement(leftIcon, {className: cx("icon", "leftIcon", size, innerVariant)})}
						<input
							className={cx(
								"input",
								styles.input,
								size,
								innerVariant,
								leftIcon && "withLeftIcon",
								"withRightIcon",
							)}
							value={currentOption?.name ?? ""}
							placeholder="Select"
							tabIndex={-1}
							readOnly
							{...props}
						/>
					</>
				}
				items={
					options.map((option) => ({
						label: option.name,
						onClick: () => onChange(option.id),
						tooltip: option.tooltip,
						icon: option.icon,
						rightIcon: option.id === value ? <CheckmarkIcon /> : undefined,
					}))
				}
			/>
		</InputElements>
	)
}
