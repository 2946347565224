import React, {forwardRef, ReactElement, Ref} from "react";
import classNames from "classnames/bind";

import styles from "./Subheader.module.scss";

const bStyles = classNames.bind(styles);

export interface SubheaderProps {
	className?: string;
	size?: "xl" | "l" | "base";
	type?: "regular" | "medium" | "semibold" | "bold";
	color?: "text-body" | "text-secondary" | "gray-modern-900" | "charcoal-elevation-200" | "gray-modern-600"
		| "charcoal-elevation-25";
	animated?: boolean;
	onClick?: () => void;
	children: ReactElement | string;
};

export const Subheader = forwardRef(function Subheader({
	children,
	animated = false,
	size = "base",
	type = "regular",
	color = "text-body",
	onClick,
	className,
}: SubheaderProps, ref: Ref<HTMLDivElement>): ReactElement {
	return <div ref={ref} className={bStyles("subheader", size, type, color, className, {animated})} onClick={onClick}>
		{children}
	</div>;
});
