import React, {ReactElement, useEffect, useState} from 'react';
import {BaseModalProps, Modal} from '../../../shared/v2/modals/modal/';

import {Button, Input, TextArea} from '../../../shared/v2';
import {useValidation} from '../../../hooks/useValidation';
import {AiSkill} from '../../../models/ai-model';

import styles from './skill-modal.module.scss';

export type AgentChanges = Omit<AiSkill, "id">;
export interface SkillModalProps extends BaseModalProps {
  skill: AiSkill | null;
  onSave: (changes: AgentChanges) => void;
}

export const SkillModal = ({isOpen, onClose, skill, onSave}: SkillModalProps): ReactElement => {
	const [changes, setChanges] = useState({
		name: "",
		description: "",
	});

	useEffect(() => {
		if (skill) {
			setChanges({
				name: skill.name,
				description: skill?.description || "",
			});
		}
	}, [skill, isOpen]);

	const {results: validation, validate, clearErrors} = useValidation(changes, {
		name: {
			ruleset: {
				required: true,
				max: 150,
			},
		},
	});

	const handleClose = (): void => {
		onClose();
		setChanges({
			name: "",
			description: "",
		});
		clearErrors();
	}

	const handleSave = ():void => {
		if (!validate()) {
			return;
		}
		onSave(changes);
		handleClose();
	}

	const handleChangeField = (value: string, field: string): void => {
		setChanges({
			...changes,
			[field]: value,
		});
	}

	return <Modal
		isOpen={isOpen}
		onClose={handleClose}
		title="Create Tag"
	>
		<div className={styles.form}>
			<Input
				value={changes.name}
				onChange={(value: string) => handleChangeField(value, "name")}
				placeholder="Name"
				validation={validation.name}
			/>
			<TextArea
				value={changes.description}
				onChange={(value: string) => handleChangeField(value, "description")}
				placeholder="Description"
			/>
		</div>

		<div className={styles.footer}>
			<Button variant="outlined" onClick={handleClose}>Cancel</Button>
			<Button onClick={handleSave}>Save</Button>
		</div>
	</Modal>
}
