/**
 * State for the DateRange input, mostly
 */

import {DateRange} from "models/filter";
import {useState} from "react";

export type DateSetter = (value: string, pos: "start" | "end") => void;

export const useDateRange = (defaultValue?: DateRange): [DateRange, DateSetter, () => void] => {
	const [date, setDate] = useState<DateRange>(defaultValue || {start: "", end: ""});

	const handleChange = (value: string, pos: "start" | "end"): void => {
		// Reset the "end" value if we set start to limit user error
		if (pos === "start") return setDate(({start: value, end: ""}));
		setDate(prev => ({...prev, end: value}));
	};

	const reset = (): void => {
		setDate({start: "", end: ""});
	};
	return [date, handleChange, reset];
};
