import React, {ReactElement, useContext, useMemo, useState} from "react";
import {useMutation, useQuery} from "@apollo/client";

import {
	DatetimeInput,
	Heading,
	formatTZ as format,
	Select,
} from "../../../shared";
import {
	CLEAR_SURVEY_SCHEDULE,
	CREATE_SURVEY_NOTIFICATION,
	PUBLISH_SURVEY,
	REMOVE_SURVEY_NOTIFICATION,
	UNPUBLISH_SURVEY,
	UPDATE_SURVEY,
} from "../../../graphql/mutations/survey-mutations";
import {
	updateAddSurveyNotification,
	updateDeleteSurveyNotification,
} from "../../../shared/utility/update-survey-notifications";
import {GET_SURVEY_LANGUAGES} from "../../../graphql/queries/queries";
import {SurveyContext} from "../../../context/survey-context";
import {ToastContext} from "../../../context/toast-context";
import {SurveyAccessLevel} from "../../../shared/constants/constants";
import {
	SurveyStatus,
	UpdateSurveyKeys,
	UpdateSurveyReturn,
	UpdateSurveyVars,
} from "../../../models/survey";
import {HelpTooltip} from "../../../shared/components/help-tooltip";
import {Card} from "../../../shared/layout/card";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {useUserContext} from "../../../context/user-context";
import {UserRoles} from "../../../models/user";
import {Button, Input, NumberInput, Checkbox, Body, RadioGroup} from "../../../shared/v2";

import styles from "./survey-settings.module.scss";
import config from "../../../config";

enum ScheduleOptionIds {
	END_DATE = "end-date",
	DO_NOT_CLOSE = "do-not-close",
	RESPONSE_LIMIT = "response-limit",
}

enum MultiResponse {
	SINGLE = "false",
	MULTIPLE = "true",
}

const SurveySettings = (): ReactElement => {
	const {
		survey: {
			id,
			closeAfterResponsesCount,
			closeAt,
			closedAt,
			notifications,
			multiResponse,
			autoClosed,
			thankYouMessage,
			accessLevel,
			status,
			offlineAssist,
			redirectUrl,
			language: {id: languageId},
		},
		setShowingPublish,
	} = useContext(SurveyContext);
	const {workspace} = useWorkspaceContext();
	const {user, isEnterpriseManagerOrSupport} = useUserContext();
	const {onError, updateToast} = useContext(ToastContext);

	const {data: languagesData, loading: languagesLoading} = useQuery(GET_SURVEY_LANGUAGES);

	const [clearSchedule] = useMutation<UpdateSurveyReturn>(CLEAR_SURVEY_SCHEDULE);
	const [removeNotification] = useMutation(REMOVE_SURVEY_NOTIFICATION);
	const [addNotification] = useMutation(CREATE_SURVEY_NOTIFICATION, {onError});
	const [updateSurvey] = useMutation<UpdateSurveyReturn, UpdateSurveyVars>(UPDATE_SURVEY);
	const [unpublishSurvey] = useMutation(UNPUBLISH_SURVEY, {
		onCompleted: () => updateToast({description: "Vurvey closed", type: "informational"}),
	});
	const [publishSurvey] = useMutation(PUBLISH_SURVEY, {
		onCompleted: () => updateToast({description: "Vurvey opened", type: "informational"}),
	});

	const [scheduleActiveOption, setScheduleActiveOption] = useState<ScheduleOptionIds[]>(() => {
		const final: ScheduleOptionIds[] = [];
		if (closeAfterResponsesCount) final.push(ScheduleOptionIds.RESPONSE_LIMIT);
		if (closeAt) final.push(ScheduleOptionIds.END_DATE);
		if (final.length === 0) final.push(ScheduleOptionIds.DO_NOT_CLOSE);
		return final;
	});

	const [customThankYouMessage, setCustomThankYouMessage] = useState(
		Boolean(thankYouMessage),
	);
	const [redirectOn, setRedirectOn] = useState(Boolean(redirectUrl));
	const [surveyLanguage, setSurveyLanguage] = useState(languageId);
	const [thankYou, setThankYou] = useState(
		thankYouMessage || "Thanks for completing the Vurvey! 🎉",
	);
	const [redirect, setRedirect] = useState(redirectUrl || "https://www.vurvey.co");
	const [closeAfterResponsesCountValue, setCloseAfterResponsesCountValue] = useState(closeAfterResponsesCount || 1)

	const today = new Date();
	const notificationScheduledHours = notifications.map(ns => ns.hoursBefore);
	const endDateLabel = closedAt && format(closedAt);

	const handlePublish = (): void => setShowingPublish(true);

	const handleUnpublish = (): void => {
		unpublishSurvey({variables: {id}});
	};
	const handleOpenSurvey = (): void => {
		publishSurvey({variables: {id}});
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleUpdate = (value: any, key: UpdateSurveyKeys): void => {
		if (key === "languageId") setSurveyLanguage(value);
		updateSurvey({
			variables: {id, changes: {[key]: value}},
		});
	};

	const handleChecked = (value: ScheduleOptionIds, currentSchedule: ScheduleOptionIds[]): ScheduleOptionIds[] => {
		if (value === ScheduleOptionIds.DO_NOT_CLOSE) {
			clearSchedule({variables: {id}});
			return [value];
		}

		if (currentSchedule.includes(ScheduleOptionIds.DO_NOT_CLOSE)) {
			currentSchedule = currentSchedule.filter(option => option !== ScheduleOptionIds.DO_NOT_CLOSE);
		}

		const updatedSchedule = [value, ...currentSchedule];

		if (value === ScheduleOptionIds.RESPONSE_LIMIT) {
			handleUpdate(closeAfterResponsesCountValue, "closeAfterResponsesCount");
		}

		return updatedSchedule;
	};

	const handleUnchecked = (value: ScheduleOptionIds, currentSchedule: ScheduleOptionIds[]): ScheduleOptionIds[] => {
		const remainingSchedule = currentSchedule.filter(option => option !== value);

		if (remainingSchedule.length === 0) {
			clearSchedule({variables: {id}});
			return [ScheduleOptionIds.DO_NOT_CLOSE];
		}

		if (value === ScheduleOptionIds.RESPONSE_LIMIT) {
			updateSurvey({variables: {id, deletions: {closeAfterResponsesCount: true}}});
		} else if (value === ScheduleOptionIds.END_DATE) {
			updateSurvey({variables: {id, deletions: {closeAt: true}}});
		}

		return remainingSchedule;
	};


	const handleSchedule = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const value = e.target.value as ScheduleOptionIds;
		const checked = e.target.checked;
		let updatedSchedule = [...scheduleActiveOption];

		if (checked) {
			updatedSchedule = handleChecked(value, updatedSchedule);
		} else {
			updatedSchedule = handleUnchecked(value, updatedSchedule);
		}

		setScheduleActiveOption(updatedSchedule);
	};

	const handleNotifyRecipients = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const value = parseInt(event.target.id);

		if (notificationScheduledHours.includes(value)) {
			const notificationScheduleToRemove = notifications.find(
				ns => ns.hoursBefore === value,
			);

			if (notificationScheduleToRemove) {
				removeNotification({
					variables: {id: notificationScheduleToRemove.id},

					update(cache) {
						updateDeleteSurveyNotification(cache, id, notificationScheduleToRemove.id);
					},
				});
			}
			return;
		}

		addNotification({
			variables: {surveyId: id, hoursBefore: value},
			update(cache, {data: notificationData}) {
				if (notificationData) {
					updateAddSurveyNotification(
						cache,
						id,
						notificationData.createSurveyNotificaton,
					);
				}
			},
		});
	};

	// Probably could update to use handleUpdate but leaving it for now
	const handleCustomThankYouMessage = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const checked = event.target.checked;

		if (!checked) {
			updateSurvey({
				variables: {
					id,
					changes: {thankYouMessage: null},
				},
			});
		}

		setCustomThankYouMessage(checked);
	};

	const handleRedirectOn = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const checked = event.target.checked;

		if (!checked) {
			updateSurvey({
				variables: {
					id,
					changes: {redirectUrl: null},
				},
			});
		}

		setRedirectOn(checked);
	};

	const isDisabled = useMemo(() => {
		// disable if the survey is set to anonymous and user.role is a regular user
		return (
			accessLevel === SurveyAccessLevel.ANONYMOUS &&
			user.role === UserRoles.REGULAR_USER
		)
	}, [accessLevel, user.role]);

	const handlePreview = (): void => {
		const open = window.open(`${config.responder}/#/${id}/preview`);
		(open as Window).opener = null;
	};
	const languageSelect = useMemo(() => {
		return (
			<Select
				className={styles.languageSelect}
				id="language-select"
				options={
					languagesData?.surveyLanguages?.map(language => ({
						text: language.name,
						value: language.id,
					})) ?? []
				}
				onChange={val => handleUpdate(val, "languageId")}
				selectedValue={surveyLanguage}
			/>
		);
	}, [languagesLoading, surveyLanguage]);

	return (
		<div className={styles.container}>
			<div className={styles.grid}>
				{workspace.enableOfflineAssist &&
				<Card className={styles.offline}>
					<Heading size="md">🌱 Enable Offline mode</Heading>
					<Checkbox
						checked={offlineAssist}
						size="s"
						onChange={checked => handleUpdate(checked, "offlineAssist")}
						id="offline-checkbox"
					/>
				</Card>
				}
				<Card id="privacy-section">
					<Heading size="md">
						Who can participate?{" "}
						<HelpTooltip id="test">
							<p>Public: Anyone with the link and Vurvey account can participate</p>
							<p>
								Private: Only those invited and with a Vurvey account can participate.
							</p>
							{workspace.allowAnonymous &&
								(user.role === UserRoles.ENTERPRISE_MANAGER ||
									user.role === UserRoles.SUPPORT) && (
								<p>
									Public and Anonymous: Anyone with the link and does not require an
									account to take survey.
								</p>
							)}
						</HelpTooltip>
					</Heading>

					<RadioGroup
						onChange={val => handleUpdate(val, "accessLevel")}
						value={accessLevel}
						size="s"
						className={styles.participateRadio}
					>
						<RadioGroup.Option
							disabled={isDisabled}
							label="Public"
							value={SurveyAccessLevel.PUBLIC}
						/>
						<RadioGroup.Option
							label="Private"
							value={SurveyAccessLevel.PRIVATE}
							disabled={isDisabled}
						/>
						{isDisabled && (
							<p>
								The access level has been set to allow anonymous users to take this
								survey. If you need to change this please reach out to your support
								contact.
							</p>
						)}
						{workspace.allowAnonymous && isEnterpriseManagerOrSupport && (
							<RadioGroup.Option
								label="Public and Anonymous"
								value={SurveyAccessLevel.ANONYMOUS}
							/>
						)}
					</RadioGroup>
				</Card>
				<Card id="participation-section">
					<RadioGroup
						size="s"
						value={String(multiResponse)}
						onChange={() => handleUpdate(!multiResponse, "multiResponse")}
					>
						<Heading size="md" additionalClasses={styles.scheduleHeader}>
							How many times?
						</Heading>
						<RadioGroup.Option
							value={MultiResponse.SINGLE}
							label={
								<span>
									Creators can respond <b>only once</b>
								</span>
							}
						/>
						<RadioGroup.Option
							value={MultiResponse.MULTIPLE}
							label={
								<span>
									Creators can respond <b>more than once</b>
								</span>
							}
						/>
					</RadioGroup>
				</Card>
				<Card id="language-section">
					<Heading size="md" additionalClasses={styles.scheduleHeader}>
						What language?
					</Heading>
					<div className={styles.languageWrapper}>
						<p className={styles.languageLabel}>Creators are responding in</p>
						{languageSelect}
					</div>
				</Card>
				<Card id="schedule-section">
					<div className={styles.checkboxGroup}>
						<Heading size="md" additionalClasses={styles.scheduleHeader}>
							When should the campaign end?
						</Heading>
						{autoClosed && (
							<p className={styles.closedAt}>
								This campaign was automatically closed on {endDateLabel}
							</p>
						)}
						<Checkbox
							id="schedule-manual"
							size="s"
							checked={scheduleActiveOption.includes(ScheduleOptionIds.DO_NOT_CLOSE)}
							value={ScheduleOptionIds.DO_NOT_CLOSE}
							onChange={checked => handleSchedule(checked)}
							text={
								<span className={styles.checkText}>
									Close <b>manually</b>
								</span>
							}
						/>
						<Checkbox
							id="schedule-date"
							size="s"
							value={ScheduleOptionIds.END_DATE}
							checked={scheduleActiveOption.includes(ScheduleOptionIds.END_DATE)}
							onChange={e => handleSchedule(e)}
							text={
								<span className={styles.checkText}>
									Close on <b>specified date</b>
								</span>
							}
						/>
						{scheduleActiveOption.includes(ScheduleOptionIds.END_DATE) && (
							<div className={styles.endDateSection}>
								<DatetimeInput
									id="end-date"
									className={styles.endDate}
									value={closeAt}
									onChange={val => handleUpdate(val, "closeAt")}
									minDate={today}
								/>
								<Heading size="sm" additionalClasses={styles.scheduleSubHeader}>
									Notify Creators
								</Heading>
								<div className={styles.scheduleCheckboxes}>
									<Checkbox
										id="12"
										text={(
											<Body size="xs" type="medium">
												12 hours before campaign ends
											</Body>
										)}
										size="s"
										checked={notificationScheduledHours.includes(12)}
										onChange={handleNotifyRecipients}
										className={styles.newCheckbox}
										disabled={!closeAt}
									/>

									<Checkbox
										id="24"
										text={(
											<Body size="xs" type="medium">
												24 hours before campaign ends
											</Body>
										)}
										size="s"
										checked={notificationScheduledHours.includes(24)}
										onChange={handleNotifyRecipients}
										className={styles.newCheckbox}
										disabled={!closeAt}
									/>
								</div>
							</div>
						)}
						<Checkbox
							id="schedule-responses"
							size="s"
							value={ScheduleOptionIds.RESPONSE_LIMIT}
							checked={scheduleActiveOption.includes(ScheduleOptionIds.RESPONSE_LIMIT)}
							onChange={e => handleSchedule(e)}
							className={styles.newCheckbox}

							text={
								<span className={styles.checkText}>
									Close after a <b>set number of responses</b>
								</span>
							}
						/>
						{scheduleActiveOption.includes(ScheduleOptionIds.RESPONSE_LIMIT) && (
							<NumberInput
								id="response-limit"
								min={1}
								className={styles.responseLimit}
								value={closeAfterResponsesCountValue}
								onChange={setCloseAfterResponsesCountValue}
								label="Response limit"
								onBlur={val => handleUpdate(val, "closeAfterResponsesCount")}
							/>
						)}
					</div>
				</Card>
				<Card id="customize-section">
					<div className={styles.checkbox}>
						<Checkbox
							id="thank-you-message"
							size="s"
							checked={customThankYouMessage}
							className={styles.newCheckbox}
							onChange={handleCustomThankYouMessage}
						/>
						Add a custom thank you message?
					</div>

					{customThankYouMessage && (
						<Input
							id="thank-you-text"
							value={thankYou}
							onChange={setThankYou}
							onBlur={val => handleUpdate(val, "thankYouMessage")}
						/>
					)}

					{workspace.allowAnonymous && (
						<div className={styles.checkbox}>
							<Checkbox
								id="redirect-url-on"
								size="s"
								checked={redirectOn}
								className={styles.newCheckbox}
								onChange={handleRedirectOn}
							/>
							Redirect to another website after completion?
						</div>
					)}

					{workspace.allowAnonymous && redirectOn && (
						<>
							<Input
								id="thank-you-text"
								value={redirect}
								onChange={setRedirect}
								onBlur={val => handleUpdate(val, "redirectUrl")}
							/>
							<div className={styles.redirectTest}>
								<a href={redirect}>Click here to test the redirect address.</a>
							</div>
						</>
					)}
				</Card>
				<Card>
					<Heading size="md">Finalize</Heading>
					<div className={styles.actions}>
						<Button variant="outlined" onClick={handlePreview}>Preview</Button>
						{status === SurveyStatus.DRAFT && (
							<Button
								onClick={handlePublish}
							>
								Publish
							</Button>
						)}
						{status === SurveyStatus.CLOSED && (
							<Button
								onClick={handleOpenSurvey}
							>
								Open
							</Button>
						)}
						{status === SurveyStatus.OPEN && (
							<Button style="danger" onClick={handleUnpublish}>Close</Button>
						)}
					</div>
				</Card>
			</div>
		</div>
	);
};

export {SurveySettings};
