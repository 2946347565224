import classNames from "classnames/bind";
import React, {cloneElement, ReactElement} from "react";

import {ChevronDownIcon} from "../../../../icons";
import {Dropdown} from "../../../../shared/v2/dropdown";
import {InputElements} from "../input-elements";
import {InputProps} from "../input";
import {useThemeMode} from "../../../../context/theme-mode-context";
import {Body} from "../../typography";

import inputStyles from "../input/input.module.scss";
import styles from "./multiselect-input.module.scss";

const cx = classNames.bind(inputStyles);
const selectCx = classNames.bind(styles);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface Option<T = any> {
	id: T;
	name: string;
  tooltip?: string;
	icon?: ReactElement;
};

export interface MultiSelectInput extends Omit<
	InputProps<string>,
	"type" | "value" | "rightIcon" | "onEnter" | "onBlur"
> {
	values?: string[];
	options: Option[];
	onChange: (value: string) => void;
  popupClassName?: string;
}

export const MultiSelectInput = ({
	className,
	size = "small",
	variant = "default",
	label,
	hintText,
	validation,
	options,
	values,
	leftIcon,
	onChange,
	placeholder,
	popupClassName,
	...props
}: MultiSelectInput): ReactElement => {
	const innerVariant = validation?.isError ? "error" : variant;
	const {isDarkMode} = useThemeMode();

	return (
		<InputElements
			className={className}
			filledIcon
			hintText={hintText}
			label={label}
			rightIcon={<ChevronDownIcon />}
			validation={validation}
			variant={innerVariant}
		>
			<Dropdown
				space={8}
				position="bottom-start"
				sameWidth
				triggerClassName={selectCx("trigger", innerVariant, {isDarkMode})}
				popupClassName={styles.popup}
				popupContentClassName={selectCx("popupContent", popupClassName)}
				trigger={
					<>
						{leftIcon && cloneElement(leftIcon, {className: cx("icon", "leftIcon", size, innerVariant)})}
						<div
							className={cx(
								"input",
								styles.input,
								size,
								innerVariant,
								leftIcon && "withLeftIcon",
								"withRightIcon",
							)}
							tabIndex={-1}
							readOnly
							{...props}
						>
							<Body className={selectCx("inputValue", {selected: Boolean(values?.length), isDarkMode})}>{placeholder}</Body>
							{Boolean(values?.length) && <div className={selectCx("selectIndicator", {isDarkMode})}>
								<Body className={styles.count} size="xs" type="bold">{values?.length}</Body>
							</div>
							}
						</div>
					</>
				}
				items={
					options?.map((option) => ({
						label: option.name,
						onClick: () => onChange(option.id),
						tooltip: option.tooltip,
						icon: option.icon,
						type: "checkbox",
						active: values?.includes(option.id),
					}))
				}
			/>
		</InputElements>
	)
}
