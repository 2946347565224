/* eslint-disable react/prop-types */
import React, {ReactElement} from "react";
import MaskComponent from "./mask";
import {Modal} from "../../shared/v2";

const ImageEditorModal = ({src, isShowing, setIsShowing}): ReactElement => {
	return (
		<Modal title="Image Studio" portal isOpen={isShowing} onClose={() => setIsShowing(false)} size="mask">
			<MaskComponent src={src} />
		</Modal>
	);
};

export default ImageEditorModal;
