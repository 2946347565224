import React, {ReactNode} from 'react';
import classNames from 'classnames/bind';

import styles from './create-card.module.scss';
import {useThemeMode} from '../../../../context/theme-mode-context';

const bStyles = classNames.bind(styles)

export interface CreateCardProps {
	children: ReactNode;
	wrapperClassname?: string;
	contentClassname?: string;
}

export const CreateCard = ({children, wrapperClassname, contentClassname}: CreateCardProps) => {
	const {isDarkMode} = useThemeMode();

	return (
		<div className={bStyles("cardWrapper", wrapperClassname, {isDarkMode})}>
			<div className={bStyles("content", contentClassname, {isDarkMode})}>
				{children}
			</div>
		</div>
	);
}
