import React, {ReactElement} from "react";
import {Setter} from "../../../types";
import {Select, Option} from "../select";
import styles from "./emailer.module.scss";
import {Input, TextArea} from "../../../shared/v2/inputs";

export interface EmailerProps {
	message: string;
	from: string;
	jobTitle: string;
	selectOptions: Option<string | undefined>[];
	setMessage: Setter<string>;
	setJobTitle: Setter<string>;
	setFrom: Setter<string>;
	anonymous?: boolean;
}

const Emailer = ({
	message,
	from,
	jobTitle,
	selectOptions,
	setJobTitle,
	setFrom,
	setMessage,
	anonymous,
}: EmailerProps): ReactElement => (
	<>
		<TextArea
			label="Add custom message (optional)"
			className={styles.customMessage}
			value={message}
			onChange={setMessage}
		/>
		{!anonymous &&
		<div className={styles.detailsWrapper}>
			<Select
				id="from-select"
				label="From"
				options={selectOptions}
				onChange={setFrom}
				selectedValue={from}
			/>
			<Input
				id="job-title"
				label="Job Description / Title"
				value={jobTitle}
				onChange={setJobTitle}
			/>
		</div>
		}
	</>
);

export {Emailer};
