import React, {ReactElement, useContext} from "react";
import {SurveyQr} from "../../components/survey-qr";
import {SurveyContext} from "../../../context/survey-context";
import config from "../../../config";
import styles from "./qr-modal.module.scss";
import {BaseModalProps, Modal} from "../../../shared/v2";

const QrModal = ({isOpen, onClose}: BaseModalProps): ReactElement => {
	const {survey: {name: surveyName, id: surveyId}} = useContext(SurveyContext);
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size="x-small"
			title="Download QR Code"
		>
			<div className={styles.container}>
				<SurveyQr
					url={`${config.responder}/#/${surveyId}?qrcode=true`}
					name={surveyName}
				/>
			</div>
		</Modal>
	);
};

export {QrModal};
