import React, {ReactElement} from "react";
import {convertToClockTime} from "../../../shared/utility/utility";
import styles from "./reel-timer.module.scss";

export interface ReelTimerProps {
	/**
	 * The end time
	 */
	endTime: number | undefined;
}

/**
 * Timer for the reel. Gonna be a bit awkward to determine times.
 * @param endTime Ending time for the timer
 */
const ReelTimer = (props: ReelTimerProps): ReactElement => <div className={styles.container}>
	{convertToClockTime(0, "milliseconds")}/{
		props.endTime ? convertToClockTime(props.endTime, "milliseconds") : "0:00"
	}
</div>;

export {ReelTimer};
