import {useMutation} from "@apollo/client";
import React, {ReactElement, useContext} from "react";
import {Descendant} from "slate";
import {SurveyContext} from "../../../context/survey-context";
import {UPDATE_SURVEY} from "../../../graphql/mutations/survey-mutations";
import {Panel} from "../../../shared/components/panel";
import {RichTextInput} from "../../../shared/components/rich-text-input";
import styles from "./details-panel.module.scss";

export interface DetailsPanelProps {
	isShowing: boolean;
	toggleShowing: () => void;
}

const DetailsPanel = ({isShowing, toggleShowing}: DetailsPanelProps): ReactElement | null => {
	const {survey} = useContext(SurveyContext);
	const [updateSurvey] = useMutation(UPDATE_SURVEY);


	const handleUpdateObjectives = (newValue: Descendant[]): void => {
		const content = JSON.stringify(newValue);
		updateSurvey({variables: {id: survey.id, changes: {objectives: content}}});
	};

	if (!isShowing) return null;
	return (
		<Panel title="Details" theme="blue" handleClose={toggleShowing}>
			<div className={styles.content}>
				<h3>Objectives</h3>
				<RichTextInput
					text={survey.objectives || ""}
					onDoneEditing={handleUpdateObjectives}
					placeholder={"Enter your key objectives..."}
					className={styles.help}
					toolbarPosition="right"
				/>
			</div>
		</Panel>
	);
};

export {DetailsPanel};
