import React, {ReactElement, useMemo, useState} from "react";
import {useQuery} from "@apollo/client";

import {ModelGroup} from "../../components/model-group";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {GET_AI_MODEL_CATEGORIES, GET_AI_MODELS} from "../../../graphql/queries/ai-models-queries";
import {AIModelCategory, AIModel, CategoryWithAIModels} from "../../../models/ai-model";
import {SearchInput,  LoadingContainer} from "../../../shared/v2";

import styles from "./ai-models-page.module.scss";

export const AiModelsPage = (): ReactElement => {
	const {workspace: {id: workspaceId}} = useWorkspaceContext();

	const [searchValue, setSearchValue] = useState<string>("");

	const {
		data: {
			aiModelCategories: modelCategories = [] as AIModelCategory[],
		} = {},
		loading: isModelCategoriesLoading,
	} = useQuery(GET_AI_MODEL_CATEGORIES);

	const {
		data: {
			workspaceAiModels: aiModelsData = [] as AIModel[],
		} = {},
		loading: isModelDataLoading,
	} = useQuery(GET_AI_MODELS, {
		variables: {
			workspaceId,
		},
	});

	const aiModels: AIModel[] = useMemo(() => {
		return aiModelsData.map(({aiModel}) => aiModel as AIModel);
	}, [aiModelsData]);

	const categoriesWithModels = useMemo(() => {
		const categories = modelCategories
			.reduce((acc: CategoryWithAIModels[], category) => {
				const models = aiModels.filter(model => {
					return model.modelCategory.id === category.id;
				});
				if (!models.length) {
					return acc;
				}
				acc.push({
					...category,
					aiModels: models,
				});
				return acc;
			}, []);

		return categories.filter(Boolean);
	}, [aiModels, modelCategories]);

	const filteredCategoriesWithModels = useMemo(() => {
		if (!searchValue) {
			return categoriesWithModels;
		}

		return categoriesWithModels.map(category => {
			const models = category.aiModels.filter(model => model.name.toLowerCase()
				.includes(searchValue.toLowerCase()));
			return {
				...category,
				aiModels: models,
			};
		});
	}, [categoriesWithModels, searchValue]);

	const handleSearchChange = (value: string):void => {
		setSearchValue(value);
	};

	const renderModels = (): ReactElement => {
		if (isModelCategoriesLoading || isModelDataLoading) {
			return <LoadingContainer />;
		}

		return <div className={styles.modelGroups}>
			{filteredCategoriesWithModels.map((category, index) => (
				<ModelGroup key={index} group={category} />
			))}
		</div>;
	};

	return <>
		<div className={styles.fullWidth}>
			<SearchInput
				className={styles.searchInput}
				value={searchValue}
				onChange={handleSearchChange}
			/>

			{renderModels()}
		</div>
	</>;
};
