import {useMutation} from "@apollo/client";
import React, {ReactElement, useContext, useRef} from "react";

import {Checkbox} from "../../../shared/v2";
import {LIKE_ANSWER, UNLIKE_ANSWER} from "../../../graphql/mutations/survey-mutations";
import {Link} from "../../../route";
import {SearchAnswer} from "../../../models/answer";
import {SelectContext} from "../../../context/select-context";
import {Transcript} from "../../../modal-components/transcript";
import {VideoOptions} from "../../../modal-components/video-options";
import {VideoSwapper} from "../../../shared/components/video-swapper";

import styles from "./video-result.module.scss";

export interface VideoResultProps {
	answer: SearchAnswer;
	search?: string;
}

const VideoResult = ({answer, search}: VideoResultProps): ReactElement => {
	const ref = useRef<HTMLDivElement>(null);
	const {selected, setSelected} = useContext(SelectContext);
	const [likeAnswer] = useMutation(LIKE_ANSWER);
	const [unlikeAnswer] = useMutation(UNLIKE_ANSWER);

	const handleLike = (): void => {
		likeAnswer({variables: {answerId: answer.id}});
	};

	const handleUnlike = (): void => {
		unlikeAnswer({variables: {answerId: answer.id}});
	};

	return (
		<div className={styles.answerContainer} key={answer.id}>
			<div className={styles.leftColumn}>
				<VideoSwapper
					ref={ref}
					answerId={answer.id}
					checkbox={<Checkbox
						id={answer.id}
						checked={selected.includes(answer.id)}
						value={answer.id}
						size="s"
						onChange={setSelected}
					/>}
					video={answer.video}/>
				<VideoOptions
					answer={answer}
					like={handleLike}
					unlike={handleUnlike}
				/>
			</div>
			<div>
				<div className={styles.author}>
					<h4 className={styles.author}>
						{answer.user.firstName} {answer.user.lastInitial}.
					</h4>
				</div>
				<div className={styles.from}>
					<Link
						workspace
						to={`/survey/${answer.question.survey?.id}/results/${answer.question?.id}`}
					>
						From &quot;{answer.question.survey.name}&quot; - Question {answer.question.index + 1}
					</Link>
					<div className={styles.tooltip}>
						{answer.question.text}
					</div>
				</div>

				<div className={styles.transcript}>
					<Transcript answerId={answer.id} transcript={answer.video.transcript} search={search}/>
				</div>
			</div>
		</div>
	);
};

export {VideoResult};
