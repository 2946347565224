 
import React, {ReactElement} from "react";
import styles from "./delete-response.module.scss";
import {Body, Button, Subheader, Modal, BaseModalProps} from "../../shared/v2";

export interface DeleteResponseModalProps extends BaseModalProps {
	/**
	 * What happens when clicking "confirm"
	 */
	confirm: () => void;
	/**
	 * Used to determine what text to have.
	 */
	isDeleteAll?: boolean;
}

const DeleteResponseModal = (props: DeleteResponseModalProps): ReactElement => {
	const {isOpen, confirm, onClose, isDeleteAll = false} = props;
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size="x-small"
			title={isDeleteAll ? "Remove User and Responses" : "Delete Response"}
		>
			<div className={styles.container}>
				<Subheader size="l" type="semibold" className={styles.header}>
					{isDeleteAll &&
					"Are you sure you want to remove this response from the vurvey?"
					}
					{!isDeleteAll && "Are you sure you want to delete this response?"}
				</Subheader>
				{!isDeleteAll && <Body size="xs" className={styles.text}>
					Once you delete, this creator&apos;s response to this question will be permanently removed
				</Body>
				}
				<Button onClick={confirm} style="danger">Delete</Button>
			</div>
		</Modal>
	);
};

export {DeleteResponseModal};
