import React, {ReactElement, useCallback, useEffect, useState} from "react";
import {BaseModalProps, Body, Button, ButtonIcon, Caption, Modal, RadioGroup} from "../../../shared/v2";
import styles from "./upload-csv.module.scss";
import classNames from "classnames/bind";
import {useThemeMode} from "../../../context/theme-mode-context";
import {useDropzone} from "react-dropzone";
import {useToastContext} from "../../../context/toast-context";
import {UploadCircles} from "../../../shared/v2/modals/upload-files-modal/upload-circles";
import {RuleAction} from "../../../models/attribute";
import {BinDeleteIcon, FileTextIcon} from "../../../icons";

const bStyles = classNames.bind(styles);

export interface UploadCSVModalProps extends BaseModalProps {
	uploadCallback: (file: File, ruleAction: RuleAction) => void;
	loading: boolean;
}

const UploadCSVModal = (props: UploadCSVModalProps): ReactElement => {
	const {uploadCallback, loading, ...rest} = props;
	const {isDarkMode} = useThemeMode();
	const {updateToast} = useToastContext();
	const [file, setFile] = useState<File | undefined>();
	const [action, setAction] = useState(RuleAction.ADD);

	const onDrop = useCallback((acceptedFiles: File[]) => {
		if (acceptedFiles.length > 1) {
			updateToast({
				description: "You may only upload a single file",
				type: "failure",
			});
			return
		}
		setFile(acceptedFiles[0])
	}, []);
	const {getRootProps, getInputProps, isDragActive} = useDropzone({
		onDrop,
		accept: {
			"text/csv": [],
			"text/plain": [],
		},
		onDropRejected: () => {
			updateToast({
				description: "Unsupported file type",
				type: "failure",
			});
		},
	});

	const handleUpload = (): void => {
		if (file) uploadCallback(file, action);
	};

	const handleRemove = (): void => setFile(undefined);

	const downloadFile = () => {
		const link = document.createElement('a');
		link.href = '/examples/property-upload-example.csv';
		link.download = 'example.csv';

		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	useEffect(() => {
		if (!rest.isOpen) setFile(undefined);
	}, [rest.isOpen]);
	return (
		<Modal {...rest} title="CSV Upload">
			<Body>
				The format for CSV should be:<br/>
				EMAIL_OR_CREATOR_TAG,ATTR_NAME,ATTR_VALUE
			</Body>
			<Button onClick={downloadFile} variant="text">
        Download example CSV file
			</Button>
			{file && <div className={styles.file}>
				<FileTextIcon className={styles.textIcon}/>
				<Caption size="l" color="text-body">{file.name}</Caption>
				<ButtonIcon
					icon={<BinDeleteIcon className={styles.deleteIcon} />}
					onClick={handleRemove}
				/>
			</div>
			}
			{!file &&
				<div
					className={bStyles("dragAndDrop", {isDarkMode, isDragActive})}
					id="drag-n-drop"
					{...getRootProps()}
				>
					<input {...getInputProps()} />
					<UploadCircles />

					<div className={styles.text}>
						<Body color="text-secondary">
							<span className={bStyles("important", {isDarkMode})}>
								Click to upload
							</span> or drag and drop.
						</Body>
					</div>
				</div>
			}
			<RadioGroup
				onChange={setAction}
				value={action}
				className={styles.radioGroup}
			>
				<RadioGroup.Option value={RuleAction.ADD} label="Add values"/>
				<RadioGroup.Option value={RuleAction.SET} label="Replace values" />
			</RadioGroup>
			<Button onClick={handleUpload} disabled={!file || loading} className={styles.button}>Upload</Button>
		</Modal>
	)
};

export {UploadCSVModal};
