import classNames from "classnames/bind";
import React, {ReactElement, useState} from "react";

import {PhotoCameraIcon} from "../../../icons";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./image-drop-area.module.scss";
import {useSafeFetchFile} from '../../../hooks/useSafeFetchFile';

const cx = classNames.bind(styles);

export type ImageDropAreaDummyProps = {
  url: string;
  size?: "sm" | "md";
  className?: string;
  onClick: () => void;
};

export const ImageDropAreaDummy = ({
	url,
	size = "md",
	className,
	onClick,
}: ImageDropAreaDummyProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	const [file, setFile] = useState<File | null>(null);
	useSafeFetchFile({url, setFile});

	return (
		<div
			className={cx("dropzone", `size-${size}`, className, {isDarkMode, hasFile: Boolean(url)})}
			onClick={onClick}
		>
			<div className={styles.iconOuterLayer}>
				<div className={styles.iconInnerLayer}>
					<PhotoCameraIcon className={styles.icon} />
				</div>
			</div>

			{file && (
				<>
					<img
						alt="preview"
						className={styles.preview}
						src={URL.createObjectURL(file)}
					/>
					<div className={styles.previewHoverEffect} />
				</>
			)}

			<div className={styles.customBorder} />
		</div>
	);
};
