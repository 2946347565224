import React, {ReactElement} from "react";
import {QRCode} from "react-qrcode-logo";
import {Button} from "../../../shared/v2";
import {DownloadIcon} from "../../../icons";
import styles from "./survey-qr.module.scss";

export interface SurveyQrProps {
	/**
	 * URL for the QR code
	 */
	url: string;
	/**
	 * Name of the survey, used to generate name of the image saved.
	 */
	name: string;
}

const SurveyQr = (props: SurveyQrProps): ReactElement => {
	const {url, name} = props;
	/**
	 * Creates an invisible link and clicks it to start a download.
	 * The ID of the canvas element is always react-qrcode-logo. Cannot change it.
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const downloadQr = (): any => {
		const canvas: HTMLCanvasElement =
		document.getElementById("react-qrcode-logo") as HTMLCanvasElement;
		const pngUrl = canvas.toDataURL("image/png")
			.replace("image/png", "image/octet-stream");
		const downloadLink = document.createElement("a");
		downloadLink.href = pngUrl;
		downloadLink.download = `${name} QR code.png`;
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};

	return (
		<div className={styles.container}>
			<QRCode
				value={url}
				size={500}
				quietZone={10}
				logoImage="images/vurvey-qrcode.png"
				qrStyle="dots"
				ecLevel="H"
				logoWidth={190}
				eyeRadius={[
					[50, 50, 0, 50],
					[50, 50, 50, 0],
					[50, 0, 50, 50],
				]}
			/>
			<Button
				onClick={downloadQr}
				leftIcon={<DownloadIcon />}
				className={styles.download}
			>
				Download
			</Button>
		</div>
	);
};

export {SurveyQr};
