/* eslint-disable*/
import React, {ReactElement, useMemo, useState} from "react";
import {BarChart, ResponsiveContainer, Bar, XAxis, YAxis, Text, Surface, Symbols, Legend, Tooltip} from "recharts";
import {Body} from "../../v2";
import {Column} from "react-table";
import {ChoiceQuestion} from "../../../models/questions";
import {convertToRechartData} from "../../utility/rechart-utility";
import {getOrdinal} from "../../utility/utility";
import {Table} from "../table";
import styles from "./ranked-bar-chart.module.scss";
import {Choice} from "../../../models/choice";

interface StackedBarChartProps {
	question: ChoiceQuestion;
	copyButton?: JSX.Element;
}

export interface RechartBarData {
	name: string;
	average: number; // For our uses, not displayed in bars.
	mode: string[];
	[key: number]: number;
}
// Turns out we actually just want 12 distinct colors total, oops.
const rankedColors = [
	"#58CB88",
	"#8FDB9F",
	"#AEE5AE",
	"#D9F0B4",
	"#F2F3B3",
	"#FBF2B4",
	"#FDE8B1",
	"#FDDDA8",
	"#FCC89D",
	"#F8A887",
	"#F2826D",
	"#EB5757",
];

/**
 * @returns [totalAnswered, totalSkipped]
 */
const determineRange = (data: Choice[], answerCount: number, required: boolean): [number, number] => {
	if (required) return [answerCount, 0];
	// We only need to check the frequency array of one answer to know total answered here
	const totalAnswered = data[0].frequency.reduce((a, b) => a + b);
	const totalSkipped = answerCount - totalAnswered;
	return [totalAnswered, totalSkipped];
};

/**
 * Rechart docs to learn more about everything being done in this file can be found
 * https://recharts.org/en-US/api
 */
const CustomTooltip = (props: any): ReactElement | null => {
	if (!(props.active && props.payload && props.payload.length)) return null;
	const {payload, label} = props;
	return (
		<div className={styles.tooltip}>
			<p className={styles.topTooltip}>{label}</p>
			{payload.map(pay =>
				<p
					className={styles.tooltipVal}
					key={`${pay.dataKey}-${pay.name}`}
					style={{color: pay.fill}}
				>
					{pay.dataKey}{getOrdinal(pay.dataKey)}: {pay.value}
				</p>)}
		</div>
	);
};


const CustomizedTick = (tickInfo: any): ReactElement => <>
	<Text x={tickInfo.x - 15} y={tickInfo.y} fontSize={13} fill="var(--batterii-black)" fontWeight={600}>
		{tickInfo.index + 1}
	</Text>
	<Text x={tickInfo.x + 10} y={tickInfo.y - 15} fill="var(--batterii-black)" fontSize={13} textAnchor="start" width={300} maxLines={2}>
		{tickInfo.payload.value}
	</Text>
	<text x="99%" textAnchor="end" y={tickInfo.y - 15} fontSize={13}>
		Average ranking: {tickInfo.data[tickInfo.index].average}
	</text>
</>;


const CustomLegend = (props: any): ReactElement =>{
	const {payload, dataLength} = props;
	return <div className={styles.legendContainer}>
		<span>First choice</span>
		{payload.map((entry, i: number) => {
			if (i >= 12) return null;
			return <Surface className={styles.square} key={entry.dataKey} width={15} height={15} viewBox={{x: 0, y: 0, width: 32, height: 32}}>
				<Symbols cx={10} cy={10} type="square" size={30} sizeType="diameter" fill={dataLength > 12 ? rankedColors[i] : entry.color}/>
			</Surface>;
		})}
		<span>Last choice</span>
	</div>;
};

const RankedBarChart = React.forwardRef<HTMLDivElement, StackedBarChartProps>((props, ref): ReactElement => {
	const {question: {choices, answerCount, required}} = props;
	const [showTable, setShowTable] = useState(false);

	const toggleShowTable = (): void => setShowTable(prev => !prev);
	const [totalAnswered, totalSkipped] = determineRange(choices, answerCount, required);
	// Expensive, so memo
	const barData = useMemo(() => convertToRechartData(choices, totalAnswered), [choices, answerCount]);
	const columns = useMemo((): Column<RechartBarData>[] => ([
		{
			Header: "Option",
			accessor: "name",
			Cell: ({value}) => <div className={styles.nameCell}>
				{value}
			</div>,
		},
		{
			id: "All values",
			Header: "Values",
			Cell: ({row: {original}}) => {
				const keys = Object.keys(original);
				return <div className={styles.valueCell}>
					{keys.map((key, i) => {
						if (key === "average" || key === "name" || key === "mode") return null;
						if (original[key] === 0 || original[key] === null) return null;
						return <p className={styles.value} key={`${key}-${i}`}>
							{key}{getOrdinal(Number(key))}: {original[key]}
						</p>;
					})}
				</div>;
			},
		},
		{
			Header: "Average",
			accessor: "average",
			maxWidth: 50,
		},
		{
			Header: "Mode",
			accessor: "mode",
			Cell: ({value}) => value.map(v => `${v}${getOrdinal(Number(v))} `),
		},
	]), [barData]);
	return (
		<div className={styles.container} ref={ref}>
			<header className={styles.chartHeader}>
				<h3 className={styles.title}>&quot;{barData[0].name}&quot; was the top ranked choice</h3>
				{props.copyButton}
			</header>
			<button className={styles.toggler} onClick={toggleShowTable} data-html2canvas-ignore>
				{showTable ? "Show chart" : "Show table"}
			</button>
			{
				showTable ? <Table
					columns={columns}
					data={barData}
				/> :
					<ResponsiveContainer width="100%" height={66 * choices.length}>
						<BarChart
							data={barData}
							layout="vertical"
							barSize={20}
						>
							<XAxis type="number" domain={[0, totalAnswered]}/>
							<YAxis
								width={20}
								type="category"
								axisLine={false} dataKey="name"
								tick={<CustomizedTick data={barData}/>}
								tickLine={false}
							/>
							<Tooltip
								content={<CustomTooltip data={barData}/>}
								contentStyle={{borderColor: "var(--batterii-purple)", backgroundColor: "var(--batterii-grey-medium)", borderRadius: 6}}/>
							{barData.map((value, i) => {
								const colorIndex = Math.ceil(i * (12 / (barData.length - 1)));
								return <Bar
									dataKey={i + 1}
									stackId="a"
									name={value.name}
									fill={rankedColors[colorIndex ? colorIndex - 1 : colorIndex]}
									key={`${value.name}+${i}`}
									style={{stroke: "#fff", strokeWidth: 2}}
								/>;
							})}
							<Legend content={<CustomLegend dataLength={barData.length}/>}/>
						</BarChart>
					</ResponsiveContainer>
			}
			{totalSkipped > 0 &&
				<Body className={styles.skipped}>{totalSkipped} users skipped this question</Body>
			}
		</div>
	);
});

RankedBarChart.displayName = "RankedBarChart";

export {RankedBarChart};
