import {
	CREATE_QUESTION_ZIP,
	LIKE_ANSWER,
	UNLIKE_ANSWER,
} from "../../../graphql/mutations/survey-mutations";
import {
	CreateZipReturn,
	GetZipQueryReturn,
	Question,
	ZipFileStatus,
} from "../../../models/questions";
import React, {ReactElement, useCallback, useContext, useState} from "react";
import {DELETE_ANSWERS} from "../../../graphql/mutations/results-mutations";
import {Answer, AnswerPage, AnswerPdf, UploadStatus} from "../../../models/answer";
import {ResponseContext} from "../../../context/response-context";
import {TranscriptModalContext} from "../../../context/transcript-modal-context";
import {convertTimeFromMiliseconds, convertToClockTime, isSupportOrEnterpriseRole} from "../../../shared/utility/utility";
import styles from "./answer-table.module.scss";
import {useMutation} from "@apollo/client";
import {GET_QUESTION_ZIP} from "../../../graphql/queries/survey-queries";
import {useLoadingQuery} from "../../../hooks";
import {updateCacheDeletePageItemByFieldId} from "../../../shared/utility/update-cache";
import {DropdownOptions, HeartIcon, Icon, ImageModal, Options} from "../../../shared";
import TimeAgo from "react-timeago";
import {ReelAdder} from "../reel-adder";
import {Highlighter} from "../../../shared/components/highlighter";
import {Transcript} from "../../../modal-components/transcript";
import {UserContext} from "../../../context/user-context";
import {CreateModalContext} from "../../../context/create-modal-context";
import {SurveyContext} from "../../../context/survey-context";
import {ToggleSwitch} from "../../../shared/components/toggle-switch";
import {Button, Spinner} from "../../../shared/v2";

export interface AnswerTableProps {
	question: Question;
	answers?: AnswerPage;
	searchValue?: string;
	addClip: (reelId: string, answerId: string, endTime: number) => void;
}

/**
 * An attempt to simplify the tables into one big table that can do it all.
 * Might still be overcomplicated, but I think it's a decent start at least
 */

const AnswerTable = (props: AnswerTableProps): ReactElement | null => {
	const {question, answers, searchValue, addClip} = props;
	const [fullImage, setFullImage] = useState("");
	const [creatingZip, setCreatingZip] = useState(false);
	const [showTranslation, setShowTranslation] = useState(false);
	const {questionId} = useContext(ResponseContext);
	const {setAnswerId} = useContext(TranscriptModalContext);
	const {survey: {language}} = useContext(SurveyContext);
	const {user: {role}} = useContext(UserContext);
	const {setCopyAnswerId} = useContext(CreateModalContext);

	const [likeAnswer] = useMutation(LIKE_ANSWER);
	const [unlikeAnswer] = useMutation(UNLIKE_ANSWER);
	const [deleteAnswer] = useMutation(DELETE_ANSWERS);
	const [createZip] = useMutation<CreateZipReturn>(CREATE_QUESTION_ZIP);

	const canCreateZip = question.subtype === "picture" || question.subtype === "pdf";
	const canCopyAnswer = isSupportOrEnterpriseRole(role);
	const hasTranslation = !language.name.toLocaleLowerCase().includes("english");

	const {data: upload, startPolling, fragment, stopPolling} =
	useLoadingQuery<GetZipQueryReturn>(GET_QUESTION_ZIP, {
		variables: {id: questionId},
		skip: !canCreateZip,
		what: "zip file",
		notifyOnNetworkStatusChange: true,
		fetchPolicy: "cache-and-network",
		onCompleted: zip => {
			if (zip.question.zipFile?.uploadStatus !== ZipFileStatus.UPDATING &&
					zip.question.zipFile?.uploadStatus !== ZipFileStatus.UPLOADING) {
				stopPolling();
				setCreatingZip(false);
			}
		},
	});

	const handleLike = (answerId: string): void => {
		likeAnswer({variables: {answerId}});
	};
	const handleDislike = (answerId: string): void => {
		unlikeAnswer({variables: {answerId}});
	};

	const handleAddClip = (reelId: string, answer): void => {
		addClip(reelId, answer.id, convertTimeFromMiliseconds(answer.video.duration, "seconds"));
	};

	const handleToggle = (e: React.ChangeEvent<HTMLInputElement>): void => setShowTranslation(e.target.checked);

	const handleDelete = (answerId: string): void => {
		deleteAnswer({
			variables: {ids: answerId},
			update(cache, {data: deleteData}) {
				if (deleteData) {
					deleteData.deleteAnswers.forEach(answer => {
						updateCacheDeletePageItemByFieldId(
							cache,
							"answers",
							"answer",
							questionId as string,
							answer.id,
						);
					});
				}
			},
		});
	};

	const handleZip = (): void => {
		createZip({
			variables: {questionId},
			onCompleted: () => {
				setCreatingZip(true);
				startPolling?.(3000);
			},
		});
	};

	/**
	 * Takes the pdf file and renders the under text depending on the status
	 * of the upload.
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const renderPdfSafely = ({uploadStatus, url}: AnswerPdf): any => {
		switch (uploadStatus) {
		case UploadStatus.SUSPICIOUS:
			return <div className={styles.sus}>
				<Icon name="warning-second" size="extrasmall" fill="var(--batterii-red)"/>
				<span>Flagged as suspicious</span>
			</div>;
		case UploadStatus.UPDATING:
		case UploadStatus.UPLOADING:
			return <span style={{color: "var(--batterii-purple)"}}>Uploading...</span>;
		case UploadStatus.SCANNING:
			return <span style={{color: "#FF7700"}}>Scanning for threats...</span>;
		case UploadStatus.FAILED:
			return <span><Icon name="cross" fill="var(--batterii-red)" />Failed to upload</span>;
		case UploadStatus.OUTDATED:
			return <div>Outdated</div>;
		case UploadStatus.SUCCEEDED:
			return <a className={styles.link}
				href={url}
				target="_blank"
				rel="noreferrer noopener">
						View pdf
			</a>;
		}
	};

	const createOptions = useCallback((answer: Answer): DropdownOptions[] => {
		const options: DropdownOptions[] = [
			{
				name: "Delete",
				actionOptions: {onClick: () => handleDelete(answer.id)},
				icon: "trash",
			}
		];
		if (!canCopyAnswer || !question.videoResponse) return options;
		options.push({
			name: "Create Story",
			actionOptions: {onClick: () => setCopyAnswerId(answer.id)},
			icon: "book",
		});
		return options;
	}, [])

	const hasAnswers = answers && answers.items.length > 0;
	if (!hasAnswers) return null;
	return (<>
		<div className={styles.zipAction}>
			{fragment || creatingZip ? <Spinner /> :
				(upload &&
						(upload.question.zipFile?.uploadStatus === ZipFileStatus.SUCCEEDED
							|| upload.question.zipFile?.uploadStatus === ZipFileStatus.OUTDATED) &&
					<div className={styles.zip}>
						<Icon name="download" size="extrasmall"/>
						<a href={upload.question.zipFile.url}>{upload.question.zipFile.originalFilename}</a>
						{upload.question?.zipFile?.uploadStatus === ZipFileStatus.OUTDATED &&
						<span className={styles.outdated}>File outdated. Create new zip file to update it</span>
						}
					</div>)
			}
			{canCreateZip &&
				<Button
					onClick={handleZip}
					className={styles.zipButton}
					disabled={creatingZip ||
						(upload?.question.zipFile?.uploadStatus === ZipFileStatus.SUCCEEDED ||
							upload?.question.zipFile?.uploadStatus === ZipFileStatus.UPLOADING)}
				>
					Create zip of uploads
				</Button>
			}
		</div>
		<div className={styles.container}>
			{hasTranslation &&
			<div className={styles.translationToggle}>
				<span>Translate to English for all available</span>
				<ToggleSwitch id="toggle-all-english" isChecked onChange={handleToggle} />
				<span><i>* Highlighting unavailable for translated transcripts</i></span>
			</div>
			}
			{answers.items.map(answer => !answer.skipped && <div key={answer.id} className={styles.grid}>
				<div className={styles.nameAndActions}>
					<span className={styles.name}>
						{answer.user.creatorTag} {answer.user.firstName && `(${answer.user.firstName} ${answer.user.lastInitial}.)`}, <TimeAgo live={false}
							date={answer.createdAt as Date}/>
					</span>
					<div className={styles.actions}>
						{answer.video && <ReelAdder
							addClip={reelId => handleAddClip(reelId, answer)}
							answerId={answer.id}
						/>}
						{!answer.skipped && <HeartIcon
							isLiked={Boolean(answer.likedAt)}
							like={() => handleLike(answer.id)}
							unlike={() => handleDislike(answer.id)}
						/>}
						<Options
							options={createOptions(answer)}
							type="menu-vertical"
						/>
					</div>
				</div>
				{/** Below are current checks for all different kinds of data we get on an answer */}
				{answer.barcodes && answer.barcodes.length > 0 &&
					<div className={styles.barcodes}>
						{answer.barcodes?.map(barcode => <div key={barcode.id} className={styles.product}>
							<span className={styles.name}>{barcode.product?.title ?? barcode.barcode}</span>
							<img className={styles.img} src={barcode.product?.images[0]}/>
						</div>)}
					</div>
				}
				{answer.text && <Highlighter
					text={answer.text}
					searchValue={searchValue}
					className={styles.text}
					highlightClassName={styles.highlight}
				/>}
				{answer.numberAnswer && <p className={styles.text}>{answer.numberAnswer}</p>}
				{answer.uploadedImages && answer.uploadedImages.length > 0 &&
						<div className={styles.imageGrid}>{answer.uploadedImages.map(image => <div
							className={styles.imageAnswer}
							key={image.thumbnail}
						>
							<img key={image.thumbnail} src={image.thumbnail} alt={image.originalFilename}/>
							<span className={styles.imgLink} onClick={() => setFullImage(image.original)}>
								{image.originalFilename}
							</span>
						</div>)}
						</div>
				}
				{answer.uploadedPdfs && answer.uploadedPdfs.length > 0 &&
					<div className={styles.imageGrid}>
						{answer.uploadedPdfs.map(pdf => <div key={pdf.id} className={styles.pdf}>
							<span className={styles.pdfName}>{pdf.originalFilename}</span>
							{renderPdfSafely(pdf)}
						</div>)}
					</div>
				}
				{
					answer.video && <div className={styles.videoContainer}>
						<div onClick={() => setAnswerId(answer.id)}>
							<img
								className={styles.videoThumbnail}
								src={answer.video.thumbnail}
								alt="video-thumbnail"
							/>
						</div>
						<div>
							<span onClick={() => setAnswerId(answer.id)} className={styles.videoDuration}>
								Video (
								{convertToClockTime(answer.video?.duration, "milliseconds")}
								)
							</span>
							<Transcript
								answerId={answer.id}
								search={searchValue}
								transcript={answer.video?.transcript}
								showTranslation={showTranslation}
							/>
						</div>
					</div>
				}
			</div>)}
		</div>
		<ImageModal
			image={fullImage}
			isShowing={Boolean(fullImage)}
			handleClose={() => setFullImage("")}
			isVideo={false}
		/>
	</>
	);
};

export {AnswerTable};
