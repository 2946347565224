import React, {ReactElement} from "react";
import {Icon} from "../icon";
import styles from "./tag.module.scss";
import classNames from "classnames";

export interface TagProps {
	/**
	 * The text rendered
	 */
	children: React.ReactNode;
	/**
	 * Shows an "X" if tag is deletable
	 */
	isDeletable?: boolean;
	/**
	 * What to do when tag is clicked
	 */
	clicked?: (e: React.MouseEvent<HTMLSpanElement>) => void;
	/**
	 * Color, if we want to override default (grey)
	 */
	color?: string;
}
/**
 *
 * @param children - Tag name
 * @param isDeletable - Denotes if tag can be deleted from container
 *
 * @returns ReactElement - a styled tag to place on elements or to
 * search on
 */
const Tag = (props: TagProps): ReactElement => {
	const {isDeletable, clicked} = props;
	return (
		<span
			className={classNames(styles.tag, styles.purple, clicked && styles.clickable)}
			onClick={e => clicked?.(e)}
		>
			{props.children}
			{isDeletable && <Icon name="cross" fill="var(--color-text-body)" size="tiny" />}
		</span>
	);
};

export {Tag};
