import React, {ReactElement} from "react";
import {DragItemTypes} from "../../constants/constants";
import {useDragLayer, XYCoord} from "react-dnd";
import styles from "./custom-drag-layer.module.scss";
import {QuestionCardDragPreview} from "./questioncard-drag-preview";
import {OptionCardDragPreview} from "./optioncard-drag-preview";

const getItemStyles = (
	initialOffset: XYCoord | null,
	currentOffset: XYCoord | null,
): React.CSSProperties => {
	if (!initialOffset || !currentOffset) {
		return {
			display: "none",
		};
	}
	const {x, y} = currentOffset;
	const transform = `translate(${x}px, ${y}px)`;
	return {
		transform,
		WebkitTransform: transform,
	};
};

const CustomDragLayer = (): ReactElement | null => {
	const {itemType, isDragging, item, initialOffset, currentOffset} =
	useDragLayer(monitor => ({
		item: monitor.getItem(),
		itemType: monitor.getItemType(),
		initialOffset: monitor.getInitialSourceClientOffset(),
		currentOffset: monitor.getSourceClientOffset(),
		isDragging: monitor.isDragging(),
	}));

	if (!isDragging) return null;
	return <div className={styles.layer}>
		<div style={getItemStyles(initialOffset, currentOffset)}>
			{
				itemType === DragItemTypes.OPTIONCARD ? <OptionCardDragPreview {...item}/> :
					itemType === DragItemTypes.QUESTIONCARD ? <QuestionCardDragPreview {...item}/>
						: null}
		</div>
	</div>;
};

export {CustomDragLayer, QuestionCardDragPreview};
