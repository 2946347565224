import {Choice} from "./choice";
import {GenericPage} from "./generic";
import {ResponsesFilter} from "./response";
import {Transcript} from "./transcript";

export interface Answer {
	id: string;
	userId: string;
	skipped: boolean;
	createdAt?: Date;
	user: {id: string; firstName?: string; lastInitial?: string, creatorTag?: string};
	videoId: string;
	video: AnswerVideo;
	highlightCount: number;
	likeCount: number;
	likedAt: Date;
	numberAnswer?: number;
	text?: string;
	uploadedImages?: AnswerImage[];
	uploadedPdfs?: AnswerPdf[];
	barcodes?: Barcode[];
	reviewed: boolean;
}

export interface AnswerImage {
	transcodingStatus: TranscodingStatus;
	thumbnail: string;
	original: string;
	originalFilename: string;
}

// Only getting what we want for now.
export interface Barcode {
	id: string;
	barcode: string;
	product: BarcodeProduct;
}

export interface BarcodeProduct {
	title: string;
	images: string[];
}

export interface AnswerPdf {
	id: string;
	uploadStatus: UploadStatus;
	originalFilename: string;
	url: string;
}
// The data we expect on an answer inside of a response
export interface ResponseAnswer {
	id: string;
	questionId: string;
	choices: Omit<Choice, "answerCount" | "frequency">[]
	numberAnswer: number;
	text: string;
	video: {
		id: string;
		badge: string;
		duration: number;
	}
}
export interface AnswerTranscript extends Answer {
	reviewed: boolean;
	reviewer: {id: string; name: string};
	highlightCount: number;
	question: {text: string, followUp: string};
}

export interface DeleteAnswer {
	id: string;
	question: {id: string}
}

export interface SearchAnswer extends Answer {
	question: {
		id: string;
		survey: {
			id: string;
			name: string;
		}
		index: number;
		text: string;
	}
}

export interface TextAnswer {
	id: string;
	userId: string;
	user: {id: string; firstName?: string; lastInitial?: string};
	videoId?: string;
	video?: AnswerVideo;
	text?: string;
	numberAnswer?: number;
	transcript?: Transcript;
}


export interface Subtitles {
	id: string;
	fileUrl: string;
	langCode: string;
}

export interface AnswerVideoDefaults {
	id: string;
	duration: number;
	transcodingStatus: TranscodingStatus;
}
export interface AnswerVideo extends AnswerVideoDefaults {
	thumbnail?: string;
	video: string;
	mp4: string;
	transcript?: Transcript;
	subtitles?: Subtitles[];
	insight?: string;
}
export interface AnswerVideoThumbnail extends AnswerVideoDefaults {
	thumbnail: string;
}
/**
 * May need this
 */
export enum AnswerVideoVersion {
	ORIGINAL = "ORIGINAL",
	VP8_VORBIS = "VP8_VORBIS",
	H264_AAC = "H264_AAC",
	SCREENSHOT = "SCREENSHOT",
	THUMBNAIL = "THUMBNAIL",
	BADGE = "BADGE",
}
export enum TranscodingStatus {
	PENDING = "PENDING",
	COMPLETED = "COMPLETED",
	FAILED = "FAILED",
}

export enum UploadStatus {
	UPLOADING = "UPLOADING",
	UPDATING = "UPDATING",
	OUTDATED = "OUTDATED",
	SUCCEEDED = "SUCCEEDED",
	FAILED = "FAILED",
	SCANNING = "SCANNING", // Uploaded by not scanned for malware yet.
	SUSPICIOUS = "SUSPICIOUS",
}

export type AnswerPage = GenericPage<Answer>;
export interface AnswerPageData {
	answers: AnswerPage;
}

export interface AnswerData {
	answer: Answer;
}

export interface AnswerTranscriptData {
	answer: AnswerTranscript;
}

export interface VideoAnswersData {
	searchAnswers: GenericPage<SearchAnswer>;
}

export interface TextAnswersVars {
	questionId: string;
	limit?: number;
	cursor?: string;
}

export interface AnswersVars {
	questionId: string;
	filter?: AnswersPageFilter;
	limit?: number;
	cursor?: string;
	sort?: string;
	search?: string;
	highlighted?: boolean;
	textAndTranscriptsSearch?: string;
}

export interface AnswersPageFilter extends ResponsesFilter {
	choiceId?: string[] | null;
	highlighted?: boolean;
	search?: string;
	questionId?: string;
	numberAnswer?: (number | null)[] | null;
	hasVideo?: boolean;
	textAndTranscriptsSearch?: string;
}

export interface AnswersPageQueryParams extends AnswersPageFilter {
	display: "list" | undefined;
	workspaceId: string;
	sort?: string;
}

export interface DeleteAnswersVars {
	ids: string | string[];
}

export interface DeleteAnswersReturn {
	deleteAnswers: DeleteAnswer[];
}

export interface TextAnswerData {
	answers: GenericPage<TextAnswer>;
}
