export enum Comparator {
	isEqual = "IS_EQUAL",
	isNotEqual = "IS_NOT_EQUAL",
}

export enum MatchType {
	All = "ALL",
	Any = "ANY",
}

export enum DynamicSegmentSort {
	DEFAULT = "DEFAULT",
	NAME_ASC = "NAME_ASC",
	NAME_DESC = "NAME_DESC",
	CREATED_AT_ASC = "CREATED_AT_ASC",
	CREATED_AT_DESC = "CREATED_AT_DESC",
}

export interface DynamicSegment {
	id: string;
	name: string;
	memberCount?: number;
	matchType: MatchType
	workspaceId?: string;
	rules: DynamicSegmentRule[]
	createdAt?: Date;
}

export interface DynamicSegmentRule {
	id?: string;
	dynamicSegmentId?: string;
	propertyId: string;
	modelOwnProperty?: string;
	value: string;
	comparator: Comparator;
	__typename?: string;
	isNew?: boolean;
}

export interface DynamicSegmentData {
	dynamicSegments: {
		items: DynamicSegment[]
		remaining: number;
		cursor: string;
	}
}

export interface NewDynamicSegment {
	workspaceId: string,
	name: string
	rules: DynamicSegmentRule[]
	matchType: MatchType
}

export interface CreateDynamicSegmentVars {
	input: NewDynamicSegment
}

export interface UpdateDynamicSegmentVars {
	id: string,
	changes: Omit<DynamicSegment, "id">
}

export interface CreateDynamicSegmentData {
	createDynamicSegment: DynamicSegment;
}
