import React, {ReactElement, useContext, useMemo, useReducer} from "react";
import classNames from "classnames";
import {useMutation} from "@apollo/client";
import {v4 as uuid} from "uuid";

import {Select, Option, Icon} from "../../shared";
import {useLoadingQuery} from "../../hooks/useLoadingQuery";
import {GET_ALL_ATTRIBUTES_WITH_VALUES} from "../../graphql/queries/attribute-queries";
import {Attribute, AttributeWithExtant} from "../../models/attribute";
import {
	Comparator,
	CreateDynamicSegmentData,
	CreateDynamicSegmentVars,
	DynamicSegment,
	DynamicSegmentRule,
	MatchType,
	UpdateDynamicSegmentVars,
} from "../../models/dynamic-segment";
import {ToastContext} from "../../context/toast-context";
import {CREATE_DYNAMIC_SEGMENT, UPDATE_DYNAMIC_SEGMENT} from "../../graphql/mutations/mutations";
import {DYNAMIC_SEGMENT_FRAGMENT} from "../../graphql/fragments/fragments";
import {updateCacheAddPageItem} from "../../shared/utility/update-cache";
import {useWorkspaceContext} from "../../context/workspace-context";
import {Button, Input, Modal, Body, BaseModalProps} from "../../shared/v2";
import {PlusIcon} from "../../icons";

import styles from "./add-segment.module.scss";

export interface AddSegmentModalProps extends Omit<BaseModalProps, "isOpen">{
	onClose: () => void;
	initialState?: DynamicSegment|null;
	isCombined?: boolean;
}

enum ActionTypes {
	UpdateRule = "update",
	DeleteRule = "delete",
	NewRule = "new",
	SetMatchType = "setMatchType",
	SetName = "setName",
	Clear = "clear",
}

type Actions =
	|	{ type: ActionTypes.NewRule }
	|	{ type: ActionTypes.UpdateRule, rule: DynamicSegmentRule }
	|	{ type: ActionTypes.DeleteRule, id: string | undefined; }
	|	{ type: ActionTypes.SetMatchType, matchType: MatchType }
	|	{ type: ActionTypes.SetName, name: string }
	|	{ type: ActionTypes.Clear };

type State = {
	id?: string,
	name: string,
	matchType: MatchType,
	rules: DynamicSegmentRule[]
};

const createBlankRule = (): DynamicSegmentRule => ({
	comparator: Comparator.isEqual,
	propertyId: "",
	value: "",
	id: uuid(),
	isNew: true,
});

const reducer = (state: State, action: Actions): State => {
	switch (action.type) {
	case (ActionTypes.NewRule): {
		return {
			...state,
			rules: [
				...state.rules,
				createBlankRule(),
			],
		};
	}
	case (ActionTypes.DeleteRule): {
		return {
			...state,
			rules: state.rules.filter(rule => rule.id !== action.id),
		};
	}
	case (ActionTypes.UpdateRule): {
		return {
			...state,
			rules: state.rules.map(rule => {
				if (rule.id !== action.rule.id) {
					return rule;
				}

				return action.rule;
			}),
		};
	}
	case (ActionTypes.SetMatchType): {
		return {
			...state,
			matchType: action.matchType,
		};
	}
	case (ActionTypes.SetName): {
		return {
			...state,
			name: action.name,
		};
	}
	case (ActionTypes.Clear): {
		return {
			name: "",
			rules: [
				createBlankRule(),
			],
			matchType: MatchType.All,
		};
	}
	}
};

export interface FilterRuleRow {
	rule: DynamicSegmentRule,
	setValue: (value: string) => void;
	setProperty: (property: string) => void;
	setComparator: (comparator: Comparator) => void;
	deleteRule: () => void;
	options: Option[]
	values: Option[]
}

 
const FilterRuleRow = ({rule, setValue, setProperty, setComparator, deleteRule, values = [], options = []}: FilterRuleRow): ReactElement => {
	return (
		<div className={styles.filterRow}>
			<Select
				className={styles.propertySelect}
				id="property-select"
				options={options}
				onChange={setProperty}
				selectedValue={rule.propertyId || rule.modelOwnProperty}
			/>
			<Select
				className={styles.select}
				id={`comparator-select-${rule.id}`}
				options={[
					{text: "is equal to", value: Comparator.isEqual},
					{text: "is not equal to", value: Comparator.isNotEqual},
				]}
				onChange={setComparator}
				selectedValue={rule.comparator}
			/>
			<Select
				className={styles.valueSelect}
				id={`property-value-${rule.id}`}
				options={values}
				onChange={setValue}
				selectedValue={rule.value}
				disabled={values.length === 0}
			/>

			<div onClick={deleteRule}>
				<Icon
					className={styles.trashIcon}
					name="trash"
					fill="gray"
					size="small"
				/>
			</div>
		</div>
	);
};

const AddSegmentModal = ({initialState, onClose, isCombined = false}: AddSegmentModalProps): ReactElement => {
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const {updateToast} = useContext(ToastContext);
	const [state, dispatch] = useReducer(reducer, {
		name: "",
		rules: [createBlankRule()],
		matchType: MatchType.All,
	}, (defaultState: State) => {
		if (initialState !== null) {
			return {
				...defaultState,
				...initialState,
			};
		}

		return defaultState;
	});
	const {data} = useLoadingQuery(GET_ALL_ATTRIBUTES_WITH_VALUES, {
		variables: {workspaceId},
	});

	const onError = (error): void => {
		if (error.message.toString().includes("duplicate key value violates unique constraint")) {
			updateToast({
				type: "failure",
				description: "This segment name is already used. Choose a different name",
			});
		}
	};

	const [createDynamicSegment, {loading}] =
		useMutation<CreateDynamicSegmentData, CreateDynamicSegmentVars>(CREATE_DYNAMIC_SEGMENT, {
			onCompleted: () => updateToast({description: "Created New Segment", type: "informational"}),
			onError,
		});

	const [updateDynamicSegment] =
		useMutation<CreateDynamicSegmentData, UpdateDynamicSegmentVars>(UPDATE_DYNAMIC_SEGMENT, {
			onCompleted: () => updateToast({description: "Updated Segment", type: "informational"}),
			onError,
		});

	const closeModal = (): void => {
		dispatch({type: ActionTypes.Clear});
		onClose();
	};

	const validRules = (): boolean => {
		const emptyProperty = state.rules.find(rule => rule.propertyId === "");
		return !emptyProperty;
	};

	const handleNew = async(): Promise<void> => {
		// eslint-disable-next-line
		const rules = state.rules.map(({id, isNew, ...rest}) => ({...rest}));

		const result = await createDynamicSegment({
			variables: {
				input: {
					workspaceId,
					name: state.name,
					matchType: state.matchType,
					rules,
				},
			},
			update(cache, {data: dynamicSegment}) {
				if (dynamicSegment) {
					const newSegmentRef = cache.writeFragment({
						data: dynamicSegment.createDynamicSegment,
						fragment: DYNAMIC_SEGMENT_FRAGMENT,
					});
					updateCacheAddPageItem(
						cache,
						newSegmentRef,
						"dynamicSegments",
						dynamicSegment.createDynamicSegment.id,
					);
				}
			},
		});

		if (!result.errors) {
			closeModal();
		}
	};

	const handleUpdate = async(): Promise<void> => {
		if (!initialState?.id) {
			return Promise.resolve();
		}

		const result = await updateDynamicSegment({
			variables: {
				id: initialState.id,
				changes: {
					name: state.name,
					matchType: state.matchType,
					// eslint-disable-next-line
					rules: state.rules.map(({__typename, ...rule}) => {
						if (rule.isNew) {
							return {
								comparator: rule.comparator,
								propertyId: rule.propertyId,
								value: rule.value,
							};
						}

						return rule;
					}),
				},
			},
		});

		if (!result.errors) {
			closeModal();
		}
	};

	const handleSave = async(): Promise<void> => {
		if (!validRules()) {
			updateToast({type: "failure", description: "Property can't be blank"});
			return;
		}

		if (initialState?.id && !isCombined) {
			return handleUpdate();
		}

		return handleNew();
	};

	const [propertyOptions, propertyValues] = useMemo(() => {
		const attributes = data?.attributes?.items;

		if (!attributes) {
			return [ [], [] ];
		}

		const attributeOptions = attributes
			.map((attribute: Attribute): Option<string> => ({
				text: attribute.name,
				value: attribute.id,
			}));

		const attributeValues =
			attributes.reduce((result: { [key: string]: Option<string>}, attribute: AttributeWithExtant) => {
				const extantValues = attribute.extantValues ?? [];
				const values = extantValues.map((value: string) => ({
					text: value,
					value,
				}));
				return {
					...result,
					[attribute.id]: values,
				};
			}, {});

		return [attributeOptions, attributeValues];
	}, [data]);

	const getValues = (propertyId: string | undefined): Option[] => {
		if (!propertyId) {
			return [];
		}

		return propertyValues[propertyId];
	};

	return (
		<Modal
			className={styles.modal}
			title="New Segment"
			isOpen={true}
			onClose={closeModal}
			size="medium"
		>
			<div className={styles.segmentName}>
				<Input
					id="new-list-name"
					value={state.name}
					onChange={name => dispatch({type: ActionTypes.SetName, name})}
					label="Segment Name"
				/>
			</div>

			<div>
				<Body size="s" className={styles.span}>Creators match</Body>
				<Select
					className={styles.matchTypeSelect}
					id="match-type-select"
					options={[
						{text: MatchType.All, value: MatchType.All},
						{text: MatchType.Any, value: MatchType.Any},
					]}
					onChange={matchType => dispatch({type: ActionTypes.SetMatchType, matchType})}
					selectedValue={state.matchType}
					size="small"
				/>
				<Body size="s" className={styles.span}>of the following conditions</Body>
			</div>

			<div className={styles.divider}></div>

			<div className={styles.rulesList}>
				{state.rules.map((rule, index) => (
					<FilterRuleRow
						key={`${rule.id}-${index}`}
						rule={rule}
						setValue={value => dispatch({type: ActionTypes.UpdateRule, rule: {...rule, value}})}
						setProperty={
							property =>
								dispatch({type: ActionTypes.UpdateRule, rule: {...rule, propertyId: property}})
						}
						setComparator={
							comparator => dispatch({type: ActionTypes.UpdateRule, rule: {...rule, comparator}})
						}
						deleteRule={() => dispatch({type: ActionTypes.DeleteRule, id: rule.id})}
						options={propertyOptions}
						values={getValues(rule.propertyId)}
					/>
				))}
			</div>

			<Button
				className={classNames(styles.button, styles.addRule)}
				leftIcon={<PlusIcon />}
				variant="outlined"
				onClick={() => dispatch({type: ActionTypes.NewRule})}
			>
				Add rule
			</Button>
			<Button
				className={styles.button}
				onClick={handleSave}
				disabled={state.name === "" || loading}
			>
				{loading ? "Creating..." : "Save"}
			</Button>
		</Modal>
	);
};

export {AddSegmentModal};
