import React, {PropsWithChildren, ReactElement} from "react";
import classNames from "classnames/bind";

import {Header} from "../../../../shared/v2/typography";
import {Separator} from "../../../../shared/v2/separator";

import styles from "./page-sub-navigation.module.scss";

const cx = classNames.bind(styles);

export type PageSubNavigationProps = PropsWithChildren<{
  header: string;
  controlPanelContent?: ReactElement;
  className?: string;
}>;

export const PageSubNavigation = ({
	header,
	children,
	className,
	controlPanelContent
}: PageSubNavigationProps): ReactElement => {
	return (
		<div className={cx("subNavigationWrapper", className)}>
			<div className={styles.controlPanel}>
				<Header type="semibold" size="base">
					{header}
				</Header>

				{controlPanelContent}
			</div>
			{children}

			<Separator />
		</div>
	)
}
